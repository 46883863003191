import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { POSService } from 'src/app/_services/pos-service';
import { CashDrawerConfirmComponent } from './cash-drawer-confirm/cash-drawer-confirm.component';
import { EndDrawerDetailComponent } from './end-drawer-detail/end-drawer-detail.component';
import { OrderService } from 'src/app/_services/order.service';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { EndDrawerConfirmationComponent } from './end-drawer-confirmation/end-drawer-confirmation.component';
import { StoreService } from 'src/app/_services/store.service';
import { Store } from 'src/app/_models/store';

@Component({
  selector: 'app-cash-drawer-management',
  templateUrl: './cash-drawer-management.component.html',
  styleUrls: ['./cash-drawer-management.component.scss']
})
export class CashDrawerManagementComponent {

  store_id: any;
  public unsubscribe: Subject<null> = new Subject<null>();
  dollarForm: FormGroup;
  centForm: FormGroup;
  totalBills = 0;
  totalChange = 0;
  total = 0;
  end_drawer_detail: any;
  popUpData: any;
  isLoading: boolean = false;
  disablebtn: boolean = false;
  storeSub: Subscription;
  store: Store;
  constructor(
    public dialogRef: MatDialogRef<CashDrawerManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private posService: POSService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private storeService: StoreService,
    private orderService: OrderService
  ) {
    this.popUpData = data

    this.storeSub = this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });


  }

  ngOnInit() {
    this.store_id = localStorage.getItem('currentStore');
    // if (this.data.denomination_detail) {
    //   this.totalBills = this.data.denomination_detail.bill_total;
    //   this.totalChange = this.data.denomination_detail.change_total
    //   this.total = this.data.denomination_detail.total;

    // [this.data.denomination_detail ? this.data.denomination_detail.dollar_100 : 0],
    // [this.data.denomination_detail ? this.data.denomination_detail.cent_1 : 0],
    // }
    this.dollarForm = this.formBuilder.group({
      dollar_100: [0, Validators.required],
      dollar_50: [0, Validators.required],
      dollar_20: [0, Validators.required],
      dollar_10: [0, Validators.required],
      dollar_5: [0, Validators.required],
      dollar_1: [0, Validators.required],
    });

    this.centForm = this.formBuilder.group({
      cent_1: [0, Validators.required],
      cent_5: [0, Validators.required],
      cent_10: [0, Validators.required],
      cent_25: [0, Validators.required],
      cent_50: [0, Validators.required],
    })


    this.dollarForm.valueChanges.subscribe(() => {
      this.calculateDollarCentTotal();
    });

    this.centForm.valueChanges.subscribe(() => {
      this.calculateDollarCentTotal();
    });
  }

  calculateDollarCentTotal(): void {
    this.total = 0;
    this.totalBills = 0;
    Object.keys(this.dollarForm.controls).forEach(controlName => {
      const parts = controlName.split('_');
      const value = +parts[1];
      const result = parseFloat((value * this.dollarForm.get(controlName).value).toFixed(2));
      this.totalBills += result;
    });
    this.totalChange = 0;
    Object.keys(this.centForm.controls).forEach(controlName => {
      const parts = controlName.split('_');
      const value = +parts[1];
      const result = parseFloat(((value * (this.centForm.get(controlName).value ? this.centForm.get(controlName).value : 0)) / 100).toFixed(2));
      this.totalChange += result;
    });
    this.total += this.totalBills + this.totalChange;
  }

  adDrawerDenominations() {
    let payload = {
    }
    this.posService.addDrawerDenominations(this.store_id, payload).pipe(takeUntil(this.unsubscribe)).subscribe(result => { });
  }

  startDrawer() {
    let payload = {
      "store": this.data.store_id,
      "terminal": this.data.terminal_id,
      "user": [this.data.user_id],
      "bill_total": this.totalBills,
      "change_total": this.totalChange,
      "total": this.total
    }

    let dialogRef = this.dialog.open(CashDrawerConfirmComponent, {
      disableClose: true,
      data: {
        'key': 'start_drawer',
        'amount': this.total
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result.key) {
        this.isLoading = true;
        this.posService.createDrawer(payload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
          console.log('Drawer Created', result);
          const formPayload = { ...this.dollarForm.value, ...this.centForm.value };
          formPayload['drawer'] = result.id;
          formPayload['user'] = this.data.user_id;
          formPayload['bill_total'] = this.totalBills.toFixed(2);
          formPayload['change_total'] = this.totalChange.toFixed(2);
          formPayload['total'] = this.total.toFixed(2);
          formPayload['dollar_2'] = 0;
          formPayload['reason'] = 'NA';
          formPayload['status'] = 3;
          this.posService.addDrawerDenominations(this.data.store_id, formPayload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
            console.log('Denomination ADD');
            this.isLoading = false;
            this.alertPopup(`${this.popUpData.terminal_name} is Successfully Opened with $${this.total.toFixed(2)} Starting Cash`, '')
            // this.dialogRef.close(true);
          });

        })
      }
    });

  }

  payCashIn() {
    console.log('Pay Cash IN');
    let dialogRef = this.dialog.open(CashDrawerConfirmComponent, {
      disableClose: true,
      data: {
        'key': 'pay_cash_in',
        'amount': this.total
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      
      if (result.key) {
        this.isLoading = true;
        const formPayload = { ...this.dollarForm.value, ...this.centForm.value };
        formPayload['drawer'] = this.data.denomination_detail.id;
        formPayload['user'] = this.data.user_id;
        formPayload['bill_total'] = this.totalBills.toFixed(2);
        formPayload['change_total'] = this.totalChange.toFixed(2);
        formPayload['total'] = this.total.toFixed(2);
        formPayload['status'] = 1;
        formPayload['reason'] = result.reason;
        formPayload['dollar_2'] = 0;
        formPayload['terminal_name'] = this.popUpData.terminal_name,
        this.posService.doingPayCashInOut(this.store_id, formPayload).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.alertPopup(`The $${this.total.toFixed(2)} cash has been successfully added to the ${this.popUpData.terminal_name} drawer.`, '')
          // this.dialogRef.close(true);
          this.isLoading = false;


        });
      }
    });

  }

  payCashOut() {
    console.log('Pay Cash OUT', this.data);
    if (this.total <= this.data.denomination_detail.total) {
      let dialogRef = this.dialog.open(CashDrawerConfirmComponent, {
        disableClose: true,
        data: {
          'key': 'pay_cash_out',
          'amount': this.total
        }
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        if (result.key) {
          this.isLoading = true;
          const formPayload = { ...this.dollarForm.value, ...this.centForm.value };
          formPayload['drawer'] = this.data.denomination_detail.id;
          formPayload['user'] = this.data.user_id;
          formPayload['bill_total'] = this.totalBills.toFixed(2);
          formPayload['change_total'] = this.totalChange.toFixed(2);
          formPayload['total'] = this.total.toFixed(2);
          formPayload['status'] = 2;
          formPayload['reason'] = result.reason;
          formPayload['dollar_2'] = 0;
          formPayload['terminal_name'] = this.popUpData.terminal_name,
          this.posService.doingPayCashInOut(this.store_id, formPayload).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.alertPopup(`The $${this.total.toFixed(2)} cash payout has been successfully processed to the ${this.popUpData.terminal_name} drawer.`, '')
            // this.dialogRef.close(true);
            this.isLoading = false;

          });
        }
      });
    } else {
      this.alertPopup(`There is only $${this.data.denomination_detail.total} in the drawer, insufficient for the payment.`, 1)
    }

  }

  endDrawer() {
    this.disablebtn = true;
    this.isLoading = true;
    console.log('End Drawer');
    let payload = {
      "store": this.data.store_id,
      "terminal": this.data.terminal_id,
      "user": [this.data.user_id],
      "bill_total": this.totalBills,
      "change_total": this.totalChange,
      "total": this.total
    }

    this.posService.endDrawerDetails(this.store_id, this.data.denomination_detail.id).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.end_drawer_detail = result.data;
      this.isLoading = false;
      let dialogRef = this.dialog.open(EndDrawerDetailComponent, {
        width: '680px',
        disableClose: true,
        data: {
          'amount': this.total,
          'end_detail': result.data
        }
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        this.disablebtn = false;

        if (result) {
          const formPayload = { ...this.dollarForm.value, ...this.centForm.value, ...this.end_drawer_detail };
          formPayload['drawer'] = this.data.denomination_detail.id;
          formPayload['user'] = this.data.user_id;
          formPayload['bill_total'] = this.totalBills.toFixed(2);
          formPayload['change_total'] = this.totalChange.toFixed(2);
          formPayload['total'] = this.total.toFixed(2);
          formPayload['status'] = 3;
          formPayload['difference_reason'] = result.reason;
          formPayload['dollar_2'] = 0;
          formPayload['actual_in_drawer'] = this.total.toFixed(2);
          formPayload['difference'] = (this.total - this.end_drawer_detail.expected_in_drawer).toFixed(2);
          this.posService.endDrawer(this.store_id, this.data.denomination_detail.id, formPayload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
            if (result) {
              this.endDrawerConfirm(result.cdm_obj);
              // this.dialogRef.close(true);
            }

          });
        }
      });
    });

  }

  endDrawerConfirm(cdm) {
    let dialogRef = this.dialog.open(EndDrawerConfirmationComponent, {
      disableClose: true,
      data: cdm
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.posService.drawerEndLogOutuser.next(true);
        this.dialogRef.close(true);
      }
    });
  }

  alertPopup(msg, i) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      data: {
        message: msg,
        for_which: 'drawer'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (i != 1) {
          this.dialogRef.close(true);
        }
      }
    });
  }

  incrementQuantity(value, currency) {
    if (currency == 'dollar') {
      const currentValue = this.dollarForm.get(value).value;
      this.dollarForm.get(value).setValue(currentValue + 1);
    } else {
      const currentValue = this.centForm.get(value).value;
      this.centForm.get(value).setValue(currentValue + 1);
    }

  }

  decrementQuantity(value, currency) {
    if (currency == 'dollar') {
      const currentValue = this.dollarForm.get(value).value;
      if (currentValue > 0) {
        this.dollarForm.get(value).setValue(currentValue - 1);
      }
    } else {
      const currentValue = this.centForm.get(value).value;
      if (currentValue > 0) {
        this.centForm.get(value).setValue(currentValue - 1);
      }
    }

  }

  startDrawerbtnvalidity() {
    if (this.data?.denomination_detail) {
      return true
    } else {
      if (this.store.getFeatureFlag('no_starting_balance')) {
        return false;
      } else {
        if (this.dollarForm.valid && this.centForm.valid && this.total > 0) {
          return false
        } else {
          return true
        }
      }
      
    }
  }

  cashInOutbtnvalidity() {
    if (!this.data?.denomination_detail?.drawer_denomination) {
      return true
    } else {
      if (this.dollarForm.valid && this.centForm.valid && this.total > 0) {
        return false
      } else {
        return true
      }
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }


}
