
<form class="fixActionRow">

<h1 mat-dialog-title>Upload Image</h1>

<div mat-dialog-content>
  <div class="drop-container" >
    <label for="uploader-input" ng2FileDrop [ngClass]="{'drag-over': false}" (fileOver)="fileOver($event)" [uploader]="uploader">
      <div class="infotext upload-image flex-wrap">Drop your picture or click to upload</div>
    </label>
    <input type="file" ng2FileSelect [uploader]="uploader" id="uploader-input" style="display:none" />
  </div>
  <div class="image-container" *ngIf="imageUrl">
    <angular-cropper
      #cropper
      [cropperOptions]="cropperConfig"
      [imageUrl]="imageUrl"></angular-cropper>
  </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="inProgress"></mat-progress-bar>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
  <button  mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr" (click)="apply()">Apply</button>
</div>

</form>
