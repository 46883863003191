import {FormBase} from './form-base';

export class FormDropDown extends FormBase<string> {
  controlType = 'dropdown';
  options: {key: string, value: string}[] = [];
  isDynamic:boolean = false

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'];
    this.isDynamic = options['isDynamic']  || this.isDynamic
  }

  getOptions(callback){
    return callback();
  }
}
