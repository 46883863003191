import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  isAck = false;
  constructor() { }

  ngOnInit() {
    this.isAck = localStorage.getItem('ack') === 'true';
  }

  ack(){
    this.isAck = true;
    localStorage.setItem('ack', 'true');
  }

}
