import {Component, OnInit} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SkillComponent implements OnInit {

  constructor(private storeService:StoreService, public dialog: MatDialog) { }
  dataSource;
  store:Store
  columnsToDisplay = ['phrase', 'response', 'star']
  expandedElement;
  isLoading = true;

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.storeService.getSkills(this.store.id).subscribe(skills => {
          this.dataSource = skills.map(skill => {
            let form = new UntypedFormGroup({
              id: new UntypedFormControl(skill.id, Validators.required),
              phrase: new UntypedFormControl(skill.phrase, Validators.required),
              response: new UntypedFormControl(skill.response, Validators.required),
              store_id: new UntypedFormControl(this.store.id, Validators.required),
            })
            skill.form = form
            skill.type = 'update'
            return skill
          })
          this.dataSource.push({
            'type': 'add',
            'phrase': 'Add New Skill',
            'response': '',
            'form': new UntypedFormGroup({
              id: new UntypedFormControl('', Validators.required),
              phrase: new UntypedFormControl('', Validators.required),
              response: new UntypedFormControl('', Validators.required),
              store_id: new UntypedFormControl(this.store.id, Validators.required),
            })
          })
          this.isLoading = false;
          console.log(this.dataSource);

        })
      }
    });
  }

  createSkill(value){

  }

  updateSkill(value){
    if(value.id){
      this.storeService.updateSkill(value.id, value).subscribe(data => this.ngOnInit())
    }else{
      this.storeService.createSkill(value).subscribe(data => this.ngOnInit())
    }
  }

  deleteSkill(value){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure you want to delete "+ value.phrase,
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.storeService.deleteSkill(value.id).subscribe(data => this.ngOnInit())
      }
    });
    // if(confirm("Are you sure you want to delete "+ value.phrase)){
    //   this.storeService.deleteSkill(value.id).subscribe(data => this.ngOnInit())
    // }
  }



}
