import {BotOrder, BotOrders} from '../_models/order';
import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {OrderService} from '../_services/order.service';


export class OrdersV2DataSource implements DataSource<BotOrder> {

  private botOrderSubject = new BehaviorSubject<BotOrder[]>([]);
  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private orderService: OrderService) {}

  connect(collectionViewer: CollectionViewer): Observable<BotOrder[]> {
      return this.botOrderSubject.asObservable();
  }

  getTotal(){
    return this.totalSubject.getValue();
  }

  getIds(){
    return this.botOrderSubject.getValue().map(bo => bo.id);
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.botOrderSubject.complete();
      this.loadingSubject.complete();
  }

  closedOrders(storeId:number, filter = '', startDate=null, endDate=null,
               sortField='id', sortOrder='desc',
               pageNumber=0, pageSize=10, cashierId='',
               occasions = [], payment = [], source = [], status = [], server_id = []) {
      this.loadingSubject.next(true);
      this.orderService.closedOrders(storeId, filter, startDate, endDate, sortField,
        sortOrder, pageNumber, pageSize, cashierId, occasions, payment, source, status, server_id).pipe(
          catchError(() => of(new BotOrders())),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(botOrders => {
        this.botOrderSubject.next(botOrders.orders);
        this.totalSubject.next(botOrders.total);
      });
  }

  openOrders(storeId:number, filter = '', startDate=null, endDate=null,
             sortField='order_counter', sortOrder='desc',
             pageNumber=0, pageSize=10, cashierId='',
             occasions = [], payment = [], source = [], status = [], server_id = []) {
      this.loadingSubject.next(true);
      this.orderService.openOrders(storeId, filter, startDate, endDate, sortField,
        sortOrder, pageNumber, pageSize, cashierId, occasions, payment, source, status, server_id).pipe(
          catchError(() => of(new BotOrders())),
          finalize(() => this.loadingSubject.next(false))
      ).subscribe(botOrders => {
        this.loadingSubject.next(false);
        this.botOrderSubject.next(botOrders.orders);
        this.totalSubject.next(botOrders.total);
      });
  }

}
