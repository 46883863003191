<div class="w-full border border-gray-200 mt-5 overflow-x-hidden">
  <table mat-table multiTemplateDataRows [dataSource]="newMenuDatasource" class="w-full">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
      <td mat-cell *matCellDef="let element" class="heading"> {{element[column]}} </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <form #f="ngForm" class="form" [formGroup]="newMenuItemGroupForm" (ngSubmit)="addNewMenuItemGroup(newMenuItemGroupForm.value)">
            <mat-form-field class="w-full">
              <input matInput placeholder="Category Name" formControlName="menu_group_name" #menuGroupName>
              <mat-hint>Examples: Lunch, Dinner, Appetizers, Beverages </mat-hint>
            </mat-form-field>

            <div class="flex">
              <mat-form-field class="w-6/12">
                <input matInput placeholder="Sub Category Name (optional)" formControlName="subgroup" #subCatName>
                <mat-hint>Examples: Pizza, Tacos </mat-hint>
              </mat-form-field>

              <mat-form-field class="ml-[20px]">
                <mat-select formControlName="assignto_printer" placeholder="Assign to Printer" multiple>
                  <mat-option *ngFor="let opt of printerSettings" [value]="opt.id">{{opt.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <app-menu-category-availability
              [store]="store"
              [menuGroupName]="menuGroupName.value" [subCatName]="subCatName.value"></app-menu-category-availability>

            <p class="heading">Add Menu Item</p>
            <p class="sub-heading">Enter at least one menu item per category. You can add more later.</p>

            <mat-form-field class="width-100">
              <input matInput placeholder="Item name" formControlName="name">
            </mat-form-field>

            <mat-form-field class="width-100">
              <input matInput placeholder="Description" formControlName="description">
            </mat-form-field>

            <p class="heading">Item Portions</p>
            <p class="sub-heading">Enter the different portions and prices for this item. Use “Standard” if the item comes in only one size.</p>

            <div formArrayName="menu_items" class="bottom-space" *ngFor="let item of newMenuItemGroupForm.get('menu_items').controls; let i = index">
              <div class="border-bottom" [formGroupName]="i">
                <div class="flex flex-row flex-wrap items-center justify-evenly">
                  <mat-form-field subscriptSizing="dynamic" class="width-10 mr--0 xs:!w-40">
                    <input type="text" placeholder="Portion" matInput formControlName="portion"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" class="width-10 mr--0 xs:!w-40">
                      <mat-option *ngFor="let option of getPortionOptions()" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                  <mat-form-field subscriptSizing="dynamic" class="width-10 mr--0 xs:!w-40">
                    <input matInput placeholder="Base price" type="number" formControlName="base_price">
                  </mat-form-field>
                  <mat-form-field subscriptSizing="dynamic" class="width-10 mr--0 xs:!w-40" *ngIf="store?.feature_flag['barcode_scanner_feature']">
                    <input matInput placeholder="Barcode" type="text" formControlName="barcode">
                  </mat-form-field>
                  <mat-form-field subscriptSizing="dynamic" class="w-[12%] xs:!w-40 mr-[12px] ml-[-11px] xs:ml-[0px]">
                    <mat-select formControlName="reset" placeholder="Reset">
                      <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field subscriptSizing="dynamic" class="width-10 mr--0 xs:!w-40">
                    <input matInput placeholder="Food Cost" type="number" formControlName="food_cost">
                  </mat-form-field>
                  <mat-form-field subscriptSizing="dynamic" class="width-10 mr--0 xs:!w-40">
                    <input matInput placeholder="Qty" formControlName="available_qty" type="number" min="0">
                  </mat-form-field>
                  <mat-checkbox formControlName="is_available">Available</mat-checkbox>
                  <mat-checkbox formControlName="is_taxable">Taxable</mat-checkbox>
                  <mat-checkbox formControlName="is_pos_only">POS Only</mat-checkbox>
                  <button class="delete" type="button" mat-stroked-button (click)="deleteMenuItemFromNewForm(); false">
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="actions button-center xs:flex xs:flex-nowrap gap-2 ">
              <button class="pull-left button-center add-btn" mat-stroked-button color="primary" type="button"
              (click)="addPortionNewMenuItemGroup(); false">Add Portion</button>
              <button class="pull-right cancel-btn" mat-stroked-button color="primary" type="button" 
              (click)="expandedElement.set(menuGroup.id, null); false">Cancel</button>
              <button class="pull-right save-btn" mat-stroked-button color="primary" type="submit"
              [disabled]="!newMenuItemGroupForm.valid" >Save</button>
              <div style="clear: both"></div>
            </div>
            <!-- <div class="flex  action flex-row gap-2 flex-wrap button-center">
              <div class="gap-2 button-center">
                <button class="pull-left new-member" mat-stroked-button color="primary" (click)="addPortionNewMenuItemGroup(); false">Add Portion</button>
              </div>
              <div class="flex flex-row gap-2 flex-wrap bottom-space button-center">
                <button class=" pull-right light-button new-member" mat-stroked-button color="primary" (click)="expandedElement.set(menuGroup.id, null); false">Cancel</button>
                <button class=" pull-right gap-2 new-member" mat-stroked-button color="primary" type="submit" [disabled]="!newMenuItemGroupForm.valid">Save</button>
              </div>
            </div> -->
          </form>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    <tr mat-row>Last</tr>
  </table>
</div>
