import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-default-screen-dialog',
  templateUrl: './default-screen-dialog.component.html',
  styleUrls: ['./default-screen-dialog.component.scss']
})
export class DefaultScreenDialogComponent {
  storeId: any;
  addDefaultScreenForm: UntypedFormGroup;
  submitted: boolean;
  screenList: any;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<DefaultScreenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("dsatdata", data);
  }
  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    if (this.data.view == 'set') {
      this.addDefaultScreenForm = this.fb.group({
        store_id: [this.storeId],
        name: ['', Validators.required],
        screen_id: [this.data.id],
      })
    }
    else if (this.data.view == 'select') {
    this.defaultScreenList();
      this.addDefaultScreenForm = this.fb.group({
        store_id: [this.storeId],
        name: [''],
        screen_id: ['', Validators.required],
      })
    }

  }

  defaultScreenList() {
    this.digitalDisplayService.defaultScreenList().subscribe(data => {
      this.screenList = data.data
    })
  }
  addDefaultScreenFormSubmit() {
    this.submitted = true;
    if (this.addDefaultScreenForm.invalid) {
      return;
    }
    this.digitalDisplayService.defaultScreenSet(this.addDefaultScreenForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.dialogRef.close(resData);
    })
  }

  addDefaultFormSubmit() {
    this.submitted = true;
    if (this.addDefaultScreenForm.invalid) {
      return;
    }
    const screenNameGet = this.screenList.filter(name => name.id == this.addDefaultScreenForm.value.screen_id);
    const screenName = screenNameGet[0].name;

    let keySend = {
      store_id: this.storeId,
      screen_id: this.addDefaultScreenForm.value.screen_id,
      name: screenName,
    }
    this.digitalDisplayService.newScreenSet(keySend).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.dialogRef.close(resData);
    })
  }

  newScreenCreate() {
    let keySend = {
      "screen_id": this.data.data.id,
      "store_id": this.storeId,
      "name": this.data.data.name
    }
    this.digitalDisplayService.newScreenSet(keySend).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.dialogRef.close(resData);
    })
  }
}
