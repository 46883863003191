import { Component, Input, OnInit } from '@angular/core';
import { DynamicFormService } from './dynamic-form.service';
import { FormBase } from './dynamic-form-control/form-base';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '../../../_models/store';
import { StoreService } from '../../../_services/store.service';
import { first } from 'rxjs/operators';
import { DialogConfig, templateConfig } from '../../store-info/store-info-template-config';
import { EditDialogComponent } from '../edit-dialog.component';
import { FormUtils } from 'src/app/_helpers/form-utils';
import * as DataObjectParser from 'dataobject-parser';
import { forkJoin } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [DynamicFormService]
})
export class DynamicFormComponent implements OnInit {
  @Input() formControls: FormBase<any>[] = [];
  @Input() editControlKeys: string[];
  @Input() store: Store;

  @Input() dialogRef: MatDialogRef<EditDialogComponent>

  form: UntypedFormGroup;
  payLoad = {};
  error = '';
  dialogConfig: DialogConfig;

  constructor(private dynamicFormService: DynamicFormService,
    private storeService: StoreService,
    private formUtils: FormUtils) { }

  ngOnInit() {
    this.form = this.dynamicFormService.toFormGroup(this.formControls);
    this.dialogConfig = templateConfig[this.editControlKeys[0]];
  }

  get24Hour(timeString: string) {
    let [time, meridiem] = timeString.split(" ")
    let [hour, min] = time.split(":")
    let parsedHour = Number(hour)
    let parsedMin = Number(min)

    if (meridiem === 'pm') {
      let hourCasted = Number(hour)
      parsedHour = hourCasted === 12 ? 12 : Number(hour) + 12
    } else if (meridiem === 'am' && Number(hour) === 12) {
      parsedHour = 0;
    }
    return [parsedHour, parsedMin]
  }

  keyToPay

  onSubmit() {
    this.payLoad = {};

    if (this.editControlKeys[0] !== 'working') {
      for (const editControlKey of this.editControlKeys) {
        if (editControlKey.includes(".")) {
          let d = new DataObjectParser();
          d.set(editControlKey, this.form.value[editControlKey])
          this.payLoad = { ...this.payLoad, ...d.data() }
        }

        if (editControlKey === 'phone' || editControlKey === 'secondary_phone' || editControlKey === 'connect_number') {
          this.payLoad[editControlKey] = this.formUtils.getPlainPhoneNumber(this.form.value[editControlKey]);
        } else if (['delivery_lead_time', 'pickup_dine_in_lead_time'].includes(editControlKey)) {
          this.payLoad[editControlKey] = this.form.value[editControlKey] ? this.form.value[editControlKey] : 0;
        }
        else {
          this.payLoad[editControlKey] = this.form.value[editControlKey];
          const occasionPl = this.payLoad;
          for (let key in occasionPl) {
            if (typeof occasionPl[key] === 'string' && occasionPl[key] === "") {
              occasionPl[key] = false;
            }
          }

        }
      }

      let obs = []
      if (this.payLoad['ivr_settings']) {
        let ivrObs = this.storeService.updateStoreIVRSetting(this.store.id, JSON.stringify(this.payLoad))
        obs.push(ivrObs)
      }

      let otherObs = this.storeService.updateStore(this.store, JSON.stringify(this.payLoad))
      obs.push(otherObs)


      forkJoin(obs).pipe(first())
        .subscribe(data => {
          this.dialogRef.close();
        },
          error => {
            console.log('failure');
            this.error = error;
          }
        );
    } else {
      let workingHourPayload = {}
      console.log(this.form.value);

      for (let day = 0; day <= 6; day++) {
        let from = this.form.value['from_' + day];
        let to = this.form.value['to_' + day];
        let is_open = this.form.value['is_open_' + day];
        let [fromHour, fromMin] = this.get24Hour(from)
        let [toHour, toMin] = this.get24Hour(to)
        workingHourPayload["from_hour_" + day] = +fromHour
        workingHourPayload["from_min_" + day] = +fromMin
        workingHourPayload["to_hour_" + day] = +toHour
        workingHourPayload["to_min_" + day] = +toMin
        workingHourPayload["is_open_" + day] = is_open ? true : false
      }

      this.storeService.updateWorkingHours(this.store, JSON.stringify(workingHourPayload)).subscribe(data => {
        console.log("updated")
        this.dialogRef.close();
      },
        error => {
          this.error = error;
        })
    }
  }
}
