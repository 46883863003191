import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from 'src/app/_services/store.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
  selector: 'app-header-settings',
  templateUrl: './header-settings.component.html',
  styleUrls: ['./header-settings.component.scss']
})
export class HeaderSettingsComponent implements OnInit {
  idStore: any;
  headersetting: UntypedFormGroup;
  attentionText: string;
  startDateTime: string;
  endDateTime: string;
  attentionTextShow: string;
  fromDateTimeShow: string;
  endDateTimeShow: string;

  /* Editor */

  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '10rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    // toolbarHiddenButtons: [],
    sanitize: false,
  };

  constructor(
    public storeService: StoreService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<HeaderSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.attentionTextShow = this.data.store.disclaimer_message;
    this.fromDateTimeShow = this.data.store.disclaimer_from_time;
    this.endDateTimeShow = this.data.store.disclaimer_to_time;
    console.log('attentionTextShow',this.attentionTextShow);
    // console.log('in locale',new Date(this.fromDateTimeShow).toLocaleString("en-US", {timeZone: this.data.store.time_zone}));
    this.idStore = localStorage.getItem('currentStore')
    this.headersetting = this.fb.group({
      attention: this.attentionTextShow,
      startdate: this.fromDateTimeShow,
      enddate: this.endDateTimeShow,

    });
    this.htmlContent = this.attentionTextShow
  }

  editAttentionContent() {
    this.attentionText = this.headersetting.value.attention;
    this.startDateTime = new Date(this.headersetting.value.startdate).toISOString();
    this.endDateTime = new Date(this.headersetting.value.enddate).toISOString();

    const keySend = {
      "disclaimer_show": this.data.store.disclaimer_show,
      "disclaimer_message": this.attentionText,
      "disclaimer_from_time": this.startDateTime,
      "disclaimer_to_time": this.endDateTime
    }
    this.storeService.updateHeaderDisclaimer(this.idStore, keySend).subscribe((data: any) => {
    })
    this.dialogRef.close(keySend);
  }
}
