import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-summary-toprint',
  templateUrl: './select-summary-toprint.component.html',
  styleUrls: ['./select-summary-toprint.component.scss']
})
export class SelectSummaryToprintComponent implements OnInit {

  selectedSummary: any;
  constructor(
    public dialogRef: MatDialogRef<SelectSummaryToprintComponent>
  ) { }

  ngOnInit() {
  }

  selectedReport(e) {
    this.selectedSummary = e;
    setTimeout(() => {
      this.dialogRef.close(e);
    }, 400)
  }

}
