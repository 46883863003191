import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-contact-bulk-import-dialog',
  templateUrl: './contact-bulk-import-dialog.component.html',
  styleUrls: ['./contact-bulk-import-dialog.component.scss']
})
export class ContactBulkImportDialogComponent implements OnInit {
  @ViewChild('file',{static: false}) file: ElementRef;
  public uploadedFile: File;
  public invalidFile: boolean;
  public termsAgreed: boolean;

  constructor(public dialogRef: MatDialogRef<ContactBulkImportDialogComponent>) { }

  ngOnInit() { }

  handleFileUpload(event: Event) {
    const uploadedFile: File = (event.currentTarget as HTMLInputElement).files[0];
    if (uploadedFile.name.endsWith('.csv')) {
      this.invalidFile = false;
      this.uploadedFile = (event.currentTarget as HTMLInputElement).files[0];
    } else {
      this.removeUploadedFile();
      this.invalidFile = true;
    }
  }

  removeUploadedFile() {
    this.uploadedFile = null;
    this.file.nativeElement.value = '';
  }

  handleTermsCheckboxChange() {
    this.termsAgreed = !this.termsAgreed;
  }

  closeDialog() {
    this.dialogRef.close(this.uploadedFile);
  }

}
