<form name="editSubCategoryForm" [formGroup]="editSubCategoryForm" (ngSubmit)="editSubCategoryFormSubmit()" class="fixActionRow">
  <h1 mat-dialog-title>Edit Sub Category</h1>
  <div mat-dialog-content style="overflow: hidden;">
    <div fxLayout="row" class="!mt-4" style="width: 100%;">
      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Display name</mat-label>
          <input matInput placeholder="Display name" formControlName="display_name">
        </mat-form-field>
      </div>
      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Priority</mat-label>
          <input matInput placeholder="" type="number" formControlName="priority">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" style="width: 100%;">
      <div class="input-gap" style="width: 50%;">
        <mat-checkbox (change)="newScreenCheck($event)" [checked]="newScreen">New screen</mat-checkbox>
      </div>
      <div class="input-gap" style="width: 50%;">
        <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide category</mat-checkbox>
      </div>
    </div>
    <div fxLayout="row" style="width: 100%; margin-top:15px;">
      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Font-color</mat-label>
          <input matInput placeholder="" type="text" formControlName="font_color">
        </mat-form-field>
      </div>
      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Emoji</mat-label>
          <input matInput aria-label="Emoji" [matAutocomplete]="auto" formControlName="emoji">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let state of emojiLists" [value]="state.value">
              <img class="example-option-img" aria-hidden [src]="state.value" height="25">
              <span>{{state.name}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
  </mat-dialog-actions>
</form>
