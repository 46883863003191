import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertAudioService {

  constructor() { }

  alert(): void {
    const audio = new Audio();
    audio.src = 'https://storage.googleapis.com/menus/alert.mp3';
    audio.load();
    audio.play();
  }
}
