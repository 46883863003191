<form [formGroup]="form" (onSubmit)="submit()" id="printer-setting-dialog" class="fixActionRow">
  <h2 mat-dialog-title>
    Edit Printer
  </h2>
  <mat-dialog-content>
    <mat-form-field class="full-width" mat-focused>
      <mat-label>Name</mat-label>
      <input matInput #name placeholder="Name" formControlName="name">
    </mat-form-field>

    <mat-form-field class="full-width" mat-focused *ngIf="store.has_order_occasion_pickup">
      <mat-label>Printouts per pickup order</mat-label>
      <input matInput #name placeholder="Pickup Count" formControlName="pickup_count">
    </mat-form-field>

    <mat-form-field class="full-width" mat-focused *ngIf="store.has_order_occasion_delivery">
      <mat-label>Printouts per delivery order</mat-label>
      <input matInput #name placeholder="Delivery Count" formControlName="delivery_count">
    </mat-form-field>

    <mat-form-field class="full-width" mat-focused *ngIf="store.has_order_occasion_curbside">
      <mat-label>Printouts per curbside order</mat-label>
      <input matInput #name placeholder="Curbside Count" formControlName="curbside_count">
    </mat-form-field>

    <mat-form-field class="full-width" mat-focused *ngIf="store.has_order_occasion_dine_in">
      <mat-label>Printouts per dine-in order</mat-label>
      <input matInput #name placeholder="Dine in Count" formControlName="dine_in_count">
    </mat-form-field>

    <mat-form-field class="full-width" mat-focused *ngIf="store.has_order_occasion_tableside">
      <mat-label>Printouts per tableside order</mat-label>
      <input matInput #name placeholder="Tableside Count" formControlName="tableside_count">
    </mat-form-field>

    <mat-form-field class="full-width" mat-focused>
      <mat-label>Printouts per kitchen order</mat-label>
      <input matInput #name placeholder="Kitchen Count" formControlName="kitchen_count">
    </mat-form-field>

    <mat-checkbox formControlName="has_kitchen_format">Include kitchen format</mat-checkbox>

    <div class="full-width">
      <app-autocomplete-chips
      [entities]='tables'
      [selectedEntities]='selectedTables'
      [key]="'table_name'"
      (output)="setSelectedTables($event)"
      [controlName]="'Assign Tables'"></app-autocomplete-chips>
    </div>


    <div class="full-width">
      <app-autocomplete-chips
      [entities]='menuCategories'
      [selectedEntities]='selectedMenuCategories'
      [key]="'menu_category'"
      (output)="setSelectedMenuCategories($event)"
      [controlName]="'Assign Menu Categories'"></app-autocomplete-chips>
    </div>


    <div class="full-width">
      <app-autocomplete-chips
      [entities]='occasions'
      [selectedEntities]='selectedOccasion'
      [key]="'occasion'"
      (output)="setSelectedOccasion($event)"
      [controlName]="'Assign Occasions'"></app-autocomplete-chips>
    </div>




  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-raised-button
      color="primary"
      (click)="submit()"
      [disabled]=" isLoading || !form.valid " class="apply-btn-colr">Update</button>
  </mat-dialog-actions>
</form>
