<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="border" style="border-color: #e5e5e5;">
  <div class="order-container w-full overflow-x-auto">
    <table mat-table multiTemplateDataRows width="100%" [dataSource]="dataSource" class="delivery-table dark-bg-color">

      <ng-container matColumnDef="placed">
        <th mat-header-cell *matHeaderCellDef> Placed </th>
        <td mat-cell *matCellDef="let element">{{element.submitted_at}}</td>
      </ng-container>
      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef> Order </th>
        <td mat-cell *matCellDef="let element">{{element.order_counter}}</td>
      </ng-container>
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef> Source </th>
        <td mat-cell *matCellDef="let element">
          <p class="pill blue-pill" *ngIf="element.is_pos == 1">POS</p>
          <p class="pill blue-pill" *ngIf="element.is_pos == 0">ONLINE</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="occasion">
        <th mat-header-cell *matHeaderCellDef> Occasion </th>
        <td mat-cell *matCellDef="let element">
          <p class="pill grey-pill">{{element.occasion}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="servername">
        <th mat-header-cell *matHeaderCellDef> Server Name </th>
        <td mat-cell *matCellDef="let element">{{element.terminal_id}}</td>
      </ng-container>

      <ng-container matColumnDef="drivername">
        <th mat-header-cell *matHeaderCellDef> Driver Name </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.delivery_created; else showDriverName">
            {{element.dasher_doordash}}
          </ng-container>
          <ng-template #showDriverName>
            {{element.driver_name}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef> Payment </th>
        <td mat-cell *matCellDef="let element">{{element.payment_type}}</td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let element">{{element.total}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          <!-- First check for delivery_status_doordash -->
          <ng-container *ngIf="element.delivery_created; else showDeliveryStatus">
            <ng-container *ngIf="element.delivery_status_doordash; else showDeliveryStatus">
              <p class="doordash-pills pill red-pill" *ngIf="element.delivery_status_doordash == 'created'">DELIVERY
                CREATED</p>
              <p class="doordash-pills pill orange-pill"
                *ngIf="element.delivery_status_doordash == 'enroute_to_pickup'">
                DASHER CONFIRMED</p>
              <p class="doordash-pills pill d-green-pill"
                *ngIf="element.delivery_status_doordash == 'arrived_at_pickup'">
                DASHER ARRIVED</p>
              <p class="doordash-pills pill d-green-pill"
                *ngIf="element.delivery_status_doordash == 'enroute_to_dropoff'">ENROUTE TO DROPOFF</p>
              <p class="doordash-pills pill orange-pill" *ngIf="element.delivery_status_doordash == 'picked_up'">
                DELIVERY
                PICKED UP</p>
              <p class="doordash-pills pill orange-pill"
                *ngIf="element.delivery_status_doordash == 'arrived_at_dropoff'">
                DASHER DROP OFF</p>
              <p class="doordash-pills pill d-green-pill" *ngIf="element.delivery_status_doordash == 'delivered'">
                DELIVERED</p>
              <p class="doordash-pills pill red-pill" *ngIf="element.delivery_status_doordash == 'cancelled'">
                CANCELLED</p>
            </ng-container>
          </ng-container>
          <!-- Fallback to show delivery_status if delivery_status_doordash is not set -->
          <ng-template #showDeliveryStatus>
            <p class="pill red-pill" *ngIf="element.delivery_status == '1'">PENDING</p>
            <p class="pill orange-pill" *ngIf="element.delivery_status == '2'">EN ROUTE</p>
            <p class="pill d-green-pill" *ngIf="element.delivery_status == '3'">DELIVERED</p>
          </ng-template>
        </td>
      </ng-container>


      <ng-container matColumnDef="dispatch">
        <th mat-header-cell *matHeaderCellDef> Dispatch </th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button class="dispatch-btn"
            *ngIf="element.delivery_status == '1' && !element.delivery_created"
            style="background-color:rgb(8, 219, 8); color: white;"
            (click)="orderStatusUpdate(2, element);stopPropagation($event)">
            Dispatch
          </button>
          <button mat-flat-button class="delivered-btn"
            *ngIf="element.delivery_status == '2' && !element.delivery_created"
            style="background-color: orange; color: white;"
            (click)="orderStatusUpdate(3, element);stopPropagation($event)">
            Delivered
          </button>
          <button mat-flat-button class="done-btn" *ngIf="element.delivery_status == '3' && !element.delivery_created"
            style="background-color: lightgray; color: white;"
            (click)="OrderStatusUpdate_done(3, element);stopPropagation($event)">
            Done
          </button>

        </td>
      </ng-container>

      <ng-container matColumnDef="selectdriver">
        <th mat-header-cell *matHeaderCellDef> Select Driver </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="fill" class="driver-select-field"
            *ngIf="element.delivery_status == '1' && !element.delivery_created">
            <mat-label>Select Driver</mat-label>
            <mat-select placeholder="Select Driver" name="selectdriver" [(value)]="element.driver_name"
              (click)="stopPropagation($event)">
              <mat-option *ngFor="let driver of drivers" (click)="onDriverSelected(driver, element)"
                [value]="driver.first_name + ' ' + driver.last_name">
                {{ driver.first_name }} {{ driver.last_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>


      <!-- EXPANDED DETAIL -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <mat-tab-group class="order-detailsss" (selectedTabChange)="onTabSelectionChange($event, element.id)">
              <mat-tab label="Order Details">
                <div class="grid grid-cols-4 gap-2 p-2 bg-gray-100 dark-bg-color">
                  <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                    <app-order-details [orderWithPayment]="order"></app-order-details>
                  </div>
                  <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                    <app-order-item-breakup [orderWithPayment]="order"></app-order-item-breakup>
                  </div>
                  <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                    <app-order-payment-breakup [orderWithPayment]="order"></app-order-payment-breakup>
                  </div>
                  <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                    <!-- <button mat-flat-button color="primary" *ngIf="store.hasModule('POS')" class="action green"
                          (click)="edit()">Edit</button> -->
                    <button mat-flat-button color="primary" *ngIf="store.hasModule('POS')" class="check-out green"
                      (click)="checkout()">Checkout</button>
                    <!-- <button mat-flat-button color="primary" class="action" (click)="print(element)">Reprint</button>
                        <button mat-flat-button color="warn" class="action red" (click)="checkPOSPermission(element)">Cancel</button> -->
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="History">
                <div class="tab-content">
                  <div class="section-fluid">
                    <ng-template matTabContent>
                      <app-order-transaction-history [order]="order"></app-order-transaction-history>
                    </ng-template>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Track Order" *ngIf="element.delivery_created">
                <div class="tab-content">
                  <div class="section-fluid">
                    <ng-template matTabContent>
                      <div *ngIf="trackingUrl">
                        <iframe width="100%" height="1000px" frameborder="0" [src]="trackingUrl"></iframe>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-elements-row"
        [class.example-expanded-row]="expandedElement === element"
        [ngClass]="{'!bg-gray-100 blur-sm': expandedElement && expandedElement !== element}"
        (click)="expandedElement = expandedElement === element ? null : element" (click)="onRowClicked(element)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
  <mat-paginator [length]="dataCount" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" (page)="yourHandler($event)">
  </mat-paginator>
</div>