import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-digital-display',
  templateUrl: './digital-display.component.html',
  styleUrls: ['./digital-display.component.scss']
})
export class DigitalDisplayComponent implements OnInit {
  storeId: any;
  storeType: any;

  constructor() { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");
  }

}
