<mat-tab-group>
  <mat-tab label="OFFERS">
    <app-offer-list></app-offer-list>
  </mat-tab>
  <mat-tab label="REDEMPTIONS">
    <app-offer-redemption-list-message-table></app-offer-redemption-list-message-table>
  </mat-tab>
  </mat-tab-group>


