import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormUtils} from '../../_helpers/form-utils';
import {PosDisplayService} from 'src/app/_services/pos-display.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {StoreService} from '../../_services/store.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-screens',
  templateUrl: './screens.component.html',
  styleUrls: ['./screens.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ScreensComponent implements OnInit {

  columnsToDisplay = ['canvas', 'screen_id', 'content_type', 'screen_ur', 'star']
  dataSource = [];
  expandedElement;
  isLoading:boolean = true;

  constructor(
    private formUtils:FormUtils,
    private posDisplayService: PosDisplayService,
    private storeService:StoreService,
    public dialog: MatDialog
    ) { }

    ngOnInit() {
      let screens;
      this.storeService.current.subscribe(store => {
        if(store){
          this.posDisplayService.getAllScreens().subscribe(data => {
            this.dataSource = data.map(screens => {
              let sUrl = "d.labrador.ai/" + store.id + "/" + screens['canvas'] + "/" + screens['tv_id']
              screens['sUrl'] = sUrl
              console.log("-----------------------", screens['sUrl'])
              screens['form'] = new UntypedFormGroup({
                id: new UntypedFormControl(screens['id']),
                canvas: new UntypedFormControl(screens['canvas']),
                tv_id: new UntypedFormControl(screens['tv_id']),
                content_type: new UntypedFormControl(screens['content_type']),
                screen_ur: new UntypedFormControl(screens['sUrl']),
              });
              this.isLoading = false;
              screens['type'] = 'update';
              return screens;
            });
          });
        }
      });

    }

    updateCategory(category){
      if(category.id){
          this.posDisplayService.updateCategory(category).subscribe(data => {
              // alert('Category saved successfully.');
              this.alertPopup('update_category', 'Category saved successfully.');
              // this.ngOnInit();
          },
          error => {
            this.alertPopup('', 'Error while saving category.');
            // alert('Error while saving category.');
          });
      }
    }

    alertPopup(name,msg) {
      let dialogRef = this.dialog.open(AlertsMessagesComponent, {
        disableClose: true,
        width: '364px',
        minHeight: '20vh',
        data: {
          message: msg
        }
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          if (name == 'update_category') {
            this.ngOnInit();
          }
        }
      });
    }

  }
