
   <form class="fixActionRow">
   <h2 mat-dialog-title>
      {{ data.campaign.id ? 'Update Campaign' : 'Create New Campaign'}}
    </h2>
    <mat-dialog-content id="campaign-dialog">
      <form [formGroup]="form" (onSubmit)="create()" class="form">
      <div class="grid grid-cols-3 gap-2  xs:!grid-rows-3 xs:!grid-cols-none">
        <div class="flex flex-col gap-2">
          <mat-form-field appearance="fill" class="full-width" mat-focused>
            <mat-label>Campaign Name</mat-label>
            <input matInput #name placeholder="Campaign Name" formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="schedule_date"
                   (dateChange)="generateTimeRange($event.value); onDateTimeChange()" >
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker touchUi #picker [startAt]="start"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Time</mat-label>
            <mat-select formControlName="schedule_time" [(value)]="scheduleTime" (selectionChange)="onDateTimeChange()">
              <mat-option value="">Select time</mat-option>
              <mat-option *ngFor="let time of timeRange" [value]="time| date:'hh:mm a'">
                {{time | date:'hh:mm a'}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill"
                          hintLabel="$.05 for first 2 segments (320 characters). $.02 for each additional segment or image"
                          class="w-full" subscriptSizing="dynamic" >
            <mat-label>Message</mat-label>
            <textarea #text rows="6" matInput #message formControlName="message" (keyup)="updateCostPerUser($event.target.value)"></textarea>
            <mat-hint align="end">Characters {{message.value?.length || 0}} </mat-hint>
          </mat-form-field>
        </div>

        <div class="flex flex-col gap-2">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Select Module link</mat-label>
            <mat-select formControlName="module_link" #url (selectionChange)="updateCostPerPart(message.value)">
              <mat-option>Select module link</mat-option>
              <mat-option (click)="setCampaignType('menu')" [value]="links['menu']">
                Add menu link
              </mat-option>
              <mat-option (click)="setCampaignType('gift')" [value]="links['gift']">
                Add giftcard link
              </mat-option>
              <mat-option (click)="setCampaignType('offer')" [value]="links['offer']">
                Add offers link
              </mat-option>
              <mat-option (click)="setCampaignType('userSpecific')" [value]="links['userSpecific']">
                Add user specific link
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width" *ngIf="campaignType === 'userSpecific'">
            <mat-label>Redirect url</mat-label>
            <input matInput #name placeholder="https://example.com" formControlName="redirect_url">
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Custom List</mat-label>
            <mat-select formControlName="custom_list" (selectionChange)="updateCostPerPart(message.value)">
              <mat-option>Select</mat-option>
              <mat-option [value]="cl.id" *ngFor="let cl of campaignCustomList">{{cl.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Segmentation</mat-label>
            <mat-select formControlName="segment" (selectionChange)="updateCostPerPart(message.value)">
              <mat-option value="ALL">ALL</mat-option>
              <mat-option value="NOT_ORDERED_SINCE">Not Ordered Since</mat-option>
              <mat-option value="ORDERED_SINCE">Ordered Since</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Audience</mat-label>
            <mat-select formControlName="segment_audience" (selectionChange)="updateCostPerPart(message.value)">
              <mat-option value="7">Since Last Week</mat-option>
              <mat-option value="30">Since Last Month</mat-option>
              <mat-option value="90">Since Last 3 Months</mat-option>
              <mat-option value="365">Since Last Year</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="grid grid-cols-2 gap-1 w-full items-center text-sm">
            <p>Total User Reach</p>
            <p class="text-right">{{optedIn}}</p>

            <p>Cost Per User</p>
            <p class="text-right">{{costPerUser | currency:'USD'}}</p>

            <p>Total Campaign Cost</p>
            <p class="text-right">{{totalCost | currency:'USD'}}</p>
          </div>

        </div>
        
        <div class="flex flex-col gap-2">
          <app-phone-preview [text]="text.value"
                             [url]="url.value || form.get('module_link').value"
                             [image]="imageStr" [optOutText]="campaignSetting?.opt_out_message"></app-phone-preview>

        </div>
        

      </div>
      <app-gift-cards-upload-image *ngIf="!data.readonly"
                                       [maxFileSize]="1000000"
                                       [imageUrl]="imageStr"
                                       (imageStringEmitter)="setImageStr($event)"
                                       (imageFileEmitter)="setImageFile($event); updateCostPerPart(message.value)"></app-gift-cards-upload-image>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <div class="actions">
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <ng-container *ngIf="ext.id; then editBlock; else newBlock "></ng-container>
        <ng-template #editBlock>
          <button mat-flat-button class="apply-btn-colr"
                  color="primary"
                  appDebounceClick
                  (debounceClick)="create()"
                  [debounceTime]="700"
                  [disabled]="!form.valid || !canEdit() " >Update</button>
        </ng-template>
        <ng-template #newBlock>
          <button mat-flat-button class="apply-btn-colr"
                  color="primary"
                  appDebounceClick
                  (debounceClick)="create()"
                  [debounceTime]="700"
                  [disabled]="!form.valid">Schedule</button>
        </ng-template>
      </div>
    </mat-dialog-actions>
  </form>