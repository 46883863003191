import {Component, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AttendantSettingsService} from 'src/app/_services/attendant-settings.service';
import {EventEmitter} from 'events';
import {AttendantActions} from '../../_models/attendant_action';

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddActionComponent implements OnInit {

  form;
  isLoading = false;
  isConnectTo = false;
  store: number;
  AttendantActions: AttendantActions;
  @Output() refresh: EventEmitter;

  constructor(
    public dialogRef: MatDialogRef<AddActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private IVRSettings: AttendantSettingsService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();

    if(this.data.action.action_type == 'CONNECT_TO'){
      this.isConnectTo = true;
    } else {
      this.isConnectTo = false;
    }
  }

  buildForm() {
    let action = this.data.action;
    this.form = this.formBuilder.group({
      store: [this.data.store, [Validators.required]],
      gather_number: [action.gather_number, [Validators.required]],
      label: [action.label, [Validators.required]],
      greeting_type: [action.greeting_type, [Validators.required]],
      action_type: [action.action_type, [Validators.required]],
      greeting: [action.greeting, [Validators.required]],
      text: [action.text, [Validators.required]],
      after_action_statement: [action.after_action_statement, [Validators.required]],
      connect_to: [action.connect_to, [Validators.required]]
    });
    this.setValidation(action.action_type)
  }

  setValidation(action_type){
    const textBackRequired = ['gather_number', 'label', 'action_type',
      'after_action_statement', 'text', 'greeting_type']
    const connectToRequired = ['label', 'greeting_type', 'action_type',
      'gather_number', 'connect_to']

    let requiredFields;
    if (action_type === 'TEXT_BACK') {
      requiredFields = textBackRequired;
    } else {
      requiredFields = connectToRequired;
    }

    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key)
      if (requiredFields.includes(key)){
        control.setValidators([Validators.required]);
      } else {
        control.setValidators([]);
      }
      control.updateValueAndValidity();
    });
  }

  create(){
    this.isLoading = true;
    let payload = this.form.value;
    this.IVRSettings.updateIvrAction(payload).subscribe(data => {
      this.dialogRef.close(data)
    });
  }

  isConnectToPhone(value: String){
    if(value == 'CONNECT_TO'){
      this.isConnectTo = true;
    } else {
      this.isConnectTo = false;
    }
  }
}
