import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {OrdersWithPayment} from '../../_models/order';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentService} from '../../_services/payment.service';
import {AdjustPayment} from '../../_models/payment';
import {first, takeUntil} from 'rxjs/operators';
import {POSService} from 'src/app/_services/pos-service';
import {Store} from 'src/app/_models/store';
import {POSStreamService} from 'src/app/_services/pos-stream.service';
import {CardPaymentMqttComponent} from '../card-payment-mqtt/card-payment-mqtt.component';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-adjust-dialog',
  templateUrl: './adjust-dialog.component.html',
  styleUrls: ['./adjust-dialog.component.scss']
})
export class AdjustDialogComponent implements OnInit, OnDestroy {
  adjustForm: UntypedFormGroup;
  orderId: number;
  orderWithPayment: OrdersWithPayment;
  loading = false;
  submitted = false;
  error = '';
  store: Store;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<AdjustDialogComponent>,
    private posService: POSService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private posStreamService: POSStreamService
  ) {
    console.log(data);

    this.orderWithPayment = data.orderWithPayment;
    this.orderId = this.data.botOrder.id;
    this.store = this.data.store;
    console.log(this.store);

  }

  onCloseClick(): void {
    this.dialogRef.close([]);
  }

  ngOnInit() {
    this.adjustForm = this.formBuilder.group({
      newSubTotal: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  // convenience getter for easy access to form fields
  get f() { return this.adjustForm.value; }

  onSubmit() {
    console.log(this.data);
    this.submitted = true;

    // stop here if form is invalid
    if (this.adjustForm.invalid) {
      return;
    }
    this.loading = true;
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      const adjustAmount = +this.f.newSubTotal;
      const localTax = parseFloat((adjustAmount * (+this.store.local_tax_percent / 100)).toFixed(2));
      const stateTax = parseFloat((adjustAmount * (+this.store.state_tax_percent / 100)).toFixed(2));
      const totalAdjustmentAmount = +adjustAmount + +localTax + +stateTax;
      const terminalId = localStorage.getItem('selectedTerminal');
      const payload = {
        command: 'SALE',
        terminal_id: terminalId,
        order_id: `${this.data.botOrder.order_hash}-${Date.now()}`,
        amount: totalAdjustmentAmount.toFixed(2),
        action: 'ADJUST'
      };
      const dialogRef = this.dialog.open(CardPaymentMqttComponent, {
        width: '630px',
        data: payload,
        disableClose: true
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        if (result) {
          const payload = {
            order_hash: this.data.botOrder.order_hash,
            amount: totalAdjustmentAmount.toFixed(2),
            state_tax: stateTax,
            local_tax: localTax
          };
          this.posStreamService.adjustOrder(terminalId, payload).pipe(takeUntil(this.destroySubject)).subscribe(r => {
            this.loading = false;
            this.onCloseClick();
          });
        } else {
          this.error = 'Unable to process';
          this.loading = false;
        }
      });
    } else {
      let o;
      if (this.data.botOrder.is_pos && this.data.botOrder.source !== 'POS_MANUAL_CREDIT') {
        o = this.posService.posAdjustment(this.data.terminalId, this.data.botOrder.order_hash, this.f.newSubTotal);
      } else if (this.store.payment_gateway == 'STRIPE') {
        o = this.paymentService.adjustStripe(new AdjustPayment(this.orderId, this.f.newSubTotal));
      } else if (this.store.payment_gateway === 'CARDCONNECT') {
        o = this.paymentService.adjust(new AdjustPayment(this.orderId, this.f.newSubTotal));
      } else {
        o = this.paymentService.adjustGateway(new AdjustPayment(this.orderId, this.f.newSubTotal));
      }

      o.pipe(first()).pipe(takeUntil(this.destroySubject))
      .subscribe(
        data => {
          this.loading = false;
          console.log(JSON.stringify(data));
          this.onCloseClick();
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    }
  }
}

