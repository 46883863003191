import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {forkJoin} from 'rxjs';
import {Store} from 'src/app/_models/store';


export interface Entry {
  name: string;
}

@Component({
  selector: 'app-portion-category-item',
  templateUrl: './portion-category-item.component.html',
  styleUrls: ['./portion-category-item.component.scss']
})
export class PortionCategoryItemComponent implements OnInit {

  @Input() store: Store;

  @Input() quantity;

  portions;
  @Input() selectedPortion;

  @Input() showMenuItems:boolean = false;
  @Input() selectedMenuItems;
  menuItems;

  @Input() showCategories:boolean = false;
  @Input() selectedCateogries;
  categories;

  @Output() emitPortion = new EventEmitter<string>();
  @Output() emitMenuItems = new EventEmitter<string[]>();
  @Output() emitCategories = new EventEmitter<string[]>();
  @Output() emitQuantity = new EventEmitter<number>();

  formQuantity = Array.from(Array(10).keys()).map(n => n+1);

  constructor(private storeService: StoreService) { }

  ngOnInit() {
    if(this.store){
      let pobs = this.storeService.getStorePortions(this.store.id);
      let mobs = this.storeService.getStoreMenuItems(this.store.id);
      let cobs = this.storeService.getStoreCategories(this.store.id);

      forkJoin(pobs, mobs, cobs).subscribe(data => {
        this.portions = data[0];
        this.menuItems = data[1];
        this.categories = data[2];
      })
    }
  }

  public objectComparisonFunction = function( option, value ) : boolean {
    return option.id === value.id;
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  setQuantity(q){
    this.quantity = q;
    this.emitQuantity.emit(q);
  }

  setPortion(p){
    this.selectedPortion = p;
    this.emitPortion.emit(p);
  }

  setMenuItems(items){
    this.selectedMenuItems = items;
    this.emitMenuItems.emit(items)
  }

  setCategories(categories){
    this.selectedCateogries = categories;
    this.emitCategories.emit(categories);
  }
}
