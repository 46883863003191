import {Component, OnInit} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {

  reportUrl
  storeId: any;
  month: any;
  year: any;
  data: any;
  dataAvailable: boolean = false;
  isLoading: boolean = true;
  startDate: any;
  endDate: any;
  constructor(
    private route: ActivatedRoute,
    private storeService: StoreService,
    private location: Location
  ) { }

  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('store_id');
    this.startDate = this.route.snapshot.paramMap.get('month');
    this.endDate = this.route.snapshot.paramMap.get('year');
    console.log('Event Route Params', this.storeId, this.startDate, this.endDate);
    this.monthlyStatement();
  }

  monthlyStatement() {
    this.storeService.getStoreDetailedReportsHTML(this.storeId, this.startDate, this.endDate).subscribe(data => {
      console.log(data);
      this.data = data;
      this.dataAvailable = true;
      this.isLoading = false;
    });
  }

  goBack(): void {
    this.location.back();
  }

}
