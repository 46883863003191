<table mat-table multiTemplateDataRows [id]="menuGroup.id" [dataSource]="tempDs" class="border-1p-grey">
  <ng-container matColumnDef="name">
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="element.type">
        <div *ngSwitchCase="'menu_group'" class="heading">
          {{element.data.name}}
        </div>
        <div *ngSwitchCase="'menu_item'" class="sub-heading">
          {{element.data.name}}
          <span style="float: right">{{element.data.base_price | currency:'USD'}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="highlight">
      <div class="example-element-detail" *ngIf="element" [@detailExpand]="isExpanded(menuGroup.id, element)">
        <div [ngSwitch]="element.type" class="full-width">
          <div *ngSwitchCase="'menu_group'"></div>
          <div *ngSwitchCase="'menu_item'">
              <app-mod-list [store]="store" [menuItem]="element.data" *ngIf="isExpanded(menuGroup.id, element) == 'expanded'"></app-mod-list>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
    [class.example-expanded-row]="expandedElement.get(menuGroup.id) === element" (click)="expandRow(menuGroup.id, element)">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
