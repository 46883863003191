import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UploadService} from 'src/app/_services/upload.service';
import {forkJoin, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HostedMenuService} from 'src/app/_services/hosted-menu.service';
import {Store} from 'src/app/_models/store';

@Component({
  selector: 'app-visual-menu',
  templateUrl: './visual-menu.component.html',
  styleUrls: ['./visual-menu.component.sass']
})
export class VisualMenuComponent implements OnInit {

  @Input() store:Store;
  @ViewChild('file', {static: true}) file;
  public files: Set<File> = new Set()
  progress
  canBeClosed = false
  primaryButtonText = 'Upload'
  showCancelButton = true
  uploading = false
  uploadSuccessful = false

  uploadUrl = `${environment.apiUrl}/backend/v2/wizard/hosted_menu/`;
  hostedMenus = [];

  subs = new Subscription();

  constructor(public uploadService: UploadService, private hostedMenuService:HostedMenuService) { }

  ngOnInit() {
    this.hostedMenuService.getHostedMenu(this.store.id).subscribe(data => {
      this.hostedMenus = data
    })
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
    this.canBeClosed = true
  }

  closeDialog() {
    this.uploading = true;
    this.progress = this.uploadService.upload(this.uploadUrl, this.files, {"store_id": this.store.id});
    let allProgressObservables = [];

    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    this.primaryButtonText = 'Clear';
    this.canBeClosed = false;
    this.showCancelButton = false;
    forkJoin(allProgressObservables).subscribe(end => {
      this.uploadSuccessful = false;
      this.uploading = false;
      this.progress = null
      this.files.clear()
      this.ngOnInit()
    });
  }

  delete(menu){
    this.hostedMenuService.deleteHostedMenu(menu.id).subscribe(data => {
      console.log(data);
      this.ngOnInit()
    })
  }


}
