<form class="order-message-table-filters flex flex-row flex-wrap">
  <mat-form-field appearance="fill" class="search xs:!w-[97%]">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Search Orders</mat-label>
    <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input (input)="searchOrders($event)">
  </mat-form-field>

  <mat-form-field appearance="fill" class="start xs:!w-[47%]">
    <mat-label>Start Date</mat-label>
    <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker" placeholder="Choose a start date" (dateInput)="searchOrders($event)">
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #startPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill" class="end xs:!w-[48%]">
    <mat-label>End Date</mat-label>
    <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker" placeholder="Choose a end date" (dateInput)="searchOrders($event)" [min]="startDate">
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
  </mat-form-field>

  <button mat-flat-button class="clear create apply-btn-colr" (click)="reset()" color="primary">CLEAR</button>
  <div class="clear"></div>
</form>

<div class="spinner-container" *ngIf="dataSource.loading$ | async">
<mat-spinner></mat-spinner>
</div>

<div class="order-container  full-y-width  xs:!overflow-x-scroll  xs:m-auto">
<table mat-table width="100%" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Order </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }">  {{element.order_counter}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="redeemed_at">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Redeemed </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Placed"> {{ element.updated_at | date:'MMM dd EEE hh:mm a'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone_number">
    <mat-header-cell *matHeaderCellDef mat-sort-header> User </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="User">
      <span>*</span> {{element.phone_number.substring(element.phone_number.length - 5)}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="offer">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Offer </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Total">
        {{element.store_offer.offer_title}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="order_total">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Order Total </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Payment"> {{(+element.order_total + +element.discount) | currency:'USD'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="discount">
      <mat-header-cell *matHeaderCellDef>Discount</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Balance">
        {{element.discount | currency:'USD'}}
      </mat-cell>
  </ng-container>


  <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="undo(element)" [disabled]="element.is_reversed">Undo</button>
        </mat-menu>
      </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

</div>
<mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>



