import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {forkJoin, Subject} from 'rxjs';
import {BotOrder} from 'src/app/_models/order';
import {Store} from 'src/app/_models/store';
import {PaymentService} from 'src/app/_services/payment.service';
import {POSStreamService} from 'src/app/_services/pos-stream.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import { OrderService } from 'src/app/_services/order.service';

@Component({
  selector: 'app-transaction-dialog',
  templateUrl: './transaction-dialog.component.html',
  styleUrls: ['./transaction-dialog.component.scss']
})
export class TransactionDialogComponent implements OnInit, OnDestroy {

  loading;
  form: UntypedFormGroup;

  botOrder: BotOrder;
  store: Store;
  error;
  submitted;

  auths = [];
  refunds = [];
  voids = [];
  adjusts = [];
  pendings = [];

  transactions = [];
  pendingTransactions = [];

  posTransactions = [];
  splitTranscations = [];

  private destroySubject: Subject<void> = new Subject();

  constructor(private paymentService: PaymentService,
              private posStreamService: POSStreamService,
              public dialogRef: MatDialogRef<TransactionDialogComponent>,
              public dialog: MatDialog,private orderService: OrderService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.botOrder = data.botOrder;
      this.store = data.store;
    }

  ngOnInit() {
    if (this.botOrder.is_pos && this.data.botOrder.source !== 'POS_MANUAL_CREDIT') {
      const terminalId = localStorage.getItem('selectedTerminal');
      const payload = {order_id: this.botOrder.order_hash};
      if (this.data.botOrder.source === 'BBPOS') {
        this.posStreamService.transactions(terminalId, payload).pipe(takeUntil(this.destroySubject)).subscribe(txns => {
          this.posTransactions = txns;
        });
      } else {
        this.paymentService.getPOSHistory(this.botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(txns => {
          this.posTransactions = txns;
        });
      }
    } else if (this.store.payment_gateway === 'CARDCONNECT') {
      const authObs = this.paymentService.getCardConnectAuths(this.botOrder.id);
      const refundObs = this.paymentService.getCardConnectRefunds(this.botOrder.id);
      const voidObs = this.paymentService.getCardConnectVoids(this.botOrder.id);
      const adjustObs = this.paymentService.getCardConnectAdjust(this.botOrder.id);
      const pendingObs = this.paymentService.getCardConnectPending(this.botOrder.id);

      forkJoin([authObs, refundObs, voidObs, adjustObs, pendingObs]).pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.auths = result[0];
        this.refunds = result[1];
        this.voids = result[2];
        this.adjusts = result[3];
        this.pendings = result[4];
      });
    } else {
      const pgAllobs = this.paymentService.getPGTransactions(this.botOrder.id);
      const pgPendingObs = this.paymentService.refundPendingPGTransactions(this.botOrder.id);

      forkJoin([pgAllobs, pgPendingObs]).pipe(takeUntil(this.destroySubject)).subscribe(transactions => {
        this.transactions = transactions[0];
        this.pendingTransactions = transactions[1].filter(t => !t.is_complete);
      });
    }

    if (this.botOrder.payment_type === 'SPLIT') {
      this.paymentService.getPOSHistory(this.botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(txns => {
        this.posTransactions = txns;
      });
      let splitId=''
      this.orderService.getSplitTransactionsHistory(this.botOrder.order_hash, splitId).pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.splitTranscations = result;
      });
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  refundTransaction(record) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to refund this transction?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        this.paymentService.refundPGTransactions(record.transaction_id).pipe(takeUntil(this.destroySubject)).subscribe(response => {
          this.alertPopup('payment_srvc', `Transaction ${record.transaction_id} refunded successfully.`);
          // alert(`Transaction ${record.transaction_id} refunded successfully.`)
          // this.ngOnInit()
        }, error => {
          this.alertPopup('', 'There was an error while processing your request.');
          // alert("There was an error while processing your request.")
        });
      }
    });
    // if(confirm("Are you sure you want to refund this transction?")){
    //   this.paymentService.refundPGTransactions(record.transaction_id).subscribe(response => {
    //     this.alertPopup('payment_srvc',`Transaction ${record.transaction_id} refunded successfully.`);
    //     // alert(`Transaction ${record.transaction_id} refunded successfully.`)
    //     // this.ngOnInit()
    //   },error => {
    //     this.alertPopup('','There was an error while processing your request.');
    //     // alert("There was an error while processing your request.")
    //   })
    // }
  }

  cancelRefundTransaction(record) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to cancel this refund?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        this.paymentService.cancelRefundPendingPGTransactions(record.id).pipe(takeUntil(this.destroySubject)).subscribe(response => {
          this.alertPopup('', `Refund pending request cancelled.`);
          // alert(`Refund pending request cancelled.`)
          this.ngOnInit();
        }, error => {
          this.alertPopup('', 'There was an error while processing your request.');
          // alert("There was an error while processing your request.")
        });
      }
    });
    // if(confirm("Are you sure you want to cancel this refund?")){
    //   this.paymentService.cancelRefundPendingPGTransactions(record.id).subscribe(response => {
    //     this.alertPopup('',`Refund pending request cancelled.`);
    //     // alert(`Refund pending request cancelled.`)
    //     this.ngOnInit()
    //   },error => {
    //     this.alertPopup('','There was an error while processing your request.');
    //     // alert("There was an error while processing your request.")
    //   })
    // }
  }

  alertPopup(name, msg) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        if (name == 'payment_srvc') {
          this.ngOnInit();
        }
      }
    });
  }

}
