import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AttendantSettings} from '../_models/attendant_settings';
import {AttendantActions} from '../_models/attendant_action';

@Injectable({ providedIn: 'root' })
export class AttendantSettingsService {
  endPoint = environment.apiUrl;
  attendantEndpointV2 = `${this.endPoint}/backend/v2/ivr_setting/`;
  attendantActionEndpointV2 = `${this.endPoint}/backend/v2/ivr_setting/attendant/`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient){}

  getIvrSetting(id:String): Observable<AttendantSettings>{
    return this.http.get<AttendantSettings>(this.attendantEndpointV2 + id + "/")
  }

  updateIVRSetting(payload: string) {
    // return this.http.post<AttendantSettings>(this.attendantEndpointV2 ,payload, this.httpOptions);
    return this.http.post<AttendantSettings>(this.attendantEndpointV2 ,payload);
  }

  getIvrActions(storeId:String){
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    return this.http.get<AttendantActions[]>(this.attendantActionEndpointV2 + storeId + "/" , { params: params })
  }

  updateIvrAction(payload: string){
    return this.http.post<AttendantActions>(this.attendantActionEndpointV2 ,payload, this.httpOptions);
  }

  deleteIvrAction(actionID){
    return this.http.delete<any>(this.attendantActionEndpointV2 + actionID, this.httpOptions)
  }
}

