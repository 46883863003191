import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import {RestaurantImagesDialogComponent} from './restaurant-images-dialog/restaurant-images-dialog.component';
import {PeriodicElement} from '../d-d-screens/d-d-screens.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-restaurant-images',
  templateUrl: './restaurant-images.component.html',
  styleUrls: ['./restaurant-images.component.scss']
})
export class RestaurantImagesComponent implements OnInit {
  displayedColumns: string[] = ["name", "position", "image_width", "image", "action"];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  storeId: any;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private digitalDisplayService: DigitalDisplayService
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.restaurantListGet();
  }
  addRestaurant() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(RestaurantImagesDialogComponent, {
      width: isMobile ? '100vw' : '43vw',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        dialog: 1,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {

      if (result && result.yesSubmit == "YesSubmit") {
        this.restaurantListGet();
      }
    });
  }
  restaurantListGet() {
    this.digitalDisplayService.restaurantListGet(this.storeId)
      .subscribe((data: any) => {
        this.dataSource = data.data;
        this.dataSource.paginator = this.paginator;
      });
  }
  editrestaurant(element) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(RestaurantImagesDialogComponent, {
      width: isMobile ? '100vw' : '43vw',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        data: element,
        dialog: 2,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.restaurantListGet();
      }
    });
  }
  deleterestaurant(element) {
    let dialogRef = this.dialog.open(RestaurantImagesDialogComponent, {
      data: {
        dialog: 3,
        data: element,

      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.restaurantListGet();
      }
    });
  }
}
