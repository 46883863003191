<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="working-hours-popup fixActionRow">
    <h1 mat-dialog-title>Hours</h1>
    <div class="ml-6">
      <p class="message">Enter your service hours here.</p>
    </div>
    <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
      <div *ngIf="!isLoading">
      <div class="scroll-container">
        <div [formGroup]="form" class="p-1">
          <div fxLayout="row" class="table" [ngClass.lt-md]="{'table_mobile': true}" fxLayout.lt-md="row" cols="2"
          *ngFor="let day of days; let di = index">
            <ng-container [formGroup]="form.controls[di]">
              <div class="headerr table-row">{{day}}</div>
              <div fxFlex class="cell">
                <mat-form-field class="open-type">
                  <mat-label>From</mat-label>
                  <mat-select formControlName="open_type">
                    <mat-option [value]="ot.key" *ngFor="let ot of openType | keyvalue">
                      {{ot.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="clear"></div>
              </div>
    
              <div *ngIf="form.value[di].open_type === 'OPEN'">
                <div class="hours"
                formArrayName="hours"
                *ngFor="let hour_in_day of form.controls[di].get('hours').controls; let hi = index" >
                  <ng-container [formGroupName]="hi">
                    <div class="cell">
                      <mat-form-field class="working-hour-field">
                        <mat-label>From</mat-label>
                        <input matInput [ngxTimepicker]="timepicker" formControlName="from">
                        <ngx-material-timepicker #timepicker class="special-hour-picker">
                        </ngx-material-timepicker>
                      </mat-form-field>
    
                    </div>
    
                    <div class="cell">
                      <mat-form-field class="working-hour-field">
                        <mat-label>To</mat-label>
                        <input matInput [ngxTimepicker]="timepickerone" formControlName="to">
                        <ngx-material-timepicker #timepickerone class="special-hour-picker">
                        </ngx-material-timepicker>
                      </mat-form-field>
                    </div>
    
                    <div class="cell">
                      <button mat-icon-button type="button" (click)="delete(di, hi)" *ngIf="hi !== 0">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
    
                    <div class="cell" *ngIf="hi === 0">
                      <button mat-button color="primary" type="button" (click)="addHours(di)" class="w-28 -mt-5">Add hours</button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container></div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="error" class="form-row alert alert alert-danger ">
      Invalid edit request
    </div>

    <div class="spacer"></div>

    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button
        color="primary"
        class="primary-color primary-button apply-btn-colr">Apply</button>
    </mat-dialog-actions>
  </form>
