import {Injectable, OnDestroy} from '@angular/core';
// import {addPouchPlugin, getRxStoragePouch} from 'rxdb/plugins/pouchdb'
// import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';

import {
  ItemModifierMappingType,
  MenuGroupType,
  MenuItemGroupType,
  MenuItemType,
  ModifierGroupType,
  ModifierOptionType
} from '../_models/menu-v2';
// import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { menuGroupSchema} from '../_schema/menu-group.schema';
import {MenuGroupCollection} from '../_schema/menu-group.schema';
import {MenuItemGroupCollection, menuItemGroupSchema} from '../_schema/menu-item-group.schema';
import {ModifierGroupCollection, modifierGroupSchema} from '../_schema/modifier-options.schema';
import {ModifierOptionCollection, modifierOptionSchema} from '../_schema/modifier-group.schema';
import {MenuItemCollection, menuItemSchema} from '../_schema/menu-item.schema';
import {ItemModifierCollection, itemModifierSchema} from '../_schema/menu-item-modifier-mapping.schema';
import {addRxPlugin, createRxDatabase, RxCollection, RxDatabase} from 'rxdb';
import {BehaviorSubject, switchMap} from 'rxjs';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import {MenuIndexService} from './menu-index.service';
import {takeUntil} from 'rxjs/operators';
import {getRxStorageMemory} from 'rxdb/plugins/storage-memory';


// addRxPlugin(RxDBValidatePlugin);
// addRxPlugin(RxDBDevModePlugin);
// addPouchPlugin(require('pouchdb-adapter-memory'));
addRxPlugin(RxDBUpdatePlugin);


export type  MenuDatabaseCollections = {
  menu_group: MenuGroupCollection;
  menu_item_group: MenuItemGroupCollection;
  menu_item: MenuItemCollection;
  modifier_group: ModifierGroupCollection;
  modifier_option: ModifierOptionCollection;
  item_modifier_mapping: ItemModifierCollection;
}

@Injectable({
  providedIn: 'root',
})
export class RxdbService implements OnDestroy{
  private db: RxDatabase<MenuDatabaseCollections> | null = null;
  dbName = 'menu_db';
  // dbAdapter = getRxStoragePouch('memory');
  private isDbReady = new BehaviorSubject<boolean>(false);
  storeId = localStorage.getItem('currentStore')
  private unsubscribe = new BehaviorSubject<void>(null);
  menuGroupUpdate = new BehaviorSubject<boolean>(false);

  constructor(private mis: MenuIndexService) {
    this.createDatabase();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setDbReady(truth: boolean){
    this.isDbReady.next(truth);
    if(!this.db){
      this.init();
    }
  }

  getDbReady() {
    return this.isDbReady.asObservable();
  }

  async createDatabase() {
    try{
      // const exists = await removeRxDatabase(this.dbName, this.dbAdapter);
      // if (exists) {
      //   console.log('Database existed and has been removed.');
      // }

      // console.log('attempting to remove old database');
      // if(this.db){
      //   this.db.remove().then(() => console.log('db removed'));
      // }

      console.log('creating DB');
      this.db = await createRxDatabase<MenuDatabaseCollections>({
        name: this.dbName,
        storage: getRxStorageMemory({}),
        multiInstance: true,
        eventReduce: true,
        ignoreDuplicate: true,
      });
      await this.db.addCollections({
        menu_group: {
          schema: menuGroupSchema,
        },
        menu_item_group: {
          schema: menuItemGroupSchema,
          options: {
            searchable: true,
          }
        },
        menu_item: {
          schema: menuItemSchema
        },
        modifier_group: {
          schema: modifierGroupSchema
        },
        modifier_option: {
          schema: modifierOptionSchema
        },
        item_modifier_mapping: {
          schema: itemModifierSchema
        }
      });
      console.log(this.db);
    }catch (error){
      console.log(error);
      if (error && error.code === 'DB8') {
        console.warn('Database already exists. Skipping creation.');
        this.db = await createRxDatabase({
          name: 'menu_db',
          storage: getRxStorageMemory(),
          multiInstance: true,
          eventReduce: true,
          ignoreDuplicate: true,
        });
      } else {
        throw error;
      }
    }
  }

  get menuGroupCollections(): RxCollection<MenuGroupType> {
    return this.db!.menu_group as RxCollection<MenuGroupType>;
  }

  get menuItemGroupCollections(): RxCollection<MenuItemGroupType> {
    return this.db!.menu_item_group as RxCollection<MenuItemGroupType>;
  }

  get menuItemCollections(): RxCollection<MenuItemType> {
    return this.db!.menu_item as RxCollection<MenuItemType>;
  }

  get modifierGroupCollections(): RxCollection<ModifierGroupType> {
    return this.db!.modifier_group as RxCollection<ModifierGroupType>;
  }

  get modifierOptionCollections(): RxCollection<ModifierOptionType> {
    return this.db!.modifier_option as RxCollection<ModifierOptionType>;
  }

  get itemModifierCollections(): RxCollection<ItemModifierMappingType> {
    return this.db!.item_modifier_mapping as RxCollection<ItemModifierMappingType>;
  }

  async init() {
    await this.createDatabase();
  }

  updateMenuItems(orderId: string) {
    console.log("updating menu items");
    return this.mis.getOrderMenuItems(orderId).pipe(
      switchMap(data => {
        return this.menuItemCollections.bulkUpsert(data);
      })
    );
  }

  refreshMenuGroup(storeId: string){
    return this.mis.getMenuGroup(storeId).pipe(
      switchMap(migs => {
        return Promise.all(migs.map(mig => this.menuGroupCollections.upsert(mig)))
      })
    )
  }

  refreshMenuItemGroup(storeId: string){
    return this.mis.getMenuItemGroup(storeId).pipe(
      switchMap(migs => {
        return Promise.all(migs.map(mig => this.menuItemGroupCollections.upsert(mig)))
      })
    )
  }

  refreshItemModifierMapping(storeId: string){
    return this.mis.getMenuItemModifierMapping(storeId).pipe(
      switchMap(migs => {
        return Promise.all(migs.map(mig => this.itemModifierCollections.upsert(mig)))
      })
    )
  }

  refreshMenuItem(storeId: string){
    return this.mis.getMenuItems(storeId).pipe(
      switchMap(mis => {
        return this.menuItemCollections.bulkUpsert(mis);
      })
    )
  }


}
