<div class="mobile-raw">
  <form class="order-message-table-filters">
    <mat-form-field appearance="fill" class="search">
      <mat-icon matSuffix>search</mat-icon>
      <mat-label>Search Orders</mat-label>
      <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input (input)="loadOpenOrders()">
    </mat-form-field>
    <mat-form-field appearance="fill" class="start">
      <mat-label>Start Date</mat-label>
      <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker"
        placeholder="Choose a start date" (dateInput)="loadOpenOrders()">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="end">
      <mat-label>End Date</mat-label>
      <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker" placeholder="Choose a end date"
        (dateInput)="loadOpenOrders()" [min]="startDate">
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
    </mat-form-field>
    <button mat-flat-button class="clear" type="submit" (click)="loadOpenOrders()" color="primary">SEARCH</button>
    <button mat-flat-button class="clear clear-gap" type="reset" (click)="reset()" color="primary">CLEAR</button>
    <div class="clear"></div>
  </form>
  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <div class="order-container">
    <div class="table-mobile-scroll">
      <table mat-table multiTemplateDataRows width="100%" matSort matSortActive="order_counter" matSortDirection="desc"
        matSortDisableClear [dataSource]="dataSource">
        <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Placed </th>
      <td mat-cell *matCellDef="let element" data-label="Placed"> {{ element.created_at }} </td>
    </ng-container>

        <ng-container matColumnDef="order_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element" data-label="Name"> {{ element.order_name }} </td>
        </ng-container>

        <ng-container matColumnDef="placed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
          <td mat-cell *matCellDef="let element" data-label="SubmitDate"> {{ element.submitted_at | date: 'hh:mm a MM/dd/yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="order_counter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Order #</th>
          <td mat-cell *matCellDef="let element" data-label="Order"> {{element.order_counter}} </td>
        </ng-container>

        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
          <td mat-cell *matCellDef="let element" data-label="Source">
            <p class="pill blue-pill" *ngIf="!element.is_pos && element.occasion == 'TABLESIDE'">
              {{element.dine_in_table_number}}</p>
            <p class="pill blue-pill" *ngIf="!element.is_pos && element.occasion != 'TABLESIDE'">Online</p>
            <p class="pill blue-pill" *ngIf="element.is_pos">POS</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="cashier_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Server Name</th>
          <td mat-cell *matCellDef="let element" data-label="Server Name"> {{element.cashier_id}} </td>
        </ng-container>

        <ng-container matColumnDef="terminal_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Station ID</th>
          <td mat-cell *matCellDef="let element" data-label="Station ID"> {{element.terminal_id}} </td>
        </ng-container>

        <ng-container matColumnDef="occasion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Occasion</th>
          <td mat-cell *matCellDef="let element" data-label="Occasion">
            <p class="pill grey-pill">{{element.occasion}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="payment_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment</th>
          <td mat-cell *matCellDef="let element" data-label="Payment">
            <p class="pill grey-pill" *ngIf="element.payment_type">{{element.payment_type}}</p>
            <p class="pill red-pill" *ngIf="!element.payment_type">UNPAID</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
          <td mat-cell *matCellDef="let element" data-label="Total"> {{element.total | currency:'USD'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element" data-label="Status">
            <p class="pill blue-pill-filled">Open</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="table_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm> Table ID </th>
          <td mat-cell *matCellDef="let element" data-label="Order" fxHide.lt-sm> {{element.table_id}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item (click)="print(element)">Reprint Order</button>
              <!-- <button mat-menu-item (click)="cancel(element)">Cancel Order</button> -->
              <button mat-menu-item (click)="checkPOSPermission(element)">Cancel order</button>
              <button *ngIf="element.occasion === 'TABLESIDE'" mat-menu-item (click)="closeTab(element)">Close
                Tab</button>
              <button mat-menu-item *ngIf="element.is_paid_by_split" (click)="openSplitPayment(element)">Split
                Payment</button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="highlight">
            <div class="example-element-detail misc-detail" *ngIf="element"
              [@detailExpand]="element.id === expandedRowId ? 'expanded' : 'collapsed'">
              <div class="row">
                <p class="heading">Add miscellaneous Items to order {{element.order_counter}} </p>
                <div *ngFor="let fg of selectedOrderMiscFromGroup.controls;let i = index;">
                  <ng-container [formGroup]="fg">
                    <mat-form-field class="width-10">
                      <mat-label>Quantity</mat-label>
                      <input formControlName="quantity" matInput placeholder="Qty" type="number">
                    </mat-form-field>

                    <mat-form-field class="width-30">
                      <mat-label>Name</mat-label>
                      <input formControlName="item_name" matInput placeholder="Name">
                    </mat-form-field>

                    <mat-form-field class="width-20">
                      <mat-label>Price</mat-label>
                      <input formControlName="price" matInput placeholder="Price">
                    </mat-form-field>
                    <mat-checkbox formControlName="is_taxable">Taxable</mat-checkbox>
                    <div class="form-ops">
                      <button mat-icon-button (click)="delete(i, fg.value.id); false">
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </div>
                    <div style="clear: both"></div>
                  </ng-container>
                </div>
                <div class="action">
                  <button class="pull-left" mat-stroked-button color="primary" type="button" (click)="add(); false">Add
                    Item</button>
                  <button class="pull-right bg" mat-stroked-button color="primary"
                    (click)="submitMisc(element)">Update</button>

                  <button class="pull-right" mat-stroked-button type="button"
                    (click)="expandedRowId = null;ngOnInit(); false">Cancel</button>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"
          [ngClass]="{ 'highlight-order-row': row?.id === selectedRow?.id }"
          [class.example-expanded-row]="expandedRowId === row.id"
          (click)="expandedRowId = expandedRowId === row.id ? null : row.id">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
  </div>
</div>
