<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<h1 mat-dialog-title *ngIf="data.category == 'gift-image'">Upload default image for gift card</h1>
<h1 mat-dialog-title *ngIf="data.category == 'category'">Upload default image for category</h1>
<h1 mat-dialog-title *ngIf="data.category == 'sub-category'">Upload default image for sub-category</h1>
<div mat-dialog-content>
  <div class="flex flex-row bg-gray-100 border rounded-md border-gray-300 p-3 items-center justify-between"
    *ngIf="data.category == 'gift-image'">
    <span style="margin-right:14px">Select Image</span>
    <input #imageInput type="file" accept="image/*" (change)="onImageSelected($event)">
  </div>
  <div class="flex flex-row bg-gray-100 border rounded-md border-gray-300 p-3 items-center justify-between"
    *ngIf="data.category != 'gift-image'">
    <span style="margin-right:14px">Select Image</span>
    <input #imageInput type="file" accept="image/*" (change)="onItemImageSelected($event)">
  </div>
  <div *ngIf="imagePreview && data.category != 'gift-image'" class="image-preview flex justify-center">
    <img [src]="imagePreview" alt="Image Preview" class="preview-img" />
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close style="margin-right: 10px;">Cancel</button>
  <button mat-flat-button color="primary" type="submit" (click)="giftImageUpload()"
    class="apply-btn-colr" *ngIf="data.category == 'gift-image'">Submit</button>
  <button mat-flat-button color="primary" type="submit" (click)="itemImageUpload()"
    class="apply-btn-colr" *ngIf="data.category != 'gift-image'">Submit</button>
</div>