import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {environment} from 'src/environments/environment';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {Store} from 'src/app/_models/store';
import {CropperComponent} from 'angular-cropperjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-menu-category-image',
  templateUrl: './menu-category-image.component.html',
  styleUrls: ['./menu-category-image.component.scss']
})
export class MenuCategoryImageComponent implements OnInit, OnDestroy {


  @Input() menuItemGroup;
  @Input() store:Store;
  public uploader: FileUploader;
  public hasDragOver = false;
  @Input() public editmode = true;
  @Input() public url = '';
  @Output() public urlChange = new EventEmitter();

  @ViewChild('cropper',{static: false})
  cropper:CropperComponent;
  imgUrl = null;
  cropperConfig = {
    strict: true,
    aspectRatio : 16 / 9,
    dragMode : 'move',
    background : true,
    movable: true,
    rotatable : false,
    scalable: false,
    zoomable: false,
    viewMode: 1,
    // height: 530,
    minWidth:500,
    minHeight: 500,
    // maxWidth:500,
    // maxHeight: 500,
    autoCropArea: 0,
    checkImageOrigin : false,
    checkCrossOrigin: false,
    cropMove: this.cropMoved.bind(this),
    crop(event){
      setTimeout(()=>{
        let canvas_img = window.document.querySelector('img.cropper-hide');
        let src = canvas_img.getAttribute('src');
        canvas_img.setAttribute('crossorigin', 'anonymous')
        canvas_img.setAttribute('src', src)
     }, 100)
    },
    ready: () => {
      const imageData = this.cropper.cropper.getImageData();
      if (imageData) {
        const naturalWidth = imageData.naturalWidth;
        const naturalHeight = imageData.naturalHeight;

        // Calculate aspect ratio to cover the full image
        const fullImageAspectRatio = naturalWidth / naturalHeight;

        // Set the aspect ratio to cover the full image
        this.cropper.cropper.setAspectRatio(fullImageAspectRatio);
      }
    }
  };

  imageUrl = null;
  imageBlob;
  inProgress = false;

  uploadUrl;

  constructor(private menuService:MenuServiceV2,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MenuCategoryImageComponent>,
    ) {
      this.menuItemGroup = this.data.menuItemGroup;
      if (this.menuItemGroup) {
        this.uploadUrl = `${environment.apiUrl}/backend/business/store/${this.menuItemGroup.store}/menu_management_v2/menu_item_group/${this.menuItemGroup.id}/image/`;
        this.uploader = new FileUploader({
          url: this.uploadUrl,
          disableMultipart: false,
          autoUpload: true,
          authToken: JSON.parse(localStorage.getItem('currentUser')),
        });
        this.uploader.onAfterAddingFile = (file) => {
          file.withCredentials = false;
          this.inProgress = true;
        };
        this.uploader.onCompleteAll = () => {
          this.inProgress = false;
        };
        this.uploader.response.subscribe(response => {
          this.imageUrl = JSON.parse(response).image;
          this.uploader.progress = 0;
          this.urlChange.emit(true);
        });
        this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; this.inProgress = true};
        this.uploader.onCompleteAll = () => {this.inProgress = false}
        this.uploader.response.subscribe(response => {
          this.imageUrl = JSON.parse(response).image;
          this.uploader.progress = 0;
          this.urlChange.emit(true)
        });
      }
    }

  cropMoved(data){
    this.cropper.cropper.getCroppedCanvas().toBlob((blob) => {
      this.imageBlob = blob;
    });
  }


  public fileOver(e: any): void {
    this.hasDragOver = e;
  }


  ngOnInit(){}

  delete(){
    this.menuService.deleteImage(this.store.id, this.menuItemGroup.id).subscribe(data => {
      this.urlChange.emit(true)
    })
  }

  apply(){
    this.inProgress = true;
    this.cropper.cropper.getCroppedCanvas().toBlob((blob) => {
      this.uploader = new FileUploader({
        url: `${environment.apiUrl}/backend/business/store/${this.menuItemGroup.store}/menu_management_v2/menu_item_group/${this.menuItemGroup.id}/image_resized/`,
        disableMultipart: false,
        autoUpload: true,
        authToken: JSON.parse(localStorage.getItem('currentUser')).token,
      });

      const date: number = new Date().getTime();
      const file = new File([blob], 'file', {'type': 'image/png', lastModified: date})

      const fileItem = new FileItem(this.uploader, file, {authToken: JSON.parse(localStorage.getItem('currentUser')).token});
      fileItem.withCredentials = false;

      this.uploader.queue.push(fileItem);
      this.uploader.uploadAll()
      this.uploader.response.subscribe(response => {
        this.inProgress = false;
        this.dialogRef.close(true)
      })
    })

  }

  ngOnDestroy(){
    this.imageBlob = undefined;
    this.imageUrl = '';
  }
}
