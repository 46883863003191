import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { pipe, Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { IOrderItem } from 'src/app/_interfaces/order';
import { MenuGroup, MenuGroupItem, MenuItem, ModifierGroup, ModifierOption, Order, OrdersWithPayment, } from 'src/app/_models/order';
import { MenuItemGroup, Store } from 'src/app/_models/store';
import { CurrentOrderService } from 'src/app/_services/current-order.service';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { OrderService } from 'src/app/_services/order.service';
import { POSMenuTabChangeService } from 'src/app/_services/pos-menu-tab-change.service';
import { POSOrderTabChangeService } from 'src/app/_services/pos-order-tab-change.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { StoreService } from 'src/app/_services/store.service';
import { PinConfirmComponent } from '../pin-confirm/pin-confirm.component';
import { PermissionService } from '../../_services/permission.service';
import { Permission } from '../../_models/permission';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatRadioChange } from '@angular/material/radio';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { POSService } from 'src/app/_services/pos-service';
import { EditModifierDialogComponent } from '../edit-modifier-dialog/edit-modifier-dialog.component';
import { ActivateGiftCardDialogComponent } from '../activate-gift-card-dialog/activate-gift-card-dialog.component';
import { AddGiftCardValueComponent } from '../add-gift-card-value/add-gift-card-value.component';
import { PurchaseGiftCardDialogComponent } from '../purchase-gift-card-dialog/purchase-gift-card-dialog.component';
import { OrderSubCatGridComponent } from '../order-sub-cat-grid/order-sub-cat-grid.component';
import { OrderItemGridComponent } from '../order-item-grid/order-item-grid.component';

@Component({
  selector: 'app-pos-menu',
  templateUrl: './pos-menu.component.html',
  styleUrls: ['./pos-menu.component.scss'],
})
export class PosMenuComponent implements OnInit, OnDestroy {
  menuContainer: any;
  renderer: any;
  @Output() onInitEventQuickEntry = new EventEmitter();
  browser: string;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateMaxHeight();
  }
  pressTimer: any;
  pressHoldDuration = 1000;
  @ViewChild(OrderSubCatGridComponent) OrderSubCatGridComponent!: OrderSubCatGridComponent;
  @ViewChild(OrderItemGridComponent) OrderItemGridComponent!: OrderItemGridComponent;
  constructor(
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    private menuService: MenuServiceV2,
    private storeService: StoreService,
    private ref: ChangeDetectorRef,
    private posOrderTabChangeService: POSOrderTabChangeService,
    private posMenuTabChangeService: POSMenuTabChangeService,
    private posSummaryToggle: POSSummaryToggleService,
    private permissionService: PermissionService,
    private currentOrderService: CurrentOrderService,
    public dialog: MatDialog,
    public posService: POSService,
  ) {
    // this.menuHeight = this.posService.posMenuHeight.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
    //   if (data == 'full-screen') {
    //     this.setDynamicHeight();
    //     this.maxHeightMenuItem = String(window.innerHeight - 240) + 'px';
    //     this.ref.detectChanges();
    //   } else if (data == 'close-screen') {
    //     this.setDynamicHeight();
    //     this.maxHeightMenuItem = String(window.innerHeight - 490) + 'px';
    //     this.ref.detectChanges();
    //   }
    // });
  }

  form: UntypedFormGroup;
  orderHash: string;
  currentMenuTitle;
  stepNumber;
  menuHeight: Subscription;
  loading;
  public menuGroups$: MenuGroup[] = [];
  public menuItemGroups$: MenuItemGroup[] = [];
  public menuItems$: MenuItem[] = [];
  public modifierGroup$: ModifierGroup;
  public filteredOrdersGroups: string[] = [];
  public filteredOrders: { [key: string]: MenuGroupItem[] };
  public modifiersLoading = false;
  public modifierQuantities = {};
  public addButtonWidth: number;
  public editItem: IOrderItem;
  public totalPrice = 0;
  public quantities = 1;
  public modifierDirections = {};
  public unsubscribe: Subject<null> = new Subject<null>();
  public special_request = new UntypedFormControl();
  public selectedItemQuantity = 1;
  public hideTab = false;
  store: Store;
  previousMenuTitle: string;
  @Input() validUserData = null;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectTab: EventEmitter<number> = new EventEmitter<number>();
  @Output() parserMenuItems: EventEmitter<any> = new EventEmitter<any>();

  @Input() drawerOpened = false;
  @Input() orderWithPayment: OrdersWithPayment;

  editMode = false;
  orderItem: Order;

  searchTerm: string;
  menuGroups;
  selectedMenuGroup;
  selectedMenuGroupNotSub;
  selectedMenuGroupSub;

  items;
  selectedItem;

  modifierGroups;
  modifierDisplay
  selectedModifiers: ModifierOption[] = [];
  public halfModOptions = { H1: 'LEFT', ADD: 'WHOLE', H2: 'RIGHT' };

  selectedOrderMiscFromGroup;
  menuTabChangeSub: Subscription;
  currentOrderSub: Subscription;
  permission: Permission;
  pinPermission: Permission;
  breakpoint: number;
  heightBox: any;
  subCattoItem: boolean = false;
  searchStart: boolean = false;
  searchingSub: Subscription;
  private searchModelChanged: Subject<string[]> = new Subject<string[]>();
  qty: number;

  maxHeightMenuItem: number = 0;
  buttonHeight: number = 0; // ✅ Default button height
  itemsPerPage: number = 0;
  currentPage: number = 0;
  totalPages: number = 0;
  displayedCategories: any[] = [];
  totalPagesArray: number[] = [];
  touchStartX: number = 0;
  touchEndX: number = 0;
  mouseStartX: number = 0;
  mouseEndX: number = 0;
  isMouseDown: boolean = false;
  SWIPE_THRESHOLD: number = 50;
  @ViewChild('swipeContainer', { static: false }) swipeContainer!: ElementRef;
  @ViewChild('modSwipeContainer', { static: false }) modSwipeContainer!: ElementRef;
  selectedModifierGroup: any;
  previousSelectedModifierGroups: Set<any> = new Set();


  maxModHeight: number = 0;
  buttonModHeight: number = 0; // ✅ Default button height
  modPerPage: number = 0;
  currentModPage: number = 0;
  totalModPages: number = 0;
  displayedModifiers: any[] = [];
  totalModPagesArray: number[] = [];
  buildResetStep: Subscription;

  selectedTags: { [key: number]: string } = {}; // Store selected tags per modifier
  selectedModifier: ModifierOption | null = null;
  lastSelectedTag: string | null = null;
  posUiUpdateFeature: boolean = false;
  modtagEnableFeature: boolean = false;
  smryOpen: boolean = false;
  smrySub: Subscription;
  isValid: boolean = false;


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.orderWithPayment) {
      this.orderWithPayment = changes.orderWithPayment.currentValue;
      // this.stepper.selectedIndex = 0;
      // this.posOrderTabChangeService.changeTab(0);
      this.ref.detectChanges();
      // this.loadMisc(this.orderWithPayment.bot_order.id);
    }
  }

  ngOnInit() {
    this.setDynamicHeight();
    this.maxHeightMenuItem = window.innerHeight - 350;
    this.browser = localStorage.getItem('browser');
    this.currentOrderSub = this.currentOrderService.current.pipe(takeUntil(this.unsubscribe)).subscribe((orderWithPayment) => {
      this.orderWithPayment = orderWithPayment;
    });
    this.loading = true;
    this.selectedModifiers = [];
    this.selectedItem = null;
    this.storeService.current.pipe(takeUntil(this.unsubscribe)).subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        this.loadMenuGroups(this.store.id, '', false);
        this.posUiUpdateFeature = this.store.feature_flag['menu_button_dynamic_height'];
        this.modtagEnableFeature = this.store.feature_flag['modifier_tag_enablement'];
      }
    });

    this.permissionService.pinCurrentOperator.pipe(takeUntil(this.unsubscribe)).subscribe((permission) => {
      this.permission = permission;
    });

    this.orderService.editOrderSubject.pipe(takeUntil(this.unsubscribe)).subscribe((item) => {
      this.loading = true;
      this.editMode = true;
      this.orderItem = item;
      // this.totalPrice = item.getItemTotal();
      this.menuService
        .getStoreMenuItemGroup(this.store.id, item.menu_item.menu_item_group)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((menuItemGroup) => {
          this.menuService
            .getMenuItemsForMenuGroup(this.store.id, menuItemGroup.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((items) => {

              if (items && items.length > 0) {
                this.items = items;
                this.selectedItem = item.menu_item;
                this.selectedItemQuantity = item.quantity;
                this.selectedModifiers = [];
                item.modifiers.forEach((m) => {
                  this.selectedModifiers.push(m.modifier);
                  this.modifierQuantities[m.modifier.id] = m.quantity;
                  this.modifierDirections[m.modifier.id] = m.mod_type;
                  this.selectedTags[m.modifier.id] = m.mod_tag;
                  if (!this.previousSelectedModifierGroups.has(m.modifier.modifier_group)) {
                    this.previousSelectedModifierGroups.add(m.modifier.modifier_group);
                  }
                });
                this.menuService
                  .getModifiersForMenuItem(this.store.id, item.menu_item.id)
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((modifiers: any) => {
                    this.modifierGroups = modifiers.modifier_groups;
                    this.selectTab.emit(2);
                    this.stepper.selectedIndex = 2;
                    this.calculateTotal();

                    const firstSelectedGroup = this.modifierGroups.find(group =>
                      this.previousSelectedModifierGroups.has(group.id)
                    );
                    if (firstSelectedGroup) {
                      this.selectedModifierGroup = firstSelectedGroup;
                    }
                    this.loading = false;
                  });
                this.loading = false;
              } else {
                this.snackBar.open('Currently Unavilable', 'OK',
                  {
                    duration: 2000,
                    verticalPosition: 'top',
                  });
              }
            });
        });
    });

    this.orderService.editMiscSubject.pipe(takeUntil(this.unsubscribe)).subscribe((item) => {
      this.loading = true;
      this.stepper.selectedIndex = 4;
      // this.loadMisc(item.bot_order);
      this.loadMisc();
    });

    this.orderService.removeItemUpdateMenuTab.pipe(takeUntil(this.unsubscribe)).subscribe((update: any) => {
      if (update) {
        if (this.stepper) {
          this.stepper.selectedIndex = 0;
        }
      }
    })

    this.selectedOrderMiscFromGroup = new UntypedFormArray([]);

    this.form = new UntypedFormGroup({
      search: new UntypedFormControl(null),
      menuGroupId: new UntypedFormControl(null, [Validators.required]),
      menuGroupItemId: new UntypedFormControl(null, [Validators.required]),
      menuItemId: new UntypedFormControl(null, [Validators.required]),
      special_request: new UntypedFormControl(null),
      modifiers: new UntypedFormControl([]),
    });

    this.menuTabChangeSub = this.posMenuTabChangeService.current.pipe(takeUntil(this.unsubscribe)).subscribe(
      (menuTabIndex) => {
        if (this.stepper) {
          this.stepper.selectedIndex = menuTabIndex;
        }
      }
    );

    this.searchingSub = this.searchModelChanged.pipe(debounceTime(500),).subscribe((data: any) => {
      this.loadMenuGroups(this.store.id, data, true);
    });

    this.buildResetStep = this.orderService.resetStep.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      if (data) {
        if (this.stepper.selectedIndex !== 0) {
          this.stepper.selectedIndex = 0;
          this.orderService.resetStep.next(false);
        }
      }
    });

    this.smrySub = this.posSummaryToggle.current.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      if (data === 'OPEN') {
        this.smryOpen = true;
      } else {
        this.smryOpen = false
      }
    });


  }

  ngAfterViewInit() {
    if (this.posUiUpdateFeature) {
      const container = this.swipeContainer.nativeElement;
      container.addEventListener('touchstart', this.onTouchStart.bind(this), false);
      container.addEventListener('touchend', this.onTouchEnd.bind(this), false);


      const cotainer = this.modSwipeContainer.nativeElement;
      container.addEventListener('touchstart', this.onTouchStart.bind(this), false);
      container.addEventListener('touchend', this.onTouchEnd.bind(this), false);

      /* SWIPE FOR NON TOUCH DEVICE */
      // container.addEventListener('mousedown', this.onMouseDown.bind(this), false);
      // container.addEventListener('mousemove', this.onMouseMove.bind(this), false);
      // container.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    }
  }

  ngOnDestroy() {

    this.unsubscribe.next();
    this.menuTabChangeSub.unsubscribe();
    this.currentOrderSub.unsubscribe();
    if (this.searchingSub) {
      this.searchingSub.unsubscribe();
    }
    if (this.buildResetStep) {
      this.buildResetStep.unsubscribe();
    }
    if (this.smrySub) {
      this.smrySub.unsubscribe();
    }
  }
  ngDoCheck() {
    if (this.stepper?.selectedIndex > 0) {
      this.onInitEventQuickEntry.emit(false)
    }
    else if (this.stepper?.selectedIndex == 0) {
      this.onInitEventQuickEntry.emit(true);
    }
  }

  onInputFieldFocus() {
    this.stepper.selectedIndex = 3;
    this.searchStart = true;

    /* Call Function From Parent Component */
    // setTimeout(() => {
    //   this.OrderItemGridComponent.isStoreUndef();
    // }, 1000);
  }

  searchItems(filter) {
    this.orderService.disableInputButton = true;
    this.searchModelChanged.next(filter);
    // this.loadMenuGroups(this.store.id, filter);
  }

  loadMenuGroups(storeId, filter, search) {
    this.menuService
      .getStoreMenuLazy(storeId, filter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (menuGroups: any) => {
          this.menuGroups = menuGroups.menu_items.map((menuGroup) => {
            const subGroups = menuGroup.list.map((subgroup) => {
              return {
                name: subgroup.name,
                image: subgroup.image,
                image_icon: subgroup.image_icon,
                items: subgroup.list,
              };
            });
            return {
              name: menuGroup.name,
              image: menuGroup.image,
              image_icon: menuGroup.image_icon,
              subGroups,
              textColor: menuGroup.text_color,
              color: menuGroup.color
            };
          });

          if (!search && this.posUiUpdateFeature) {
            this.menuGroups.push(
              {
                name: "Add Misc.",
                image: null,
                image_icon: null,
                items: [],
                textColor: "#000000", // Black text
                color: "#FFFFFF", // White background
                isButton: true, // Custom flag to differentiate buttons
              },
              {
                name: "Gift Cards",
                image: null,
                image_icon: null,
                items: [],
                textColor: "#000000",
                color: "#FFFFFF",
                isButton: true,
              }
            );
            setTimeout(() => {
              this.updateMaxHeight();
            }, 1000);
            this.loading = false;
          } else {
            this.loading = false;
          }

        },
        () => {
          this.loading = false;
        }
      );
  }

  checkPOSPermissionSubmit(event: Event) {
    if (this.isIos() && this.isMobile()) {
      this.posService.scrolltoTop.next(true);
    }
    if (this.validUserData) {
      event.preventDefault();
      const modifierItemSubmit = this.selectedModifiers.map((modifier) => {
        return {
          modifier: {
            id: modifier.id,
            fixed_price: modifier.fixed_price,
            mod_tag: this.selectedTags[modifier.id]
          },
          quantity: this.modifierQuantities[modifier.id],
          mod_type: this.modifierDirections[modifier.id],
        };
      });

      let cuObs = null;
      const orderHash = localStorage.getItem('order_hash');
      let botOrder;
      let resultP;
      this.orderService.getOrderByHash(orderHash).pipe(takeUntil(this.unsubscribe)).subscribe((resultOrder) => {
        botOrder = resultOrder;

        if (!this.editMode || this.orderWithPayment == null || !this.orderWithPayment.bot_order.has_submitted_tab ||
          this.permission.hasPermission('POS_FUNCTIONS', 'MODIFY')) {
          if (this.editMode && this.permission.hasPermission('POS_FUNCTIONS', 'MODIFY')) {
            cuObs = this.orderService.updateItemId(orderHash, this.orderItem.id, {
              menu_item_id: this.selectedItem.id,
              quantity: this.selectedItemQuantity,
              updated_modifiers: modifierItemSubmit,
              special_request: this.special_request.value,
            }).pipe(takeUntil(this.unsubscribe)).subscribe(
              (retV) => {
                this.posSummaryToggle.toggle('OPEN');
                if (this.form.controls.search.value) {
                  this.form.controls.search.setValue('');
                  return;
                }
                this.refresh.emit(true);
                this.parserMenuItems.emit(retV);
                this.resetSteps();

              },
              (err) => {
                console.log(err);
              }
            );

          } else {
            cuObs = this.orderService.addItemId(orderHash, this.selectedItem?.id, {
              modifiers: modifierItemSubmit,
              quantity: this.selectedItemQuantity,
              special_request: this.special_request.value,
            });
          }
        } else {
          const isMobile = window.innerWidth <= 599;
          const dialogRef = this.dialog.open(PinConfirmComponent, {
            width: isMobile ? '100vw' : '500px',
            height: isMobile ? '100vh' : '410px',
            maxWidth: isMobile ? '100vw' : '80vw',
            data: {}
          });
          dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(resultPer => {
            this.pinPermission = resultPer.permission;
            resultP = resultPer;
            if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'MODIFY')) {
              cuObs = this.orderService.updateItemId(orderHash, this.orderItem.id, {
                menu_item_id: this.selectedItem.id,
                quantity: this.selectedItemQuantity,
                updated_modifiers: modifierItemSubmit,
                special_request: this.special_request.value,
              }).pipe(takeUntil(this.unsubscribe)).subscribe(
                (retV) => {
                  this.posSummaryToggle.toggle('OPEN');
                  if (this.form.controls.search.value) {
                    this.form.controls.search.setValue('');
                    return;
                  }
                  this.orderService.updateDescription(botOrder.bot_order.id, { description: resultP.description })
                    .pipe(takeUntil(this.unsubscribe)).subscribe((updated) => {
                      console.log('________________', updated);
                    });
                  this.refresh.emit(true);
                  this.parserMenuItems.emit(retV);
                  this.resetSteps();
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              const dialogRef = this.dialog.open(AlertsMessagesComponent, {
                disableClose: true,
                width: '364px',
                minHeight: '20vh',
                data: {
                  message: 'This User/Manager does not have the required permission to perform this operation!'
                }
              });
              dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
              });
              // alert('This User/Manager does not have the required permission to perform this operation!' );
            }
          });
        }
        cuObs.pipe(takeUntil(this.unsubscribe)).subscribe(
          (retV) => {
            this.selectedModifierGroup = null;
            delete this.selectedTags[this.selectedModifier?.id];
            this.posSummaryToggle.toggle('OPEN');
            if (this.form.controls.search.value) {
              this.form.controls.search.setValue('');
              return;
            }
            this.refresh.emit(true);
            this.parserMenuItems.emit(retV);
            this.resetSteps();
          },
          (err) => {
            console.log(err);
          }
        );
      });
    }
  }

  handleSubmit(event: Event) {
    if (this.validUserData) {
      event.preventDefault();
      const modifierItemSubmit = this.selectedModifiers.map((modifier) => {
        return {
          modifier: {
            id: modifier.id,
          },
          quantity: this.modifierQuantities[modifier.id],
          mod_type: this.modifierDirections[modifier.id],
        };
      });

      let cuObs = null;
      const orderHash = localStorage.getItem('order_hash');
      if (this.editMode) {
        cuObs = this.orderService.updateItemId(orderHash, this.orderItem.id, {
          menu_item_id: this.selectedItem.id,
          quantity: this.selectedItemQuantity,
          updated_modifiers: modifierItemSubmit,
          special_request: this.special_request.value,
        });
      } else {
        cuObs = this.orderService.addItemId(orderHash, this.selectedItem.id, {
          modifiers: modifierItemSubmit,
          quantity: this.selectedItemQuantity,
          special_request: this.special_request.value,
        });
      }
      cuObs.pipe(takeUntil(this.unsubscribe)).subscribe(
        () => {
          this.posSummaryToggle.toggle('OPEN');
          if (this.form.controls.search.value) {
            this.form.controls.search.setValue('');
            return;
          }
          this.refresh.emit(true);
          this.resetSteps();
        },
        (err) => {
          console.log(err);
        }
      );
    }

  }

  backMenuStep(ev: Event) {
    ev.stopImmediatePropagation();
    this.stepper.previous();
    if (this.stepNumber > 1) {
      if (this.stepNumber === 3) {
        this.currentMenuTitle = this.previousMenuTitle;
        this.form.controls.menuItemId.setValue(null);
        this.form.controls.modifiers.setValue([]);
        this.modifierDirections = null;
        this.modifierQuantities = null;
        this.previousSelectedModifierGroups = new Set();
      } else if (this.stepNumber === 2) {
        this.currentMenuTitle = '';
        this.form.controls.menuGroupItemId.setValue(null);
      }

      this.previousMenuTitle = '';
      this.stepNumber--;
    }
  }

  moveToNextStep() {
    this.stepper.next();
    this.stepNumber++;
  }

  resetSteps() {
    this.stepNumber = 1;
    this.form.controls.menuGroupId.setValue(null);
    this.form.controls.menuGroupItemId.setValue(null);
    this.form.controls.menuItemId.setValue(null);
    this.form.controls.modifiers.setValue([]);
    this.special_request.setValue(null);
    this.selectedItemQuantity = 1;
    if (this.modifierGroup$) {
      this.modifierGroup$.options = [];
    }

    this.currentMenuTitle = '';
    this.previousMenuTitle = '';
    this.stepper.selectedIndex = 0;
    this.selectedOrderMiscFromGroup = new UntypedFormArray([]);
  }

  public toggleVisibility(): void {
    this.hideTab = !this.hideTab;
  }

  onMenuGroupSelect(menuGroup, name) {
    console.log('ON MENU GROUP SELECT', menuGroup, name);
    if (this.validUserData) {
      if (this.posUiUpdateFeature) {
        if (name === 'Add Misc.') {
          this.addMisc();
        } else if (name === 'Gift Cards') {
          this.addGiftCard();
        } else {
          this.currentMenuTitle = menuGroup.name;
          this.selectedMenuGroup = menuGroup;
          this.selectedMenuGroupSub = menuGroup.subGroups;
          if (this.selectedMenuGroup.subGroups[0].name != 0) {

            setTimeout(() => {
              this.OrderSubCatGridComponent.isStoreUndef();
            }, 200);

            this.stepper.selectedIndex = 5;
          } else {
            this.selectedMenuGroupNotSub = this.selectedMenuGroup.subGroups[0].items;

            // setTimeout(() => {
            //   this.OrderItemGridComponent.isStoreUndef();
            // }, 200);

            this.moveToNextStep();
          }
          this.toggleVisibility();
        }
      } else {
        this.currentMenuTitle = menuGroup.name;
        this.selectedMenuGroup = menuGroup;
        this.selectedMenuGroupSub = menuGroup.subGroups;
        if (this.selectedMenuGroup.subGroups[0].name != 0) {
          setTimeout(() => {
            this.OrderSubCatGridComponent.isStoreUndef();
          }, 200);

          this.stepper.selectedIndex = 5;
        } else {
          this.selectedMenuGroupNotSub = this.selectedMenuGroup.subGroups[0].items;
          // setTimeout(() => {
          //   this.OrderItemGridComponent.isStoreUndef();
          // }, 200);
          this.moveToNextStep();
        }
        this.toggleVisibility();
      }
    }
  }

  public selectedSearchFiltering(menuGroupItem: MenuGroupItem): void {
    this.moveToNextStep();
    this.currentMenuTitle = '';
    this.form.controls.menuGroupId.setValue(menuGroupItem.menu_group_id);
    this.form.controls.menuGroupItemId.setValue(menuGroupItem.id);
  }

  onMenuItemGroupSelect(menuItemGroup: MenuItemGroup) {
    if (this.validUserData) {
      this.lastSelectedTag = null;
      this.previousSelectedModifierGroups = new Set();
      this.resetTagSelection();
      this.currentMenuTitle = menuItemGroup.name;
      this.menuService
        .getMenuItemsForMenuGroup(this.store.id, menuItemGroup.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((items) => {
          if (items.length > 0) {
            this.items = items;
            this.selectedItem = items[0];
            this.onPortionSelect(this.selectedItem, this.items.length);
            this.moveToNextStep();
          } else {
            this.snackBar.open('Currently Unavilable', 'OK',
              {
                duration: 2000,
                verticalPosition: 'top',
              });
          }
        });
    }
  }

  onMenuItemGroupSelectFromSearch(menuItemGroup: MenuItemGroup) {
    if (this.validUserData) {
      this.currentMenuTitle = menuItemGroup.name;
      this.menuService
        .getMenuItemsForMenuGroup(this.store.id, menuItemGroup.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((items) => {
          this.items = items;
          this.selectedItem = items[0];
          this.onPortionSelect(this.selectedItem, this.items.length);
          this.stepper.selectedIndex = 2;
        });
    }
  }

  onPortionSelect(menuItem, length) {
    if (this.validUserData) {
      this.selectedItem = menuItem;
      // this.totalPrice = +menuItem.base_price
      this.calculateTotal();
      this.selectedModifiers = [];
      this.calculateTotal();
      this.currentMenuTitle = menuItem.name.replace('Standard', '');
      this.isValid = false;

      this.menuService
        .getModifiersForMenuItem(this.store.id, menuItem.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((modifiers: any) => {
          this.modifierGroups = modifiers.modifier_groups;
          this.modifierDisplay = modifiers.modifier_display_hv;
          const requiredModifierGroup = this.modifierGroups.find(group => group.is_required === true);
          if (length <= 1) {
            if (requiredModifierGroup) {
              this.onModifierGroupSelection(requiredModifierGroup);
            }
          } else {
            this.selectedModifierGroup = 'portions';
            if (!requiredModifierGroup) {
              this.isValid = true;
            }
          }

        });
    }

  }

  setModifierDirection(event: MatButtonToggleChange, modifier: ModifierOption) {
    if (this.validUserData) {
      if (this.modifierDirections) {
        this.modifierDirections[modifier.id] = event.value;
      } else {
        this.modifierDirections = { [modifier.id]: event.value };
      }
      this.calculateTotal();
      if (this.modtagEnableFeature) {
        this.moveToNextRequiredModifierGroup();
      }
    }

  }

  onSelectModifier(modifierGroup: ModifierGroup, modifier: ModifierOption) {

    if (this.validUserData) {
      let wasAdded = false

      /* IT IS RESETTING THE PREVIOUS MODIFIER ASSIGNED TAG */
      // if (this.selectedModifier?.id !== modifier.id) {
      //   this.resetTagSelection();
      // }

      const totalQty = Object.values(this.modifierQuantities).reduce(
        (a: number, b: number) => a + b,
        0
      );

      if (this.isSelectedModifier(modifier)) {
        const indexOfSelected = this.selectedModifiers
          .map((i) => i.id)
          .indexOf(modifier.id);
        if (indexOfSelected > -1) {
          this.selectedModifiers.splice(indexOfSelected, 1);
          delete this.modifierDirections[modifier.id];
          delete this.modifierQuantities[modifier.id];
          delete this.selectedTags[modifier.id];
          if (this.selectedModifier?.id === modifier.id) {
            this.selectedModifier = null;
          }
          wasAdded = false;
          // this.totalPrice -= +modifier.upcharge;
          this.calculateTotal();
        }
      } else {
        // if (modifierGroup.max_mods === totalQty) {
        //   this.snackBar.open(
        //     `#Item can have ${
        //       modifierGroup.max_mods
        //     } '${modifierGroup.name.toLowerCase()}' only!`,
        //     "OK",
        //     {
        //       duration: 2000,
        //       verticalPosition: "top",
        //     }
        //   );
        //   return;
        // }

        /*  */
        // const existingGroupCount = this.selectedModifiers.filter(
        //   (m) => m.modifier_group == modifierGroup.id
        // ).length;

        /* Based on the total quantity selected  */
        const existingGroupQty = this.selectedModifiers
          .filter((m) => m.modifier_group == modifierGroup.id)
          .reduce((sum, m) => sum + (this.modifierQuantities[m.id] || 0), 0);
        if (
          existingGroupQty < modifierGroup.max_mods ||
          modifierGroup.max_mods === -1
        ) {
          // if(existingGroupCount < modifierGroup.max_mods && modifier.max_mod_units > modifierGroup.max_mods   || modifierGroup.max_mods === -1){
          this.selectedModifiers.push(modifier);
          this.modifierDirections[modifier.id] = 'ADD';
          this.modifierQuantities[modifier.id] = 1;
          this.selectedModifier = modifier;
          // this.totalPrice += +modifier.upcharge;
          
          if (this.lastSelectedTag) {
            this.selectedTags[modifier.id] = this.lastSelectedTag;
            modifier.mod_tag = this.lastSelectedTag;
            this.lastSelectedTag = null;
          }

          if (!modifier.has_half_mod) {
            if (this.modtagEnableFeature) {
              setTimeout(() => {
                this.moveToNextRequiredModifierGroup();
              }, 200);
            }
          }

          wasAdded = true;
          this.calculateTotal();
        } else {
          this.snackBar.open(
            `Item can have ${modifierGroup.max_mods
            } '${modifierGroup.name.toLowerCase()}' only!`,
            'OK',
            {
              duration: 2000,
              verticalPosition: 'top',
            }
          );
        }
      }

      this.updateGroupSelectionState(modifierGroup, wasAdded);
      this.checkModGroupValidityV2();
    }
    // const currentModifiers: number[] = [...this.form.controls.modifiers.value];

    // const price: number = this.totalPrice;
    // let calculatedPrice: number;
    // if (currentModifiers.indexOf(modifier.id) > -1) {
    //   const countedPrice = modifier.upcharge * this.modifierQuantities[modifier.id];
    //   currentModifiers.splice(currentModifiers.indexOf(modifier.id), 1);
    //   calculatedPrice = +price - countedPrice;
    //   if (this.modifierDirections && this.modifierDirections[modifier.id]) {
    //     delete this.modifierDirections[modifier.id];
    //   }

    //   if (this.modifierQuantities && this.modifierQuantities[modifier.id]) {
    //     delete this.modifierQuantities[modifier.id];
    //   }
    // } else {
    //   currentModifiers.push(modifier.id);
    //   calculatedPrice = +price + +modifier.upcharge;

    //   if (modifier.has_quantity) {
    //     if (!this.modifierQuantities) {
    //       this.modifierQuantities = {[modifier.id]: 1};
    //     } else if (!this.modifierQuantities[modifier.id]) {
    //       this.modifierQuantities[modifier.id] = 1;
    //     }
    //   }
    // }

    // this.totalPrice = calculatedPrice;

    // this.form.controls.modifiers.setValue([...currentModifiers]);
  }

  checkModGroupValidityV2() {
    // Step 1: Get required groups with their required quantity
    const requiredGroups = this.modifierGroups
      .filter(group => group.is_required)
      .map(group => ({
        id: group.id,
        requiredQty: group.max_mods,
      }));

    // Step 2: Create a map of groupId -> total selected quantity
    const selectedModifiersByGroup: { [groupId: number]: number } = {};

    this.selectedModifiers.forEach(modifier => {
      const groupId = modifier.modifier_group;
      const modifierId = modifier.id;
      const quantity = this.modifierQuantities[modifierId] || 0;

      selectedModifiersByGroup[groupId] = (selectedModifiersByGroup[groupId] || 0) + quantity;
    });

    // Step 3: Validate that all required groups meet required quantity
    this.isValid = requiredGroups.every(group =>
      (selectedModifiersByGroup[group.id] || 0) >= group.requiredQty
    );
  }


  updateGroupSelectionState(modifierGroup: ModifierGroup, wasAdded: boolean) {
    const hasModifiersInGroup = this.selectedModifiers.some(m => m.modifier_group === modifierGroup.id);

    if (wasAdded && hasModifiersInGroup) {
      if (!this.previousSelectedModifierGroups.has(modifierGroup.id)) {
        this.previousSelectedModifierGroups.add(modifierGroup.id);
      }
    } else if (!hasModifiersInGroup) {
      if (this.previousSelectedModifierGroups.has(modifierGroup.id)) {
        this.previousSelectedModifierGroups.delete(modifierGroup.id);
      }
    }
    this.ref.detectChanges();
  }


  selectedTag(tag: string) {
    if (this.lastSelectedTag === tag) {
      this.lastSelectedTag = null;
    } else {
      this.lastSelectedTag = tag;
    }
  }

  resetTagSelection() {
    this.selectedTags = {};
  }

  moveToNextRequiredModifierGroup() {

    if (!this.selectedModifierGroup) {
      return;
    }

    const requiredGroups = this.modifierGroups
      .filter(group => group.is_required)
      .map(group => ({
        id: group.id,
        requiredQty: group.max_mods,
      }));

    const selectedModifiersByGroup: { [groupId: number]: number } = {};
    this.selectedModifiers.forEach(modifier => {
      const groupId = modifier.modifier_group;
      const modifierId = modifier.id;
      const quantity = this.modifierQuantities[modifierId] || 0;

      selectedModifiersByGroup[groupId] = (selectedModifiersByGroup[groupId] || 0) + quantity;
    });

    // Step 1: Check if current group is fulfilled
    const currentGroupId = this.selectedModifierGroup.id;
    const currentGroup = requiredGroups.find(g => g.id === currentGroupId);
    const currentQty = selectedModifiersByGroup[currentGroupId] || 0;

    if (currentGroup && currentQty < currentGroup.requiredQty) {
      return;
    }

    const nextGroup = requiredGroups.find(group =>
      (selectedModifiersByGroup[group.id] || 0) < group.requiredQty
    );

    if (nextGroup) {
      const fullGroup = this.modifierGroups.find(group => group.id === nextGroup.id);
      this.selectedModifierGroup = fullGroup!;
    }
  }



  isSelectedModifier(modifierOption: ModifierOption) {
    return (
      this.selectedModifiers &&
      this.selectedModifiers.some((m) => m.id === modifierOption.id)
    );
  }

  getHalfModCheckedValue(modifierOption: ModifierOption) {
    const existing = this.selectedModifiers.find(
      (m) => m.id === modifierOption.id
    );
    return existing ? existing.mod_type : 'ADD';
  }

  increaseQuantity() {
    if (this.selectedItemQuantity >= 50 || (this.selectedItem.available_qty && this.selectedItemQuantity >= this.selectedItem.available_qty)) {
      return;
    } else {
      this.selectedItemQuantity++;
      this.calculateTotal();
    }
  }

  decreaseQuantity() {
    if (this.selectedItemQuantity <= 1) {
      return;
    } else {
      this.selectedItemQuantity--;
      this.calculateTotal();
    }
  }

  setModifierQuantities(
    event: Event,
    modifier: ModifierOption,
    operation: '-' | '+'
  ) {
    event.stopImmediatePropagation();
    // if (operation === '+') {
    //   if(+this.modifierQuantities[modifier.id] < modifier.max_mod_units){
    //     this.modifierQuantities[modifier.id]++;
    //     // this.totalPrice += +modifier.upcharge;
    //     this.calculateTotal();
    //   }
    // } else if (this.modifierQuantities[modifier.id] !== 1) {
    //   this.modifierQuantities[modifier.id]--;
    //   // this.totalPrice -= +modifier.upcharge;
    //   this.calculateTotal();
    // }
    const modifierGroup = this.modifierGroups.find(
      (mod) => mod.id === modifier.modifier_group
    );
    const totalQty = Object.values(this.modifierQuantities).reduce(
      (a: number, b: number) => a + b
    );
    const existingGroupCount = this.selectedModifiers.filter(
      (m) => m.modifier_group == modifierGroup.id
    ).length;

    if (operation === '+') {
      if (modifier.max_mod_units && modifier.max_mod_units > 0) {

        if (!this.checkModGroupRequiredQty(modifier)) {
          return;
        }
        if (
          this.modifierQuantities[modifier.id] < modifier.max_mod_units
          // && totalQty < modifierGroup.max_mods
        ) {
          this.modifierQuantities[modifier.id]++;
          if (this.modifierQuantities[modifier.id] > 1 && !modifier.fixed_price) {
            this.totalPrice += +modifier.upcharge;
          }
        }
      } else if (modifier.max_mod_units === -1) {
        if (!this.checkModGroupRequiredQty(modifier)) {
          return;
        }
        this.modifierQuantities[modifier.id]++;
        if (this.modifierQuantities[modifier.id] > 1 && !modifier.fixed_price) {
          this.totalPrice += +modifier.upcharge;
        }
      } else {
        if (!this.checkModGroupRequiredQty(modifier)) {
          return;
        }
        if (
          this.modifierQuantities[modifier.id] < modifierGroup.max_mods
          // && totalQty < modifierGroup.max_mods
        ) {
          this.modifierQuantities[modifier.id]++;
          if (this.modifierQuantities[modifier.id] > 1 && !modifier.fixed_price) {
            this.totalPrice += +modifier.upcharge;
          }
        }
      }
    } else if (this.modifierQuantities[modifier.id] !== 1) {
      this.modifierQuantities[modifier.id]--;
      if (!modifier.fixed_price) {
        this.totalPrice -= +modifier.upcharge;
      }
    }

    this.checkModGroupValidityV2();
    this.moveToNextRequiredModifierGroup();

  }

  checkModGroupRequiredQty(modifier): boolean {
    const group = this.modifierGroups.find(g => g.id === modifier.modifier_group);

    if (group?.is_required) {
      const currentGroupQty = Object.keys(this.modifierQuantities)
        .map(mid => {
          const mod = this.selectedModifiers.find(m => m.id === +mid);
          const qty = this.modifierQuantities[+mid] || 0;
          return mod?.modifier_group === group.id ? qty : 0;
        })
        .reduce((sum, qty) => sum + qty, 0);

      if (currentGroupQty >= group.max_mods) {
        console.warn(`🚫 Limit reached for required group '${group.name}'`);
        return false; // Block it
      }
    }

    return true; // ✅ Allow it
  }

  calculateTotal() {
    this.totalPrice = +this.selectedItem.base_price * this.selectedItemQuantity;
    this.selectedModifiers.forEach((m) => {
      if (m.fixed_price) {
        this.qty = 1;
      } else {
        this.qty = this.modifierQuantities[m.id];
      }
      const dir = this.modifierDirections[m.id];
      let upcharge = 0;
      // mod price changes based on mod type and is calculated in server
      if (
        dir === m.mod_type ||
        (['H1', 'H2'].includes(m.mod_type) && ['H1', 'H2'].includes(dir))
      ) {
        upcharge = m.upcharge;
      } else if (dir === 'ADD' && m.mod_type !== 'ADD') {
        upcharge = m.upcharge * 2;
      } else {
        upcharge = m.upcharge / 2;
      }

      /* TAG CHARGE */
      let tagCharge = 0;
      if (m.mod_tag === 'No' && m.no_tag_upcharge) {
        tagCharge += m.no_tag_upcharge;
        console.log(`Applying Light Tag Upcharge: ${m.no_tag_upcharge}`);
      } else if (m.mod_tag === 'Light' && m.light_tag_upcharge) {
        tagCharge += m.light_tag_upcharge;
        console.log(`Applying Light Tag Upcharge: ${m.light_tag_upcharge}`);
      } else if (m.mod_tag === 'Extra' && m.extra_tag_upcharge) {
        tagCharge += m.extra_tag_upcharge;
        console.log(`Applying Extra Tag Upcharge: ${m.extra_tag_upcharge}`);
      } else if (
        m.mod_tag === 'On Side' && m.onside_tag_upcharge) {
        tagCharge += m.onside_tag_upcharge;
      } else if (
        m.mod_tag === 'Sub' && m.sub_tag_upcharge) {
        tagCharge += m.sub_tag_upcharge;
      }
      const chrge = upcharge + tagCharge;
      const modPrice = +this.qty * +chrge * this.selectedItemQuantity;
      this.totalPrice += +modPrice;
    });
  }

  resetState() {
    this.editMode = false;
    this.selectedItem = null;
    this.selectedModifiers = [];
    this.modifierDirections = {};
    this.modifierQuantities = {};
    this.previousSelectedModifierGroups = new Set();
    this.resetTagSelection();
    this.searchTerm = "";
    this.orderHash = "";
    if (!this.searchStart) {
      this.stepper.selectedIndex = 1;
    }
    this.searchStart = false;
  }

  stepChange(event) {
    console.log('EVNET', event.selectedIndex);

    this.onInitEventQuickEntry.emit(event.selectedIndex == 0 ? true : false)
    this.orderService.disableInputButton = true;
    switch (event.selectedIndex) {
      case 0:
        this.resetState();
        this.loadMenuGroups(this.store.id, '', false);
        break;
      case 1:
        this.currentMenuTitle = this.selectedMenuGroup?.name;
        break;
      case 2:
        console.log('MENU TILE STP #2');

        this.currentMenuTitle = this.selectedItem.name;
        break;
      case 3:
        this.currentMenuTitle = 'Search Results';
        break;
      case 4:
        this.currentMenuTitle = 'Add Miscellaneous';
        this.loadMisc();
        // this.loadMisc(this.orderWithPayment.bot_order.id);
        break;
      case 5:
        this.currentMenuTitle = this.selectedMenuGroup.name;
        // this.loadMisc(this.orderWithPayment.bot_order.id);
        break;
      case 6:
        console.log('Gift Card step');
        this.currentMenuTitle = 'Gift Card';
        this.loadMisc();
        break;
    }

    // if(event.selectedIndex === 0){
    //   this.resetState();
    // }
  }

  updateMaxHeight() {
    this.maxHeightMenuItem = window.innerHeight - 350;
    if (this.posUiUpdateFeature) {
      this.buttonHeight = this.getButtonHeight();
      this.calculatePagination();
    }

  }

  getColumnsCount(): number {
    const width = window.innerWidth;
    if (width >= 1536) return 6;
    else if (width >= 1280) return 5;
    else if (width >= 1024) return 5;
    else if (width >= 768) return 5;
    else return 3;
  }

  getButtonHeight(): number {
    return window.innerWidth < 768 ? 100 : 200;
  }

  updateDisplayedCategories() {
    const start = this.currentPage * this.itemsPerPage;
    this.displayedCategories = this.menuGroups.slice(start, start + this.itemsPerPage);
  }

  getPaginatedCategories() {
    if (!this.menuGroups || this.menuGroups.length === 0) {
      return [];
    }

    const start = this.currentPage * this.itemsPerPage;
    return this.menuGroups.slice(start, start + this.itemsPerPage);
  }

  getHeightRange() {
    const width = window.innerWidth;
    if (width >= 1536) return { minHeight: 150, maxHeight: 240 };
    if (width >= 1280) return { minHeight: 150, maxHeight: 220 };
    if (width >= 1024) return { minHeight: 120, maxHeight: 200 };
    if (width >= 768) return { minHeight: 100, maxHeight: 180 };
    return { minHeight: 90, maxHeight: 150 };
  }

  calculatePagination() {
    if (!this.menuGroups || this.menuGroups.length === 0) {
      console.warn("menuGroups not loaded yet, skipping pagination calculation.");
      return;
    }

    const containerHeight = this.maxHeightMenuItem - 20;
    const columns = this.getColumnsCount();
    let { minHeight, maxHeight } = this.getHeightRange();
    let buttonHeight = this.buttonHeight || maxHeight;
    let bestHeight = buttonHeight;
    let fitsBetter = false;
    const adjustedItemLength = this.menuGroups.length;
    while (buttonHeight >= minHeight) {
      let testRows = Math.floor(containerHeight / buttonHeight);
      let testTotalItemsPerPage = testRows * columns;
      if (testTotalItemsPerPage >= adjustedItemLength) {
        bestHeight = buttonHeight;
        fitsBetter = true;
        break;
      }

      buttonHeight -= 2;
    }

    this.buttonHeight = fitsBetter ? bestHeight : Math.min(maxHeight, containerHeight / 3);

    let maxRowsThatFit = Math.floor(containerHeight / this.buttonHeight);

    if (maxRowsThatFit < 2) {
      maxRowsThatFit = 2;
      this.buttonHeight = containerHeight / maxRowsThatFit;
    }

    if (this.buttonHeight < 80) {
      this.buttonHeight = 80;
    }

    this.itemsPerPage = Math.max(1, maxRowsThatFit * columns);
    this.totalPages = Math.max(1, Math.ceil(adjustedItemLength / this.itemsPerPage));
    this.totalPagesArray = Array.from({ length: this.totalPages }, (_, index) => index);
    this.currentPage = Math.min(this.currentPage, this.totalPages - 1);
    this.updateDisplayedCategories();
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].clientX;
    this.detectSwipe(this.touchStartX, this.touchEndX);
  }

  /* SWIPE FOR NON TOUCH DEVICE */
  // onMouseDown(event: MouseEvent) {
  //   this.isMouseDown = true;
  //   this.mouseStartX = event.clientX;
  // }

  // onMouseMove(event: MouseEvent) {
  //   if (this.isMouseDown) {
  //     this.mouseEndX = event.clientX;
  //   }
  // }

  // onMouseUp(event: MouseEvent) {
  //   this.isMouseDown = false;
  //   this.detectSwipe(this.mouseStartX, this.mouseEndX);
  // }

  detectSwipe(startX: number, endX: number) {
    const swipeDistance = startX - endX;
    if (swipeDistance > this.SWIPE_THRESHOLD) {
      this.goToNextPage();
    } else if (swipeDistance < -this.SWIPE_THRESHOLD) {
      this.goToPrevPage();
    }
  }

  goToPage(pageIndex: number) {
    if (pageIndex >= 0 && pageIndex < this.totalPages) {
      this.currentPage = pageIndex;
      this.updateDisplayedCategories();
    }
  }

  goToNextPage() {
    if (this.stepper.selectedIndex == 2) {
      if (this.currentModPage < this.totalModPages - 1) {
        this.currentModPage++;
        this.updateDisplayedMod();
      }
    } else {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
        this.updateDisplayedCategories();
      }
    }
  }

  goToPrevPage() {
    if (this.stepper.selectedIndex == 2) {
      if (this.currentModPage > 0) {
        this.currentModPage--;
        this.updateDisplayedMod();
      }
    } else {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.updateDisplayedCategories();
      }
    }
  }

  addMisc() {
    this.stepper.selectedIndex = 4;
  }

  getFormGroup(data) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(data ? data.id : null),
      quantity: new UntypedFormControl(data ? data.quantity : 1, Validators.required),
      item_name: new UntypedFormControl(
        { value: data ? data.item_name : null, disabled: data?.is_gift_card }, // <-- Conditionally disable here
        Validators.required
      ),
      price: new UntypedFormControl(data ? data.price : null, Validators.required),
      is_taxable: new UntypedFormControl(data ? data.is_taxable : true),
    });
  }

  add() {
    this.selectedOrderMiscFromGroup.push(this.getFormGroup(null));
  }

  delete(index, miscId) {
    if (miscId) {
      this.orderService.deleteMiscItem(miscId).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
        this.selectedOrderMiscFromGroup.removeAt(index);
        this.refresh.emit(true);
      });
    } else {
      this.selectedOrderMiscFromGroup.removeAt(index);
    }
  }

  loadMisc() {
    const bot_order_id = this.orderWithPayment.bot_order.id;
    this.storeService.getMiscItems(bot_order_id).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.selectedOrderMiscFromGroup = new UntypedFormArray([]);
      data.forEach((element) => {
        this.selectedOrderMiscFromGroup.push(this.getFormGroup(element));
      });
      this.loading = false;
    });
  }

  submitMisc() {
    this.loading = true;
    this.currentOrderService.current.pipe(takeUntil(this.unsubscribe)).pipe(take(1)).subscribe(order => {
      const payload = {
        bot_order_id: order.bot_order.id,
        items: this.selectedOrderMiscFromGroup.value,
      };

      this.storeService.addMiscItems(payload).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
        // this.onRowClicked(this.selectedRow);
        this.refresh.emit(true);
        // this.loadMisc(order.bot_order.id);
        this.loadMisc();
        this.stepper.selectedIndex = 0;
        this.posSummaryToggle.toggle('OPEN');
        this.loading = false;
      });
    });

  }

  onSubCatGroup(subcategoryItems) {
    if (this.validUserData) {
      this.selectedMenuGroupNotSub = subcategoryItems.items;
      this.stepper.selectedIndex = 1;

      // setTimeout(() => {
      //   this.OrderItemGridComponent.isStoreUndef();
      // }, 1000);

      this.subCattoItem = true;
    }
  }

  goBack(e) {
    if (!this.searchStart) {
      if (this.stepper.selectedIndex == 6) {
        this.stepper.selectedIndex = 0;
      } else if (this.stepper.selectedIndex == 5 || this.stepper.selectedIndex == 4) {
        this.stepper.selectedIndex = 0;
      } else if (this.selectedMenuGroup.subGroups[0].name != 0 && this.stepper.selectedIndex == 1) {
        this.stepper.selectedIndex = 5;
      } else {
        this.stepper.previous()
      }
    } else {
      if (this.stepper.selectedIndex == 3) {
        this.stepper.selectedIndex = 0;
      } else {
        this.stepper.previous();
      }
    }


    if (this.stepper.selectedIndex === 2) {
      this.selectedModifierGroup = null;
      this.currentModPage = 0;
      this.totalModPages = 0;
      this.totalModPagesArray = [];
      this.displayedModifiers = [];
      this.modPerPage = 0;
    }


  }

  goToHome() {
    this.stepper.reset()
  }

  // Function to set dynamic height for the menu container
  setDynamicHeight(): void {
    if (this.menuContainer) {
      const containerHeight = this.menuContainer.nativeElement.scrollHeight;
      // You can add or subtract a certain value if needed for better visual representation
      const dynamicHeight = containerHeight + 'px';

      // Set the height of the container dynamically
      this.renderer.setStyle(this.menuContainer.nativeElement, 'height', dynamicHeight);
    }
  }

  isIos(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    return /iPad|iPhone|iPod/.test(userAgent) && !(window['MSStream']);
  }

  isMobile(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  preventEnter(event: KeyboardEvent, inputElement: HTMLInputElement) {
    event.preventDefault();  // Prevent the default behavior of the Enter key
    inputElement.blur();     // Remove focus from the input field
  }

  // Called when mousedown or touchstart event starts for modifier button
  onModifierMouseDown(modifierGroup: any, modifierOption: any) {
    // Start the timer when button is pressed down
    this.pressTimer = setTimeout(() => {
      // Open the EditModifierDialogComponent on long press
      this.checkModEditPermission(modifierGroup, modifierOption);
    }, this.pressHoldDuration);
  }

  // Called when mouseup or touchend event is fired (when the user releases the mouse or touch)
  onModifierMouseUp() {
    // Clear the timer if the mouse/touch is released before the threshold
    clearTimeout(this.pressTimer);
  }

  // Called when mouse leaves the button
  onModifierMouseLeave() {
    // Clear the timer if the mouse leaves the button (prevents dialog from opening)
    clearTimeout(this.pressTimer);
  }

  // Open EditModifierDialogComponent for modifier on long press
  checkModEditPermission(modifierGroup: any, modifierOption: any) {
    if (this.permission.hasPermission('ACCESS', 'MENU')) {
      this.openEditModifierDialog(modifierGroup, modifierOption);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(resultPer => {
        if (resultPer && resultPer.permission) {
          this.permission = resultPer['permission'];
          if (this.permission.hasPermission('ACCESS', 'MENU')) {
            this.openEditModifierDialog(modifierGroup, modifierOption);
          } else {
            this.alertPopup('', 'This User/Manager does not have the required permission to perform this operation!');
          }
        }
      });
    }
  }

  openEditModifierDialog(modifierGroup: any, modifierOption: any) {
    const dialogRef = this.dialog.open(EditModifierDialogComponent, {
      data: { modifierGroup, modifierOption },
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
      panelClass: 'full-screen-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Modifier dialog result:', this.selectedItem);
      this.menuService
        .getModifiersForMenuItem(this.store.id, this.selectedItem.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (modifiers: any) => {
            this.modifierGroups = modifiers.modifier_groups;
            this.modifierDisplay = modifiers.modifier_display_hv;
            let requiredModifierGroup
            if (this.selectedModifierGroup) {
              requiredModifierGroup = this.modifierGroups.find(group => group.id === this.selectedModifierGroup.id);
            } else {
              requiredModifierGroup = this.modifierGroups.find(group => group.is_required === true);
            }
            if (requiredModifierGroup) {
              this.onModifierGroupSelection(requiredModifierGroup);
            }


          },
          (error: any) => {
            console.error('Error fetching modifiers:', error);
          }
        );
    });
  }

  alertPopup(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // if (result) {
      //   if (name == 'process_payment') {
      //     this.ngOnInit();
      //   }
      // }
    });
  }

  addGiftCard() {
    this.stepper.selectedIndex = 6;
  }

  giftCardBalanceInquiry() {
    const isMobile = window.innerWidth <= 599;
    console.log("balance inquiry");
    this.dialog.open(ActivateGiftCardDialogComponent, {
      width: isMobile ? '100vw' : '600px',
      data: { for: 'balance-inquiry', store: this.orderWithPayment.store, orderId: this.orderWithPayment.bot_order.id },
    });
  }

  purchaseGiftCard() {
    const isMobile = window.innerWidth <= 599;
    let dialogref = this.dialog.open(PurchaseGiftCardDialogComponent, {
      disableClose: true,
      width: isMobile ? '100vw' : '600px',
      data: { order: this.orderWithPayment },
    });
    dialogref.afterClosed().subscribe((result: any) => {
      console.log('Resultsssss', result);

      if (result) {
        this.resetSteps();
      }
    });
  }

  addGiftCardValue() {
    const isMobile = window.innerWidth <= 599;
    let dialogref = this.dialog.open(AddGiftCardValueComponent, {
      width: isMobile ? '100vw' : '435px',
      data: { order: this.orderWithPayment },
    });
    dialogref.afterClosed().subscribe((result: any) => {
      if (result) {
        this.resetSteps();
      }
    });
  }


  /* MODIFIER SWIPE AND PAGINATION FUNCTIONALITY */
  onModifierGroupSelection(groupId: string) {
    this.lastSelectedTag = null;
    this.selectedModifierGroup = groupId;
    this.updateModSectionMaxHeight();
    this.ref.detectChanges();
  }

  updateModSectionMaxHeight() {
    this.maxModHeight = window.innerHeight - 426;
    this.calculateModPagination();
  }

  updateDisplayedMod() {
    const start = this.currentModPage * this.modPerPage;
    const end = start + this.modPerPage;
    this.displayedModifiers = this.selectedModifierGroup.options.slice(start, end);
  }


  getPaginatedMod() {
    if (!this.selectedModifierGroup.options || this.selectedModifierGroup.options.length === 0) {
      console.warn("No modifiers available for pagination.");
      return [];
    }
    const start = this.currentModPage * this.modPerPage;
    const end = start + this.modPerPage;
    return this.selectedModifierGroup.options.slice(start, end);
  }

  getModHeightRange() {
    const width = window.innerWidth;
    if (width >= 1536) return { minHeight: 74, maxHeight: 110 };
    if (width >= 1280) return { minHeight: 62, maxHeight: 104 };
    if (width >= 1024) return { minHeight: 58, maxHeight: 94 };
    if (width >= 768) return { minHeight: 44, maxHeight: 84 };
    return { minHeight: 62, maxHeight: 104 };
  }

  goToModPage(pageIndex: number) {
    if (pageIndex >= 0 && pageIndex < this.totalModPages) {
      this.currentModPage = pageIndex;
      this.updateDisplayedMod();
    }
  }

  calculateModPagination() {
    if (!this.selectedModifierGroup.options || this.selectedModifierGroup.options.length === 0) {
      console.warn("menuGroups not loaded yet, skipping pagination calculation.");
      return;
    }

    const containerHeight = this.maxModHeight - 30;
    const columns = this.getColumnsCount();
    let { minHeight, maxHeight } = this.getModHeightRange();
    let buttonHeight = this.buttonModHeight || maxHeight;
    let bestHeight = buttonHeight;
    let fitsBetter = false;
    const adjustedItemLength = this.selectedModifierGroup.options.length;
    while (buttonHeight >= minHeight) {
      let testRows = Math.floor(containerHeight / buttonHeight);
      let testTotalItemsPerPage = testRows * columns;
      if (testTotalItemsPerPage >= adjustedItemLength) {
        bestHeight = buttonHeight;
        fitsBetter = true;
        break;
      }
      buttonHeight -= 4;
    }

    this.buttonModHeight = fitsBetter ? bestHeight : Math.min(maxHeight, containerHeight / 3);
    let maxRowsThatFit = Math.floor(containerHeight / this.buttonModHeight);

    if (maxRowsThatFit < 2) {
      maxRowsThatFit = 2;
      this.buttonModHeight = containerHeight / maxRowsThatFit;
    }

    this.modPerPage = Math.max(1, maxRowsThatFit * columns);
    this.totalModPages = Math.max(1, Math.ceil(adjustedItemLength / this.modPerPage));
    this.totalModPagesArray = Array.from({ length: this.totalModPages }, (_, index) => index);
    this.currentModPage = Math.min(this.currentModPage, this.totalModPages - 1);
    this.updateDisplayedMod();
  }

  isSelectedGroup(groupId: any): boolean {
    let isSelected;
    if (groupId === 'portions') {
      isSelected = this.selectedModifierGroup === groupId;
    } else {
      isSelected = this.selectedModifierGroup === groupId;
    }
    return isSelected;
  }

  isPreviouslySelectedGroup(groupId: any): boolean {
    const isPreviouslySelected = this.previousSelectedModifierGroups.has(groupId);
    return isPreviouslySelected;
  }
}
