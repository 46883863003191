import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { POSService } from 'src/app/_services/pos-service';
import { CashDrawerConfirmComponent } from '../cash-drawer-confirm/cash-drawer-confirm.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-end-drawer-detail',
  templateUrl: './end-drawer-detail.component.html',
  styleUrls: ['./end-drawer-detail.component.scss']
})
export class EndDrawerDetailComponent {

  public unsubscribe: Subject<null> = new Subject<null>();
  diff: any
  constructor(public dialogRef: MatDialogRef<EndDrawerDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private posService: POSService,
    private dialog: MatDialog) {

  }
  
  ngOnInit() {
    this.diff = +(this.data.amount - this.data.end_detail.expected_in_drawer).toFixed(2);
    console.log('rr',typeof(this.data.amount ), typeof(this.data.end_detail.expected_in_drawer), typeof(this.diff));
    
  }

  endDrawer() {
    let dialogRef = this.dialog.open(CashDrawerConfirmComponent, {
      disableClose: true,
      data: {
        'key': 'end_drawer',
        'amount': this.diff
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result.key) {
        this.dialogRef.close(true);
      }
    });
  }

  back() {
    this.dialogRef.close(false);
  }

}
