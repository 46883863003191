
export class Campaign{
  name:string;
  message:string;
  run_time:string;
  status:string;
  audience:number;
  cost:number;
  created_at:string;
  updated_at:string;
}

export class Gift{
  id: number;
  title: string;
  description: string;
  list_price: string;
  sale_price: string;
  store: number;
  ends: string;
  counter: number
  image: string;
  is_valid: boolean;
  limit: number
}

export class OfferV2{
  id:number;
  store:number;
  offer_title: string;
  offer_description: string;
  promo_code: string;
  image:string;
  trigger: any;
  offer: any;
  limit_1: boolean;
  disclaimer: string;
  start_date: Date;
  end_date: Date;
  total_redeemed: number;
  total_generated: number;
  status:string;
}


export class DataSourceResult<T>{
  data: T[]
  count:number
}

export class CampaignSetting{
  opt_out_message: string;
}
