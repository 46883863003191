import {MenuItemGroupType, MenuItemType} from '../_models/menu-v2';
import {RxCollection, RxDocument, RxJsonSchema} from 'rxdb';


export type MenuItemDocument = RxDocument<MenuItemType>;
export type MenuItemCollection = RxCollection<MenuItemType>;


export const menuItemSchema: RxJsonSchema<MenuItemType> = {
  title: 'menu item schema',
  description: 'describes a simple menu item',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    name: {
      type: 'string',
    },
    available_qty: {
      type: ['number', 'null']
    },
    base_price: {
      type: 'string',
    },
    is_available: {
      type: 'boolean'
    },
    menu_item_group: {
      type: 'number'
    },
    portion: {
      type: 'string',
    },
    is_taxable: {
      type: 'boolean'
    },
    is_pos_only: {
      type: 'boolean'
    },
    reset: {
      type: ['number', 'null']
    },
    description: {
      type: ['string', 'null']
    },
    food_cost: {
      type: ['string', 'null']
    },
    order: {
      type: 'number',
      multipleOf: 1,
      minimum: 0,
      maximum: 999
    },
    barcode: {
      type: ['number', 'null']
    },
    menu_item_group_name: {
      type: ['string', 'null']
    },
    display_name: {
      type: ['string', 'null']
    },
    has_image: {
      type: 'boolean'
    },
    reset_time: {
      type: ['string', 'null']
    },
    joined_with: {
      type: ['string', 'null']
    },
  },
  required: ['id', 'name', 'available_qty', 'base_price', 'is_available', 'menu_item_group', 'portion'],
  indexes: ['order']
};
