<span *ngIf="!showGraph">
  <div class="flex flex-row flex-wrap xs:flex-col xs:flex-row-none items-center !w-full my-7 px-5">
    <form [formGroup]="Daterange" (ngSubmit)="filterSubmit()" autocomplete="off" class="flex flex-row">
      <mat-form-field appearance="fill" class="mr-2 xs:mr-0 xs:!w-full">
        <mat-label>Start Date</mat-label>
        <input matInput [min]="maxDateCheck" [matDatepicker]="dp3" placeholder="Choose a date" readonly="true"
          formControlName="from_date">
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3></mat-datepicker>
      </mat-form-field>
      <material-timepicker color="primary" label="From time" (change)="onChangeHour($event)" revertLabel="Remove"
        appearance="fill" [userTime]="exportTime" class="mr-2 xs:mr-0 xs:!w-full">
        <input matInput type="time" formControlName="start_time" readonly="true" id="timePickerr">
      </material-timepicker>
      <mat-form-field class="mr-2 xs:mr-0 xs:!w-full" appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [min]="Daterange.get('from_date').value" [disabled]="!Daterange.get('from_date').value"
          [matDatepicker]="dp1" placeholder="Choose a date" readonly="true" formControlName="to_date">
        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
        <mat-datepicker #dp1></mat-datepicker>
      </mat-form-field>
      <material-timepicker color="primary" (change)="onChangeHour2($event)" label="To time" appearance="fill"
        [userTime]="exportTime" class="something xs:!w-full mr-2 xs:mr-0">
        <input matInput readonly="true" formControlName="end_time" class="">
      </material-timepicker>
      <button mat-stroked-button color="primary" class="mr-2 xs:mr-0 xs:!w-full mb-4 xs:mb-0 top-2.5">Search</button>
      <button mat-stroked-button color="primary" (click)="clearDate()" class="mr-2 xs:mr-0 xs:!w-full mb-4 xs:mb-0 top-2.5">Clear</button>
    </form>
  </div>

  <div class="mobile-raw">
    <div *ngIf="store" class="px-5 xs:px-1">
      <!-- Pick And Click Ordering -->
      <div *ngIf="store.enable_pick_and_click_ordering" class="all-graph xs:flex xs:flex-col xs:!min-w-full xs:!px-0">
        <div class="graph-tworow xs:!min-w-full !grid !grid-cols-2 gap-4 xs:!flex xs:!flex-col xs:!justify-center xs:!items-center h-auto">
          <div>
            <div class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
              <div class="chart-controls">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="orderVolumeTimePc" class="app-font">
                  <mat-radio-button *ngFor="let opt of orderVolumeOptions" [value]="opt"
                    (change)="getOrderVolume($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="pcOrderVolumeData" class=" !h-full">
                <p class="chart-title">Gross Sales </p>
                <google-chart [type]="'ComboChart'" [data]="pcOrderVolumeData.rows" class="chart !w-full !h-full"
                  [columns]="pcOrderVolumeData.cols" [options]="orderVolumeChartOptions"></google-chart>
              </div>
              <div class="show-full-btn">
                <button mat-stroked-button color="primary" (click)="showFull('graph1')">Show Full</button>
              </div>
            </div>
          </div>
          <!-- Customer Traffic -->
          <div>
            <div class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
              <div class="chart-controls">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="customerTrafficPcs" class="app-font">
                  <mat-radio-button *ngFor="let opt of customerTrafficOptions" [value]="opt"
                    (change)="getCustomerTraffic($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="chart-controls -mt-5" style="margin-bottom: 0px;">
                <mat-radio-group aria-label="Select an option" class="app-font" [(ngModel)]="posOrOnlinePcs">
                  <mat-radio-button *ngFor="let opt of posOrOnline" [value]="opt"
                    (change)="getPosOnline($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>

              <div *ngIf="pcCustomerTrafficData" class=" !h-full">
                <p class="chart-title">Customer Traffic</p>
                <google-chart [type]="'ComboChart'" [data]="pcCustomerTrafficData.rows"
                  [columns]="pcCustomerTrafficData.cols" class="chart !w-full !h-full" style="padding: 4px;"
                  [options]="customerTrafficChartOptions"></google-chart>
              </div>
              <div class="show-full-btn">
                <button mat-stroked-button color="primary" (click)="showFull('graph2')">Show Full</button>
              </div>
            </div>
          </div>
        </div>

        <div class="graph-tworow xs:!min-w-full !grid !grid-cols-2 gap-4 xs:!flex xs:!flex-col xs:!justify-center xs:!items-center h-auto ">
          <!-- Line Chart -->
          <div>
            <div class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
              <div class="chart-controls">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="averageCheckSizePcs" class="app-font">
                  <mat-radio-button *ngFor="let opt of averageCheckSizeOptions" [value]="opt"
                    (change)="getAverageCheckSize($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="pcaverageCheckSizeData" class=" !h-full">
                <p class="chart-title">Average Check Size</p>
                <google-chart [type]="'LineChart'" [data]="pcaverageCheckSizeData.rows" class="chart !w-full !h-full"
                  [columns]="pcaverageCheckSizeData.cols" [options]="averageTicketSalesChart">
                </google-chart>
              </div>
              <div class="show-full-btn">
                <button mat-stroked-button color="primary" (click)="showFull('graph3')">Show Full</button>
              </div>
            </div>
          </div>
          <!-- Labor Cost -->
          <div>
            <div class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
              <div class="chart-controls">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="laborCostPcs" class="app-font">
                  <mat-radio-button *ngFor="let opt of laborCostOptions" [value]="opt"
                    (change)="getLaborCost($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class=" !h-full" *ngIf="laborCostData">
                <p class="chart-title">Labor Cost</p>
                <google-chart [type]="'ComboChart'" [data]="laborCostData.rows" class="chart !w-full !h-full"
                  [columns]="laborCostData.cols" [options]="laborCostChartOptions"></google-chart>
              </div>
              <div class="show-full-btn">
                <button mat-stroked-button color="primary" (click)="showFull('graph4')">Show Full</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Text Order Graphs -->
      <div *ngIf="store.enable_text_ordering" class="all-graph">
        <div class="graph-tworow xs:!min-w-full !grid !grid-cols-2 gap-4 xs:!flex xs:!flex-col xs:!justify-center xs:!items-center h-auto">
          <div>
            <div class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
              <div class="chart-controls">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="orderVolumeTimeTxt" class="app-font">
                  <mat-radio-button *ngFor="let opt of orderVolumeTxtOptions" [value]="opt"
                    (change)="getOrderVolume($event, 'text')">{{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="textOrderVolumeData" class=" !h-full">
                <p class="chart-title">Text Order - Order Volume</p>
                <google-chart [title]="'Text Order Volume'" [type]="'ComboChart'" [data]="textOrderVolumeData.rows"
                  class="chart" [columns]="textOrderVolumeData.cols"
                  [options]="orderVolumeChartOptions"></google-chart>
              </div>
              <div class="show-full-btn">
                <button mat-stroked-button color="primary" (click)="showFull('graph5')">Show Full</button>
              </div>
            </div>
          </div>

          <div>
            <div class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
              <div class="chart-controls">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="totalUserTimeTxt" class="app-font">
                  <mat-radio-button *ngFor="let opt of totalUserOptions" [value]="opt"
                    (change)="getTotalUser($event, 'text')">
                    {{opt|titlecase}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="textTotalUserData" class=" !h-full">
                <p class="chart-title">Text Order - Total Users</p>
                <google-chart [type]="'Line'" [data]="textTotalUserData.rows" class="chart"
                  [columns]="textTotalUserData.cols" [options]="orderVolumeChartOptions"></google-chart>
              </div>
              <div class="show-full-btn">
                <button mat-stroked-button color="primary" (click)="showFull('graph6')">Show Full</button>
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>

      <div style="clear: both;"></div>
    </div>
  </div>
</span>


<div *ngIf="showGraph && store">
  <div *ngIf="store.enable_pick_and_click_ordering">
    <!-- Pick And Click Ordering -->
    <span *ngIf="showGraphName == 'graph1'">
      <div class="all-data">
        <button mat-stroked-button color="primary" (click)="showFull('')">Back</button>
        <div class="chart-controls-test">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="orderVolumeTimePc" class="app-font">
            <mat-radio-button *ngFor="let opt of orderVolumeOptions" [value]="opt"
              (change)="getOrderVolume($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="chart-title-test">
        <p>Gross Sales</p>
      </div>
      <div *ngIf="pcOrderVolumeData" class="graph-data">
        <google-chart [type]="'ComboChart'" [data]="pcOrderVolumeData.rows" [columns]="pcOrderVolumeData.cols"
          [options]="orderVolumeChartOptions" class="chart-test"></google-chart>
      </div>
    </span>

    <!-- Customer Traffic -->
 <span *ngIf="showGraphName == 'graph2'">
  <div class="all-data">
    <button mat-stroked-button color="primary" (click)="showFull('')">Back</button>
    <div class="chart-controls-test">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="customerTrafficPcs" class="app-font">
        <mat-radio-button *ngFor="let opt of customerTrafficOptions" [value]="opt"
          (change)="getCustomerTraffic($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="chart-controls" style="margin-bottom: 0px;">
    <mat-radio-group aria-label="Select an option" class="app-font" [(ngModel)]="posOrOnlinePcs">
      <mat-radio-button *ngFor="let opt of posOrOnline" [value]="opt"
        (change)="getPosOnline($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="chart-title-test">
    <p>Customer Traffic</p>
  </div>
  <div *ngIf="pcCustomerTrafficData" class="graph-data">
    <google-chart [type]="'ComboChart'" [data]="pcCustomerTrafficData.rows" [columns]="pcCustomerTrafficData.cols"
      [options]="customerTrafficChartOptions" class="chart-test"></google-chart>
  </div>
  </span>

  <!-- Average Check Size -->
  <span *ngIf="showGraphName == 'graph3'">
    <div class="all-data">
      <button mat-stroked-button color="primary" (click)="showFull('')">Back</button>
      <div class="chart-controls-test">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="averageCheckSizePcs" class="app-font">
          <mat-radio-button *ngFor="let opt of averageCheckSizeOptions" [value]="opt"
            (change)="getAverageCheckSize($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="chart-title-test">
      <p>Average Check Size</p>
    </div>
    <div *ngIf="pcaverageCheckSizeData" class="graph-data">
      <google-chart [type]="'LineChart'" [data]="pcaverageCheckSizeData.rows"
        [columns]="pcaverageCheckSizeData.cols" [options]="averageTicketSalesChart" class="chart-test">
      </google-chart>
    </div>
  </span>

  <!-- Labor Cost -->
  <span *ngIf="showGraphName == 'graph4'">
    <div class="all-data">
      <button mat-stroked-button color="primary" (click)="showFull('')">Back</button>
      <div class="chart-controls-test">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="laborCostPcs" class="app-font">
          <mat-radio-button *ngFor="let opt of laborCostOptions" [value]="opt"
            (change)="getLaborCost($event, 'pc')">{{opt|titlecase}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="chart-title-test">
      <p>Labor Cost</p>
    </div>
    <div *ngIf="laborCostData" class="graph-data">
      <google-chart [type]="'ComboChart'" [data]="laborCostData.rows" 
      [columns]="laborCostData.cols" [options]="laborCostChartOptions" class="chart-test"></google-chart>
    </div>
  </span>

    <!-- Text Order - Order Volume -->
   <span *ngIf="showGraphName == 'graph5'">
      <div class="all-data">
        <button mat-stroked-button color="primary" (click)="showFull('')">Back</button>
        <div class="chart-controls-test">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="orderVolumeTimeTxt" class="app-font">
            <mat-radio-button *ngFor="let opt of orderVolumeTxtOptions" [value]="opt"
              (change)="getOrderVolume($event, 'text')">{{opt|titlecase}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="chart-title-test">
        <p>Text Order - Order Volume</p>
      </div>
      <div *ngIf="textOrderVolumeData" class="graph-data">
        <google-chart [title]="'Text Order Volume'" [type]="'ComboChart'" [data]="textOrderVolumeData.rows"
        class="chart-test" [columns]="textOrderVolumeData.cols"
        [options]="orderVolumeChartOptions"></google-chart>
      </div>
    </span>
   
  <!-- Text Order - Total Users -->
  <span *ngIf="showGraphName == 'graph6'">
    <div class="all-data">
      <button mat-stroked-button color="primary" (click)="showFull('')">Back</button>
      <div class="chart-controls-test">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="totalUserTimeTxt" class="app-font">
          <mat-radio-button *ngFor="let opt of totalUserOptions" [value]="opt"
            (change)="getTotalUser($event, 'text')">
            {{opt|titlecase}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="chart-title-test">
      <p>Text Order - Total Users</p>
    </div>
    <div *ngIf="textTotalUserData" class="graph-data">
      <google-chart [type]="'Line'" [data]="textTotalUserData.rows" class="chart-test"
      [columns]="textTotalUserData.cols" [options]="orderVolumeChartOptions"></google-chart>
    </div>
  </span>
  </div>
</div>
