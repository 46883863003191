import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
@Component({
  selector: 'app-gift-cards-upload-image',
  templateUrl: './gift-cards-upload-image.component.html',
  styleUrls: ['./gift-cards-upload-image.component.scss']
})
export class GiftCardsUploadImageComponent implements OnInit {
  name;
  ourFile: File; // hold our file
  imageSrc;
  hasError = false
  @Input() imageUrl;
  @Input() maxFileSize = -1;
  @Input() disabled = false;
  @Output() imageFileEmitter = new EventEmitter<File[]>();
  @Output() imageStringEmitter = new EventEmitter<string>();
  @ViewChild('fileOpen',{static: false}) fileOpen: ElementRef;
  reader = new FileReader();
  constructor(public dialog: MatDialog,) { }
  ngOnInit() {
    this.reader.onload = e => {
      this.imageSrc = this.reader.result
      this.imageFileEmitter.emit([this.ourFile])
      this.imageStringEmitter.emit(this.imageSrc)
    };
  }
  openFile(){
    document.getElementById('fileOpen').click();
  }
  fileChange(files: File[]) {
    console.log(files);
    if (files.length > 0) {
      this.ourFile = files[0];
      if((+this.maxFileSize > 0 && this.ourFile.size <= +this.maxFileSize) || +this.maxFileSize < 0){
        this.reader.readAsDataURL(this.ourFile);
        this.hasError = false;
      }else{
        this.hasError = true;
        this.ourFile = null;
        this.imageUrl = null;
        this.alertPopup('', 'File size exceeds the maximum limit of 1MB. Please select image under 1MB');
      }
    }
  }
  alertPopup(name,msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
  }
  delete(){
    this.ourFile = undefined;
    this.imageSrc =  undefined;
    this.imageFileEmitter.emit(undefined);
    this.imageStringEmitter.emit(undefined);
    document.getElementById('fileOpen');
    this.fileOpen.nativeElement.value = null;
  }
}