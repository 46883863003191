import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Store} from 'src/app/_models/store';
import {OfferV2RedeemHistoryDataSource} from 'src/app/_datasources/offer-v2-redeem-history-datasource';
import {tap} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {RedeemHistory} from 'src/app/_models/order';
import {StoreService} from 'src/app/_services/store.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-offer-redemption-list-message-table',
  templateUrl: './offer-redemption-list-message-table.component.html',
  styleUrls: ['./offer-redemption-list-message-table.component.scss']
})
export class OfferRedemptionListMessageTableComponent implements OnInit {

  displayedColumns: string[] = ['id', 'redeemed_at', 'phone_number', 'offer', 'order_total', 'discount'];
  dataSource : OfferV2RedeemHistoryDataSource;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  @Input() store:Store;
  @Input() refresh: Subject<boolean> = new Subject<boolean>();

  search;
  startDate;
  endDate;

  constructor(private storeService: StoreService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new OfferV2RedeemHistoryDataSource(this.storeService)
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = store
        this.dataSource.loadRedeemHistory(+store.id);
      }
    })

    this.refresh.subscribe(data => {
      this.loadRedeemHistory()
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadRedeemHistory()))
      .subscribe();
  }

  loadRedeemHistory() {
    this.dataSource.loadRedeemHistory(+this.store.id, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  searchOrders(any:any){
    this.loadRedeemHistory()
  }

  onRowClicked(row){
    let botOrder = Object.assign(new RedeemHistory(), row)
  }

  reset(){
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.loadRedeemHistory();
  }

  undo(element){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to undo redemption "+element.redeem_id+"?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
    // if(confirm("Are you sure to undo redemption "+element.redeem_id+"?")) {

    // }
  }
}
