import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DataRow} from 'src/app/_models/data-row';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-mod-menu-item',
  templateUrl: './mod-menu-item.component.html',
  styleUrls: ['./mod-menu-item.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ModMenuItemComponent implements OnInit {

  @Input() menuGroup
  @Input() store

  expandedElement = new Map<string, any>();
  displayedColumns = ['name'];
  tempDs;

  constructor(private menuService:MenuServiceV2) { }

  ngOnInit() {
    if(this.menuGroup){
      this.getDataSource(this.menuGroup)
    }
  }

  getDataSource(menuGroup){
    const rows = [];
    this.menuService.getStoreMenuGroup(menuGroup.store, menuGroup.id, '').subscribe(data =>{
      this.menuGroup = data
      rows.push(new DataRow("menu_group", menuGroup, new UntypedFormGroup({
        id: new UntypedFormControl(menuGroup.id, Validators.required),
        name: new UntypedFormControl(menuGroup.name, Validators.required)
      })))

      data.menu_items.map(mi => new DataRow('menu_item', mi, new UntypedFormGroup({

      }))).forEach(mi => {
        rows.push(mi)
      })
      this.tempDs = new MatTableDataSource(rows)
    })
  }

  isExpanded(parent, element){
    let currentExpanded = this.expandedElement.get(parent)
    if(currentExpanded && currentExpanded.data == element.data){
      return 'expanded';
    }else{
      return 'collapsed';
    }
  }

  expandRow(parent, element){
    this.expandedElement = this.expandedElement.set(parent, element)
  }

}
