<mat-accordion>
  <mat-expansion-panel [expanded]="panelOpenState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2 class="dim-color lean-font">{{printer?.name | titlecase}}</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="flex flex-row xw-full justify-between items-center">
      <p class="text-base">Printer Name</p>
      <p class="text-base">{{printer?.name | titlecase}}</p>
      <button mat-stroked-button class="xs:mx-3" (click)="edit(printer)">Edit</button>
    </div>
    <div class="p-3">
      <mat-divider></mat-divider>
    </div>


    <div class="flex flex-row xw-full justify-between items-center">
      <p class="text-sm"></p>
      <p class="text-sm"></p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="printer?.heartbeat">
      <p class="text-sm">Printer Status</p>
      <p class="text-sm">{{printer?.heartbeat && printer?.heartbeat.is_online ? 'Online' : 'Offline'}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="printer?.heartbeat">
      <p class="text-sm">Last checked in</p>
      <p class="text-sm">{{ printer?.heartbeat.last_seen | date:'hh:mm a EEE MMM dd, y'}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="store.has_order_occasion_pickup">
      <p class="text-sm">Printouts per pickup order</p>
      <p class="text-sm">{{printer?.pickup_count}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="store.has_order_occasion_delivery">
      <p class="text-sm">Printouts per delivery order</p>
      <p class="text-sm">{{printer?.delivery_count}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="store.has_order_occasion_curbside">
      <p class="text-sm">Printouts per curbside order</p>
      <p class="text-sm">{{printer?.curbside_count}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="store.has_order_occasion_dine_in">
      <p class="text-sm">Printouts per dine-in order</p>
      <p class="text-sm">{{printer?.dine_in_count}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="store.has_order_occasion_tableside">
      <p class="text-sm">Printouts per tableside order</p>
      <p class="text-sm">{{printer?.tableside_count}}</p>
    </div>

    <div class="flex flex-row xw-full justify-between items-center" *ngIf="store.has_order_occasion_tableside">
      <p class="text-sm">Printouts per kitchen print</p>  
      <p class="text-sm">{{printer?.kitchen_count}}</p>
    </div>  

    <div class="flex flex-row xw-full justify-between items-center">
      <p class="text-sm">Include Kitchen Format</p>
      <p class="text-sm">{{printer?.has_kitchen_format ? 'Yes' : 'No'}}</p>
    </div>
  </mat-expansion-panel>
</mat-accordion>
