<form class="fixActionRow">
  <p class="msg">
    Edit Total Break Time Manually?
  </p>
  <mat-dialog-content>
    <div mat-dialog-actions class="act">
      <p>Break Time</p>
    </div>

    <div class="act">
      <mat-form-field>
        <input matInput class="inputL" tabindex="-1" step="2" [value]="breakTime" #breaktime>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="act">
    <button mat-stroked-button mat-dialog-close tabindex="-1" class="buttonCancel">Cancel</button>
    <button mat-flat-button color="primary" type="button" (click)="editAddBreakTime(breaktime.value)" tabindex="-1"
      class="buttonConfirm  apply-btn-colr">
      Confirm</button>
  </div>
</form>