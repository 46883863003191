<div
  class="px-4 py-2 xs:px-3 xs:py-3 md:px-6 md:py-4 lg:px-8 lg:py-6 xl:px-12 xl:py-8 mx-auto w-full h-full flex flex-col">
  <form class="form w-full flex-grow flex flex-col" [formGroup]="form" (ngSubmit)="updateModifier(form.value)">
    <div class="flex justify-between items-center pb-3">
      <h1>Edit Modifier</h1>
      <div class="flex gap-4">
        <button mat-stroked-button class="cancel-btn" color="primary" (click)="onNoClick($event)">Cancel</button>
        <button mat-stroked-button class="save-btn" color="primary" type="submit" [disabled]="!form.valid">Save</button>
      </div>
    </div>
    <!-- Scrollable Section for Form Fields -->
    <div class="overflow-y-auto flex-grow">
      <div class="flex flex-col gap-4 vxs:gap-2 xs:gap-2 md:gap-4 lg:gap-6">

        <!-- First Row of Fields -->
        <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-5 gap-4 vxs:gap-2 xs:gap-2 md:gap-4 lg:gap-6">
          <mat-form-field class="w-full">
            <input matInput placeholder="Modifier Name" formControlName="name">
          </mat-form-field>

          <mat-form-field class="w-full">
            <input matInput placeholder="Upcharge (USD)" formControlName="upcharge" type="number">
          </mat-form-field>

          <mat-form-field class="w-full">
            <input matInput placeholder="Food Cost" formControlName="food_cost" type="number">
          </mat-form-field>

          <mat-form-field class="w-full">
            <mat-select formControlName="max_mod_units" placeholder="Max Mod Units">
              <mat-option *ngFor="let mod of mods" [value]="mod.value">
                {{ mod.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-full">
            <mat-select formControlName="reset" placeholder="Reset">
              <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{ opt.value }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox class="!w-auto" formControlName="has_quantity">
            Quantity
          </mat-checkbox>

          <mat-checkbox class="!w-auto" formControlName="fixed_price">
            Fixed Price
          </mat-checkbox>


          <mat-checkbox class="!w-auto" formControlName="is_available">
            Available
          </mat-checkbox>

          <mat-checkbox class="!w-auto" formControlName="is_pos_only">
            POS Only
          </mat-checkbox>

          <mat-checkbox class="!w-auto" formControlName="has_half_mod">
            Half Mod
          </mat-checkbox>

          <span *ngIf="form.value.has_half_mod" class="w-full">
            <mat-form-field class="w-full">
              <input matInput placeholder="Half Mod Upcharge (USD)" formControlName="half_mod_upcharge" type="number">
            </mat-form-field>
          </span>

        </div>
      </div>
    </div>
  </form>
</div>