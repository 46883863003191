import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class TerminalStatusService {

  private source = new BehaviorSubject<any>(null);
  current = this.source.asObservable();

  updateStatus(terminal){
    this.source.next(terminal);
  }

}
