import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BotOrder} from '../_models/order';

@Injectable()
export class ReceiptService{
  private source = new BehaviorSubject<BotOrder>(null);
  current = this.source.asObservable();
  constructor(){}
  changeBotOrder(order:BotOrder){
    this.source.next(order);
  }
}
