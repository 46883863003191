import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-advanced-css-dialog',
  templateUrl: './advanced-css-dialog.component.html',
  styleUrls: ['./advanced-css-dialog.component.scss']
})
export class AdvancedCssDialogComponent {
  submitted = false;
  isLoading = false;
  editCssForm: UntypedFormGroup;
  storeId: any;
  constructor(
    public fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<AdvancedCssDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.GetAdvanceCssScreen()
    this.storeId = localStorage.getItem("currentStore");
    this.editCssForm = this.fb.group({
      advance_css: [''],
    })

  }

  GetAdvanceCssScreen() {
    this.digitalDisplayService.GetAdvanceCssScreen(this.data.id).subscribe((data) => {
      this.editCssForm.patchValue({
        advance_css: data.data[0].advance_css
      })
    })
  }


  editCssFormFormSubmit() {
    const validationForm = this.editCssForm.status;
    if (validationForm == "VALID") {
      const formData = new FormData();
      formData.append("store_id", this.storeId);
      formData.append("advance_css", this.editCssForm.value.advance_css);
      this.isLoading = true;
      this.digitalDisplayService.AdvanceCssScreen(this.data.id, formData).subscribe((data) => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      });
    }
  }

}
