import {Component, OnInit} from '@angular/core';
import {LoyaltyPointsService} from 'src/app/_services/loyalty-points.service';

@Component({
  selector: 'app-loyalty-settings',
  templateUrl: './loyalty-settings.component.html',
  styleUrls: ['./loyalty-settings.component.scss']
})
export class LoyaltySettingsComponent implements OnInit {


  storeId: any;
  pointsList: any;
  constructor(public loyaltyPointsService: LoyaltyPointsService) { }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.getPointsList();

  }

  getPointsList() {
    this.loyaltyPointsService.getTierlist(this.storeId).subscribe((data: any) => {
      this.pointsList = data.data;
    })
  }

  updatePointValue(p, cv, c) {
    const sendData = {
      "id": c.id,
      "points": p,
      "card_value": cv
    }
    this.loyaltyPointsService.updatePointsValue(sendData).subscribe((data: any) => {
    })
  }

  noTierRow() {
    const sendData = {
      "points": 0,
      "card_value": 0,
      "store_id": this.storeId
    }
    this.loyaltyPointsService.createTierPoints(sendData).subscribe((data: any) => {
      this.getPointsList();
    })
  }


  addTierRow(row) {
    const sendData = {
      "id": row.id,
      "points": row.points,
      "card_value": row.card_value,
      "store_id": row.store
    }
    this.loyaltyPointsService.createTierPoints(sendData).subscribe((data: any) => {
      this.getPointsList();
    })
  }

  deleteTierRow(row) {
    const sendData = {
      "id": row.id,
      "points": row.points,
      "card_value": row.card_value,
      "store_id": row.store,
      "is_deleted": true
    }
    this.loyaltyPointsService.deletedTier(sendData).subscribe((data: any) => {
      this.getPointsList();
    })
  }

}
