import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText || typeof searchText !== 'string') {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      // Add a check to ensure item is a string before calling toLowerCase
      const itemText = (item.display_name || ''|| item.name).toString().toLowerCase();
      return itemText.includes(searchText);
    });
  }
}
