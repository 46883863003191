import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CampaignService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getCampaignProgress(campaignId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/campaign/${campaignId}/progress/`,
      this.httpOptions
    );
  }

  getCampaignCustomList(storeId){
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/campaign/custom-list/${storeId}/`,
      this.httpOptions
    );
  }

  getCampaignListUsers(customListId,
                       pageNumber = 0,
                       pageSize = 10,
                       filter = '') {
    const params = new HttpParams()
      .set('campaign_id', customListId)
      .set('page_number', pageNumber.toString())
      .set('page_size', pageSize.toString())
      .set('filter', filter);

    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/campaign/custom-list/${customListId}/users/`,
      {params}
    );
  }
}
