<mat-tab-group>
  <mat-tab label="Feedback Details">
    <ng-template matTabContent>
      <app-feedback-details></app-feedback-details>
    </ng-template>
  </mat-tab>
  <mat-tab label="Dashboard">
    <ng-template matTabContent>
      <app-feedback-dashboard></app-feedback-dashboard>
    </ng-template>
  </mat-tab>
</mat-tab-group>