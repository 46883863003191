import {Component, OnInit} from '@angular/core';
import {StoreService} from '../_services/store.service';
import {Store, StoreMerchant} from '../_models/store';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  isLoading: boolean = true;
  store: Store;
  storeMerchant: StoreMerchant;

  breakpoint;
  browserName = '';
  constructor(public storeService: StoreService) { }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 425) ? 1 : 2;

    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        const slug = this.store.url_slug;
        localStorage.setItem('slug', slug);
        localStorage.setItem('company', this.store.store_name);
        localStorage.setItem('address', this.store.address);
        localStorage.setItem('city', this.store.city);
        localStorage.setItem('geo_state', this.store.state_geographic);
        localStorage.setItem('zip_code', this.store.zip_code);

        this.storeService.getMerchant(store).subscribe(merchant => {


          console.log('nnnnnnnnnnnnnnnnnnnnnnn',merchant)
          this.storeMerchant = Object.assign(new StoreMerchant(), merchant)
          if (this.storeMerchant) {
            this.isLoading = false;
          }
        });
      }
    });

    this.browserName = this.detectBrowserName();
    localStorage.setItem('browser', this.browserName);

  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 425) ? 1 : 2;
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}
