<mat-toolbar class="header">
  <div class="image-container">
    <img src="https://storage.googleapis.com/menus/static/frontend/img/avatar-person.svg" width="30px" height="30px" />
  </div>
  <span *ngIf="user">{{"*"+user?.phone_number.substring(user?.phone_number.length - 5)}}</span>
  <span class="toolbar-spacer"></span>
  <button mat-icon-button (click)="sidebar.close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="content" *ngIf="form">
  <div class="row">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" >
      <mat-form-field class="full-width" mat-focused>
        <mat-label>Name</mat-label>
        <input matInput #name placeholder="Name" formControlName="name">
      </mat-form-field>

      <mat-checkbox formControlName="is_blocked">Block</mat-checkbox>
      <br/><br/>
      <div class="action">
        <button mat-stroked-button type="button" (click)="sidebar.close()">Cancel</button>
        <button mat-stroked-button class="bg" color="primary" type="submit">Update</button>
      </div>
    </form>
  </div>
</div>

