import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OrdersWithPayment} from '../_models/order';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CurrentOrderService {

  currentOrder: OrdersWithPayment;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private source = new BehaviorSubject<OrdersWithPayment>(null);
  current = this.source.asObservable();

  constructor(private http: HttpClient) {}

  changeCurrentOrder(orderWithPayment:OrdersWithPayment){
    this.currentOrder = orderWithPayment;
    this.source.next(orderWithPayment);
  }


  isAvailable() {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/order-validation/${this.currentOrder.bot_order.order_hash}/is_valid_order/`,
      this.httpOptions
    );
  }

}
