import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {StoreService} from '../_services/store.service';
import {DataSourceResult, OfferV2} from '../_models/campaign';


export class OffersV2DataSource implements DataSource<OfferV2> {

  private redeemHistorySubject = new BehaviorSubject<OfferV2[]>([]);
  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private storeService: StoreService) {}

  connect(collectionViewer: CollectionViewer): Observable<OfferV2[]> {
      return this.redeemHistorySubject.asObservable();
  }

  getTotal(){
    return this.totalSubject.getValue();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.redeemHistorySubject.complete();
      this.loadingSubject.complete();
  }

  loadOffers(storeId:number, filter = '',
  startDate=null, endDate=null,
  sortField='id', sortOrder='desc',
  pageNumber=0, pageSize=10) {
      this.loadingSubject.next(true);
      this.storeService.getStoreOffersV2(storeId, filter, startDate, endDate, sortField, sortOrder, pageNumber, pageSize).pipe(
          catchError(() => of(new DataSourceResult<OfferV2>())),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(history => {
        this.redeemHistorySubject.next(history.data);
        this.totalSubject.next(history.count);
      });
  }

  loadOfferList(storeId:number, filter = '',
  startDate=null, endDate=null,
  sortField='id', sortOrder='desc',
  pageNumber=0, pageSize=10) {
      this.loadingSubject.next(true);
      this.storeService.getStoreOfferListV2(storeId, filter, startDate, endDate, sortField, sortOrder, pageNumber, pageSize).pipe(
          catchError(() => of(new DataSourceResult<OfferV2>())),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(history => {
        this.redeemHistorySubject.next(history.data);
        this.totalSubject.next(history.count);
      });
  }
}
