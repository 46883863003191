import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {

  constructor(private updates: SwUpdate) {
    console.log('PwaUpdateService initialized');
  
    this.updates.versionUpdates
      .pipe(filter(event => event.type === 'VERSION_READY'))
      .subscribe(event => {
        console.log('New version is ready:', event);
  
        if (confirm('A new version of the application is available. Do you want to reload to update?')) {
          console.log('User confirmed the update', this.updates);
          
          // Check if the app is running as a PWA or Web App
          if (this.updates.isEnabled) {
            // If service worker updates are enabled (for PWA), activate and reload
            this.updates.activateUpdate().then(() => {
              console.log('Service worker activated, reloading app');
              document.location.reload();
            }).catch(error => {
              console.error('Error activating update:', error);
            });
          } else {
            // If not in PWA mode, just reload the web app
            console.log('Not in PWA, reloading web app');
            document.location.reload();
          }
        } else {
          console.log('User declined the update');
        }
      }, error => {
        console.error('Error subscribing to version updates:', error);
      });
  }
}
