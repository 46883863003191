import { Component } from '@angular/core';
import {NMISettlementService} from '../_services/nmi-settlement.service';
import {StoreService} from '../_services/store.service';
import {Store} from '../_models/store';
import {NmiSettlementAction,NmiSettlementAggregate, NmiSettlementPaged} from '../_models/nmi-settlement';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss']
})
export class SettlementsComponent {

  orderCounterFilter: string;
  store: Store;
  settlements: NmiSettlementPaged;
  aggregates: any;
  settlementForm = this.formBuilder.group({
    filter: [''],
    start_date: [''],
    end_date: ['']
  });

  constructor(private settlementService: NMISettlementService,
              private storeService: StoreService,
              private formBuilder: FormBuilder) {

    this.storeService.current.subscribe(store => {
      if(store){
        this.store = store;
        this.onSubmit();
      }
    })
  }

  clearSearch(){
    this.orderCounterFilter = '';
    this.settlementForm.reset();
  }

  onSubmit(){
    console.log(this.settlementForm.value);
    this.settlementService.getSettlements(this.store.id, this.settlementForm.value).subscribe(data => {
      this.settlements = data
    });

    this.settlementService.getSettlementsAggregate(this.store.id, this.settlementForm.value).subscribe(data => {
      this.aggregates = data;
    })
  }

  loadMore(){
    if(this.settlements.next){
      if (this.settlements.next && !this.settlements.next.startsWith('https://')) {
        this.settlements.next = this.settlements.next.replace('http://', 'https://');
      }
      this.settlementService.getNextPage(this.settlements.next).subscribe(result => {
        this.settlements.results.push(...result.results);
        this.settlements.next = result.next;
      })
    }
  }

  getSettlementDate(dateStr: string){
    return moment(dateStr, 'YYYYMMDDhhmmss').toDate()
  }

  getSettledBatches(){
    if(this.aggregates){
      return this.aggregates.filter(agg => agg.settlement_date !== null);
    }else{
      return [];
    }
  }

  getNonSetledBatches(){
    if(this.aggregates){
      return this.aggregates.filter(agg => agg.settlement_date === null);
    }else{
      return [];
    }
  }

  getFee(ag: NmiSettlementAggregate){
    return parseFloat(ag.pos_service_charge)
      + parseFloat(ag.pos_service_charge_from_dollar)
      + parseFloat(ag.pos_service_charge_from_percent)
      + parseFloat(ag.online_service_charge)
      + parseFloat(ag.online_service_charge_from_dollar)
      + parseFloat(ag.online_service_charge_from_percent)
  }
}
