<div class="section xs:!box-shadow-md xs:!bg-white xs:!rounded-md xs:!w-full xs:!h-[450px] xs:!mt-[20%] xs:!mb-0 xs:hidden">
  <div class="opt-desc">
    <input type="password" placeholder="- - - -" [ngModel]="result" readonly class="w-full">
    <div class="clear"></div>
  </div>

  <div class="first-row">
    <input type="button" name="" value="1" class="global drop-shadow-md" (click)="pressNum('1')">
    <input type="button" name="" value="2" class="global drop-shadow-md" (click)="pressNum('2')">
    <input type="button" name="" value="3" class="global drop-shadow-md" (click)="pressNum('3')">
    <button class="global3" (click)="clockIn()" [disabled]="input?.length < 4 || clockedIn==true || errorMessage==true">
      CLOCK IN
    </button>
  </div>
  <div class="second-row">
    <input type="button" name="" value="4" class="global drop-shadow-md" (click)="pressNum('4')">
    <input type="button" name="" value="5" class="global drop-shadow-md" (click)="pressNum('5')">
    <input type="button" name="" value="6" class="global drop-shadow-md" (click)="pressNum('6')">
    <button class="global-primary" (click)="clockOut()"
      [disabled]="input?.length < 4 || clockedIn==false || errorMessage==true">
      CLOCK OUT
    </button>
  </div>
  <div class="third-row">
    <input type="button" name="" value="7" class="global drop-shadow-md" (click)="pressNum('7')">
    <input type="button" name="" value="8" class="global drop-shadow-md" (click)="pressNum('8')">
    <input type="button" name="" value="9" class="global drop-shadow-md" (click)="pressNum('9')">
    <button class="global4" (click)="punchBreak()"
      [disabled]="input?.length < 4 || clockedIn==false || errorMessage==true">
      {{breakString}}
    </button>

  </div>
  <div class="fourth-row">
    <input type="button" name="" value="0" class="global drop-shadow-md" (click)="pressNum('0')">
    <input type="button" name="" value="C" class="global drop-shadow-md" (click)="allClear()"
      [ngStyle]="{'background-color': errorMessage ? 'rgba(47, 211, 43, 0.75)' : '' }">
    <button class="global drop-shadow-md flex flex-row items-center justify-center" (click)="clear()">
      <span>
        <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
            fill="#636363" />
        </svg>
      </span>
    </button>
  </div>
</div>

<!-- MOBILE VIEW -->
<div class="section !w-full hidden xs:block" style="padding: 20px 20px 20px 35px !important;">
  <div class="opt-desc">
    <input type="password" placeholder="- - - -" [ngModel]="result" readonly class="w-full">
    <div class="clear"></div>
  </div>
  <div class="grid grid-cols-3 mb-2.5">
    <input type="button" name="" value="1" class="global drop-shadow-md" (click)="pressNum('1')">
    <input type="button" name="" value="2" class="global drop-shadow-md" (click)="pressNum('2')">
    <input type="button" name="" value="3" class="global drop-shadow-md" (click)="pressNum('3')">
  </div>
  <div class="grid grid-cols-3 mb-2.5">
    <input type="button" name="" value="4" class="global drop-shadow-md" (click)="pressNum('4')">
    <input type="button" name="" value="5" class="global drop-shadow-md" (click)="pressNum('5')">
    <input type="button" name="" value="6" class="global drop-shadow-md" (click)="pressNum('6')">
  </div>
  <div class="grid grid-cols-3 mb-2.5">
    <input type="button" name="" value="7" class="global drop-shadow-md" (click)="pressNum('7')">
    <input type="button" name="" value="8" class="global drop-shadow-md" (click)="pressNum('8')">
    <input type="button" name="" value="9" class="global drop-shadow-md" (click)="pressNum('9')">
  </div>
  <div class="grid grid-cols-3 mb-2.5">
    <input type="button" name="" value="0" class="global drop-shadow-md" (click)="pressNum('0')">
    <input type="button" name="" value="C" class="global drop-shadow-md" (click)="allClear()"
      [ngStyle]="{'background-color': errorMessage ? 'rgba(47, 211, 43, 0.75)' : '' }">
    <button class="global drop-shadow-md flex flex-row items-center justify-center" (click)="clear()">
      <span>
        <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
            fill="#636363" />
        </svg>
      </span>
    </button>
  </div>
  <div class="grid">
    <button class="global3 !h-[75px] !mb-2.5" (click)="clockIn()" [disabled]="input?.length < 4 || clockedIn==true || errorMessage==true">
      CLOCK IN
    </button>
    <button class="global-primary !h-[75px] !mb-2.5" (click)="clockOut()"
      [disabled]="input?.length < 4 || clockedIn==false || errorMessage==true">
      CLOCK OUT
    </button>
    <button class="global4 !h-[75px]" (click)="punchBreak()"
    [disabled]="input?.length < 4 || clockedIn==false || errorMessage==true">
    {{breakString}}
  </button>
  </div>
</div>