export default class Utils{
  static getPlainPhoneNumberFromString(phoneNumber:string){
    let ph = phoneNumber.replace(/[\s()-]/g, '');
    return '+1' + ph.substring(0,10);
  }

  static getFormattedPhoneNumber(phoneNumber){
    return phoneNumber.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4');
  }

  static toISOLocal(d) {
    var z  = n =>  ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off > 0? '-' : '+';
    off = Math.abs(off);

    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  +
           z(d.getMinutes()) + ':' +
           z(d.getSeconds()) + '.' +
           zz(d.getMilliseconds()) +
           sign + z(off/60|0) + ':' + z(off%60);
  }

  static groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});

}
