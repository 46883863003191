import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { StoreService } from 'src/app/_services/store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from 'src/app/_models/store';

@Component({
  selector: 'app-end-drawer-confirmation',
  templateUrl: './end-drawer-confirmation.component.html',
  styleUrls: ['./end-drawer-confirmation.component.scss']
})
export class EndDrawerConfirmationComponent {
  now = moment().local().toDate();
  private destroySubject: Subject<void> = new Subject();
  store: Store;
  isLoading: boolean = false;
  constructor(public dialogRef: MatDialogRef<EndDrawerConfirmationComponent>,
    private storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit() {
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });

  }

  done() {
    this.dialogRef.close(true);
  }

  printReport(n, name) {
    this.isLoading = true;
    if (name == 'period') {
      this.printPeriodReport(n, name, 1)
      this.printPeriodReport(n, name, 2)
      this.printPeriodReport(n, name, 3)

    } else {
      this.printDrawerReport(n, name);
    }
  }

  printDrawerReport(n, name) {
    const date = new Date(this.datePipe.transform(this.data.drawer_start_time, 'yyyy-MM-dd HH:mm:ss', this.store.time_zone));
    const endDate = new Date(this.datePipe.transform(this.data.drawer_end_time, 'yyyy-MM-dd HH:mm:ss', this.store.time_zone));
    this.storeService
      .printStoreMiniReport(
        this.data.store,
        this.data.terminal_name,
        date,
        endDate,
        { hour: date.getHours(), min: date.getMinutes() },
        { hour: endDate.getHours(), min: endDate.getMinutes()},
        this.data.terminal_name,
        localStorage.getItem("currentPIN"),
        n,
        null,
        ''
      )
      .subscribe(
        (data) => {
          this._snackBar.open("Report Printed Successfully!", "OK", {
            duration: 1000,
          });
          this.isLoading = false;


        },
        (error) => {
          this._snackBar.open("Unable to print report", "OK");
        }
      );
  }

  printPeriodReport(n, name, smryChoice) {
    const date = new Date(this.datePipe.transform(this.data.drawer_start_time, 'yyyy-MM-dd HH:mm:ss', this.store.time_zone));
    const endDate = new Date(this.datePipe.transform(this.data.drawer_end_time, 'yyyy-MM-dd HH:mm:ss', this.store.time_zone));
    this.storeService
      .printStoreMiniReportAll(
        this.data.store,
        this.data.terminal_name,
        date,
        endDate,
        { hour: date.getHours(), min: date.getMinutes() },
        { hour: endDate.getHours(), min: endDate.getMinutes()},
        this.data.terminal_name,
        n,
        null,
        smryChoice
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this._snackBar.open("Report Printed Successfully!", "OK", {
            duration: 1000,
          });
        },
        (error) => {
          this._snackBar.open("Unable to print report", "OK");
        }
      );
  }

}

