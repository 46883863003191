import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const mqttsession = localStorage.getItem('mqttsession');
if (!mqttsession){
  localStorage.setItem('mqttsession', Date.now().toString());
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/ngsw-worker.js')
    .then(registration => {
      console.log('Angular Service Worker registered with scope:', registration.scope);
    })
    .catch(err => console.error('Service Worker registration failed', err));
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
