import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  alertAddress: any;
  alertTitle:string;
  form: UntypedFormGroup;
  
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.alertTitle = "Delivery Out Of Range!"
    this.alertAddress = `The address you entered '${this.data.title}' is outside of the current delivery area.`
  }

  ngOnInit() {
    // this.alertAddress = `The address you entered '${this.data.title}' is outside of the current delivery area.`
  }

  close(event){
    this.dialogRef.close(event);
  }
  save(event) {
    this.dialogRef.close(event)
  }

}
