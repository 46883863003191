<form [formGroup]="form" class="fixActionRow">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <h1 mat-dialog-title>Service Areas</h1>
  <div class="ml-6">
    <p class="message">Enter the distance radius where your business provides services and deliveries.</p>
  </div>
  <div mat-dialog-content class="dialog-content">
    <span *ngIf="!isLoading && store">
      <div class="form-content">
        <mat-button-toggle-group formControlName="service_type" #group="matButtonToggleGroup"
          (change)="onTypeChange($event)">
          <mat-button-toggle value="RADIUS">Radius</mat-button-toggle>
          <mat-button-toggle value="AREA">Area</mat-button-toggle>
        </mat-button-toggle-group>
        <br />

        <ng-container *ngIf="form.value.service_type === 'RADIUS'">
          <mat-form-field>
            <mat-label>Radius</mat-label>
            <input matInput formControlName="radius" type="number">
          </mat-form-field> Miles
        </ng-container>

        <ng-container *ngIf="form.value.service_type === 'AREA'">
          <div formArrayName="areas" *ngFor="let item of form.controls['areas'].controls; let i = index">
            <ng-container [formGroupName]="i">
              <div *ngIf="form.value.areas[i].name; then existBlock else newBlock"></div>
              <ng-template #newBlock>
                <mat-form-field class="area-selection">
                  <mat-label>Service Area</mat-label>
                  <input matInput matValidateAddress matGoogleMapsAutocomplete
                    #matGoogleMapsAutocomplete="matGoogleMapsAutocomplete" placeholder="Search Location"
                    class="form-control" [type]="['geocode']" [country]="us"
                    (onAutocompleteSelected)="onAutocompleteSelected(item, $event, i)"
                    (onLocationSelected)="onLocationSelected($event)">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Charge</mat-label>
                  <input matInput type="number" formControlName="charge">
                </mat-form-field>

                <button mat-icon-button (click)="deleteAreaEntry(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-template>
              <ng-template #existBlock>
                <mat-form-field class="area-selection">
                  <mat-label>Service Area</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Charge</mat-label>
                  <input matInput type="number" formControlName="charge">
                </mat-form-field>

                <button mat-icon-button (click)="deleteAreaEntry(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-template>
            </ng-container>
          </div>
          <button type="button" mat-stroked-button color="primary" (click)="addAreaEntry()">Add Area</button>
        </ng-container>

      </div>
    </span>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-flat-button (click)="submitAreaFrom()" color="primary" class="apply-btn-colr"
      [disabled]="!form.valid">Apply</button>
  </div>
</form>