<app-progress-spinner *ngIf="isLoading"></app-progress-spinner>
<div class="flex flex-row flex-wrap xs:flex-col	xs:flex-row-none items-center !w-full my-7 px-5">
  <div class="example-button-row" style="margin-bottom: 19px;">
    <button mat-stroked-button (click)="openDialog()" color="primary">Manage Filters</button>
  </div>
  <form [formGroup]="Daterange" (ngSubmit)="filterSubmit()" autocomplete="off" class="flex flex-row pl-2">
    <mat-form-field appearance="fill" class="mr-2 xs:mr-0 xs:!w-full">
      <mat-label>Start Date</mat-label>
      <input matInput [min]="maxDateCheck" [matDatepicker]="dp3" placeholder="Choose a date" readonly="true"
        formControlName="from_date">
      <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
      <mat-datepicker #dp3></mat-datepicker>
    </mat-form-field>
    <material-timepicker color="primary" label="From time" (change)="onChangeHour($event)" revertLabel="Remove"
      appearance="fill" [userTime]="exportTime" class="mr-2 xs:mr-0 xs:!w-full">
      <input matInput type="time" formControlName="from_time" readonly="true" id="timePickerr">
    </material-timepicker>
    <mat-form-field class="mr-2 xs:mr-0 xs:!w-full" appearance="fill">
      <mat-label>End Date</mat-label>
      <input matInput [min]="Daterange.get('from_date').value" [disabled]="!Daterange.get('from_date').value"
        [matDatepicker]="dp1" placeholder="Choose a date" readonly="true" formControlName="to_date">
      <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
      <mat-datepicker #dp1></mat-datepicker>
    </mat-form-field>

    <material-timepicker color="primary" (change)="onChangeHour2($event)" label="To time" appearance="fill"
      [userTime]="exportTime" class="something xs:!w-full mr-2 xs:mr-0">
      <input matInput readonly="true" formControlName="to_time" class="">
    </material-timepicker>

    <button mat-stroked-button color="primary" class="mr-2 xs:mr-0 xs:!w-full mb-4 xs:mb-0 top-2.5">Search</button>
    <button class="export-button mr-2 xs:mr-0 xs:!w-full mb-4 xs:mb-0 top-2.5" mat-stroked-button color="primary"
      (click)="download()">Export</button>
  </form>
</div>

<div class="grid grid-cols-3 gap-3 h-[400px] px-5 pb-5  xs:grid-cols-none xs:h-auto xs:overflow-x-hidden">
  <div class="p-5 border border-gray-300 rounded-md bg-white shadow-lg h-[370px]">
    <div class="grid grid-cols-2 gap-3">
      <div class="flex flex-col h-[165px]">
        <p class="text-xl">Menu Breakout</p>
        <p class="text-lg">{{totalSold | number}}</p>
        <p class="text-sm text-gray-500">Items sold</p>
      </div>
      <div class="graph">
        <app-menu-piechart></app-menu-piechart>
      </div>
    </div>

    <div class="items h-40 overflow-x-hidden overflow-y-auto">
      <div *ngFor="let bar of menuNAme; let i = index" class="mb-2">
        <div class="flex justify-between">
          <p>{{bar.menu_group_name}}</p>
          <p>{{bar.percent}}%</p>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-full">
          <div class="h-full bg-green-500 rounded-full" [ngStyle]="{'width': bar.percent + '%','background-color': bar.color}"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5 border border-gray-300 rounded-md bg-white shadow-lg  h-[370px]">
    <div class="grid grid-cols-2 gap-3">
      <div class="stats h-[165px]">
        <div class="flex flex-col mb-2">
          <p class="text-xl">Gross Sales</p>
          <p class="text-lg">{{totalSales | currency:'USD'}}</p>
          <p class="text-sm text-gray-500">in total</p>
        </div>
        <div class="flex flex-col">
          <p class="text-xl">Gross Sales</p>
          <p class="text-lg">{{discountGrossSales | currency:'USD'}}</p>
          <p class="text-sm text-gray-500">discounted sales</p>
        </div>
      </div>
      <div class="">
        <app-gross-graph></app-gross-graph>
      </div>
    </div>

    <div class="items h-40 overflow-x-hidden overflow-y-auto">
      <div *ngFor="let sndpie of grossName; let i = index" class="mb-2">
        <div class="flex justify-between">
          <p>{{sndpie.menu_group_name}}</p>
          <p>{{sndpie.total_price | currency: 'USD'}}</p>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-full">
          <div class="h-full bg-green-500 rounded-full" [ngStyle]="{'width': sndpie.percent + '%', 'background-color': sndpie.color}"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5 border border-gray-300 rounded-md bg-white shadow-lg  h-[370px]">
    <div class="flex flex-col">
      <div class="title">
        <p class="text-xl">Category Performance</p>
        <p class="text-sm text-gray-500">in gross sales</p>
      </div>
      <div #chartContainer class="chart">
        <google-chart style="width: 100%;" [options]="chartData.myOptions" [columnNames]="columnNames"
          [type]="chartData.type" [data]="chartData.data" *ngIf="chartData">
        </google-chart>
      </div>
      <div class="line-data flex flex-col h-[80px] overflow-y-auto p-2 border border-gray-200 rounded-md">
        <div style="width: 100%;" *ngFor="let line of lineData; let i = index"
          class="flex flex-row items-center xs:!items-center">
          <div class="rectangle rounded-full mr-2 xs:mr-0" [ngStyle]="{'background-color': line.color}"></div>
          <p>{{line.menu_group_name}}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="message-table border border-gray-200 shadow-sm">
  <!-- on click table start -->
  <table mat-table [dataSource]="dataSourceMixTable" multiTemplateDataRows *ngIf="dataSourceMixTable != null">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumnsProduct">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="column=='menu'">Menu</span>

        <span *ngIf="column=='item_sales_total'">Total Items Sold</span>
        <span *ngIf="column=='item_sales_pos'">Total Items Sold - POS</span>
        <span *ngIf="column=='item_sales_online'">Total Items Sold - Online</span>

        <span *ngIf="column=='gross_sales_total'">Total Gross Sales</span>
        <span *ngIf="column=='gross_sales_pos'">Total Gross Sales - POS</span>
        <span *ngIf="column=='gross_sales_online'">Total Gross Sales - Online</span>

        <span *ngIf="column=='sales_mix_total'">Total Sales Mix %</span>
        <span *ngIf="column=='sales_mix_pos'">Total Sales Mix - POS %</span>
        <span *ngIf="column=='sales_mix_online'">Total Sales Mix - Online %</span>

        <span *ngIf="column=='qty_mix_total'">Total Qty Mix %</span>
        <span *ngIf="column=='qty_mix_pos'">Total Qty Mix - POS %</span>
        <span *ngIf="column=='qty_mix_online'">Total Qty Mix - Online %</span>
        <span *ngIf="column=='action'"></span>
        <div class="header-set">
          <span
            *ngIf="column != 'menu' && column != 'item_sales_total' && column != 'item_sales_pos' && column != 'item_sales_online' && column != 'gross_sales_total' && column != 'gross_sales_pos'&& column != 'gross_sales_online' && column != 'sales_mix_total' && column != 'sales_mix_pos'
                && column != 'sales_mix_online' && column != 'qty_mix_total' && column != 'qty_mix_pos' && column != 'qty_mix_online' && column != 'action'">
            {{column}}</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="column == 'menu'">{{element.menu_group_name}}</span>

        <span *ngIf="column == 'item_sales_total'">{{element.quantity_by_sub_cat_b}}</span>
        <span *ngIf="column == 'item_sales_pos'">{{element.pos_quantity_by_sub_cat_b}}</span>
        <span *ngIf="column == 'item_sales_online'">{{element.online_quantity_by_sub_cat_b}}</span>

        <span *ngIf="column == 'gross_sales_total'">
          <span *ngIf="element.total_price.length != 0">${{element.total_price | number}}</span>
        </span>
        <span *ngIf="column == 'gross_sales_pos'">
          <span *ngIf="element.total_price.length != 0">${{element.pos_total_price | number}}</span>
        </span>
        <span *ngIf="column == 'gross_sales_online'">
          <span *ngIf="element.total_price.length != 0">${{element.online_total_price | number}}</span>
        </span>

        <span *ngIf="column == 'sales_mix_total'">
          <span *ngIf="element.sales_per.length != 0">{{element.sales_per}}%</span>
        </span>
        <span *ngIf="column == 'sales_mix_pos'">
          <span *ngIf="element.sales_per.length != 0">{{element.pos_sales_per}}%</span>
        </span>
        <span *ngIf="column == 'sales_mix_online'">
          <span *ngIf="element.sales_per.length != 0">{{element.online_sales_per}}%</span>
        </span>

        <span *ngIf="column == 'qty_mix_total'">
          <span *ngIf="element.quantity_per.length != 0" style="line-height: 39px;">{{element.quantity_per}}%</span>
        </span>
        <span *ngIf="column == 'qty_mix_pos'">
          <span *ngIf="element.quantity_per.length != 0" style="line-height: 39px;">{{element.pos_quantity_per}}%</span>
        </span>
        <span *ngIf="column == 'qty_mix_online'">
          <span *ngIf="element.quantity_per.length != 0"
            style="line-height: 39px;">{{element.online_quantity_per}}%</span>
        </span>
        <span style="float: right;" *ngIf="column == 'action'">
          <button mat-icon-button color="primary">
            <mat-icon style="color:#636363;" id="expand_more" #expand_more *ngIf="!element.expanded "
              (click)="expand(element)">
              expand_more
            </mat-icon>
            <mat-icon style="color:#636363;" id="expand_less" #expand_less *ngIf="element.expanded"
              (click)="expand(element)">
              expand_less
            </mat-icon>
          </button>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsProduct.length"
        style="background-color: #ededed;" class="sub-header">
        <div class="example-element-detail" [@detailExpand]="element?.expanded" *ngIf="element?.expanded">
          <div style="width: 100%;">

            <table style="background-color: #ededed;" mat-table [dataSource]="element.menu_item_group_data"
              multiTemplateDataRows matSort>
              <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns">
                <th mat-header-cell *matHeaderCellDef>
                  <span *ngIf="innerColumn=='menu'">Menu</span>

                  <span *ngIf="innerColumn=='item_sales_total'">Total Items Sold</span>
                  <span *ngIf="innerColumn=='item_sales_pos'">Total Items Sold - POS</span>
                  <span *ngIf="innerColumn=='item_sales_online'">Total Items Sold - Online</span>

                  <span *ngIf="innerColumn=='gross_sales_total'">Total Gross Sales</span>
                  <span *ngIf="innerColumn=='gross_sales_pos'">Total Gross Sales - POS</span>
                  <span *ngIf="innerColumn=='gross_sales_online'">Total Gross Sales - Online</span>

                  <span *ngIf="innerColumn=='sales_mix_total'">Total Sales Mix %</span>
                  <span *ngIf="innerColumn=='sales_mix_pos'">Total Sales Mix - POS %</span>
                  <span *ngIf="innerColumn=='sales_mix_online'">Total Sales Mix - Online %</span>

                  <span *ngIf="innerColumn=='qty_mix_total'">Total Qty Mix %</span>
                  <span *ngIf="innerColumn=='qty_mix_pos'">Total Qty Mix - POS %</span>
                  <span *ngIf="innerColumn=='qty_mix_online'">Total Qty Mix - Online %</span>
                  <span *ngIf="innerColumn=='subaction'"></span>

                  <div class="header-set">
                    <span
                      *ngIf="innerColumn != 'menu' && innerColumn != 'item_sales_total' && innerColumn != 'item_sales_pos' && innerColumn != 'item_sales_online' && innerColumn != 'gross_sales_total' && innerColumn != 'gross_sales_pos'&& innerColumn != 'gross_sales_online' && innerColumn != 'sales_mix_total' && innerColumn != 'sales_mix_pos'
                          && innerColumn != 'sales_mix_online' && innerColumn != 'qty_mix_total' && innerColumn != 'qty_mix_pos' && innerColumn != 'qty_mix_online' && innerColumn != 'subaction'">
                      {{innerColumn}}</span>
                  </div>
                </th>
                <td mat-cell *matCellDef="let address">
                  <span *ngIf="innerColumn == 'menu'">{{address.menu_item_group_name}}</span>

                  <span *ngIf="innerColumn == 'item_sales_total'">{{address.quantity_by_sub_cat_a}}</span>
                  <span *ngIf="innerColumn == 'item_sales_pos'">{{address.pos_quantity_by_sub_cat_a}}</span>
                  <span *ngIf="innerColumn == 'item_sales_online'">{{address.online_quantity_by_sub_cat_a}}</span>

                  <span *ngIf="innerColumn == 'gross_sales_total'">${{address.total_price_sub | number}}</span>
                  <span *ngIf="innerColumn == 'gross_sales_pos'">${{address.pos_total_price_sub | number}}</span>
                  <span *ngIf="innerColumn == 'gross_sales_online'">${{address.online_total_price_sub |
                    number}}</span>

                  <span *ngIf="innerColumn == 'sales_mix_total'">{{address.sub_sales_percent}}%</span>
                  <span *ngIf="innerColumn == 'sales_mix_pos'">{{address.pos_sub_sales_percent}}%</span>
                  <span *ngIf="innerColumn == 'sales_mix_online'">{{address.online_sub_sales_perent}}%</span>

                  <span *ngIf="innerColumn == 'qty_mix_total'">{{address.sub_qtyty_percent}}%</span>
                  <span *ngIf="innerColumn == 'qty_mix_pos'">{{address.pos_sub_qty_percent}}%</span>
                  <span *ngIf="innerColumn == 'qty_mix_online'">{{address.online_sub_qty_percent}}%</span>
                  <span *ngIf="innerColumn == 'subaction'"></span>

                </td>
              </ng-container>
              <tr mat-row *matRowDef="let address; columns: innerDisplayedColumns;"
                [class.example-expanded-row]="address?.expanded" (click)="address.expanded = !address?.expanded">
              </tr>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsProduct"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumnsProduct;"
      [class.example-expanded-row]="element?.expanded" (click)="element.expanded = !element?.expanded">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <!-- On click table end -->
</div>