import {Component, OnInit} from '@angular/core';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {MenuService} from 'src/app/_services/menu.service';


@Component({
  selector: 'app-store-menu',
  templateUrl: './store-menu.component.html',
  styleUrls: ['./store-menu.component.scss']
})

export class StoreMenuComponent implements OnInit {
  panelOpenState = false;
  store: Store;
  menuGroups: any;
  menuItemDetailGroup: any;
  modifierGroupOrder: any;
  modifierGroupDetails: any;
  modifierGroups: any;
  menuItemMapping: any;
  menuItemGroupsOrder: any;
  menuItemGroupDetails: any;
  menuPortions: any;
  menuDetails: any;
  order: [];

  constructor(public storeService: StoreService, public menuService: MenuService) {
  }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;

        this.refresh();
      }
    });
  }

  refresh() {
    this.menuGroups = new Set();
    this.modifierGroups = new Set();
    this.menuItemDetailGroup = new Set();
  }

  addMenu() {
    const payload = [{ name: 'New', parent: 'New'} ];
    this.menuService.addMenu(this.store, payload).subscribe((respose) => {
      this.refresh();
    });
  }

  addModGroup() {
    const payload = { name: 'New', is_required: false, max_mods: 0, mod_option: [{ name: 'New', upcharge: '0.00', mod_type: null }] };
    this.menuService.addModifierGroup(this.store, payload).subscribe((respose) => {
      this.refresh();
    });
  }

  addMenuItemGroup() {
    const payload = { id: 0, name: 'New', description: '', starting_price: 0,
        menu_group: {id: this.menuDetails[0].id, name: this.menuDetails[0].name},
        menu_items: [{ display_name: 'New', description: '', base_price: 0.00, portion: ''}] };


    this.menuService.addMenuItemGroup(this.store, payload).subscribe((respose) => {
      this.refresh();
    });
  }

}
