import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class POSStreamService {
  endPoint = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  process(terminalId, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/process/`,
      payload,
      this.httpOptions
    );
  }

  closeOrder(terminalId, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/log_transaction_response/`,
      payload,
      this.httpOptions
    );
  }

  cancelOrder(terminalId, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/cancel_order/`,
      payload,
      this.httpOptions
    );
  }

  voidSplitOrder(terminalId, payload){
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/void_split/`,
      payload,
      this.httpOptions
    );
  }

  tipSplitOrder(terminalId, payload){
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/add_tip_split/`,
      payload,
      this.httpOptions
    );
  }

  adjustOrder(terminalId, payload){
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/adjust/`,
      payload,
      this.httpOptions
    );
  }

  void(terminalId, payload){
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/void/`,
      payload,
      this.httpOptions
    );
  }

  transactions(terminalId, payload){
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/transactions/`,
      payload,
      this.httpOptions
    );
  }

  pinVerification(terminalId, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/stream/${terminalId}/verify_signature/`,
      payload,
      this.httpOptions
    );
  }
}
