import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class POSMenuTabChangeService {

  private source = new BehaviorSubject<number>(null);
  current = this.source.asObservable();

  constructor() {}

  changeTab(tabId){
    console.log(tabId);
    this.source.next(tabId);
  }

}
