<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="report-component">
  <form class="flex flex-wrap statements-filter">
    <mat-form-field appearance="fill" class="date" subscriptSizing="dynamic">
      <mat-label>Start Date</mat-label>
      <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker"
        placeholder="Choose a start date" (dateInput)="searchOrders($event)">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="date" subscriptSizing="dynamic">
      <mat-label>End Date</mat-label>
      <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker" placeholder="Choose a end date"
        (dateInput)="searchOrders($event)" [min]="startDate">
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="reset()" class="action-btn">CLEAR</button>
  </form>
  <!-- <div class="border border-gray-300 m-5 drop-shadow-md"> -->
  <div class="border mt-8 px-5" style="border-color: #e5e5e5;">
    <div class="order-container w-full overflow-x-auto">
      <mat-table matSort matSortActive="month" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource"
        class="dark-bg-color">

        <ng-container matColumnDef="month">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Month </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Month">{{element.month_year}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="orders">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Orders </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Orders">{{element.orders}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="sales">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sales </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Sales">${{element.sales}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon (click)=vieworDownload(element,1)
              class="material-icons-outlined pointer">cloud_download_outline</mat-icon>
            <mat-icon (click)=vieworDownload(element,2)
              class="material-icons-outlined pointer">remove_red_eye</mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
  </div>
</div>