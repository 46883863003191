<div class="filters">
    <form [formGroup]="form" (ngSubmit)="search()">
        <div class="hidden md:flex md:flex-wrap md:items-start above-mob">
            <mat-form-field class="form-field filter search-filter" subscriptSizing="dynamic">
                <mat-label>Search Order</mat-label>
                <input matInput type="text" formControlName="filter" (keydown.enter)="onEnter($event)">
                <button *ngIf="form.controls.filter.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="date">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="start_picker" formControlName="start_date">
                <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
                <mat-datepicker #start_picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="date" subscriptSizing="dynamic">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="end_picker" formControlName="end_date">
                <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
                <mat-datepicker #end_picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" class="payment-type">
                <mat-label>Occasion</mat-label>
                <mat-select formControlName="occasion" multiple>
                    <mat-option *ngFor="let s of occassions" [value]="s">{{s}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" class="source">
                <mat-label>Source</mat-label>
                <mat-select formControlName="source" multiple>
                    <mat-option *ngFor="let s of this.store?.getSource()" [value]="s">{{s}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" multiple>
                    <span *ngIf="isOpenClose">
                        <mat-option *ngFor="let s of this.store?.getCloseStatusTypes()" [value]="s">{{s}}</mat-option>
                    </span>
                    <span *ngIf="!isOpenClose">
                        <mat-option *ngFor="let s of this.store?.getOpenStatusTypes()" [value]="s">{{s}}</mat-option>
                    </span>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" class="payment-type">
                <mat-label>Payment Type</mat-label>
                <mat-select formControlName="payment" multiple>
                    <mat-option *ngFor="let s of this.store?.getPaymentTypes()" [value]="s">{{s}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" class="payment-type">
                <mat-label>Server ID</mat-label>
                <mat-select formControlName="server_id" multiple>
                    <mat-option *ngFor="let s of allUserData" [value]="s">{{s}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="reset()"
                class="action-btn-colr reset-button">Reset</button>
        </div>

        <!-- MOBILE VIEW -->
        <div class="flex flex-col gap-2 md:hidden">
            <!-- Full-width Search Order field -->
            <mat-form-field class="w-full open-close-filters">
                <mat-label>Search Order</mat-label>
                <input matInput type="text" formControlName="filter" (keydown.enter)="onEnter($event)">
                <button *ngIf="form.controls.filter.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <!-- Two filters per row -->
            <div class="grid grid-cols-2 gap-2">
                <mat-form-field class="w-full open-close-filters">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="start_picker" formControlName="start_date">
                    <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
                    <mat-datepicker #start_picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="w-full open-close-filters">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="end_picker" formControlName="end_date">
                    <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
                    <mat-datepicker #end_picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="w-full open-close-filters">
                    <mat-label>Occasion</mat-label>
                    <mat-select formControlName="occasion" multiple>
                        <mat-option *ngFor="let s of occassions" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full open-close-filters">
                    <mat-label>Source</mat-label>
                    <mat-select formControlName="source" multiple>
                        <mat-option *ngFor="let s of store?.getSource()" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full open-close-filters">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" multiple>
                        <span *ngIf="isOpenClose">
                            <mat-option *ngFor="let s of store?.getCloseStatusTypes()" [value]="s">{{s}}</mat-option>
                        </span>
                        <span *ngIf="!isOpenClose">
                            <mat-option *ngFor="let s of store?.getOpenStatusTypes()" [value]="s">{{s}}</mat-option>
                        </span>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full open-close-filters">
                    <mat-label>Payment Type</mat-label>
                    <mat-select formControlName="payment" multiple>
                        <mat-option *ngFor="let s of store?.getPaymentTypes()" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full open-close-filters">
                    <mat-label>Server ID</mat-label>
                    <mat-select formControlName="server_id" multiple>
                        <mat-option *ngFor="let s of allUserData" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Reset Button Next to Server ID Filter -->
                <button mat-flat-button color="primary" (click)="reset()" class="w-full mt-2">Reset</button>
            </div>
        </div>
    </form>
</div>