<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="order-container m-8" *ngIf="!isLoading">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows width="100%">
    <ng-container matColumnDef="phrase">
      <th mat-header-cell *matHeaderCellDef> Phrase </th>
      <td mat-cell *matCellDef="let element"> {{element.phrase}} </td>
    </ng-container>

    <ng-container matColumnDef="response">
      <th mat-header-cell *matHeaderCellDef> Response </th>
      <td mat-cell *matCellDef="let element"> {{element.response}} </td>
    </ng-container>

    <ng-container matColumnDef="star">
        <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="clean-icon">
        <mat-menu #operations="matMenu">
          <button mat-menu-item (click)="deleteSkill(element)">Delete</button>
        </mat-menu>

        <button *ngIf="element.type != 'add'" mat-icon-button [matMenuTriggerFor]="operations">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <form class="form" [formGroup]="element.form" (ngSubmit)="updateSkill(element.form.value)">
            <input type="hidden" formControlName="id" >
            <input type="hidden" formControlName="store_id" >
            <mat-form-field class="full-width">
              <input matInput placeholder="Phrase" formControlName="phrase" autofocus>
              <mat-hint>Comma separated phrases</mat-hint>
            </mat-form-field>
            <br/>
            <mat-form-field class="full-width">
              <textarea rows="5" matInput placeholder="Response" formControlName="response"></textarea>
              <mat-hint>Bot's response (SHIFT + Enter for new line)</mat-hint>
            </mat-form-field>
            <div class="action">
                <button mat-stroked-button (click)="expandedElement = null; false">Cancel</button>
                <button mat-flat-button class="apply-btn-colr" color="primary" type="submit" *ngIf="element.type == 'update'">Update</button>
                <button mat-flat-button class="apply-btn-colr" color="primary" type="submit" *ngIf="element.type == 'add'">Add</button>
            </div>
          </form>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>


