export class TransationHistory{
  id: number;
  bot_order_id: number;
  action: string;
  payment_type: string;
  amount: number;
  card_last_4: string;
  name_on_card: string;
  gateway: string;
  terminal_id: string;
  transaction_id: string;
  updated_at: Date;
  has_refund: boolean;
  refunded_amount: number;

  getAvailableBalance(){
    let refundedAmount = this.refunded_amount ? this.refunded_amount : 0
    return this.amount - refundedAmount;
  }
}
