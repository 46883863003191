<mat-tab-group>
  <mat-tab label="TIME CLOCK">
    <ng-template matTabContent>
      <div class="content xs:!h-full xs:!block">
        <app-time-tracker></app-time-tracker>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="REPORTING">
    <ng-template matTabContent>
      <div class="coloredContainerX" fxlayout="row" fxLayout.lt-sm="column" cols="2">
        <div fxFlex.xs="100%" fxFlex.lt-md="100%" fxFlex="100%">
          <app-time-report></app-time-report>
        </div>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>