<form class="fixActionRow">
<h2 mat-dialog-title>
  Import Contact List
</h2>
<mat-dialog-content>
  <div>
    <input type="file" #file (change)="handleFileUpload($event)" hidden accept=".csv">
    <button mat-button (click)="file.click()" type="button">
      <mat-icon class="material-icons-outlined">add_photo_alternate</mat-icon>
      <span class="mat-subtitle-2">Select import file</span>
    </button>
    <button mat-icon-button [disabled]="!uploadedFile" (click)="removeUploadedFile()">
      <mat-icon class="material-icons-outlined">delete_forever</mat-icon>
    </button>
    <mat-error *ngIf="invalidFile">Please upload CSV file</mat-error>
  </div>
  <div class="agreement">
    <mat-checkbox (change)="handleTermsCheckboxChange()"></mat-checkbox>
    <span class="mat-caption">
      I agree to <a href="https://www.labrador.ai/customer-service-agreement" target="_blank">Labrador AI's Terms & Conditions</a> and confirm that all subscribers have<br>given prior express written consent to receive messages.
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
  <button mat-raised-button color="primary" class="apply-btn-colr" (click)="closeDialog()" [disabled]="!termsAgreed || !uploadedFile">Next</button>
</mat-dialog-actions>
</form>