<div mat-dialog-content class="container">
  <div class="display mb-2 xs:!h-[80px]">
    <input type="text" class="result xs:!p-0 xs:!text-5xl	xs:!w-[50%]" [ngModel]="result | currency:'USD':''" readonly>
  </div>
  <div class="grid grid-cols-4 gap-2 w-full mt-2 xs:!mt-7">
    <mat-form-field class="">
      <mat-label>Qty</mat-label>
      <input matInput type="number" [(ngModel)]="quantity" placeholder="Qty">
    </mat-form-field>

    <mat-form-field class="col-span-3 ">
      <mat-label>Optional Description</mat-label>
      <input matInput type="text" [(ngModel)]="optionalDesc" placeholder="Optional Description">
    </mat-form-field>
  </div>

  <div class="first-row">
    <input type="button" name="" value="1" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('1')">
    <input type="button" name="" value="2" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('2')">
    <input type="button" name="" value="3" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('3')">
    <button mat-raised-button class="calculator-global3 xs:!h-[80px]" color="primary" (click)="addMisc()" *ngIf="key_id != 1">
      Add
    </button>
    <button mat-raised-button class="calculator-global3 xs:!h-[80px]" color="primary" (click)="addDiscount(2)" *ngIf="key_id == 1"
      style="margin-right: -8px;">
      $ Disc.
    </button>
  </div>
  <div class="second-row">
    <input type="button" name="" value="4" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('4')">
    <input type="button" name="" value="5" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('5')">
    <input type="button" name="" value="6" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('6')">
    <button mat-raised-button class="global-primary xs:!h-[80px]" color=primary (click)="onNoClick()" *ngIf="key_id != 1">
      Done
    </button>
    <button mat-raised-button class="global-primary xs:!h-[80px] p-disc" color=primary (click)="addDiscount(1)" *ngIf="key_id == 1"
      style="margin-right: -8px; background-color: #1e88e5;">
      % Disc.
    </button>

  </div>
  <div class="third-row">
    <input type="button" name="" value="7" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('7')">
    <input type="button" name="" value="8" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('8')">
    <input type="button" name="" value="9" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('9')">
    <div *ngIf="key_id != 1" class="taxable">
      <mat-checkbox [(ngModel)]="taxable">Taxable</mat-checkbox>
      <!-- <p style="margin-bottom: 12px;"></p> -->
    </div>
    <button mat-raised-button class="global-primary xs:!h-[80px]" color=primary (click)="onNoClick()" *ngIf="key_id == 1"
      style="margin-right: -8px;">
      Done
    </button>
  </div>
  <div class="fourth-row">
    <input type="button" name="" value="0" class="calculator-global disable-dbl-tap-zoom" (click)="pressNum('0')">
    <input type="button" name="" value="C" class="calculator-global disable-dbl-tap-zoom" (click)="allClear()">
    <button mat-raised-button class="calculator-global calc-button3 disable-dbl-tap-zoom" (click)="clear()">
      <span>
        <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
            fill="#636363" />
        </svg>
      </span>
    </button>
    <button class="calculator-global4 xs:!h-[80px] cncl" (click)="onNoClick()" style="margin-right: -8px;">Cancel</button>
    <!-- <div class="numpad col-span-3">
      <div class="grid grid-cols-3 gap-2">
        <input matRipple type="button" name="" [value]="i+1"
               class="border text-4xl drop-shadow-md xs:!h-[60px] rounded-md hover:bg-gray-100"
               (click)="pressNum(i+1)"
               *ngFor="let num of [].constructor(10) | slice:1; let i = index"/>
        <input matRipple type="button" name="" value="0"
               class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 xs:!h-[60px]"
               (click)="pressNum('0')"/>
        <input matRipple type="button" name="" value="C"
               class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 xs:!h-[60px]"
               (click)="allClear()"/>
        <button
          class="border text-4xl drop-shadow-md rounded-md hover:bg-gray-100 xs:!h-[60px]"
          (click)="clear()">
            <span class="flex justify-center">
              <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                  fill="#636363"/>
              </svg>
            </span>
        </button>
      </div>
    </div>
    <div class="login grid grid-cols-1 grid-rows-4 gap-2">
      <button class="calculator-global3 lab-bg-blue w-full xs:!text-base xs:!p-2" (click)="addMisc()">
        Add
      </button>
      <button class="global-primary w-full xs:!text-base xs:!p-2" (click)="onNoClick()">
        Done
      </button>
      <mat-checkbox [(ngModel)]="taxable">Taxable</mat-checkbox>
      <button class="calculator-global4 w-full xs:!text-base xs:!p-2" (click)="onNoClick()">Cancel</button>
    </div>
  </div> -->
  </div>
</div>