import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdvancedCssDialogComponent } from '../advanced-css-dialog/advanced-css-dialog.component';

@Component({
  selector: "app-edit-screen-dialog",
  templateUrl: "./edit-screen-dialog.component.html",
  styleUrls: ["./edit-screen-dialog.component.scss"],
})
export class EditScreenDialogComponent implements OnInit {
  isLoading = false;
  submitted = false;
  editScreenForm: UntypedFormGroup;
  getId: any;
  file: File = null;
  storeId: any;
  emoji: any;
  image_list: any;
  video_list: any;
  decimal_point_hide: boolean;
  hide_tag_colors: boolean;
  hide_key_display: boolean;
  show_header: boolean;
  imageWidth: Array<any> = [];
  optionValue: any;
  rowdata: any;
  storeType: any;
  ImgErrorShow: any = '';
  maxFileSize: number = 30 * 1024 * 1024;
  constructor(
    public fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<EditScreenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("wewqeqweqweqwe", data);
  }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");
    (this.getId = this.data.id),
      (this.editScreenForm = this.fb.group({
        tv_id: [this.data.tv_id],
        content_type: [this.data.content_type],
        canvas: [this.data.canvas],
        screen_width: [this.data.screen_width],
        screen_height: [this.data.screen_height],
        screen_bgcolor: [this.data.screen_bgcolor],
        rows_per_screen: [this.data.rows_per_screen],
        rotate: [this.data.rotate],
        refresh_seconds: [this.data.refresh_seconds],
        padding: [this.data.padding],
        product_font_size: [this.data.product_font_size],
        product_description_size: [this.data.product_description_size],
        product_price_font_size: [this.data.product_price_font_size],
        price_header_font_size: [this.data.price_header_font_size],
        product_weight_font_size: [this.data.product_weight_font_size],
        sub_category_font_size: [this.data.sub_category_font_size],
        category_font_size: [this.data.category_font_size],
        master_category_font_size: [this.data.master_category_font_size],
        master_category_alignment: [this.data.master_category_alignment],
        hybrid_color: [this.data.hybrid_color],
        indica_color: [this.data.indica_color],
        sativa_color: [this.data.sativa_color],
        strain_font_size: [this.data.strain_font_size],
        strain_type_size: [this.data.strain_type_size],
        font_family: [this.data.font_family],
        header_text: [this.data.header_text],
        header_font_color: [this.data.header_font_color],
        media_file: [this.data.media_file],
        weight_font_color: [this.data.weight_font_color],
        description_font_color: [this.data.description_font_color],
        price_font_color: [this.data.price_font_color],
        tag_font_color: [this.data.tag_font_color],
        product_font_color: [this.data.product_font_color],
        header_emoji: [this.data.header_emoji],
        strain_right_margin: [this.data.strain_right_margin],
        hide_key_display: [this.data.hide_key_display],
        show_header: [this.data.show_header],
        hide_tag_colors: [this.data.hide_tag_colors],
        decimal_point_hide: [this.data.decimal_point_hide],
        image: [this.data.image],
        video: [this.data.video],
        store_id: [this.storeId],
        image_width: [this.data.image_width],

      }));
    this.optionValue = this.data.content_type;
    this.fileSizeLimit();
    this.emojiList();
    this.imageList();
    this.videoList();
    for (let i = 1; i <= 100; i++) {
      this.imageWidth.push(i)
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
  editScreenFormSubmit() {
    // this.submitted = true;
    // if (this.editScreenForm.invalid) {
    //   return;
    // }

    const validationForm = this.editScreenForm.status;
    if (validationForm == "VALID") {
      const formData = new FormData();
      formData.append("store_id", this.storeId);
      formData.append("store", this.storeId);
      formData.append("tv_id", this.editScreenForm.value.tv_id);
      formData.append("content_type", this.editScreenForm.value.content_type);
      formData.append("canvas", this.editScreenForm.value.canvas);
      formData.append("screen_width", this.editScreenForm.value.screen_width);
      formData.append("screen_height", this.editScreenForm.value.screen_height);
      formData.append(
        "screen_bgcolor",
        this.editScreenForm.value.screen_bgcolor
      );
      // formData.append(
      //   "rows_per_screen",
      //   this.editScreenForm.value.rows_per_screen
      // );
      // formData.append("rotate", this.editScreenForm.value.rotate);

      if (this.editScreenForm.value.rows_per_screen == null) {
        this.rowdata = 10;
        formData.append(
          "rows_per_screen", this.rowdata);
      }
      else {
        formData.append(
          "rows_per_screen",
          this.editScreenForm.value.rows_per_screen
        );
      }


      if (this.editScreenForm.value.rotate == null) {
        this.rowdata = 0;
        formData.append(
          "rotate", this.rowdata);
      }
      else {
        formData.append("rotate", this.editScreenForm.value.rotate);
      }

      formData.append(
        "refresh_seconds",
        this.editScreenForm.value.refresh_seconds
      );
      formData.append("padding", this.editScreenForm.value.padding);
      formData.append(
        "product_font_size",
        this.editScreenForm.value.product_font_size
      );
      formData.append(
        "product_description_size",
        this.editScreenForm.value.product_description_size
      );
      formData.append(
        "product_price_font_size",
        this.editScreenForm.value.product_price_font_size
      );
      formData.append(
        "price_header_font_size",
        this.editScreenForm.value.price_header_font_size
      );
      formData.append(
        "product_weight_font_size",
        this.editScreenForm.value.product_weight_font_size
      );
      formData.append(
        "sub_category_font_size",
        this.editScreenForm.value.sub_category_font_size
      );
      formData.append(
        "category_font_size",
        this.editScreenForm.value.category_font_size
      );
      formData.append(
        "master_category_font_size",
        this.editScreenForm.value.master_category_font_size
      );
      formData.append(
        "master_category_alignment",
        this.editScreenForm.value.master_category_alignment
      );
      formData.append("hybrid_color", this.editScreenForm.value.hybrid_color);
      formData.append("indica_color", this.editScreenForm.value.indica_color);
      formData.append("sativa_color", this.editScreenForm.value.sativa_color);
      formData.append(
        "strain_font_size",
        this.editScreenForm.value.strain_font_size
      );
      formData.append(
        "strain_type_size",
        this.editScreenForm.value.strain_type_size
      );
      formData.append("font_family", this.editScreenForm.value.font_family);
      formData.append("header_text", this.editScreenForm.value.header_text);
      formData.append(
        "header_font_color",
        this.editScreenForm.value.header_font_color
      );
      formData.append(
        "weight_font_color",
        this.editScreenForm.value.weight_font_color
      );
      formData.append(
        "description_font_color",
        this.editScreenForm.value.description_font_color
      );
      formData.append(
        "price_font_color",
        this.editScreenForm.value.price_font_color
      );
      formData.append(
        "tag_font_color",
        this.editScreenForm.value.tag_font_color
      );
      formData.append(
        "product_font_color",
        this.editScreenForm.value.product_font_color
      );
      formData.append(
        "hide_key_display",
        this.editScreenForm.value.hide_key_display
      );
      formData.append("show_header", this.editScreenForm.value.show_header);
      formData.append(
        "hide_tag_colors",
        this.editScreenForm.value.hide_tag_colors
      );
      formData.append(
        "decimal_point_hide",
        this.editScreenForm.value.decimal_point_hide
      );
      if (this.file != null) {
        formData.append("media_file", this.file, this.file.name);
      }

      formData.append(
        "strain_right_margin",
        this.editScreenForm.value.strain_right_margin
      );
      if (this.editScreenForm.value.video == "") {
        this.editScreenForm.value.video = null;
      }
      else {
        formData.append("video", this.editScreenForm.value.video);
      }
      if (this.editScreenForm.value.image == "") {
        this.editScreenForm.value.image = [];
        console.log("1112222333", this.editScreenForm.value.image);
      } else {
        formData.append("image", this.editScreenForm.value.image);
      }
      if (this.editScreenForm.value.header_emoji == null) {
        formData.append("header_emoji", '');
      }
      else {
        formData.append("header_emoji", this.editScreenForm.value.header_emoji);
      }
      formData.append("image_width", this.editScreenForm.value.image_width);
      this.isLoading = true;
      this.digitalDisplayService
        .editDisplayScreen(this.getId, formData)
        .subscribe((data) => {
          if (data.msg) {
            console.log('check', data.msg);
            this.ImgErrorShow = data.msg;
            this.isLoading = false;
          }
          else {
            const resData = { yesSubmit: "YesSubmit" };
            this.isLoading = false;
            this.dialogRef.close(resData);
          }

        });
    }
  }
  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emoji = data.data;
    });
  }

  fileSizeLimit() {
    this.digitalDisplayService.fileSizeLimit(this.storeId).subscribe((data: any) => {
      this.maxFileSize = data.data.file_size;
    });
  }

  onChange(event) {
    this.file = event.target.files[0];
    const maxFileSizeInBytes = this.maxFileSize * 1024 * 1024;
    if (this.file) {
      if (this.file.size > maxFileSizeInBytes) {
        this.ImgErrorShow = `File size exceeds the maximum limit of ${this.maxFileSize}MB.`;
        this.file = null;
      } else {
        this.ImgErrorShow = '';
      }
    }
  }

  imageList() {
    this.digitalDisplayService
      .ImageMediaListGet(this.storeId)
      .subscribe((data: any) => {
        this.image_list = data.data;
      });
  }
  videoList() {
    this.digitalDisplayService
      .videoListGet(this.storeId)
      .subscribe((data: any) => {
        this.video_list = data.data;
      });
  }

  headerCheck(a) {
    this.editScreenForm.value.show_header = a.checked;
  }
  displayCheck(a) {
    this.editScreenForm.value.hide_key_display = a.checked;
  }
  hideColorCheck(a) {
    this.editScreenForm.value.hide_tag_colors = a.checked;
  }
  decimalPointCheck(a) {
    this.editScreenForm.value.decimal_point_hide = a.checked;
  }

  AdvancedCssDialog() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(AdvancedCssDialogComponent, {
      data: {
        id: this.getId,
        storeId: this.storeId,
        advance_css: this.data.advance_css,
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        ;
      }
    });
  }
}
