<div class="flex flex-col p-7">
    <div class="flex justify-center pb-7">
        <button class="w-3/6 h-[86px] text-2xl rounded text-black bg-white border-gray-300 border-2 mr-3" (click)="back()">Go Back</button>
        <button class="w-3/6 h-[86px] text-2xl rounded text-white bg-[#FF5733]" (click)="endDrawer()">End Drawer</button>
    </div>
    <p class="text-center text-3xl text-[dimgrey] font-medium px-6">End Drawer</p>
    <div class="flex flex-row pb-4 pt-6">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Start Of Drawer</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.start_time  | date: 'MM/dd/yyyy hh:mm a'}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Starting Cash</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.starting_cash | currency}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Cash Sales</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.cash_sales | currency}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Cash Refunds</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.cash_refund | currency}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Paid In</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.pay_in | currency}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Paid Out</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.pay_out | currency}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Expected In Drawer</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.end_detail.expected_in_drawer | currency}}</p>
    </div>
    <div class="flex flex-row pb-4">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Actual In Drawer</p>
        <p class="w-[60%] text-2xl text-[dimgrey] font-medium px-6">{{data.amount | currency}}</p>
    </div>
    <div class="flex flex-row pb-6">
        <p class="w-[45%] text-2xl text-[dimgrey] font-medium px-6">Difference</p>
        <p class="w-[60%] text-2xl font-medium px-6 text-[#FF5733]" *ngIf="diff < 0">{{diff | currency}}</p>
        <p class="w-[60%] text-2xl font-medium px-6 text-[#4caf50]" *ngIf="diff >= 0">{{diff | currency}}</p>
    </div>
</div>