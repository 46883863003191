import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from 'src/app/_models/store';
import { FeedbacksService } from 'src/app/_services/feedbacks.service';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-feedback-dashboard',
  templateUrl: './feedback-dashboard.component.html',
  styleUrls: ['./feedback-dashboard.component.scss']
})
export class FeedbackDashboardComponent {

  store: Store;
  Daterange: UntypedFormGroup;
  maxDateCheck: string;
  startDate: any;
  endDate: any;

  // Line Bar Graph
  lineBarByGroup = "daily";
  lineBarRatingGraphOptions = ["daily", "weekly", "monthly"];
  lineBarRatingGraph;

  pieChartByGroup = "daily";
  pieChartRatingGraphOptions = ["daily", "weekly", "monthly"];
  ratingPieChart

  // title = 'My Dummy Pie Chart';
  // type = 'PieChart';
  // data = [
  //   ['Category A', 45],
  //   ['Category B', 26],
  //   ['Category C', 12],
  //   ['Category D', 17]
  // ];
  // columnNames = ['Category', 'Value'];
  // options = {
  //   pieHole: 0.4,
  //   is3D: false
  // };
  // width = 550;
  // height = 400;

  feedbackChartOptions = {
    chartArea: { width: "90%", height: "70%" },  // Adjust the chart area
    legend: { position: "top" },  // Position the legend at the top
    width: '100%',  // Set the width to 100%
    height: '100%',  // Set the height to 100%
    vAxis: {
      title: 'Ratings',  // Vertical axis title as "Ratings"
      gridlines: { color: "transparent" },  // No gridlines
      viewWindow: { min: 0 }  // Ensure the axis starts from 0
    },
    isStacked: true,  // Enable stacked bars
    seriesType: "bars",  // Set default series type to bars
    series: {
      0: { color: "red" },    // 1 Star (Red)
      1: { color: "orange" }, // 2 Stars (Orange)
      2: { color: "yellow" }, // 3 Stars (Yellow)
      3: { color: "silver" }, // 4 Stars (Silver)
      4: { color: "green" },  // 5 Stars (Green)
      5: {
        type: "line",  // Average Rating Line
        targetAxisIndex: 1,
        lineWidth: 5,
        pointSize: 10,
        color: "#1e90ff"  // Line color for average rating
      }
    },
    annotations: {
      alwaysOutside: true,  // Show annotations outside the bars
      textStyle: {
        fontSize: 12,
        bold: true,
        color: '#000'  // Text color for annotations
      },
      stem: { color: 'none' }  // Remove the connecting stem line
    },
    colors: ["red", "orange", "yellow", "silver", "green"],  // Bar colors
  };

  totalFeedback: any;
  ratingData: any;
  arrPie = {
    "numbers": [],
    "colors": [],
    "isUpdate": 0,
  }

  constructor(public feedbackService: FeedbacksService,
    private storeService: StoreService,
    private _formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.Daterange = this._formBuilder.group({
      from_date: '',
      to_date: '',
      start_time: '',
      end_time: '',
    });
    this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = store;
        this.lineBarGraphData(this.store.id, this.Daterange.value.from_date, this.Daterange.value.to_date, this.lineBarByGroup);
        this.piechartGraphData(this.store.id, this.Daterange.value.from_date, this.Daterange.value.to_date, this.lineBarByGroup);
      }
    });

  }

  lineBarGraphData(storeId:String, startDate:any, endDate:any, group:String) {
    this.feedbackService
      .getLineBarGraph(storeId, startDate, endDate, group)
      .subscribe((data: any) => {
        this.lineBarRatingGraph = data;
        
    });
  }

  lineBarFilterGraphData(event: MatRadioChange) {
    const startDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd') : "";
    const endDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd') : "";
    this.lineBarGraphData(this.store.id, startDate, endDate, event.value);

  }

  piechartGraphData(storeId:String, startDate:any, endDate:any, group:String) {
    this.feedbackService
    .getPieChartData(storeId, startDate, endDate, group)
    .subscribe((data: any) => {
      this.ratingPieChart = data;
      this.totalFeedback = this.ratingPieChart.total_feedbacks;
      this.ratingData = this.ratingPieChart.pie_chart_data
      this.setGraphData();
      
    });
  }

  setGraphData() {
    this.ratingData.map(data => {
      this.arrPie.numbers.push(data.percentage)
      this.arrPie.colors.push(data.color)
      this.arrPie.isUpdate = 1
    })
    this.feedbackService.setRefresh(this.arrPie);
  }

  pieChartFilterGraphData(event: MatRadioChange) {
    const startDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd') : "";
    const endDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd') : "";
    this.piechartGraphData(this.store.id, startDate, endDate, event.value);
  }


  filterSubmit() {
    this.startDate = this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd');
    this.lineBarGraphData(this.store.id, this.startDate, this.endDate, this.lineBarByGroup);
    this.piechartGraphData(this.store.id, this.startDate, this.endDate, this.lineBarByGroup);
  }

  clearDate() {
    this.Daterange.get('from_date').setValue('');
    this.Daterange.get('to_date').setValue('');
    this.lineBarByGroup = 'daily';   
    this.pieChartByGroup = 'daily'
    this.lineBarGraphData(this.store.id, this.Daterange.value.from_date, this.Daterange.value.to_date, this.lineBarByGroup);
    this.piechartGraphData(this.store.id, this.Daterange.value.from_date, this.Daterange.value.to_date, this.lineBarByGroup);
  }

  ngOnDestroy() {
    this.feedbackService.setRefresh(null);
  }
  
  

}
