<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="flex flex-col p-7">
    <p class="text-center text-2xl text-[dimgrey] font-medium">{{data.terminal_name}} Drawer Has been Closed!</p>
    <p class="text-center text-xl text-[dimgrey] font-normal my-2.5">Opened: {{data.drawer_start_time | date: 'hh:mm a EE MMM dd, yyyy'}}</p>
    <p class="text-center text-xl text-[dimgrey] font-normal">Closed: {{data.drawer_end_time | date: 'hh:mm a EE MMM dd, yyyy'}}</p>
    <div class="flex flex-col mt-8">
        <button class="w-full h-[72px] text-2xl rounded text-white mr-3.5 mb-2.5 bg-[#3b82f6]" (click)="printReport(1, 'period')" [disabled]="isLoading">Print Period Report</button>
        <button class="w-full h-[72px] text-2xl rounded text-white mr-3.5 mb-2.5 bg-[#3b82f6]" (click)="printReport(6, 'drawer')" [disabled]="isLoading">Print Drawer Report</button>
        <button class="w-full h-[72px] text-2xl rounded text-[#3b82f6] border-[#3b82f6] border-2 mr-3.5 bg-white" (click)="done()" [disabled]="isLoading">Done</button>
    </div>
</div>