<mat-tab-group class="loyalty-grph">
  <mat-tab label="USERS">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="100%" fxFlex="100%">
        <div class="card">
          <app-user-loyalty></app-user-loyalty>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="SETTINGS">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="100%" fxFlex="100%">
        <div class="card">
          <app-loyalty-settings></app-loyalty-settings>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>