<div class="uploader">
  <button mat-icon-button (click)="openFile()" [ngClass]="{'error-text': hasError}" [disabled]="disabled">
    <mat-icon>add_photo_alternate</mat-icon>
  </button>
  &nbsp;
  <span class="mes" *ngIf="imageUrl && !ourFile"><a [href]="imageUrl" target="_blank" >Uploaded Image</a></span>
  <span class="mes" *ngIf="ourFile">{{ourFile?.name}}</span>
  <span class="mes" *ngIf="!ourFile && !imageUrl" [ngClass]="{'error-text': hasError}">Add Image {{+maxFileSize > 0 ? "(1MB Max)" : ""}}  </span>
  <span *ngIf="hasError" class="ex-mes error-text"> File size exceeds the maximum limit of 1MB. Please select image under 1MB</span>
  <button mat-icon-button (click)="delete()" *ngIf="ourFile" [disabled]="disabled">
    <mat-icon>delete_forever</mat-icon>
  </button>
  <input type="file" hidden id="fileOpen" (change)="fileChange($event.target.files)" #fileOpen>
</div>