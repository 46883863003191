<div *ngIf="showScreen == '1'">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <table mat-table [dataSource]="dataSource" width="100%" class="screenTable xs:!table-fixed">
    <ng-container matColumnDef="canvas">
      <th mat-header-cell *matHeaderCellDef> Canvas </th>
      <td mat-cell *matCellDef="let element"> {{element.canvas}} </td>
    </ng-container>

    <ng-container matColumnDef="screen_id">
      <th mat-header-cell *matHeaderCellDef> Screen ID </th>
      <td mat-cell *matCellDef="let element"> {{element.tv_id}} </td>
    </ng-container>

    <ng-container matColumnDef="content_type">
      <th mat-header-cell *matHeaderCellDef> Content Type </th>
      <td mat-cell *matCellDef="let element"> {{element.content_type}} </td>
    </ng-container>

    <ng-container matColumnDef="screen_url">
      <th mat-header-cell *matHeaderCellDef> Screen URL </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="storeType == 'Leaflogix';else all_store">
          <a
            href="{{leaflogixendpoint}}/pos-display/1/{{storeId}}/{{element.tv_id}}/">{{leaflogixendpoint}}/pos-display/1/{{storeId}}/{{element.tv_id}}/</a>
        </span>
        <ng-template #all_store>
          <a
            href="{{endpoint}}/backend/v2/pos-display/screens/?store_id={{storeId}}&tv_id={{element.tv_id}}">{{endpoint}}/backend/v2/pos-display/screens/?store_id={{storeId}}&tv_id={{element.tv_id}}</a>
        </ng-template>

      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menuScreen="matMenu">
          <button mat-menu-item (click)="editScreen(element)">
            <span class="material-icons-outlined edit-delete-icon">
              edit
            </span>&nbsp;
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="copyScreen(element.tv_id)">
            <span class="material-icons-outlined edit-delete-icon">
              content_copy
            </span>&nbsp;
            <span>Copy</span>
          </button>
          <button mat-menu-item (click)="defaultScreenSet(element.tv_id)">
            <span class="material-icons-outlined edit-delete-icon">
              tv
            </span>&nbsp;
            <span>Set Default Screen</span>
          </button>
          <button mat-menu-item (click)="deleteScreens(element.id)">
            <span class="material-icons edit-delete-icon">
              delete_outline
            </span>&nbsp;
            <span>Delete</span>
          </button>
          <button mat-menu-item (click)="previewScreen(element)">
            <span class="material-icons-outlined edit-delete-icon">
              preview
            </span>&nbsp;
            <span>Preview</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <!-- <div class="add-screen"></div> -->
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
  <div mat-button [matMenuTriggerFor]="menu" class="add-screen">Add New Screen</div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="addScreen()">Add New Screen</button>
    <button mat-menu-item (click)="defaultScreen()">Default Screen</button>
  </mat-menu>
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]">
  </mat-paginator>
</div>

<div *ngIf="showScreen == '2'">
  <app-digital-screens [data]="urlCreate"></app-digital-screens>
</div>
