<table mat-table multiTemplateDataRows [dataSource]="datasource" class="border-1p-grey">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
      <td mat-cell *matCellDef="let element" class="heading"> {{element[column]}} </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <form class="full-width" [formGroup]="form" (ngSubmit)="save(form)" #f="ngForm">
            <mat-form-field class="width-50 xs:!w-full">
              <input matInput placeholder="Modifier Group Name" formControlName="modifier_group_name">
              <!-- <mat-hint>Examples: Lunch, Dinner, Appetizers, Beverages </mat-hint> -->
            </mat-form-field>

            <mat-form-field class="width-25 xs:!w-2/5">
             <mat-select formControlName="max_mod" placeholder="Maximum # of Mods">
                <mat-option *ngFor="let mod of mods" [value]="mod.value">
                  {{mod.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="width-25 xs:!w-2/4">
             <mat-select formControlName="is_required" placeholder="Required?">
                <mat-option *ngFor="let presenseOption of presenseOptions" [value]="presenseOption.value">
                  {{presenseOption.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <p class="heading">Add Modifier</p>
            <p class="sub-heading">Enter at least one modifier per modifier group. You can add more later.</p>

            <mat-form-field class="width-75 xs:!w-full">
              <input matInput placeholder="Modifer Name" formControlName="modifier_name">
              <!-- <mat-hint>Examples: Lunch, Dinner, Appetizers, Beverages </mat-hint> -->
            </mat-form-field>

            <mat-form-field class="width-25 xs:!w-full">
              <input matInput placeholder="Upcharge (USD)" formControlName="upcharge" type="number">
              <!-- <mat-hint>Examples: Lunch, Dinner, Appetizers, Beverages </mat-hint> -->
            </mat-form-field>

            <mat-form-field class="width-25">
              <input matInput placeholder="Food Cost" formControlName="food_cost" type="number">
            </mat-form-field>

            <div class="action">
              <button mat-stroked-button  class="cancel-btn" color="primary" (click)="expandedElement = null; false">Cancel</button>
              <button mat-stroked-button class="save-btn" color="primary" [disabled]="!form.valid">Save</button>
            </div>
          </form>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    <tr mat-row>Last</tr>
</table>
