import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-screen-media-dialog",
  templateUrl: "./screen-media-dialog.component.html",
  styleUrls: ["./screen-media-dialog.component.scss"],
})
export class ScreenMediaDialogComponent implements OnInit {
  isLoading = false;
  addMediaForm: UntypedFormGroup;
  storeId: string;
  file: File;
  addEditUpdate: any;
  editMediaForm: UntypedFormGroup;
  rowId: any;
  ImgErrorShow: any = '';
  maxFileSize: number = 30;

  constructor(
    public fb: UntypedFormBuilder,
    public digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<ScreenMediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.fileSizeLimit();
    if (this.data.dialog == 1) {
      this.addMediaForm = this.fb.group({
        store: [this.storeId],
        name: ["", Validators.required],
        row: ["", Validators.required],
        image: ["", Validators.required],
      });
    } else if (this.data.dialog == 2) {
      this.rowId = this.data.data.id;
      const editData = this.data.data;
      this.editMediaForm = this.fb.group({
        store: [this.storeId],
        name: [editData.name],
        row: [editData.row],
        image: [""],
      });
    } else if (this.data.dialog == 3) {
      this.rowId = this.data.data.id;

    }

    this.addEditUpdate = this.data.dialog;
  }

  addMediaFormSubmit() {
    const validationForm = this.addMediaForm.status;
    if (validationForm == "VALID") {
      const formData = new FormData();
      formData.append("store", this.storeId);
      formData.append("name", this.addMediaForm.value.name);
      formData.append("row", this.addMediaForm.value.row);
      if (this.file != null) {
        formData.append("image", this.file, this.file.name);
      }
      this.isLoading = true;
      this.digitalDisplayService.addMedia(formData)
        .subscribe((data: any) => {
          if (data.msg) {
            console.log('check', data.msg);
            this.ImgErrorShow = data.msg;
            this.isLoading = false;
          }
          else {
            const resData = { yesSubmit: "YesSubmit" };
            this.isLoading = false;
            this.dialogRef.close(resData);
          }
        });
    }
  }

  editMediaFormSubmit() {
    const formData = new FormData();
    formData.append("store", this.storeId);
    formData.append("name", this.editMediaForm.value.name);
    formData.append("row", this.editMediaForm.value.row);
    if (this.file != null) {
      formData.append("image", this.file, this.file.name);
    }
    this.isLoading = true;
    this.digitalDisplayService.editMedia(formData, this.rowId).subscribe((data: any) => {
      if (data.msg) {
        console.log('check', data.msg);
        this.ImgErrorShow = data.msg;
        this.isLoading = false;
      }
      else {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      }
    });

  }

  mediaDelete() {
    this.isLoading = true;
    this.digitalDisplayService
      .deleteMedia(this.rowId)
      .subscribe((data: any) => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);

      });
  }

  fileSizeLimit() {
    this.digitalDisplayService.fileSizeLimit(this.storeId).subscribe((data: any) => {
      this.maxFileSize = data.data.file_size;
    });
  }

  onChange(event) {
    this.file = event.target.files[0];
    const maxFileSizeInBytes = this.maxFileSize * 1024 * 1024;
    if (this.file) {
      if (this.file.size > maxFileSizeInBytes) {
        this.ImgErrorShow = `File size exceeds the maximum limit of ${this.maxFileSize}MB.`;
        this.file = null;
      } else {
        this.ImgErrorShow = '';
      }
    }
  }

}
