<div class="flex flex-col p-7">
    <div class="flex pb-7 pt-3.5 px-3.5">
        <button class="w-1/2 h-[86px] text-2xl rounded mr-3.5 border-2 border-[#3b82f6] text-[#3b82f6]"
            (click)="cancel()">Cancel</button>
        <button class="w-1/2 h-[86px] text-2xl rounded text-white bg-[#3b82f6]" (click)="confirm()">Confirm</button>
    </div>
    <span *ngIf="showData.key == 'start_drawer'">
        <p class="text-center text-3xl text-[dimgrey] font-medium px-6">Are You Sure Starting Balance Is?:</p>
        <p class="text-center font-medium basis-1/4 text-6xl text-[#4caf50] py-14">{{showData.amount | currency}}</p>
    </span>
    <span *ngIf="showData.key == 'pay_cash_in'">
        <p class="text-center text-3xl text-[dimgrey] font-medium px-6">Are You Sure You Want Pay Cash IN?:</p>
        <p class="text-center font-medium basis-1/4 text-6xl text-[#4caf50] py-10">{{showData.amount | currency}}</p>
    </span>
    <span *ngIf="showData.key == 'pay_cash_out'">
        <p class="text-center text-3xl text-[dimgrey] font-medium px-6">Are You Sure You Want Pay Cash OUT?:</p>
        <p class="text-center font-medium basis-1/4 text-6xl text-[#FFC300] py-10">{{showData.amount | currency}}</p>
    </span>
    <span *ngIf="showData.key == 'end_drawer'">
        <p class="text-center text-3xl text-[dimgrey] font-medium px-6">Are You Sure You Want End Drawer?:</p>
        <p class="text-center font-medium basis-1/4 text-6xl text-[#FF5733] py-10" *ngIf="showData.amount < 0">{{showData.amount | currency}}</p>
        <p class="text-center font-medium basis-1/4 text-6xl text-[#4caf50] py-10" *ngIf="showData.amount >= 0">{{showData.amount | currency}}</p>
    </span>
    <div class="px-6" *ngIf="showData.key != 'start_drawer'">
        <mat-form-field class="w-full">
            <textarea matInput [placeholder]="placeHolderText" [(ngModel)]="reason"></textarea>
        </mat-form-field>
    </div>
</div>