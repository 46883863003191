  <div class="content-receipt" *ngIf="!isLoading && orderWithPayment" >
    <div class="receipt">
      <div class="center hero" *ngIf="!onlyItems">
        <div class="initial" mat-stroked-button>{{initial}}</div>
        <h3>{{ orderWithPayment.store.store_name}}</h3>
        <p>{{ orderWithPayment.store.address}}</p>
      </div>

      <div class="text-center">
        <p>{{orderWithPayment.bot_order.occasion}} {{orderWithPayment.bot_order.order_counter}}</p>
        <p class="price-sup" *ngIf="orderWithPayment.getItemCount() > 0 && !splitOrder"><span class="price"><span class="sup">$</span>{{orderWithPayment.payment.total | number:'1.2-2'}}</span></p>
        <p *ngIf="splitOrder">{{splitOrder[0].possplitpayment_details.check_hash}}</p>
        <p class="price-sup" *ngIf="splitOrder"><span class="price"><span class="sup">$</span>{{splitOrder[0].possplitpayment_details.total | number:'1.2-2'}}</span></p>
        
        <mat-divider></mat-divider>
      </div>

      <div class=row *ngIf="!splitOrder">
        <div *ngIf="orderWithPayment.getItemCount() > 0; then parserBlock else textBlock;"></div>
        <ng-template #textBlock>
          <p class="text-order">
            <span class="line-item" *ngFor="let order of orderWithPayment.bot_order.order.split('.nl.')">{{order.trim()}}</span>
          </p>
          <mat-divider></mat-divider>
        </ng-template>
        <ng-template #parserBlock>
            <table mat-table [dataSource]="orderWithPayment.items" width="100%" cellspacing="10" cellpadding="10">
                <ng-container matColumnDef="quantity">
                  <td class="itemdetail" mat-cell *matCellDef="let item itemname">
                    {{item.quantity}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="item">
                  <td mat-cell *matCellDef="let item itemname">
                    <h4 class="itemdetail" mat-line> <span
                        *ngIf="item.menu_item.portion != 'Standard' ">{{item.menu_item.portion}}&nbsp;</span>{{item.menu_item.display_name}}
                    </h4>
                    <div class="modifiers">
                      <p *ngIf="item.special_request">"{{item.special_request}}"</p>
                      <div *ngFor="let mod of item.modifiers">
                        <div *ngIf="mod.modifier; then thenBlock else elseBlock"></div>
                        <ng-template #thenBlock>
                          <div *ngIf="mod.modifier">
                            <p><span *ngIf="mod.mod_type != 'ADD'">{{ pizzaOptions.get(mod.mod_type) }}</span>
                              {{mod.modifier.name}}
                              <span
                                *ngIf="mod.mod_type.toLowerCase() !='no'">({{mod.modifier.upcharge  | currency:'USD' }})</span>
                            </p>
                          </div>
                        </ng-template>

                        <ng-template #elseBlock>
                          <i class="non-match">
                            <p><span *ngIf="mod.mod_type != 'ADD'">{{ pizzaOptions.get(mod.mod_type) }}</span>
                              {{mod.modifier_raw}}</p>
                          </i>
                        </ng-template>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="itemTotal">
                  <td class="itemdetail" mat-cell *matCellDef="let item" class="align-right w-[27%]"> {{item.getItemTotal() | currency:'USD' }} </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                  <td mat-cell *matCellDef="let item">
                    <button mat-icon-button>
                      <mat-icon aria-label="">clear</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <table mat-table [dataSource]="orderWithPayment?.misc_items" width="100%" cellspacing="" cellpadding="10">
                <ng-container matColumnDef="quantity">
                  <td mat-cell *matCellDef="let item" class="itemname">{{item.quantity}}</td>
                </ng-container>

                <ng-container matColumnDef="item">
                  <td mat-cell *matCellDef="let item" class="align-left itemname">
                      <h4 class="itemdetail" mat-line>{{item.item_name}}</h4>
                  </td>
                </ng-container>

                <ng-container matColumnDef="itemTotal">
                  <td mat-cell *matCellDef="let item" class="align-right w-[27%]">{{item?.price  | currency:'USD' }} </td>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </ng-template>
      </div>
      <div class=row *ngIf="splitOrder">
        <table mat-table [dataSource]="splitOrder" width="100%" cellspacing="10" cellpadding="10">
            <ng-container matColumnDef="item">
              <td mat-cell *matCellDef="let item">
                <h4 class="itemdetail" mat-line>{{item.item_name}}</h4>
              </td>
            </ng-container>

            <ng-container matColumnDef="itemTotal">
              <td class="itemdetail" mat-cell *matCellDef="let item" class="align-right w-[27%]"> <span *ngIf="+item.amount >= 0">{{item.amount | currency:'USD' }} </span></td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <td mat-cell *matCellDef="let item">
                <button mat-icon-button>
                  <mat-icon aria-label="">clear</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedSplitColumns;"></tr>
          </table>
      </div>


      <div class="row" >
        <div *ngIf="orderWithPayment.getItemCount() > 0 && !splitOrder">
          <table width="100%" class="price-breakdown-table" cellspacing="10px;">
            <tr>
              <td class="item pl-2">Subtotal</td>
              <td class="item align-right pr-2">{{orderWithPayment.payment.sub_total  | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="orderWithPayment?.payment.discount > 0">
              <td class="item pl-2">Discount</td>
              <td class="item align-right pr-2">-{{orderWithPayment?.payment.discount | currency:'USD'}}</td>
            </tr>
            <tr>
              <td class="item pl-2">Tax</td>
              <td class="item align-right pr-2">{{orderWithPayment.payment.tax | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="+orderWithPayment?.payment.service_charge > 0">
              <td class="item pl-2">Service Charge</td>
              <td class="item align-right pr-2">{{orderWithPayment?.payment.service_charge | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="orderWithPayment?.payment.tip">
              <td class="item pl-2">Tip</td>
              <td class="item align-right pr-2">{{orderWithPayment.payment.tip | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="+orderWithPayment?.payment.store_credit > 0">
              <td class="item pl-2">Store Credit</td>
              <td class="item align-right pr-2">{{'-' + orderWithPayment?.payment.store_credit | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="orderWithPayment.bot_order.occasion === 'DELIVERY'">
              <td class="item pl-2">Delivery</td>
              <td class="item align-right pr-2">{{orderWithPayment.payment.delivery_charge | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="orderWithPayment.payment.surcharge > 0">
              <td class="item pl-2">Surcharge</td>
              <td class="item align-right pr-2">{{orderWithPayment?.payment.surcharge | currency:'USD'}}</td>
            </tr>
            <tr>
              <td class="item total pl-2">Total</td>
              <td class="item total align-right pr-2">{{orderWithPayment.payment.total | currency:'USD'}}</td>
            </tr>
          </table>
          <mat-divider></mat-divider>
        </div>

        <div *ngIf="splitOrder">
          <table width="100%" class="price-breakdown-table" cellspacing="10px;">
            <tr>
              <td class="item pl-2">Subtotal</td>
              <td class="item align-right pr-2">{{splitOrder[0].possplitpayment_details.split_amount  | currency:'USD'}}</td>
            </tr>
            <tr *ngIf="orderWithPayment?.payment.tip">
              <td class="item pl-2">Tip</td>
              <td class="item align-right pr-2">{{splitOrder[0].possplitpayment_details.tip | currency:'USD'}}</td>
            </tr>
            <tr>
              <td class="item total pl-2">Total</td>
              <td class="item total align-right pr-2">{{splitOrder[0].possplitpayment_details.total | currency:'USD'}}</td>
            </tr>
          </table>
          <mat-divider></mat-divider>
        </div>

        <div class="instructions"> Order Instructions: {{orderWithPayment.bot_order.special_request}}</div>

        <mat-divider></mat-divider>
        <table width="100%" class="price-breakdown-table" cellspacing="10px;">
          <tr>
            <td class="item pl-2">Submitted</td>
            <td class="item pr-2">{{orderWithPayment.bot_order.submitted_at}}</td>
          </tr>
          <tr>
            <td class="item pl-2">Est. {{orderWithPayment.bot_order.occasion.toLowerCase()}} time</td>
            <td class="item pr-2">{{orderWithPayment.bot_order.occasion_schedule}}</td>
          </tr>
        </table>
        <mat-divider></mat-divider>
        <div class="row center" *ngIf="!onlyItems">Thank you!</div>
      </div>
    </div>

    <div class="company-info center" *ngIf="!onlyItems">
      <span>&copy;&nbsp;Labrador AI, Inc<br />
        <a href="http://www.labrador.ai" target="_blank">www.labrador.ai</a><br />
        Boston, MA</span>
      <br /><br />
      <img width="50px;" src="https://storage.googleapis.com/menus/lab-logo.png" />
      <br /><br />
    </div>
  </div>

