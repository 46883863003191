import {Store} from './store';
import {MenuImage} from '../_interfaces/menu-image';

export class MenuGroup {
  id: number;
  name: string;
  parent: string;
  store: number;
}

export class MenuGroupItem {
  id: number;
  name: string;
  image: string;
  description: string;
  starting_price: number;
  latest_image: MenuImage;
  menu_group_id: string;
}

export class MenuItem {
  id: number;
  name: string;
  portion: string;
  description: string;
  base_price: number;
  pos_name: string;
  menu_item_group: number;
  store: number;
}

export class ModifierGroup {
  id:number;
  name: string;
  max_mods: number;
  is_required: boolean;
  store: number;
  options: ModifierOption[];
}

export class ModifierOption {
  id:number;
  name: string;
  modifier_group: number;
  upcharge: number;
  store: number;
  mod_type:string;
  has_half_mod:boolean;
  has_quantity: boolean;
  fixed_price: boolean;
  max_mod_units:number;

  getUpcharge(quantity): number{
    if (this.fixed_price) {
      return +this.upcharge * 1;
    } else {
      return +this.upcharge * quantity;
    }
  }
}

export class Modifier {
  modifier: ModifierOption;
  modifier_raw: string;
  quantity:number;
  mod_type:string;
  price:number;
  max_mod_units:number;
  has_half_mod:boolean;
  has_quantity:boolean;
  direction?:string;
  hasQuantity: number;
  upcharge:number;

  getUpcharge(): number{
    this.modifier = Object.assign(new ModifierOption(), this.modifier)
    if (!this.modifier || this.mod_type.toLowerCase() === 'no' || this.mod_type.toLowerCase() === 'remove'){
      return 0;
    } else {
      const upcharge = this.modifier.getUpcharge(this.quantity);
      return upcharge
    }
  }
}

export class Order {
    id:number;
    bot_order: number;
    quantity: number;
    portion: string;
    menu_item: MenuItem;
    menu_item_raw: string;
    special_request: string;
    item_instructions: string;
    modifiers: Modifier[];
    price: number;
    ts_submitted:boolean;
    sale_price: number;

    getItemTotal(){
      this.menu_item = Object.assign(new MenuItem(), this.menu_item);
      let itemTotal = 0
      itemTotal = +this.menu_item.base_price * this.quantity;
      for(const mod of this.modifiers) {
        itemTotal = itemTotal + (mod.getUpcharge() * this.quantity);
      }
      return itemTotal;
    }
}

export class Payment{
  auth_number: string;
  date:Date;
  sub_total:number;
  tax: number;
  tip:number;
  total:number;
  type:string;
  delivery_charge:number;
  discount:number;
  service_charge:number;
  store_credit:number;
  surcharge:number;
  promotional_discount:number;
  due_total:number;
  instant_cash_discount: number;
  cash_tax: number;
  cash_due_total:number;
  pos_service_charge:number;
  pos_surcharge:any;
  instant_discount:any;
}

export class EditModifierOptions{
  parsed_menu_item: Order;

  modifier_groups:Map<string, ModifierOption[]>;
}
export class ModifierGroups{
  menu_item: MenuItem;
  modifier_groups:Map<string, ModifierOption[]>;
}

export class BotOrder {
  id: number;
  phone_number: string;
  store: number;
  account: number;
  time: string;
  time_raw: string;
  occasion: string;
  occasion_raw: string;
  occasion_schedule:string;
  delivery_address:string;
  order: string;
  special_request: string;
  item_instructions: string;
  order_hash: string;
  dine_in_table_number: string;
  is_submitted: boolean;
  is_temp:boolean;
  is_parsed:boolean;
  has_refund:boolean;
  refund_total:number;
  has_adjustment:boolean;
  adjusted_total:number
  is_settled:boolean;
  created_at: Date;
  updated_at: Date;
  order_counter:string;
  submitted_at:Date;
  ready_notified:boolean;
  curbside_vehicle_id:string;
  tip_percent:number;
  is_tab_open:boolean;
  phone_number_verified:boolean;
  order_name:string;
  promo_code:string;
  is_pos: boolean;
  source: string;
  source_id: string;
  is_paid_by_cash:boolean;
  is_payment_pending:boolean;
  payment_type:string;
  // is_paid_by_split: boolean;
  has_submitted_tab:boolean;
  is_paid_by_split: boolean;
  pos_service_charge_percent:number;
  view_notified: boolean;
  cashier_id: string;
}

export class MiscItem{
  quantity:number;
  item_name:string;
  price:number;
  ts_submitted:boolean;
}

export class OrdersWithPayment{
  bot_order:BotOrder;
  store:Store;
  items:Order[];
  misc_items: MiscItem[];
  payment: Payment;
  split_items: any;

  updateTip(tipPercentage:number){
    this.payment.tip = +(this.payment.sub_total * (+tipPercentage / 100)).toFixed(2)
    this.payment.due_total = +(+this.payment.due_total + (+this.payment.tip)).toFixed(2);
  }

  updateTipAmount(amount){
    this.payment.tip = +amount.toFixed(2);
    this.payment.due_total = +(+this.payment.due_total + (+this.payment.tip)).toFixed(2);
  }

  getItemCount(){
    const item_count = this.items.reduce((sum, item) => sum + +item.quantity, 0);
    const misc_item_count = this.misc_items.reduce((sum, item) => sum + +item.quantity, 0);
    return item_count + misc_item_count
  }

  getOccasionDetail(){
    return new OccasionDetail(this.bot_order.occasion, this.bot_order.time,
      this.bot_order.occasion_schedule,this.bot_order.dine_in_table_number,
      this.bot_order.delivery_address, this.bot_order.curbside_vehicle_id)
  }

  setOccasionDetail(occasionDetail:OccasionDetail){
    console.log(occasionDetail);

    this.bot_order.occasion = occasionDetail.occasion;
    this.bot_order.time = occasionDetail.time;
    this.bot_order.occasion_schedule = occasionDetail.occasion_schedule;
    this.bot_order.dine_in_table_number = occasionDetail.dine_in_table_number;
    this.bot_order.delivery_address = occasionDetail.delivery_address;
    this.bot_order.curbside_vehicle_id = occasionDetail.curbside_vehicle_id;
  }

  applyStoreCredit(amount){
    return this.payment.store_credit < this.payment.total ? this.payment.total : this.payment.store_credit - this.payment.total
  }

  updateServiceCharge(charge:number){
    this.payment.pos_service_charge = +(charge).toFixed(2)
    this.payment.total = +(+this.payment.total + (+this.payment.pos_service_charge)).toFixed(2);
  }

  isValidOrder(){
    if(this.bot_order.occasion === 'DELIVERY'){
      return this.payment.type !== 'STORE_CREDIT' ? (this.payment.total + this.payment.store_credit) >= this.store.delivery_minimum : true
    }else if(this.bot_order.occasion === 'TABLESIDE'){
      return this.bot_order.dine_in_table_number ? true : false;
    }else if(this.bot_order.occasion === 'CURBSIDE'){
      return this.bot_order.curbside_vehicle_id ? true : false;
    }else{
      return true;
    }
  }

  updateTipCustom(tip:number){
    this.payment.tip = +(tip).toFixed(2)
    this.payment.due_total = +(+this.payment.due_total + (+this.payment.tip)).toFixed(2);
  }

  getOriginalTotal(){
    return this.payment.tip + this.payment.sub_total + this.payment.tax + this.payment.delivery_charge
  }

  getTipPercent(){
    return +this.bot_order.tip_percent;
  }

  getTipPercentOptions(){
    return this.store.tip_percent_option.sort((a,b) => a.percent - b.percent);
  }

  getTableSideItemCount(){
    return this.items.filter(i => !i.ts_submitted).reduce((sum,item) => sum + item.quantity, 0)
  }

  isTabOpen(){
    return this.bot_order.is_tab_open;
  }

  getServiceChargePercentOptions(){
    return this.store.service_charge_percent_option.sort((a,b) => a.percent - b.percent);
  }

  getOccasion(){
    return this.bot_order.occasion;
  }

  getTableNumber(){
    return this.bot_order.dine_in_table_number.trim();
  }

  isPhoneDefault(){
    return this.bot_order.phone_number === this.store.sms_number;
  }

  getFormattedPhoneNumber(){
    if(!this.isPhoneDefault()){
      return this.bot_order.phone_number.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4');
    } else {
      return null;
    }
  }

  getTotal(){
    return this.payment.total;
  }

  getCardChargeWithTip(tip){
    return (+this.payment.due_total + +tip).toFixed(2);
  }

  getCardChargeWithTip2(tip, e) {
    if (e == 0) {
      return (this.payment.due_total - tip).toFixed(2);
    } else {
      const a = Number(tip);
      if (this.payment.tip != 0) {
        return (this.payment.due_total - this.payment.tip + a).toFixed(2);
      } else {
        return (+this.payment.due_total + a).toFixed(2);
      }
    }
  }

  getDueTotal(){
    return +this.payment.sub_total;
  }
}

export class CardDetails{
  id:number;
  name_on_card:string;
  card_type: string;
  phone_number:string;
  token:string;
  expiry:number;
  cvc:number;
  store:number;
  expiryMonth:string;
  expiryYear:string;
  last_4:string;
  gateway:string;
}


export class MenuItemGroup{
  id:number;
  name:string;
  description:string;
  starting_price:number;
  store:number;
  menu_group:MenuGroup;
}

export class BotOrders{
  total: number;
  orders: BotOrder[];
}

export class RedeemHistorys{
  total: number;
  orders: RedeemHistory[];
}


export class OfferOrder {
  id: number;
  phone_number: string;
  offer_order_id: string;
  total?: any;
  is_temp: boolean;
  phone_number_verified: boolean;
}

export class Offer {
  id: number;
  title: string;
  description: string;
  list_price: number;
  sale_price: number;
  store: number;
  ends: Date;
  counter: number;
  image: string;
}

export class OfferItem {
  id: number;
  offer_order: OfferOrder;
  quantity: number;
  offer: Offer;
  redeemed_amount: number;
  total_sale_price: number;
  total_list_price: number;
}

export class RedeemHistory {
  id: number;
  offer_item: OfferItem;
  redeemed_amount: number;
  redeem_id: string;
  redeemed_at: Date;
  expires_at: Date;
}

export class OccasionDetail{
  occasion:string;
  time:string;
  occasion_schedule:string;
  delivery_address:string;
  dine_in_table_number:string;
  curbside_vehicle_id:string;
  location_selected:boolean;

  constructor(occasion='PICKUP', time='NOW', schedule=null,
    tableId=null, address=null, vehicleId=null){
    this.occasion = occasion
    this.time = time
    this.occasion_schedule = schedule
    this.dine_in_table_number = tableId
    this.delivery_address = address
    this.curbside_vehicle_id = vehicleId
  }

  isEmpty(value){
    return (typeof value === 'undefined' || value === null || value === '');
  }

  isValid(){
    let valid = false;
      switch(this.occasion){
        case 'PICKUP':
          valid = true
          break;
        case 'DELIVERY':
          valid = !this.isEmpty(this.delivery_address) && this.location_selected
          break;
        case 'CURBSIDE':
          valid = !this.isEmpty(this.curbside_vehicle_id)
          break;
        case 'DINE-IN':
          valid = !this.isEmpty(this.dine_in_table_number)
          break;
        case 'TABLESIDE':
          valid = !this.isEmpty(this.dine_in_table_number)
          break;
      }

      if(this.time === 'LATER'){
        return valid && !this.isEmpty(this.occasion_schedule)
      }else{
        return valid;
      }
  }
}



