import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';


export class Item{
  id: number;
  name: string;
  constructor(id, name){
    this.id = id;
    this.name = name;
  }
}

@Component({
  selector: 'app-item-auto-complete-chips',
  templateUrl: './item-auto-complete-chips.component.html',
  styleUrls: ['./item-auto-complete-chips.component.scss']
})
export class ItemAutoCompleteChipsComponent{

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  itemCtrl = new UntypedFormControl();
  filteredItems: Observable<Item[]>;
  @Input() selectedItems = [];

  @Input() allItems: Item[] = [];
  @Input() label:string = '';

  @ViewChild('itemInput',{static: false}) itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto',{static: false}) matAutocomplete: MatAutocomplete;

  @Output() emitSelected = new EventEmitter<Item[]>();


  constructor() {
    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map((item: Item | null) => item ? this._filter(item) : this.allItems.slice()));
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.selectedItems.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.itemCtrl.setValue(null);
  }

  remove(item: string): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log(event);

    this.selectedItems.push(event.option.value);
    this.emitSelected.emit(this.selectedItems);
    this.itemInput.nativeElement.value = '';
    this.itemCtrl.setValue(null);
  }

  private _filter(value: any): any[] {
    let filterValue;
    if(typeof value === 'string'){
      filterValue = value.toLowerCase();
    }else{
      filterValue = value.name.toLowerCase();
    }
    return this.allItems.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
  }


}
