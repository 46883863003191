export class Payment {
  bot_order_id: number;

  constructor(orderId) {
    this.bot_order_id = orderId;
  }
}

export class RefundPayment extends Payment {
  refund_amount: number;

  constructor(orderId: number, refundAmount: number) {
    super(orderId);
    this.refund_amount = refundAmount;
  }
}

export class AdjustPayment extends Payment {
  adjust_amount: number;

  constructor(orderId: number, adjustAmount: number) {
    super(orderId);
    this.adjust_amount = adjustAmount;
  }
}

export class CardDetail{
  id: number;
  name_on_card: string;
  card_type: string;
  phone_number: string;
  token: string;
  expiry: number;
  cvc: number;
  store: number;
  expiryMonth: string;
  expiryYear: string;
  last_4: string;
  gateway: string;
}

