import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from 'src/app/_services/store.service';

@Component({
  selector: 'app-order-ready-settings',
  templateUrl: './order-ready-settings.component.html',
  styleUrls: ['./order-ready-settings.component.scss']
})
export class OrderReadySettingsComponent implements OnInit {

  form:UntypedFormGroup;
  toggleValue: boolean = false;
  storeID: any;
  store;
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<OrderReadySettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService
  ) {
    this.store = this.data.store;
   }

  ngOnInit() {
    this.storeID = localStorage.getItem('currentStore');
    this.toggleValue = this.store.order_ready_notified;
    this.form = new UntypedFormGroup({
      pickup_message: new UntypedFormControl(this.store.occasion_pickup_message),
      dinein_message: new UntypedFormControl(this.store.occasion_dinein_message),
      delivery_message: new UntypedFormControl(this.store.occasion_delivery_message),
      curbside_message: new UntypedFormControl(this.store.occasion_curbside_message),
      tableside_message: new UntypedFormControl(this.store.occasion_tableside_message),
    });
  }

  onChangeToggle(event) {
    this.toggleValue = event.checked;
  }

  submitMessagesForm() {
    this.isLoading = true;
    const paylaod = {
      "order_ready_notified": this.toggleValue,
      "occasion_pickup_message": this.form.value.pickup_message,
      "occasion_dinein_message": this.form.value.dinein_message,
      "occasion_delivery_message": this.form.value.delivery_message,
      "occasion_curbside_message": this.form.value.curbside_message,
      "occasion_tableside_message": this.form.value.tableside_message
    }
    this.storeService.orderReadyMessageSetting(this.storeID, paylaod).subscribe((data:any) => {
      this.isLoading = false;
      this.dialogRef.close();
    });
  }

}
