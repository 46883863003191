import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PinConfirmComponent } from 'src/app/orders/pin-confirm/pin-confirm.component';
import { OrdersWithPayment } from 'src/app/_models/order';
import { TransationHistory } from 'src/app/_models/order-transaction-history';
import { Permission } from 'src/app/_models/permission';
import { Store } from 'src/app/_models/store';
import { OrderService } from 'src/app/_services/order.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-history-refund',
  templateUrl: './history-refund.component.html',
  styleUrls: ['./history-refund.component.scss']
})
export class HistoryRefundComponent implements OnInit, OnDestroy {

  constructor(private formbuilder: UntypedFormBuilder,
    private orderService: OrderService,
    private dialog: MatDialog,
    private permissionService: PermissionService) { }

  @Input() order: OrdersWithPayment;
  @Input() txn: TransationHistory;

  @Output() refresh = new EventEmitter();

  store: Store;
  paymentForm: UntypedFormGroup;
  paymentHint: string;
  isPaymentLoading = false;
  paymentFailed = false;
  paymentSuccess = false;
  permission: Permission;
  pinPermission: Permission;
  pinUser: any;
  private destroySubject: Subject<void> = new Subject();
  formDisplayed: boolean = false;

  ngOnInit() {
    if (this.txn) {
      this.txn = Object.assign(new TransationHistory(), this.txn);
      this.store = this.order.store;
      this.permissionService.pinCurrentOperator.pipe(takeUntil(this.destroySubject)).subscribe((permission) => {
        this.permission = permission;
      });

      this.paymentForm = this.formbuilder.group({
        history_id: [this.txn.id, Validators.required],
        order_id: [this.order.bot_order.order_hash, Validators.required],
        payment_type: [{ value: '', disabled: this.txn.getAvailableBalance() <= 0 }, Validators.required],
        amount: [{ value: '', disabled: this.txn.getAvailableBalance() <= 0 }, [Validators.required, Validators.max(this.txn.getAvailableBalance())]],
        note: ''
      });

      if (this.store.hasModule('POS')) {
        this.formDisplayed = true;
      } else {
        this.paymentForm.addControl('cashier_name', this.formbuilder.control('', Validators.required));
        if (this.paymentForm.contains('cashier_name')) {
          this.formDisplayed = true;
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  onPaymentTypeChange(paymentType) {
    this.paymentForm.get('amount').enable();
    switch (paymentType) {
      case 'TIP':
        this.paymentHint = 'Enter tip amount above. Tips will not be taxed and are added to the Order Total';
        break;
      case 'ADJUST':
        this.paymentHint = 'Enter increase value above. Taxes will be automatically applied accordingly';
        break;
      case 'REFUND':
        this.paymentHint = 'Submit a partial refund';
        break;
      case 'FULL_REFUND':
        this.paymentForm.get('amount').setValue(this.txn.getAvailableBalance());
        this.paymentForm.get('amount').disable();
        this.paymentHint = 'Submit a full refund';
        break;
      default:
        return 'Please select a payment operation';
    }
  }

  checkPermissions() {
    const payload = this.paymentForm.getRawValue();
    console.log(payload);

    let ops = payload.payment_type;
    if (ops === 'PARTIAL_REFUND' || ops === 'FULL_REFUND') {
      ops = 'REFUND';
    }

    if (ops === 'TIP_ADJUST') {
      ops = 'ADJUST';
    }

    if (!this.store.hasModule('POS')) {
      this.processPayment();
    }
    else {
      if (this.permission.hasPermission('POS_FUNCTIONS', ops)) {
        this.processPayment();
      } else {
        const isMobile = window.innerWidth <= 599;
        const dialogRef = this.dialog.open(PinConfirmComponent, {
          width: isMobile ? '100vw' : '500px',
          height: isMobile ? '100vh' : '410px',
          maxWidth: isMobile ? '100vw' : '80vw',
          data: {}
        });
        dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
          this.pinPermission = resultPer.permission;
          if (this.pinPermission.hasPermission('POS_FUNCTIONS', ops)) {
            this.processPayment();
            this.orderService.updateDescription(this.order.bot_order.id,
              { description: resultPer.description }).pipe(takeUntil(this.destroySubject)).subscribe((updated) => {
                console.log('________________', updated);
              });
          } else {
            const dialogRef = this.dialog.open(AlertsMessagesComponent, {
              disableClose: true,
              width: '364px',
              minHeight: '20vh',
              data: {
                message: 'This User/Manager does not have the required permission to perform this operation!'
              }
            });
            dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
            });
            // alert('This User/Manager does not have the required permission to perform this operation!' );
          }
        });
      }
    }
  }

  processPayment() {
    this.isPaymentLoading = true;
    this.pinUser = localStorage.getItem('pinUser');
    if (!this.pinUser) {
      this.pinUser = '';
    }
    const fromValue = this.paymentForm.getRawValue();
    const keyData = {
      history_id: fromValue.history_id,
      order_id: fromValue.order_id,
      payment_type: fromValue.payment_type,
      amount: fromValue.amount,
      note: fromValue.note,
      user: this.pinUser,
      cashier_name: this.store.hasModule('POS') ? localStorage.getItem('posLoggedUser') : fromValue.cashier_name
    };
    this.orderService.processPostPayment(this.store.id, keyData).subscribe(data => {
      this.isPaymentLoading = false;
      this.paymentFailed = false;
      this.paymentSuccess = true;
      this.orderService.loadClosedOpenSubject.next(true);
      this.refresh.emit(null);
    }, (error) => {
      this.isPaymentLoading = false;
      this.paymentFailed = true;
    });
  }
}
