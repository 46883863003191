<div class="spinner-container" *ngIf="dataSource.loading$ | async">
<mat-spinner></mat-spinner>
</div>

<div class="example-container ys-campaign order-container">
<mat-table width="100%" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Campaign Name</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }"> {{element.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="start_time">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Started</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Started">
      <ng-container *ngIf="element.stats.sent === 0; then oldSent else newSent"></ng-container>
      <ng-template #oldSent>
        {{ element.run_time | date: 'hh:mmaaa M/d/yy'}}
      </ng-template>
      <ng-template #newSent>
        {{ element.stats.start_time + 'Z' | date: 'hh:mmaaa M/d/yy'}}
      </ng-template>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="end_time">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Finished</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Finished" >
      <ng-container *ngIf="element.stats.sent === 0; then oldSent else newSent"></ng-container>
      <ng-template #oldSent>NA</ng-template>
      <ng-template #newSent>
        <ng-container *ngIf="element.status === 'COMPLETED'">{{ element.stats.end_time + 'Z'| date: 'hh:mmaaa M/d/yy'}}</ng-container>
        <ng-container *ngIf="element.status !== 'COMPLETED'"></ng-container>
      </ng-template>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Status" fxHide.lt-sm>
      {{element.status | titlecase}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="sent">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Sent</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Audience" fxHide.lt-sm>
      <ng-container *ngIf="element.stats.sent === 0; then oldSent else newSent"></ng-container>
      <ng-template #oldSent>{{element.sent}}</ng-template>
      <ng-template #newSent>{{element.stats.sent}}</ng-template>

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="delivered">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Delivered</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Audience" fxHide.lt-sm>
      <ng-container *ngIf="element.stats.sent === 0; then oldSent else newSent"></ng-container>
      <ng-template #oldSent>NA</ng-template>
      <ng-template #newSent>{{element.stats.delivered}}</ng-template>


    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="error">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Bounce/error</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Audience" fxHide.lt-sm>
      <ng-container *ngIf="element.stats.sent === 0; then oldSent else newSent"></ng-container>
      <ng-template #oldSent>NA</ng-template>
      <ng-template #newSent>{{element.stats.failed}}</ng-template>


    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="reach">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Reach</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Audience" fxHide.lt-sm>
      <ng-container *ngIf="element.stats.sent === 0; then oldSent else newSent"></ng-container>
      <ng-template #oldSent>{{ (element.sent/stats.opted_in) | percent}}</ng-template>
      <ng-template #newSent>{{ (element.stats.delivered/stats.opted_in) | percent}}</ng-template>


    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cost</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Cost"> {{element.cost | currency:'USD'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="edit(element)">{{element.status === 'SCHEDULED' ? 'Edit' : 'View'}}</button>
          <button mat-menu-item (click)="copy(element)">Copy</button>
          <!-- <button mat-menu-item (click)="delete(element)">Delete</button> -->
          <!-- <button disabled mat-menu-item (click)="resend(element)">Resend</button> -->
        </mat-menu>

      </mat-cell>
  </ng-container>

  <ng-container matColumnDef="progress">
    <mat-header-cell *matHeaderCellDef>Progress</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <app-campaign-progress class="progress" [campaignId]="element.id"
                             [stats]="stats" [startTime]="element.stats.start_time"></app-campaign-progress>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
</div>


