import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from 'src/app/_services/store.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from 'src/app/_helpers/form-utils';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  form;
  isLoading
  user;
  store;
  constructor(public dialogRef: MatDialogRef<ContactDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private storeService: StoreService,
              private formUtils: FormUtils) { }


  ngOnInit() {
    this.user = this.data.user;
    this.store = this.data.store;
    this.form = new UntypedFormGroup({
      phone_number: new UntypedFormControl(this.user.phone_number || '', Validators.required),
      store: new UntypedFormControl(this.store.id),
      name: new UntypedFormControl(this.user.name || ''),
      is_blocked: new UntypedFormControl(this.user.is_blocked || false)
    })
  }

  update(){
    let payload = this.form.value
    payload['phone_number'] = this.formUtils.getPlainPhoneNumber(payload['phone_number']);
    this.storeService.updateContact(this.form.value).subscribe(data => {
      this.dialogRef.close();
    })
  }

}
