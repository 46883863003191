<span *ngIf="data.view == 'add'">
    <form class="master-cat-from" class="fixActionRow" name="addTVScreenForm" [formGroup]="addTVScreenForm"
        (ngSubmit)="addTVFormSubmit()">
        <h1 mat-dialog-title>Create TV Screen</h1>

        <div mat-dialog-content>

            <div *ngIf="storeType == 'Leaflogix'" fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>TV ID</mat-label>
                    <input matInput formControlName="tv_id" placeholder="">
                </mat-form-field>
            </div>

            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screens</mat-label>
                    <mat-select multiple formControlName="screens">
                        <mat-option *ngFor="let data of screenData" [value]="data.id">
                            {{data.tv_id}}:{{data.content_type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="storeType == 'Restaurant'" fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Columns per screen</mat-label>
                    <input matInput formControlName="columns_per_screen" placeholder="" type="number">
                </mat-form-field>
            </div>
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screen Duration</mat-label>
                    <input matInput formControlName="screen_duration" placeholder="" type="number">
                </mat-form-field>
            </div>
            <div *ngIf="storeType == 'Restaurant'" fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screen View Type</mat-label>
                    <mat-select formControlName="screen_view_type">
                        <mat-option value="Rotation"> Rotation </mat-option>
                        <mat-option value="Column"> Column </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" class="xs:!w-full" style="margin-top: 14px;">
                <div class="input-gap">
                    <mat-checkbox formControlName="hide_key_display" (change)="keyDisplayCheck($event)"
                        [checked]="keyDisplayScreenCheck">Hide Key Display
                    </mat-checkbox>
                </div>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        </div>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>

<span *ngIf="data.view == 'edit'">
    <form class="master-cat-from" class="fixActionRow" name="editTVScreenForm" [formGroup]="editTVScreenForm"
        (ngSubmit)="editTVScreenFormSubmit()">
        <h1 mat-dialog-title>Edit TV Screen</h1>

        <div mat-dialog-content>

            <div *ngIf="storeType == 'Leaflogix'" fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>TV ID</mat-label>
                    <input matInput formControlName="tv_id" placeholder="">
                </mat-form-field>
            </div>


            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screens</mat-label>
                    <mat-select multiple formControlName="screens">
                        <mat-option *ngFor="let data of screenData" [value]="data.id">
                            {{data.tv_id}}:{{data.content_type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="storeType == 'Restaurant'" fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Columns per screen</mat-label>
                    <input matInput formControlName="columns_per_screen" placeholder="" type="number">
                </mat-form-field>
            </div>
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screen Duration</mat-label>
                    <input matInput formControlName="screen_duration" placeholder="" type="number">
                </mat-form-field>
            </div>
            <div *ngIf="storeType == 'Restaurant'" fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screen View Type</mat-label>
                    <mat-select formControlName="screen_view_type">
                        <mat-option value="Rotation"> Rotation </mat-option>
                        <mat-option value="Column"> Column </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" class="xs:!w-full" style="margin-top: 14px;">
                <div class="input-gap">
                    <mat-checkbox formControlName="hide_key_display" (change)="keyDisplayCheck($event)"
                        [checked]="keyDisplayScreenCheck">Hide Key Display
                    </mat-checkbox>
                </div>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        </div>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>


<span *ngIf="data.view == 'delete'">
    <h1 mat-dialog-title>Delete TV Screen</h1>
    <div mat-dialog-content>
        Are you sure you want to Delete TV Screen
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
        <button mat-button mat-flat-button color="primary" class="apply-btn-colr"
            (click)="deleteTVScreen()">Delete</button>
    </mat-dialog-actions>
</span>