<div class="dialog-content flex flex-col h-full xs:!overflow-hidden">
  <div *ngIf="permissionDenied" class="flex justify-center p-4 z-10">
    <button (click)="closeDialog()" class="px-4 py-2 rounded-lg w-full !h-[50px] border border-black ">Close</button>
  </div>
  <div *ngIf="permissionDenied" class="flex items-center justify-center flex-grow">
    <p class="text-center p-4 rounded-lg">Camera permission has been denied. Please enable the camera permission in your
      browser settings.</p>
  </div>
  <div *ngIf="isScanning && !permissionDenied" class="flex justify-center p-4 z-10">
    <button (click)="closeDialog()" class="px-4 py-2 bg-green-500 text-white rounded-lg w-full !h-[50px]">DONE SCANNING</button>
  </div>
  <div *ngIf="isScanning && !permissionDenied" class="flex-grow absolute">
    <zxing-scanner [start]="startScanner" [device]="selectedDevice" (scanSuccess)="handleScanSuccess($event)"
      (scanError)="handleScanError($event)" (scanFailure)="handleScanFailure($event)"
      (camerasFound)="camerasFoundHandler($event)" (camerasNotFound)="camerasNotFoundHandler()"
      [formats]="allowedFormats" [torch]="torchEnabled" [tryHarder]="true" class="w-full h-full xs:!overflow-hidden xs:!contents"></zxing-scanner>
  </div>

</div>