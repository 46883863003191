import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from 'src/app/_services/store.service';
import { UploadService } from 'src/app/_services/upload.service';
import { disclaimer, offerV2Disclaimer } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { MenuCategoryAvailabilityDialogComponent } from 'src/app/info/store-menu/menu-category/menu-category-availability-dialog/menu-category-availability-dialog.component';
import { FormUtils } from 'src/app/_helpers/form-utils';


@Component({
  selector: 'app-new-offer-dialog',
  templateUrl: './new-offer-dialog.component.html',
  styleUrls: ['./new-offer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewOfferDialogComponent implements OnInit {

  form: UntypedFormGroup;
  isLoading;
  store;

  image: File[];
  imageStr: string;

  step = 0;

  formQuantity = Array.from(Array(10).keys()).map(n => n + 1);
  portions;
  menuItems;
  categories;

  triggerConditions;
  offerConditions;

  defaultDisclaimer = offerV2Disclaimer;
  setOfferDayAvailability: string[];
  selectedOption: string;

  constructor(public dialogRef: MatDialogRef<NewOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    private dialog: MatDialog,
    private uploadService: UploadService,  private formBuilder: UntypedFormBuilder,
    private formUtils:FormUtils,) {
    this.store = data.store;
  }

  ngOnInit() {
    let pobs = this.storeService.getStorePortions(this.store.id);
    let mobs = this.storeService.getStoreMenuItems(this.store.id);
    let cobs = this.storeService.getStoreCategories(this.store.id);

    forkJoin(pobs, mobs, cobs).subscribe(data => {
      this.portions = data[0];
      this.menuItems = data[1];
      this.categories = data[2];
    })

    this.form = this.getFormData(null);
    this.triggerConditions = this.form.get('trigger').get('condition') as UntypedFormArray;
    this.offerConditions = this.form.get('offer_value').get('condition') as UntypedFormArray;
    console.log(this.form);
    console.log(this.data);


    if (this.data.offer) {
      this.imageStr = this.data.offer.image
      this.selectedOption = this.data.offer.availability_type;
    }
  }

  getOrElse(key, parent = null, _default = undefined) {
    if (parent) {
      return this.data.offer && this.data.offer[parent] ? this.data.offer[parent][key] : _default
    } else {
      return this.data.offer && this.data.offer[key] ? this.data.offer[key] : _default
    }
  }

  getFormData(data) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.getOrElse('id')),
      details: new UntypedFormGroup({
        store_id: new UntypedFormControl(this.store.id),
        offer_title: new UntypedFormControl(this.getOrElse('offer_title'), Validators.required),
        offer_description: new UntypedFormControl(this.getOrElse('offer_description'), Validators.required),
        promo_code: new UntypedFormControl(this.getOrElse('promo_code')),
      }),
      trigger: new UntypedFormGroup({
        trigger_type: new UntypedFormControl(this.getOrElse('trigger_type', 'trigger'), Validators.required),
        spend_threshold: new UntypedFormControl(this.getOrElse('spend_threshold', 'trigger')),
        condition: new UntypedFormArray(this.getOrElse('condition', 'trigger', [{}]).map(p => this.getConditionFormGroup(p, this.getOrElse('trigger_type', 'trigger')))),
      }),
      offer_value: new UntypedFormGroup({
        offer_type: new UntypedFormControl(this.getOrElse('offer_type', 'offer'), Validators.required),
        offer_value: new UntypedFormControl(this.getOrElse('offer_value', 'offer'), Validators.required),
        applies_to: new UntypedFormControl(this.getOrElse('applies_to', 'offer')),
        condition: new UntypedFormArray(this.getOrElse('condition', 'offer', [{}]).map(p => this.getConditionFormGroup(p, this.getOrElse('applies_to', 'offer')))),
      }),
      conditions: new UntypedFormGroup({
        limit_1: new UntypedFormControl(this.getOrElse('limit_1')),
        private: new UntypedFormControl(this.getOrElse('private')),
        disclaimer: new UntypedFormControl(this.getOrElse('disclaimer', null, offerV2Disclaimer)),
        start_date: new UntypedFormControl(this.getOrElse('start_date')),
        end_date: new UntypedFormControl(this.getOrElse('end_date')),
        availability_type: new UntypedFormControl(this.getOrElse('availability_type'), Validators.required),
      })
    });
  }

  public updateDisclaimer(value) {
    let defaultDisclaimer = offerV2Disclaimer.replace("[XYZ]", value)
    this.form.get('conditions').get('disclaimer').setValue(defaultDisclaimer);
  }

  public objectComparisonFunction = function (option, value): boolean {
    return option === value;
  }

  create() {
    let files = new Set<File>();
    if (this.image && this.image.length > 0) {
      files.add(this.image[0])
    }

    if (this.setOfferDayAvailability && this.form.value.conditions.availability_type == "CUSTOM") {
      this.form.value.conditions.day_availability = this.setOfferDayAvailability;
    }

    let progress = this.uploadService.upload(`${environment.apiUrl}/backend/v2/offers-v2/`, files, { payload: JSON.stringify(this.form.value) })
    forkJoin(Object.values(progress).map(p => p.progress)).subscribe(
      data => console.log(data),
      err => console.log(err),
      () => {if (progress)  {
        setTimeout(() => {
          this.dialogRef.close();
        }, 2000);
      }})
      
  }

  setImageStr(image) {
    this.imageStr = image;
  }

  setImageFile(image) {
    this.image = image;
  }

  changeValidation(event) {
    console.log(event);
    if (event.value === 'FACE') {
      this.form.get('sale_price').setValidators(null)
    } else {
      this.form.get('sale_price').setValidators(Validators.required)
    }
    this.form.get('sale_price').updateValueAndValidity()
  }

  openWeekdayDialog(event: any) {
    
    const selectedValue = event;
    if (selectedValue === 'CUSTOM') {
      let dialogRef = this.dialog.open(MenuCategoryAvailabilityDialogComponent, {
        disableClose: true,
        data: {
          from_where: 'from_gift',
          availabilityType: this.data.offer?.availability_type,
          availabilitySetting: {
            availability_type:this.data.offer?.availability_type,
            day_availability:this.data.offer?.day_availability,
            store: this.store
          },
          store: this.store
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.setOfferDayAvailability = result.day_availability;
        } else {
          let formGroup = {}
          this.data.offer.day_availability.map((day, i) => {
            let from12 = this.formUtils.get12HourValue(day.hours[0].from_hour, day.hours[0].from_min)
            let to12 = this.formUtils.get12HourValue(day.hours[0].to_hour, day.hours[0].to_min)
    
            formGroup[day.day] = this.formBuilder.group({
              day: [day.day],
              is_available: [day.is_available],
              from: [from12],
              to: [to12],
            })
          })
          this.setOfferDayAvailability = new UntypedFormGroup(formGroup).value;
        }
      });
    }
    this.selectedOption = event;

  }

  setStep(index: number) {
    const isMobile = window.innerWidth <= 599;
    if (isMobile && index === 0) {
      this.step = null;
    } else {
      this.step = index;
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getConditionFormGroup(p, triggerType) {
    let fg = new UntypedFormGroup({
      quantity: new UntypedFormControl(p && p.quantity ? p.quantity : ''),
      portion: new UntypedFormControl(p && p.portion ? p.portion.id : ''),
      category: new UntypedFormControl(p && p.category ? p.category.id : ''),
      item: new UntypedFormControl(p && p.item ? p.item.id : '')
    });
    if (triggerType === 'CATEGORY') {
      fg.get('category').setValidators([Validators.required])
    } else if (triggerType === 'ITEM') {
      fg.get('item').setValidators([Validators.required])
    }
    return fg;
  }

  addCondition(parent, triggerType) {
    (this.form.get(parent).get('condition') as UntypedFormArray).push(this.getConditionFormGroup(null, triggerType))
  }

  deleteCondition(parent, index) {
    (this.form.get(parent).get('condition') as UntypedFormArray).removeAt(index)
  }

  updateTriggerValidation(event) {
    console.log(event.value);
    if (event.value === 'SPEND') {
      (this.form.get('trigger').get('condition') as UntypedFormArray).controls.forEach(c => c.disable());
      (this.form.get('trigger').get('spend_threshold') as UntypedFormControl).setValidators([Validators.required]);
      (this.form.get('trigger').get('spend_threshold') as UntypedFormControl).updateValueAndValidity();
    } else if (event.value === 'CATEGORY') {
      (this.form.get('trigger').get('spend_threshold') as UntypedFormControl).disable();
      (this.form.get('trigger').get('condition') as UntypedFormArray).controls.forEach(c => {
        c.enable();
        (c.get('category') as UntypedFormControl).setValidators([Validators.required]);
        (c.get('category') as UntypedFormControl).updateValueAndValidity();
        (c.get('item') as UntypedFormControl).disable()
      })
    } else if (event.value === 'ITEM') {
      (this.form.get('trigger').get('spend_threshold') as UntypedFormControl).disable();
      (this.form.get('trigger').get('condition') as UntypedFormArray).controls.forEach(c => {
        c.enable();
        (c.get('item') as UntypedFormControl).setValidators([Validators.required]);
        (c.get('item') as UntypedFormControl).updateValueAndValidity();
        (c.get('category') as UntypedFormControl).disable()
      })
    }
  }

  updateOfferValidation(event) {
    if (event.value === 'ORDER') {
      (this.form.get('offer_value').get('condition') as UntypedFormArray).controls.forEach(c => c.disable());
    } else if (event.value === 'CATEGORY') {
      (this.form.get('offer_value').get('condition') as UntypedFormArray).controls.forEach(c => {
        c.enable();
        (c.get('category') as UntypedFormControl).setValidators([Validators.required]);
        (c.get('category') as UntypedFormControl).updateValueAndValidity();
        (c.get('item') as UntypedFormControl).disable()
      })
    } else if (event.value === 'ITEM') {
      (this.form.get('offer_value').get('condition') as UntypedFormArray).controls.forEach(c => {
        c.enable();
        (c.get('item') as UntypedFormControl).setValidators([Validators.required]);
        (c.get('item') as UntypedFormControl).updateValueAndValidity();
        (c.get('category') as UntypedFormControl).disable()
      })
    }
  }


}
