<form (ngSubmit)="onSubmit()" [formGroup]="form" class="fixActionRow">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <h1 mat-dialog-title>Hours</h1>
  <div class="ml-6">
    <p class="message">Enter hours for days when this business has an irregular schedule</p>
  </div>
  <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
    <div *ngIf="!isLoading">
      <table class="table" cellpadding=5>
        <tr *ngFor="let hour of form.controls; index as i" [formGroupName]="i">
          <td>
            <mat-form-field>
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="special_date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <mat-label>End date</mat-label>
              <input matInput [matDatepicker]="pickerone" formControlName="special_end_date">
              <mat-datepicker-toggle matSuffix [for]="pickerone"></mat-datepicker-toggle>
              <mat-datepicker #pickerone></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-slide-toggle formControlName="is_open" (click)="toggle(i)">{{ hour.value.is_open ? 'Open' : 'Closed'
              }}</mat-slide-toggle>
          </td>
          <td>
            <mat-form-field *ngIf="hour.value.is_open">
              <mat-label>From</mat-label>
              <input matInput [ngxTimepicker]="timepicker" formControlName="from">
              <ngx-material-timepicker #timepicker class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field *ngIf="hour.value.is_open">
              <mat-label>To</mat-label>
              <input matInput [ngxTimepicker]="timepickerone" formControlName="to">
              <ngx-material-timepicker #timepickerone class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </td>
          <td class="delete_icon">
            <button mat-icon-button (click)="delete(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </table>
      <button mat-raised-button (click)="addNew()" type="button" class="new-date-btn">Add new date</button>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary"
      class="primary-color primary-button apply-btn-colr">Apply</button>
  </mat-dialog-actions>
</form>