export const environment = {
  production: true,
  apiUrl: 'https://backend.text-order.com',
  stripeClientId: 'ca_DIebjgGfiJUOO3sBXrfRNNiSvWADVksN',
  // websocketHost:'wss://backend.text-order.com',
  websocketHost:'wss://production.labrador.ai',
  orderAppUrl: 'https://order.text-order.com',
  placesAPIKey: 'AIzaSyDRmxxVBcS640504Q28_fP9JJv8iH-Ki3A',
  firebase:{
    apiKey: "AIzaSyBKHYGsT9uTznTz5FOFxqVxjXeXyyUxkH0",
    authDomain: "labrador-frontend.firebaseapp.com",
    databaseURL: "https://labrador-frontend.firebaseio.com",
    projectId: "labrador-frontend",
    storageBucket: "labrador-frontend.appspot.com",
    messagingSenderId: "917402468037"
  },
  storeArr: [1, 645, 685],
  leaflogixApiEndpoint: "https://beta.labrador.ai",
};
