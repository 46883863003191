export const environment = {
  production: true,
  // apiUrl: 'https://backend.text-order.com',
  apiUrl: 'https://order-api-backend-628090102143.us-east1.run.app',
  stripeClientId: 'ca_DIebjgGfiJUOO3sBXrfRNNiSvWADVksN',
  websocketHost:'wss://backend.text-order.com',
  // orderAppUrl: 'https://order.text-order.com',
  orderAppUrl: 'https://clr-orderapifrontend-staging.firebaseapp.com',
  placesAPIKey: 'AIzaSyDRmxxVBcS640504Q28_fP9JJv8iH-Ki3A',
  firebase:{
    apiKey: "AIzaSyBKHYGsT9uTznTz5FOFxqVxjXeXyyUxkH0",
    authDomain: "labrador-frontend.firebaseapp.com",
    databaseURL: "https://labrador-frontend.firebaseio.com",
    projectId: "labrador-frontend",
    storageBucket: "labrador-frontend.appspot.com",
    messagingSenderId: "917402468037"
  },
  storeArr: [1, 645, 685],
  // leaflogixApiEndpoint: "https://beta.labrador.ai",
  leaflogixApiEndpoint: "https://staging-pos-display-628090102143.us-central1.run.app",
  envID: 1
};
