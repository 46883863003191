import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';

@Component({
  selector: 'app-add-new-floor-plan',
  templateUrl: './add-new-floor-plan.component.html',
  styleUrls: ['./add-new-floor-plan.component.scss']
})
export class AddNewFloorPlanComponent {
  floorForm!: FormGroup;
  tableForm!: FormGroup;
  objectForm: FormGroup;
  storeid: any;
  selectedShape: string = 'square';
  rotation: number = 0;
  private destroySubject: Subject<void> = new Subject();

  constructor(private fb: FormBuilder, public floorPlanService: FloorPlanService, public dialogRef: MatDialogRef<AddNewFloorPlanComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.storeid = localStorage.getItem('currentStore');
    this.createForm();
  }

  createForm(): void {
    if (this.data.from === 'add-floor') {
      this.floorForm = this.fb.group({
        name: ['', Validators.required],
        width: ['', [Validators.required, Validators.min(1)]],
        height: ['', [Validators.required, Validators.min(1)]]
      });
    } else if (this.data.from === 'edit-floor') {
      const floorData = this.data.floor || {};
      this.floorForm = this.fb.group({
        name: [floorData.name || '', Validators.required],
        width: [floorData.width || '', [Validators.required, Validators.min(1)]],
        height: [floorData.height || '', [Validators.required, Validators.min(1)]]
      });
    } else if (this.data.from === 'add-table') {
      this.tableForm = this.fb.group({
        table_name: ['', Validators.required],
        shape: ['square', Validators.required],
        seating_capacity: ['']
      });
    } else if (this.data.from === 'edit-table') {
      const tableData = this.data.table || {};
      this.tableForm = this.fb.group({
        table_name: [tableData.table_name || '', Validators.required],
        shape: [tableData.shape || 'square', Validators.required],
        seating_capacity: [tableData.seating_capacity || ''],
        width: [tableData.width || '', [Validators.required, Validators.min(1)]],
        height: [tableData.height || '', [Validators.required, Validators.min(1)]]
      });
      if (tableData) {
        this.rotation = tableData.rotation;
        this.selectedShape = tableData.shape;
      }
    } else if (this.data.from === 'add-object') {
      this.objectForm = this.fb.group({
        object_name: ['', Validators.required]
      });
    } else if (this.data.from === 'edit-object') {
      const objectData = this.data.object || {};
      this.objectForm = this.fb.group({
        object_name: [objectData.object_name || '', Validators.required],
        width: [objectData.width || '', [Validators.required, Validators.min(1)]],
        height: [objectData.height || '', [Validators.required, Validators.min(1)]]
      });
    }
  }

  onTableTypeChange(type: string): void {
    this.selectedShape = type;
    this.rotation = 0;
  }

  rotateShape() {
    this.rotation = (this.rotation + 45) % 360;
  }

  onSubmit(): void {
    if (this.data.from == 'add-floor') {
      this.floorCreation(this.floorForm.value);
    } else if (this.data.from == 'edit-floor') {
      this.floorUpdate(this.floorForm.value);
    } else if (this.data.from == 'add-table') {
      this.tableCreation(this.tableForm.value);
    } else if (this.data.from == 'edit-table') {
      this.updateTable(this.tableForm.value);
    } else if (this.data.from === 'add-object') {
      this.objectCreation(this.objectForm.value);
    } else if (this.data.from === 'edit-object') {
      this.updateObject(this.objectForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  floorCreation(form) {
    form = { ...form, store: this.storeid };
    this.floorPlanService.createFloor(form).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }

  floorUpdate(form) {
    if (this.data.floor) {
      this.data.floor.name = form.name || this.data.floor.name;
      this.data.floor.width = form.width || this.data.floor.width;
      this.data.floor.height = form.height || this.data.floor.height;
    }
    this.floorPlanService.updateFloor(this.data.floor).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }

  tableCreation(form) {
    if (form.shape == 'rectangle') {
      form = { ...form, store: this.storeid, floor_plan: this.data.f_id, rotation: this.rotation, width: 150, height: 100 };
    } else {
      form = { ...form, store: this.storeid, floor_plan: this.data.f_id, rotation: this.rotation };
    }
    this.floorPlanService.createTable(form).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }

  updateTable(form) {
    if (this.data.table) {
      this.data.table.table_name = form.table_name || this.data.table.table_name;
      this.data.table.shape = form.shape || this.data.table.shape;
      this.data.table.seating_capacity = form.seating_capacity || this.data.table.seating_capacity;
      this.data.table.rotation = this.rotation || this.data.table.rotation;
      if (this.data.from == 'edit-table') {
        this.data.table.width = form.width || this.data.table.width;
        this.data.table.height = form.height || this.data.table.height;
      }
    }
    this.floorPlanService.updateFloorSingleTable(this.data.table).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }

  objectCreation(form) {
    form = { ...form, store: this.storeid, floor_plan: this.data.f_id };
    this.floorPlanService.createObject(form).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }

  updateObject(form) {
    if (this.data.object) {
      this.data.object.object_name = form.object_name || this.data.object.table_name;
      if (this.data.from == 'edit-object') {
        this.data.object.width = form.width || this.data.object.width;
        this.data.object.height = form.height || this.data.object.height;
      }
    }
    this.floorPlanService.updateFloorSingleObject(this.data.object).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }


}
