import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BooleanOptions, Options } from 'src/app/_models/Options';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { MenuResetOptions, Store } from 'src/app/_models/store';
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { log } from "util";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataRow } from 'src/app/_models/data-row';
import { MatOption } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createLogErrorHandler } from '@angular/compiler-cli/ngcc/src/execution/tasks/completion';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

interface Option {
  id: number; // Adjust the type based on your actual data structure
  // Add other properties if needed
}

@Component({
  selector: 'app-modifiers',
  templateUrl: './modifiers.component.html',
  styleUrls: ['./modifiers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class ModifiersComponent implements OnInit {

  presenseOptions: BooleanOptions[];
  titleDict = {};
  mods: Options[];

  @Input() modifierGroup;
  @Input() store: Store;

  @Output() modGroupUpdate = new EventEmitter();
  @Output() refresh = new EventEmitter();
  // @ViewChild('allSelected') private allSelected: MatSelect;
  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
  tempDs = new MatTableDataSource<any>();


  expandedElement = new Map<string, any>();
  previous: any;
  displayedColumns = ['name', 'star'];

  modiferOrder = [];
  checked: boolean = true;
  max;
  @Input() availability;
  @Input() searchTerm = '';
  private destroySubject: Subject<void> = new Subject();
  resetOptions = MenuResetOptions;
  isExpand = false;
  ascending: boolean = false;
  isGroupLoading = false;
  isTouchDevice: boolean;
  formControl = new FormControl();
  modOptionsList: Option[] = [];
  filteredOptions: Option[] = [];
  element: { form: FormGroup };
  selectedJoinedWith: number | null = null;
  isIconHovered: boolean = false;

  constructor(private menuService: MenuServiceV2, private ref: ChangeDetectorRef, private snackBar: MatSnackBar, private fb: FormBuilder) {
    this.isTouchDevice = this.checkIfTouchDevice();
    this.element = {
      form: this.fb.group({
        joined_with: [''], // Other form controls
      })
    };

  }

  checkIfTouchDevice(): boolean {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }

  ngOnInit() {
    this.mods = [];
    this.mods.push(new Options('No Max', -1));

    for (let index = 1; index < 101; index++) {
      this.mods.push(new Options('' + index, index));
    }

    this.presenseOptions = [];
    this.presenseOptions.push(new BooleanOptions('Required', true));
    this.presenseOptions.push(new BooleanOptions('Optional', false));
    this.menuService.getModOptionIds(this.store.id).pipe(
      takeUntil(this.destroySubject)
    ).subscribe((data: Option[]) => { // Expecting an array of Option objects
      this.modOptionsList = data;
      this.filteredOptions = [...this.modOptionsList]; // Initialize filteredOptions with all options
    });
    this.getDataSource(this.store?.id, this.modifierGroup, '');
  }

  isAllSelected(controls, option) {
    var allSelected = true;
    Object.keys(controls).forEach(key => allSelected = allSelected && controls[key].controls[option].value)
    return allSelected
  }

  toggleAll(controls, option, value) {
    Object.keys(controls).forEach(key => {
      controls[key].controls[option].setValue(value.checked)
      if (value.checked) {
        controls[key].controls['max_mod_units'].setValue(1);
        controls[key].controls['max_mod_units'].enable()
      } else {
        controls[key].controls['max_mod_units'].setValue(null);
        controls[key].controls['max_mod_units'].disable()
      }
    });

  }

  fillAll(controls, option, event) {
    Object.keys(controls).forEach(key => controls[key].controls[option].setValue(event.value))
  }

  changeMaxMods(event) {
    this.modifierGroup.max_mods = event.value
  }

  changeValue(event, formGroup) {
    this.checked = event.checked;
    if (event.checked) {
      // Object.values(formGroup.value.availability).map((value) => {
      //   // @ts-ignore
      //   value.max_mod_units =  this.modifierGroup.max_mods
      // })
      // console.log('kuujygfgd', this.mods[this.mods.findIndex(m => m.value === this.modifierGroup.max_mods)]);
      console.log(formGroup.get('availability'));
      Object.values(formGroup.get('availability').value).map((value: any) => {
        console.log('bbbvalue.get(\'max_mod_units\')', value.max_mod_units);
        // value.get('max_mod_units').setValue(this.mods[this.mods.findIndex(m => m.value === this.modifierGroup.max_mods)].value);
        value.max_mod_units = this.mods[this.mods.findIndex(m => m.value === this.modifierGroup.max_mods)].value
        console.log('aaavalue.get(\'max_mod_units\')', value.max_mod_units);
      })
      this.max = this.modifierGroup.items.map((val) => {
        return val.max_mod_units = this.mods[this.mods.findIndex(m => m.value === this.modifierGroup.max_mods)].value
      })
      console.log('formGroup.value', formGroup.value);
      console.log('group 1', this.modifierGroup);
      this.menuService.updateModGroup(this.store.id, JSON.stringify(formGroup.value)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
        this.getDataSource(this.store.id, this.modifierGroup, '');
      })
    }

  }

  doSomething(event, formGroup) {
    console.log(Object.values(formGroup.value.availability));
    if (event.value) {
      this.modifierGroup.max_mods = event.value;
      console.log('d')
    }
    console.log('event change of do', event)
    console.log('max mod changing', this.modifierGroup.max_mods);
  }

  modSorting() {
    this.isGroupLoading = true;
    this.ascending = !this.ascending;
    const groupRows = this.tempDs.data.filter((row: any) => row.type === "modifier");
    const sortedGroups = groupRows.sort((a: any, b: any) => {
      const nameA = a.data.name.toLowerCase();
      const nameB = b.data.name.toLowerCase();
      return this.ascending ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });

    
    
    const sortedIds = sortedGroups.map((row: any) => row.data.id);
    console.log(sortedIds);

    this.menuService.updateOrder(this.store.id, JSON.stringify({
      type: 'modifier_options',
      order: sortedIds
    })).subscribe(() => {
      this.ngOnInit();
    });
  }


  getDataSource(storeId, modifierGroup, sort) {
    this.modiferOrder = [];
    this.menuService.getModifierOptions(storeId, modifierGroup.id, this.availability, this.searchTerm, sort).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.modifierGroup = data
      console.log('get modifier group', this.modifierGroup);
      this.modGroupUpdate.emit(data)

      const rows = [];
      let avlFormControls = []

      data.items.forEach(f => {
        avlFormControls.push(new UntypedFormGroup({
          id: new FormControl(f.id),
          name: new FormControl(f.name),
          is_available: new UntypedFormControl(f.is_available, Validators.required),
          reset: new UntypedFormControl(f.reset),
          has_half_mod: new UntypedFormControl(f.has_half_mod, Validators.required),
          is_pos_only: new UntypedFormControl(f.is_pos_only),
          joined_with: new UntypedFormControl(f.joined_with),
          has_quantity: new UntypedFormControl(f.has_quantity, Validators.required),
          max_mod_units: new UntypedFormControl({ value: f.max_mod_units, disabled: !f.has_quantity }),
        }));

        this.titleDict[f.id] = f.name
      })

      rows.push(new DataRow("mod_group", data, new UntypedFormGroup({
        id: new UntypedFormControl(data.id, Validators.required),
        name: new UntypedFormControl(data.name, Validators.required),
        max_mods: new UntypedFormControl(data.max_mods, Validators.required),
        is_required: new UntypedFormControl(data.is_required, Validators.required),
        availability: new UntypedFormArray(avlFormControls)
      })))

      data.items.map(mod => {
        this.modiferOrder.push(mod.id);
        var formattedDateTime = null
        if (mod.reset_time) {
          var resetTimeDate = new Date(mod.reset_time);
          formattedDateTime = resetTimeDate.toLocaleString("en-US", {
            timeZone: localStorage.getItem('storeTimezone'),
            hour12: false,
          }).replace(',', '');
        }
        return new DataRow('modifier', mod, new UntypedFormGroup({
          modifier_group: new UntypedFormControl(data.id, Validators.required),
          id: new UntypedFormControl(mod.id, Validators.required),
          name: new UntypedFormControl(mod.name, Validators.required),
          upcharge: new UntypedFormControl(mod.upcharge, Validators.required),
          food_cost: new UntypedFormControl(mod.food_cost),
          reset: new UntypedFormControl(mod.reset),
          half_mod_upcharge: new UntypedFormControl(mod.half_mod_upcharge),
          is_available: new UntypedFormControl(mod.is_available, Validators.required),
          has_quantity: new UntypedFormControl(mod.has_quantity, Validators.required),
          fixed_price: new UntypedFormControl(mod.fixed_price, Validators.required),
          has_half_mod: new UntypedFormControl(mod.has_half_mod, Validators.required),
          is_pos_only: new UntypedFormControl(mod.is_pos_only),
          joined_with: new UntypedFormControl(mod.joined_with),
          max_mod_units: new UntypedFormControl({ value: mod.max_mod_units, disabled: !mod.has_quantity }),
          reset_time: new UntypedFormControl(formattedDateTime || null),
          no_charge: new UntypedFormControl(mod.no_tag_upcharge),
          light_charge: new UntypedFormControl(mod.light_tag_upcharge),
          extra_charge: new UntypedFormControl(mod.extra_tag_upcharge),
          onside_charge: new UntypedFormControl(mod.onside_tag_upcharge),
          sub_charge: new UntypedFormControl(mod.sub_tag_upcharge),
        }))
      }).forEach(mi => rows.push(mi))

      rows.push(new DataRow("add", { "name": "Add modifier" }, new UntypedFormGroup({
        modifier_group: new UntypedFormControl(data.id, Validators.required),
        name: new UntypedFormControl('', Validators.required),
        upcharge: new UntypedFormControl('', Validators.required),
        food_cost: new UntypedFormControl(),
        reset: new UntypedFormControl(null),
        half_mod_upcharge: new UntypedFormControl(),
        is_available: new UntypedFormControl(true, Validators.required),
        has_quantity: new UntypedFormControl(false, Validators.required),
        fixed_price: new UntypedFormControl(false, Validators.required),
        has_half_mod: new UntypedFormControl(false, Validators.required),
        is_pos_only: new UntypedFormControl(false),
        joined_with: new UntypedFormControl(),
        max_mod_units: new UntypedFormControl({ value: null, disabled: true }),
      })))

      console.log(rows);

      this.tempDs.data = rows
      this.isGroupLoading = false;

      // this.ref.detectChanges();
    })
  }

  filterOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.modOptionsList.filter(option =>
      option.id.toString().includes(filterValue)
    );
  }

  getSelectedOptionLabel(joinedWithValue: any): string {
    const selectedOption = this.modOptionsList.find(option => option.id === joinedWithValue);
    return selectedOption ? selectedOption.id.toString() : 'Assign ID';
  }

  onOptionSelected(event: any): void {
    this.selectedJoinedWith = event.value;
  }

  isExpanded(parent, element) {

    let currentExpanded = this.expandedElement.get(parent)
    if (this.previous && this.previous != currentExpanded?.data) {
      this.isExpand = true;
    }
    if (currentExpanded && currentExpanded.data == element.data) {
      this.previous = currentExpanded.data;
      if (!this.isExpand) {
        return 'collapsed'
      } else {
        return 'expanded';
      }
    } else {
      return 'collapsed';
    }

  }

  expandRow(parent, element) {
    this.expandedElement = this.expandedElement.set(parent, element)
    this.isExpand = !this.isExpand;
  }


  updateModGroup(values) {
    console.log('values', values);
    this.menuService.updateModGroup(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.isExpand = false;
      this.ngOnInit()
    })
  }

  addModifier(values) {
    this.menuService.addModifier(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.snackBar.open('Item has been added', 'OK', { duration: 2000 });
      this.isExpand = false;
      this.ngOnInit()
    })
  }

  updateModifier(values) {
    this.menuService.updateModifier(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.isExpand = false;
      this.ngOnInit()
    })
  }

  deleteModGroup(element) {
    if (confirm("Are you sure to delete " + element.name + "?")) {
      this.menuService.deleteModGroup(this.store.id, JSON.stringify(element)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
        this.refresh.emit(true);
        this.ngOnInit();
      })
    }
  }

  deleteModifier(element) {
    if (confirm("Are you sure to delete " + element.name + "?")) {
      this.menuService.deleteModifier(this.store.id, JSON.stringify(element)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
        this.refresh.emit(true);
        this.ngOnInit();
      })
    }
  }

  swap(array, ci, ni) {
    [array[ci], array[ni]] = [array[ni], array[ci]]
  }

  moveModifierOptions(direction, value) {
    const currentIndex = this.modiferOrder.indexOf(value.id);
    switch (direction) {
      case 'top': {
        this.modiferOrder.unshift(this.modiferOrder.splice(currentIndex, 1)[0]);
        break;
      }
      case 'up': {
        const newIndex = currentIndex - 1;
        this.swap(this.modiferOrder, currentIndex, newIndex);
        break;
      }
      case 'down': {
        const newIndex = currentIndex + 1;
        this.swap(this.modiferOrder, currentIndex, newIndex);
        break;
      }
      case 'bottom': {
        this.modiferOrder.push(this.modiferOrder.splice(currentIndex, 1)[0]);
        break;
      }
    }
    this.menuService.updateOrder(this.store.id, JSON.stringify({
      'type': 'modifier_options',
      'order': this.modiferOrder
    })).subscribe(data => {
      this.getDataSource(this.store.id, this.modifierGroup, '');
    })
  }

  enableMaxMod(event, formGroup) {
    console.log(formGroup);

    if (event.checked) {
      formGroup.controls.max_mod_units.setValue(1);
      formGroup.controls.max_mod_units.enable()
    } else {
      formGroup.controls.max_mod_units.setValue(null);
      formGroup.controls.max_mod_units.disable()
    }
  }

  createModifierCopy(modId) {
    this.menuService.createModifierCopy(this.store.id, modId).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.snackBar.open('Item copied successfully!', 'OK', { duration: 2000 });
      // this.ngOnInit();
      this.refresh.emit(true);
    });
  }

  ngOnDestroy(): void {
    // Cleanup the subscriptions
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.isGroupLoading = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    let a = event.container.data;
    let dragMod = [];
    a.map((mod: any) => {
      if (mod?.data?.modifier_group) {
        dragMod.push(mod.data.id);
      }
    });
    this.menuService.updateOrder(this.store.id, JSON.stringify({
      'type': 'modifier_options',
      'order': dragMod
    })).subscribe(data => {
      this.isGroupLoading = false;
      this.getDataSource(this.store.id, this.modifierGroup, '');
    })
  }

  createModCopy(modId) {
    this.menuService.createModCopy(this.store.id, modId).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.snackBar.open('Item copied successfully!', 'OK', { duration: 2000 });
      this.ngOnInit();
    });
  }

  onMouseEnter(): void {
    this.isIconHovered = true; // Enable dragging
  }

  onMouseLeave(): void {
    this.isIconHovered = false; // Disabled dragging
  }
}
