<div class="p-4">
  <div fxLayout="row" class="mobile-ocntent">
    <div style="overflow: hidden;" class="mobile-view-slip">
      <app-time-acceptance-order-iframe></app-time-acceptance-order-iframe>
    </div>
    <div fxLayout="column" class="all-content">
      <div>
        <h1 mat-dialog-title class="!pl-0">Would you like to accept this order?</h1>
        <p class="message p-tag">Please select estimated pick up time:</p>
        <div style="overflow: hidden;height: 270px;margin-bottom: 6px;width: 98%;" class="tab-lap-view">
          <app-time-acceptance-order-iframe></app-time-acceptance-order-iframe>
        </div>
        <div class="custom-time">
          <form [formGroup]="customTime">
            <div class="time-field">
              <input type="number" matInput formControlName="time" placeholder="Custom time in minutes"
                (input)="valueTyped($event.target.value)">
            </div>
          </form>
        </div>
        <div class="grid-minutes outer-padding">
          <div *ngFor="let min of data">
            <div class="minute-option" (click)="acceptOrder(min.value1)"
              [ngClass]="{ 'bg-selected': time === min.value1}">
              <p class="size-minute">{{min.value1}}</p>
            </div>
          </div>
        </div>
      </div>


      <mat-dialog-actions class="align-buttons mobile-view-slip">
        <button mat-button mat-flat-button color="warn" class="decline-btn" (click)="declineOrder(0)">Decline</button>
        <button mat-button mat-flat-button color="primary" (click)="submitTime()"
          [disabled]="!this.orderTime && !this.typeTime">Accept</button>
      </mat-dialog-actions>

      <mat-dialog-actions class="align-buttons tab-lap-view">
        <button mat-button mat-flat-button color="primary" (click)="submitTime()"
          [disabled]="!this.orderTime && !this.typeTime" class="accept-btn">Accept</button>
        <button mat-button mat-flat-button color="warn" class="decline-btn" (click)="declineOrder(0)">Decline</button>
      </mat-dialog-actions>
    </div>
  </div>

</div>