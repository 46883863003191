import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UserService} from '../../_services/user.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  msg: string;
  userObject: any;
  currentRecordId: any;
  action: any;
  date: Date;
  payload: any;
  numberOfBreaks: any;
  store_id: any;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.userObject = data['userObject'];
      this.currentRecordId = data['pk'];
      this.action = data['action'];
      this.numberOfBreaks = data['numberOfBreaks'];
      this.store_id = data['store_id']
    }


  ngOnInit() {
    this.date = new Date();
    let tZ = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(this.action=="clock-in") {
      this.msg = "Clock in " + this.userObject['first_name'] + " " + this.userObject['last_name'] + " at " + this.formatDate(this.date) + "?";
      this.payload = {
        'pk': this.currentRecordId,
        'user': this.userObject['id'],
        'clock_in_time': this.date,
        'store_id': this.store_id
      }
    } else if(this.action=="START BREAK") {
      this.msg = "Start Break for " + this.userObject['first_name'] + " " + this.userObject['last_name'] + " at " + this.formatDate(this.date) + "?";
      this.numberOfBreaks++;
      this.payload = {
        'pk': this.currentRecordId,
        'user': this.userObject['id'],
        'start_break_time': this.date,
        'number_of_breaks': this.numberOfBreaks,
        'store_id': this.store_id
      }
    } else if(this.action=="END BREAK") {
      this.msg = "End Break for " + this.userObject['first_name'] + " " + this.userObject['last_name'] + " at " + this.formatDate(this.date) + "?";
      this.payload = {
        'pk': this.currentRecordId,
        'user': this.userObject['id'],
        'end_break_time': this.date,
        'store_id': this.store_id
      }
    } else if(this.action=="clock-out") {
      this.msg = "Clock Out " + this.userObject['first_name'] + " " + this.userObject['last_name'] + " at " + this.formatDate(this.date) + "?";
      this.payload = {
        'pk': this.currentRecordId,
        'user': this.userObject['id'],
        'clock_out_time': this.date,
        'store_id': this.store_id,
        'cashier_id': this.userObject['first_name'] + " " + this.userObject['last_name'],
      }
    }
  }

  punchTime() {
    this.userService.updateUserTimeClock(this.payload).subscribe(data => {
      if(data.Open_order){
        this.dialog.closeAll();
        this.alertPopup('ATTENTION: There are OPEN ORDERS that require payment and closing out tied to your PIN. Please go to OPEN ORDERS and close out your transactions before clocking out.');
      } else {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.delay(500).then(any => {
          this.dialog.closeAll();
          this.alertPopup('Time Clock has been punched successfully.');
          // alert('Time Clock has been punched successfully.');
        });
      }
    },
    error => {
      this.alertPopup('Error while punching the time clock.');
      // alert('Error while punching the time clock.');
    })
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(""), ms)).then(()=>console.log(""));
  }

  formatDate(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


  alertPopup(msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
