import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { CallerIdService } from '../_services/caller-id.service';
import { StoreService } from '../_services/store.service';
import { Store } from '../_models/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-card',
  templateUrl: './call-card.component.html',
  styleUrls: ['./call-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class CallCardComponent {

  /* DUMMY DATA */
  // incomingCallArray: any[] = [
  //   {
  //     phone_number: '+17813167627',
  //     callId: "c-racbchfdl5s1",
  //     customer_name: "Mitush",
  //     is_existing_customer: true,
  //     station: 'STATION 1'
  //   }
  // ];

  incomingCallArray: any[] = [];
  private destroySubject: Subject<void> = new Subject();
  storeSub: Subscription;
  store: Store;
  terminalname: string;

  constructor(
    private callerIdService: CallerIdService,
    private storeService: StoreService,
    private router: Router
  ) { }

  ngOnInit() {

    this.storeSub = this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });
    this.callerIdService.callSet.pipe(takeUntil(this.destroySubject)).subscribe((result: any) => {
      if (result.length != 0) {
        this.terminalname = localStorage.getItem('selectedTerminalName');
        if (this.store) {
          console.log('Store', this.store.feature_flag['HYBRID_CALLER_ID'], this.terminalname, result.station);
          
          if (this.store.feature_flag['HYBRID_CALLER_ID'] && this.terminalname === result.station) {
            console.log('Hybrid caller ID True');
            
            const existingCall = this.incomingCallArray.find((n) => n.call_id === result.call_id);
            if (!existingCall) {
              this.incomingCallArray.unshift(result);
            }
          } else if (!this.store.feature_flag['HYBRID_CALLER_ID']) {
            console.log('Hybrid caller ID False');
            
            const existingCall = this.incomingCallArray.find((n) => n.call_id === result.call_id);
            if (!existingCall) {
              this.incomingCallArray.unshift(result);
            }
          }
        }
        
      }
    });


  }


  handleAccept(call: any): void {
    this.incomingCallStatusUpdate(call, 'Answered');
  }

  handleIgnore(call: any): void {
    this.incomingCallStatusUpdate(call, 'Missed');
  }

  incomingCallStatusUpdate(call: any, status: string): void {
    const payload = {
      callId: call.call_id ,
      eventType: status
    }
    this.callerIdService.updateCallStatus(payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.incomingCallArray.splice(this.incomingCallArray.findIndex((n) => n.call_id === call.callId), 1);
      if (status === 'Answered') {
        localStorage.setItem('order_PN', call.phone_number);
        localStorage.setItem('order_name', call.customer_name || 'No Name');
        if (window.location.hash === '#/home/orders') {
          console.log('Condition passed', );
          this.callerIdService.acceptIgnreCall.next(true);
          this.callerIdService.setPhoneNumbertoDelivery.next(call.phone_number.slice(2));
        } else {
          this.callerIdService.setPhoneNumbertoDelivery.next(call.phone_number.slice(2));
          this.router.navigate(['/home/orders']);
        }
        
      }

    });
  }
}
