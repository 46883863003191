import {FormBase} from './form-base';

export class FormKeyTextBox extends FormBase<string> {
  controlType = 'key-textbox';
  // type: string;

  spellcheck = false
  constructor(options: {} = {}) {
    super(options);
    // this.type = options['type'] || this.controlType;
  }
}
