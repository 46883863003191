import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-column-management-dialog',
  templateUrl: './column-management-dialog.component.html',
  styleUrls: ['./column-management-dialog.component.scss']
})
export class ColumnManagementDialogComponent {
  isLoading = false;
  storeId: any;
  addTVScreenForm: UntypedFormGroup;
  editTVScreenForm: UntypedFormGroup;
  submitted: boolean;
  screenList: any;
  screenData: any;
  storeType: any;
  keyDisplayScreenCheck: boolean = false;
  tvId: string;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<ColumnManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("dsatdata", data);
  }
  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.storeType = localStorage.getItem("store_type");
    this.getDisplayScreen();
    if (this.data.view == 'add') {
      if (this.storeType == "Leaflogix") {
        this.addTVScreenForm = this.fb.group({
          tv_id: ["", Validators.required],
          store_id: [this.storeId],
          screen_duration: ["", Validators.required],
          screens: ["", Validators.required],
          hide_key_display: [""],
        })
      }
      else {
        this.addTVScreenForm = this.fb.group({
          store_id: [this.storeId],
          screen_duration: ["", Validators.required],
          screens: ["", Validators.required],
          hide_key_display: [""],
          screen_view_type: ["", Validators.required],
          columns_per_screen: ["", Validators.required],
        })
      }
    }
    else if (this.data.view == 'edit') {

      if (this.storeType == "Leaflogix") {
        this.editTVScreenForm = this.fb.group({
          tv_id: [this.data.data.tv_id],
          store_id: [this.storeId],
          screen_duration: [this.data.data.screen_duration, Validators.required],
          screens: [this.data.data.screens, Validators.required],
          hide_key_display: [this.data.data.hide_key_display],
        })
      }
      else {
        this.editTVScreenForm = this.fb.group({
          tv_id: [this.tvId],
          store_id: [this.storeId],
          screen_duration: [this.data.data.screen_duration, Validators.required],
          screens: [this.data.data.screens, Validators.required],
          hide_key_display: [this.data.data.hide_key_display],
          screen_view_type: [this.data.data.screen_view_type, Validators.required],
          columns_per_screen: [this.data.data.columns_per_screen, Validators.required],
        })
      }

    }
  }
  keyDisplayCheck(a) {
    this.keyDisplayScreenCheck = a.checked;
  }

  getDisplayScreen() {
    this.digitalDisplayService.getDisplayScreen(this.storeId).subscribe((data) => {
      this.screenData = data.data;
    })
  }
  addTVFormSubmit() {
    this.isLoading = true;
    if (this.addTVScreenForm.status == "VALID") {
      this.addTVScreenForm.value.hide_key_display = this.keyDisplayScreenCheck
      this.digitalDisplayService
        .addTVScreen(this.addTVScreenForm.value)
        .subscribe((data: any) => {
          const resData = { yesSubmit: "YesSubmit" };
          this.isLoading = false;
          this.dialogRef.close(resData);
        });
    }
  }
  editTVScreenFormSubmit() {
    this.isLoading = true;
    if (this.editTVScreenForm.status == "VALID") {
      this.editTVScreenForm.value.hide_key_display = this.keyDisplayScreenCheck
      this.digitalDisplayService
        .updateTVScreen(this.data.data.id, this.editTVScreenForm.value)
        .subscribe((data: any) => {
          const resData = { yesSubmit: "YesSubmit" };
          this.isLoading = false;
          this.dialogRef.close(resData);
        });
    }
  }
  deleteTVScreen() {
    this.isLoading = true;
    this.digitalDisplayService
      .deleteTVScreen(this.data.id)
      .subscribe((data: any) => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      });
  }
}
