<input
  type="file"
  #file
  style="display: none"
  (change)="onFilesAdded()" [multiple]="multiple"/>

<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
  <div [ngSwitch]="_for">
      <h1 *ngSwitchCase="'voidedCheck'" mat-dialog-title>Upload Voided Check</h1>
      <h1 *ngSwitchCase="'menu'" mat-dialog-title>Upload Menu</h1>
  </div>

 

  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content fxFlex>
    <div>
      <button
        [disabled]="uploading || uploadSuccessful"
        mat-raised-button
        color="primary"
        class="add-files-btn apply-btn-colr"
        (click)="addFiles()">
        {{files.size > 0 ? 'Add Another': 'Select File'}}
      </button>
    </div>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{file.name}}</h4>
        <mat-progress-bar
          *ngIf="progress"
          mode="determinate"
          [value]="progress[file.name].progress | async"
        ></mat-progress-bar>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <button *ngIf="showCancelButton" mat-button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="apply-btn-colr"
      [disabled]="!canBeClosed"
      (click)="closeDialog()"
    >
      {{primaryButtonText}}
    </button>
  </mat-dialog-actions>
</div>
