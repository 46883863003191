<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<mat-accordion>
  <ng-container *ngFor="let mgid of orderedMgIds">
      <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
                {{menuGroupsOrdered[mgid].name}}
                <span *ngIf="menuGroupsOrdered[mgid].subgroup">&nbsp;-&nbsp;{{menuGroupsOrdered[mgid].subgroup}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <app-mod-menu-item
            [menuGroup]="menuGroupsOrdered[mgid]"
            [store]="store"
            ></app-mod-menu-item>
          </ng-template>
        </mat-expansion-panel>
  </ng-container>
</mat-accordion>
