import {Component, Inject, OnInit} from '@angular/core';
import {Appearance, Location} from '@angular-material-extensions/google-maps-autocomplete';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';

@Component({
  selector: 'app-business-location',
  templateUrl: './business-location.component.html',
  styleUrls: ['./business-location.component.scss']
})
export class BusinessLocationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService:StoreService,
    public dialogRef: MatDialogRef<BusinessLocationComponent>) {
    this.store = data.store;
   }


  store:Store;
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress;
  public payload = null;


  formFields;

  ngOnInit() {}

  onAddressSelected(result) {
    console.log('onAddressSelected: ', result);
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onAutocompleteSelected(result) {
    console.log('onAutocompleteSelected: ', result);
    let parts = {}
    result.address_components.forEach(p => {
      parts[p['types'][0]] = p['short_name']
    });

    this.payload = {
      "address": result.formatted_address,
      "city": parts['locality'],
      "state_geographic": parts['administrative_area_level_1'],
      "zip_code": parts['postal_code'],
    }
  }

  submit(){
    this.storeService.updateStore(this.store, JSON.stringify(this.payload)).subscribe(data => {
      this.dialogRef.close()
    })
  }


}
