<span *ngIf="checkCategorie">
  <div *ngIf="storeType == 'Restaurant'" class="category-button">
    <!-- <div *ngIf="checkCategorie.master_category == 0 && checkCategorie.category == 0" class="category-button"> -->
    <button mat-stroked-button class="master action-btn-colr" (click)="addMasterCategoryDialog()">Add Master
      Category</button>
  </div>
  <div *ngIf="storeType == 'Leaflogix'" class="category-button">
    <!-- <div *ngIf="checkCategorie.master_category == 1 && checkCategorie.category == 1" class="category-button"> -->
    <button mat-stroked-button class="master action-btn-colr" (click)="addCategoryDialog(0)"
      style="margin-right: 10px;"> Add Master
      Category</button>
    <button mat-stroked-button class="master action-btn-colr" (click)="addCategoryDialog(1)"> Add Category</button>
  </div>
</span>
<span *ngIf="storeType == 'Restaurant'" style="display: flex; float: right;">
  <!-- <span *ngIf="storeId != '1'" style="display: flex; float: right;"> -->
  <div class="category-button">
    <button mat-stroked-button class="master action-btn-colr" (click)="allStoreMasterCategoryDialog('allStore')"
      style="margin-right: 10px;">
      Add Master Category</button>
  </div>
  <!-- <div class="category-button">
    <button mat-stroked-button class="master" (click)="menuSubCategory()">Add Sub Category</button>
  </div> -->
</span>

<div *ngIf="storeType == 'Leaflogix'">
  <mat-accordion *ngFor="let first of allCategorys;let i = index">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title class="category-name"> {{first.name}} </mat-panel-title>
        <mat-panel-description class="category-description">
          <div class="w-50"> Master Category </div>
          <div class="category-action w-50">
            <div (click)="editMasterCategory(first)"> Edit </div>
            <div (click)="deleteMasterCategory(first.id)"> Delete </div>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-expansion-panel *ngFor="let second of first.categorys" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="category-name" style="width: 28%;"> {{second.name}} </mat-panel-title>
          <mat-panel-description class="category-description">
            <div class="w-50"> Category </div>
            <div class="category-action w-50">
              <div (click)="editCategoryDialog(second)" class="xs:!-right-8 xs:!mr-7"
                style="position:relative; right: -30px;"> Edit </div>
              <div (click)="deleteCategory(second.id)" class="xs:!left-1" style="position: relative; left: 32px;">
                Delete </div>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngFor="let third of second.subcategorys; let i=index">
          <table mat-table [dataSource]="third" width="100%" class="xs:!table-fixed">
            <ng-container matColumnDef="api_category_name">
              <th mat-header-cell *matHeaderCellDef class=""> Sub Category Name </th>
              <td mat-cell *matCellDef="let element"> {{element.display_name}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Hide Category </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hide_category == true">True</span>
                <span *ngIf="element.hide_category == false">False</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="screen">
              <th mat-header-cell *matHeaderCellDef> New Screen </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.new_screen == true">True</span>
                <span *ngIf="element.new_screen == false">False</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="priority">
              <th mat-header-cell *matHeaderCellDef> Priority </th>
              <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
            </ng-container>

            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef> Color </th>
              <td mat-cell *matCellDef="let element">
                <span class="circle" [style.background-color]="element.font_color"></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen1">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #menuScreen1="matMenu">
                  <button mat-menu-item (click)="editSubCategoryDialog(element)">
                    <span class="material-icons-outlined edit-delete-icon">
                      edit
                    </span>&nbsp;
                    <span>Edit</span>
                  </button>
                  <!-- <button mat-menu-item (click)="deleteScreens()">
                    <span class="material-icons edit-delete-icon">
                      delete_outline
                    </span>&nbsp;
                    <span>Delete</span>
                </button> -->
                </mat-menu>
              </td>
            </ng-container>
            <span *ngIf="i==0">
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            </span>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 33.33%"></tr>
          </table>

        </div>
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<!-- <div *ngIf="storeId == '5'">
  <mat-accordion>
    <ng-container *ngFor="let menuGroup of menuGroupsOrdered">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{menuGroup.name}}
                <span *ngIf="menuGroup.subgroup">&nbsp;-&nbsp;{{menuGroup.subgroup}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <app-menu-item-group #m
                [menuGroup]="menuGroup"
                [store]="store"
                (menuGroupUpdate)="setMenuGroup($event)"
                (menuGroupOrderUpdate)="updateOrder($event)"
                (refresh)="menuGroupReset()"
              ></app-menu-item-group>
            </ng-template>
          </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div> -->



<div *ngIf="storeType == 'Restaurant'" style="clear: both;" cdkDropList (cdkDropListDropped)="drop($event)">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <mat-accordion *ngFor="let first of allCategorys;let i = index" cdkDrag>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title class="category-name">
          {{first.name}}{{first.subgroup ? ' - ' + first.subgroup : ''}}
        </mat-panel-title>
        <mat-panel-description class="category-description">
          <div class="w-50"> Master Category </div>
          <div class="category-action w-50">
            <div (click)="allStoreEditMasterCategory(first)"> Edit </div>
            <div (click)="deleteMasterCategory(first.id)"> Delete </div>
            <div (click)="copyMasterCategory(first.id)"> Copy </div>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-expansion-panel *ngFor="let second of first.modifiergroup" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="category-name" style="width: 28%;">
            {{second.name}}{{second.subgroup ? ' - ' + second.subgroup : ''}}
          </mat-panel-title>
          <mat-panel-description class="category-description">
            <div class="w-50"> Modifier Group </div>
            <!-- <div class="category-action w-50">
            <div (click)="editmenuCategoryDialog(second)" style="position:relative; right: -33px;"> Edit </div>
            <div (click)="deleteScreens(second.id)" style="position: relative; left: 32px;"> Delete </div>
          </div> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- <div *ngFor="let third of second.subcategorys; let i=index;"> -->
        <table mat-table [dataSource]="second.submodifiers" width="100%">
          <ng-container matColumnDef="api_category_name">
            <th mat-header-cell *matHeaderCellDef> Modifier Items Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Hide Category </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span *ngIf="element.hide_category == true">True</span>
              <span *ngIf="element.hide_category == false">False</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="screen">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> New Screen </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span *ngIf="element.new_screen == true">True</span>
              <span *ngIf="element.new_screen == false">False</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef> Base Price </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.upcharge > 0 ">
                <!-- ${{element.upcharge}} -->
                ${{ element.upcharge | number: '1.2-2' }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Color </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span class="circle" [style.background-color]="element.font_color"></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen1">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menuScreen1="matMenu">
                <button mat-menu-item (click)="editSubCategoryDialog(element)">
                  <span class="material-icons-outlined edit-delete-icon">
                    edit
                  </span>&nbsp;
                  <span>Edit</span>
                </button>
                <!-- <button mat-menu-item (click)="deleteScreens()">
                  <span class="material-icons edit-delete-icon">
                    delete_outline
                  </span>&nbsp;
                  <span>Delete</span>
              </button> -->
              </mat-menu>
            </td>
          </ng-container>
          <!-- <span *ngIf="i==0"> -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <!-- </span> -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 33.33%"></tr>
        </table>

        <!-- </div> -->
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let second of first.categorys" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="category-name" style="width: 28%;">
            {{second.name}}{{second.subgroup ? ' - ' + second.subgroup : ''}}
          </mat-panel-title>
          <mat-panel-description class="category-description">
            <div class="w-50"> Sub Category </div>
            <!-- <div class="category-action w-50">
              <div (click)="editmenuCategoryDialog(second)" style="position:relative; right: -33px;"> Edit </div>
              <div (click)="deleteScreens(second.id)" style="position: relative; left: 32px;"> Delete </div>
            </div> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- <div *ngFor="let third of second.subcategorys; let i=index;"> -->
        <table mat-table [dataSource]="second.subcategorys" width="100%">
          <ng-container matColumnDef="api_category_name">
            <th mat-header-cell *matHeaderCellDef> Menu Items Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} {{element.description ? ' - ' + element.description : ''}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Hide Category </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span *ngIf="element.hide_category == true">True</span>
              <span *ngIf="element.hide_category == false">False</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="screen">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> New Screen </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span *ngIf="element.new_screen == true">True</span>
              <span *ngIf="element.new_screen == false">False</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef> Base Price </th>
            <td mat-cell *matCellDef="let element"> {{element.starting_price}} </td>
          </ng-container>

          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Color </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span class="circle" [style.background-color]="element.font_color"></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen1">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menuScreen1="matMenu">
                <button mat-menu-item (click)="editSubCategoryDialog(element)">
                  <span class="material-icons-outlined edit-delete-icon">
                    edit
                  </span>&nbsp;
                  <span>Edit</span>
                </button>
                <!-- <button mat-menu-item (click)="deleteScreens()">
                    <span class="material-icons edit-delete-icon">
                      delete_outline
                    </span>&nbsp;
                    <span>Delete</span>
                </button> -->
              </mat-menu>
            </td>
          </ng-container>
          <!-- <span *ngIf="i==0"> -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <!-- </span> -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 33.33%"></tr>
        </table>

        <!-- </div> -->
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div>





<!-- <form>
  <div fxLayout="row">
    <div style="margin-right: 31px;">
      <mat-form-field appearance="fill">
        <mat-label>API Category Name</mat-label>
        <mat-select [(ngModel)]="selectedValue" name="food">
          <mat-option *ngFor="let food of foods" [value]="food.value">
            {{food.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="padding: 14px 10px 10px 12px;">
      <mat-form-field class="example-full-width">
        <mat-label>Display name</mat-label>
        <input matInput placeholder="Ex. Pizza" />
      </mat-form-field>
    </div>
    <div style="margin-right: 31px;">
      <mat-form-field appearance="fill">
        <mat-label>Flag</mat-label>
        <mat-select [(ngModel)]="selectedValue" name="food">
          <mat-option *ngFor="let food of foods" [value]="food.value">
            {{food.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="padding: 14px 10px 10px 12px;">
      <mat-form-field class="example-full-width">
        <mat-label>Priority</mat-label>
        <input matInput placeholder="Ex. Pizza" />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row">
    <div style="margin-right: 31px;">
      <mat-form-field appearance="fill">
        <mat-label>Master Category</mat-label>
        <mat-select [(ngModel)]="selectedValue" name="food">
          <mat-option *ngFor="let food of foods" [value]="food.value">
            {{food.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button color="primary" class="button-d-d">
      Add New
    </button>
  </div>
</form> -->
<!-- <div style="float: right;">
  <button class="cancel-button">Cancel</button>
  <button class="cancel-button" style="background-color:blue ; color: white;">Save</button>
</div>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
</mat-paginator> -->