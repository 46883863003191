import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from 'src/app/_models/store';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { MenuCategoryAvailabilityDialogComponent } from '../menu-category-availability-dialog/menu-category-availability-dialog.component';

@Component({
  selector: 'app-menu-category-availability',
  templateUrl: './menu-category-availability.component.html',
  styleUrls: ['./menu-category-availability.component.scss']
})
export class MenuCategoryAvailabilityComponent implements OnInit {

  availability = {
    'ALL_DAY_EVERYDAY': 'All day, every day',
    'NOT_AVAILABLE': 'Not available', 'CUSTOM': 'Custom'
  }

  categoryAvailability = 'ALL_DAY_EVERYDAY';

  @Input() menuGroupName;
  @Input() store: Store;
  @Input() subCatName;
  store_id: any;

  menuCatAvailability;
  storeId: any;

  constructor(
    public dialog: MatDialog,
    private menuService: MenuServiceV2
  ) { }


  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    if (this.store) {
      this.menuService.getMenuCategoryAvailability((this.store ? this.store.id : this.storeId), (this.subCatName ? (this.menuGroupName+' - '+this.subCatName) : this.menuGroupName)).subscribe(data => {
        this.menuCatAvailability = data;
        this.categoryAvailability = data['availability_type']
      })
    }
  }

  setAvailability(value) {
    this.categoryAvailability = value
    let payload = {
      store: this.store.id,
      menu_category_name: this.subCatName ? (this.menuGroupName+' - '+this.subCatName) : this.menuGroupName,
      availability_type: value
    }
    this.menuService.updateMenuCategoryAvailability(payload).subscribe(data => {
      this.ngOnInit();
    })
  }

  availabilityDialog() {
    this.categoryAvailability = 'CUSTOM'
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(MenuCategoryAvailabilityDialogComponent,
      {
        width: isMobile ? '100vw' : '600px',
        height: isMobile ? '100vh' : '',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {
          menuGroupName: this.subCatName ? (this.menuGroupName+' - '+this.subCatName) : this.menuGroupName,
          store: this.store,
          availabilityType: this.categoryAvailability,
          availabilitySetting: this.menuCatAvailability,
          from_where: 'from_menu'
        }
      })

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    })
  }
}
