<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="flex flex-col">
  <div class="add-time-btn">
    <button mat-flat-button color="primary" (click)="addTimeClockPopup()" class="clear apply-btn-colr">Add Time</button>
  </div>

  <div class="status1-container full-y-width  xs:!overflow-x-scroll  xs:m-auto mat-mds">
    <table mat-table width="100%" matSort matSortActive="status_counter" matSortDirection="desc" matSortDisableClear
      [dataSource]="dataSource">
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element" fxHide.lt-sm>
          <button mat-flat-button class="ready" [ngStyle]="{'background-color':getColor(element.status) }">
            {{element.status}}
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="team-member">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Team Member </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.first_name}} {{ element.last_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.time_clock.clock_in_time | date: 'MM/dd/yyyy' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clocked_in_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Clocked-In </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ formatDate(element.time_clock.clock_in_time)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clocked_out_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Clocked-Out </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ formatDate(element.time_clock.clock_out_time)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="number_of_breaks">
        <mat-header-cell *matHeaderCellDef mat-sort-header> # of Breaks </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.time_clock.number_of_breaks}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="break_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Break Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.time_clock.break_time }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Time (Hrs)</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.total_time }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_cost">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Cost </mat-header-cell>
        <mat-cell *matCellDef="let element"> ${{ element.total_cost }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="action1"></mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="action1">
          <button mat-icon-button [matMenuTriggerFor]="actionMenu" class="action1">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="editTime(element)"
              [disabled]="!permission?.hasPermission('CLOCK', 'EDIT')">Edit Time</button>
            <button mat-menu-item (click)="editBreakTime(element, 0)"
              [disabled]="!permission?.hasPermission('CLOCK', 'EDIT')">Edit Break Time</button>
            <button mat-menu-item (click)="deleteTimeClockSession(element)"
              [disabled]="!permission?.hasPermission('CLOCK', 'EDIT')">Delete</button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{ 'highlight-order-row': row === selectedRow }" (click)="onRowClicked(row)"></mat-row>
    </table>
  </div>
  <div class="status1-container full-y-width">
    <mat-paginator [length]="filteredDataSource.getTotal()" [pageSize]="10"
      [pageSizeOptions]="[10,20,50]"></mat-paginator>
  </div>

  <form class="status-table-filters" [hidden]="!permission?.hasPermission('CLOCK', 'EDIT')">
    <div class="flex xs:flex-wrap items-start">
      <mat-form-field class="search filt-margin xs:!ml-0 sm:!ml-0">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Filter</mat-label>
        <input [(ngModel)]="search" name="search" matInput placeholder="Filter" #input>
      </mat-form-field>

      <mat-form-field class="start filt-margin xs:!ml-0 sm:!ml-0">
        <mat-label>Start Date</mat-label>
        <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker"
          placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="end filt-margin xs:!ml-0 sm:!ml-0">
        <mat-label>End Date</mat-label>
        <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker"
          placeholder="Choose an end date" [min]="startDate">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="btn-reset-search">
      <button mat-flat-button class="clear xs:!mb-5 sm:!mb-5 action-btn-colr" type="submit"
        (click)="searchTimeClock($event)" color="primary">SEARCH</button>
      <button mat-flat-button class="clear xs:!mb-5 sm:!mb-5 action-btn-colr" type="reset" (click)="reset()"
        color="primary">CLEAR</button>
      <button mat-flat-button (click)="exporter.exportTable('xlsx', {fileName: 'employee_data'})" color="primary"
        class="clear xs:!mb-5 sm:!mb-5 action-btn-colr">EXPORT</button>
      <div class="clear"></div>
    </div>
  </form>

  <div class="status1-container full-y-width  xs:!overflow-x-scroll  xs:m-auto"
    [hidden]="!permission?.hasPermission('CLOCK', 'EDIT')">
    <table mat-table matTableExporter width="100%" matSort matSortActive="status_counter" matSortDirection="desc"
      matSortDisableClear [dataSource]="filteredDataSource" #exporter="matTableExporter">
      <ng-container matColumnDef="team-member">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Team Member </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.first_name}} {{ element.last_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="number_of_days">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total number of Days </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.number_of_days }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_breaks">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total # of Breaks </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.total_breaks }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_break_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Break Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.total_break_time }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="filtered_total_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Time (Hrs)</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.filtered_total_time }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="filtered_total_cost">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Cost </mat-header-cell>
        <mat-cell *matCellDef="let element"> ${{ element.filtered_total_cost }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedFilterColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedFilterColumns;"
        [ngClass]="{ 'highlight-order-row': row === selectedRow }" (click)="onRowClicked(row)"></mat-row>
    </table>
  </div>
  <div class="status1-container full-y-width">
    <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
  </div>
  <div class="status2-container full-y-width  xs:!overflow-x-scroll  xs:m-auto"
    [hidden]="!permission?.hasPermission('CLOCK', 'EDIT')">
    <table mat-table width="100%" matSort matSortActive="status_counter" matSortDirection="desc" matSortDisableClear
      [dataSource]="allDataSource">
      <ng-container matColumnDef="team-member">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Team Member </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.team_member }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.clock_in_time | date: 'MM/dd/yyyy' }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="clocked_in_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Clocked-In </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ formatDate(element.clock_in_time)}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="clocked_out_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Clocked-Out </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.force_checkout" class="force-clock-out"
            matTooltip="This user forgot to punch out manually and was automatically clocked out 60 minutes after closing.">
            {{ formatDate(element.clock_out_time)}}
          </span>
          <span *ngIf="!element.force_checkout">
            {{ formatDate(element.clock_out_time)}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="number_of_breaks">
        <mat-header-cell *matHeaderCellDef mat-sort-header> # of Breaks </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.number_of_breaks}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="break_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Break Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.break_time }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="total_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Time (Hrs)</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.total_time }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="total_cost">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Cost </mat-header-cell>
        <mat-cell *matCellDef="let element"> ${{ element.total_cost }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <button mat-icon-button [matMenuTriggerFor]="actionMenu">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="editTimeClock(element)">Edit Time</button>
            <button mat-menu-item (click)="editBreakTime(element, 1)"
              [disabled]="!permission?.hasPermission('CLOCK', 'EDIT')">Edit Break Time</button>
            <button mat-menu-item (click)="deleteTimeClock(element)">Delete</button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedAllColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedAllColumns;"
        [ngClass]="{ 'highlight-order-row': row === selectedRow }" (click)="onRowClicked(row)"></mat-row>
    </table>
  </div>
  <div class="status1-container full-y-width">
    <mat-paginator [length]="allDataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"
      (page)="yourHandler($event)"></mat-paginator>
  </div>
</div>