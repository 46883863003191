<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<body>
    <button mat-flat-button (click)="goBack()" class="back-btn xs:!ml-[-3px]  xs:!justify-start top-[2px]">
      <mat-icon class="xs:!ml-[-18px] xs:!text-base xs:!h-[23px] back-icon">arrow_back_ios_new</mat-icon>
      Back
    </button>
    <div class="tables-div" *ngIf="dataAvailable">
        <div>
            <table cellpadding="5px" cellspacing="1" style="width: 100%;">
                <tr style="height: 80px;">
                    <td colspan="17">
                        <img width="50px"
                            src="https://storage.cloud.google.com/menus/static/frontend/img/lab-report-logo.png">
                        <span class="company"><span class="blue">Labrador</span> &nbsp;<span
                                class="grey">AI</span></span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="title revert">Monthly Statement</td>
                </tr>
                <tr>
                    <td colspan="2">Statement Period</td>
                    <td colspan="3" class="border-right">{{data.start_date}} - {{data.end_date}}</td>
                </tr>
                <tr>
                    <td colspan="6">{{data.store.store_name}}</td>
                    <td colspan="4"></td>
                </tr>
                <tr>
                    <td colspan="8">{{data.store.contact_name}}</td>
                    <td colspan="9"></td>
                </tr>
                <tr>
                    <td colspan="8">{{data.store.address}}</td>
                    <td colspan="9"></td>
                </tr>
                <tr>
                    <td colspan="17">&nbsp;</td>
                </tr>
            </table>
        </div>
        <div style="display: flex;flex-direction:column">
            <div style="display:flex">
                <div fxFlex="25% 25%">
                    <table cellpadding="5px" cellspacing="1" style="width: 100%;">
                        <tr>
                            <td colspan="8" class="header">Monthly Period Report</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Gross Sales</td>
                            <td colspan="5">{{data.summary.total_gross_sales || 0 |currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Gross Sales POS</td>
                            <td colspan="5">{{data.summary.total_gross_sales_pos || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Gross Sales Online</td>
                            <td colspan="5">{{data.summary.total_gross_sales_online || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Discounts</td>
                            <td colspan="5">{{data.summary.total_discounts || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Discounts POS</td>
                            <td colspan="5">{{data.summary.total_discounts_pos || 0 | currency:'USD'
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Discounts Online</td>
                            <td colspan="5">{{data.summary.total_discounts_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Net Sales</td>
                            <td colspan="5">{{data.summary.total_net_sales || 0 |currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Net Sales POS</td>
                            <td colspan="5">{{data.summary.total_net_sales_pos || 0 | currency:'USD'
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Net Sales Online</td>
                            <td colspan="5">{{data.summary.total_net_sales_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Taxes</td>
                            <td colspan="5">{{data.summary.total_taxes || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Taxes POS</td>
                            <td colspan="5">{{data.summary.total_taxes_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Taxes Online</td>
                            <td colspan="5">{{data.summary.total_taxes_online || 0 | currency:'USD'
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Tips</td>
                            <td colspan="5">{{data.summary.total_tips || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Tips POS</td>
                            <td colspan="5">{{data.summary.total_tips_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Tips Online</td>
                            <td colspan="5">{{data.summary.total_tips_online || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Platform Charges</td>
                            <td colspan="5">{{data.summary.total_platform_charges || 0 |currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Platform Charges POS</td>
                            <td colspan="5">{{data.summary.total_platform_charges_pos || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Platform Charges Online</td>
                            <td colspan="5">{{data.summary.total_platform_charges_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Non Taxable Sales</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sales || 0 |currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sales POS</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sales_pos || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sales Online</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sales_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Non Taxable Sale Cash</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_cash || 0 |currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Cash POS</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_cash_pos || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Cash Online</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_cash_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Non Taxable Sale Card</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_card || 0 |currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Card POS</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_card_pos || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Card Online</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_card_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr class="border-top border-bottom">
                            <td colspan="3">Total Receipts</td>
                            <td colspan="5">{{data.summary.total_total_receipts || 0 | currency:'USD' }}</td>
                        </tr>

                        <tr>
                            <td colspan="17">&nbsp;</td>
                        </tr>
                    </table>
                </div>
                <div fxFlex="75%" style="margin-right: 5px">
                    <div class="daily-report-header">
                        Daily Period Report
                    </div>
                    <div class="daily-summary-table">
                        <table cellpadding="5px" cellspacing="1" style="width: 100%;">
                            <tr class="border-bottom">
                                <td>Order Date</td>
                                <td>Order Count</td>
                                <td>Average Order Value</td>
                                <td>Total Gross Sales</td>
                                <td>Total Gross Sales POS</td>
                                <td>Total Gross Sales Online</td>
                                <td>Total Non-Taxable Sales</td>
                                <td>Total Non-Taxable Sales Cash</td>
                                <td>Total Non-Taxable Sales Card</td>
                                <td>Total Discounts</td>
                                <td>Total Discounts POS</td>
                                <td>Total Discounts Online</td>
                                <td>Total Net Sales</td>
                                <td>Total Net Sales POS</td>
                                <td>Total Net Sales Online</td>
                                <td>Total Taxes</td>
                                <td>Total Taxes POS</td>
                                <td>Total Taxes Online</td>
                                <td>Total Tips</td>
                                <td>Total Tips POS</td>
                                <td>Total Tips Online</td>
                                <td>Total Delivery Fees</td>
                                <td>Total Delivery Fees POS</td>
                                <td>Total Delivery Fees Online</td>
                                <td>Total Platform Charges</td>
                                <td>Total Platform Charges POS</td>
                                <td>Total Platform Charges Online</td>
                                <td>Total Refund(Qty)</td>
                                <td>Total Refund($)</td>
                                <td class="border-right">Total Receipts</td>
                                <td class="border-right">Total Receipts POS</td>
                                <td class="border-right">Total Receipts Online</td>
                            </tr>
                            <tr *ngFor="let record of data.records" class="align-right">
                                <td>{{ record.order_date}}</td>
                                <td>{{ record.order_count}}</td>
                                <td>{{ record.average_order_value || 0 | currency:'USD'}}</td>
                                <td>{{ record.gross_sales || 0 |currency:'USD' }}</td>
                                <td>{{ record.gross_sales_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.gross_sales_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.non_taxable_sales || 0 |currency:'USD' }}</td>
                                <td>{{ record.non_taxable_sales_pos || 0 |currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sales_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.discounts || 0 |currency:'USD' }}</td>
                                <td>{{ record.discounts_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.discounts_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.net_sales || 0 |currency:'USD' }}</td>
                                <td>{{ record.net_sales_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.net_sales_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.taxes || 0 |currency:'USD' }}</td>
                                <td>{{ record.taxes_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.taxes_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.tips || 0 |currency:'USD' }}</td>
                                <td>{{ record.tips_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.tips_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.delivery_fees || 0 |currency:'USD' }}</td>
                                <td>{{ record.delivery_fees_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.delivery_fees_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.platform_charges || 0 |currency:'USD' }}</td>
                                <td>{{ record.platform_charges_pos || 0 |currency:'USD' }}</td>
                                <td>{{ record.platform_charges_online || 0 |currency:'USD' }}</td>
                                <td>{{ record.refund_quantity || 0 }}</td>
                                <td>{{ record.refund_total || 0 |currency:'USD' }}</td>
                                <td class="border-right">{{ record.total_receipts || 0 |currency:'USD' }}</td>
                                <td class="border-right">{{ record.total_receipts_pos || 0 |currency:'USD' }}</td>
                                <td class="border-right">{{ record.total_receipts_online || 0 |currency:'USD' }}</td>
                            </tr>
                            <tr class="align-right border-top">
                                <td>Total</td>
                                <td>{{ data.summary.total_order_count || 0 }}</td>
                                <td></td>
                                <td>{{ data.summary.total_gross_sales || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_gross_sales_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_gross_sales_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sales || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sales_pos || 0 |currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sales_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_discounts || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_discounts_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_discounts_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_sales || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_sales_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_sales_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_taxes || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_taxes_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_taxes_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_tips || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_tips_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_tips_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_delivery_fees || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_delivery_fees_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_delivery_fees_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_platform_charges || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_platform_charges_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_platform_charges_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_refund_quantity || 0 }}
                                </td>
                                <td>{{ data.summary.total_refund_total || 0 | currency:'USD'}}
                                </td>
                                <td class="border-right">{{ data.summary.total_total_receipts || 0 | currency:'USD' }}
                                </td>
                                <td class="border-right">{{ data.summary.total_total_receipts_pos || 0 | currency:'USD'
                                    }}
                                </td>
                                <td class="border-right">{{ data.summary.total_total_receipts_online || 0 |
                                    currency:'USD'
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div style="display:flex">
                <div fxFlex="25% 25%">
                    <table cellpadding="5px" cellspacing="1" style="width: 100%;">
                        <tr>
                            <td colspan="8" class="header">Monthly Payment Summary</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total cash</td>
                            <td colspan="5">{{data.summary.total_cash || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total cash POS</td>
                            <td colspan="5">{{data.summary.total_cash_pos || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_pos_cash_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total cash Online</td>
                            <td colspan="5">{{data.summary.total_cash_online || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_online_cash_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total card</td>
                            <td colspan="5">{{data.summary.total_card || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total card POS</td>
                            <td colspan="5">{{data.summary.total_card_pos || 0 | currency:'USD'
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_pos_card_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total card Online</td>
                            <td colspan="5">{{data.summary.total_card_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Credit Keyed</td>
                            <td colspan="5">{{data.summary.total_credit_keyed || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Credit Keyed POS</td>
                            <td colspan="5">{{data.summary.total_credit_keyed_pos || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_credit_keyed_refund_net_value || 0 | currency:'USD' }}</td>
                        </tr>
                        
                        <tr>
                            <td colspan="3">Total Non Taxable Sale Cash</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_cash || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Cash POS</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_cash_pos || 0 | currency:'USD'
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_non_taxable_pos_cash_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Cash Online</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_cash_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_non_taxable_online_cash_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Non Taxable Sale Card</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_card || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Card POS</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_card_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_non_taxable_pos_card_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Non Taxable Sale Card Online</td>
                            <td colspan="5">{{data.summary.total_non_taxable_sale_card_online || 0 | currency:'USD'
                                }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_non_taxable_online_card_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total gifts</td>
                            <td colspan="5">{{data.summary.total_gifts || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total gifts POS</td>
                            <td colspan="5">{{data.summary.total_gifts_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_pos_gift_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total gifts Online</td>
                            <td colspan="5">{{data.summary.total_gifts_online || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_online_gift_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total Splits</td>
                            <td colspan="5">{{data.summary.total_split || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Splits POS</td>
                            <td colspan="5">{{data.summary.total_split_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_pos_gift_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total Splits Online</td>
                            <td colspan="5">{{data.summary.total_split_online || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_online_gift_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Total others</td>
                            <td colspan="5">{{data.summary.total_others || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total others POS</td>
                            <td colspan="5">{{data.summary.total_others_pos || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_pos_other_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">Total others Online</td>
                            <td colspan="5">{{data.summary.total_others_online || 0 |
                                currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 32px;">Refunds</td>
                            <td colspan="5">{{data.summary.total_net_online_other_refund || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr class="border-top border-bottom">
                            <td colspan="3">Total Receipts</td>
                            <td colspan="5">{{data.summary.total_total_receipts || 0 | currency:'USD' }}</td>
                        </tr>

                        <tr>
                            <td colspan="17">&nbsp;</td>
                        </tr>
                    </table>
                </div>
                <div fxFlex="75%" style="margin-right: 5px">
                    <div class="daily-report-header">
                        Daily Payment Summary
                    </div>
                    <div class="daily-summary-table">
                        <table cellpadding="5px" cellspacing="1" style="width: 100%;">
                            <tr class="border-bottom">
                                <td>Order Date</td>
                                <td>Cash Count</td>
                                <td>Total Cash</td>
                                <td>Cash POS</td>
                                <td>Cash POS Refund</td>
                                <td>Cash Online</td>
                                <td>Cash Online Refund</td>
                                <td>Card Count</td>
                                <td>Total Card</td>
                                <td>Card POS</td>
                                <td>Card POS Refund</td>
                                <td>Card Online</td>
                                <td>Total Credit Keyed</td>
                                <td>Credit Keyed POS</td>
                                <td>Card Online Refund</td>
                                <td>Total Non-Taxable Sales Cash</td>
                                <td>Total Non-Taxable Sales Cash POS</td>
                                <td>Non-Taxable Sales Cash POS Refund</td>
                                <td>Total Non-Taxable Sales Cash Online</td>
                                <td>Non-Taxable Sales Cash Online Refund</td>
                                <td>Total Non-Taxable Sales Card</td>
                                <td>Total Non-Taxable Sales Card POS</td>
                                <td>Non-Taxable Sales Card POS Refund</td>
                                <td>Total Non-Taxable Sales Card Online</td>
                                <td>Total Refund(Qty)</td>
                                <td>Total Refund($)</td>
                                <td>Total Non-Taxable Sales Card Online Refund</td>
                                <td>Total Gifts </td>
                                <td>Total Gifts POS</td>
                                <td>Gifts POS Refund</td>
                                <td>Total Gifts Online</td>
                                <td>Gifts Online Refund</td>
                                <td>Total Others </td>
                                <td>Total Others POS</td>
                                <td>Others POS Refund</td>
                                <td>Total Others Online</td>
                                <td>Others Online Refund</td>
                            </tr>
                            <tr *ngFor="let record of data.records" class="align-right">
                                <td>{{ record.order_date}}</td>
                                <td>{{ record.order_count_cash}}</td>
                                <td>{{ record.cash || 0 | currency:'USD'}}</td>
                                <td>{{ record.cash_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_pos_cash_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.cash_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_online_cash_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.order_count_card}}</td>
                                <td>{{ record.card || 0 | currency:'USD'}}</td>
                                <td>{{ record.card_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_pos_card_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.card_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.credit_keyed || 0 | currency:'USD'}}</td>
                                <td>{{ record.credit_keyed_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_online_card_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sale_cash || 0 | currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sale_cash_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_non_taxable_pos_cash_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sale_cash_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_non_taxable_online_cash_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sale_card || 0 | currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sale_card_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_non_taxable_pos_card_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.non_taxable_sale_card_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.refund_quantity || 0 }}</td>
                                <td>{{ record.refund_total || 0 | currency:'USD' }}</td>
                                <td>{{ record.net_non_taxable_online_card_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.gifts || 0 | currency:'USD'}}</td>
                                <td>{{ record.gifts_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_pos_gift_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.gifts_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_online_gift_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.others || 0 | currency:'USD'}}</td>
                                <td>{{ record.others_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_pos_other_refund || 0 | currency:'USD'}}</td>
                                <td>{{ record.others_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.others_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.others_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.others_pos || 0 | currency:'USD'}}</td>
                                <td>{{ record.others_online || 0 | currency:'USD'}}</td>
                                <td>{{ record.net_online_other_refund || 0 | currency:'USD'}}</td>
                            </tr>
                            <tr class="align-right border-top">
                                <td style="text-align: start;">Total</td>
                                <td>{{ data.summary.total_order_count_cash || 0 }}</td>
                                <td>{{ data.summary.total_cash || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_cash_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_pos_cash_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_cash_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_online_cash_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_order_count_card || 0 }}</td>
                                <td>{{ data.summary.total_card || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_card_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_pos_card_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_card_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_credit_keyed || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_credit_keyed_pos || 0 | currency:'USD' }}</td>
                                
                                <td>{{ data.summary.total_net_online_card_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sale_cash || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sale_cash_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_non_taxable_pos_cash_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sale_cash_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_non_taxable_online_cash_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sale_card || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sale_card_pos || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_net_non_taxable_pos_card_refund || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_non_taxable_sale_card_online || 0 | currency:'USD' }}</td>
                                <td>{{ data.summary.total_refund_quantity || 0 }}</td>
                                <td>{{ data.summary.total_refund_total || 0 | currency:'USD' }}</td>
                                <td class="border-right">{{ data.summary.total_gifts || 0 | currency:'USD' }}</td>
                                <td class="border-right">{{ data.summary.total_gifts_pos || 0 | currency:'USD' }}</td>
                                <td class="border-right">{{ data.summary.total_gifts_online || 0 | currency:'USD' }}
                                </td>
                                <td class="border-right">{{ data.summary.total_total_others || 0 | currency:'USD' }}
                                </td>
                                <td class="border-right">{{ data.summary.total_total_others_pos || 0 | currency:'USD' }}
                                </td>
                                <td class="border-right">{{ data.summary.total_total_others_online || 0 | currency:'USD'
                                    }}
                                </td>
                                <td class="border-right">{{ data.summary.total_net_online_other_refund || 0 | currency:'USD' }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div style="display:flex">
                <div fxFlex="25%">
                    <table cellpadding="5px" cellspacing="1" style="width: 100%;">
                        <tr>
                            <td colspan="8" class="header">Platform Charges Summary</td>
                        </tr>
                        <tr>
                            <td colspan="3">$ Service Charges</td>
                            <td colspan="5">{{ data.summary.total_service_charge_dollar || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.service_charge_label_pos}}({{data.summary.total_pos_service_charge_dollar_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_service_charge_dollar_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.service_charge_label}}({{data.summary.total_online_service_charge_dollar_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_service_charge_dollar_online || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">% Service Charges</td>
                            <td colspan="5">{{ data.summary.total_service_charge_percent || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.pos_service_charge_title}}({{data.summary.total_pos_service_charge_per_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_service_charge_percent_pos || 0 | currency:'USD'}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.online_service_charge_title}}({{data.summary.total_online_service_charge_per_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_service_charge_percent_online || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">$ Credit Only Charges</td>
                            <td colspan="5">{{ data.summary.total_surcharge_dollar || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.pos_surcharge_dollar_label}}({{data.summary.total_pos_sur_charge_dollar_state_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_surcharge_dollar_pos || 0 | currency:'USD'}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.surcharge_label_dollar}}({{data.summary.total_online_sur_charge_dollar_state_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_surcharge_dollar_online || 0 | currency:'USD' }}</td>
                        </tr>

                        <tr>
                            <td colspan="3">% Credit Only Charges</td>
                            <td colspan="5">{{ data.summary.total_surcharge_percent || 0 | currency:'USD' }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.pos_surcharge_percent_label}}({{data.summary.total_pos_sur_charge_per_state_count
                                || 0 }})</td>
                            <td colspan="5">{{ data.summary.total_surcharge_percent_pos || 0 | currency:'USD' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding-left: 20px;">
                                {{data.store.surcharge_label}}({{data.summary.total_online_sur_charge_per_state_count ||
                                0 }})</td>
                            <td colspan="5">{{ data.summary.total_surcharge_percent_online || 0 | currency:'USD'}}</td>
                        </tr>
                        <tr class="border-top border-bottom">
                            <td colspan="3">Total Platform Charges</td>
                            <td colspan="5">{{data.summary.total_platform_charges || 0 | currency:'USD' }}</td>
                        </tr>

                        <tr>
                            <td colspan="17">&nbsp;</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
    </div>
</body>