<mat-dialog-content class="mat-typography">
    <div class="container">
      <p class="cardPayment" *ngIf="data?.command === 'SIGN_REQUIRED'">
        Please sign below
      </p>

      <p class="cardPayment" *ngIf="data?.command === 'PIN_REQUIRED'">
        Please enter your PIN below
      </p>

      <div class="pin-content">
        <div class="signature" [hidden]="data?.command !== 'SIGN_REQUIRED'">
          <canvas #canvas width="600" height="400"></canvas>
          <button mat-button (click)="clear()">Clear</button>
        </div>
        <div class="pin-verify" [hidden]="data?.command !== 'PIN_REQUIRED'">
          <app-pin-verify-pad (pinEmitter)="updatePin($event)"></app-pin-verify-pad>
        </div>
      </div>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="done()">Done</button>
</mat-dialog-actions>
