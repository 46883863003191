<form (ngSubmit)="onSubmit()" [formGroup]="form" class="fixActionRow">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content class="campaign-dialog">
    <div [formGroup]="form">
      <div *ngFor="let field of data.fields">
        <div *ngVar="controlMap?.get(field) as fieldControl">
          <div [ngSwitch]="fieldControl.controlType">
            <mat-form-field *ngSwitchCase="'textbox'" class="full-width">
              <input type="text" [formControlName]="fieldControl?.key" class="form-control" matInput
                [value]="fieldControl?.value" [placeholder]="fieldControl?.label | titlecase" />
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'dropdown'" class="full-width">
              <mat-select [(ngModel)]="storeMerchant[editKey][field]" [formControlName]="fieldControl?.key"
                [placeholder]="fieldControl?.label | titlecase">
                <mat-option *ngFor="let opt of fieldControl.options" [value]="opt.key">{{opt.value}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'phone'" class="full-width">
              <input type="text" [formControlName]="fieldControl?.key" class="form-control" matInput
                [value]="storeMerchant[editKey][field] || '' | phone" autofocus [placeholder]="fieldControl?.label"
                appPhoneMask />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-stroked-button (click)="cancel()">Cancel</button>
    <button mat-button color="primary" class="primary-color apply-btn-colr primary-button">Apply</button>
  </mat-dialog-actions>

</form>