import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenHeightIncreaseService {
  private info11 = new BehaviorSubject<any>('');

  getNewUserInfo: any;

  constructor() { }

  getInfo(isFullScreen: any) {
    if (!isFullScreen) {
      this.info11.next(window.innerHeight - 466);
    } else {
      this.info11.next(window.innerHeight - 202);
    }
  }

  reciveInfo(): Observable<any> {
    console.log('reciveInfo');
    return this.info11.asObservable();
  }
}
