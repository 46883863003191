import {Component, Inject, OnInit} from '@angular/core';
import {Store} from 'src/app/_models/store';

import {StoreService} from 'src/app/_services/store.service';
import {MatProgressButtonOptions} from 'mat-progress-buttons';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';


@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {

  store:Store;
  verificationCode:string;
  step1:boolean = false;
  step2:boolean = false;
  isLoading:boolean = false;

  callNowBtnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Call Now',
    spinnerSize: 19,
    raised: false,
    stroked: false,
    flat: false,
    fab: false,
    buttonColor: 'accent',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate'
  };

  verifyBtnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Submit',
    spinnerSize: 19,
    raised: false,
    stroked: false,
    flat: false,
    fab: false,
    buttonColor: 'accent',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate'
  };



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VerifyPhoneComponent>,
    private storeService:StoreService) {
      this.store = data.store;
  }

  ngOnInit() {}

  callNow(stepper: MatStepper){
    this.callNowBtnOpts.active = true
    this.storeService.verifyPhone(this.store).subscribe(data => {
      this.verificationCode = data['verification_code'].split("").join("-")
      this.step1 = true;
      this.callNowBtnOpts.active = false;
      stepper.next();
    })
  }

  verify(stepper: MatStepper){
    this.verifyBtnOpts.active = true
    this.storeService.phoneVerificationStatus(this.store).subscribe(data => {
      this.step2 = data['status'] === 'verified';
      this.verifyBtnOpts.active = false;
      stepper.next();
    })
  }

}
