<div class="mock">
  <div class="bubble" *ngIf="text">{{text}}<br/><br/>{{optOutText}}</div>
  <div class="imagecontainer">
    <img *ngIf="image"
         class="image"
         [src]="image || 'https://via.placeholder.com/300x150?text=Sample+Image'"
         alt="Campaign image">
  </div>

  <div class="link" *ngIf="url" [ngClass]="{'has-image': image, 'no-image': !image}">
    <div class="top">Click to Load Preview</div>
    <div class="bottom">{{url}}</div>
  </div>
</div>
