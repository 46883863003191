<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <h1 mat-dialog-title>Change Password</h1>
  <div mat-dialog-content>
    <div class="form-group">
      <mat-form-field style="width: 100%">
        <mat-label>New Password</mat-label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" matInput
        placeholder="New Password">
      </mat-form-field>

      <mat-form-field style="width: 100%">
        <mat-label>Confirm Password</mat-label>
        <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" matInput
        placeholder="Confirm Password">
      </mat-form-field>
    </div>
    <p class="error" *ngIf="error">{{error}}</p>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-button class="primary-color apply-btn-colr primary-button">Apply</button>
  </mat-dialog-actions>
</form>

