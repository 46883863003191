<table width="100%" matSort  mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-SortActive xs:!table-fixed">

  <!-- Greeting Type -->
  <ng-container matColumnDef="greeting_type" >
    <th mat-header-cell *matHeaderCellDef>Greeting Type</th>
    <td mat-cell *matCellDef="let element;"> {{element.greeting_type ? element.greeting_type : 'N/A'}} </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="trigger">
    <th mat-header-cell *matHeaderCellDef>Trigger</th>
    <td mat-cell *matCellDef="let element;"> Press {{element.gather_number}} </td>
  </ng-container>

  <!-- Label Column -->
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.label|titlecase}} </td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="greeting">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> {{element.after_action_statement ? element.after_action_statement:'N/A'}} </td>
  </ng-container>

  <!-- Edit -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td style="text-align: center;" mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="actionMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu" class="action-icons apply-btn-colr">
        <mat-icon style="cursor: pointer;" class="edit-icon material-icons-outlined" (click)="addAction(element)">edit</mat-icon>
        <mat-icon style="cursor: pointer;" class="delete-icon material-icons-outlined" (click)="deleteAction(element)">delete</mat-icon>
      </mat-menu>
    </td>
  </ng-container>

  <!-- Add button -->
  <ng-container matColumnDef="disclaimer">
    <td mat-footer-cell *matFooterCellDef colspan="4">
      <button class="mat-flat action-btn-colr"  (click)="addAction('ADD')"> Add </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"
      class="example-first-header-row">
  </tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="example-second-footer-row"></tr>


</table>
