<div class="p-2 w-full max-w-md mx-auto">
    <h1 mat-dialog-title>Select User</h1>
  
    <div mat-dialog-content>
      <form class="w-full">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Select a User</mat-label>
          <mat-select [(ngModel)]="selectedUserEmail" name="userSelect">
            <mat-option *ngFor="let user of userData.user_data" [value]="user">
              {{ user.first_name }} {{ user.last_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  
    <!-- Buttons -->
    <div class="flex justify-end m-4 space-x-2">
      <button mat-button (click)="onCancel()" class="text-gray-500 border border-gray-300 px-4 py-2 rounded-md">
        Cancel
      </button>
      <button 
        mat-button 
        (click)="onConfirm()" 
        [disabled]="!selectedUserEmail" 
        [ngClass]="{
          'bg-blue-600 text-white border border-blue-600': selectedUserEmail, 
          'bg-gray-300 text-gray-600 cursor-not-allowed': !selectedUserEmail
        }" 
        class="px-4 py-2 rounded-md"
      >
        Confirm
      </button>
    </div>
  </div>
  