import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Store} from 'src/app/_models/store';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {StoreService} from 'src/app/_services/store.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-assign-mods',
  templateUrl: './assign-mods.component.html',
  styleUrls: ['./assign-mods.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class AssignModsComponent implements OnInit, OnChanges {
  @Input() store: Store;
  @Output() refresh = new EventEmitter();


  dataSource: MatTableDataSource<any>;
  order
  dataSources = {}
  isLoading = true;
  menuGroupsOrdered
  orderedMgIds = []

  constructor(private menuService: MenuServiceV2, public storeService: StoreService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.menuService.getStoreMenuGroups(this.store.id).subscribe(data => {
          this.menuGroupsOrdered = data
          let mgids = Object.keys(data).map(i => +i).sort((a,b) => +a - +b)
          this.orderedMgIds = mgids.map(p => p.toString());
          this.isLoading = false;
        })
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {}
}
