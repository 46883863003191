import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from 'src/app/_services/store.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.scss']
})
export class UserSelectDialogComponent {
  selectedUserEmail: any = null;
  currentPIN: any;

  constructor(
    private storeService: StoreService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public userData: any,
    private dialogRef: MatDialogRef<UserSelectDialogComponent>
  ) {
    this.currentPIN = localStorage.getItem('currentPIN');
    if (this.currentPIN) {
      this.selectedUserEmail = this.userData.user_data.find(
        (user: any) => user.user_profile.pin === this.currentPIN
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    const params = this.userData.report_param;
    if (params.is_current) {
      this.currentPIN = this.selectedUserEmail.user_profile.pin;
    } else {
      this.currentPIN = null;
    }

    const jsonData = {
      "store_id": params.store_id,
      "terminal_id": params.sration_name,
      "current_pin": this.currentPIN,
      "email": this.selectedUserEmail.email,
      "start_date": moment(params.start_date).format("YYYY-MM-DD"),
      "end_date": moment(params.end_date).format("YYYY-MM-DD"),
      "from_hour": params.from.hour,
      "from_min": params.from.min,
      "to_hour": params.to.hour,
      "to_min": params.to.min,
      "status": "pending",
      "user_id": params.user_id
    };

    this.storeService.reportOnMail(jsonData).subscribe(
      (data: any) => {
        if (data.status === "new") {
          this._snackBar.open("You will receive the report at your email address in 20 to 30 min.", "OK", {
            duration: 10000,
          });
        } else if (data.status === "no") {
          this._snackBar.open("Date range is outside user profile constraints.", "OK", {
            duration: 10000,
          });
        } else {
          this._snackBar.open("A report request has already been submitted you will recieve in 20 to 30 min.", "OK", {
            duration: 10000,
          });
        }
      },
      (error) => {
        console.error('Error sending params:', error);
      }
    );
    this.dialogRef.close();
  }
}
