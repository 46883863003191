import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import {environment} from 'src/environments/environment';
import {AddScreenDialogComponent} from '../add-screen-dialog/add-screen-dialog.component';
import {EditScreenDialogComponent} from '../edit-screen-dialog/edit-screen-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {ScreenDeleteComponent} from '../screen-delete/screen-delete.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ShowScreenService } from 'src/app/_services/show-screen.service';
@Component({
  selector: "app-digital-screens",
  templateUrl: "./digital-screens.component.html",
  styleUrls: ["./digital-screens.component.scss"],
})
export class DigitalScreensComponent implements OnInit {
  @Input() data;
  safeUrl: SafeResourceUrl;
  displayedColumns: string[] = [
    "canvas",
    "screen_id",
    "content_type",
    "screen_url",
    "action",
  ];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  storeId: any;
  screenData: any;
  endpoint: any;
  showUrl: any = [];
  orderUrl;
  orderUrls;
  public elementSrc = [];
  ertertr: any;
  src;
  aaa: any = false;
  @ViewChild("iframe11", { static: false }) iframe11: ElementRef;
  abc: any;
  storeType: any;
  interval;
  messages: any[] = [];
  subscription: Subscription;

  iframeWidth: string = '1920px';
  iframeHeight: string = '1000px';
  iframeScale: string = '0.75';
  iframePosition: string = 'absolute';
  // iframeTop: string = '78%';
  iframeTop: string = '71%';
  iframeTransform: string = 'translate(-100%, -101%)';
  iframeLeft: string = '76%';
  cusClass: string = 'tvfullhd-hr';

  // resolutions = [
  //   { label: '40-inch TV: 2160p:(3840 x 2160 pixels)', width: '3840px', height: '2004px', styles: { scale: '1.15', position: 'absolute', top: '103%', left: '98%', transform: 'translate(-100%, -101%)' }},

  //   { label: '40-inch TV: 1440p:(2560 x 1440 pixels)', width: '2560px', height: '1440px', styles: { scale: '1.15', position: 'absolute', top: '103%', left: '98%', transform: 'translate(-100%, -101%)' }},

  //   { label: '40-inch TV: 1080p:(1920 x 1080 pixels)', width: '1920px', height: '1000px', styles: { scale: '1.15', position: 'absolute', top: '103%', left: '98%', transform: 'translate(-100%, -101%)' }},

  //   { label: '40-inch TV: 720p:(1280 x 720 pixels)', width: '1280px', height: '720px', styles: { scale: '1.15', position: 'absolute', top: '103%', left: '98%', transform: 'translate(-100%, -101%)' }},
  // ];


  resolutions = [

    { label: 'TCL: 40-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'TCL: 40-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'TCL: 40-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'TCL: 43-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'TCL: 43-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'TCL: 43-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'Hisense: 40-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Hisense: 40-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Hisense: 40-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Hisense: 43-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Hisense: 43-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Hisense: 43-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'Insignia: 40-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Insignia: 40-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Insignia: 40-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Insignia: 43-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Insignia: 43-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Insignia: 43-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Philips: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Philips: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Philips: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Philips: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Philips: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Philips: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'LG: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'LG: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'LG: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'LG: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'LG: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'LG: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'SONY: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'SONY: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'SONY: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'SONY: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'SONY: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'SONY: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'Samsung: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Samsung: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Samsung: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },



    { label: 'Samsung: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Samsung: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Samsung: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    // { label: '40-inch TV: 1143p:(2397 x 1143 pixels)', width: '2397px', height: '1143px', scale: '0.7', position: 'absolute', top: '78%', left: '76%', transform: 'translate(-100%, -101%)' },




    // { label: '40-inch TV: 686p:(1438 x 686 pixels)', width: '1438px', height: '686px', position: 'absolute', top: '100%', left: '100%', transform: 'translate(-111%, -118%)' },


  ];


  selectedResolution: any;
  selectedSize: string;
  selectedNativeResolution: any;
  tvId: any;
  type: any;
  leaflogixendpoint: string;
  iframeStyles: any;
  orientationSet: any = 'Horizontal';
  vir_class: string;
  constructor(
    private digitalDisplayService: DigitalDisplayService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private showScreenService: ShowScreenService,
    private renderer: Renderer2
  ) {
    this.subscription = this.digitalDisplayService.getDigitalscreen().subscribe(message => {
      if (message) {
        if(message.text == 'copy'){
          console.log("copy");
          var tv_id = Number(this.showUrl[this.showUrl.length-1].screen_id);
          tv_id += 1;
          var newtv_id = tv_id.toString();
          console.log("newtv_id", newtv_id);
          this.orderUrl = this.endpoint+"/backend/v2/pos-display/web_screens/?store_id=" + this.storeId +  "&tv_id=" +  tv_id;
          var aaa = {
            url: this.sanitizer.bypassSecurityTrustResourceUrl(
              `${this.orderUrl}`
            ),
            screen_id: newtv_id,
            canvas: 1
          };
          this.interval = setTimeout(() => {
            this.showUrl.push(aaa);
          }, 1000);
        }
        else if (message.text == 'add') {
          console.log("addd");
          var tv_id = Number(this.showUrl[this.showUrl.length-1].screen_id);
          tv_id += 1;
          var newtv_id = tv_id.toString();
          console.log("newtv_id", newtv_id);
          this.orderUrl = this.endpoint+"/backend/v2/pos-display/web_screens/?store_id=" + this.storeId +  "&tv_id=" +  tv_id;
          var aaa = {
            url: this.sanitizer.bypassSecurityTrustResourceUrl(
              `${this.orderUrl}`
            ),
            screen_id: newtv_id,
            canvas: 1
          };
          this.interval = setTimeout(() => {
            this.showUrl.push(aaa);
          }, 2000);
        }
        else{
          this.showUrl = this.showUrl.filter((item) => item.screen_id != message.text);
          this.messages.push(message);
        }

      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.leaflogixendpoint = environment.leaflogixApiEndpoint;

    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.tvId = params['tv_id'];
      this.type = params['type'];
    });
    let urlCreate;
    if (this.type == '1') {
      urlCreate = `${this.leaflogixendpoint}/pos-display/1/${this.storeId}/${this.tvId}/`;
      // urlCreate = 'https://beta.labrador.ai/pos-display/1/1/1/'
    }
    else {
      urlCreate = `${this.endpoint}/backend/v2/pos-display/screens/?store_id=${this.storeId}&tv_id=${this.tvId}`;
    }

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlCreate);

    this.selectedResolution = this.resolutions[4]; // Default to Custom 6
    this.updateIframe();


  }

  back() {
    this.showScreenService.submit(true);
  }

  refreshScreen() {
    let urlCreate;
    if (this.type == '1') {
      urlCreate = `${this.leaflogixendpoint}/pos-display/1/${this.storeId}/${this.tvId}/`;
      // urlCreate = 'https://beta.labrador.ai/pos-display/1/1/1/'
    }
    else {
      urlCreate = `${this.endpoint}/backend/v2/pos-display/screens/?store_id=${this.storeId}&tv_id=${this.tvId}`;
    }

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlCreate);
  }

  // updateIframe() {
  //   if (this.selectedResolution) {
  //     this.iframeWidth = this.selectedResolution.width;
  //     this.iframeHeight = this.selectedResolution.height;
  //   } else if (this.selectedSize && this.selectedNativeResolution) {
  //     const resolutionRatio = 3840 / 32; // Assuming native resolution 3840 x 2160 for 32 Inch
  //     const size = parseInt(this.selectedSize);
  //     const width = (resolutionRatio * size).toString() + 'px';
  //     const height = (resolutionRatio * size * (9 / 16)).toString() + 'px'; // Assuming 16:9 aspect ratio

  //     this.iframeWidth = width;
  //     this.iframeHeight = height;
  //   }
  // }

  updateIframe() {
    if (this.selectedResolution) {
      const resolution = this.resolutions.find(res => res.label === this.selectedResolution);
      if (resolution) {
        this.iframeWidth = resolution.width;
        this.iframeHeight = resolution.height;
        this.iframeScale = resolution.scale;
        this.iframePosition = resolution.position;
        this.iframeTop = resolution.top;
        this.iframeLeft = resolution.left;
        this.iframeTransform = resolution.transform;
        this.cusClass = resolution.cus_class
        this.vir_class = resolution.vir_class

      }
    }
  }


  updateOrientation(event: any) {
    this.orientationSet = event.value;

    console.log("add css", event);

  }




  getDisplayScreen() {
    this.digitalDisplayService
      .getDisplayScreen(this.storeId)
      .subscribe((data) => {
        this.screenData = data.data;
        this.dataSource = new MatTableDataSource<PeriodicElement>(
          this.screenData
        );
        this.dataSource.paginator = this.paginator;
        this.abc = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://beta.labrador.ai/pos-display/web/screens/?store_id=1&tv_id=3`
          // `http://127.0.0.1:8002/pos-display/web/screens/?store_id=1&tv_id=3`
        );
        for (let i = 0; i < this.screenData.length; i++) {
          const element = this.screenData[i].tv_id;

          if (this.storeType == "Leaflogix") {
            this.orderUrl = "https://beta.labrador.ai/pos-display/web/screens/?store_id=" + this.storeId + "&tv_id=" + element;
            // this.orderUrl = "http://127.0.0.1:8002/pos-display/web/screens/?store_id=" + this.storeId + "&tv_id=" + element;
          }
          else {
            this.orderUrl = this.endpoint + "/backend/v2/pos-display/web_screens/?store_id=" + this.storeId + "&tv_id=" + element;
          }
          const aaa = {
            url: this.sanitizer.bypassSecurityTrustResourceUrl(
              `${this.orderUrl}`
            ),
            screen_id: this.screenData[i].tv_id,
            canvas: this.screenData[i].canvas
          };
          this.showUrl.push(aaa);
        }
      });
  }

  deleteDisplayScreen(id) {
    this.digitalDisplayService.deleteDisplayScreen(id).subscribe((data) => {
      this.getDisplayScreen();
    });
  }
  addScreen() {
    const dialogRef = this.dialog.open(AddScreenDialogComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }
  editScreen(element) {
    const dialogRef = this.dialog.open(EditScreenDialogComponent, {
      data: element,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }

  deleteScreens() {
    let dialogRef = this.dialog.open(ScreenDeleteComponent, {});
  }
}



export interface PeriodicElement {
  canvas: any;
  screen_id: any;
  content_type: any;
  screen_url: any;
}
