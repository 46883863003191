<div>
    <div class="flex flex-row flex-wrap xs:flex-col xs:flex-row-none items-center !w-full my-7 px-5">
        <form [formGroup]="Daterange" (ngSubmit)="filterSubmit()" autocomplete="off" class="flex flex-row">
            <mat-form-field appearance="fill" class="mr-2 xs:mr-0 xs:!w-full">
                <mat-label>Start Date</mat-label>
                <input matInput [min]="maxDateCheck" [matDatepicker]="dp3" placeholder="Choose a date" readonly="true"
                    formControlName="from_date">
                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                <mat-datepicker #dp3></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="mr-2 xs:mr-0 xs:!w-full" appearance="fill">
                <mat-label>End Date</mat-label>
                <input matInput [min]="Daterange.get('from_date').value" [disabled]="!Daterange.get('from_date').value"
                    [matDatepicker]="dp1" placeholder="Choose a date" readonly="true" formControlName="to_date">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
            <button mat-stroked-button color="primary"
                class="mr-2 xs:mr-0 xs:!w-full mb-4 xs:mb-0 top-2.5">Search</button>
            <button mat-stroked-button color="primary" (click)="clearDate()"
                class="mr-2 xs:mr-0 xs:!w-full mb-4 xs:mb-0 top-2.5">Clear</button>
        </form>
    </div>
    <div
        class="flex flex-row mb-[22px] xs:!min-w-full !grid !grid-cols-2 gap-4 xs:!flex xs:!flex-col xs:!justify-center xs:!items-center h-auto ml-5">
        <div>
            <div
                class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
                <div class="text-center mb-5">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="lineBarByGroup"
                        class="text-xs font-sans">
                        <mat-radio-button *ngFor="let opt of lineBarRatingGraphOptions" [value]="opt"
                            (change)="lineBarFilterGraphData($event)">{{opt|titlecase}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="lineBarRatingGraph" class="p-[10%] pt-0 !h-full">
                    <p class="text-center font-semibold">Rating Graph</p>
                    <google-chart [type]="'ComboChart'" [data]="lineBarRatingGraph.rows" class="chart !w-full !h-full"
                        [columns]="lineBarRatingGraph.cols" [options]="feedbackChartOptions"></google-chart>
                </div>
            </div>
        </div>

        <div>
            <div
                class="!w-full h-[100%] shadow-lg xs:!w-full py-5 xs:px-2 !flex !flex-col !gap-5 border border-gray-300 rounded-md">
                <div class="text-center mb-5">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="pieChartByGroup"
                        class="text-xs font-sans">
                        <mat-radio-button *ngFor="let opt of pieChartRatingGraphOptions" [value]="opt"
                            (change)="pieChartFilterGraphData($event)">{{opt|titlecase}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="text-center font-semibold">Total Rating: {{ totalFeedback }}</div>
                <div *ngIf="ratingPieChart" class="p-[10%] pt-0 !h-full">
                    <p class="text-center font-semibold">Rating Graph</p>
                    <app-rating-pie-chart [allData]="ratingPieChart"></app-rating-pie-chart>
                </div>
            </div>
        </div>
    </div>
</div>