<form (ngSubmit)="onSubmit()" [formGroup]="form" class="fixActionRow">
  <h1 mat-dialog-title>Holidays</h1>

  <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}" class="xs:!overflow-x-hidden">
    <p class="message">Enter the Holiday day when the store is closed.</p>

    <table class="table" cellpadding=3>
      <tr *ngFor="let hour of form.controls; index as i" [formGroupName]="i">
        <td>
          <mat-form-field appearance="fill" class="field-size">
            <mat-label>Add Holiday</mat-label>
            <mat-select formControlName="holiday_name">
              <mat-option *ngFor="let holiday of holiday_names" [value]="holiday">{{ holiday }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        
        <td>
          <mat-slide-toggle formControlName="is_open" (change)="toggle($event, i)">
          {{ hour.value.is_open ? 'Open' : 'Closed' }}</mat-slide-toggle>
        </td>

        <td class="delete_icon">
          <button mat-icon-button (click)="delete(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>

      </tr>
    </table>
    <button mat-raised-button (click)="addNew()" type="button" class="new-date-btn">Add Holiday</button>
  </div>

  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr">Apply</button>
  </mat-dialog-actions>

</form>
