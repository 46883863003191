import { Component, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
@Component({
  selector: 'app-menu-item-image',
  templateUrl: './menu-item-image.component.html',
  styleUrls: ['./menu-item-image.component.scss']
})
export class MenuItemImageComponent {
  private destroySubject: Subject<void> = new Subject();
  image_id: any;
  imageList = [];
  constructor(
    private menuService: MenuServiceV2,
    private dialogRef: MatDialogRef<MenuItemImageComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() {
    this.imageList = this.data.menuItemGroup?.latest_image;
  }
  delete(id, type) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: type == 'single_image' ? 'Are you sure you want to delete the image?' : 'Are you sure you want to delete all the images?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 'all') {
          this.menuService.deleteImage(this.data.menuItemGroup.store, this.data.menuItemGroup.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.dialogRef.close(true);
          })
        }
        else if (type == 'single_image') {
          this.menuService.deleteSingleImage(this.data.menuItemGroup.store, this.data.menuItemGroup.id, id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.imageList = this.imageList.filter(image => image.id !== id);
            if (this.imageList.length == 0) {
              this.dialogRef.close(true);
            }
          })
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.destroySubject.next();
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}