export class TimeClock {
  id: number;
  user: User;
  clock_in_time: string;
  clock_out_time: string;
  start_break_time: string;
  end_break_time: string;
  number_of_breaks: number;
  break_time: string;
  status: string;
}

export class TimeClocks{
  total: number;
  timeClocks: TimeClock[];
}

export class User{
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
}