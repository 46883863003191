<div class="order-container full-y-width  xs:!overflow-x-scroll  xs:m-auto">
  <table mat-table width="100%"matSort matSortActive="order_counter" matSortDirection="desc"
    matSortDisableClear [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Name">{{element.offer_title}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Created">{{element.created_at | date: 'hh:mmaaa
        MM/dd/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="total_redeemed">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Total Redeemed</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Total Redeemed">{{element.total_redeemed || 0}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="net_sales">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Net Sales</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Net Sales">{{element.net_sales || 0 | currency:'USD'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="discounts">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Discounts</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Discounts">{{element.discounts || 0 | currency:'USD'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Status">{{element.status}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef sticky></mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" sticky>
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="edit(element)">Edit</button>
          <button mat-menu-item (click)="delete(element)">Delete</button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'highlight-order-row': row === selectedRow}"
      (click)="onRowClicked(row)"></mat-row>
  </table>
</div>
<mat-paginator [length]="dataSource?.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>