import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrderService} from 'src/app/_services/order.service';
import {POSService} from 'src/app/_services/pos-service';
import {PaymentService} from 'src/app/_services/payment.service';
import {BotOrder} from 'src/app/_models/order';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-order-acceptance-dialog',
  templateUrl: './order-acceptance-dialog.component.html',
  styleUrls: ['./order-acceptance-dialog.component.scss']
})
export class OrderAcceptanceDialogComponent implements OnInit {

  data = [
    {
      "value1": "15 Minutes"
    }, {
      "value1": "20 Minutes"
    }, {
      "value1": "25 Minutes"
    }, {
      "value1": "30 Minutes"
    }, {
      "value1": "35 Minutes"
    }, {
      "value1": "40 Minutes"
    }, {
      "value1": "45 Minutes"
    }, {
      "value1": "50 Minutes"
    }, {
      "value1": "55 Minutes"
    }, {
      "value1": "60 Minutes"
    }, {
      "value1": "65 Minutes"
    }, {
      "value1": "70 Minutes"
    }
  ]

  loading;
  error;
  orderTime: any;
  minutes: any;
  orderId;
  store;
  time;
  customTime: UntypedFormGroup;
  typeTime: any;

  constructor(
    private orderService: OrderService,
    private posService: POSService,
    private paymentService: PaymentService,
    private receiptService: ReceiptService,
    public dialogRef: MatDialogRef<OrderAcceptanceDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data1: any,
  ) {
    this.orderId = this.data1.botOrder.order_hash;
    this.store = this.data1.store;
  }

  ngOnInit() {
    let botOrder = Object.assign(new BotOrder(), this.data1.botOrder);
    this.receiptService.changeBotOrder(botOrder);
    this.customTime = this.fb.group({
      time: ''
    });
  }

  acceptOrder(event) {
    this.time = event;
    this.orderTime = event.split(' ');
    this.minutes = this.orderTime[0];
    this.customTime = this.fb.group({
      time: this.minutes
    });
  }

  valueTyped(event) {
    this.typeTime = event;
    this.time = '';
    this.orderTime = '';
    this.minutes = this.typeTime;
  }

  submitTime() {
    this.dialogRef.close(this.minutes);
  }

  declineOrder(event) {
    this.minutes = event;
    this.dialogRef.close(this.minutes);
  }

}
