import {Component, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {StoreService} from 'src/app/_services/store.service';
import {EventEmitter} from 'events';
import {environment} from 'src/environments/environment';
import {Store} from 'src/app/_models/store';
import {UploadService} from 'src/app/_services/upload.service';
import {forkJoin} from 'rxjs';
import {CampaignService} from '../../_services/campaign.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-dialog',
  templateUrl: './campaign-dialog.component.html',
  styleUrls: ['./campaign-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignDialogComponent implements OnInit {
  form;
  costPerPart = 0.05
  costPerUser = 0.05
  totalCost = 0
  schedule = 'later';
  store:Store;

  scheduleDate
  scheduleTime

  timeRange
  start = moment().toDate()

  @Output() refresh: EventEmitter;

  cols;
  isLoading = false;
  ext;
  links;

  image;
  imageStr;
  campaignType;
  optedIn = 0;

  difference: number;
  scheduleDatetime: any;
  campaignSetting: any;
  campaignCustomList: any[];

  constructor(
    public dialogRef: MatDialogRef<CampaignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    private uploadService: UploadService,
    private campaignService: CampaignService,
    public dialog: MatDialog
  ){}

  ngOnInit() {

    this.store = this.data.store;
    this.ext = this.data.campaign;
    this.links = {
      'menu': `${environment.orderAppUrl}/store/${this.store.url_slug}/`,
      'gift': `${environment.orderAppUrl}/store/${this.store.url_slug}/offers/`,
      'offer': `${environment.orderAppUrl}/store/${this.store.url_slug}/order-offers/`,
      'userSpecific':'https://pinvrf.com/*************'
    }

    let schedule_datetime = moment(this.ext.run_time ? this.ext.run_time : Date.now())
    const remainder = 15 - (schedule_datetime.minute() % 15);
    schedule_datetime = schedule_datetime.add(remainder, "minutes")
    console.log(schedule_datetime);

    let ext_schedule_date = moment(schedule_datetime).format('YYYY-MM-DD')
    let ext_schedule_time = moment(schedule_datetime).format('hh:mm A')
    console.log(ext_schedule_time);

    // this.schedule = this.data.campaign.schedule || 'later';
    this.totalCost = this.data.campaign.cost;
    if(this.data.campaign.image){
      this.imageStr = this.data.campaign.image;
    }
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.ext.id || ''),
      store:new UntypedFormControl(this.data.store.id, Validators.required),
      name: new UntypedFormControl({value: this.ext.name || '', disabled: this.data.readonly}, Validators.required),
      message: new UntypedFormControl({value: this.ext.message || '', disabled: this.data.readonly}, Validators.required),
      schedule: new UntypedFormControl({value: this.ext.schedule || 'later', disabled: this.data.readonly}, Validators.required),
      module_link: new UntypedFormControl({value: this.ext.module_link || '', disabled: this.data.readonly}),
      schedule_date: new UntypedFormControl({value: moment(ext_schedule_date).add(1, 'day').format('YYYY-MM-DD'), disabled: this.data.readonly}, Validators.required),
      schedule_time: new UntypedFormControl({value: ext_schedule_time, disabled: this.data.readonly}, Validators.required),
      status: new UntypedFormControl('SCHEDULED'),
      redirect_url: new UntypedFormControl({value: this.ext.redirect_url, disabled: this.data.readonly}),
      segment: new UntypedFormControl({value: this.ext.segment, disabled: this.data.readonly}, Validators.required),
      segment_audience: new UntypedFormControl({value: this.ext.segment_audience, disabled: this.data.readonly}),
      custom_list: new UntypedFormControl({value: this.ext.custom_list, disabled: this.data.readonly}),
    });

    this.generateTimeRange(schedule_datetime);
    this.cols = (window.innerWidth <= 400) ? 1 : 2;
    this.updateCostPerUser(this.ext.message);

    this.storeService.campaignSetting(this.store.id).subscribe(setting => {
      this.campaignSetting = setting;
    });

    this.campaignService.getCampaignCustomList(this.store.id).subscribe(list => {
      this.campaignCustomList = list;
    });
  }


  generateTimeRange(start){
    start = moment(start)

    let endOfDay = moment(start).endOf('day');
    let duration = moment.duration(endOfDay.diff(start))
    let hoursLeft = Math.floor(duration.asHours())


    const times = hoursLeft * 4; // 24 hours * 15 mins in an hour

    this.timeRange = new Array(times)
    for (let i = 0; i < times; i++) {
      const toPrint = moment(start)
        .add(15 * i, 'minutes')
      this.timeRange[i] = toPrint
    }
    this.scheduleTime = this.timeRange[0]
  }

  onDateTimeChange(){
    const schduleDate = this.form.get('schedule_date').value;
    const scheduleTime = this.form.get('schedule_time').value;
    const scheduleDateTime = moment(`${schduleDate} ${scheduleTime}`, 'YYYY-MM-DD hh:mm A');
    this.scheduleDatetime = scheduleDateTime;
    console.log(scheduleDateTime);
  }

  containsNonLatinCodepoints(s) {
    return /[^\u0000-\u00ff]/.test(s);
  }

  updateCost(value, totalUser){
    if(value){
      let text = value.replace(/[^\x00-\x7F]/g, "");
      this.form.get('message').setValue(text);
      var length = text.length;

      // if(this.form.get('module_link').value){
      //   length = length + this.form.get('module_link').value.length;
      // }
      let parts = Math.ceil(length / 160)

      let addOnPart = parts - 2 > 0 ? parts - 2 : 0
      let imagePart = this.image ? 1 : 0
      let imageCost = parts > 0 ? 0.02 : 0.05

      this.costPerUser = (0.05 + (addOnPart * 0.02) + (imagePart * imageCost))
      this.totalCost = this.costPerUser * totalUser;
      this.optedIn = totalUser;
    }
  }

  updateCostPerUser(value: string){
    if(this.containsNonLatinCodepoints(value)){
      this.alertPopup('Campaign message can contain only english and printable characters. Other characters have been stripped.');
      // alert('Campaign message can contain only english and printable characters. Other characters have been stripped.')
    }
    this.updateCost(value, this.data.stats.opted_in);
  }

  updateCostPerPart(value:string){
    if(this.form.get('custom_list').value) {
      this.form.get('segment').disable();
      this.form.get('segment_audience').disable();
    }

    if(!this.form.get('custom_list').value) {
      this.form.get('segment').enable();
      this.form.get('segment_audience').enable();
    }

    if(this.form.get('segment').value === 'ALL') {
      this.form.get('segment_audience').disable();
    }

    if(this.containsNonLatinCodepoints(value)){
      this.alertPopup('Campaign message can contain only english and printable characters. Other characters have been stripped.');
      // alert('Campaign message can contain only english and printable characters. Other characters have been stripped.')
    }
    if(value){
      const segment = this.form.get('segment').value || '';
      const segmentAudience = this.form.get('segment_audience').value || '';
      const customList = this.form.get('custom_list').value || '';
      this.storeService.getCampaignSegmentStats(this.store.id, segment, segmentAudience, customList).subscribe(stats => {
        this.updateCost(value, stats.total);
      });
    }
  }

  onScheduleChange(event){
    this.schedule = event.value
  }

  toURL = (input:string) =>{
    if (input && !input.startsWith('http://') && !input.startsWith('https://')) {
        input = 'https://' + input;
    }
    return input
}

  create(){
    this.isLoading = true;

    let files = new Set<File>();
    if(this.image && this.image.length > 0){
      files.add(this.image[0])
    }

    var payload = this.form.getRawValue();
    Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);
    console.log(payload);

    payload['cost'] = (+this.totalCost).toFixed(2);
    payload['audience'] = this.data.stats.opted_in
    payload['campaign_type'] = this.campaignType;
    payload['redirect_url'] = this.toURL(this.form.getRawValue()['redirect_url'])


    if(this.schedule == 'now'){
      payload['run_time'] = moment().toISOString()
    }else{
      let scheduledDate = moment(payload.schedule_date).subtract(1, 'day').format('YYYY-MM-DD'); // Subtract 1 day here
      let scheduleDateTime = moment(scheduledDate+" "+payload.schedule_time, "YYYY-MM-DD hh:mm A").toISOString()
      payload['run_time'] = scheduleDateTime
    }

    let progress = this.uploadService.upload(`${environment.apiUrl}/backend/v2/campaign/`, files, payload);

    forkJoin(Object.values(progress).map(p => p.progress)).subscribe(
      data => console.log(data),
      err=>console.log(err),
      () => this.dialogRef.close())


    // if(payload.id){
    //   this.storeService.updateCampaign(payload).subscribe(data => {
    //     this.dialogRef.close()
    //   })
    // }else{
    //   this.storeService.createCampaign(payload).subscribe(data => {
    //     this.dialogRef.close()
    //   })
    // }
  }

  setImageStr(image){
    console.log(image);

    this.imageStr = image;
  }

  setImageFile(image){
    console.log(image);

    this.image = image;
  }

  setCampaignType(type){
    this.campaignType=type
  }

  canEdit(){
    console.log(this.difference);
    return this.ext.id && this.difference > 60;
  }

  setDifference(difference){
    this.difference = difference;
  }

  alertPopup(msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


}
