<div class="flex flex-col gap-3 h-full items-center py-8 p-[20px]">
  <p class="text-4xl text-gray-700" *ngIf="calculatedTotal >= 0">Change Due:</p>
  <p class="text-4xl text-gray-700 my-5" *ngIf="calculatedTotal < 0">Remaining Balance:</p>

  <p class="text-6xl mt-2" *ngIf="data?.split_type != 'GIFT' && data?.split_type != 'KEYED'"> {{absTotal | currency}}</p>
  <p class="text-6xl mt-2" *ngIf="data?.split_type == 'GIFT' || data?.split_type == 'KEYED'"> {{totalDueAmount | currency}}</p>

  <button *ngIf="!store.feature_flag['manual_print_standard_checkout']" mat-raised-button class="doneButton top-5 w-[50%] xs:w-full"
          [ngClass]="{'bg-gray-300': isLoading, 'lab-bg-blue': !isLoading}" (click)="doneClick()"
          [disabled]="isLoading">
    Done
  </button>
  <app-manual-print-control
    *ngIf="store.feature_flag['manual_print_standard_checkout']"
    [manualData]="manualData" (selectionEvent)="doneClick()"
    class="mx-5 w-full"></app-manual-print-control>
</div>

