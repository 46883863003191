import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OccasionDetail} from '../_models/order';


@Injectable()
export class OccasionDetailService{
  private source = new BehaviorSubject<OccasionDetail>(null);
  current = this.source.asObservable();

  constructor(){}

  updateOccasionDetail(occasionDetail: OccasionDetail) {
    if(occasionDetail){
      this.source.next(occasionDetail);
    }
  }
}
