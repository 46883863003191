import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';


@Component({
  selector: 'app-mod-groups',
  templateUrl: './mod-groups.component.html',
  styleUrls: ['./mod-groups.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ModGroupsComponent implements OnInit {
  @Input() store: Store;
  @Output() refresh = new EventEmitter();

  dataSource: MatTableDataSource<any>;
  expandedElement = new Map<string, any>();

  order
  isLoading = true
  modGroupOrdered
  titleDict = {};
  orderedMgIds;

  searchTerm:string;
  private searchModelChanged: Subject<string[]> = new Subject<string[]>();
  private searchSub: Subscription;
  debounceTime = 500;
  searchResults;
  availabilityFilter;
  filterValue:string;
  isTouchDevice: boolean;
  openedPanel: any;
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  constructor(private menuService: MenuServiceV2,
    public storeService: StoreService,
    private snackBar: MatSnackBar, private cdr: ChangeDetectorRef) {
      this.isTouchDevice = this.checkIfTouchDevice(); 

  }

  checkIfTouchDevice(): boolean {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }
  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.refreshModGroup();
      }
    });

    this.searchSub = this.searchModelChanged
      .pipe(debounceTime(this.debounceTime),)
      .subscribe(() => {
        this.searchModifiers();
      });
  }

   onPanelOpen(panel: MatExpansionPanel, modGroup: any) {
    this.isLoading = true;
    if (this.openedPanel && this.openedPanel !== modGroup) {
      const previouslyOpenedPanel = this.panels.find(p => p.expanded && p !== panel);
      if (previouslyOpenedPanel) {
        previouslyOpenedPanel.close();
      }
      this.cdr.detectChanges();
    }
    this.openedPanel = modGroup;
    this.isLoading = false;

    this.cdr.detectChanges();
  }
  onPanelClose(modGroup: any) {
    console.log('Panel closed for', modGroup);
    this.openedPanel = null;
  }

  search(e){
    console.log(e);
    this.filterValue = e;
    this.searchModelChanged.next(e);
  }

  refreshModGroup(){
    this.isLoading = true;
    this.menuService.getModGroup(this.store.id).subscribe(data => {
      this.modGroupOrdered = data;
      let sortedData = Object.values(data).sort((a, b) => a.order - b.order);
      this.orderedMgIds = sortedData.map(item => item.id.toString());
      this.isLoading = false;
    });
  }

  searchModifiers(){
    console.log(this.searchTerm, this.availabilityFilter);
    this.menuService.searchModifiers(this.store.id, this.searchTerm || '',
    this.availabilityFilter).subscribe(searchResults => {
      console.log(searchResults);
      this.searchResults = searchResults;
    });
  }

  setModGroup(event){
    this.modGroupOrdered[event.id].name = event.name
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    let a = event.container.data;
    let intArray: number[] = a.map(Number);
    this.menuService.updateOrder(this.store.id, JSON.stringify({'type': 'modifier_group', 'order': intArray })).subscribe(data => {
      this.ngOnInit();
    })
  }
}
