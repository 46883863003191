import {MenuItemType, ModifierOptionType} from './menu-v2';
import {MenuItem} from './order';

export class Modifier{
  name: string;
  modifier_id: number;
  fixed_price: boolean;
}

export class DraftModifierOption{
  modifier: ModifierOptionType;
  quantity: number;
  mod_type: string;

  constructor(modifier: ModifierOptionType, quantity: number, modType: string){
    this.modifier = modifier;
    this.quantity = quantity;
    this.mod_type = modType;
  }
}

export class DraftItem{
  menu_item_id: number;
  menu_item: MenuItemType
  sale_price: number;
  name: string;
  quantity: number;
  special_request: string;
  modifiers: DraftModifierOption[] = [];
  position: number;

  constructor(menuItem: MenuItemType, quantity: number, specialRequest: string){
    this.menu_item = menuItem;
    this.quantity = quantity;
    this.special_request = specialRequest;
  }

  getItemTotal(){
    let itemTotal = 0
    itemTotal = +this.menu_item.base_price * this.quantity;
    for(const mod of this.modifiers) {
      itemTotal = itemTotal + (+mod.modifier.upcharge * this.quantity);
    }
    return itemTotal;
  }
}

export class DraftMiscItem{
  is_taxable: boolean;
  item_name: string;
  price: number;
  quantity: number;

  constructor(item: any) {
    this.is_taxable = item.is_taxable;
    this.item_name = item.item_name;
    this.price = item.price;
    this.quantity = item.quantity;
  }

  getTotal(){
    return this.price * this.quantity;
  }
}

export class DraftOccasion{
  occasion: string;
  time: string;
  dine_in_table_number: string;
  schedule_date: string;
  schedule_time: string;
  curbside_vehicle_id: string;
  phone_number: string;
  specialRequest: string;
  occasion_schedule: string;
  delivery_address: string;
  order_name: string;

  constructor(formValue: any){
    this.occasion = formValue.occasion;
    this.time = formValue.time;
    this.dine_in_table_number = formValue.dine_in_table_number;
    this.schedule_date = formValue.schedule_date;
    this.schedule_time = formValue.schedule_time;
    this.curbside_vehicle_id = formValue.curbside_vehicle_id;
    this.phone_number = formValue.phone_number;
    this.specialRequest = formValue.specialRequest;
    this.occasion_schedule = formValue.occasion_schedule;
    this.delivery_address = formValue.delivery_address;
    this.order_name = formValue.order_name;
  }
}

export class Tip{
  tip_percent: number;
  tip_value: number;
  is_custom: boolean;
}

export class InstantDiscount{
  type: string;
  description: string;
  promotional_discount: number;
}

export class DraftOrder{
  name: string;
  phone: string;
  items: DraftItem[] = [];
  tip: Tip;
  speciial_request: string;
  offer_id: number;
  support_local_percent: number;
  misc_items: DraftMiscItem[] = [];
  occasion: DraftOccasion;
  service_charge: number;
  promo_code: string;
  terminal_id: string;
  cashier_id: string;
  instant_discount: InstantDiscount;
  offer_discount: number;
  cash_discount: number;

  getFormattedPhoneNumber(){
    if(this.phone && this.phone.length === 10){
      return this.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }else{
      return '';
    }

  }

  updatePhone(phone: string){
    console.log(phone);
    this.phone = phone.replace(/\D/g, '');
  }

  getSubtotal(): number {
    let subtotal = 0;
    if (this.items != null) {
      this.items.forEach(item => {
        subtotal += item.getItemTotal();
      });
    }
    if(this.misc_items != null){
      this.misc_items.forEach(item => {
        subtotal += item.getTotal();
      });
    }
    return subtotal;
  }

  getInstantDiscount(){
    if(this.instant_discount){
      return this.instant_discount.promotional_discount;
    }
    return 0;
  }

  getTipValue(){
    if(this.tip){
      if(this.tip.is_custom){
        return this.tip.tip_value;
      }else{
        return this.getSubtotal() * (this.tip.tip_percent / 100);
      }
    }
    return 0;
  }

  getServiceChargeValue(){
    const subTotal = this.getSubtotal();
    return subTotal * (this.service_charge / 100);
  }

  getOfferDiscount(){
    return this.offer_discount;
  }

  updateDraftItemQuantity(menuItemId: number, newQuantity: number) {
    const item = this.items.find(i => i.menu_item_id === menuItemId);
    if (item) {
      item.quantity = newQuantity;
      return true;
    }
    return false; // Return false if the item was not found
  }
}
