import {ErrorHandler, Injectable} from '@angular/core';
import {ErrorLoggingService} from '../_services/error-logging.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

  constructor(private logger: ErrorLoggingService) {
    super();
  }

  handleError(error) {
    this.logger.logError(error);
    super.handleError(error);
  }
}
