<span *ngIf="formDisplayed">
  <form [formGroup]="paymentForm"class="history-refund" (ngSubmit)="checkPermissions()" *ngIf="txn.action != 'CANCELLED'">
    <div class="flex gap-2 items-center justify-start">
      <div class="flex-initial w-52	 refunded-table">
        <table class="refund-breakup">
          <tr>
            <td>Total Refunded</td>
            <td>{{txn.refunded_amount || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Available Balance</td>
            <td>{{txn.getAvailableBalance() | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="txn.action == 'ADJUST' || txn.action == 'TIP_ADJUST' || txn.action == 'REFUND' || txn.action == 'VOID' || txn.action == 'REFUND SUBMITTED' || txn.action == 'VOID SUBMITTED' || txn.action == 'PAY_LATER'">
            <td>User:</td>
            <td>{{txn.cashier_name}}</td>
          </tr>
          <tr *ngIf="txn.action == 'ADJUST' || txn.action == 'TIP_ADJUST' || txn.action == 'REFUND' || txn.action == 'VOID' || txn.action == 'REFUND SUBMITTED' || txn.action == 'VOID SUBMITTED' || txn.action == 'PAY_LATER'">
            <td>Note for Adjustment:</td>
            <td>{{txn.note}}</td>
          </tr>
        </table>
      </div>
      <div class="flex-initial mb-2">
        <mat-button-toggle-group *ngIf="txn.action != 'REFUND' && txn.action != 'VOID' && txn.action != 'REFUND SUBMITTED' && txn.action != 'VOID SUBMITTED' && txn.action != 'PAY_LATER'" formControlName="payment_type"
          (change)="onPaymentTypeChange($event.value)" class="payment-ops">
          <mat-button-toggle value="REFUND" class="neg">Partial Refund</mat-button-toggle>
          <mat-button-toggle value="FULL_REFUND" class="neg">Full Refund</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
        <div class="btn-amount-submit">
        <mat-form-field  *ngIf="txn.action != 'REFUND' && txn.action != 'VOID' && txn.action != 'REFUND SUBMITTED' && txn.action != 'VOID SUBMITTED' && txn.action != 'PAY_LATER'" appearance="fill" class="mt-3 amount-money" subscriptSizing="dynamic">
          <mat-label>Amount</mat-label>
          <input matInput placeholder="Amount" formControlName="amount" type="number">
          <mat-icon matSuffix>attach_money</mat-icon>
          <mat-hint>{{paymentHint}}</mat-hint>
        </mat-form-field>
        <mat-form-field  *ngIf="txn.action != 'REFUND' && txn.action != 'VOID' && txn.action != 'REFUND SUBMITTED' && txn.action != 'VOID SUBMITTED' && txn.action != 'PAY_LATER'" appearance="fill" class="mt-3 add-record" subscriptSizing="dynamic">
          <mat-label>Add note for record</mat-label>
          <input matInput placeholder="Add note for record" formControlName="note">
          <mat-hint></mat-hint>
        </mat-form-field>
        <span *ngIf="!store?.hasModule('POS') && txn.action != 'REFUND' && txn.action != 'VOID' && txn.action != 'REFUND SUBMITTED' && txn.action != 'VOID SUBMITTED' && txn.action != 'PAY_LATER'">
          <mat-form-field appearance="fill" class="mt-3 add-record">
            <mat-label>Cashier Name</mat-label>
            <input matInput placeholder="Cashier name" formControlName="cashier_name">
          </mat-form-field>
        </span>
        <button *ngIf="txn.action != 'REFUND' && txn.action != 'VOID' && txn.action != 'REFUND SUBMITTED' && txn.action != 'VOID SUBMITTED' && txn.action != 'PAY_LATER'" mat-flat-button color="primary" type="submit" class="submit"
          [disabled]="isPaymentLoading || !paymentForm.valid || txn.getAvailableBalance() <= 0">Submit</button>
      </div>
    </div>
    <div class="row">
      <p *ngIf="paymentFailed" class="error">Unable to process payment. </p>
      <p *ngIf="paymentSuccess" class="success">Payment processed successfully.</p>
    </div>
  </form>

  <div *ngIf="txn.action == 'CANCELLED'" class="cancelled_text">
    Cancelled Reason: {{order.bot_order.cancel_reason || 'None'}}
  </div>
</span>