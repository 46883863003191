import {Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {ScreenMediaDialogComponent} from './screen-media-dialog/screen-media-dialog.component';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

export interface PeriodicElement {
  name: any;
  row: any;
  image: any;
}
@Component({
  selector: "app-screen-media",
  templateUrl: "./screen-media.component.html",
  styleUrls: ["./screen-media.component.scss"],
})
// @Pipe({ name: "safe" })
export class ScreenMediaComponent implements OnInit {
  displayedColumns: string[] = ["name", "row", "image", "action"];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  storeId: any;
  screenData: any;
  endpoint: any;
  showUrl: any = [];
  orderUrl;
  orderUrls;
  // public urlsUrl = [];
  public elementSrc = [];
  ertertr: any;
  src;
  @ViewChild("iframe11",{static: false}) iframe11: ElementRef;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private digitalDisplayService: DigitalDisplayService
  ) {}
  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.storeId = localStorage.getItem("currentStore");
    this.getMediaList();
  }

  addMedia() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ScreenMediaDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        // data: element,
        dialog: 1,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {

      if (result && result.yesSubmit == "YesSubmit") {
        this.getMediaList();

      }
    });
  }

  getMediaList() {
    this.digitalDisplayService
      .ImageMediaListGet(this.storeId)
      .subscribe((data: any) => {
        this.dataSource = data.data;
        this.dataSource.paginator = this.paginator;
      });
  }
  editImage(element) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ScreenMediaDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        data: element,
        dialog: 2,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMediaList();
      }
    });
  }

  deleteImage(element) {
    let dialogRef = this.dialog.open(ScreenMediaDialogComponent, {
      data: {
        dialog: 3,
        data: element,

      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMediaList();
      }
    });
  }
}
