import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-messages-view',
  templateUrl: './messages-view.component.html',
  styleUrls: ['./messages-view.component.scss']
})
export class MessagesViewComponent implements OnInit, OnChanges {

  constructor(private storeService:StoreService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  public get width() {
    return window.innerWidth;
  }

  @Input() messageGroup;
  @Input() previousMessage;
  @Input() currentMessage;
  @Input() selectedUser;
  @Output() sendMessage = new EventEmitter<any>();
  @Output() deleteUser = new EventEmitter<any>();
  @Input() editUserSideNav;
  @Input() isLoading;
  @Input() contactListSideNav;

  selectedUserName(){
    if(this.selectedUser && this.selectedUser.user){
      if(this.selectedUser.user.name){
        return this.selectedUser.user.name;
      }else{
        return "*"+this.selectedUser.phone_number.substring(this.selectedUser.phone_number.length - 5)
      }
    }
  }

  onSendMessage(message: string) {
    let payload = {"message": message,
      "user_type": "STORE",
      "phone_number": this.messageGroup.phone_number}
    this.sendMessage.emit(payload);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

    if(changes.selectedUser){
      this.messageGroup = changes.selectedUser.currentValue;
    }

    if(changes.previousMessage){
      this.previousMessage = changes.previousMessage.currentValue;
    }

    if(changes.currentMessage){
      this.currentMessage = changes.currentMessage.currentValue;
    }

  }

  delete(messageGroup){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are your sure to delete?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.storeService.deleteMessageGroup(messageGroup.store, messageGroup.id).subscribe(data => {
          this.deleteUser.emit(messageGroup)
        })
      }
    });
    // if(confirm("Are your sure to delete?")){
    //   this.storeService.deleteMessageGroup(messageGroup.store, messageGroup.id).subscribe(data => {
    //     this.deleteUser.emit(messageGroup)
    //   })
    // }
  }

  profile(user){
    this.editUserSideNav.toggle()
  }

  endConversation(user){
    console.log(user);

    this.storeService.endConversation(user.store, user.phone_number).subscribe(data => {
      let dialogRef = this.dialog.open(AlertsMessagesComponent, {
        disableClose: true,
        width: '364px',
        minHeight: '20vh',
        data: {
          message: 'Conversation control handed over to bot!'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
      // alert("Conversation control handed over to bot!")
    })
  }

  toggleContactList(){
    this.contactListSideNav.toggle()
  }

  onScrollUp(){
    console.log('scrolled up!!');
  }

}
