<div *ngIf="hostedMenus" class="image-container">
  <div *ngFor="let menu of hostedMenus" class="thumbnail">
      <a (click)="delete(menu)" style="cursor: pointer"><mat-icon style=" float: right;margin-left: 170px; margin-top:5px;position: absolute;">delete</mat-icon></a>
      <img [src]="menu.upload_file">
  </div>
  <div style="clear: both"></div>
</div>

<input
type="file"
#file
style="display: none"
(change)="onFilesAdded()" multiple="true"/>

<div class="container overflow-hidden" fxLayout="column" fxLayoutAlign="space-evenly stretch">
<h1 mat-dialog-title>Select menu image files</h1>


<!-- This is the content of the dialog, containing a list of the files to upload -->

  <mat-list>
    <mat-list-item *ngFor="let file of files">
      <h4 mat-line>{{file.name}}</h4>
      <mat-progress-bar
        *ngIf="progress"
        mode="determinate"
        [value]="progress[file.name].progress | async"
      ></mat-progress-bar>
    </mat-list-item>
  </mat-list>


<!-- This are the actions of the dialog, containing the primary and the cancel button-->
<div class="actions flex flex-row gap-2">
  <div>
    <button
      [disabled]="uploading || uploadSuccessful || files.size > 0"
      mat-flat-button
      color="primary"
      class="add-files-btn apply-btn-colr"
      (click)="addFiles()">
      Select File
    </button>
  </div>
  <button
    mat-flat-button
    mat-stroked-button
    color="primary"
    [ngClass]="{'apply-btn-colr': canBeClosed}"
    [disabled]="!canBeClosed"
    (click)="closeDialog()">
    {{primaryButtonText}}
  </button>
</div>

</div>
