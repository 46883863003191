import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {first, takeUntil} from 'rxjs/operators';
import {OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {PaymentService} from 'src/app/_services/payment.service';
import {POSService} from 'src/app/_services/pos-service';
import {POSStreamService} from 'src/app/_services/pos-stream.service';
import {AdjustDialogComponent} from '../adjust-dialog/adjust-dialog.component';
import {CardPaymentMqttComponent} from '../card-payment-mqtt/card-payment-mqtt.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-add-tip-dialog',
  templateUrl: './add-tip-dialog.component.html',
  styleUrls: ['./add-tip-dialog.component.scss']
})
export class AddTipDialogComponent implements OnInit, OnDestroy {

  form;
  orderId: number;
  orderWithPayment: OrdersWithPayment;
  loading = false;
  submitted = false;
  error = '';
  store;

  splits;
  terminalId;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<AdjustDialogComponent>,
    private posService: POSService,
    private orderService: OrderService,
    private _snackBar: MatSnackBar,
    private posStreamService: POSStreamService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orderId = this.data.botOrder.id;
    this.store = this.data.store;
  }

  ngOnInit() {
    if (this.isSplit()) {
      this.paymentService.getSplitTransactions(this.data.botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(splits => {
        this.splits = splits;
        this.form = this.formBuilder.group({
          splits: this.formBuilder.array(this.splits.filter(s => s.payment_type === 'CREDIT').map(s =>
            this.formBuilder.group({
              split_id: [s.id],
              terminal_id: [localStorage.getItem('selectedTerminal')],
              amount: [null, Validators.required],
              name_on_card: [s.name_on_card],
              last_4: [s.last_4],
              split_amount: [s.split_amount],
              is_complete: [false],
              error: [false],
              error_message: ['']
            })))
        });
      });
    } else {
      this.form = this.formBuilder.group({
        tip: [null, Validators.required]
      });
    }
    this.terminalId = localStorage.getItem('selectedTerminal');
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  isSplit() {
    return this.data.botOrder.is_paid_by_split;
  }

  onCloseClick(): void {
    this.dialogRef.close([]);
  }

  onSubmit() {
    if (this.data.botOrder.is_paid_by_split) {
      this.onCloseClick();
    }
    console.log(this.data);
    this.submitted = true;
    this.loading = true;

    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      const payload = {
        command: 'SALE',
        action: 'TIP',
        order_id: `${this.data.botOrder.order_hash}-tip`,
        terminal_id: localStorage.getItem('selectedTerminal'),
        amount: this.form.value.tip
      };
      const dialogRef = this.dialog.open(CardPaymentMqttComponent, {
        width: '630px',
        data: payload,
        disableClose: true
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        if (result) {
          this.loading = false;
          this.onCloseClick();
        }
      });
    } else {
      const payload = this.form.value;
      payload.bot_order_id = this.data.botOrder.id;
      let o;
      if (this.data.botOrder.is_pos && this.data.botOrder.source !== 'POS_MANUAL_CREDIT') {
        o = this.posService.posAddTip(this.data.terminalId, this.data.botOrder.order_hash, payload.tip);
      } else if (this.store.payment_gateway === 'CARDCONNECT') {
        o = this.paymentService.adjustTip(this.form.value);
      } else {
        o = this.paymentService.addTipGateway(this.form.value);
      }

      o.pipe(first()).pipe(takeUntil(this.destroySubject))
      .subscribe(
        data => {
          this.loading = false;
          console.log(JSON.stringify(data));
          this.onCloseClick();
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    }
  }

  addTip(split) {
    console.log(split);
    this.loading = true;
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      const payload = split.value;
      console.log(payload);
      payload.action = 'TIP_SPLIT';
      payload.command = 'SALE';
      payload.order_id = `${this.data.botOrder.order_hash}-${split.value.split_id}-tip`;
      const dialogRef = this.dialog.open(CardPaymentMqttComponent, {
        width: '630px',
        data: payload,
        disableClose: true
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        if (result) {
          this.posStreamService.tipSplitOrder(this.terminalId, payload).pipe(takeUntil(this.destroySubject)).subscribe(r => {
            split.value.is_complete = true;
            this._snackBar.open('Tip approved', 'OK', {
              duration: 1000,
            });
            this.loading = false;
          });
        } else {
          split.value.is_complete = false;
          this.loading = false;
        }
      });
    } else {
      this.orderService.addTipSplitTransaction(this.data.botOrder.order_hash, split.value)
        .pipe(takeUntil(this.destroySubject)).subscribe((d: any) => {
        split.value.is_complete = true;
        this._snackBar.open(d.message, 'OK', {
          duration: 1000,
        });
        this.loading = false;
      }, error => {
        console.log(error.message);
        split.value.error_message = error.message;
        this.loading = false;
        split.value.error = true;
        console.log(split);
      });
    }
  }

}
