import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CampaignService} from '../../_services/campaign.service';
import {StoreService} from '../../_services/store.service';

@Component({
  selector: 'app-campaign-progress',
  templateUrl: './campaign-progress.component.html',
  styleUrls: ['./campaign-progress.component.scss']
})
export class CampaignProgressComponent implements OnInit, OnDestroy {

  color = 'primary';
  mode = 'indeterminate';

  @Input() campaignId;
  @Input() stats;
  @Input() startTime;

  value = 50;
  bufferValue = 75;

  interval;

  constructor(private campaignService: CampaignService,
              private storeService: StoreService) { }

  ngOnInit() {
    if (this.campaignId) {
      this.getProgress();
      console.log(this.startTime);
      const isToday = new Date(this.startTime).toDateString() === new Date().toDateString();
      console.log(isToday);
      if (isToday) {
        this.interval = setInterval(() => {
          this.getProgress();
        }, 30000);
      }
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getProgress() {
    this.campaignService.getCampaignProgress(this.campaignId).subscribe(data => {
      this.mode = 'determinate';
      console.log(data);
      if (data.sent === 0) {
        this.value = 100;
      } else {
        this.value = Math.round((data.sent / this.stats.opted_in) * 100);
      }
    });
  }

}
