import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  endpoint = environment.apiUrl;
  analyticsEndpoint = `${this.endpoint}/backend/business/analytics`;

  constructor(private http: HttpClient) { }

  getOrderVolume(storeId:string, time: string, orderType:string, startDate: any, endDate: any,startTime: any, endTime:any, customGroup: any) {
    const params = new HttpParams().set('store_id', storeId).set('group', time).set('order_type', orderType).set('start_date', startDate).set('end_date', endDate).set('start_time', startTime).set('end_time', endTime).set('custom_group', customGroup)
    return this.http.get(this.analyticsEndpoint + '/order_volume_net_sales/', { params })
  }

  getCustomerTraffic(storeId:string, time: string, orderType:string, isPos:any, startDate: any, endDate: any, startTime:any, endTime:any,customGroup:any) {
    const params = new HttpParams().set('store_id', storeId).set('group', time).set('order_type', orderType).set('is_pos', isPos).set('start_date', startDate).set('end_date', endDate).set('start_time', startTime).set('end_time', endTime).set('custom_group', customGroup)
    return this.http.get(this.analyticsEndpoint + '/customers_traffic/', { params })
  }

  getAverageTicketSales(storeId:string, time: string, orderType:string, startDate: any, endDate: any ,startTime: any, endTime:any, customGroup: any) {
    const params = new HttpParams().set('store_id', storeId).set('group', time).set('order_type', orderType).set('start_date', startDate).set('end_date', endDate).set('start_time', startTime).set('end_time', endTime).set('custom_group', customGroup)
    return this.http.get(this.analyticsEndpoint + '/average_ticket_sales/', { params })
  }

  getLaborCostPercent(storeId:string, time: string, orderType:string, startDate: any, endDate: any,startTime: any, endTime:any, customGroup: any) {
    const params = new HttpParams().set('store_id', storeId).set('group', time).set('order_type', orderType).set('start_date', startDate).set('end_date', endDate).set('start_time', startTime).set('end_time', endTime).set('custom_group', customGroup)
    return this.http.get(this.analyticsEndpoint + '/labor_cost_percentage/', { params })
  }

  getTotalUsers(storeId:string, time: string, orderType:string, startDate: any, endDate: any, startTime: any, endTime:any) {
    const params = new HttpParams().set('store_id', storeId).set('group', time).set('order_type', orderType).set('start_date', startDate).set('end_date', endDate).set('start_time', startTime).set('end_time', endTime)
    return this.http.get(this.analyticsEndpoint + '/total_users/', { params })
  }

}
