import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Utils from 'src/app/utils';
import {MyErrorStateMatcher} from 'src/app/_helpers/error-matcher';
import {OrdersWithPayment} from 'src/app/_models/order';
import {Store} from 'src/app/_models/store';
import {OrderService} from 'src/app/_services/order.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-join-user-base',
  templateUrl: './join-user-base.component.html',
  styleUrls: ['./join-user-base.component.scss']
})
export class JoinUserBaseComponent implements OnInit, OnDestroy {

  store: Store;
  orderWithPayment: OrdersWithPayment;
  form: UntypedFormGroup;
  errorMatcher = new MyErrorStateMatcher();
  phoneNumber = '';
  isValidPhone = false;
  isUserUpdated = false;
  private destroySubject: Subject<void> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private orderService: OrderService,
              public dialogRef: MatDialogRef<JoinUserBaseComponent>, ) {
    this.store = this.data.store;
    this.orderWithPayment = this.data.orderWithPayment;

    if (!this.orderWithPayment.isPhoneDefault()) {
      if (this.orderWithPayment.bot_order.phone_number.length === 12) {
        this.phoneNumber = Utils.getFormattedPhoneNumber(this.orderWithPayment.bot_order.phone_number);
        this.isValidPhone = true;
      }
    } else {
      console.log(this.store.sms_number);
      if (this.store.sms_number.length === 12) {
        this.phoneNumber = Utils.getFormattedPhoneNumber(this.store.sms_number);
        this.isValidPhone = true;
      }
    }
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      phone_number: new UntypedFormControl(this.phoneNumber),
      is_pos: new UntypedFormControl(true),
      opt_in: new UntypedFormControl(true)
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  getPayload() {
    console.log(this.phoneNumber);

    const payload = this.form.value;
    payload.phone_number = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
    return payload;
  }

  updateUser(orderHash) {
    const payload = this.getPayload();
    this.orderService.updateUser(orderHash, payload).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.isUserUpdated = data.updated;
    });
  }

  sendTextReceipt(orderHash) {
    const payload = this.getPayload();
    this.orderService.sendTextReceipt(orderHash, payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.close();
    });
  }

  printPaperReceipt(orderHash) {
    const payload = this.getPayload();
    this.orderService.printPaperReceipt(orderHash, payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.close();
    });
  }

  updatePhoneNumber(phoneNumber: string) {
    if (Utils.getPlainPhoneNumberFromString(phoneNumber).length === 12) {
      this.phoneNumber = phoneNumber;
      this.isValidPhone = true;
    } else {
      this.isValidPhone = false;
    }
  }

  close() {
    this.dialogRef.close();
    this.data.orderWithPayment = null;
    this.data.occasionDetail = null;
    this.data.orderName = null;
    localStorage.removeItem('order_hash');
    this.data.total = 0;
    this.data.subTotal = 0;
    this.data.cashTotal = 0;
    this.orderService.orderDoneSubject.next();
  }

}
