import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DefaultScreenDialogComponent } from '../default-screen-dialog/default-screen-dialog.component';

export interface PeriodicElement {
  canvas: any;
  screen_id: any;
  content_type: any;
  screen_url: any;
}
@Component({
  selector: 'app-default-screen',
  templateUrl: './default-screen.component.html',
  styleUrls: ['./default-screen.component.scss']
})
export class DefaultScreenComponent {
  displayedColumns: string[] = [
    "canvas",
    "screen_id",
    "content_type",
    "action",
  ];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  storeId: any;
  storeArr: any;
  screenData: any;
  isLoading = false;
  endpoint: any;
  leaflogixendpoint: any;
  storeType: string;
  screenList: any;
  constructor(
    private digitalDisplayService: DigitalDisplayService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
  }
  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.leaflogixendpoint = environment.leaflogixApiEndpoint;
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");
    this.defaultScreenList();
    this.storeArr = environment.storeArr
  }

  defaultScreenList() {
    this.isLoading = true;
    this.digitalDisplayService.defaultScreenList().subscribe(data => {
      this.screenList = data.data
      this.dataSource = new MatTableDataSource<PeriodicElement>(
        this.screenList
      );
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    })
  }

  newScreenSet(data) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(DefaultScreenDialogComponent, {
      data: {
        view: 'comman',
        data: data
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        ;
      }
    });
  }

  // newScreenSet(data) {
  //   let keySend = {
  //     "screen_id": data.id,
  //     "store_id": this.storeId,
  //     "name": data.name
  //   }
  //   this.digitalDisplayService.newScreenSet(keySend).subscribe(data => {

  //   })
  // }
}
