<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<div class="flex p-5 flex-col">
  <button mat-button type="button" (click)="back()" class="!justify-start" tabindex="-1">
    <mat-icon class="bottom-0.5">arrow_back_ios_new</mat-icon>
    Back
  </button>
  <h1 class="text-[22px] font-normal pt-[5px]">Add card</h1>

  <div class="p-5">
    <mat-form-field class="example-full-width xs:!block" appearance="fill">
      <mat-label>Phone Number</mat-label>
      <input #phone appPhoneMask matInput autofocus [(ngModel)]="phoneNumber" placeholder="Add phone number"
        (input)="updatePhoneNumber();" [errorStateMatcher]="errorMatcher"
        [value]="orderWithPayment?.getFormattedPhoneNumber()" [disabled]="number_verified">
      <button *ngIf="phone.value" matSuffix mat-icon-button aria-label="Clear" (click)="phone.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button mat-flat-button *ngIf="!otp_field" color="primary" class="wallet-add-btn" [disabled]="!isValidPhone"
      (click)="addToWallet()">Add
      to Wallet</button>
    <button *ngIf="otp_field && !number_verified" mat-stroked-button color="primary" class="rounded-button"
      (click)="addToWallet()">Resend</button>
    <button *ngIf="number_verified" mat-stroked-button color="primary" class="rounded-button"
      (click)="changePhone()">Change</button>
  </div>

  <div class="xs:!mt-5 pl-5">
    <span *ngIf="otp_field && !number_verified">
      <mat-form-field class="example-full-width xs:!block" appearance="fill">
        <mat-label>Verification code</mat-label>
        <input autofocus required matInput placeholder="Verification code" [(ngModel)]="otp" type="number">
      </mat-form-field>
      &nbsp;
      <button mat-flat-button color="primary" class="verify-btn" (click)="verifyOTP()">Verify</button>
    </span>
  </div>

  <br />
  <div *ngIf="cardsOnFile" class="opt-container">
    <p class="card-title">Existing Payment Options</p>
    <div *ngFor="let card of cardsOnFile.cards" class="options">
      <div class="left">
        {{card.card_type | titlecase }} &bull;&bull;&bull;&bull; {{card.last_4}}
      </div>
      <div class="right">
        <button mat-flat-button (click)="useCard(card)" color="primary">Use Card</button>
        <span (click)="deleteCard(card)"> <mat-icon>delete_forever</mat-icon></span>

      </div>
      <div class="clear"></div>
    </div>
  </div>
  <button *ngIf="store?.payment_gateway === 'PAYROC'" mat-flat-button color="primary" class="one-timebtn"
    id="payroc-button" [disabled]="!isValidPhone">
    <span *ngIf="!number_verified">One Time Payment</span>
    <span *ngIf="number_verified">Make Payment & Add Card To Wallet</span>
  </button>

  <button *ngIf="(store?.payment_gateway === 'NMIPR' || store?.payment_gateway === 'NMI')" mat-flat-button
    color="primary" class="one-timebtn" id="payroc-button" (click)="openCollectJsLightBox($event)"
    [disabled]="!isValidPhone">
    <span *ngIf="!number_verified">One Time Payment</span>
    <span *ngIf="number_verified">Make Payment & Add Card To Wallet</span>
  </button>

  <button *ngIf="(store?.payment_gateway === 'PAYROC_CLOUD' || store?.payment_gateway === 'WORLDNET')"
          mat-flat-button color="primary" class="one-timebtn" id="payroc-cloud-button" (click)="redirectToWorldnet(!number_verified)"
          [disabled]="!isValidPhone">
    <span *ngIf="!number_verified">One Time Payment</span>
    <span *ngIf="number_verified">Make Payment & Add Card To Wallet</span>
  </button>

  <p class="failure" *ngIf="failure">Unable to complete the request. Sorry we could not process your order. Please
    update or change the payment type and resubmit your order.</p>
  <div class="opt-in-checkbox">
    <mat-checkbox [(ngModel)]="optIn">Opt-in to receive automated offers & messages</mat-checkbox>
  </div>
</div>
