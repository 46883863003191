<div [formGroup]="form" class="wdth">
  <div *ngFor="let editControlKey of editControlKeys">
    <div [ngSwitch]="myFormControl.controlType">
      <span *ngIf="(editControlKey == 'has_order_occasion_togo' || editControlKey == 'has_order_occasion_forhere') && store?.modules.includes('POS')">
        <div *ngIf="myFormControl.key == editControlKey">
          <div fxLayout="row" *ngSwitchCase="'checkbox'" class="colr">
            <div style="width: 25%;">
              <mat-checkbox [formControlName]="myFormControl.key" class="form-control colr"
                [checked]="store[editControlKey]" [(ngModel)]="store[editControlKey]"
                (change)="store[editControlKey] = !store[editControlKey]">{{myFormControl.label}}
                
              </mat-checkbox>
            </div>
            <div class="avaibility-text ">
              <span>:</span>
              <button mat-button color="primary" (click)="setOccasionAvaibility(myFormControl.label)" class="fromtop colr">Set Custom Avaibility</button>
            </div>
          </div>

          <mat-form-field *ngSwitchCase="'textbox'" style="width: 60%">
            <input type="text" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="store[editControlKey]" autofocus [placeholder]="myFormControl.label"
              [spellcheck]="myFormControl.spellcheck" />
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'key-textbox'" style="width: 60%">
            <input type="text" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="getNestedValue(editControlKey)" autofocus [placeholder]="myFormControl.label"
              [spellcheck]="myFormControl.spellcheck" />
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'textarea'" style="width: 100%">
            <input [formControlName]="myFormControl.key" class="form-control" matInput [value]="store[editControlKey]"
              autofocus [placeholder]="myFormControl.label" [spellcheck]="myFormControl.spellcheck">
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'phone'" style="width: 60%">
            <input type="text" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="store[editControlKey] || '' | phone" autofocus [placeholder]="myFormControl.label" appPhoneMask />
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'number'" style="width: 35%">
            <mat-label value="[editControlKey]">{{editControlKey}}</mat-label>
            <input type="number" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="store[editControlKey]" autofocus [placeholder]="myFormControl.label">
          </mat-form-field>&nbsp;
          <span *ngIf="dialogConfig.suffix[editControlKey]">{{dialogConfig.suffix[editControlKey]}}</span>
        </div>
      </span>  
      <span *ngIf="editControlKey != 'has_order_occasion_togo' && editControlKey != 'has_order_occasion_forhere'">
        <div *ngIf="myFormControl.key == editControlKey">
          <div fxLayout="row" *ngSwitchCase="'checkbox'" class="colr">
            <div style="width: 25%;">
              <mat-checkbox [formControlName]="myFormControl.key" class="form-control colr"
                [checked]="store[editControlKey]" [(ngModel)]="store[editControlKey]"
                (change)="store[editControlKey] = !store[editControlKey]">{{myFormControl.label}}
                
              </mat-checkbox>
            </div>
            <div class="avaibility-text ">
              <span>:</span>
              <button mat-button color="primary" (click)="setOccasionAvaibility(myFormControl.label)" class="fromtop colr">Set Custom Avaibility</button>
            </div>
          </div>
  
          <mat-form-field *ngSwitchCase="'textbox'" style="width: 60%">
            <input type="text" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="store[editControlKey]" autofocus [placeholder]="myFormControl.label"
              [spellcheck]="myFormControl.spellcheck" />
          </mat-form-field>
  
          <mat-form-field *ngSwitchCase="'key-textbox'" style="width: 60%">
            <input type="text" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="getNestedValue(editControlKey)" autofocus [placeholder]="myFormControl.label"
              [spellcheck]="myFormControl.spellcheck" />
          </mat-form-field>
  
          <mat-form-field *ngSwitchCase="'textarea'" style="width: 100%">
            <input [formControlName]="myFormControl.key" class="form-control" matInput [value]="store[editControlKey]"
              autofocus [placeholder]="myFormControl.label" [spellcheck]="myFormControl.spellcheck">
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'phone'" style="width: 60%">
            <input type="text" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="store[editControlKey] || '' | phone" autofocus [placeholder]="myFormControl.label" appPhoneMask />
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'number'" style="width: 35%">
            <mat-label value="[editControlKey]">{{editControlKey}}</mat-label>
            <input type="number" [formControlName]="myFormControl.key" class="form-control" matInput
              [value]="store[editControlKey]" autofocus [placeholder]="myFormControl.label">
          </mat-form-field>&nbsp;
          <span *ngIf="dialogConfig.suffix[editControlKey]">{{dialogConfig.suffix[editControlKey]}}</span>
        </div>
      </span>
    </div>
  </div>
</div>
