import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {TimeClockDataSource} from 'src/app/_datasources/time-clock-datasource';
import {TimeClockFilteredDataSource} from 'src/app/_datasources/time-clock-filtered-datasource';
import {AllTimeClockDataSource} from 'src/app/_datasources/time-clock-users-datasource';
import {Store} from 'src/app/_models/store';
import {tap} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {StoreService} from 'src/app/_services/store.service';
import {UserService} from 'src/app/_services/user.service';
import * as moment from 'moment';
import {EditTimeComponent} from '../edit-time/edit-time.component';
import {EditTimeClockComponent} from '../edit-time-clock/edit-time-clock.component';
import {PermissionService} from '../../_services/permission.service';
import {Permission} from '../../_models/permission';
import {EditBreakTimeComponent} from '../edit-break-time/edit-break-time.component';
import {AddTimeClockComponent} from '../add-time-clock/add-time-clock.component';
import {DatePipe} from '@angular/common';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-time-report',
  templateUrl: './time-report.component.html',
  styleUrls: ['./time-report.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class TimeReportComponent implements AfterViewInit, OnInit, OnDestroy {
  displayedColumns: string[] = ['status', 'team-member', 'created_at', 'clocked_in_time', 'clocked_out_time', 'number_of_breaks',
  'break_time', 'total_time', 'total_cost', 'action', ];
  displayedFilterColumns: string[] = ['team-member', 'number_of_days', 'total_breaks', 'total_break_time', 'filtered_total_time',
  'filtered_total_cost'];
  displayedAllColumns: string[] = ['team-member', 'created_at', 'clocked_in_time', 'clocked_out_time', 'number_of_breaks',
  'break_time', 'total_time', 'total_cost', 'action', ];
  dataSource: TimeClockDataSource;
  filteredDataSource: TimeClockFilteredDataSource;
  allDataSource: AllTimeClockDataSource;
  pageNumber = 0;
  pageSize = 10;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  store:Store;
  permission: Permission;
  search;
  startDate;
  endDate;
  selectedRow: boolean;
  interval;
  payload;
  private unsubscribe: Subject<null> = new Subject();
  @Output() openSideNav: EventEmitter<boolean> = new EventEmitter();
  terminalId = localStorage.getItem("selectedTerminal");
  isLoading: boolean = true
  timeclockorSession: any;

  constructor(
    private storeService:StoreService,
    private userService:UserService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private permissionService: PermissionService,
    private datePipe: DatePipe
  ) {
    this.userService.isLoaderOff.subscribe(result => {
      this.isLoading = result;
    });
   }

  ngOnInit() {
    const terminal_id = localStorage.getItem("selectedTerminal");
    this.terminalId = terminal_id;
    this.dataSource = new TimeClockDataSource(this.userService);
    this.filteredDataSource = new TimeClockFilteredDataSource(this.userService);
    this.allDataSource = new AllTimeClockDataSource(this.userService);
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store)
        this.loadTimeClocks();
        if(!this.interval){
          this.interval = setInterval(() => {
            this.loadTimeClocks();
          }, 5000);
        }
      }
    })
    this.permissionService.current.subscribe((permission) => {
      this.permission = permission;
    });
    this.isLoading = false;
  }

  ngOnDestroy(){
    console.log('destroy - reporting');
    if(this.interval){
      clearInterval(this.interval);
    }
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadTimeClocks()))
      .subscribe();
  }

  // loadTimeClocks() {
  //   const currentDate = new Date();
  //   const dateFormat = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
  //   this.dataSource.loadTotalClocks(+this.store.id, this.search,
  //     this.sort.active || 'status_counter',
  //     this.sort.direction || 'desc', this.paginator.pageIndex, this.paginator.pageSize, dateFormat, dateFormat);
  // }
     loadTimeClocks() {
    const currentDate = new Date();
    const dateFormat = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    if (this.sort && this.paginator) {
      this.dataSource.loadTotalClocks( +this.store.id, this.search,
     
      this.sort.active || 'status_counter',
      this.sort.direction || 'desc',this.paginator.pageIndex,this.paginator.pageSize,dateFormat,dateFormat
      );
    }
  }

  searchTimeClock(any: any) {
    this.isLoading = true;
    this.filteredDataSource.loadFilteredTotalClocks(+this.store.id, this.search, this.startDate, this.endDate, 
      this.sort.active || 'status_counter',
      this.sort.direction || 'desc', this.paginator.pageIndex, this.paginator.pageSize);
    
    this.loadAllTimeClocks();  
  }

  loadAllTimeClocks() {
    this.allDataSource.loadAllUsersClocks(+this.store.id, this.search, this.startDate, this.endDate,
      this.sort.active || 'status_counter',
      this.sort.direction || 'desc', this.pageNumber, this.pageSize);
      // this.isLoading = false
  }

  editTime(element){
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(EditTimeComponent, {
      width: isMobile ? '100vw' : '700px',
      height: isMobile ? '100vh' : '500px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'element': element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  editBreakTime(element, e) {
    const isMobile = window.innerWidth <= 599;
    const data = {
      'element': element,
      'key': e
    }
    const dialogRef = this.dialog.open(EditBreakTimeComponent, {
      width: isMobile ? '100vw' : '700px',
      height: isMobile ? '100vh' : '500px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'dial_data': data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (e == 1) {
        this.searchTimeClock(this.search);
      }
    });
  }

  editTimeClock(element){
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(EditTimeClockComponent, {
      width: isMobile ? '100vw' : '700px',
      height: isMobile ? '100vh' : '500px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'element': element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.searchTimeClock(this.search);
    });
  }

  formatDate(date){
    if(date){
      //  2022-02-10T21:10:30.806000Z
      return moment(date).format('hh:mm a');
    } else {
      return '';
    }
  }

  getColor(c: string): string {
    switch(c) {
      case 'CLOCKED IN': return 'rgba(47, 211, 43, 0.75)';
      case 'CLOCKED OUT': return 'rgba(210, 43, 6, 0.63)';
      case 'IN BREAK': return 'rgba(238, 175, 14, 0.63)';
    }
  }

  reset(){
    this.search = '----';
    this.startDate = null;
    this.endDate = null;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.paginator.pageSize = 10;
    this.searchTimeClock(null);
    this.loadAllTimeClocks();


    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.pageNumber, this.pageSize, this.paginator.pageSize, this.paginator.pageIndex);
    
  }

  deleteTimeClock(element) {
    this.timeclockorSession = element;
    this.confirmPopup('delete_clock','Are you sure to delete this time clock session?');
    // if(confirm("Are you sure to delete this time clock session?")){
    //   this.payload = {
    //     'pk': element.id,
    //     // 'user': this.userObject
    //   }
    //   this.userService.deleteTimeClockSession(this.payload).subscribe(data => {
    //     alert('Time Clock session has been deleted successfully.');
    //     this.searchTimeClock(this.search);
    //   },
    //   error => {
    //     alert('Error while deleting the time clock session.');
    //   })
    // }
  }

  deleteTimeClockSession(element) {
    this.timeclockorSession = element;
    this.confirmPopup('delete_clock_session','Are you sure to delete this time clock session?');
    // if(confirm("Are you sure to delete this time clock session?")){
    //   this.payload = {
    //     'pk': element.time_clock.id,
    //     // 'user': this.userObject
    //   }
    //   this.userService.deleteTimeClockSession(this.payload).subscribe(data => {
    //     alert('Time Clock session has been deleted successfully.');
    //     this.searchTimeClock(this.search);
    //   },
    //   error => {
    //     alert('Error while deleting the time clock session.');
    //   })
    // }
  }

  deleteClockOrSession(id) {
    this.payload = {
      'pk': id,
      // 'user': this.userObject
    }
    this.userService.deleteTimeClockSession(this.payload).subscribe(data => {
      this.timeclockorSession = null;
      this.alertPopup('delete_done', 'Time Clock session has been deleted successfully.');
      // alert('Time Clock session has been deleted successfully.');
      // this.searchTimeClock(this.search);
    },
    error => {
      // this.alertPopup('delete_error', 'Error while deleting the time clock session.');
      // alert('Error while deleting the time clock session.');
    })
  }


  addTimeClockPopup() {
    const isMobile = window.innerWidth <= 768;
    const dialogRef = this.dialog.open(AddTimeClockComponent, {
      width: isMobile ?'100vw' : '',
      // height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '100vw',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  alertPopup(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if (name == 'delete_done') {
          this.searchTimeClock(this.search);
        }
      }
    });
  }

  yourHandler(q) {
    this.pageNumber = q.pageIndex;
    this.pageSize = q.pageSize;
    this.loadAllTimeClocks();
  }
  confirmPopup(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg,
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (name == 'delete_clock') {
          this.deleteClockOrSession(this.timeclockorSession.id);
        } else if (name == 'delete_clock_session') {
          this.deleteClockOrSession(this.timeclockorSession.time_clock.id);
        }
      }
    });
  }
}
