<div class="terminal-status">

  <div class="battery-percent">
      <mat-icon class="off" *ngIf="batteryPercent == 0">battery_0_bar</mat-icon>
      <mat-icon class="off" *ngIf="batteryPercent > 0 && batteryPercent <= 10">battery_1_bar</mat-icon>
      <mat-icon class="amber" *ngIf="batteryPercent > 10 && batteryPercent <= 20">battery_2_bar</mat-icon>
      <mat-icon class="amber" *ngIf="batteryPercent > 20 && batteryPercent <= 40">battery_3_bar</mat-icon>
      <mat-icon class="on" *ngIf="batteryPercent > 40 && batteryPercent <= 60">battery_4_bar</mat-icon>
      <mat-icon class="on" *ngIf="batteryPercent > 60 && batteryPercent <= 80">battery_5_bar</mat-icon>
      <mat-icon class="on" *ngIf="batteryPercent > 80 && batteryPercent <= 100">battery_full</mat-icon>

    <span class="battery-percent-text" *ngIf="batteryPercent > 0">{{batteryPercent}}%</span>
  </div>


  <button mat-icon-button *ngIf="isDeviceActive" class="on" (click)="reconnectMqttServer()">
    <mat-icon>bluetooth_connected</mat-icon>
  </button>

  <button mat-icon-button class="off"
          *ngIf="!isDeviceActive"
          #tooltipError="matTooltip"
          (click)="reconnectMqttServer()"
          matTooltip="Payment device disconnected"
          matTooltipPosition="before">
    <mat-icon>bluetooth_disabled</mat-icon>
  </button>

  <button mat-icon-button *ngIf="isSocketActive" class="on" (click)="reconnectMqttServer()">
    <mat-icon>link</mat-icon>
  </button>

  <button mat-icon-button *ngIf="!isSocketActive" class="off" (click)="reconnectMqttServer()">
    <mat-icon>link_off</mat-icon>
  </button>
</div>
