<div class="flex flex-col p-7">
  <p class="text-xl font-medium">Manager Approval Required</p>
  <form (ngSubmit)="onSubmit(pin.value, description.value)" [formGroup]="form">
    <p class="text-base mb-2 pt-5">Your user does not have permission to do this. Please get authorized manager for
      assistance</p>
    <div class="input-box mb-2 pt-3">
      <input class="pin-input" formControlName="pin" #pin>
    </div>
    <p class="mb-2 pt-3">Description:</p>
    <div class="input-box pt-3">
      <textarea class="description" formControlName="description" #description></textarea>
    </div>
    <div class="flex justify-end pt-7">
      <button mat-stroked-button mat-dialog-close (click)="close()" class="mr-2.5">Cancel</button>
      <button mat-flat-button color="primary" [ngClass]="{ 'apply-btn-colr': form.valid }" type="submit"
        [disabled]="!form.valid">Submit</button>
    </div>
  </form>
</div>