import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../_models/store';
import { ContactBulkImportDialogComponent } from '../contact/contact-bulk-import-dialog/contact-bulk-import-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CsvData } from '../../_interfaces/csv-data';
import { ExcelService } from '../../_services/excel.service';
import { ContactFieldMappingDialogComponent } from '../contact/contact-field-mapping-dialog/contact-field-mapping-dialog.component';
import { CampaignService } from '../../_services/campaign.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-custom-list',
  templateUrl: './campaign-custom-list.component.html',
  styleUrls: ['./campaign-custom-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CampaignCustomListComponent implements OnInit {

  @Input() store: Store;
  unsubscribe = new Subject();
  dataSource;
  columnsToDisplay = ['name'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any;

  constructor(private dialog: MatDialog,
    private excelService: ExcelService,
    private campaignService: CampaignService) { }

  ngOnInit() {
    this.loadCustomList();
  }

  loadCustomList() {
    this.campaignService.getCampaignCustomList(this.store.id).subscribe(data => {
      this.dataSource = data;
    });
  }

  uploadFile() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ContactBulkImportDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: { customList: true },
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe))
      .subscribe((file: File) => file && this.readFileContent(file));
  }

  readFileContent(file: File) {
    this.excelService.parseFile(file).pipe(takeUntil(this.unsubscribe))
      .subscribe((csvData: CsvData) => this.openMappingDialog(csvData));
  }

  openMappingDialog(csvData: CsvData) {
    const dialogRef = this.dialog.open(ContactFieldMappingDialogComponent, {
      data: { csvData, store: this.store, customList: true },
      width: '400px'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe))
      .subscribe((updated: boolean) => {
        if (updated) {
          this.loadCustomList();
        }
      });
  }

}
