import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class TerminalService {

  private source = new BehaviorSubject<any>(null);
  current = this.source.asObservable();

  constructor(private http: HttpClient) {}

  changeTerminal(terminal){
    this.source.next(terminal);
  }

}
