<form [formGroup]="form" (onSubmit)="create()" class="form fixActionRow" *ngIf="form">
  <h2 mat-dialog-title> 
    Create New Gift Card
  </h2>
  <mat-dialog-content id="campaign-dialog" [ngClass.xs]="{'scroll_xs': true}">
    <div class="div-left">
      <mat-form-field appearance="fill" class="full-width" mat-focused>
        <mat-label>Gift Card Title</mat-label>
        <input matInput #name placeholder="Gift Card title" formControlName="title" #title>
      </mat-form-field>

      <app-gift-cards-upload-image
        [imageUrl]="imageStr"
        (imageStringEmitter)="setImageStr($event)"
        (imageFileEmitter)="setImageFile($event)"></app-gift-cards-upload-image>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Corporate</mat-label>
        <mat-select name="corporateValue" [(value)]="selectedCorp" (selectionChange)="corpChange($event)">
          <mat-option>Select</mat-option>
          <mat-option *ngFor="let corp of corpList" [value]="corp.id">{{corp.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width" mat-focused>
        <mat-label>Gift Card Description</mat-label>
        <textarea #text rows="3" matInput #message formControlName="description" #description></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Type</mat-label>
        <mat-select  formControlName="offer_type" #offerType
        (selectionChange)="changeValidation($event)" [disabled]="data.gift">
          <mat-option>Select</mat-option>
          <mat-option value="FACE">Face</mat-option>
          <mat-option value="DISCOUNT">Discount</mat-option>
          <mat-option value="CUSTOM">Custom</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" mat-focused *ngIf="offerType.value !== 'CUSTOM'"
        [ngClass]="{'full-width': form.get('offer_type').value !== 'DISCOUNT',
        'width-50':form.get('offer_type').value === 'DISCOUNT'}" >
        <mat-label>Face Value</mat-label>
        <input matInput formControlName="face_value" type="number" placeholder="USD">
      </mat-form-field>

      <mat-form-field appearance="fill" class="width-50" mat-focused
      *ngIf="offerType.value === 'DISCOUNT' || form.get('offer_type').value === 'DISCOUNT'">
        <mat-label>Price</mat-label>
        <input matInput formControlName="sale_price" type="number" placeholder="USD">
      </mat-form-field>

      <mat-form-field class="width-50" appearance="fill">
        <mat-label>Expires</mat-label>
        <input matInput [matDatepicker]="picker" #endDate formControlName="expires">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" class="width-50" mat-focused>
        <mat-label>Qty limit</mat-label>
        <input matInput formControlName="quantity" type="number" #quantity>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width" mat-focused>
        <mat-label>Disclaimer</mat-label>
        <textarea #text rows="4" matInput #message formControlName="disclaimer" #disclaimer></textarea>
      </mat-form-field>

    </div>
    <div class="div-right">
      <app-gift-cards-phone-preview
        [store]="store"
        [title]="title.value"
        [description]="description.value"
        [image]="imageStr"
        [endDate]="endDate.value"
        [quantity]="quantity.value"
        [disclaimer]="disclaimer.value"
      ></app-gift-cards-phone-preview>
    </div>
    <div class="clear"></div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="wrap-btn">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class="send"
      [disabled]="!form.valid"
      color="primary"
      [ngClass]="{'apply-btn-colr': form.valid}"
      (click)="create()">{{ data.gift ? 'Update' : 'Create'}}</button>
  </mat-dialog-actions>
</form>
