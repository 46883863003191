import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-phone-preview',
  templateUrl: './phone-preview.component.html',
  styleUrls: ['./phone-preview.component.scss']
})
export class PhonePreviewComponent implements OnInit {

  @Input() text;
  @Input() url;
  @Input() image;
  @Input() optOutText = '';

  constructor() { }

  ngOnInit() {
  }

}
