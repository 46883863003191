<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef class={{columnName[column]}}> {{columnName[column]}} </th>
    <ng-container *ngIf="column === 'button'; else defaultColumn">
      <td mat-cell *matCellDef="let element">
        <button 
          mat-stroked-button 
          mat-button 
          *ngIf="element.action !== 'REFUND' && element.action !== 'VOID' && element.action !== 'PAY_LATER' && element.action !== 'REFUND SUBMITTED' && element.action !== 'VOID SUBMITTED' && !element.status"  
          [disabled]="isDisabled(element)"
          [ngClass]="{
            'action-btn-colr': !isDisabled(element),
            'bg-gray-500 cursor-not-allowed opacity-50': isDisabled(element)
          }"
          (click)="undo(element)">
          Undo
        </button>
      </td>
    </ng-container>
    <!-- Default rendering for other columns -->
    <ng-template #defaultColumn>
      <td mat-cell *matCellDef="let element">
        {{element[column]}}
      </td>
    </ng-template>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row"
        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="(hasRefund(element) || splitTranscations.length > 0) && expandedElement !== element">
          keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="(hasRefund(element) || splitTranscations.length > 0) && expandedElement === element">
          keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>
  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length" >
      <div class="example-element-detail11"
        [@detailExpand]="hasRefund(element) && element == expandedElement ? 'expanded' : 'collapsed'">
        <app-history-refund [txn]="element" [order]="order" (refresh)="ngOnInit()"></app-history-refund>
      </div>
      <div class="example-element-detail"
        [@detailExpand]="splitTranscations.length > 0 && element == expandedElement ? 'expanded' : 'collapsed'">
        <table class="styled-table">
          <tr>
            <th>Time</th>
            <th>Type</th>
            <th>Amount</th>
            <th></th>
          </tr>
          <tr *ngFor="let record of splitTranscations">
            <td style="font-size: 14px;">{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
            <td style="font-size: 14px;">Split - {{ record.is_tip ? 'TIP' : record.payment_type}}</td>
            <td style="font-size: 14px;">{{record.split_amount}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>