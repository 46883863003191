import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PosDisplayService {

  constructor(private http: HttpClient) { }

  getAllCategories() {
    return this.http.get<any[]>(`${environment.apiUrl}/backend/pos_display/selectedcategories`);
    // return this.http.post<any[]>(`${environment.apiUrl}/backend/pos_display/${store}/allcategories/`, {username});
  }

  updateCategory(category) {
    return this.http.post<any>(`${environment.apiUrl}/backend/pos_display/save_category`, {category});
  }

  getAllScreens() {
    return this.http.get<any[]>(`${environment.apiUrl}/backend/pos_display/allscreens`);
  }
}
