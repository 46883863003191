import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { type } from 'os';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Modules} from 'src/app/constants';
import {OrderService} from 'src/app/_services/order.service';
import {RefreshService} from 'src/app/_services/refresh.service';
import {StoreService} from 'src/app/_services/store.service';
import {CheckoutDialogComponent} from '../checkout-dialog/checkout-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit, OnDestroy {

  private destroySubject: Subject<void> = new Subject();
  key_id: number;
  dollar: any;

  constructor(public dialogRef: MatDialogRef<CalculatorComponent>,
    private orderService:OrderService,
    private storeService:StoreService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog, private refreshService:RefreshService) { }

  input = '';
  result = '0.00';
  dueTotal = 0;

  left = 0
  right = 0

  terminals
  terminalId
  public unsubscribe: Subject<null> = new Subject<null>();
  resetInput = false;

  isDirty = false;
  optionalDesc;
  quantity = 1;
  taxable = true;
  type = true;

  botOrderId;

  ngOnInit() {
    this.key_id = this.data.key_id;
  }
  ngOnDestroy() {
    this.destroySubject.next();
  }

  pressNum(num: string) {
    if(this.left > 0){
      this.isDirty = true;
    }

    if (this.input === '' || this.resetInput) {
      this.input = '0.00';
      this.resetInput = false;
    }
    const resArr: any = this.input.split('');

    const tiv: any = `${num}`.split('');
    tiv.forEach(e => resArr.push(e));
    for (let i = tiv.length; i >= 0; i--) {
      resArr[resArr.length - (3 + i)] = resArr[resArr.length - (2 + i)];
    }
    resArr[resArr.length - 3] = '.';

    if (resArr.length >= 4 && resArr[0] === '0') {
      resArr.shift();
    }
    this.input = resArr.join('');
    console.log(resArr);

    this.calcAnswer();
  }

  openCheckoutDialog(){
    if(this.left <= 0 && +this.result > 0){
      this.left = +this.result;
    }
    console.log(this.isDirty);


    if(this.isDirty){
      this.add();
      this.isDirty = false;
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      width: '630px', height: '630px',
      data: {
        terminalId: this.terminalId,
        total: this.left,
        subTotal: this.left,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if(result){
        this.dialogRef.close(true);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  add(){
    this.left += +this.input;
    this.result = this.left.toFixed(2)
    this.resetInput = true;
    this.isDirty = false;
  }

  allClear() {
    this.result = '0.00';
    this.input = '0.00';
    this.left = 0
    this.isDirty = false;
  }

  clear() {
    if (this.input !== '0.00') {
      this.input = this.input.substr(0, this.input.length - 1);
      this.calcAnswer();
    }
  }

  calcAnswer() {
    const formula = this.input;
    this.result = eval(String(+formula));
  }


  number(str) {
    return +str;
  }

  addMisc() {
    let payload = {
      bot_order_id: this.data.bot_order_id,
      items: [
        {
          id: null,
          is_taxable: this.taxable,
          item_name: this.optionalDesc ? this.optionalDesc : 'MISCELLANEOUS',
          price: this.result,
          quantity: this.quantity
        }
      ]
    };

    this.storeService.addPosMiscItems(this.data.order_hash, payload)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(data => {
        this.optionalDesc = null;
        this.result = '0.00';
        this.resetInput = true;
        this.quantity = 1; 
        this.taxable = true;
        this.refreshService.refreshModule(Modules.POS_ORDER_SUMMARY);
      });
  }

  addDiscount(e) {
    let payload = {
      promotional_discount:this.result,
      description: this.optionalDesc ? this.optionalDesc : 'Instant Discount',
      type:e
    };
    
    this.orderService.addQuickDiscount(this.data.order_hash,payload).pipe(takeUntil(this.destroySubject))
    .subscribe((data: any) => {
     this.refreshService.refreshModule(Modules.POS_ORDER_SUMMARY);
     this.snackBar.open(data.message, 'OK', { duration: 2000 });
     this.optionalDesc = null;
        this.result = '0.00';
        this.resetInput = true;
    });

  }
 
 
}
