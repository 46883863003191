import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '../_models/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { UserApi } from '../_models/contacts';
import { Campaign, DataSourceResult, Gift, OfferV2 } from '../_models/campaign';
import { Report } from '../_models/report';
import Utils from 'src/app/utils';


@Injectable()
export class StoreService {
  endpoint = environment.apiUrl;
  storeEndpointV1 = `${this.endpoint}/backend/store/`;
  storeEndpointV2 = `${this.endpoint}/backend/v2/stores/`;
  storeMerchantEndpointV2 = `${this.endpoint}/backend/v2/merchants/stores/`;
  graphMenu = this.endpoint;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  private source = new BehaviorSubject<Store>(null);
  public isOrderView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOrderTab: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  current = this.source.asObservable();
  public isStoreChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public sideNavOpenClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fullScreenUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fillScreenUpdateFromPOS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);




  constructor(private http: HttpClient) { }

  generateHeaders(): HttpHeaders {
    var httpHeaders = null;
    var token = JSON.parse(localStorage.getItem("currentUser"));
    if (token) {
      httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token.token}`,
      });
    } else {
      httpHeaders = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      });
    }
    return httpHeaders;
  }

  refresh(store: Store) {
    this.http
      .get<Store>(this.storeEndpointV2 + `${store.id}/`, this.httpOptions)
      .subscribe((store) => {
        this.isStoreChange.next(store);
        this.changeStore(store);
        const stre = {
          store_data: store,
          number: 1
        }
        this.isStoreChange.next(stre);
      });
  }

  refreshStorTimer(store: Store) {
    this.http
      .get<Store>(this.storeEndpointV2 + `${store.id}/`, this.httpOptions)
      .subscribe((store) => {
        const stre = {
          store_data: store,
          number: 1
        }
        this.isStoreChange.next(stre);
      });
  }

  changeStore(store: Store) {
    this.source.next(store);
    localStorage.setItem("currentStore", store.id);
    localStorage.setItem("storeTimezone", store.time_zone);
    localStorage.setItem("store_type", store.store_type);
  }

  updateStore(store: Store, payload: string) {
    return this.http.patch<Store>(
      this.storeEndpointV2 + `${store.id}/`,
      payload,
      this.httpOptions
    );
  }

  updateStoreFeatureFlag(storeId: string, payload: string) {
    return this.http.patch<Store>(
      this.storeEndpointV2 + `${storeId}/feature_flag/`,
      payload,
      this.httpOptions
    );
  }

  updateStoreIVRSetting(storeId: string, payload: string) {
    return this.http.patch<Store>(
      this.storeEndpointV2 + `${storeId}/ivr_settings/`,
      payload,
      this.httpOptions
    );
  }

  updateWorkingHours(store: Store, payload: string) {
    return this.http.post(
      this.storeEndpointV1 + `${store.id}/update_working_hours/`,
      payload,
      this.httpOptions
    );
  }

  getMerchant(store: Store) {
    return this.http.get(
      this.storeMerchantEndpointV2 + (store.id ? `${store.id}/` : `${store}/`),
      this.httpOptions
    );
  }

  updateMerchant(store: Store, payload: Object) {
    return this.http.patch(
      this.storeMerchantEndpointV2 + `${store.id}/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  refreshMerchant(store: Store) {
    return this.http.get(
      this.storeEndpointV1 + `${store.id}/refresh_merchant_status/`,
      this.httpOptions
    );
  }

  createMerchant(store: Store) {
    return this.http.get(
      this.storeEndpointV1 + `${store.id}/create_merchant/`,
      this.httpOptions
    );
  }

  verifyPhone(store: Store) {
    return this.http.get(
      this.storeEndpointV1 + `${store.id}/start_phone_verification/`,
      this.httpOptions
    );
  }

  phoneVerificationStatus(store: Store) {
    return this.http.get(
      this.storeEndpointV1 + `${store.id}/phone_verification_status/`,
      this.httpOptions
    );
  }

  rawMenuVersion(storeId: string) {
    return this.http.get(
      `${this.storeEndpointV2}upload/menu/${storeId}/`,
      this.httpOptions
    );
  }

  triggerMenuUploadEmail(storeId: string, version: number) {
    return this.http.post(
      `${this.endpoint}/backend/menu/email`,
      JSON.stringify({ store_id: +storeId, version: version }),
      this.httpOptions
    );
  }

  getSkills(storeId: string) {
    return this.http.get<any[]>(
      `${this.endpoint}/backend/v2/business/store/skill/${storeId}/`,
      this.httpOptions
    );
  }

  createSkill(payload: any) {
    return this.http.post(
      `${this.endpoint}/backend/v2/business/store/skill/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateSkill(skillId: string, payload: any) {
    return this.http.put(
      `${this.endpoint}/backend/v2/business/store/skill/${skillId}/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  deleteSkill(skillId) {
    return this.http.delete(
      `${this.endpoint}/backend/v2/business/store/skill/${skillId}/`,
      this.httpOptions
    );
  }

  getChatList(storeId: string, phoneNumber: string) {
    let params = new HttpParams().set("filter", phoneNumber);

    return this.http.get<any>(
      `${this.endpoint}/backend/message/${storeId}/list/`,
      { params: params }
    );
  }

  getMessageList(storeId: any, messageGroupId: any) {
    return this.http.get(
      `${this.endpoint}/backend/message/${storeId}/${messageGroupId}/list/`,
      this.httpOptions
    );
  }

  unreadMessages(storeId: any) {
    return this.http.get(
      `${this.endpoint}/backend/message/${storeId}/unread`,
      this.httpOptions
    );
  }

  newMessageList(storeId, phone_number) {
    return this.http.post(
      `${this.endpoint}/backend/message/${storeId}/new/`,
      JSON.stringify({ phone_number }),
      this.httpOptions
    );
  }

  deleteMessageGroup(storeId: any, messageGroupId: any) {
    return this.http.get(
      `${this.endpoint}/backend/message/${storeId}/${messageGroupId}/delete/`,
      this.httpOptions
    );
  }

  updateMessageGroup(storeId: any, messageGroupId: any, payload: any) {
    return this.http.post<any>(
      `${this.endpoint}/backend/message/${storeId}/${messageGroupId}/update/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  endConversation(storeId: any, phone_number: any) {
    return this.http.post(
      `${this.endpoint}/backend/message/${storeId}/end_conversation/`,
      JSON.stringify({ phone_number }),
      this.httpOptions
    );
  }

  getStoreCampaigns(
    storeId,
    filter = "",
    startDate = null,
    endDate = null,
    sortField = "id",
    sortOrder = "asc",
    pageNumber = 0,
    pageSize = 10
  ) {
    let params = new HttpParams()
      .set("store_id", storeId.toString())
      .set("filter", filter)
      .set("sort_field", sortField)
      .set("sort_dir", sortOrder)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString());

    if (startDate) {
      params = params.set("start_date", startDate.toISOString().slice(0, 10));
    }

    if (endDate) {
      params = params.set("end_date", endDate.toISOString().slice(0, 10));
    }
    return this.http.get<DataSourceResult<Campaign>>(
      `${environment.apiUrl}/backend/v2/campaign/${storeId}/campaigns/`,
      { params: params }
    );
  }

  getCampaignStats(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/campaign/${storeId}/stats/`
    );
  }

  getCampaignSegmentStats(campaignId, segment, segmentAudience, customListId) {
    let params = new HttpParams()
      .set('segment', segment)
      .set('segment_audience', segmentAudience)
      .set('custom_list_id', customListId);
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/campaign/${campaignId}/segment_stats/`, { params: params }
    );
  }


  copyCampaign(campaignId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/campaign/${campaignId}/copy/`
    );
  }

  createCampaign(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/campaign/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateCampaign(payload) {
    return this.http.put<any>(
      `${environment.apiUrl}/backend/v2/campaign/${payload.id}/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  deleteCampaign(campaignId) {
    return this.http.delete<any>(
      `${environment.apiUrl}/backend/v2/campaign/${campaignId}/`,
      this.httpOptions
    );
  }

  getStoreReports(
    storeId,
    startDate = null,
    endDate = null,
    sortField = "id",
    sortOrder = "asc",
    pageNumber = 0,
    pageSize = 10
  ) {
    let params = new HttpParams()
      .set("store_id", storeId.toString())
      .set("sort_field", sortField)
      .set("sort_dir", sortOrder)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString());

    if (startDate) {
      // params = params.set("start_date", startDate.toISOString().slice(0, 10));
      params = params.set('start_date', Utils.toISOLocal(startDate).slice(0, 10))
    }

    if (endDate) {
      // params = params.set("end_date", endDate.toISOString().slice(0, 10));
      params = params.set('end_date', Utils.toISOLocal(endDate).slice(0, 10))
    }

    return this.http.get<DataSourceResult<Report>>(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/statement/`,
      { params: params }
    );
  }

  getStoreDetailedReportsTSV(storeId, startDate, endDate) {
    let params = new HttpParams()
      .set("start_date", startDate)
      .set("end_date", endDate);

    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/statement/detailed/`,
      { params: params, responseType: "blob" }
    );
  }

  getStoreDetailedReportsHTML(storeId, startDate, endDate) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }
    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/statement/html/`,
      { params: params }
    );
  }

  /* Sales Report */
  getSalesMiniReport(storeId, startDate, endDate, from, to, station, pin, user_id: string) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }
    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    if (user_id) {
      params = params.set("user_id", user_id);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/sales_summary/`,
      { params: params }
    );
  }

  getSalesMiniReportAll(storeId, startDate, endDate, from, to, station, user_id) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }

    if (station) {
      params = params.set("terminal_id", station);
    }

    if (user_id) {
      params = params.set("user_id", user_id);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/sales_summary/`,
      { params: params }
    );
  }

  /* Category Report API */
  getCategoryMiniReport(storeId, startDate, endDate, from, to, station, pin) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }
    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/category_summary/`,
      { params: params }
    );
  }

  getCategoryMiniReportAll(storeId, startDate, endDate, from, to, station) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/category_summary/`,
      { params: params }
    );
  }


  printStoreMiniReport(
    storeId,
    selectedTerminalName,
    startDate,
    endDate,
    from,
    to,
    station,
    pin,
    report,
    printerId = null,
    smryChoice
  ) {
    let params = new HttpParams().set(
      "terminal_name",
      selectedTerminalName.toString()
    );
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (report) {
      params = params.set("report", report);
    }

    if (printerId) {
      params = params.set("printer_id", printerId);
    }

    if (smryChoice) {
      params = params.set("type", smryChoice);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/print/`,
      { params: params }
    );
  }

  printStoreMiniReportAll(
    storeId,
    selectedTerminalName,
    startDate,
    endDate,
    from,
    to,
    station,
    report,
    printerId = null,
    smryChoice
  ) {
    let params = new HttpParams().set(
      "terminal_name",
      selectedTerminalName.toString()
    );
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (report) {
      params = params.set("report", report);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    if (printerId) {
      params = params.set("printer_id", printerId);
    }
    if (smryChoice) {
      params = params.set("type", smryChoice);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/print/`,
      { params: params }
    );
  }

  updateCustomLeadTime(payload) {
    return this.http.post(
      `${this.storeEndpointV1}lead_time/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getContactStats(storeId) {
    let params = new HttpParams();
    params = params.set("store_id", storeId);
    return this.http.get(
      `${environment.apiUrl}/backend/v2/message/user/all_contact_stats/`,
      { params: params }
    );
  }

  getContactList(
    storeId,
    pageNumber = 0,
    pageSize = 10,
    blocked = false,
    filter = ""
  ) {
    let params = new HttpParams()
      .set("store_id", storeId)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString())
      .set("blocked", blocked.toString())
      .set("filter", filter);
    return this.http.get<UserApi>(
      `${environment.apiUrl}/backend/v2/message/user/`,
      { params: params }
    );
  }

  updateContact(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/message/user/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  uploadContactsList(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/upload/user/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  addMiscItems(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/orders/misc_items/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  addPosMiscItems(orderHash, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/orders/misc_items/${orderHash}/pos_add_misc/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getMiscItems(botOrderId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/orders/misc_items/${botOrderId}/`,
      this.httpOptions
    );
  }

  getPrinterHearbeat(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/store/${storeId}/heartbeat/`,
      this.httpOptions
    );
  }

  getTablesideTables(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/tableside/table/${storeId}/`,
      this.httpOptions
    );
  }

  addTablesideTable(storeId, tableName) {
    let payload = {
      store_id: storeId,
      table_name: tableName,
    };
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/tableside/table/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  deleteTablesideTable(tableId) {
    return this.http.delete<any>(
      `${environment.apiUrl}/backend/v2/tableside/table/${tableId}/`,
      this.httpOptions
    );
  }

  getPrinterMenu(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/printer/menu/${storeId}/`,
      this.httpOptions
    );
  }

  getPrinterOccasion(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/printer/occasion/${storeId}/`,
      this.httpOptions
    );
  }

  getLastOrderBuffer(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/stores/last_order_buffer/${storeId}/`,
      this.httpOptions
    );
  }

  updateLastOrderBuffer(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/stores/last_order_buffer/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getStoreLogo(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/stores/logo/${storeId}/`,
      this.httpOptions
    );
  }

  deleteLogo(logoId) {
    return this.http.delete<any>(
      `${environment.apiUrl}/backend/v2/stores/logo/${logoId}/`,
      this.httpOptions
    );
  }

  getFaviconLogo(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/stores/favicon/${storeId}/`,
      this.httpOptions
    );
  }

  deleteFaviconLogo(logoId) {
    return this.http.delete<any>(
      `${environment.apiUrl}/backend/v2/stores/favicon/${logoId}/`,
      this.httpOptions
    );
  }

  getStoreGifts(
    storeId,
    filter = "",
    startDate = null,
    endDate = null,
    sortField = "id",
    sortOrder = "asc",
    pageNumber = 0,
    pageSize = 10
  ) {
    let params = new HttpParams()
      .set("store_id", storeId.toString())
      .set("filter", filter)
      .set("sort_field", sortField)
      .set("sort_dir", sortOrder)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString());

    if (startDate) {
      params = params.set("start_date", startDate.toISOString().slice(0, 10));
    }

    if (endDate) {
      params = params.set("end_date", endDate.toISOString().slice(0, 10));
    }
    return this.http.get<DataSourceResult<Gift>>(
      `${environment.apiUrl}/backend/v2/gift/${storeId}/`,
      { params: params }
    );
  }

  createGift(formData, params, headers) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/gift/`,
      formData,
      { params, headers }
    );
  }

  deleteGiftCard(giftId) {
    return this.http.delete<any>(
      `${environment.apiUrl}/backend/v2/gift/${giftId}/`,
      this.httpOptions
    );
  }

  giftStats(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/gift/${storeId}/stats`,
      this.httpOptions
    );
  }

  offerStats(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/stats`,
      this.httpOptions
    );
  }

  getStoreOffersV2(
    storeId,
    filter = "",
    startDate = null,
    endDate = null,
    sortField = "id",
    sortOrder = "asc",
    pageNumber = 0,
    pageSize = 10
  ) {
    let params = new HttpParams()
      .set("store_id", storeId.toString())
      .set("filter", filter)
      .set("sort_field", sortField)
      .set("sort_dir", sortOrder)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString());

    if (startDate) {
      params = params.set("start_date", startDate.toISOString().slice(0, 10));
    }

    if (endDate) {
      params = params.set("end_date", endDate.toISOString().slice(0, 10));
    }
    return this.http.get<DataSourceResult<OfferV2>>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/`,
      { params: params }
    );
  }

  getStoreOfferListV2(
    storeId,
    filter = "",
    startDate = null,
    endDate = null,
    sortField = "id",
    sortOrder = "asc",
    pageNumber = 0,
    pageSize = 10
  ) {
    let params = new HttpParams()
      .set("store_id", storeId.toString())
      .set("filter", filter)
      .set("sort_field", sortField)
      .set("sort_dir", sortOrder)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString());

    if (startDate) {
      params = params.set("start_date", startDate.toISOString().slice(0, 10));
    }

    if (endDate) {
      params = params.set("end_date", endDate.toISOString().slice(0, 10));
    }
    return this.http.get<DataSourceResult<OfferV2>>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/store_offer_list/`,
      { params: params }
    );
  }

  getStorePortions(storeId) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/portions/`,
      this.httpOptions
    );
  }

  getStoreMenuItems(storeId) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/items/`,
      this.httpOptions
    );
  }

  getStoreCategories(storeId) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/categories/`,
      this.httpOptions
    );
  }

  deleteOfferV2(giftId) {
    return this.http.delete<any>(
      `${environment.apiUrl}/backend/v2/offers-v2/${giftId}/`,
      this.httpOptions
    );
  }

  getOfferV2RedeemHistory(
    storeId: number,
    filter = "",
    startDate = null,
    endDate = null,
    sortField = "id",
    sortOrder = "asc",
    pageNumber = 0,
    pageSize = 10
  ) {
    let params = new HttpParams()
      .set("store_id", storeId.toString())
      .set("filter", filter)
      .set("sort_field", sortField)
      .set("sort_dir", sortOrder)
      .set("page_number", pageNumber.toString())
      .set("page_size", pageSize.toString());

    if (startDate) {
      params = params.set("start_date", startDate.toISOString().slice(0, 10));
    }

    if (endDate) {
      params = params.set("end_date", endDate.toISOString().slice(0, 10));
    }

    return this.http.get<DataSourceResult<any[]>>(
      `${environment.apiUrl}/backend/v2/offers-v2/${storeId}/redemption/`,
      { params: params }
    );
  }

  getWorkingHours(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/working-hour/${storeId}/`,
      this.httpOptions
    );
  }

  saveWorkingHours(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/working-hour/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateSpecialHour(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/special-hour/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }
  updateHoliday(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/holiday-settings/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }
  saveServiceAreas(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/service-area/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getServiceAreas(store_id) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/service-area/${store_id}/`,
      this.httpOptions
    );
  }

  setDefaultPosSetting(
    storeId,
    formdata,
    defaultLoad,
    closedOrders,
    openOrders
  ) {
    console.log(defaultLoad, closedOrders, closedOrders);
    let payload = {
      store: storeId,
      default_order_occasion: formdata.default_order_occasion,
      default_time: formdata.default_time,
      terminal_id: formdata.terminal_id,
      default_load: defaultLoad,
      closed_orders: closedOrders,
      open_orders: openOrders,
    };
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/setting/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  createPosSetting(
    pos_setting_id,
    storeId,
    formdata,
    defaultLoad,
    closedOrders,
    openOrders
  ) {
    console.log(defaultLoad, closedOrders, closedOrders);
    let payload = {
      store: storeId,
      default_order_occasion: formdata.default_order_occasion,
      default_time: formdata.default_time,
      terminal_id: formdata.terminal_id,
      default_load: defaultLoad,
      closed_orders: closedOrders,
      open_orders: openOrders,
    };
    return this.http.put<any>(
      `${environment.apiUrl}/backend/v2/pos/setting/${pos_setting_id}/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getDefaultPosSetting(pos_setting_id) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/pos/setting/${pos_setting_id}/`,
      this.httpOptions
    );
  }

  updateSurchargeDetail(store: Store, data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.post(
      this.graphMenu + `/backend/store/${store}/update_surcharge_detail/`,
      data,
      { headers: headers }
    );
  }

  getStoreEmpReport(storeId, startDate, endDate, from, to, station, pin) {
    let params = new HttpParams();
    if (storeId) {
      params = params.set("store_id", storeId);
    }

    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(`${environment.apiUrl}/backend/v2/report/employee/`, {
      params: params,
    });
  }
  getStoreEmpReportAll(storeId, startDate, endDate, from, to, station) {
    let params = new HttpParams();
    if (storeId) {
      params = params.set("store_id", storeId);
    }

    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }

    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(`${environment.apiUrl}/backend/v2/report/employee/`, {
      params: params,
    });
  }

  updateSalesTax(store: Store, data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.post(
      this.graphMenu + `/backend/store/${store}/sales_tax_update/`,
      data,
      { headers: headers }
    );
  }
  updateHeaderDisclaimer(store: Store, data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.post(
      this.graphMenu + `/backend/store/${store}/update_disclaimer_detail/`,
      data,
      { headers: headers }
    );
  }

  giftCardOrdersList(store: Store, data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.get(
      this.graphMenu +
      `/backend/store/${store}/offer/orders/` +
      "?start_date=" +
      data.start_date +
      "&end_date=" +
      data.end_date,
      { headers: headers }
    );
  }

  getOccasionTime(store: Store, occasion: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.get(
      this.graphMenu +
      `/backend/v2/occasion-hour/${store}/?occasion_name=${occasion}`,
      { headers: headers }
    );
  }

  saveDeliveryHours(payload) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.post<any>(
      this.graphMenu + `/backend/v2/occasion-hour/`,
      payload,
      { headers: headers }
    );
  }

  getGiftCardHolderUserList(storeid: number, pageNumber: any, pageSize: any, filterValue: any, sort_id: any, sortDir: any, fromDate: any, toDate: any) {
    let params = new HttpParams()
      .set('page_number', pageNumber.toString())
      .set('page_size', pageSize.toString())
      .set('search_key', filterValue)
      .set('sort_id', sort_id)
      .set('sort_dir', sortDir)

    if (fromDate) {
      params = params.set('from_date', moment(fromDate).format("YYYY-MM-DD"))
    }

    if (toDate) {
      params = params.set('to_date', moment(toDate).format("YYYY-MM-DD"))
    }

    return this.http.get(`${environment.apiUrl}/backend/business/offers/${storeid}/gift_cardholders_user_list/`, { params: params });
  }

  getUserGiftOrderList(storeid: any, phone_number: any) {
    return this.http.get(`${environment.apiUrl}/backend/business/offers/${storeid}/${phone_number}/gift_bot_orders_list/`, this.httpOptions);
  }

  updateGiftCardValue(payload: any) {
    return this.http.post(`${environment.apiUrl}/backend/business/offers/value/gift_card_value_add_sub/`, payload, this.httpOptions
    );
  }

  getGiftCardLogHistory(store_id: any, phone_number: any) {
    return this.http.get(`${environment.apiUrl}/backend/business/offers/${store_id}/${phone_number}/gift_card_holders_history/`, this.httpOptions);
  }

  alertStatusChange(storeId: any, status: boolean) {
    const payload = { store_id: storeId, state: status };
    return this.http.patch<Store>(this.storeEndpointV2 + `${storeId}/alert_status/`, payload, this.httpOptions);
  }

  giftCardDefaultImage(storeId: any, payload: any) {
    return this.http.put(this.endpoint + `/backend/${storeId}/gift_card_default_image_update/`, payload);
  }

  gmbUpdateLogo(payload: any) {
    return this.http.put(this.endpoint + `/backend/gmbs/location/update/logo/`, payload);
  }

  pauseStoreService(storeId: any, payload: any) {
    return this.http.put(`${environment.apiUrl}/backend/store/${storeId}/pause_store/`, payload, this.httpOptions);
  }

  campaignSetting(storeId: any) {
    return this.http.get(`${environment.apiUrl}/backend/v2/campaign/${storeId}/setting/`, this.httpOptions);
  }

  addgmbOnlineOrderLink(payload: any) {
    return this.http.post(`${environment.apiUrl}/backend/gmbs/location/online_order_link/add/`, payload, this.httpOptions
    );
  }

  addgmbOnlineOrderLinkDelete(payload: any) {
    return this.http.delete(`${environment.apiUrl}/backend/gmbs/location/online_order_link/add/` + '?store_id=' + payload.store_id + '&link_id=' + payload.link_id, this.httpOptions
    );
  }


  uploadCustomList(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/campaign/custom-list/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  orderReadyMessageSetting(storeId: any, payload: any) {
    return this.http.put(`${environment.apiUrl}/backend/store/${storeId}/order_ready_sms_setting_update/`, payload, this.httpOptions)
  }

  orderConfirmationMessageSetting(storeId: any, payload: any) {
    return this.http.put(`${environment.apiUrl}/backend/store/${storeId}/order_confirmation_message_update/`, payload, this.httpOptions)
  }

  getGiftCradOrdersCsv(storeId: any, startDate: any, endDate: any) {
    let params = new HttpParams()
      .set("start_date", startDate)
      .set("end_date", endDate);

    return this.http.get(`${environment.apiUrl}/backend/business/store/${storeId}/gift_crad_order_list/details/`, { params: params, responseType: "blob" }
    );
  }

  forceClockOutTime(storeId: any, payload: any) {
    return this.http.put(`${environment.apiUrl}/backend/store/${storeId}/force_clock_out_time_update/`, payload, this.httpOptions);
  }

  getStorePrinters(storeId) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/backend/v2/printer/${storeId}/`,
      this.httpOptions
    );
  }

  getGMBLocationData() {
    return this.http.get<any[]>(
      `${environment.apiUrl}/backend/gmb/locations/list/`,
      this.httpOptions
    );
  }

  updateGMBLocation(data) {
    return this.http.put<any[]>(
      `${environment.apiUrl}/backend/gmb/locations/list/`, data, this.httpOptions);
  }


  /* Food Cost Report API */
  getFoodCostReport(storeId, startDate, endDate, from, to, station, pin) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }
    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/food_cost_summary/`,
      { params: params }
    );
  }

  getFoodCostReportAll(storeId, startDate, endDate, from, to, station) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(
      `${environment.apiUrl}/backend/business/store/${storeId}/report/mini_report/food_cost_summary/`,
      { params: params }
    );
  }


  /* Profitability Report */
  getProfitabilityReport(storeId, startDate, endDate, from, to, station, pin) {
    let params = new HttpParams();
    if (storeId) {
      params = params.set("store_id", storeId);
    }

    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(`${environment.apiUrl}/backend/v2/report/profitability_report/`, {
      params: params,
    });
  }

  getProfitabilityReportAll(storeId, startDate, endDate, from, to, station) {
    let params = new HttpParams();
    if (storeId) {
      params = params.set("store_id", storeId);
    }

    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }

    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(`${environment.apiUrl}/backend/v2/report/profitability_report/`, {
      params: params,
    });
  }


  /* Cash Drawer Report */
  getCashDrawerReport(storeId, startDate, endDate, from, to, station, pin) {
    let params = new HttpParams();
    if (storeId) {
      params = params.set("store_id", storeId);
    }

    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }
    if (pin) {
      params = params.set("currentPIN", pin);
    }
    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(`${environment.apiUrl}/backend/v2/drawer-manage/${storeId}/cash_drawer_management_report_data`, {
      params: params,
    });
  }

  getCashDrawerReportAll(storeId, startDate, endDate, from, to, station) {
    let params = new HttpParams();
    if (storeId) {
      params = params.set("store_id", storeId);
    }

    if (startDate) {
      params = params.set("start_date", moment(startDate).format("YYYY-MM-DD"));
    }

    if (endDate) {
      params = params.set("end_date", moment(endDate).format("YYYY-MM-DD"));
    }

    if (from) {
      params = params.set("from_hour", from.hour);
      params = params.set("from_min", from.min);
    }

    if (to) {
      params = params.set("to_hour", to.hour);
      params = params.set("to_min", to.min);
    }

    if (station) {
      params = params.set("terminal_id", station);
    }
    return this.http.get(`${environment.apiUrl}/backend/v2/drawer-manage/${storeId}/cash_drawer_management_report_data`, {
      params: params,
    });
  }

  updateEmployeeTax(store: Store, data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.post(
      this.graphMenu + `/backend/store/${store}/update_employee_tax_detail/`,
      data,
      { headers: headers }
    );
  }
  
  getCorporateList(storeId: any) {
    return this.http.get(`${environment.apiUrl}/backend/${storeId}/corporate_list/`, this.httpOptions);
  }

  updateFeedbackMessageTax(store: Store, data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    return this.http.post(
      this.graphMenu + `/backend/store/${store}/update_feedback_message_detail/`,
      data,
      { headers: headers }
    );
  }

  reportOnMail(data: any) {
    const headers: HttpHeaders = this.generateHeaders();
    const url = this.graphMenu + '/backend/business/create_report_send_mail_batch/';
    return this.http.post(url, data, { headers: headers });
  }

  uploadBulkPhysicalGiftCardData(formData: FormData) {
    const headers: HttpHeaders = this.generateHeaders();
    const storeId = formData.get('store') as string;
    return this.http.post(
      this.endpoint + `/backend/physical-giftcards/import/${storeId}/`,formData,{ headers: headers }
    );
  }
  
  getGiftCards(storeId: number, pageNumber: any, pageSize: any, filterValue: any, sort_id: any, sortDir: any, fromDate: any, toDate: any) {
    let params = new HttpParams()
      .set('page_number', pageNumber.toString())
      .set('page_size', pageSize.toString())
      .set('search_key', filterValue)
      .set('sort_id', sort_id)
      .set('sort_dir', sortDir)
    if (fromDate) {
      params = params.set('from_date', moment(fromDate).format("YYYY-MM-DD"))
    }
    if (toDate) {
      params = params.set('to_date', moment(toDate).format("YYYY-MM-DD"))
    }
    return this.http.get(`${environment.apiUrl}/backend/physical-giftcards/${storeId}/`, { params: params });
  }   
  
  getGiftCardRedemptionHistory(giftID) {
    return this.http.get(`${environment.apiUrl}/backend/order/${giftID}/physical-giftcards-redemptions/`);
  }

  getLatestAppVersion(envID: number, app: string) {
    return this.http.get(`${this.endpoint}/backend/version/${envID}/${app}/`);
  }

  updateAppVersion(envID: number, newVersion: string) {
    const headers = new HttpHeaders().set('Authorization', 'Token your-auth-token-here');
    return this.http.post(`${this.endpoint}/backend/version/update/${envID}/'business'/`, 
      { version: newVersion }, 
      { headers: headers }
    ).toPromise();
  }
  

}
