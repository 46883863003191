import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UserService} from '../../_services/user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {
  role : any;
  store: any;
  constructor(
    public dialogRef: MatDialogRef<EditRoleComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService) {
      this.role = data['role'];
      this.store = data['store'];
    }

  ngOnInit() {
  }

  onSubmit(newRole:string) {
    this.dialogRef.close({data:newRole});
  }

  editRole(newrole) {
    this.role.role_name = newrole
    this.userService.editRole(this.role, this.store.id).toPromise().then(
      result => {
        console.log(result)
      });
    this.dialogRef.close();
  }

}
