import {FormBase} from './form-base';

export class FormPhone extends FormBase<string> {
  controlType = 'phone';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
