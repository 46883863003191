import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from 'src/app/_services/store.service';

@Component({
  selector: 'app-order-confirmation-message',
  templateUrl: './order-confirmation-message.component.html',
  styleUrls: ['./order-confirmation-message.component.scss']
})
export class OrderConfirmationMessageComponent implements OnInit {

  form:UntypedFormGroup;
  toggleValue: boolean = false;
  storeID: any;
  store;
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<OrderConfirmationMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService
  ) {
    this.store = this.data.store;
   }

  ngOnInit() {
    this.storeID = localStorage.getItem('currentStore');
    this.toggleValue = this.store.feature_flag.order_comfirmation_message;
    this.form = new UntypedFormGroup({
      order_comfirmation_message: new UntypedFormControl(this.store.store_data.order_comfirmation_message),
    });
  }

  onChangeToggle(event) {
    this.toggleValue = event.checked;
  }

  submitMessagesForm() {
    this.isLoading = true;
    const paylaod = {
      "order_comfirmation_message_toggle": this.toggleValue,
      "order_comfirmation_message": this.form.value.order_comfirmation_message
    }
    this.storeService.orderConfirmationMessageSetting(this.storeID, paylaod).subscribe((data:any) => {
      this.isLoading = false;
      this.dialogRef.close();
    });
  }
}
