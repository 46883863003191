import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SideNavComponent} from './side-nav/side-nav.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {LoginLayoutComponent} from './login-layout/login-layout.component';
import {HomeLayoutComponent} from './home-layout/home-layout.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {FormUtils} from './_helpers/form-utils';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {PasswordRestConfirmComponent} from './login/password-rest-confirm/password-rest-confirm.component';
import {LoaderInterceptor} from './_helpers/loader.interceptor';
import {ReportDialogComponent} from './dashboard/report/report-dialog/report-dialog.component';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';
import {IMqttServiceOptions, MqttModule} from 'ngx-mqtt';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {HolidaysComponent} from './holidays/holidays.component';
import {RxdbService} from './_services/rxdb.service';
import {RouterModule} from '@angular/router';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {CoreModule} from './core.module';
import {TerminalStatusComponent} from './terminal-status/terminal-status.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {environment} from '../environments/environment';
import { CallCardComponent } from './call-card/call-card.component';
import { ServiceWorkerModule } from '@angular/service-worker';


export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'labrador-o58365.a01.euc1.aws.hivemq.cloud',
  port: 8884,
  protocol: 'wss',
  path: '/mqtt',
  clean: true,
  username: 'labrador',
  password: 'kUbS6EyzcXYU8R',
  connectTimeout: 4000,
  reconnectPeriod: 4000,
  clientId: 'labmq-business-' + localStorage.getItem('mqttsession')
};

export function initializeApp(rxdbService: RxdbService) {
  return () => rxdbService.init();
}

@NgModule({
    declarations: [
      AppComponent,
      SideNavComponent,
      LoginComponent,
      LoginLayoutComponent,
      HomeLayoutComponent,
      ForgotPasswordComponent,
      PasswordRestConfirmComponent,
      ReportDialogComponent,
      HolidaysComponent,
      CallCardComponent
    ],
    imports: [
      CommonModule,
      RouterModule,
      FlexLayoutModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserModule,
      AppRoutingModule,
      MatAutocompleteModule,
      MatChipsModule,
      MatIconModule,
      MatInputModule,
      MatFormFieldModule,
      MatMenuModule,
      MatSidenavModule,
      MatToolbarModule,
      MatDialogModule,
      MatSlideToggleModule,
      MatSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatListModule,
      MatSnackBarModule,
      NgIdleKeepaliveModule.forRoot(),
      MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
      MatButtonModule,
      MatTooltipModule,
      Ng2SearchPipeModule,
      BrowserAnimationsModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
    ],
    providers: [
      { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [RxdbService], multi: true,},
      { provide: HTTP_INTERCEPTORS, useClass:JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
      { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      // { provide: ErrorHandler, useClass: CustomErrorHandlerService},
      CoreModule,
      FormUtils
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
