import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class POSSummaryToggleService {

  private source = new BehaviorSubject<string>(null);
  current = this.source.asObservable();

  constructor() {}

  toggle(state) {
    this.source.next(state);
  }

}
