import {Component, Inject, OnInit, Optional} from '@angular/core';
import * as moment from 'moment';
import {UserService} from '../../_services/user.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-break-time',
  templateUrl: './edit-break-time.component.html',
  styleUrls: ['./edit-break-time.component.scss']
})
export class EditBreakTimeComponent implements OnInit {
  datas: any;
  breakTime: any;
  userObject: any;
  currentRecordId: any;
  payload: any;
  store_id: any;
  constructor(private userService: UserService,public dialogRef: MatDialogRef<EditBreakTimeComponent>,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.datas = data['dial_data']
    }

  ngOnInit() {
    this.store_id = localStorage.getItem('currentStore');
    if (this.datas.key == 0) {
      this.breakTime = this.datas.element.time_clock.break_time ? this.datas.element.time_clock.break_time : '00:00:00';
      this.currentRecordId = this.datas.element.time_clock.id;
      this.userObject = this.datas.element.time_clock.user;
    } else if (this.datas.key == 1) {
      this.breakTime = this.datas.element.break_time ? this.datas.element.break_time : '00:00:00';
      this.currentRecordId = this.datas.element.id;
      this.userObject = this.datas.element.user;
    }
  }

  editAddBreakTime(breaktime) {
    var a = breaktime.split(':');
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    this.payload = {
      'pk': this.currentRecordId,
      'user': this.userObject,
      'seconds': seconds
    }
    this.userService.updateUserBreakTimeClock(this.payload, this.store_id).subscribe(data => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialog.closeAll();
      this.alertPopup('Time Clock has been edited successfully.');
    },
    error => {
      this.alertPopup('Error while editing the break time clock.');
    })
  }

  formatDate(date){
    let e_date = new Date();
    if(date){
      //  2022-02-10T21:10:30.806000Z
      return moment(date).format('HH:mm');
    } else {
      return '';
    }
  }

  unformatDate(date){
    if(date){
      return moment(date,'hh:mm a MM-DD-YYYY').format();
    } else {
      return '';
    }
  }

  alertPopup(msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
