import {MenuItemGroupType, MenuItemType, ModifierGroupType, ModifierOptionType} from '../_models/menu-v2';

import {RxCollection, RxDocument, RxJsonSchema} from 'rxdb';


export type ModifierOptionDocument = RxDocument<ModifierOptionType>;
export type ModifierOptionCollection = RxCollection<ModifierOptionType>;

export const modifierOptionSchema: RxJsonSchema<ModifierOptionType> = {
  title: 'modifier group schema',
  description: 'describes a simple modifier group',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    name: {
      type: 'string',
    },
    modifier_group: {
      type: 'number',
    },
    upcharge: {
      type: 'string',
    },
    mod_type: {
      type: ['string', 'null'],
    },
    is_available: {
      type: 'boolean',
    },
    has_quantity: {
      type: 'boolean',
    },
    fixed_price: {
      type: 'boolean',
    },
    has_half_mod: {
      type: 'boolean',
    },
    half_mod_upcharge: {
      type: ['string', 'null']
    },
    max_mod_units: {
      type: ['number', 'null']
    },
    order: {
      type: 'number',
      multipleOf: 1,
      minimum: 0,
      maximum: 999
    },
  },
  required: ['id', 'name', 'modifier_group', 'upcharge', 'mod_type', 'is_available', 'has_quantity',
    'fixed_price', 'has_half_mod', 'half_mod_upcharge', 'max_mod_units'],
  indexes: ['order']
};
