import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { OrderService } from 'src/app/_services/order.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { BotOrder, Modifier, OccasionDetail, Order, OrdersWithPayment } from 'src/app/_models/order';
import * as moment from 'moment';
import { Store } from 'src/app/_models/store';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { StoreService } from 'src/app/_services/store.service';
import { CurrentOrderService } from 'src/app/_services/current-order.service';
import { POSOrderTabChangeService } from 'src/app/_services/pos-order-tab-change.service';
import { OrderTypeTabChangeService } from 'src/app/_services/order-type-tab-change.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { PaymentOptionsDialogComponent } from 'src/app/orders/payment-options-dialog/payment-options-dialog.component';
import { CardPaymentMqttComponent } from 'src/app/orders/card-payment-mqtt/card-payment-mqtt.component';
import { CheckoutDialogComponent } from 'src/app/orders/checkout-dialog/checkout-dialog.component';

@Component({
  selector: 'app-deliveries-dispatch',
  templateUrl: './deliveries-dispatch.component.html',
  styleUrls: ['./deliveries-dispatch.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeliveriesDispatchComponent implements OnInit {
  displayedColumns = ['placed', 'order', 'source', 'occasion', 'servername', 'drivername', 'payment', 'total', 'status', 'dispatch', 'selectdriver'];
  dataSource: any; 
  drivers:any[];
  storeId: any;
  driverId:any;
  dataCount: any;
  pageNumber: any = 0;
  pageSize: any = 10;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  expandedElement: PeriodicElement | null;
  order: OrdersWithPayment;
  @Input() store: Store;
  occasionDetail: OccasionDetail;
  setOccasionDetail: OccasionDetail;
  terminalId: any;
  history = [];
  
  constructor(
    private orderService: OrderService,
    private storeService: StoreService,
    private snackBar: MatSnackBar,
    private orderTabChangeService: POSOrderTabChangeService,
    private currentOrderService: CurrentOrderService,
    private orderTypeTabChangeService: OrderTypeTabChangeService,
    private posSummaryToggleService: POSSummaryToggleService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.storeId= localStorage.getItem('currentStore'); 
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });

    if (this.order) {
      this.occasionDetail = this.order.getOccasionDetail();
    }
    this.terminalId = localStorage.getItem("selectedTerminal");
    this.fetchDeliveryOrders();
    this.driverUserList();
  }
  
  fetchDeliveryOrders(){
    this.orderService.deliveryOrderList(this.storeId, this.pageNumber, this.pageSize).subscribe((data:any) => {
      this.dataSource = data.orders;
      this.dataCount = data.total;
    });
    this.expandedElement = null;
  }

  driverUserList(){
    this.orderService.driverList(this.storeId).subscribe((driverData: any) => {      
      this.drivers = driverData.data;
    });
  }

  onDriverSelected(event: any, element: any) {
    const payload = {
      user_id: event.user_id,
      hash_id: element.order_hash,
    };
    this.orderService.assignOrderToDriver(payload, this.storeId).subscribe((result:any) => {
      this.fetchDeliveryOrders();
    });
  }

  OrderStatusUpdate_done(status, element: any) {
    if (element.is_submitted === false) {
        console.log('is_submitted.....', element.is_submitted);
        this.expandedElement = element;
    } else {
        // Do something else when is_submitted is true
        this.orderStatusUpdate(status, element);
    }
}
  orderStatusUpdate(status, element: any) {
    if (status != 1 && element.driver_id) {
      const payload = {
        order_hash: element.order_hash,
        delivery_status: status,
      };
      this.orderService.updateOrderStatus(payload, this.storeId).subscribe((data:any) => {
        if (data) {
          this.fetchDeliveryOrders();
        }
      });
    } else {
      this.snackBar.open("Please select the driver.", "OK");
    }

    if (!element.is_submitted) {
      // Open checkbox directly
      this.expandedElement = element;
      console.log('this.expandedElement',this.expandedElement);
      
    }
  }
  yourHandler(q) {
    this.pageNumber = q.pageIndex;
    this.pageSize = q.pageSize;
    this.fetchDeliveryOrders();
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  onRowClicked(row) {
    console.log(row);
    this.order = undefined;
    this.orderService.getOrder(row.order_hash).subscribe(data => {
      this.order = Object.assign(new OrdersWithPayment(), data, {
        bot_order: Object.assign(new BotOrder(), data.bot_order, {
          occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
          created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
          updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
          submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
        }),
        store: Object.assign(new Store(), data.store),
        items: data.items.map(o => Object.assign(new Order(), o, {
          modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
        })),
        payment: OrderHelpers.parsePayment(data.payment)
      });
      this.loadHistory(this.order.bot_order.order_hash);
    });
  }

  loadHistory(order_hash) {
    this.orderService.history(order_hash).subscribe(history => {
      this.history = history;
      // if (this.history.length === 1) {
      //   this.paymentForm.get('history_id').setValue(this.history[0].id);
      // }
    });
  }

  edit() {
    this.setOccasionDetail = this.occasionDetail;
    localStorage.setItem('setOccasionDetail', JSON.stringify(this.order.getOccasionDetail()));
    if (this.store.hasModule('POS')) {
      localStorage.setItem('order_hash', this.order.bot_order.order_hash);
      this.currentOrderService.changeCurrentOrder(this.order);
      this.orderTypeTabChangeService.changeTab(0);
      this.orderTabChangeService.changeTab(2);
      this.posSummaryToggleService.toggle('OPEN')
    } else {
      this.orderTypeTabChangeService.changeTab(0);
      this.alertPopup('Please login and select the station');
      // alert("Please login and select the station")
    }
  }

  checkout() {
    if (!this.order.bot_order.driver_id) {
      this.snackBar.open("Please select the driver.", "OK", {
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      return;
    }
    this.currentOrderService.changeCurrentOrder(this.order);
    if (this.store.enable_consumer_choice) {
      if(this.order.bot_order){
        this.openConsumerChoiceDialog();
      }
    } else if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      this.openBBPOSCheckout();
    } else {
      this.openRegularCheckout();
    }
  }

  afterOrderClosed() {
    localStorage.removeItem('order_hash')
    if (this.occasionDetail != null) {
      this.setOccasionDetail = this.occasionDetail;
    }
    this.order = null;
    if (this.store.pos_setting && !this.store.pos_setting["default_load"]) {
      this.occasionDetail = null;
    }
    this.ngOnInit()
    this.orderService.orderDoneSubject.next();
    localStorage.removeItem('setOccasionDetail');
  }

  openConsumerChoiceDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(PaymentOptionsDialogComponent, {
      width: '80vw', height: '90vh',
      disableClose: true,
      data: {
        command: 'SALE',
        openOrder: true,
        order_id: this.order.bot_order.order_hash,
        orderWithPayment: this.order,
        terminalId: this.terminalId,
        total: this.order.payment.total,
        subTotal: this.order.payment.sub_total,
        cashier_id: localStorage.getItem('posLoggedUser'),
        terminal_name: localStorage.getItem('selectedTerminalName')
      }
    });

    dialogRef.afterClosed().subscribe(isComplete => {
      console.log(isComplete);

      if (isComplete) {
        this.afterOrderClosed();
      }
    });
  }

  openBBPOSCheckout() {
    console.log('WS implementation');
    let data = {
      terminal_id: localStorage.getItem('selectedTerminal'),
      command: 'SALE',
      order_id: this.order.bot_order.order_hash,
      amount: this.order.getTotal(),
      orderWithPayment: this.order,
      terminalId: this.terminalId,
      total: this.order.payment.total,
      cashTotal: this.order.payment.cash_due_total,
      subTotal: this.order.payment.sub_total,
      payment_type: 'CREDIT',
      is_open_tab: false,
      cashier_id: localStorage.getItem("posLoggedUser"),
      pin_user: localStorage.getItem('pinUser'),
      terminal_name: localStorage.getItem('selectedTerminalName')
    };
    let dialogRef = this.dialog.open(CardPaymentMqttComponent, {
      width: '630px', data: data, disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.afterOrderClosed();
      }
    });
  }

  openRegularCheckout() {
    const orderHash = localStorage.getItem('order_hash');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      width: '630px',
      disableClose: true,
      data: {
        orderWithPayment: this.order,
        terminalId: this.terminalId,
        total: this.order?.payment?.total,
        cashTotal: this.order?.payment?.cash_due_total,
        subTotal: this.order?.payment?.sub_total,
      }
    });
    dialogRef.afterClosed().subscribe(isComplete => {
    });
  }

  alertPopup(msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

export interface PeriodicElement {
  placed:string;
  order: string;
  source: number;
  occasion: string;
  servername: string;
  drivername: string;
  payment: string;
  total: string;
  status: string;
  dispatch: string;
}
