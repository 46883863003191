import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {days, occasion, occasionRi} from 'src/app/constants';
import {FormUtils} from 'src/app/_helpers/form-utils';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-store-lead-time',
  templateUrl: './store-lead-time.component.html',
  styleUrls: ['./store-lead-time.component.scss']
})
export class StoreLeadTimeComponent implements OnInit {

  store:Store;
  form:UntypedFormGroup;

  days = days
  occasion = occasion
  hours;
  lead_times;
  constructor(private formBuilder: UntypedFormBuilder,
    private storeService: StoreService,
    private formUtils:FormUtils,
    public dialogRef: MatDialogRef<StoreLeadTimeComponent>) {
      this.hours = formUtils.buildTimeOptions();
      console.log(this.hours);

    }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if(store) {
        this.store = store;
        this.form = this.formBuilder.group({
          store_id: this.store.id,
          pickup_dine_in_lead_time: [this.store.pickup_dine_in_lead_time, Validators.required],
          custom_pickup_lead_times: new UntypedFormArray(this.store.lead_times.filter(t => t.occasion === 1)
            .map(t => this.getExistingLeadTimeRow(t))),
          dine_in_lead_time: [this.store.dine_in_lead_time, Validators.required],
          custom_dine_in_lead_times: new UntypedFormArray(this.store.lead_times.filter(t => t.occasion === 2)
            .map(t => this.getExistingLeadTimeRow(t))),
          delivery_lead_time: [this.store.delivery_lead_time, Validators.required],
          custom_delivery_lead_times: new UntypedFormArray(this.store.lead_times.filter(t => t.occasion === 3)
            .map(t => this.getExistingLeadTimeRow(t))),
        });

        this.lead_times = this.form.get('lead_time') as UntypedFormArray;
      }
    });
  }

  hourCompare(left, right){
    return left.value === right.value;
  }

  getExistingLeadTimeRow(data){
    let from12 = this.formUtils.get12HourValue(data.from_hour, data.from_min)
    let fromOptions = {
      hour: data.from_hour,
      min: data.from_min,
      key: from12.replace(' am', '').replace(' pm', ''),
      value: from12
    }

    let to12 = this.formUtils.get12HourValue(data.to_hour, data.to_min)
    let toOptions = {
      hour: data.to_hour,
      min: data.to_min,
      key: to12.replace(' am', '').replace(' pm', ''),
      value: to12}

    return this.formBuilder.group({
      occasion: [data.occasion, Validators.required],
      day: [data.day, Validators.required],
      from: [fromOptions.value, Validators.required],
      to: [toOptions.value, Validators.required],
      lead_time: [data.lead_time, Validators.required],
    });
  }

  getLeadTimeRow(occasion){
    return this.formBuilder.group({
      occasion: [occasion, Validators.required],
      day: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      lead_time: ['', Validators.required],
    });
  }


  getKey(occasion){
    let key = null;
    switch(occasion) {
      case 'pickup': {
        key = 'custom_pickup_lead_times';
        break;
      }
      case 'dine-in': {
        key = 'custom_dine_in_lead_times';
        break;
      }
      case 'delivery':{
        key = 'custom_delivery_lead_times';
        break;
      }
    }
    return key;
  }

  addPickupLeadTime() {this.addLeadTime('pickup')}
  addDineInLeadTime() {this.addLeadTime('dine-in')}
  addDeliveryLeadTime() {this.addLeadTime('delivery')}
  addLeadTime(occasion){
    let key = this.getKey(occasion);
    (this.form.controls[key] as UntypedFormArray).push(this.getLeadTimeRow(occasionRi[occasion]));
  }

  deleteLeadTime(occasion, index){
    let key = this.getKey(occasion);
    (this.form.controls[key] as UntypedFormArray).removeAt(index);
  }

  submit(form){
    if(form.valid){
      let payload = form.value
      let lead_times = []
      lead_times = lead_times
        .concat(payload.custom_pickup_lead_times)
        .concat(payload.custom_dine_in_lead_times)
        .concat(payload.custom_delivery_lead_times);
      let stor_lead_times = []
      lead_times.map(sh => {
        const convertTo24Hour = (time, meridiem) => {
          let [hour, minute] = time.split(':').map(Number);
          if (meridiem.toLowerCase() === 'pm' && hour !== 12) {
            hour += 12;
          }
          if (meridiem.toLowerCase() === 'am' && hour === 12) {
            hour = 0;
          }
          return { hour: hour.toString().padStart(2, '0'), minute: minute.toString().padStart(2, '0') };
        };
        // Convert from time
        const [timeFrom, meridiemFrom] = sh.from.split(' ');
        const { hour: hourFrom24, minute: minFrom24 } = convertTo24Hour(timeFrom, meridiemFrom);
        const fromOptions = {
          hour: hourFrom24,
          min: minFrom24,
          key: `${hourFrom24}:${minFrom24}`,
          value: `${hourFrom24}:${minFrom24}`
        };
        // Convert to time
        const [timeTo, meridiemTo] = sh.to.split(' ');
        const { hour: hourTo24, minute: minTo24 } = convertTo24Hour(timeTo, meridiemTo);
        const toOptions = {
          hour: hourTo24,
          min: minTo24,
          key: `${hourTo24}:${minTo24}`,
          value: `${hourTo24}:${minTo24}`
        };
        stor_lead_times.push({
          "occasion": sh.occasion,
          "day": sh.day,
          "from": fromOptions,
          "to": toOptions,
          "lead_time": sh.lead_time
        });

        sh.from = `${hourFrom24}:${minFrom24}`;
        sh.to = `${hourTo24}:${minTo24}`;
      });
      payload['lead_times'] = stor_lead_times;
      this.storeService.updateCustomLeadTime(payload).subscribe(data => {
        this.dialogRef.close()
      })
    }
  }

  getCustomLeadTime(key){
    return (this.form.controls[key] as UntypedFormArray).controls
  }
}
