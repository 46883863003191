<ng-container> 
  <form> 
    <div class="input-box">
      <input class="role-input" name="newrole" id="newrole" #newrole [value]="role.role_name">    
    </div>
    <div mat-dialog-actions class="act">
      <button mat-stroked-button mat-dialog-close class="buttonCancel">Cancel</button>
      <button mat-flat-button color="primary" type="button" (click)="editRole(newrole.value)" class="buttonConfirm">
        Confirm</button>
    </div>
  </form>
</ng-container>



