<div style="padding: 10px 25px">
  <div>
    <p class="mFilterHeading">
      Filters for Product Mix Reporting
    </p>
    <p class="mFilterLine">Select the Rows you would like to see in the report</p>
    <div>
      <table class="filtersetting-table" *ngFor="let item of itemss">
        <tr>
          <th>Items Sales</th>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.item_sales_total" (change)="changeSelection()">Total</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.item_sales_online" (change)="changeSelection()">Online</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.item_sales_pos" (change)="changeSelection()">POS</mat-checkbox></td>
        </tr>
        <tr>
          <th>Gross Sales</th>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.gross_sales_total" (change)="changeSelection()">Total</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.gross_sales_online" (change)="changeSelection()">Online</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.gross_sales_pos" (change)="changeSelection()">POS</mat-checkbox></td>
        </tr>
        <tr>
          <th>Sales Mix %</th>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.sales_mix_total" (change)="changeSelection()">Total</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.sales_mix_online" (change)="changeSelection()">Online</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.sales_mix_pos" (change)="changeSelection()">POS</mat-checkbox></td>
        </tr>
        <tr>
          <th>Quantity Mix %</th>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.qty_mix_total" (change)="changeSelection()">Total</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.qty_mix_online" (change)="changeSelection()">Online</mat-checkbox></td>
          <td><mat-checkbox class="example-margin" [(ngModel)]="item.qty_mix_pos" (change)="changeSelection()">POS</mat-checkbox></td>
        </tr>
      </table>
    </div>
  </div>
  <div style="float: right;">
    <button class="settingCancelButton" (click)="cancelFilterButton()" mat-raised-button>Cancel</button>
    <button class="settingApplyButton" (click)="updateManage()" mat-raised-button>Apply</button>
  </div>
</div>