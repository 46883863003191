import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';
import {occasion, occasionNew} from 'src/app/constants';

@Component({
  selector: 'app-last-order-buffer',
  templateUrl: './last-order-buffer.component.html',
  styleUrls: ['./last-order-buffer.component.scss']
})
export class LastOrderBufferComponent implements OnInit {

  form: UntypedFormGroup;
  store: Store;
  occasion = occasion;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    public dialogRef: MatDialogRef<LastOrderBufferComponent>
  ) {
    this.store = data.store;
  }

  ngOnInit() {
    console.log(this.store.last_order_buffer);

    if (this.store?.modules.includes('POS')) {
      this.occasion = occasionNew;
    } else {
      this.occasion = occasion;
    }
    this.form = new UntypedFormGroup({
      'PICKUP': new UntypedFormControl(this.store.last_order_buffer['PICKUP'] || 0, [Validators.min(0)]),
      'DINE-IN': new UntypedFormControl(this.store.last_order_buffer['DINE-IN'] || 0, [Validators.min(0)]),
      'DELIVERY': new UntypedFormControl(this.store.last_order_buffer['DELIVERY'] || 0, [Validators.min(0)]),
      'CURBSIDE': new UntypedFormControl(this.store.last_order_buffer['CURBSIDE'] || 0, [Validators.min(0)]),
      'TABLESIDE': new UntypedFormControl(this.store.last_order_buffer['TABLESIDE'] || 0, [Validators.min(0)]),
    })

    if (this.store?.modules.includes('POS')) {
      this.form.addControl('TO-GO', new UntypedFormControl(this.store.last_order_buffer['TO-GO'] || 0, [Validators.min(0)]));
      this.form.addControl('FOR-HERE', new UntypedFormControl(this.store.last_order_buffer['FOR-HERE'] || 0, [Validators.min(0)]));
    }
  }

  submit(form){
    let payload = {
      store: this.store.id,
      last_order_buffer: form.value
    }
    this.storeService.updateLastOrderBuffer(payload).subscribe(data => {
      this.dialogRef.close()
    })

  }

}
