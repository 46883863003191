<div class="p-4">
    <span *ngIf="data.from === 'add-floor' || data.from === 'edit-floor'">
        <p class="font-medium text-xl mb-7">
            <span *ngIf="data.from === 'add-floor'">Add Floor</span>
            <span *ngIf="data.from === 'edit-floor'">Edit Floor</span>
        </p>
        <form [formGroup]="floorForm">
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>Name</mat-label>
                <input matInput id="name" formControlName="name" placeholder="Enter floor name">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>Width</mat-label>
                <input matInput id="width" formControlName="width" type="number" placeholder="Enter width">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>Height</mat-label>
                <input matInput id="height" formControlName="height" type="number" placeholder="Enter height">
            </mat-form-field>
            <div class="flex justify-end mt-8">
                <button mat-button mat-stroked-button class="mr-3 h-[40px]" (click)="onCancel()">Cancel</button>
                <button mat-raised-button color="primary" type="submit" [disabled]="floorForm.invalid"
                    (click)="onSubmit()">Submit</button>
            </div>
        </form>
    </span>

    <span *ngIf="data.from === 'add-table' || data.from === 'edit-table'">
        <p class="font-medium text-xl mb-7">
            <span *ngIf="data.from === 'add-table'">Add Table</span>
            <span *ngIf="data.from === 'edit-table'">Edit Table</span>
        </p>
        <form [formGroup]="tableForm">
            <div class="flex flex-col">
                <div class="flex">
                    <div class="w-1/2">
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>Table Name</mat-label>
                            <input matInput id="table_name" formControlName="table_name" placeholder="Enter table name">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>Table Type</mat-label>
                            <mat-select id="shape" formControlName="shape" placeholder="Select table type"
                                (selectionChange)="onTableTypeChange($event.value)">
                                <mat-option value="round">Round</mat-option>
                                <mat-option value="rectangle">Rectangle</mat-option>
                                <mat-option value="square">Square</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>Seat</mat-label>
                            <input matInput id="seating_capacity" formControlName="seating_capacity" type="number"
                                placeholder="Seat">
                        </mat-form-field>
                        <span *ngIf="data.from === 'edit-table'">
                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label>Width</mat-label>
                                <input matInput id="width" formControlName="width" type="number" placeholder="Enter width">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label>Height</mat-label>
                                <input matInput id="height" formControlName="height" type="number" placeholder="Enter height">
                            </mat-form-field>
                        </span>
                    </div>

                    <div class="w-1/2 flex items-center justify-center">
                        <div #shapeContainer>
                            <ng-container *ngIf="selectedShape === 'round'">
                                <div [style.transform]="'rotate(' + rotation + 'deg)'"
                                    class="relative w-[130px] h-[130px] rounded-full border-2 border-black bg-white flex items-center justify-center">
                                    <mat-icon class="cursor-pointer text-gray-500 hover:text-black text-4xl"
                                        (click)="rotateShape()">autorenew</mat-icon>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="selectedShape === 'rectangle'">
                                <div [style.transform]="'rotate(' + rotation + 'deg)'"
                                    class="relative w-[180px] h-[120px] border-2 border-black bg-white flex items-center justify-center">
                                    <mat-icon class="cursor-pointer text-gray-500 hover:text-black text-4xl"
                                        (click)="rotateShape()">autorenew</mat-icon>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="selectedShape === 'square'">
                                <div [style.transform]="'rotate(' + rotation + 'deg)'"
                                    class="relative w-[130px] h-[130px] border-2 border-black bg-white flex items-center justify-center">
                                    <mat-icon class="cursor-pointer text-gray-500 hover:text-black text-4xl"
                                        (click)="rotateShape()">autorenew</mat-icon>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="flex justify-between mt-8 w-full space-x-4">
                    <button mat-button mat-stroked-button class="w-1/2 h-[40px]" (click)="onCancel()">Cancel</button>
                    <button mat-raised-button color="primary" type="submit" [disabled]="tableForm.invalid"
                        class="w-1/2 h-[40px]" (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </form>
    </span>

    <span *ngIf="data.from === 'add-object' || data.from === 'edit-object'">
        <p class="font-medium text-xl mb-7">
            <span *ngIf="data.from === 'add-object'">Add Object</span>
            <span *ngIf="data.from === 'edit-object'">Edit Object</span>
        </p>
        <form [formGroup]="objectForm">
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>Object Name</mat-label>
                <input matInput id="object_name" formControlName="object_name" placeholder="Object">
            </mat-form-field>
            <span *ngIf="data.from === 'edit-object'">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Width</mat-label>
                    <input matInput id="width" formControlName="width" type="number" placeholder="Enter width">
                </mat-form-field>
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Height</mat-label>
                    <input matInput id="height" formControlName="height" type="number" placeholder="Enter height">
                </mat-form-field>
            </span>

            <div class="flex justify-end mt-8">
                <button mat-button mat-stroked-button class="mr-3 h-[40px]" (click)="onCancel()">Cancel</button>
                <button mat-raised-button color="primary" type="submit" [disabled]="objectForm.invalid"
                    (click)="onSubmit()">Submit</button>
            </div>
        </form>
    </span>
</div>