<div class="p-5">
    <div class="floor-table">
        <button mat-flat-button color="primary" class="apply-btn-colr xs:!w-[100%] mb-[21px]"
            (click)="newFloorAdded()">Add
            Floor</button>

        <div class="w-full overflow-x-auto">
            <table mat-table multiTemplateDataRows width="100%" [dataSource]="dataSource">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let element" data-label=""> {{ element.id }} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element" data-label="">
                        {{ element.name }} </td>
                </ng-container>

                <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef> Width </th>
                    <td mat-cell *matCellDef="let element" data-label=""> {{element.width}} </td>
                </ng-container>

                <ng-container matColumnDef="height">
                    <th mat-header-cell *matHeaderCellDef> Height</th>
                    <td mat-cell *matCellDef="let element" data-label=""> {{element.height}} </td>
                </ng-container>

                <ng-container matColumnDef="button">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" data-label="">
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="createFloorPlan(element)">Floor Plan</button>
                            <button mat-menu-item (click)="editFloorPlan(element)">Edit Floor</button>
                            <button mat-menu-item (click)="deleteFloorPlan(element)">Delete</button>
                        </mat-menu>

                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="highlight">
                        <div class="example-element-detail misc-detail" *ngIf="element"
                            [@detailExpand]="element.id === expandedRowId ? 'expanded' : 'collapsed'">
                            <mat-tab-group>
                                <mat-tab label="TABLE LIST">
                                    <table mat-table multiTemplateDataRows width="100%" [dataSource]="element.tables"
                                        style="background-color: #ededed;">
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.id}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="table_name">
                                            <th mat-header-cell *matHeaderCellDef> Table Name </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.table_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="seating_capacity">
                                            <th mat-header-cell *matHeaderCellDef> Capacity </th>
                                            <td mat-cell *matCellDef="let element" data-label="">
                                                {{element.seating_capacity}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="shape">
                                            <th mat-header-cell *matHeaderCellDef> Shape </th>
                                            <td mat-cell *matCellDef="let element" data-label="">
                                                <div class="flex">
                                                    <div *ngIf="element.shape === 'square'"
                                                        class="bg-gray-300 w-[3rem] h-[3rem] my-1.5"></div>
                                                    <div *ngIf="element.shape === 'rectangle'"
                                                        class="bg-gray-300 w-[50%] h-[3rem] my-1.5"></div>
                                                    <div *ngIf="element.shape === 'round'"
                                                        class="bg-gray-300 w-[3rem] h-[3rem] rounded-full my-1.5"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="width">
                                            <th mat-header-cell *matHeaderCellDef> Width </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.width}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="height">
                                            <th mat-header-cell *matHeaderCellDef> Height </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.height}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="created_at">
                                            <th mat-header-cell *matHeaderCellDef> Created At </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.created_at |
                                                date:
                                                'MM/dd/yyyy hh:mm a'}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element" data-label="">
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item (click)="deleteTable(element)">Delete</button>
                                                    <button mat-menu-item (click)="editTable(element)">Edit</button>
                                                    <button mat-menu-item (click)="copy(element)">Create copy</button>
                                                    <button mat-menu-item (click)="resetTableState(element)">Reset Table State</button>
                                                </mat-menu>

                                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="!h-[52px]"></tr>
                                    </table>
                                </mat-tab>
                                <mat-tab label="OBJECT LIST">
                                    <table mat-table multiTemplateDataRows width="100%" [dataSource]="element.objects"
                                        style="background-color: #ededed;">
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.id}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="table_name">
                                            <th mat-header-cell *matHeaderCellDef> Object Name </th>
                                            <td mat-cell *matCellDef="let element" data-label="">
                                                {{element.object_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="width">
                                            <th mat-header-cell *matHeaderCellDef> Width </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.width}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="height">
                                            <th mat-header-cell *matHeaderCellDef> Height </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.height}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="created_at">
                                            <th mat-header-cell *matHeaderCellDef> Created At </th>
                                            <td mat-cell *matCellDef="let element" data-label=""> {{element.created_at |
                                                date:
                                                'MM/dd/yyyy hh:mm a'}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element" data-label="">
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item
                                                        (click)="deleteObject(element)">Delete</button>
                                                    <button mat-menu-item (click)="editObject(element)">Edit</button>
                                                </mat-menu>

                                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="objectColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: objectColumns;" class="!h-[52px]">
                                        </tr>
                                    </table>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="onRowClicked(row,0)"
                    [class.example-expanded-row]="expandedRowId === row.id"
                    (click)="expandedRowId = expandedRowId === row.id ? null : row.id">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>
    </div>
</div>