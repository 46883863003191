import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UserService} from '../../_services/user.service';
import * as moment from 'moment';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-time-clock',
  templateUrl: './edit-time-clock.component.html',
  styleUrls: ['./edit-time-clock.component.scss']
 })

export class EditTimeClockComponent implements OnInit {

  msg: string;
  userObject: any;
  currentRecordId: any;
  action: any;
  date: Date;
  payload: any;
  numberOfBreaks: any;
  element: any;
  clockedIn: any;
  clockedOut: any;
  changedDate = new Date();
  updateCLock: boolean;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<EditTimeClockComponent>,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.element = data['element']
    }

  ngOnInit() {
    this.clockedIn = this.formatDate(this.element.clock_in_time);
    this.clockedOut = this.formatDate(this.element.clock_out_time);
    this.currentRecordId = this.element.id;
    this.userObject = this.element.user;
  }

  editTime(clockedin, clockedout){
    this.payload = {
      'pk': this.currentRecordId,
      'user': this.userObject
    }
    if(clockedin){
      this.payload['clock_in_time'] = this.unformatDate(clockedin);
    }
    if(clockedout){
      this.payload['clock_out_time'] = this.unformatDate(clockedout);
    }
    this.userService.updateUserTimeClock(this.payload).subscribe(data => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      if (data.success === 'false') {
        this.updateCLock = false;
      } else if (data.success === 'true') {
        this.updateCLock = true;
      }
      const message = data.message ? data.message : 'Time Clock has been edited successfully.';
      this.alertPopup(message);
    },
    error => {
      this.alertPopup('Error while editing the time clock.');
    })
  }

  formatDate(date){
    let e_date = new Date();
    if(date){
      //  2022-02-10T21:10:30.806000Z
      return moment(date).format('hh:mm a MM-DD-YYYY');
    } else {
      return moment(e_date).format('hh:mm a MM-DD-YYYY');
    }
  }
  unformatDate(date){
    if(date){
      return moment(date,'hh:mm a MM-DD-YYYY').format();
    } else {
      return '';
    }
  }

  alertPopup(msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.updateCLock) {
        this.dialog.closeAll();
      }

    });
  }
}
