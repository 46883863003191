<span>
  <div class="add-restaurant-button">
    <button mat-stroked-button class="restaurant action-btn-colr" (click)="addRestaurant()"> Add Image</button>
  </div>
</span>
<table mat-table [dataSource]="dataSource" width="100%" class="screenTable xs:!table-fixed">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef> Position </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.position == 'below_item'; else elseData">
        Below Menu Item
      </span>
      <ng-template #elseData>
        {{element.position}}
      </ng-template>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef> Category </th>
    <td mat-cell *matCellDef="let element"> {{element.Category}} </td>
  </ng-container> -->

  <ng-container matColumnDef="image_width">
    <th mat-header-cell *matHeaderCellDef> Image Width </th>
    <td mat-cell *matCellDef="let element"> {{element.image_width}}% </td>
  </ng-container>

  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef> Image </th>
    <td mat-cell *matCellDef="let element"><a href="{{element.image}}" target="_blank"
        style="color:#4088e5;text-decoration:none"><img src="{{element.image}}"
          style="height:30px;width:30px;vertical-align: bottom;" /> <span style="vertical-align:super;"> Open
          File</span></a></td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menuScreen="matMenu">
        <button mat-menu-item (click)="editrestaurant(element)">
          <span class="material-icons-outlined edit-delete-icon">
            edit
          </span>&nbsp;
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="deleterestaurant(element)">
          <span class="material-icons edit-delete-icon">
            delete_outline
          </span>&nbsp;
          <span>Delete</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]">
</mat-paginator>