
<div [ngStyle]="{'max-height': maxHeightMenuItem}" class="order-item-grid grid grid-cols-5 xs:grid-cols-3 gap-1 overflow-y-auto overflow-x-hidden xs:!m-1 xs:!h-auto 2xl:h-auto place-content-start">
  <button class="border border-gray-300 bg-white h-24 xs:!h-20 text-lg xs:!text-xs xs:rounded-md min-h-20 hover:bg-gray-100
  text-ellipsis p-2 drop-shadow-md dark-bg-color"
          [ngClass]="{ selected: selected === item.id, 'disabled-cta': !validUserData?.sessionId }"
          *ngFor="let item of items" 
          (mousedown)="onMouseDown(item)" 
          (mouseup)="onMouseUp()" 
          (mouseleave)="onMouseLeave()" 
          (touchstart)="onMouseDown(item)" 
          (touchend)="onMouseUp()" 
          (click)="onSelectHandler.next(item)">
    <span>{{ item.name }}</span>
    <span *ngIf="item.starting_price">({{ item.starting_price | currency }})</span>
  </button>
</div>
