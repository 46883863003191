import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UploadService} from 'src/app/_services/upload.service';
import {forkJoin} from 'rxjs';
import {StoreMerchant} from 'src/app/_models/store';
import {environment} from '../../../environments/environment';
import {StoreService} from 'src/app/_services/store.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-upload-favicon-dialog',
  templateUrl: './upload-favicon-dialog.component.html',
  styleUrls: ['./upload-favicon-dialog.component.scss']
})
export class UploadFaviconDialogComponent implements OnInit {

  @ViewChild('file', {static: true}) file;
  public files: Set<File> = new Set()
  progress
  canBeClosed = true
  primaryButtonText = 'Upload'
  showCancelButton = true
  uploading = false
  uploadSuccessful = false
  storeMerchant: StoreMerchant
  _for:string
  multiple:boolean;
  version:number

  uploadUrls = {
    "voidedCheck": `${environment.apiUrl}/backend/v2/stores/voided_checks/`,
    "menu": `${environment.apiUrl}/backend/v2/stores/upload/menu/`,
    "logo": `${environment.apiUrl}/backend/v2/stores/favicon/`,

  }


  constructor(
    public dialogRef: MatDialogRef<UploadFaviconDialogComponent>,
    public uploadService: UploadService,
    public storeService:StoreService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.storeMerchant = data.storeMerchant
      this._for = data._for
      this.multiple = data.multiple
      this.version = data.version
    }

  ngOnInit() {
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
  }

  closeDialog() {
    // if everything was uploaded already, just close the dialog
    if (this.uploadSuccessful) {
      return this.dialogRef.close();
    }

    // set the component state to "uploading"
    this.uploading = true;
    let uploadUrl = this.uploadUrls[this._for]
    console.log(this._for, uploadUrl);

    // start the upload and save the progress map
    this.progress = this.uploadService.upload(uploadUrl,
      this.files,
      {"store_id": this.storeMerchant.store.id, "version": this.version});

    // convert the progress map into an array
    let allProgressObservables = [];
    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    // Adjust the state variables

    // The OK-button should have the text "Finish" now
    this.primaryButtonText = 'Finish';

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe(end => {
      if(this._for === 'menu'){
        this.storeService.triggerMenuUploadEmail(this.storeMerchant.store.id, this.version).subscribe(status => {
          // alert("menu uploaded successfully!")
          let dialogRef = this.dialog.open(AlertsMessagesComponent, {
            disableClose: true,
            width: '364px',
            minHeight: '20vh',
            data: {
              message: 'menu uploaded successfully!'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.closeFlags();
          });
        })
      }else{
        this.closeFlags();
      }
    });
  }

  closeFlags(){
    this.canBeClosed = true;
    this.dialogRef.disableClose = false;
    this.uploadSuccessful = true;
    this.uploading = false;
  }

}
