import { Component, OnInit, ViewChild } from '@angular/core';
import { LoyaltyPointsService } from 'src/app/_services/loyalty-points.service';
import { Counter, CounterService } from 'src/app/_services/counter.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe, formatDate } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-referral',
  templateUrl: './user-referral.component.html',
  styleUrls: ['./user-referral.component.scss'],
  animations: [
    trigger('detailExpand', [state('collapsed, void', style({ height: '0px' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')), transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])
  ],
})
export class UserReferralComponent implements OnInit {
  input: number = 0;
  storeId: any;
  countData: any;
  userCount: any;
  pointsTotal: any;
  redeemedPoints: any;
  pointsEarned: any;
  outstansingPoints: any;
  displyy = false;
  expandedRowId;
  selectedRow;
  phoneNumber: any;
  pageNumber: any;
  pageSize: any;
  filterValue: any;

  arrLoyalty = {
    "numbers": [],
    "colors": [],
    "isUpdate": 0,
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  columnsToDisplay = ['name', 'phone_number', 'status',
    'user_order_visit', 'total_spend', 'average_spend', 'last_visit', 'since'];
  dataSource: any;
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'action'];
  expandedElement: PeriodicElement | null;
  innerLoyaltyDisplayedColumns = ['order_id', 'order_date', 'order_time',
    'payment_type', 'total_spend', 'occasion', 'pos_online'];
  innerDatasource: any;
  currentPoints: any;
  userRedeemedPoints; any;
  Daterange: FormGroup;
  innerDaterange: FormGroup;
  from_date: any;
  to_date: any;
  from_date_inner: any;
  to_date_inner: any;
  search_str: any;
  filterCount: any;
  maxDateCheck: string;
  isLoading: boolean = false;

  constructor(
    public loyaltyPointsService: LoyaltyPointsService,
    public countService: CounterService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    // this.usersCountList(0);
    // this.totalPoints();
    // this.tableUserList();

    this.Daterange = this._formBuilder.group({
      from_date: [],
      to_date: [],
      search_str: []
    })

    this.innerDaterange = this._formBuilder.group({
      from_date_inner: [],
      to_date_inner: [],
    })
  }



  onRowClicked(row) {
    this.selectedRow = row;
    this.phoneNumber = this.selectedRow.phone_number;
    this.from_date_inner = '';
    this.to_date_inner = ''
    this.orderList(this.phoneNumber);
  }


  innerFilterSubmit() {
    this.isLoading = true;
    const keyData = {
      "phone_number": this.phoneNumber,
      "store_id": this.storeId
    }
    if (this.innerDaterange.value.from_date_inner != null) {
      this.from_date_inner = formatDate(this.innerDaterange.value.from_date_inner, 'yyyy-MM-dd', 'en');
    } else {
      this.from_date_inner = '';
    }
    if (this.innerDaterange.value.to_date_inner != null) {
      this.to_date_inner = formatDate(this.innerDaterange.value.to_date_inner, 'yyyy-MM-dd', 'en');
    } else {
      this.to_date_inner = ''
    }
    
  }

  innerClearFilter() {
    this.orderList(this.phoneNumber);
  }

  /* LAST THREE ORDER */
  orderList(number) {
    const keyData = {
      "phone_number": number,
      "store_id": this.storeId
    }
    this.from_date_inner = '';
    this.to_date_inner = ''
    
  }

  /* ADD POINTS */
  addPoints(p) {
    const keyData = {
      "phone_number": this.phoneNumber,
      "store_id": this.storeId,
      "points": p
    }
   
  }

  /* SUBTRACT POINTS */
  subtractPoints(p) {
    const keyData = {
      "phone_number": this.phoneNumber,
      "store_id": this.storeId,
      "points": p
    }
    if (this.currentPoints >= p) {
      
    } else {
      this.snackBar.open("Input value must not be greater than current point balance", "OK", {
        duration: 1500,
      });
    }
  }

  longDate(w) {
    if (w.last_visit != null) {
      var ll = this.datePipe.transform(w.last_visit, 'M/dd/yyyy');
      var lastVisit = new Date(ll);
      var dateObj = new Date();
      var cc = this.datePipe.transform(dateObj, 'M/dd/yyyy');
      var currentDate = new Date(cc)
      var Time = currentDate.getTime() - lastVisit.getTime();
      var Days = Time / (1000 * 3600 * 24);
      return Days
    }
    else {
      return '-1'
    }
  }


  filterSubmit() {
    if (this.Daterange.value.from_date != null) {
      this.from_date = formatDate(this.Daterange.value.from_date, 'yyyy-MM-dd', 'en');
    } else {
      this.from_date = '';
    }
    if (this.Daterange.value.to_date != null) {
      this.to_date = formatDate(this.Daterange.value.to_date, 'yyyy-MM-dd', 'en');
    } else {
      this.to_date = ''
    }
    if (this.Daterange.value.search_str != null) {
      this.search_str = this.Daterange.value.search_str;
    } else {
      this.search_str = ''
    }
    
  }

  clearFilter() {
    this.from_date = '';
    this.to_date = '';
    this.pageNumber = 0;
    this.pageSize = 10;
    this.search_str = '';
    this.paginator.pageSize = 10;
    this.paginator.pageIndex = 0;
    this.sort.active = '';
    this.sort.direction = '';
    
  }

  yourHandler(q) {
    if (this.Daterange.value.from_date != null) {
      this.from_date = formatDate(this.Daterange.value.from_date, 'yyyy-MM-dd', 'en');
    } else {
      this.from_date = ''
    }
    if (this.Daterange.value.to_date != null) {
      this.to_date = formatDate(this.Daterange.value.to_date, 'yyyy-MM-dd', 'en');
    } else {
      this.to_date = ''
    }
    if (this.Daterange.value.search_str != null) {
      this.search_str = this.Daterange.value.search_str;
    } else {
      this.search_str = ''
    }
    this.pageNumber = q.pageIndex;
    this.pageSize = q.pageSize;
    if (this.filterValue == undefined) {
      this.filterValue = '';
    }
    if (this.sort.active == undefined) {
      this.sort.active = ''
      this.sort.direction = ''
    }
    
  }

  sortingHeader() {
    
  }

}


export interface PeriodicElement {
  name: any;
  phone_number: any;
  status: any;
  user_order_visit: any;
  total_spend: any;
  average_spend: any;
  last_visit: any;
  since: any;
}
