<form [formGroup]="form" (onSubmit)="create()" class="form fixActionRow">
  <h2 mat-dialog-title>
    Create a new offer
  </h2>
  <mat-dialog-content id="campaign-dialog">
    <div class="div-left image-bottom-space">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title class="xs:!contents sm:!contents">Offer Details</mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <ng-template matExpansionPanelContent> -->
            <form [formGroup]="form.controls.details" class="form-container">
              <mat-form-field appearance="fill" class="full-width" mat-focused>
                <mat-label>Title</mat-label>
                <input matInput #name placeholder="Title" formControlName="offer_title" #title>
              </mat-form-field>

              <mat-form-field appearance="fill" class="full-width" mat-focused>
                <mat-label>Description</mat-label>
                <textarea #text rows="2" matInput #message formControlName="offer_description" #description></textarea>
              </mat-form-field>

              <mat-form-field appearance="fill" class="full-width" mat-focused>
                <mat-label>Promo Code</mat-label>
                <input matInput #promocode placeholder="Promo Code" formControlName="promo_code" (keyup)="updateDisclaimer(promocode.value)">
              </mat-form-field>

              <app-gift-cards-upload-image
                [imageUrl]="imageStr"
                (imageStringEmitter)="setImageStr($event)"
                (imageFileEmitter)="setImageFile($event)"></app-gift-cards-upload-image>
            </form>
          <!-- </ng-template> -->
          <mat-action-row>
            <button mat-button color="primary" class="next" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>


        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
          <mat-expansion-panel-header>
            <mat-panel-title>Trigger</mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <form [formGroup]="form.controls.trigger" class="form-container">

              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Trigger Type</mat-label>
                <mat-select formControlName="trigger_type" #triggerType (selectionChange)="updateTriggerValidation($event)">
                  <mat-option>Select</mat-option>
                  <mat-option value="SPEND">Spend (Amount)</mat-option>
                  <mat-option value="CATEGORY">Buy Category</mat-option>
                  <mat-option value="ITEM">Buy Item</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill"
                class="full-width" mat-focused
                *ngIf="triggerType.value === 'SPEND' || form.get('trigger').get('trigger_type').value === 'SPEND'">
                <mat-label>Minimum spend value</mat-label>
                <input matInput #name placeholder="Minimum spend value" formControlName="spend_threshold" type="number">
              </mat-form-field>

              <div formArrayName="condition"
                *ngIf="triggerType.value === 'CATEGORY' || triggerType.value === 'ITEM'
                || form.get('trigger').get('trigger_type').value === 'CATEGORY'
                || form.get('trigger').get('trigger_type').value === 'ITEM'" class="image-bottom-space">
                <div class="offer-row item-center" *ngFor="let item of triggerConditions.controls; index as i">
                  <ng-container [formGroupName]="i">
                    <div class="offer-col">
                      <mat-form-field appearance="fill" class="width-30">
                        <mat-label>Quantity</mat-label>
                        <mat-select formControlName="quantity">
                          <mat-option>Select</mat-option>
                          <mat-option value="">Any Quantity</mat-option>
                          <mat-option *ngFor="let n of formQuantity" [value]="n">
                            {{n}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col">
                      <mat-form-field appearance="fill" class="width-30">
                        <mat-label>Portion</mat-label>
                        <mat-select formControlName="portion"
                          [compareWith]="objectComparisonFunction">
                          <mat-option>Select</mat-option>
                          <mat-option value="">Any Portion</mat-option>
                          <mat-option *ngFor="let n of portions" [value]="n.id">
                            {{n.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col" *ngIf="triggerType.value === 'CATEGORY' || form.get('trigger').get('trigger_type').value === 'CATEGORY'">
                      <mat-form-field appearance="fill" class="width-40">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="category"
                          [compareWith]="objectComparisonFunction">
                          <mat-option>Select</mat-option>
                          <mat-option *ngFor="let n of categories" [value]="n.id">
                            {{n.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col" *ngIf="triggerType.value === 'ITEM' || form.get('trigger').get('trigger_type').value === 'ITEM'">
                      <mat-form-field appearance="fill" class="width-30">
                        <mat-label>Menu Item</mat-label>
                        <mat-select formControlName="item"
                          [compareWith]="objectComparisonFunction">
                          <mat-option>Select</mat-option>
                          <mat-option *ngFor="let n of menuItems" [value]="n.id">
                            {{n.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col" *ngIf="i > 0">
                      <button mat-icon-button (click)="deleteCondition('trigger', i)">
                        <mat-icon>delete_fill</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                </div>
                <div class="clear"></div>
                <button mat-stroked-button color="primary" class="previous" (click)="addCondition('trigger', triggerType.value)">Add Trigger Condition</button>
              </div>

            </form>
          </ng-template>
          <mat-action-row>
            <button mat-button color="primary" class="previous" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" class="next" (click)="nextStep()" [disabled]="!form.controls.trigger.valid">Next</button>
          </mat-action-row>
        </mat-expansion-panel>


        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
          <mat-expansion-panel-header>
            <mat-panel-title>Offer</mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <form [formGroup]="form.controls.offer_value" class="form-container">
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Offer Type</mat-label>
                <mat-select  formControlName="offer_type" #offerType (selectionChange)="changeValidation($event)">
                  <mat-option>Select</mat-option>
                  <mat-option value="AMOUNT">Amount</mat-option>
                  <mat-option value="PERCENT">Percent</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" class="full-width" mat-focused>
                <mat-label>Offer value</mat-label>
                <input matInput #name placeholder="Offer value" formControlName="offer_value" type="number">
              </mat-form-field>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Applied to</mat-label>
                <mat-select formControlName="applies_to" #appliesToType (selectionChange)="updateOfferValidation($event)">
                  <mat-option>Select</mat-option>
                  <mat-option value="ORDER">Entire order</mat-option>
                  <mat-option value="CATEGORY">Category</mat-option>
                  <mat-option value="ITEM">Item</mat-option>
                </mat-select>
              </mat-form-field>

              <div formArrayName="condition"
              *ngIf="appliesToType.value === 'CATEGORY' || appliesToType.value === 'ITEM'
                || form.get('offer_value').get('applies_to').value === 'CATEGORY'
                || form.get('offer_value').get('applies_to').value === 'ITEM'" class="image-bottom-space">
                <div class="offer-row" *ngFor="let item of offerConditions.controls; index as i">
                  <ng-container [formGroupName]="i">
                    <div class="offer-col">
                      <mat-form-field appearance="fill" class="width-30">
                        <mat-label>Quantity</mat-label>
                        <mat-select formControlName="quantity">
                          <mat-option>Select</mat-option>
                          <mat-option value="">Any Quantity</mat-option>
                          <mat-option *ngFor="let n of formQuantity" [value]="n">
                            {{n}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col">
                      <!-- Portion -->
                      <mat-form-field appearance="fill" class="width-30" >
                        <mat-label>Portion</mat-label>
                        <mat-select formControlName="portion"
                          [compareWith]="objectComparisonFunction">
                          <mat-option>Select</mat-option>
                          <mat-option value="">Any Portion</mat-option>
                          <mat-option *ngFor="let n of portions" [value]="n.id">
                            {{n.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col" *ngIf="appliesToType.value === 'CATEGORY' || form.get('offer_value').get('applies_to').value === 'CATEGORY'">
                      <mat-form-field appearance="fill" class="width-40">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="category"
                          [compareWith]="objectComparisonFunction">
                          <mat-option>Select</mat-option>
                          <mat-option *ngFor="let n of categories" [value]="n.id">
                            {{n.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col" *ngIf="appliesToType.value === 'ITEM' || form.get('offer_value').get('applies_to').value === 'ITEM'">
                      <mat-form-field appearance="fill" class="width-30">
                        <mat-label>Menu Item</mat-label>
                        <mat-select formControlName="item"
                          [compareWith]="objectComparisonFunction">
                          <mat-option>Select</mat-option>
                          <mat-option *ngFor="let n of menuItems" [value]="n.id">
                            {{n.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="offer-col" *ngIf="i > 0">
                      <button mat-icon-button (click)="deleteCondition('offer_value', i)">
                        <mat-icon>delete_fill</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                </div>
                <div class="clear"></div>
                <button mat-stroked-button color="primary" (click)="addCondition('offer_value')" class="previous">Add Offer Condition</button>
              </div>

            </form>
          </ng-template>
          <mat-action-row>
            <button mat-button color="primary" (click)="prevStep()" class="previous">Previous</button>
            <button [ngClass]="{'next': form.controls}" mat-button color="primary" class="next" (click)="nextStep()" [disabled]="!form.controls.offer_value.valid">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
          <mat-expansion-panel-header>
            <mat-panel-title>Conditions</mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <ng-template matExpansionPanelContent> -->
            <form [formGroup]="form.controls.conditions" class="form-container">
              <div style="padding: 10px 2px;">
                <mat-checkbox formControlName="limit_1">Limit 1 per customer</mat-checkbox>
              </div>

              <div style="padding: 10px 2px;">
                <mat-checkbox formControlName="private">Hide from offers page</mat-checkbox>
              </div>


              <mat-form-field appearance="fill" class="full-width" mat-focused>
                <mat-label>Disclaimer</mat-label>
                <textarea rows="3" matInput formControlName="disclaimer" #disclaimer></textarea>
              </mat-form-field>

              <mat-form-field class="width-50" appearance="fill">
                <mat-label>Valid From</mat-label>
                <input matInput [matDatepicker]="startDate" #startDateVal formControlName="start_date">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="width-50" appearance="fill">
                <mat-label>Valid Until</mat-label>
                <input matInput [matDatepicker]="endDate" #endDateVal formControlName="end_date">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Availability Type</mat-label>
                <mat-select  formControlName="availability_type" [(ngModel)]="selectedOption">
                  <mat-option value="ALL_DAY_EVERYDAY">ALL_DAY_EVERYDAY</mat-option>
                  <mat-option value="CUSTOM" (click)="openWeekdayDialog('CUSTOM')">CUSTOM</mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          <!-- </ng-template> -->
          <mat-action-row>
            <button mat-button color="primary" class="previous" (click)="prevStep()">Previous</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="div-right">
      <app-gift-cards-phone-preview
        [store]="store"
        [title]="title.value"
        [description]="description.value"
        [image]="imageStr"
        [endDate]="endDateVal.value"
        [quantity]="1"
        [disclaimer]="disclaimer.value"
      ></app-gift-cards-phone-preview>
    </div>
    <div class="clear"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class="send"
      [disabled]="!form.valid"
      color="primary"
      [ngClass]="{'apply-btn-colr': form.valid}"
      (click)="create()">{{ data.offer ? 'Update' : 'Create'}}</button>
  </mat-dialog-actions>
</form>
