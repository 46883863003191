import {FormBase} from './form-base';

export class FormNumber extends FormBase<number> {
  controlType = 'number';
  type: number;
  value = 0

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.value = options['value']  || 0
  }
}
