import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-sub-category-dialog',
  templateUrl: './sub-category-dialog.component.html',
  styleUrls: ['./sub-category-dialog.component.scss']
})
export class SubCategoryDialogComponent implements OnInit {
  getId: any;
  editSubCategoryForm: UntypedFormGroup;
  submitted: boolean;
  newScreen: boolean;
  hideCategory: boolean;
  emojiLists: any;
  storeId: any;

  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
     public dialogRef: MatDialogRef<SubCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      console.log('data 11111111111', data);

     }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.getId = this.data.id,
    this.newScreen = this.data.new_screen;
    this.hideCategory = this.data.hide_category;
    this.editSubCategoryForm = this.fb.group({
      // tv_id: [this.data.tv_id],
      hide_category: [this.data.hide_category],
      display_name: [this.data.display_name],
      new_screen: [this.data.new_screen],
      priority: [this.data.priority],
      font_color: [this.data.font_color],
      emoji: [this.data.emoji],
      store_id: [this.storeId],
    })

    this.emojiList();
  }
  onNoClick(){
    this.dialogRef.close();
  }
  editSubCategoryFormSubmit() {
    this.submitted = true;
    if (this.editSubCategoryForm.invalid) {
      return;
    }
    this.digitalDisplayService.editSubCategory(this.getId,this.editSubCategoryForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
        this.dialogRef.close(resData);
    })
  }

  newScreenCheck(a) {
    this.editSubCategoryForm.value.new_screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.editSubCategoryForm.value.hide_category = a.checked;
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emojiLists = data.data;

    });
  }

}
