import {Component, OnInit, ViewChild,} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import {PeriodicElement} from '../d-d-screens/d-d-screens.component';
import {AddVideoDialogComponent} from './add-video-dialog/add-video-dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-screen-video',
  templateUrl: './screen-video.component.html',
  styleUrls: ['./screen-video.component.scss']
})
export class ScreenVideoComponent implements OnInit {
  displayedColumns: string[] = ["name", "row", "video", "action"];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  storeId: any;
  screenData: any;
  endpoint: any;
  showUrl: any = [];
  orderUrl;
  orderUrls;
  // public urlsUrl = [];
  public elementSrc = [];
  ertertr: any;
  src;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private digitalDisplayService: DigitalDisplayService
  ) { }

  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.storeId = localStorage.getItem("currentStore");
    this.getVideoList();
  }

  addVideo() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(AddVideoDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        dialog: 1,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getVideoList();

      }
    });
  }

  getVideoList() {
    this.digitalDisplayService
      .videoListGet(this.storeId)
      .subscribe((data: any) => {
        this.dataSource = data.data;
        this.dataSource.paginator = this.paginator;
      });
  }
  editVideo(element) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(AddVideoDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        data: element,
        dialog: 2,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getVideoList();
      }
    });
  }

  deleteVideo(element) {
    let dialogRef = this.dialog.open(AddVideoDialogComponent, {
      data: {
        dialog: 3,
        data: element,

      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getVideoList();
      }
    });
  }
}
