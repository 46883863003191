<input type="hidden" formControlName="bot_order_id">
<h1 mat-dialog-title>Add Tip</h1>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <span style="color: #666666">Add a manual tip to this order here</span>
  <div style="clear: both; margin-top: 10px;"></div>
  <div *ngIf="!isSplit()">
    <form [formGroup]="form">
      <div class="left-sec">
        <app-receipt [orderWithPayment]="orderWithPayment" [onlyItems]="true"></app-receipt>
      </div>
      <div class="right-sec">
        <div class="center-box box-height">
          <div class="form-group item-pad">
            <mat-form-field appearance="outline" class="tip-field">
              <!-- <span style="color:gray;float: left;" matPrefix class="dollar">$ &nbsp;</span> -->
              <mat-label>Tip amount</mat-label>
              <!-- <span style="color:gray;float: left;" matPrefix class="dollar">$ &nbsp;</span> -->
              <input type="text" formControlName="tip" class="form-control" [ngClass]="{ 'is-invalid': submitted }"
                matInput placeholder="$ Tip amount" autofocus required="true">
            </mat-form-field>
          </div>
          <label class="primary-color">Enter tip amount above. Tips will not be taxed and are added to the Order
            Total</label>
          <div *ngIf="error" class="alert alert-danger item-pad">Invalid tip request</div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isSplit()">
    <form [formGroup]="form">
      <div class="manual-tip-add-table">
        <table class="styled-table" formArrayName="splits">
          <tr>
            <th>Name</th>
            <th>Last 4</th>
            <th>Amount</th>
            <th>Tip</th>
            <th></th>
          </tr>
          <tr *ngFor="let split of form.controls.splits.controls; index as i" [formGroupName]="i">
            <td>{{split.value.name_on_card}}</td>
            <td>{{split.value.last_4}}</td>
            <td>{{split.value.split_amount | currency:'USD'}}</td>
            <td>
              <mat-form-field appearance="outline">
                <span style="color:gray;float: left;" matPrefix>$ &nbsp;</span>
                <mat-label>Tip amount</mat-label>
                <input type="text" formControlName="amount" class="form-control" [ngClass]="{ 'is-invalid': submitted }"
                  matInput placeholder="Tip amount" autofocus required="true">
              </mat-form-field>
              <div *ngIf="split.value.error_message" class="alert alert-danger">{{split.value.error_message}}</div>
            </td>
            <td>
              <button mat-flat-button type="button" color="primary" [disabled]="loading" (click)="addTip(split)">Add
                Tip</button>
            </td>
          </tr>
        </table>
      </div>
    </form>
  </div>
</div>

<mat-dialog-actions class="btn-align">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" type="button" (click)="onSubmit()">Submit</button>
</mat-dialog-actions>