import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';
import {GiftDataSource} from 'src/app/_datasources/gifts-datasource';
import {merge, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NewGiftCardsDialogComponent} from '../new-gift-cards-dialog/new-gift-cards-dialog.component';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-gift-cards-message-table',
  templateUrl: './gift-cards-message-table.component.html',
  styleUrls: ['./gift-cards-message-table.component.scss']
})
export class GiftCardsMessageTableComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['name', 'created_at', 'description', 'total_sold', 'generated', 'status', 'action'];

  datasource: GiftDataSource;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  @Input() store:Store;
  @Input() refresh: Subject<boolean> = new Subject<boolean>();

  dataSource
  selectedRow
  search;
  startDate;
  endDate;



  constructor(private storeService: StoreService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new GiftDataSource(this.storeService)
    this.storeService.current.subscribe(store =>{
      if(store){
        this.dataSource.loadGifts(+store.id);
      }
    })

    this.refresh.subscribe(data => {
      this.loadGifts()
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadGifts()))
      .subscribe();
  }

  loadGifts() {
    this.dataSource.loadGifts(+this.store.id, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  onRowClicked(row){}

  edit(element){
    const isMobile = window.innerWidth <= 768;
    const dialogRef = this.dialog.open(NewGiftCardsDialogComponent, {
      panelClass: 'campaign-dialog',
      width: isMobile ?'100vw' : '',
      height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '100vw',
      data: {
        store: this.store,
        gift: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refresh.next(true);
    });
  }

  delete(element){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to delete?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.storeService.deleteGiftCard(element.id).subscribe(data => {
          this.loadGifts();
        })
      }
    });
    // if(confirm("Are you sure to delete?")){
    //   this.storeService.deleteGiftCard(element.id).subscribe(data => {
    //     this.loadGifts();
    //   })
    // }
  }
}
