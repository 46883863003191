import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountDownService {
  private countdownValue = 0;
  private countdownInterval: any;
  private countdownSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor() {
    const storedValue = localStorage.getItem('countdownValue');
    this.countdownValue = storedValue ? parseInt(storedValue, 10) : 0;
  }

  setInitialCountdownValue(value: number): void {
    if (this.countdownValue === 0) {
      this.countdownValue = value;
      this.startCountdown(this.countdownValue);
    }
  }

  startCountdown(durationMinutes: number): void {
    console.log('Start Count Down Service File');

    const durationSeconds = Math.floor(durationMinutes * 60);
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }

    this.countdownValue = durationSeconds;
    this.countdownInterval = setInterval(() => {
      if (this.countdownValue > 0) {
        this.countdownValue--;
        localStorage.setItem('countdownValue', this.countdownValue.toString());
        this.countdownSubject.next(this.countdownValue);
      } else {
        this.stopCountdown();
      }
    }, 1000);
  }
  stopCountdown(): void {
    clearInterval(this.countdownInterval);
    localStorage.removeItem('countdownValue');
    localStorage.removeItem('dayPause');
    this.countdownValue = 0;
    this.countdownSubject.next(this.countdownValue);
  }

  getCountdownValue(): number {
    return this.countdownValue;
  }

  subscribeToChanges(): Observable<number> {
    return this.countdownSubject.asObservable();
  }

  convertToHHMMSS(duration: number): string {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const secondss = (duration % 60).toFixed(2);
    const seconds = parseInt(secondss);

    const hh = this.padNumber(hours);
    const mm = this.padNumber(minutes);
    const ss = this.padNumber(seconds);

    return `${hh}:${mm}:${ss}`;
  }

  private padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
