<form class="fixActionRow">
  <p class="msg">
    Manually Edit Time Clock?
  </p>
  <mat-dialog-content>
    <div mat-dialog-actions class="act">
      <p>Clocked-In</p>
      <p>Clocked-Out</p>
    </div>

    <div class="act">
      <mat-form-field>
        <input matInput class="inputL" tabindex="-1" [value]="clockedIn" #clockedin>
      </mat-form-field>
      <mat-form-field>
        <input matInput class="inputR" tabindex="-1" [value]="clockedOut" #clockedout>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="act">
    <button mat-stroked-button mat-dialog-close tabindex="-1" class="buttonCancel">Cancel</button>
    <button mat-flat-button color="primary" type="button" (click)="editTime(clockedin.value, clockedout.value)"
      tabindex="-1" class="buttonConfirm apply-btn-colr">
      Confirm</button>
  </div>
</form>