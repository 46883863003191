import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {StoreService} from 'src/app/_services/store.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from 'src/app/_models/store';

@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.scss']
})
export class ServiceAreaComponent implements OnInit {

  form:UntypedFormGroup;
  store:Store;
  serviceArea;
  isLoading = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    public dialogRef: MatDialogRef<ServiceAreaComponent>
  ) {
    this.store = data.store;
   }

  ngOnInit() {
    if(this.store){
      this.storeService.getServiceAreas(this.store.id).subscribe(data => {
        console.log(data);
        console.log(data.areas.map(a => this.getAreaEntry(a)));

        this.serviceArea = data
        this.form = this.getServiceAreaForm(data)
        this.isLoading = false;
        console.log(this.form);
      }, error => {
        this.form = this.getServiceAreaForm({})
        this.isLoading = false;
      })
    }
  }

  onTypeChange(event){
    if(event.value === 'AREA' && (this.form.controls['areas'] as UntypedFormArray).length <= 0){
      this.addAreaEntry()
    }
  }

  getAc(v){
    return {'long_name': v.long_name, 'short_name': v.short_name, 'type': v.types[0]}
  }

  onAutocompleteSelected(item, result,i) {
    let place_id = result.place_id;
    let formatted_address = result.formatted_address;
    let levels = result.address_components.map(ac => this.getAc(ac));
    item.controls.name.value = formatted_address;
    item.controls.place_id.value = place_id;
    item.controls.levels.value = levels;
    this.form.get('areas')['controls'].at(i).patchValue({ name: formatted_address});
  }

  onLocationSelected(event){
    console.log(event);
  }

  getServiceAreaForm(data){
    return new UntypedFormGroup({
      service_type: new UntypedFormControl(data.service_type || 'RADIUS', Validators.required),
      radius: new UntypedFormControl(data.radius || ''),
      areas: new UntypedFormArray(data.areas ? data.areas.map(a => this.getAreaEntry(a)) : []),
    })
  }


  getAreaEntry(value){
    console.log(value);

    return new UntypedFormGroup({
      id: new UntypedFormControl(value.id),
      name: new UntypedFormControl(value.name, Validators.required),
      place_id: new UntypedFormControl(value.place_id ),
      charge: new UntypedFormControl(value.charge, Validators.required),
      levels: new UntypedFormControl(value.levels)
    })
  }

  addAreaEntry(){
    (this.form.controls['areas'] as UntypedFormArray).push(this.getAreaEntry({}))
  }

  getAreaControls(){
    (this.form.controls['areas'] as UntypedFormArray).controls
  }

  deleteAreaEntry(i){
    (this.form.controls['areas'] as UntypedFormArray).removeAt(i)
  }

  submitAreaFrom(){
    let payload = this.form.getRawValue();
    payload['store_id'] = this.store.id;
    this.storeService.saveServiceAreas(payload).subscribe(data => {
      console.log(data);
      this.dialogRef.close()
    })
  }

}
