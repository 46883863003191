import {MenuItemGroupType, MenuItemType, ModifierGroupType} from '../_models/menu-v2';
import {RxCollection, RxDocument, RxJsonSchema} from 'rxdb';


export type ModifierGroupDocument = RxDocument<ModifierGroupType>;
export type ModifierGroupCollection = RxCollection<ModifierGroupType>;

export const modifierGroupSchema: RxJsonSchema<ModifierGroupType> = {
  title: 'modifier group schema',
  description: 'describes a simple modifier group',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    name: {
      type: 'string',
    },
    max_mods: {
      type: ['number', 'null']
    },
    is_required: {
      type: 'boolean'
    },
    order: {
      type: 'number',
      multipleOf: 1,
      minimum: 0,
      maximum: 999
    },
  },
  required: ['id', 'name', 'max_mods', 'is_required'],
  indexes: ['order']
};
