export class User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  lastLogin:string;
  token?: string;
  user_profile: any;


  constructor(id:number, username:string, firstName:string, lastName:string, lastLogin:string, token:string){
    this.id = id;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.token = token;
    this.lastLogin = lastLogin;
  }
}
