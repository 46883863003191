<div class="login-body">
  <mat-toolbar class="mat-elevation-z7" style="z-index:2; background-color: white">
    <a [routerLink]="['/home/dashboard']" routerLinkActive="is-active">
      <picture height="50px">
        <source srcset="../../assets/logo_sm.jpg" media="(min-width: 300px) and (max-width: 576px)">
        <source srcset="../../assets/logo.jpg" media="(min-width: 576px)">
        <img srcset="../../assets/logo.jpg" alt="Labrador AI" class="h-12">
      </picture>
    </a>
  </mat-toolbar>
  <div class="login-form">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input type="password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" matInput placeholder="New Password">
      </div>
      <div class="form-group">
        <input type="password" formControlName="confirmPassword" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" matInput placeholder="Confirm Password">
      </div>
      <div *ngIf="error" class="alert alert-danger">Invalid Password</div>
      <div class="form-group" style="padding:10px;">
        <button type="submit" color="primary" *ngIf="!submitted">Submit</button>
        <a mat-button href='/login/'>Back</a>
      </div>
    </form>
  </div>

</div>
