export class Constants{
  public static PHONE_NUMBER_KEY = "phoneNumber"
  public static pizzaOptions:Map<string, string> = new Map(Object.entries({
    "ADD": "WHOLE",
    "H1": "LEFT",
    "H2": "RIGHT",
    "Half": "HALF",
    "Lite": "LITE",
    "Extra": "EXTRA",
    "No": "NO",
    "Remove": "NO"
  }));

  public static pizzaOptionsFlag:Map<string, string> = new Map(Object.entries({
    "ADD": "WHOLE",
    "H1": "L Half",
    "H2": "R Half",
    "Half": "HALF",
    "Lite": "LITE",
    "Extra": "EXTRA",
    "No": "NO",
    "Remove": "NO"
  }));

  public static occasion = {  
    1: 'Pickup',
    2: 'Dine-In',
    3: 'Delivery',
  }


  public static occasionRi = {
    'PICKUP' : 1,
    'DINE-IN' : 2,
    'DELIVERY' : 3,
    'CURBSIDE' : 1
  }



}
export enum Modules{
  POS_ORDER_SUMMARY = 'POS_ORDER_SUMMARY',
  OPEN_TAB = 'OPEN_TAB',
  CLOSED_TAB = 'CLOSED_TAB'
}

export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
export const standardDateFormat = 'hh:mm A MMM DD, YYYY';

export const occasion = {
  1: 'Pickup',
  2: 'Dine-In',
  3: 'Delivery',
  4: 'Curbside',
  5: 'Tableside'
}

export const occasionNew = {
  1: 'Pickup',
  2: 'Dine-In',
  3: 'Delivery',
  4: 'Curbside',
  5: 'Tableside',
  6: 'To-Go',
  7: 'For-Here'
}

export const occasionRi = {
  'pickup': 1,
  'dine-in': 2,
  'delivery': 3,
  'curbside': 4,
  'tableside': 5,
  'togo': 6,
  'forhere': 7
}

export const chunk = (arr, size) => (
  arr.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(arr.slice(i, i + size))
    return acc
  }, [])
)

export const pad  = (num:number, size:number) => {
  let s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
}


export const disclaimer = "This gift card is redeemable for merchandise or services at <merchant name>. It is not redeemable for or refundable for cash. For balance, information please text “Wallet” to our business phone number. The unused value expires 24 months after purchase unless otherwise regulated by merchant location state laws."

export const offerV2Disclaimer = 'Offer valid on online purchases only. To redeem online promotion code [XYZ] must be entered at checkout and is valid for one-time use only. Sales tax, shipping & handling do not qualify for discount. This offer is not redeemable for cash or gift cards, nor is it valid toward previous purchases. Offer may not be combined with any other coupons, discounts, offers, or promotions. Void if altered, copied, transferred, auctioned or sold. Offer valid on in-stock items only. No rain checks issued. Offer not valid for prior purchases. Other restrictions may apply.'
