import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {IMqttMessage, IMqttServiceOptions, IPublishOptions, MqttService} from 'ngx-mqtt';
import {IClientSubscribeOptions} from 'mqtt/types/lib/client-options';

@Injectable({ providedIn: 'root' })
export class LinkMqttService {
  private curSubscription: Subscription | undefined;
  connection = {
    hostname: 'priser.labrador.ai',
    port: 1883,
    path: '/link',
    clean: true,
    connectTimeout: 4000,
    reconnectPeriod: 4000,
    clientId: 'mqttx_597046f4',
    username: 'priser',
    password: 'fweiPX6csDNnR3XxaAAxNvxu',
    protocol: 'ws',
  };
  subscription = {
    topic: 'topic/mqttx',
    qos: 0,
  };
  publish = {
    topic: 'topic/browser',
    qos: 0,
    payload: '{ "msg": "Hello, I am browser." }',
  };
  receiveNews = '';
  client: MqttService ;
  isConnection = false;
  subscribeSuccess = false;

  createConnection() {
    try {
      this.client.connect(this.connection as IMqttServiceOptions);
    } catch (error) {
      console.log('mqtt.connect error', error);
    }

    this.client.onConnect.subscribe(() => {
      this.isConnection = true;
      console.log('Connection succeeded!');
    });

    this.client.onError.subscribe((error: any) => {
      this.isConnection = false;
      console.log('Connection failed', error);
    });

    this.client.onMessage.subscribe((packet: any) => {
      this.receiveNews = this.receiveNews.concat(packet.payload.toString());
      console.log(`Received message ${packet.payload.toString()} from topic ${packet.topic}`);
    });
  }

  doSubscribe() {
    const { topic, qos } = this.subscription;
    this.curSubscription = this.client.observe(topic, { qos } as IClientSubscribeOptions).subscribe((message: IMqttMessage) => {
      this.subscribeSuccess = true;
      console.log('Subscribe to topics res', message.payload.toString());
    });
  }

  doUnSubscribe() {
    this.curSubscription.unsubscribe();
    this.subscribeSuccess = false;
  }

  doPublish() {
    const { topic, qos, payload } = this.publish;
    console.log(this.publish);
    this.client.unsafePublish(topic, payload, { qos } as IPublishOptions);
  }

  destroyConnection() {
    try {
      this.client.disconnect(true);
      this.isConnection = false;
      console.log('Successfully disconnected!');
    } catch (error) {
      console.log('Disconnect failed', error.toString());
    }
  }
}
