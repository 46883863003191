import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {Store} from 'src/app/_models/store';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-mod-list',
  templateUrl: './mod-list.component.html',
  styleUrls: ['./mod-list.component.scss']
})
export class ModListComponent implements OnInit {

  @Input() store: Store;
  @Input() menuItem;
  isLoading = true;
  modGroups = [];
  selectedMods = [];
  index: any;
  menuItemId: any;

  constructor(private menuService: MenuServiceV2, public storeService: StoreService, private snackBar: MatSnackBar) {
    this.filteredMods = this.modCtrl.valueChanges.pipe(
      startWith(null),
      map((mod: any) => mod ? this._filter(mod) : this.modGroups.slice()));
  }

  ngOnInit() {
    if(this.store){
      this.menuService.getModGroup(this.store.id).subscribe(response => {
        console.log(response);

        this.modGroups = Object.values(response)
        this.menuService.getModGroupForMenuItem(this.store.id, JSON.stringify(this.menuItem)).subscribe(mods => {
          this.selectedMods = mods;
          // console.log('hhhhhhhhhhhhhhhh',this.selectedMods);
          this.isLoading = false
        })
      })
    }
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  modCtrl = new UntypedFormControl();
  filteredMods: Observable<any[]>;

  @ViewChild('modInput',{static: false}) modInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto',{static: false}) matAutocomplete: MatAutocomplete;

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // event input enters on search input form submit
      if (input) {
        return;
      }

      // Add our fruit
      if ((value || '')) {
        this.selectedMods.push(value);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.modCtrl.setValue(null);
    }
  }

  remove(modGroup: any): void {
    const index = this.selectedMods.indexOf(modGroup);
    this.isLoading = true;
    this.menuService.deleteModGroupForMenuItem(this.store.id, JSON.stringify({"menu_item_id": this.menuItem.id,
    "modifier_group_id": modGroup.id})).subscribe(response => {
      if (index >= 0) {
        this.selectedMods.splice(index, 1);
      }
      this.isLoading = false;
    })
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.isLoading = true

    const lastIndex = this.selectedMods.length;

    this.menuService.updateModGroupForMenuItem(this.store.id, JSON.stringify({"menu_item_id": this.menuItem.id,
    "modifier_group_id": event.option.value.id,"index":lastIndex})).subscribe(response => {
      const index = this.selectedMods.find(p => p.id == event.option.value.id);
      
      if(!index){
        this.selectedMods.push(event.option.value);
      }
      console.log('SelectedMods',this.selectedMods);
      // this.modInput.nativeElement.value = '';
      this.modCtrl.setValue(null);
      this.isLoading=false;
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
  
    if (previousIndex !== currentIndex) {
      const movedItem = this.selectedMods[previousIndex];
  
      this.selectedMods.splice(previousIndex, 1);
      this.selectedMods.splice(currentIndex, 0, movedItem);

      this.updateIndexes();

    }
  }
  updateIndexes() {
    for (let i = 0; i < this.selectedMods.length; i++) {
      this.selectedMods[i].index = i;
    }
    const payload = {
      "menu_item_id":this.menuItem.id,
      "data": this.selectedMods,
    }
    this.menuService.updateIndexModGroupForMenuItem(this.store.id, payload).subscribe(response => {
      console.log('Indexes updated', response);
    }, error => {
      console.error('Error', error);
    });
  }
  private _filter(value: any): any[] {
    let filterValue;
    if(typeof value === 'string'){
      filterValue = value.toLowerCase();
    }else{
      filterValue = value.name.toLowerCase();
    }
    return this.modGroups.filter(mod => mod.name.trim().toLowerCase().indexOf(filterValue) === 0);
  }

}
