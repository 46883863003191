<div class="mobile-row">
  <div class="blink_me">
    <form class="order-message-table-filters">
      <mat-form-field appearance="fill" class="search">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search Orders</mat-label>
        <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input>
      </mat-form-field>

      <mat-form-field appearance="fill" class="start">
        <mat-label>Start Date</mat-label>
        <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker"
          placeholder="Choose a start date" (dateInput)="loadBotOrders()">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" class="end">
        <mat-label>End Date</mat-label>
        <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker" placeholder="Choose a end date"
          (dateInput)="loadBotOrders()" [min]="startDate">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>

      <button mat-flat-button class="clear" type="submit" (click)="loadBotOrders()" color="primary">SEARCH</button>
      <button mat-flat-button class="clear btn-gap" type="reset" (click)="reset()" color="primary">CLEAR</button>

      <div class="clear"></div>
    </form>

    <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>


    <div class="order-container">
      <mat-table width="100%" matSort matSortActive="order_counter" matSortDirection="desc" matSortDisableClear
        [dataSource]="dataSource" class="cell-content">

        <ng-container matColumnDef="ready">
          <mat-header-cell *matHeaderCellDef>Ready</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Payment" (click)="$event.stopPropagation()">
            <!-- <span *ngIf="!element.ready_notified && !element.view_notified">
          <audio autoplay loop>
            <source src="assets/audio/censor-beep-01.mp3" type="audio/ogg">
          </audio>
        </span> -->
            <button mat-flat-button class="ready view-btn" color="primary" (click)="timeSelect(element)"
              *ngIf="!element.ready_notified && !element.view_notified">View
            </button>
            <button mat-flat-button class="ready" (click)="ready(element)"
              *ngIf="!element.ready_notified && element.view_notified">Ready</button>
            <button mat-flat-button disabled (click)="ready(element)" *ngIf="element.ready_notified">Done</button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef> Placed </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Placed"> {{ formatDate(element.submitted_at)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="readyby">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Ready By </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="ReadyBy">
            <span *ngIf="element.est_time_and_date != '' ">{{ formatDate(element.est_time_and_date)}}</span>
            <span *ngIf="element.est_time_and_date == '' ">{{ formatDate(element.occasion_schedule)}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="order_counter">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Order </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Order"> {{element.order_counter}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="source">
          <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Source">
            <p class="pill blue-pill" *ngIf="!element.is_pos">Online</p>
            <p class="pill blue-pill" *ngIf="element.is_pos">POS</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="occasion">
          <mat-header-cell *matHeaderCellDef> Occasion </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Occasion">
            <p class="pill grey-pill">{{element.occasion}}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="table_id">
          <mat-header-cell *matHeaderCellDef fxHide.lt-sm> Table ID </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="TableID" fxHide.lt-sm> {{element.table_id}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="payment_type">
          <mat-header-cell *matHeaderCellDef fxHide.lt-sm> Payment </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Payment" fxHide.lt-sm> {{element.payment_type | titlecase}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef> Total </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Total">
            <span *ngIf="element.total > 0">
              ${{element.total}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="payment_status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Status">
            <div *ngIf="element.is_submitted && !element.is_cancelled; then submitted else cancelled"></div>
            <ng-template #submitted>
              <p class="pill green-pill" *ngIf="element.is_submitted">Closed</p>
              <p class="pill grey-pill" *ngIf="element.is_submitted && element.has_adjustment">Adjusted</p>
              <p class="pill red-pill" matTooltip="Refunded {{element.refund_total | currency: 'USD'}}"
                *ngIf="element.is_submitted && element.has_refund">Refunded</p>
              <p class="pill red-pill" *ngIf="element.is_submitted && element.refund_pending">Pending</p>
            </ng-template>
            <ng-template #cancelled>
              <p class="pill red-pill" *ngIf="element.is_cancelled">Cancelled</p>
              <p class="pill red-pill" matTooltip="Refunded {{element.refund_total | currency: 'USD'}}"
                *ngIf="element.is_submitted && element.has_refund">Refunded</p>
              <p class="pill red-pill" *ngIf="element.is_submitted && element.refund_pending">Pending</p>
            </ng-template>

          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cashier_id">
          <mat-header-cell *matHeaderCellDef fxHide.lt-sm>Server Name</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Server Name" fxHide.lt-sm> {{element.cashier_id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="terminal_id">
          <mat-header-cell *matHeaderCellDef fxHide.lt-sm>Station ID</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Server Name" fxHide.lt-sm> {{element.terminal_id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="order_name">
          <mat-header-cell *matHeaderCellDef fxHide.lt-sm>Order Name</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Server Name" fxHide.lt-sm> {{element.order_name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item (click)="print(element)">Reprint order</button>
              <mat-divider></mat-divider>
              <button mat-menu-item [disabled]="element.payment_type == 'CASH'"
                (click)="checkPOSPermissionAdjust(element)">Adjust up</button>
              <button mat-menu-item [disabled]="element.payment_type == 'CASH'" (click)="addTip(element)">Add
                tip</button>

              <div *ngIf="element.is_pos; then posBlock else onlineBlock"></div>
              <ng-template #posBlock>
                <button mat-menu-item
                  [disabled]="element.payment_type != 'CASH' && element.payment_type != 'CREDIT' && element.payment_type != 'SPLIT'"
                  (click)="checkPOSPermissionRefund(element)">Refund order</button>
                <button mat-menu-item
                  [disabled]="element.payment_type != 'CASH' && element.payment_type != 'CREDIT' && element.payment_type != 'SPLIT'"
                  (click)="transactions(element)">History</button>
              </ng-template>
              <ng-template #onlineBlock>
                <button mat-menu-item [disabled]="element.payment_type == 'CASH'"
                  (click)="checkPOSPermissionRefund(element)">Refund order</button>
                <button mat-menu-item [disabled]="element.payment_type == 'CASH'"
                  (click)="transactions(element)">History</button>
              </ng-template>
              <button mat-menu-item (click)="checkPOSPermissionCancel(element)">Cancel order</button>
              <button mat-menu-item *ngIf="element.is_pos" (click)="checkPOSStatus(element)">Status</button>
              <!-- <button mat-menu-item *ngIf="element.is_pos" (click)="checkPOSPermission()">PIN</button> -->
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"
          [ngClass]="{ 'highlight-order-row': row === selectedRow }" (click)="onRowClicked(row)"></mat-row>
      </mat-table>
      <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
    </div>
  </div>
</div>