import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PrinterSettingsService} from 'src/app/_services/printer-settings.service';
import {Store} from 'src/app/_models/store';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class Table {
  id: number;
  table_name: string;
  store: number

  constructor(t:any){
    this.id = t.id;
    this.table_name = t.table_name;
    this.store = t.store;
  }

  toLowerCase(){
    return this.table_name.toLowerCase()
  }

}


@Component({
  selector: 'app-edit-printer',
  templateUrl: './edit-printer.component.html',
  styleUrls: ['./edit-printer.component.scss']
})
export class EditPrinterComponent implements OnInit {

  form: UntypedFormGroup;
  isLoading = false;
  store: Store;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('tableInput',{static: false}) modInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto',{static: false}) matAutocomplete: MatAutocomplete;

  tables: Table[];
  selectedTables: Table[] = [];

  menuCategories;
  selectedMenuCategories;

  occasions;
  selectedOccasion;

  constructor(
    private dialogRef: MatDialogRef<EditPrinterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private printerService: PrinterSettingsService) {}

  ngOnInit() {
    if(this.data){
      console.log(this.data);
      this.store = this.data.store;
      this.form = new UntypedFormGroup({
        store: new UntypedFormControl(this.data.printer.store),
        printer_id: new UntypedFormControl(this.data.printer.printer_id),
        name: new UntypedFormControl(this.data.printer.name),
        pickup_count: new UntypedFormControl(this.data.printer.pickup_count),
        curbside_count: new UntypedFormControl(this.data.printer.curbside_count),
        delivery_count: new UntypedFormControl(this.data.printer.delivery_count),
        dine_in_count: new UntypedFormControl(this.data.printer.dine_in_count),
        tableside_count: new UntypedFormControl(this.data.printer.tableside_count),
        kitchen_count: new UntypedFormControl(this.data.printer.kitchen_count),
        has_kitchen_format: new UntypedFormControl(this.data.printer.has_kitchen_format),
      });
      this.selectedTables = this.data.printer.tableside_table;
      this.selectedMenuCategories = this.data.printer.menu_category;
      this.selectedOccasion = this.data.printer.occasion;
      this.tables = this.data.tableside_table;
      this.menuCategories = this.data.menu_category;
      this.occasions = this.data.occasion;
    }
  }

  setSelectedTables(event){
    this.selectedTables = event;
  }

  setSelectedMenuCategories(event){
    this.selectedMenuCategories = event;
  }

  setSelectedOccasion(event){
    this.selectedOccasion = event;
  }

  submit(){
    const payload = this.form.value;
    payload.heartbeat = this.data.printer.heartbeat;
    payload.tableside_table = this.selectedTables;
    payload.menu_category = this.selectedMenuCategories;
    payload.occasion = this.selectedOccasion;
    this.printerService.updatePrinterSetting(payload).subscribe(data => {
      this.dialogRef.close(payload);
    })
  }
}
