import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf, Subject} from 'rxjs';
import {catchError, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';
import {User} from 'src/app/_models/contacts';
import {MatDialog} from '@angular/material/dialog';
import {ContactDialogComponent} from '../contact-dialog/contact-dialog.component';
import {ContactBulkImportDialogComponent} from '../contact-bulk-import-dialog/contact-bulk-import-dialog.component';
import {ContactFieldMappingDialogComponent} from '../contact-field-mapping-dialog/contact-field-mapping-dialog.component';
import {ExcelService} from '../../../_services/excel.service';
import {CsvData} from '../../../_interfaces/csv-data';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-contact-active-table',
  templateUrl: './contact-active-table.component.html',
  styleUrls: ['./contact-active-table.component.scss']
})
export class ContactActiveTableComponent implements AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['phone_number', 'created', 'name'];

  data: User[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  unsubscribe = new Subject();

  @Input() store: Store;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  filter;
  @Input() isBlocked;
  @Output() refreshParent = new EventEmitter();

  constructor(
    private storeService: StoreService,
    private excelService: ExcelService,
    private dialog: MatDialog,
  ) { }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.storeService!.getContactList(this.store.id,
            this.paginator.pageIndex, this.paginator.pageSize,
            this.isBlocked, this.filter);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.stats.total;

          return data.users;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  openEditDailog(user) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'store': this.store,
        'user': user}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshParent.emit(true);
      this.ngAfterViewInit();
    });
  }

  openBulkImportDialog() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ContactBulkImportDialogComponent,{
        width: isMobile ? '100vw' : '',
        height: isMobile ? '100vh' : '',
        maxWidth: isMobile ? '100vw' : '80vw',
      });
    
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe))
      .subscribe((file: File) => file && this.readFileContent(file));
  }

  readFileContent(file: File) {
    this.excelService.parseFile(file).pipe(takeUntil(this.unsubscribe))
      .subscribe((csvData: CsvData) => this.openMappingDialog(csvData));
  }

  openMappingDialog(csvData: CsvData) {
    const dialogRef = this.dialog.open(ContactFieldMappingDialogComponent, {
      data: { csvData, store: this.store },
      width: '400px'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe))
      .subscribe((updated: boolean) => {
        if (updated) {
          this.refreshParent.emit(true);
          if (this.paginator.pageIndex === 0) {
            this.paginator._changePageSize(this.paginator.pageSize);
          } else {
            this.paginator.firstPage();
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}

