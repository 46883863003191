import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {FormUtils} from 'src/app/_helpers/form-utils';
import {OrderService} from 'src/app/_services/order.service';

@Component({
  selector: 'app-custom-batch-time',
  templateUrl: './custom-batch-time.component.html',
  styleUrls: ['./custom-batch-time.component.scss']
})
export class CustomBatchTimeComponent implements OnInit {

  form: UntypedFormGroup;
  hours: any;
  strHour: any;
  storeId: any;
  time: any;
  selected: any;


  constructor(
    private formUtils: FormUtils,
    private orderService: OrderService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomBatchTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hours = formUtils.buildTimeOptions();
  }


  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');

    this.time = this.data.custom.batch_time.split(':');
    var hours = this.time[0];
    var minutes = this.time[1];
    // Check whether AM or PM
    var newformat = hours >= 12 ? 'pm' : 'am';
    // Find current hour in AM-PM Format
    hours = hours % 12;
    // To display "0" as "12"
    hours = hours ? hours : 12;
    // Combine hour minute
    const getString = hours + ':' + minutes + ' ' + newformat;
    this.selected = getString;
    this.form = this.fb.group({
      time: '',
    });
  }


  onSubmit() {
    const dt = moment(this.form.value.time, ["h:mm A"]).format("HH:mm");
    const keyData = {
      "store_id": this.storeId,
      "batch_time": dt
    }
    this.orderService.closedBatchCustom(keyData).subscribe((data: any) => {
      this.ngOnInit();
    })
    this.dialogRef.close();

  }

}
