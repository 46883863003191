<div>
    <div mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <div class="content" *ngIf="getID != '4'">Are you sure you want to delete. </div>
            <div class="content" *ngIf="getID == '4'">Are you sure you want to Copy Menu Category. </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <button mat-button mat-dialog-close mat-stroked-button (click)="onNoClick()">Cancel</button>
        <button mat-button mat-flat-button color="primary" class="apply-btn-colr"
            (click)="categoryDelete()">Submit</button>
    </mat-dialog-actions>
</div>