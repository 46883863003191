

<mat-tab-group>
  <mat-tab label="SETTINGS">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
        <div class="card">
          <app-referral-settings></app-referral-settings>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="REPORTING">
    <ng-template matTabContent>
      <app-redemptions></app-redemptions>
    </ng-template>
  </mat-tab>

</mat-tab-group>