import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbacksService {

  endpoint = environment.apiUrl;
  feedbackyurl = this.endpoint + '/backend';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) {}
  
  private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getRefresh(): Observable<any> {
    return this.refresh.asObservable();
  }
  public setRefresh(value: any): void {
    this.refresh.next(value);
  }


  getFeedbacklist(storeId:number, filter = '', startDate=null, endDate=null,
    sortField='id', sortOrder='asc', pageNumber=0, pageSize=10) {
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('search_key', filter)
    .set('sort_id', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    if(startDate){
      params = params.set('from_date', startDate.toISOString().slice(0, 10))
    }

    if(endDate){
      params = params.set('to_date', endDate.toISOString().slice(0, 10))
    }
    return this.http.get(this.feedbackyurl + `/business/${storeId}/order_feedback_list/`, { params: params });
  }
  
  getLineBarGraph(storeId:String, startDate=null, endDate=null,type:String) {
    let params = new HttpParams()
    .set('graph_type', type.toString())
    if(startDate){
      const parsedDate = new Date(startDate);
      params = params.set('from_date', parsedDate.toISOString().slice(0, 10))
    }

    if(endDate){
      const parsedDate = new Date(endDate);
      params = params.set('to_date', parsedDate.toISOString().slice(0, 10))
    }
    return this.http.get(this.feedbackyurl + `/business/${storeId}/order_feedback_graph/`, { params: params });
  }

  getPieChartData(storeId:String, startDate=null, endDate=null,type:String) {
    let params = new HttpParams()
    .set('graph_type', type.toString())
    if(startDate){
      const parsedDate = new Date(startDate);
      params = params.set('from_date', parsedDate.toISOString().slice(0, 10))
    }

    if(endDate){
      const parsedDate = new Date(endDate);
      params = params.set('to_date', parsedDate.toISOString().slice(0, 10))
    }

    return this.http.get(this.feedbackyurl + `/business/${storeId}/order_feedback_pie_chart/`, { params: params });
  }
}
