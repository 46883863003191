<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<form [formGroup]="form" class="form-message-design fixActionRow">
  <h1 mat-dialog-title>Order Confirmation Message</h1>

  <div class="container xs:!pb-32" mat-dialog-content>
    <div class="flex flex-row justify-between w-full mb-3">
      <label class="dim-color lean-font">Enable Order Confirmation Message</label>
      <mat-slide-toggle class="example-margin" (change)="onChangeToggle($event)" [checked]="toggleValue">
      </mat-slide-toggle>
    </div>

    <div class="mb-5">
      <mat-form-field appearance="outline" class="w-full">
        <textarea
          matInput
          formControlName="order_comfirmation_message"
          rows="5"
          placeholder="Enter your confirmation message here..."
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end" class="p-5 xs:!pt-0">
    <button mat-stroked-button mat-dialog-close class="mr-1 mb-3">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="submitMessagesForm()"
      class="mb-3 mr-3 apply-btn-colr"
      [disabled]="isLoading"
    >
      Apply
    </button>
  </mat-dialog-actions>
</form>