import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderService } from 'src/app/_services/order.service';
import { POSService } from 'src/app/_services/pos-service';

@Component({
  selector: 'app-manual-print-control',
  templateUrl: './manual-print-control.component.html',
  styleUrls: ['./manual-print-control.component.scss']
})
export class ManualPrintControlComponent implements OnInit {

  @Input() manualData: any;
  o_hash: any;
  isSuccess: boolean;
  params: any;
  terminalName: string;
  posLoggedUser: string;
  @Output() selectionEvent = new EventEmitter<string>();
  public unsubscribe: Subject<null> = new Subject<null>();
  hasBack = false;

  constructor(public dialogRef: MatDialogRef<ManualPrintControlComponent>,
     public orderService: OrderService,
     private snackBar: MatSnackBar,
     private posService: POSService,
     @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    this.hasBack = this.data.hasBack || false;
      this.orderService.isCashCLose.subscribe((data:any) => {
        if (!data) {
          this.isSuccess = false;
        } else {
          this.isSuccess = true;
        }
      });
    }

  ngOnInit() {
    if (this.data && (this.data.key == 'manual_card' || this.data.key == 'pay_later_print')) {
      this.manualData = this.data;
    }
    this.terminalName = localStorage.getItem('selectedTerminalName');
    this.posLoggedUser = localStorage.getItem('posLoggedUser');
    if (this.isIos() && this.isMobile()) {
      this.posService.scrolltoTop.next(false);
    }

  }

  receiptPrinting(e) {
    if (this.manualData && this.manualData.value == 1) {
      if (e != 0) {
        if (this.data.is_split) {
          if (!this.data.hasOwnProperty('given_cash') && !this.data.hasOwnProperty('total')) {
            this.data['result'] = 0;
            this.data['cashDueTotal'] = 0;
          }
          let terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : "NA";
          this.orderService.printOrderSplitManual(this.data.hasOwnProperty('order_hash') ? this.data.order_hash : this.data.orderWithPayment.bot_order.order_hash, terminalName, this.data.split_id, e, this.data).subscribe(
            data => {
              this.snackBar.open('Order printed successfully', 'OK', { duration: 2000 });
            },
            error => {
              this.snackBar.open('Unable to print order', 'OK', { duration: 2000 });
            })
          this.dialogRef.close(true);
        } else {
          this.manualPrintSelection(e);
        }
      } else {
        if (this.data.key != 'pay_later_print' && this.manualData?.payment_cash) {
          // this.openDrawer();
        } else {
          this.dialogRef.close(true);
        }
      }
      this.selectionEvent.emit('true');
    } else {
      this.dialogRef.close(true);
    }
  }

  manualPrintSelection(selection) {
    this.orderService.manualPrintReceipt(this.manualData.order_hash,selection,this.terminalName, this.posLoggedUser)
    .subscribe(
      _data  => {
        if (selection != 0) {
          this.snackBar.open('Order printed successfully', 'OK', { duration: 2000 });
        }
        this.dialogRef.close(true);
    },
    error => {
      this.snackBar.open('Unable to print order', 'OK', { duration: 2000 });
    }
    );
  }
 
  isIos(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    return /iPad|iPhone|iPod/.test(userAgent) && !(window['MSStream']);
  }
  
  isMobile(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  
  
}
