<form (ngSubmit)="onSubmit()" [formGroup]="form" class="fixActionRow">
  <h1 mat-dialog-title>{{dialogConfig.title}}</h1>
  <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}" class="xs:!overflow-x-hidden">
    <p class="message">{{dialogConfig.message}}</p>
    <div *ngIf="editControlKeys[0] != 'working'">
      <div *ngFor="let formControl of formControls" class="form-row">
        <app-dynamic-form-control
        [myFormControl]="formControl"
        [editControlKeys]="editControlKeys"
        [store]="store"
        [form]="form"
        [dialogConfig]="dialogConfig"></app-dynamic-form-control>
      </div>
    </div>

    <div *ngIf="editControlKeys[0] == 'working'">
      <app-working-hours [formControls]="formControls" [editControlKeys]="editControlKeys" [store]="store" [form]="form"></app-working-hours>
    </div>
  </div>

  <div *ngIf="error" class="form-row alert alert alert-danger ">
      Invalid edit request
  </div>

  <div class="spacer"></div>

  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr">Apply</button>
  </mat-dialog-actions>
</form>
