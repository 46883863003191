<div *ngIf="optBox === 'cash'; then withDiscount else withoutDiscount"></div>
  <ng-template #withDiscount>
    <table width="100%" class="price-breakdown-table" cellspacing="10px;">
      <tr>
        <td class="item">Subtotal</td>
        <td class="item-val">{{orderWithPayment?.payment.sub_total | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.discount > 0">
        <td class="item">Discount</td>
        <td class="item-val">-{{orderWithPayment?.payment.discount | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.instant_discount > 0">
        <td class="item">{{ orderWithPayment?.bot_order.instant_discount_description }}</td>
        <td class="item-val">-{{orderWithPayment?.payment.instant_discount | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.promotional_discount > 0">
        <td class="item">Promotional Discount</td>
        <td class="item-val">-{{orderWithPayment?.payment.promotional_discount | currency:'USD'}}</td>
      </tr>
      <tr >
          <td class="item">Cash Discount</td>
          <td class="item-val">-{{orderWithPayment?.payment.instant_cash_discount | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.tip > 0">
        <td class="item">Tip</td>
        <td class="item-val">{{orderWithPayment?.payment.tip | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="!orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">Tax</td>
        <td class="item-val">{{orderWithPayment?.payment.cash_tax | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">
          {{orderWithPayment?.payment.service_charge_title}}
        </td>
        <td class="item-val">{{orderWithPayment?.payment.service_charge | currency:'USD'}}</td>
      </tr>  
      <tr *ngIf="orderWithPayment?.bot_order.occasion == 'DELIVERY'">
        <td class="item">Delivery</td>
        <td class="item-val">{{orderWithPayment?.payment.delivery_charge | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.store_credit > 0">
        <td class="item">Store Credit</td>
        <td class="item-val">{{'-' + orderWithPayment?.payment.store_credit | currency:'USD'}}</td>
      </tr>
      
      <tr *ngIf="!orderWithPayment?.bot_order.is_pos && +orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">{{orderWithPayment?.store.online_service_charge_title}}</td>
        <td class="item-val">{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="orderWithPayment?.bot_order.is_pos && +orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">{{orderWithPayment?.store.pos_service_charge_title}}</td>
        <td class="item-val">{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</td>
      </tr>

      <!-- <tr *ngIf="orderWithPayment?.store.consolidate_taxes_and_fees && orderWithPayment?.payment.consolidate_taxes_and_fees_cash > 0">
        <td class="item">Total Taxes & Fees</td>
        <td class="item-val">{{orderWithPayment?.payment.consolidate_taxes_and_fees_cash | currency:'USD'}}</td>
      </tr> -->

      <tr *ngIf="orderWithPayment?.store.consolidate_taxes_and_fees && orderWithPayment?.payment.consolidate_taxes_and_fees_cash > 0">
        <td class="item">Total Taxes & Fees</td>
        <td class="item-val">{{orderWithPayment?.payment.consolidate_taxes_and_fees_cash | currency:'USD'}}</td>
      </tr>

       <tr>
        <td class="item"><br></td>
        <td class="item-val"></td>
      </tr> 

      <tr>
        <td class="item total">Total</td>
        <td class="item-val total">{{orderWithPayment?.payment.cash_due_total | currency:'USD'}}</td>
      </tr>
      <tr>
        <td class="item"></td>
        <td class="item-val"></td>
      </tr> 
      <tr>
        <td class="item"></td>
        <td class="item-val"></td>
      </tr> 
    </table>
  </ng-template>
  <ng-template #withoutDiscount>
    <table width="100%" class="price-breakdown-table" cellspacing="10px;">
      <tr>
        <td class="item">Subtotal</td>
        <td class="item-val">{{orderWithPayment?.payment.sub_total | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.discount > 0">
        <td class="item">Discount</td>
        <td class="item-val">-{{orderWithPayment?.payment.discount | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.instant_discount > 0">
        <td class="item">{{ orderWithPayment?.bot_order.instant_discount_description }}</td>
        <td class="item-val">-{{orderWithPayment?.payment.instant_discount | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.promotional_discount > 0">
        <td class="item">Promotional Discount</td>
        <td class="item-val">-{{orderWithPayment?.payment.promotional_discount | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="!orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">Tax</td>
        <td class="item-val">{{orderWithPayment?.payment.tax | currency:'USD'}}</td>
      </tr>  
      <tr *ngIf="+orderWithPayment?.payment.service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">
          {{orderWithPayment?.payment.service_charge_title}}
        </td>
        <td class="item-val">{{orderWithPayment?.payment.service_charge | currency:'USD'}}</td>
      </tr>  
      <tr *ngIf="orderWithPayment?.bot_order.occasion == 'DELIVERY'">
        <td class="item">Delivery</td>
        <td class="item-val">{{orderWithPayment?.payment.delivery_charge | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="orderWithPayment?.payment.surcharge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">{{orderWithPayment?.payment.only_credit_surcharge_title}}</td>
        <td class="item-val">{{orderWithPayment?.payment.surcharge | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="+orderWithPayment?.payment.store_credit > 0">
        <td class="item">Store Credit</td>
        <td class="item-val">{{'-' + orderWithPayment?.payment.store_credit | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="!orderWithPayment?.bot_order.is_pos && +orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">{{orderWithPayment?.store.online_service_charge_title}}</td>
        <td class="item-val">{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</td>
      </tr>
      <tr *ngIf="orderWithPayment?.bot_order.is_pos && +orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
        <td class="item">{{orderWithPayment?.store.pos_service_charge_title}}</td>
        <td class="item-val">{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</td>
      </tr>

      <tr *ngIf="orderWithPayment?.store.consolidate_taxes_and_fees && orderWithPayment?.payment.consolidate_taxes_and_fees_credit > 0">
        <td class="item">Total Taxes & Fees</td>
        <td class="item-val">{{orderWithPayment?.payment.consolidate_taxes_and_fees_credit | currency:'USD'}}</td>
      </tr>
      <tr>
        <td class="item"><br></td>
        <td class="item-val"></td>
      </tr> 
      <tr>
        <td class="item total">Total</td>
        <td class="item-val total">{{orderWithPayment?.payment.due_total | currency:'USD'}}</td>
      </tr>
      <tr>
        <td class="item"></td>
        <td class="item-val"></td>
      </tr>        
      <tr>
        <td class="item"></td>
        <td class="item-val"></td>
      </tr> 
    </table>
  </ng-template>
