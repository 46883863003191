import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormUtils} from '../../_helpers/form-utils';
import {PosDisplayService} from 'src/app/_services/pos-display.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class MenuCategoriesComponent implements OnInit {

  columnsToDisplay = ['api_category_name', 'display_name', 'flag', 'priority', 'star']
  dataSource = [];
  expandedElement;
  isLoading: boolean = true;
  constructor(
    private formUtils:FormUtils,
    private posDisplayService: PosDisplayService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    let categories;
    this.posDisplayService.getAllCategories().subscribe(data => {
      this.dataSource = data.map(displayCategories => {
        displayCategories['form'] = new UntypedFormGroup({
          id: new UntypedFormControl(displayCategories['id']),
          Category: new UntypedFormControl(displayCategories['Category']),
          display_name: new UntypedFormControl(displayCategories['display_name']),
          Flag: new UntypedFormControl(displayCategories['Flag']),
          Priority_value: new UntypedFormControl(displayCategories['Priority_value'])
        });
        this.isLoading = false;
        displayCategories['type'] = 'update';
        return displayCategories;
      });
    });
  }

  updateCategory(category){
    if(category.id){
        this.posDisplayService.updateCategory(category).subscribe(data => {
          this.alertPopup('update_ctgry','Category saved successfully.');
            // alert('Category saved successfully.');
            // this.ngOnInit();
        },
        error => {
          this.alertPopup('', 'Error while saving category.');
          // alert('Error while saving category.');
        });
    }
  }

  alertPopup(name,msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (name == 'update_ctgry') {
          this.ngOnInit();
        }
      }
    });
  }

}

