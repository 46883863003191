import {FormBase} from './form-base';

export class FormCheckBox extends FormBase<boolean> {
  controlType = 'checkbox';
  type: boolean;
  value = false;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || this.controlType;
    this.value = options['value'] === true
  }
}
