<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="content" *ngIf="!isLoading">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows width="100%">
      <ng-container matColumnDef="canvas">
        <th mat-header-cell *matHeaderCellDef> Canvas </th>
        <td mat-cell *matCellDef="let element">{{element.canvas}}</td>
      </ng-container>

      <ng-container matColumnDef="screen_id">
        <th mat-header-cell *matHeaderCellDef fxHide.lt-sm>Screen ID</th>
        <td mat-cell *matCellDef="let element" fxHide.lt-sm>{{element.tv_id}}</td>
      </ng-container>

      <ng-container matColumnDef="content_type">
        <th mat-header-cell *matHeaderCellDef>Content Type</th>
        <td mat-cell *matCellDef="let element">{{element.content_type}}</td>
      </ng-container>
      
      <ng-container matColumnDef="screen_ur">
        <th mat-header-cell *matHeaderCellDef>Screen URL</th>
        <td mat-cell *matCellDef="let element">{{element.sUrl}}</td>
      </ng-container>      

      <ng-container matColumnDef="star">
          <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="clean-icon">
          <mat-menu #operations="matMenu">
            <!-- <button [disabled]="element.user_profile.role.role_name === 'MASTER_ADMIN'" mat-menu-item
            (click)="deleteUser(element)">Delete</button> -->
          </mat-menu>

          <button *ngIf="element.type != 'add'" mat-icon-button [matMenuTriggerFor]="operations">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <form class="form" [formGroup]="element.form" (ngSubmit)="updateCategory(element.form.value)">
              <input type="hidden" formControlName="id" >
              <div class="form-row">
                <mat-form-field class="width-20">
                  <input matInput placeholder="Canvas" formControlName="canvas" autofocus required>
                </mat-form-field>
                <mat-form-field class="width-20">
                  <input matInput placeholder="Screen ID" formControlName="tv_id" required>
                </mat-form-field>
                <mat-form-field class="width-20">
                  <input matInput placeholder="Screen" formControlName="screen_ur" required>
                </mat-form-field>
              </div>

              <div class="action">

                <button mat-stroked-button color="primary" (click)="expandedElement = null; false">Cancel</button>
                <button mat-flat-button color="primary"
                  type="submit"
                  *ngIf="element.type == 'update'"
                  [disabled]="!element.form.valid">Save</button>
                <button mat-flat-button color="primary"
                  type="submit"
                  *ngIf="element.type == 'add'"
                  [disabled]="!element.form.valid">Add</button>
              </div>
            </form>
          </div>
        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>


