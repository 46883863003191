import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from 'src/app/_models/store';
import {Subject, Subscription} from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-order-filters',
  templateUrl: './order-filters.component.html',
  styleUrls: ['./order-filters.component.scss']
})
export class OrderFiltersComponent implements OnInit {

  form: UntypedFormGroup;
  @Input() store: Store;
  @Input() isOpenClose: any;
  occassions = ['PICKUP', 'DELIVERY', 'DINE-IN', 'CURBSIDE', 'TABLESIDE'];

  public unsubscribe: Subject<null> = new Subject<null>();
  allUserData: any;
  storeSub: Subscription;

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService, private storeService: StoreService,) { }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const store = localStorage.getItem('currentStore');
    this.userService.getPOSAllUser(store).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      const userNames: string[] = data.map((user: any) => `${user.first_name} ${user.last_name}`);
      this.allUserData = userNames;
    });
    this.reset();

    this.storeSub = this.storeService.current.pipe(takeUntil(this.unsubscribe)).subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
    
      }
      if (this.store && this.store.hasModule('POS')) {
        this.occassions.push('TO-GO','FOR-HERE');
        
      }
    });
  }

  onEnter(event: KeyboardEvent): void {
    event.preventDefault();
  }

  reset(){
    this.form = this.formBuilder.group({
      filter: [''],
      start_date: [''],
      end_date: [''],
      source: [[]],
      occasion: [[]],
      payment: [[]],
      status: [[]],
      server_id: [[]]
    });
  }

  clearSearch(){
    this.form.get('filter').setValue('');
  }

  search(){}

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
