<h3 mat-dialog-title>Phone verification</h3>
<mat-horizontal-stepper linear #stepper>
  <mat-step label="Start" editable="false">
    <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
      <p class="text-justify light">To verify, you will receive a call to the primary business phone number below wih a
        numeric verification code.
        You will need to enter the code you see on the next page on your telephone keypad</p>
      <p class="phone-highlight">{{store.phone | phone}}</p>
      <p class="text-center light">Are you by the phone and able to answer the call now?</p>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <mat-spinner-button (click)="callNow(stepper)" [options]="callNowBtnOpts">Call Now</mat-spinner-button>
    </mat-dialog-actions>
  </mat-step>
  <mat-step label="Calling" editable="false">
    <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
      <p class="text-justify light">Calling now... Please answer the call and enter the Numeric Code below into the
        telephone keypad</p>
      <p class="verify-code">{{verificationCode}}</p>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <mat-spinner-button (click)="verify(stepper)" [options]="verifyBtnOpts">Submit</mat-spinner-button>
    </mat-dialog-actions>
  </mat-step>
  <mat-step label="Success" editable="false">
    <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
      <p class="text-justify light">Thank you! You have successfully verified phone number:</p>
      <p class="phone-highlight">{{store.phone | phone}}</p>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button (click)="dialogRef.close()">Close</button>
    </mat-dialog-actions>
  </mat-step>
</mat-horizontal-stepper>
