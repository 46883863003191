import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '../_models/store';
import {StoreService} from '../_services/store.service';
import {Subject, Subscription} from 'rxjs';
import {OrderTableComponent} from './order-table/order-table.component';
import {takeUntil} from 'rxjs/operators';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-order-v2',
  templateUrl: './order-v2.component.html',
  styleUrls: ['./order-v2.component.scss']
})
export class OrderV2Component implements OnInit, OnDestroy {

  isLoading = false;
  form: UntypedFormGroup;
  store: Store;

  @ViewChild(MatAccordion,{static: false}) accordion: MatAccordion;
  @ViewChild(OrderTableComponent,{static: false}) orderTable: OrderTableComponent;

  statusSocket;
  statusSocketSubject: Subscription;
  step = 2;
  private destroySubject: Subject<void> = new Subject();

  constructor(private formBuilder: UntypedFormBuilder,
              private storeService: StoreService) { }

  ngOnInit() {
    this.isLoading = true;
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      if(store){
        this.store = store;
        this.reset();
        this.isLoading = false;
        console.log(this.store);
      }
    });
  }

  search(){}

  onExpand(){
    console.log('expanded');
  }

  clearSearch(){
    this.form.get('filter').setValue('');
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    if(this.statusSocketSubject){
      this.statusSocketSubject.unsubscribe();
    }
  }

  reset(){
    this.form = this.formBuilder.group({
      filter: [''],
      start_date: [''],
      end_date: [''],
      source: [[]],
      occasion: [[]],
      payment: [[]],
      status: [[]]
    });
  }

}
