import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pin-verify-pad',
  templateUrl: './pin-verify-pad.component.html',
  styleUrls: ['./pin-verify-pad.component.scss']
})
export class PinVerifyPadComponent implements OnInit {

  input = "";
  result;

  @Output() pinEmitter: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  pressNum(num: string) {
    const updatedText = this.input + num;
    if (updatedText.length === 5) {
      return;
    }
    this.input = this.input + num;
    this.calcAnswer();
  }

  allClear() {
    this.result = '';
    this.input = '';
  }

  calcAnswer() {
    const formula = this.input;
    // this.result = eval(String(+formula));
    this.result = formula;
    this.pinEmitter.emit(this.result);
  }

  clear() {
    if (this.input !== '') {
      this.input = this.input.substr(0, this.input.length - 1);
      if (!this.input) {
        this.result = null;
        return;
      }
      this.calcAnswer();
    }
  }


}
