import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PwaSessionService {

  constructor(private swUpdate: SwUpdate) {}

  refreshSession() {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'REFRESH_SESSION' });
    }
  }
}
