export class Permission{
  ACCESS: string[];
  ORDERS: string[];
  POS_FUNCTION: string[];
  POS_ALERTS: string[];

  constructor(){
    this.ACCESS = [];
    this.ORDERS = [];
    this.POS_FUNCTION = [];
    this.POS_ALERTS = [];
  }

  hasPermission(group, permission){
    return group in this && this[group] && this[group].includes(permission)
  }
}
