<span *ngIf="data.view == 'select'">
    <form class="master-cat-from" name="addDefaultScreenForm" [formGroup]="addDefaultScreenForm"
        (ngSubmit)="addDefaultFormSubmit()" class="fixActionRow">
        <h1 mat-dialog-title>Select Default Screen</h1>

        <div mat-dialog-content>
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Default Screen</mat-label>
                    <mat-select formControlName="screen_id">
                        <mat-option *ngFor="let data of screenList" [value]="data.id">
                            {{data.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>

<span *ngIf="data.view == 'set'">
    <form class="master-cat-from" name="addDefaultScreenForm" [formGroup]="addDefaultScreenForm"
        (ngSubmit)="addDefaultScreenFormSubmit()" class="fixActionRow">
        <h1 mat-dialog-title>Add Default Screen</h1>

        <div mat-dialog-content>
            <div fxLayout="row" class="!mt-4" style="width: 100%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Screen Name</mat-label>
                    <input matInput placeholder="" type="text" formControlName="name">
                </mat-form-field>
            </div>
        </div>
        <mat-dialog-actions align="end" class="button-box-right form-row">
            <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
            <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
        </mat-dialog-actions>
    </form>
</span>
<span *ngIf="data.view == 'comman'">
    <h1 mat-dialog-title>Add New Screen</h1>
    <div mat-dialog-content>
        Are you sure you want to Add New Screen
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
        <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
        <button mat-button mat-flat-button color="primary" class="apply-btn-colr" (click)="newScreenCreate()">Submit</button>
    </mat-dialog-actions>

</span>