import {CardDetails} from './order';

export class PaymentOption {
  cash: boolean;
  card: boolean;
  storeCredit: boolean;
  cardDetail: CardDetails;
  storeCreditBalance: number;

  constructor(cash: boolean, card: boolean, storeCredit: boolean,
              cardDetail: CardDetails, storeCreditBalance: number = 0) {
    this.cash = cash;
    this.card = card;
    this.storeCredit = storeCredit;
    this.cardDetail = cardDetail;
    this.storeCreditBalance = storeCreditBalance;
  }

  hasPaymentOption(){
    return this.cash || this.card || this.storeCredit || (this.storeCredit && this.cash) || (this.storeCredit && this.card)
  }
}
