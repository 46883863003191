<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<ng-container *ngIf="!isLoading">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Campaigns">
      <div class="container xs:!overflow-hidden xs:!p-3">
        <div class="controls">
          <div id="stats" class="drop-shadow-md">
            <div class="grid grid-cols-3 gap-2">
              <div class="flex flex-col text-center border-r border-gray-300">
                <span class="text-xl">{{stats?.total}}</span>
                <span class="text-sm">Total Users</span>
              </div>
              <div class="flex flex-col text-center border-r border-gray-300 lab-text-blue">
                <span class="text-xl">{{stats?.opted_in}}</span>
                <span class="text-sm">Opt-in Users</span>
              </div>
              <div class="flex flex-col text-center">
                <span class="text-xl">{{ ((stats?.opted_in / stats.total) * 100).toFixed() }}%</span>
                <span class="text-sm">Opt-in Rate</span>
              </div>
            </div>
          </div>

          <button mat-flat-button color="primary" class="create action-btn-colr" (click)="startCampaign()">
            NEW CAMPAIGN
          </button>

          <div style="clear: both"></div>
        </div>

        <div class="coloredContainerX" fxlayout="row" fxLayout.lt-sm="column" cols="2" >
<!--          <div fxFlex.xs="100%" fxFlex.lt-md="70%" fxFlex="70%">-->
            <app-campaign-message-table [stats]="stats" ></app-campaign-message-table>
<!--          </div>-->
        </div>
      </div>

    </mat-tab>
    <mat-tab label="Contacts">
      <div class="container">
        <app-contact [store]="store"></app-contact>
      </div>
    </mat-tab>
    <mat-tab label="Custom List">
      <div class="container">
        <app-campaign-custom-list [store]="store"></app-campaign-custom-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
