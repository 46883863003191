import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BotOrder} from 'src/app/_models/order';
import {StoreService} from 'src/app/_services/store.service';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DatePipe, formatDate} from '@angular/common';
import * as moment from 'moment';
import {Store} from 'src/app/_models/store';
import {saveAs as importedSaveAs} from 'file-saver';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-gift-card-order',
  templateUrl: './gift-card-order.component.html',
  styleUrls: ['./gift-card-order.component.scss']
})
export class GiftCardOrderComponent implements OnInit {
  startDate;
  storeId: any;
  dataGift: any;
  openTrue: any;
  Daterange: UntypedFormGroup;

  displayedGiftColumns = ["order_id", "user_name", "phone_no", "placed", "redeem_id", "remaining_balance" , "transaction_type", "transaction_detail", "gift_card_id", "target_store"];
  // giftDataSource: any;
  giftDataSource: any;
  selectedRow: boolean;
  search: any;


  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  @Output() openSideNav: EventEmitter<boolean> = new EventEmitter();
  toDate: any;
  fromDate: any;
  resetEndDate: any;
  resetDate: any;
  isLoading: boolean = false;
  store:Store;

  constructor(
    private storeService: StoreService,
    private receiptService: ReceiptService,
    private _formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {

    this.toDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    var startdate = moment().subtract(30, "days").format("YYYY-MM-DD");
    this.fromDate = startdate;

    this.resetEndDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.resetDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.getGiftCardList();

    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store);
      }
    })
    this.Daterange = this._formBuilder.group({
      from_date: [this.fromDate],
      to_date: [this.toDate],
      search: ''

    })

  }

  getGiftCardList() {
    let keyData = {
      start_date: this.fromDate,
      end_date: this.toDate
    }
    this.storeService.giftCardOrdersList(this.storeId, keyData).subscribe((data: any) => {
      this.dataGift = data;
      this.giftDataSource = new MatTableDataSource(this.dataGift);
      this.giftDataSource.paginator = this.paginator;
      this.giftDataSource.sort = this.sort;
      this.isLoading = false;
    })
  }

  onRowClicked(row) {
    if (!this.selectedRow) {
      this.selectedRow = row;
    } else {
      this.selectedRow = row;
    }

    if (row.transaction_type == 'Redemption') {
      let botOrder = Object.assign(new BotOrder(), row);
      this.receiptService.changeBotOrder(botOrder) == null;
      this.openSideNav.emit(true);

    } else {
      let botOrder = Object.assign(new BotOrder(), row);
      this.receiptService.changeBotOrder(botOrder);
      this.openSideNav.emit(true);
    }

  }

  filterSubmit() {
    this.isLoading = true;
    this.fromDate = this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd');
    this.Daterange = this._formBuilder.group({
      from_date: [this.fromDate],
      to_date: [this.toDate],
      search: ''

    })

    this.getGiftCardList();

    /* Filter From Front End */
    // this.giftDataSource = this.dataGift.filter(a => {
    //   var date = this.datePipe.transform(new Date(a.created_at), 'yyyy-MM-dd');
    //   return (date >= this.startDate && date <= this.endDate);
    // });
    // this.paginator.length = this.giftDataSource.length;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.giftDataSource.filter = filterValue;

  }

  reset() {
    this.toDate = this.resetEndDate
    this.fromDate = this.resetDate
    this.Daterange = this._formBuilder.group({
      from_date: [this.fromDate],
      to_date: [this.toDate],
      search: ''
    })
    this.getGiftCardList();
  }

  exportData() {
    this.isLoading = true;
    this.storeService.getGiftCradOrdersCsv(this.storeId, this.fromDate, this.toDate).subscribe(data => {
      importedSaveAs(data, `${this.store.store_name}-labrador-gift-crad-orders-list.tsv`);
      this.isLoading = false;
    });
  }
}
