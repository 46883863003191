import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';

import SignaturePad from 'signature_pad';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {

  isError;
  isConfirmed;

  hide = true;

  pin: string;

  @ViewChild('canvas',{static: false}) canvas: ElementRef;
  sig: SignaturePad;

  constructor(private formBuilder: UntypedFormBuilder,
              private dialogRef: MatDialogRef<SignaturePadComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
    this.sig = new SignaturePad(this.canvas.nativeElement);
  }

  updatePin(event) {
    this.pin = event;
  }

  cancel() {
    this.dialogRef.close(false);
  }
  done() {
    const pin = this.pin || '0000';
    const payload = {
      signed: true,
      pin: this.pin
    };
    console.log(payload);
    this.dialogRef.close(payload);
  }

  clear() {
    this.sig.clear();
  }

}
