<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="container" *ngIf="!isLoading">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>
      <app-order-filters></app-order-filters>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="orders">
    <mat-accordion #accordion (selectionChange)="onExpand()">
      <mat-expansion-panel [expanded]="step === 1" (afterExpand)="openOrders.loadOpenOrders()"
      *ngIf="store && (store.has_order_occasion_tableside || store?.hasModule('POS'))">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Open Orders
          </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
          <div class="order-table">
            <app-order-table
              #openOrders
              [filters]="form.value"
              [isOpenOrders]="true"></app-order-table>
          </div>
      </mat-expansion-panel >
      <mat-expansion-panel [expanded]="step === 2" (afterExpand)="closedOrders.loadClosedOrders()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Closed Orders
          </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
          <div class="order-table">
            <app-order-table
              #closedOrders
              [filters]="form.value"
              [isOpenOrders]="false"></app-order-table>
          </div>
      </mat-expansion-panel>
    </mat-accordion>


  </div>
</div>
