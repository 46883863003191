<mat-sidenav-container class="example-container">
  <mat-sidenav #contactList mode="side"
    [opened]="userListOpened">
    <app-contact-list #userList
      [toDelete]="toDelete"
      [sidebar]="contactList"
      (userSelected)="onUserSelected($event); width < 768 ? contactList.toggle() : false"
      >
    </app-contact-list>
  </mat-sidenav>
  <mat-sidenav mode="side" position="end" closed #editUser>
    <app-edit-contact
      [user]="selectedUser"
      [store]="store"
      [sidebar]="editUser"
      (profileUpdateEvent)="profileUpdate($event)">
    </app-edit-contact>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-messages-view
      [isLoading]="isLoading"
      [previousMessage]="previousMessages"
      [currentMessage]="currentMessage"
      [selectedUser]="selectedUser"
      [editUserSideNav]="editUser"
      [contactListSideNav]="contactList"
      (sendMessage)="onSendMessage($event)"
      (deleteUser)="onDelete($event)">
    </app-messages-view>
  </mat-sidenav-content>
</mat-sidenav-container>
