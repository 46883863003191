<span *ngIf="storeCheck == 'Store1'">
  <form class="master-cat-from" name="addMasterCategoryForm" [formGroup]="addMasterCategoryForm"
    (ngSubmit)="addMasterCategoryFormSubmit()" class="fixActionRow">
    <h1 mat-dialog-title>Add Master Category</h1>
    <div mat-dialog-content style="overflow: hidden;">
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Priority</mat-label>
            <input matInput placeholder="" type="number" formControlName="priority">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;margin-bottom:17px;">
        <div class="input-gap" style="width: 50%;">
          <mat-checkbox (change)="newScreenCheck($event)" [checked]="screen">New screen</mat-checkbox>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide category</mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
  </form>
</span>

<span *ngIf="storeAll == 'allStore'">
  <form name="addMasterCategoryAllStoreForm" [formGroup]="addMasterCategoryAllStoreForm"
    (ngSubmit)="addMasterCategoryAllStoreFormSubmit()" class="fixActionRow">
    <h1 mat-dialog-title>Add Master Category</h1>
    <div mat-dialog-content>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Priority</mat-label>
            <input matInput placeholder="" type="number" formControlName="priority">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>SubCategory</mat-label>
            <mat-select formControlName="subcategory" multiple>
              <div class="search-filter-input">
                <mat-form-field appearance="fill" fxFlex="100" style="padding:10px;">
                  <mat-label>Search for Sub Category</mat-label>
                  <input matInput [(ngModel)]="searchSubCategory" formControlName="SubCategory_search"
                    autocomplete="off">
                  <mat-icon class="admin-icons" matPrefix>search
                  </mat-icon>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let topping of subcategory_list_data | filter:searchSubCategory" [value]="topping.id">
                {{topping.name}}{{topping.subgroup ? ' - ' + topping.subgroup : ''}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Font-color</mat-label>
            <input matInput placeholder="" type="text" formControlName="font_color">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field class="filter-button" appearance="fill" fxFlex="100">
            <mat-label>Select Emoji</mat-label>
            <mat-select class="subject-text" formControlName="emoji" style="width: 100%;">
              <div class="search-filter-input">
                <mat-form-field appearance="fill" fxFlex="100" style="padding:10px;">
                  <mat-label>Search for emoji</mat-label>
                  <input matInput [(ngModel)]="searchEmoji" formControlName="emoji_search" autocomplete="off">
                  <mat-icon class="admin-icons" matPrefix>search
                  </mat-icon>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of emoji | filter:searchEmoji" [value]="state.value">
                <img style="float: left; margin-right: 10px; width: 30px; height: 30px; max-width: 30px;"
                  class="example-option-img" aria-hidden [src]="state.value" height="25">
                <span style="line-height: 35px;">{{state.name}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>ModifierGroup</mat-label>
            <mat-select formControlName="modifiergroups" multiple>
              <div class="search-filter-input">
                <mat-form-field appearance="fill" fxFlex="100" style="padding:10px;">
                  <mat-label>Search for ModifierGroup</mat-label>
                  <input matInput [(ngModel)]="searchEmoji" formControlName="emoji_search" autocomplete="off">
                  <mat-icon class="admin-icons" matPrefix>search
                  </mat-icon>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let topping of showModifierGroupSelete | filter:searchEmoji" [value]="topping.id">
                {{topping.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field class="filter-button" appearance="fill" fxFlex="100">
            <mat-label>Select Alignment</mat-label>
            <mat-select class="subject-text" formControlName="alignment" style="width: 100%;">
              <mat-option value="center"> Center </mat-option>
              <mat-option value="left">Left </mat-option>
              <mat-option value="right"> Right </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Font size</mat-label>
            <input matInput placeholder="Font size" formControlName="font_size" type="number">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field class="filter-button" fxFlex="100">
            <mat-label>Select Modifiergroups subcat alignment</mat-label>
            <mat-select class="subject-text" formControlName="modifiergroups_subcat_alignment" style="width: 100%;">
              <mat-option value="center"> Center </mat-option>
              <mat-option value="left">Left </mat-option>
              <mat-option value="right"> Right </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Modifiergroups subcat font color</mat-label>
            <input matInput placeholder="Font color" formControlName="modifiergroups_subcat_font_color" type="text">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Modifiergroups subcat font size</mat-label>
            <input matInput placeholder="Font size" formControlName="modifiergroups_subcat_font_size" type="number">
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Modifiergroups subcat background color</mat-label>
            <input matInput placeholder="Background color" formControlName="modifiergroups_subcat_background_color"
              type="text">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field class="filter-button" fxFlex="100">
            <mat-label>Select Single Pricing Alignment</mat-label>
            <mat-select class="subject-text" formControlName="single_pricing_alignment" style="width: 100%;">
              <mat-option value="center"> Center </mat-option>
              <mat-option value="left">Left </mat-option>
              <mat-option value="right"> Right </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="input-gap" style="width: 50%;">
          <mat-form-field class="filter-button" fxFlex="100">
            <mat-label>Select Price Format</mat-label>
            <mat-select class="subject-text" formControlName="price_format" style="width: 100%;">
              <mat-option value="top_view"> Top View </mat-option>
              <mat-option value="inline_view">Inline View </mat-option>
              <mat-option value="hide">Hide </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div fxLayout="row" class="!mt-4" style="width: 100%;">

        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Price Header Font Color</mat-label>
            <input matInput placeholder="Price Header Font Color" formControlName="price_header_font_color" type="text">
          </mat-form-field>
        </div>

        <!-- <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Product Price Font Size</mat-label>
            <input matInput placeholder="Product Price Font Size" formControlName="product_price_font_size" type="text">
          </mat-form-field>
        </div> -->

        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Margin Top</mat-label>
            <input matInput placeholder="Margin Top" formControlName="margin_top" type="text">
          </mat-form-field>
        </div>

      </div>

      <div fxLayout="row" class="!mt-4" style="width: 100%;">

        

        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Margin Bottom</mat-label>
            <input matInput placeholder="Margin Bottom" formControlName="margin_bottom" type="text">
          </mat-form-field>
        </div>

        <div class="input-gap" style="width: 50%;">
          <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide Master
            category</mat-checkbox>
        </div>



      </div>



      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <!-- <div class="input-gap" style="width: 33.33%;">
          <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide Master category</mat-checkbox>
        </div> -->

        


        <div class="input-gap" style="width: 50%;">
          <mat-checkbox (change)="newScreenCheck($event)" [checked]="newScreen">New screen</mat-checkbox>
        </div>

      </div>

      



    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
  </form>

</span>