<div class="table" *ngIf="dataSource.length > 0">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z3" width="100%">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Total </th>
      <td mat-cell *matCellDef="let element">{{element.total}}</td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef> Active </th>
      <td mat-cell *matCellDef="let element">{{element.active}}</td>
    </ng-container>

    <ng-container matColumnDef="unsubscribed">
      <th mat-header-cell *matHeaderCellDef> Unsubscribed </th>
      <td mat-cell *matCellDef="let element">{{element.unsubscribed}}</td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element"
      [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <app-contact-active-table
          [store]="store"
          [isBlocked]="element.is_blocked"
          (refreshParent)="ngOnInit()"
          ></app-contact-active-table>
        </div>
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
</div>
