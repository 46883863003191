import {Injectable} from '@angular/core';
import {FormBase} from './dynamic-form/dynamic-form-control/form-base';
import {FormDropDown} from './dynamic-form/dynamic-form-control/form-dropdown';
import {FormTextBox} from './dynamic-form/dynamic-form-control/form-textbox';
import {FormCheckBox} from './dynamic-form/dynamic-form-control/form-checkbox';
import {Store} from '../../_models/store';
import {FormPhone} from './dynamic-form/dynamic-form-control/form-phone';
import {FormNumber} from './dynamic-form/dynamic-form-control/form-number';
import {FormTextArea} from './dynamic-form/dynamic-form-control/form-textarea';
import {FormKeyTextBox} from './dynamic-form/dynamic-form-control/form-key-textbox';
import {pad} from 'src/app/constants';


@Injectable()
export class StoreDataService {


  getFormDataControls(store: Store) {
    console.log(store['ivr_settings']['closed_hours_message']);
    console.log(store['ivr_settings']);



    const storeControls: FormBase<any>[] = [

      new FormTextBox({
        key: 'account.company',
        label: 'Company',
        required: true,
        value: store.account.company,
        order: 1,
        spellcheck:true
      }),
      new FormTextBox({
        key: 'store_name',
        label: 'Store Name',
        required: true,
        value: store.store_name,
        order: 1,
        spellcheck:true
      }),

      new FormTextArea({
        key: 'address',
        label: 'Business Address',
        required: true,
        value: store.address,
        order: 2,
        spellcheck:true
      }),

      new FormNumber({
        key: 'delivery_radius',
        label: 'Service Areas',
        required: true,
        value: store['delivery_radius'],
        order: 3
      }),

      new FormNumber({
        key: 'delivery_charge',
        label: 'Delivery Charge',
        required: true,
        value: store['delivery_charge'],
        order: 3
      }),

      new FormNumber({
        key: 'service_charge',
        label: 'Service Charge',
        required: true,
        value: store['service_charge'],
        order: 3
      }),

      new FormNumber({
        key: 'delivery_minimum',
        label: 'Delivery Minimum',
        required: true,
        value: store['delivery_minimum'],
        order: 3
      }),

      new FormPhone({
        key: 'phone',
        label: 'Primary Phone Number',
        required: true,
        value: store.phone,
        order: 5
      }),

      new FormPhone({
        key: 'secondary_phone',
        label: 'Secondary Phone Number',
        required: true,
        value: store.secondary_phone,
        order: 5
      }),

      new FormPhone({
        key: 'ivr_phone',
        label: 'IVR Phone Number',
        required: true,
        value: store.ivr_phone || '',
        order: 5
      }),

      new FormPhone({
        key: 'connect_number',
        label: 'Connect Number',
        required: true,
        value: store.connect_number || '',
        order: 5
      }),

      new FormPhone({
        key: 'sms_number',
        label: 'SMS Number',
        required: true,
        value: store.sms_number,
        order: 6
      }),

      new FormNumber({
        key: 'pickup_dine_in_lead_time',
        label: 'Pick Up',
        required: true,
        value: store.pickup_dine_in_lead_time,
        order: 8
      }),

      new FormNumber({
        key: 'dine_in_lead_time',
        label: 'Dine In',
        required: true,
        value: store.pickup_dine_in_lead_time,
        order: 9
      }),

      new FormNumber({
        key: 'delivery_lead_time',
        label: 'Delivery',
        required: true,
        value: store.delivery_lead_time,
        order: 10
      }),

      new FormTextBox({
        key: 'gmb_location_id',
        label: 'Google Places Id',
        required: true,
        value: store['gmb_location_id'],
        order: 11,
        spellcheck:false
      }),

      new FormTextArea({
        key: 'gmb_menu_url',
        label: 'Menu URL',
        required: true,
        value: store['gmb_menu_url'],
        order: 12,
        spellcheck:false
      }),

      new FormTextArea({
        key: 'gmb_order_url',
        label: 'Order Ahead URL',
        required: true,
        value: store['gmb_order_url'],
        order: 13,
        spellcheck: false
      }),

      new FormTextArea({
        key: 'gmb_reservation_url',
        label: 'Reservations URL',
        required: true,
        value: store['gmb_reservation_url'],
        order: 14,
        spellcheck: false
      }),

      new FormNumber({
        key: 'printouts_per_order',
        label: 'Printouts per pickup order',
        required: true,
        value: store['printouts_per_order'],
        order: 15
      }),
      new FormNumber({
        key: 'printouts_per_delivery_order',
        label: 'Printouts per delivery order',
        required: true,
        value: store['printouts_per_delivery_order'],
        order: 15
      }),
      new FormCheckBox({
        key: 'has_order_occasion_pickup',
        label: 'Pickup',
        required: true,
        value: store['has_order_occasion_pickup'],
        order: 16
      }),
      new FormCheckBox({
        key: 'has_order_occasion_delivery',
        label: 'Delivery',
        required: true,
        value: store['has_order_occasion_delivery'],
        order: 16
      }),
      new FormCheckBox({
        key: 'has_order_occasion_dine_in',
        label: 'Dine-In',
        required: true,
        value: store['has_order_occasion_dine_in'],
        order: 16
      }),
      new FormCheckBox({
        key: 'has_order_occasion_curbside',
        label: 'Curbside',
        required: true,
        value: store['has_order_occasion_curbside'],
        order: 16
      }),
      new FormCheckBox({
        key: 'has_order_occasion_tableside',
        label: 'Tableside',
        required: true,
        value: store['has_order_occasion_tableside'],
        order: 16
      }),
      new FormCheckBox({
        key: 'has_order_occasion_togo',
        label: 'To-Go',
        required: true,
        value: store['has_order_occasion_togo'],
        order: 16
      }),
      new FormCheckBox({
        key: 'has_order_occasion_forhere',
        label: 'For-Here',
        required: true,
        value: store['has_order_occasion_forhere'],
        order: 16
      }),
      new FormTextBox({
        key: 'ivr_welcome_message',
        label: 'Welcome Message',
        required: true,
        value: store['ivr_welcome_message'],
        order: 17,
        spellcheck:false
      }),

      new FormKeyTextBox({
        key: 'ivr_settings.closed_hours_message',
        label: 'Closed hours Message',
        required: true,
        value: store['ivr_settings']['closed_hours_message'],
        order: 17,
        spellcheck:false
      }),
      new FormNumber({
        key: 'printouts_per_curbside_order',
        label: 'Printouts per curbside order',
        required: true,
        value: store['printouts_per_curbside_order'],
        order: 15
      }),
      new FormNumber({
        key: 'printouts_per_dine_in_order',
        label: 'Printouts per dine-in order',
        required: true,
        value: store['printouts_per_dine_in_order'],
        order: 15
      })

    ];

    return storeControls.sort((a, b) => a.order - b.order);
  }

  getWorkingHoursControls(workingHours: any) {

    const workingHourControls: FormBase<any>[][] = [];

    for (let i = 0; i < 7; i++) {
      workingHourControls[i] = this.buildWorkingHourControls(i, workingHours);
    }

    return workingHourControls;
  }

  buildWorkingHourControls(i, workingHours) {
    const dropDowns = [];

    dropDowns.push(this.buildCheckBox(workingHours, i, 'is_open', 'Is Open'));
    dropDowns.push(this.buildTimeDropdown(workingHours, i, 'from', 'From'));
    dropDowns.push(this.buildTimeDropdown(workingHours, i, 'to', 'To'));
    // dropDowns.push(this.buildDropDown(workingHours, i, 'from_hour', 'From Hour', 'HOUR'));
    // dropDowns.push(this.buildDropDown(workingHours, i, 'from_min', 'From Min', 'MIN'));
    // dropDowns.push(this.buildDropDown(workingHours, i, 'to_hour', 'To Hour', 'HOUR'));
    // dropDowns.push(this.buildDropDown(workingHours, i, 'to_min', 'To Min', 'MIN'));

    return dropDowns;
  }

  buildCheckBox(workingHours, index, pKey, pLabel) {
    return new FormCheckBox({
      id: pKey + '_' + index,
      key: pKey,
      label: pLabel,
      controlType: 'dropdown',
      value: workingHours[index][pKey],
    });
  }


  buildDropDown(workingHours, index, pKey, pLabel, type) {
    return new FormDropDown({
          id: pKey + '_' + index,
          key: pKey,
          label: pLabel,
          controlType: 'dropdown',
          value: workingHours[index][pKey],
          options: type === 'HOUR' ? this.buildOptions(0, 23) : this.buildOptions(0, 59)
    });
  }

  buildOptions(start, end) {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push({key: i,  value: i});
    }
    return options;
  }

  buildTimeDropdown(workingHours, index, pKey, pLabel){
    return new FormDropDown({
      id: pKey + '_' + index,
      key: pKey,
      label: pLabel,
      controlType: 'dropdown',
      value: this.get12HourValue(workingHours[index][pKey + "_hour"], workingHours[index][pKey + "_min"]),
      options: this.buildTimeOptions()
    });
  }

  get12HourValue(hour, min){
    const minString = pad(min, 2);
    if(hour === 12){
      return `${hour}:${minString} pm`
    }
    else if(hour > 12){
      return `${hour - 12}:${minString} pm`
    }else if (hour === 0){
      return `${hour + 12}:${minString} am`
    }else{
      return `${hour}:${minString} am`
    }
  }

  pad(num: number, size: number): string {
    let s = num+"";
    while (s.length < size) s = '0' + s;
    return s;
}


  buildTimeOptions(){
    let options = [];
    for(let hour = 0; hour < 12; hour++){
      for(let min = 0; min < 60; min += 5){
        const minString = this.pad(min, 2);
        const hourString = hour === 0 ? '12' : hour;
        options.push({hour:hour, min:minString, key:`${hourString}:${minString}`, value:`${hourString}:${minString} am`});
      }
    }

    for(let hour = 0; hour < 12; hour++){
      for(let min = 0; min < 60; min += 5){
        const minString = this.pad(min, 2);
        const hourString = hour === 0 ? '12' : hour;
        options.push({hour:hour + 12, min:minString, key:`${hourString}:${minString}`, value:`${hourString}:${minString} pm`});
      }
    }
    return options;
  }

}
