import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {POSStreamService} from 'src/app/_services/pos-stream.service';
import {environment} from 'src/environments/environment';
import {SignaturePadComponent} from '../signature-pad/signature-pad.component';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-card-payment-ws',
  templateUrl: './card-payment-ws.component.html',
  styleUrls: ['./card-payment-ws.component.scss']
})
export class CardPaymentWsComponent implements OnInit, OnDestroy {

  isLoading;
  result;
  logs = [];

  progress = 0;
  isConfirmed = false;
  maskedCardNum = '';
  isError = false;

  private statusSocket:WebSocketSubject<any>;
  terminalId;

  processSubject: Subscription;
  closeOrderSubject: Subscription;
  statusSocketSubject: Subscription;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<CardPaymentWsComponent>,
    private posStreamService: POSStreamService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.data);
    this.terminalId = localStorage.getItem("selectedTerminal");
    this.processSubject = this.posStreamService.process(this.terminalId, this.data).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.logs.push(`Payment request sent to terminal: $${this.data.amount}`);
      this.logs.push('Waiting for response');
      this.progress = 20;
      this.initSocket();
    }, error =>{
      this.logs.push('Unable to process payment, Server returned an error.');
    });
  }

  ngOnDestroy(): void {
    if(this.statusSocketSubject){
      this.statusSocketSubject.unsubscribe();
    }

    if(this.processSubject){
      this.processSubject.unsubscribe();
    }

    if(this.closeOrderSubject){
      this.closeOrderSubject.unsubscribe();
    }

    this.destroySubject.next();
  }

  initSocket(){
    this.statusSocket = webSocket(`${environment.websocketHost}/ws/link/${this.terminalId}/`);
    this.statusSocketSubject = this.statusSocket.pipe(takeUntil(this.destroySubject)).subscribe(
      (msg) => {
        switch(msg.command){
          case 'CARD_READ':
            this.maskedCardNum = msg.masked_num;
            this.logs.push('Processing Card: ', msg.masked_num);
            this.logs.push('Confirming transaction with the gateway');
            this.progress += 20;
            break;
          case 'CONFIRM':
            console.log(msg);
            this.isConfirmed = true;
            this.logs.push('Transaction confirmed');
            this.progress = 80;
            this.logs.push('Closing the order');
            this.progress = 100;
            this.logs.push('Order successfully closed.');
            break;
          case 'PIN_REQUIRED':
          case 'SIGN_REQUIRED':
            this.logs.push('PIN required, please sign.');
            this.startSignatureVerification(msg);
            break;
          case 'ERROR':
            this.logs.push('Unable to process transaction, retry again');
            this.isError = true;
            break;
          default:
            if(!msg.message){
              this.logs.push(JSON.stringify(msg));
            }
        }
        console.log(msg);
      },
      (err) => console.log(err),
      () => console.log('complete')
    );
  }

  done(){
    if(this.statusSocketSubject){
      this.statusSocketSubject.unsubscribe();
    }

    if(this.closeOrderSubject){
      this.closeOrderSubject.unsubscribe();
    }

    if(this.statusSocket){
      this.statusSocket.complete();
    }


    this.dialogRef.close(this.isConfirmed);
  }

  cancel(){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to cancel?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        this.posStreamService.cancelOrder(this.terminalId, this.data).pipe(takeUntil(this.destroySubject)).subscribe(data => {
          this.dialogRef.close(false);
        })
      }
    });
    // if(confirm("Are you sure to cancel?")){
    //   this.posStreamService.cancelOrder(this.terminalId, this.data).subscribe(data => {
    //     this.dialogRef.close(false);
    //   })
    // }
  }

  sendPaymentRequest(){
    return this.posStreamService.process(this.terminalId, this.data).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.logs.push('Payment request sent to terminal');
      this.logs.push('Waiting for response');
      this.progress = 20;
    });
  }

  voidTransaction(){
    this.isConfirmed = false;
    this.data.command = 'VOID'
    this.progress = 0;
    return this.posStreamService.process(this.terminalId, this.data).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.logs.push('Transaction voided');
      this.isConfirmed = true;
      this.progress = 100;
    });
  }

  startSignatureVerification(msg: any){
    console.log(msg);
    const dialogRef = this.dialog.open(SignaturePadComponent,{
      data: msg, disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      result.order_id = this.data.order_id;
      this.posStreamService.pinVerification(this.terminalId, result).pipe(takeUntil(this.destroySubject)).subscribe(response => {
        console.log(response);
      });
      console.log(result);
    });
  }
}
