<h1 mat-dialog-title>Add Favicon</h1>
<mat-dialog-content>
  <div style="float: right;">
    <label for="user_logo">
      <a mat-stroked-button class="add-files-btn apply-btn-colr">Select File</a>
      <input #myInput type="file" id="user_logo" hidden (change)="onFilesAdded($event)" />
    </label>
  </div>
  <mat-list>
    <mat-list-item>
      <div *ngIf="file">
        <h4 mat-line>Selected file: {{ file.name }}</h4>
      </div>
      <div *ngIf="file1">
        <p>Previous file: {{file1}}</p>
      </div>
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button mat-stroked-button mat-button mat-dialog-close [disabled]="isLoading">Cancel</button>
  <button mat-raised-button color="primary" class="apply-btn-colr" [disabled]="!file || isLoading" (click)="uploadFile()">
    Upload
  </button>
</mat-dialog-actions>