import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ShowScreenService {
    private submitSubject = new BehaviorSubject<boolean>(false);
    submit$ = this.submitSubject.asObservable();

    submit(value: boolean) {
        this.submitSubject.next(value);
    }
}