<form [formGroup]="form">
  <div class="center">
    <p class="center title">
      Join Our VIP List for <b>Exclusive</b> Offers
    </p>
    <div class="phone-ctl">
      <input
        class="phone"
        type="tel"
        formControlName="phone_number"
        #phone
        appPhoneMask matInput autofocus
        [errorStateMatcher]="errorMatcher"
        (input)="updatePhoneNumber($event.target.value);"
        />
        <!-- [value]="orderWithPayment?.getFormattedPhoneNumber()" -->
        <div *ngIf="isUserUpdated; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <button class="phone-cta success" type="button" mat-flat-button >Success</button>
        </ng-template>
        <ng-template #elseBlock>
          <button class="phone-cta" type="button" mat-flat-button color="primary"
          (click)="updateUser(orderWithPayment.bot_order.order_hash)"
          [disabled]="!isValidPhone">Join</button>
        </ng-template>

      <mat-checkbox formControlName="opt_in">Send me exclusive offers and promotions from {{store.store_name}}</mat-checkbox>
    </div>

    <div class="receipt">
      <p>HOW WOULD YOU LIKE YOUR RECEIPT?</p>
      <div class="options">
        <button [disabled]="!isValidPhone" type="button" mat-stroked-button color="primary"
        (click)="sendTextReceipt(orderWithPayment.bot_order.order_hash)">Text</button>

        <button type="button" mat-stroked-button color="primary"
        (click)="printPaperReceipt(orderWithPayment.bot_order.order_hash)">Paper</button>

        <button type="button" mat-stroked-button color="primary"
        (click)="close()">No Receipt</button>
      </div>
    </div>

  </div>


</form>
