import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import {Emoji} from '../add-categories-dialog/add-categories-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-menu-categorg-dialog',
  templateUrl: './menu-categorg-dialog.component.html',
  styleUrls: ['./menu-categorg-dialog.component.scss']
})
export class MenuCategorgDialogComponent implements OnInit {
  storeId: any;
  addCategoryForm: UntypedFormGroup;
  getId: any;
  submitted: boolean;
  newScreen: boolean;
  subcategory_list_data: any;
  showSelete: any = [];
  categoryContent: any;
  category_list_data: any;
  screen: boolean = false;
  hideCategory: boolean = false;
  hideProductWeight: boolean = false;
  emoji: any;
  filteredStates: Observable<Emoji[]>;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<MenuCategorgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
   ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.subCategoryList();
    this.addCategoryForm = this.fb.group({
      store_id: [],
      display_name: ["", Validators.required],
      priority: ["1"],
      hide_category: [""],
      new_screen: [""],
      menuitems: [""],
      font_color: ["#FFFFFF"],
      hide_product_weight: [""],
      emoji: [""]
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
  // subcategoryList2() {
  //   this.digitalDisplayService
  //     .SubCategoriesOtionList(this.storeId)
  //     .subscribe((data) => {
  //       this.subcategory_list_data = data.data;
  //     });
  // }
  subCategoryList() {
    this.digitalDisplayService
      .subCategoryList(this.storeId).subscribe((data:any) => {
        this.subcategory_list_data = data.data;
        // this.subcategory_list_data = data.data;
      });
  }
  addCategorySubmit() {
    if (this.addCategoryForm.status == "VALID") {
      const keyData = {
        store:this.storeId,
        display_name: this.addCategoryForm.value.display_name,
        new_screen: this.screen,
        priority: this.addCategoryForm.value.priority,
        hide_category: this.hideCategory,
        // store: this.storeId,
        menuitems: this.addCategoryForm.value.menuitems,
        font_color: this.addCategoryForm.value.font_color,
        hide_product_weight: this.hideProductWeight,
        emoji:this.addCategoryForm.value.emoji
      };
      this.digitalDisplayService.addSubCategory(keyData).subscribe((data: any) => {
        const resData = { yesSubmit: "YesSubmit"}
        this.dialogRef.close(resData);
      });
    }
  }
  newScreenCheck(a) {
    this.screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.hideCategory = a.checked;
  }
  hideProductWeightCheck(a) {
    this.hideProductWeight = a.checked;
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emoji = data.data;

    });
  }
}
