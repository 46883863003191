<span *ngIf="orderGift != true">
    <iframe *ngIf="orderGiftUrl" [src]="orderGiftUrl" seamless width="375" class="margin-gift-iframe"></iframe>
</span>

<span *ngIf="orderGift == true">
    <div class="content">
        <div class="receipt">
            <div class="center hero">
                <div class="initial" mat-stroked-button>{{initial}}</div>
                <h3>{{ namestore }}</h3>
                <p>{{ address }}, {{city}}, {{geograpy}} {{zip}}</p>
            </div>
            <div class="row" style="text-align: center">
                <p></p>
                <p>
                    <span class="price">{{data[0].transaction_detail | currency:'USD'}}
                    </span>
                </p>
                <mat-divider></mat-divider>
            </div>
            <div class="row">
                <div>
                    <table width="100%" class="price-breakdown-table" cellspacing="10px;">
                        <tr>
                            <td class="item">Remaining Balance</td>
                            <td class="item right-align">
                                <span>{{data[0].remaining_balance | currency:'USD'}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="item">Store Credit</td>
                            <td class="item right-align">
                                <span>-{{data[0].transaction_detail | currency:'USD'}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="item">Transaction Type</td>
                            <td class="item right-align">
                                <span>{{data[0].transaction_type}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <mat-divider></mat-divider>
                <table width="100%" class="price-breakdown-table" cellspacing="10px;">
                    <tr>
                        <td class="item">Submitted</td>
                        <td class="item dtae">{{data[0].created_at | date:'hh:mm a EEE MMM dd'}}</td>
                    </tr>
                </table>
                <mat-divider></mat-divider>
                <div class="row center">Thank you!</div>
            </div>
        </div>
        <div class="company-info center">
            <span>&copy;&nbsp;Labrador AI, Inc<br />
                <a href="http://www.labrador.ai" target="_blank">www.labrador.ai</a><br />
                Boston, MA</span>
            <br /><br />
            <img width="50px;" src="https://storage.googleapis.com/menus/lab-logo.png" />
            <br /><br />
        </div>
    </div>
</span>