import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormTextBox} from 'src/app/info/edit-dialog/dynamic-form/dynamic-form-control/form-textbox';
import {Store, StoreMerchant} from 'src/app/_models/store';
import {UntypedFormGroup} from '@angular/forms';
import {DynamicFormService} from 'src/app/info/edit-dialog/dynamic-form/dynamic-form.service';
import {StoreService} from 'src/app/_services/store.service';
import {FormDropDown} from 'src/app/info/edit-dialog/dynamic-form/dynamic-form-control/form-dropdown';
import {FormUtils} from 'src/app/_helpers/form-utils';
import {FormPhone} from 'src/app/info/edit-dialog/dynamic-form/dynamic-form-control/form-phone';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
  providers: [DynamicFormService]
})
export class EditDialogComponent implements OnInit {

  @Input() store: Store;
  storeMerchant:StoreMerchant;
  form: UntypedFormGroup;
  controlMap: Map<string, any>;
  fields:string[];
  editKey:string;
  isLoading: boolean = true;

  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditDialogComponent>,
    private dynamicFormService: DynamicFormService,
    private storeService:StoreService,
    public formUtils: FormUtils
    ) {
    console.log(data)
    this.storeMerchant  = data.storeMerchant;
    this.fields = data.fields;
    this.editKey = data.editKey;
  }

  ngOnInit() {
    console.log("init")
    this.controlMap = new Map([
      ['id', new FormTextBox({key:'id', label: 'id', value: this.storeMerchant.copilot_merchant.id})],
      ['dba_name', new FormTextBox({key:'dba_name', label: 'Business Name', value: this.storeMerchant.copilot_merchant.dba_name})],
      ['business_phone', new FormPhone({key:'business_phone', label: 'Business Phone', value: this.storeMerchant.copilot_merchant.business_phone})],
      ['business_address_address', new FormTextBox({
        key:'business_address_address',
        label: 'Business Address',
        value: this.storeMerchant.copilot_merchant.business_address_address})
      ],
      ['business_address_city', new FormTextBox({
        key:'business_address_city',
        label: 'City',
        value: this.storeMerchant.copilot_merchant.business_address_city})
      ],
      ['business_address_state', new FormTextBox({
        key:'business_address_state',
        label: 'State',
        value: this.storeMerchant.copilot_merchant.business_address_state})
      ],
      ['business_address_zip', new FormTextBox({
        key:'business_address_zip',
        label: 'Zip',
        value: this.storeMerchant.copilot_merchant.business_address_zip})
      ],
      ['legal_business_name', new FormTextBox({
        key:'legal_business_name',
        label: 'Entity Name',
        value: this.storeMerchant.copilot_merchant.legal_business_name})
      ],
      ['tax_filing_method', new FormDropDown({
        key:'tax_filing_method',
        label: 'Tax filing method',
        value: this.storeMerchant.copilot_merchant.tax_filing_method,
        options: [
          {key:'TIN', value:'TIN'},
          {key: 'SSN', value:'SSN'}
        ]})
      ],
      ['tax_filing_name', new FormTextBox({
        key:'tax_filing_name',
        label: 'Tax Filing Name',
        value: this.storeMerchant.copilot_merchant.tax_filing_name})
      ],
      ['website_address', new FormTextBox({
        key:'website_address',
        label: 'Website Address',
        value: this.storeMerchant.copilot_merchant.website_address})
      ],
      ['mailing_address_address', new FormTextBox({
        key:'mailing_address_address',
        label: 'Mailing Address',
        value: this.storeMerchant.copilot_merchant.mailing_address_address})
      ],
      ['mailing_address_city', new FormTextBox({
        key:'mailing_address_city',
        label: 'City',
        value: this.storeMerchant.copilot_merchant.mailing_address_city})
      ],
      ['mailing_address_state', new FormTextBox({
        key:'mailing_address_state',
        label: 'State',
        value: this.storeMerchant.copilot_merchant.mailing_address_state})
      ],
      ['mailing_address_zip', new FormTextBox({
        key:'mailing_address_zip',
        label: 'Zip',
        value: this.storeMerchant.copilot_merchant.mailing_address_zip})
      ],
      ['deposit_bank_name', new FormTextBox({
        key:'deposit_bank_name',
        label: 'Deposit Bank Name',
        value: this.storeMerchant.copilot_merchant.deposit_bank_name})
      ],
      ['deposit_bank_account_number', new FormTextBox({
        key:'deposit_bank_account_number',
        label: 'deposit bank account number',
        value: this.storeMerchant.copilot_merchant.deposit_bank_account_number})
      ],
      ['deposit_bank_account_type', new FormDropDown({
        key:'deposit_bank_account_type',
        label: 'deposit bank account type',
        value: this.storeMerchant.copilot_merchant.deposit_bank_account_type,
        options: [
          {key:'BIZ', value: 'Business Checking'},
          {key:'GL', value: 'General Ledger'},
          {key:'SAVINGS', value:'Savings'}]})
      ],
      ['deposit_bank_routing_number', new FormTextBox({
        key:'deposit_bank_routing_number',
        label: 'deposit bank routing number',
        value: this.storeMerchant.copilot_merchant.deposit_bank_routing_number})
      ],
      ['withdrawal_bank_name', new FormTextBox({
        key:'withdrawal_bank_name',
        label: 'withdrawal bank name',
        value: this.storeMerchant.copilot_merchant.withdrawal_bank_name})
      ],
      ['withdrawal_bank_account_number', new FormTextBox({
        key:'withdrawal_bank_account_number',
        label: 'withdrawal bank account number',
        value: this.storeMerchant.copilot_merchant.withdrawal_bank_account_number})
      ],
      ['withdrawal_bank_account_type', new FormDropDown({
        key:'withdrawal_bank_account_type',
        label: 'withdrawal bank account type',
        value: this.storeMerchant.copilot_merchant.withdrawal_bank_account_type,
        options: [
          {key:'BIZ', value: 'Business Checking'},
          {key:'GL', value: 'General Ledger'},
          {key:'SAVINGS', value:'Savings'}]
        })
      ],
      ['withdrawal_bank_routing_number', new FormTextBox({
        key:'withdrawal_bank_routing_number',
        label: 'withdrawal bank routing number',
        value: this.storeMerchant.copilot_merchant.withdrawal_bank_routing_number})
      ],
      ['owner_name', new FormTextBox({
        key:'owner_name',
        label: 'owner name',
        value: this.storeMerchant.copilot_merchant.owner_name})
      ],
      ['owner_type', new FormDropDown({
        key:'owner_type',
        label: 'owner Type',
        value: this.storeMerchant.copilot_merchant.owner_type,
        options: this.formUtils.getOwnerTypes()
      })
      ],
      ['owner_title', new FormDropDown({
        key:'owner_title',
        label: 'owner title',
        value: this.storeMerchant.copilot_merchant.owner_title,
        options: this.formUtils.getOwnerTitleUnique()
      })
      ],
      ['owner_email', new FormTextBox({
        key:'owner_email',
        label: 'owner email',
        value: this.storeMerchant.copilot_merchant.owner_email})
      ],
      ['owner_ssn', new FormTextBox({
        key:'owner_ssn',
        label: 'owner SSN',
        value: this.storeMerchant.copilot_merchant.owner_ssn})
      ],
      ['owner_phone', new FormPhone({
        key:'owner_phone',
        label: 'owner phone',
        value: this.storeMerchant.copilot_merchant.owner_phone})
      ],
      ['owner_mobile_phone', new FormPhone({
        key:'owner_mobile_phone',
        label: 'owner mobile phone',
        value: this.storeMerchant.copilot_merchant.owner_mobile_phone})
      ],
      ['owner_address', new FormTextBox({
        key:'owner_address',
        label: 'owner address',
        value: this.storeMerchant.copilot_merchant.owner_address})
      ],
      ['owner_city', new FormTextBox({
        key:'owner_city',
        label: 'City',
        value: this.storeMerchant.copilot_merchant.owner_city})
      ],
      ['owner_address_state', new FormTextBox({
        key:'owner_address_state',
        label: 'state',
        value: this.storeMerchant.copilot_merchant.owner_address_state})
      ],
      ['owner_zip', new FormTextBox({
        key:'owner_zip',
        label: 'Zip',
        value: this.storeMerchant.copilot_merchant.owner_zip})
      ],
      ['voided_check', new FormTextBox({
        key:'voided_check',
        label: 'Voided Check',
        value: this.storeMerchant.copilot_merchant.voided_check})
      ],
      ['merchant_id', new FormTextBox({
        key:'merchant_id',
        label: 'merchant_id',
        value: this.storeMerchant.copilot_merchant.merchant_id})
      ],
      ['contact_name', new FormTextBox({
        key:'contact_name',
        label: 'Contact Name',
        value: this.storeMerchant.store.contact_name})
      ],
      ['contact_email', new FormTextBox({
        key:'contact_email',
        label: 'Contact Email',
        value: this.storeMerchant.store.contact_email || ''})
      ],
      ['contact_phone', new FormPhone({
        key:'contact_phone',
        label: 'Contact Phone',
        value: this.storeMerchant.store.contact_phone || ''})
      ],

    ])
    this.form = this.dynamicFormService.toFormGroup(Array.from(this.controlMap.values()))
    this.isLoading = false
  }

  getPlainPhoneNumber(value){
    let ph = value.replace(/[\s()-]/g, '')
    return '+1' + ph.substring(0,10)
  }

  onSubmit(){
    let copyMapping = new Map([
      ["deposit_bank_name", "withdrawal_bank_name"],
      ["deposit_bank_account_number", "withdrawal_bank_account_number"],
      ["deposit_bank_account_type", "withdrawal_bank_account_type"],
      ["deposit_bank_routing_number", "withdrawal_bank_routing_number"],
    ])
    let payload = {'copilot_merchant': {}, 'store':this.storeMerchant.store}
    payload['id'] = this.storeMerchant.id;
    payload[this.editKey] = {}
    this.fields.forEach(f => {
      let fieldControl = this.controlMap.get(f)
      if(fieldControl['controlType'] === 'phone'){
        console.log(this.form.value[f])
        payload[this.editKey][f] = this.formUtils.getPlainPhoneNumber(this.form.value[f]);
      }else{
        payload[this.editKey][f] = this.form.value[f]
      }

      if(copyMapping.has(f)){
        payload[this.editKey][copyMapping.get(f)] = this.form.value[f]
      }
    })

    this.storeService.updateMerchant(this.storeMerchant.store, payload).subscribe(data => {
      this.dialogRef.close(data)
    })
  }

  cancel():void{
    this.dialogRef.close()
  }
}

