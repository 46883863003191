import {Component, Input, OnInit} from '@angular/core';
import {OrdersWithPayment} from 'src/app/_models/order';

@Component({
  selector: 'app-payment-options-breakdown',
  templateUrl: './payment-options-breakdown.component.html',
  styleUrls: ['./payment-options-breakdown.component.scss']
})
export class PaymentOptionsBreakdownComponent implements OnInit {

  @Input() orderWithPayment:OrdersWithPayment;
  @Input() optBox:any;

  constructor() {
   }

  ngOnInit() {
  }

}
