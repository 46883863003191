import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Modules} from '../constants';


@Injectable()
export class RefreshService {

  private source = new BehaviorSubject<Modules>(null);
  current = this.source.asObservable();

  constructor() {}

  refreshModule(component){
    console.log(component);

    this.source.next(component);
  }

}
