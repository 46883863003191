<mat-dialog-content class="mat-typography">
  <div class="container">
    <p class="cardPayment xs:!text-2xl">
      Card Payment In Progress:
    </p>

    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>

    <div class="status">
      <p *ngFor="let log of logs">{{log}}</p>
    </div>
  </div>
</mat-dialog-content>


<mat-dialog-actions align="end">

    <button mat-flat-button *ngIf="isError && !isConfirmed" (click)="sendPaymentRequest()">Retry</button>
<!--    <button mat-flat-button color="warn" *ngIf="isConfirmed" (click)="voidTransaction()">Void</button>-->

    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button mat-dialog-close [disabled]="!isConfirmed" color="primary" (click)="done()">Done</button>

</mat-dialog-actions>
