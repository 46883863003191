<div
  class="px-4 py-2 xs:px-3 xs:py-3 md:px-6 md:py-4 lg:px-8 lg:py-6 xl:px-12 xl:py-8 mx-auto w-full h-full flex flex-col">
  <form class="form w-full flex-grow flex flex-col" [formGroup]="form" (ngSubmit)="updateMenuItemGroup(form.value)">
    <div class="flex justify-between items-center pb-3">
      <h1>Edit Item</h1>
      <div class="flex gap-4">
        <button mat-stroked-button class="cancel-btn" color="primary" (click)="onNoClick($event)">Cancel</button>
        <button mat-stroked-button class="save-btn" color="primary" type="submit" [disabled]="!form.valid">Save</button>
      </div>
    </div>
    <!-- Item Name and Description Fields -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <mat-form-field class="w-full col-span-1">
        <input matInput placeholder="Item name" formControlName="name">
      </mat-form-field>
      <mat-form-field class="w-full col-span-1">
        <input matInput placeholder="Description" formControlName="description">
      </mat-form-field>
    </div>
    <p class="text-xl xs:text-lg md:text-xl">Item Portions</p>

    <!-- Scrollable Menu Items Section -->
    <div class="overflow-y-auto flex-grow" [style.max-height]="maxHeight">
      <div formArrayName="menu_items" *ngFor="let item of form.get('menu_items')['controls']; let i = index">
        <div class="grid grid-cols-1 gap-3 xs:gap-2 xs:grid-cols-2 md:grid-cols-8 border-b py-2 xs:py-1"
          [formGroupName]="i">
          <!-- Portion -->
          <mat-form-field class="w-full col-span-1" subscriptSizing="dynamic">
            <input type="text" placeholder="Portion" matInput formControlName="portion" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of portions" [value]="option">{{option}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- Base Price -->
          <mat-form-field class="w-full col-span-1" subscriptSizing="dynamic">
            <input matInput placeholder="Base price" type="number" formControlName="base_price">
          </mat-form-field>

          <!-- Reset -->
          <mat-form-field class="w-full col-span-1" subscriptSizing="dynamic">
            <mat-select formControlName="reset" placeholder="Reset">
              <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Food Cost -->
          <mat-form-field class="w-full col-span-1" subscriptSizing="dynamic">
            <input matInput placeholder="Food Cost" type="number" formControlName="food_cost">
          </mat-form-field>

          <!-- Available, Qty, Taxable, POS Only -->
          <mat-checkbox class="w-full col-span-1" formControlName="is_available">Available</mat-checkbox>
          <mat-form-field class="w-full col-span-1" subscriptSizing="dynamic">
            <input matInput placeholder="Qty" formControlName="available_qty" type="number">
          </mat-form-field>
          <mat-checkbox class="w-full col-span-1" formControlName="is_taxable">Taxable</mat-checkbox>
          <mat-checkbox class="w-full col-span-1" formControlName="is_pos_only">POS Only</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</div>