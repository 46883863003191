import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {type} from 'os';
import * as moment from 'moment';
import {Subscription, interval} from 'rxjs';

@Component({
  selector: 'app-campaign-countdown',
  templateUrl: './campaign-countdown.component.html',
  styleUrls: ['./campaign-countdown.component.scss']
})
export class CampaignCountdownComponent implements OnInit, OnDestroy, OnChanges {

  @Input() schedule: string;
  @Output() difference = new EventEmitter<number>();
  private subscription: Subscription;

  public dateNow = new Date();
  public dDay: Date; // Declare but don't initialize here
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public timeDifference;
  public secondsToDday = 0;
  public minutesToDday = 0;
  public hoursToDday = 0;
  public daysToDday = 0;


  private getTimeDifference() {
    if (this.dDay) {
      this.timeDifference = this.dDay.getTime() - new  Date().getTime();
      this.difference.emit(Math.floor(this.timeDifference / this.milliSecondsInASecond));
      this.allocateTimeUnits(this.timeDifference);
    }
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.schedule && changes.schedule.currentValue) {
      this.dDay = moment(changes.schedule.currentValue).toDate(); // Initialize dDay here
    }
  }

  ngOnInit() {
    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
