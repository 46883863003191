import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { POSService } from 'src/app/_services/pos-service';
import { FormUtils } from 'src/app/_helpers/form-utils';
import { OrderService } from 'src/app/_services/order.service';
import { Store } from 'src/app/_models/store';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-manage-drawer-cash-logs',
  templateUrl: './manage-drawer-cash-logs.component.html',
  styleUrls: ['./manage-drawer-cash-logs.component.scss']
})
export class ManageDrawerCashLogsComponent {

  displayedColumns: string[] = ['start_time', 'end_time', 'terminal', 'paid_in', 'paid_out', 'expected_amount', 'actual_amount', 'difference', 'status'];
  dataSource: any;
  public unsubscribe: Subject<null> = new Subject<null>();
  hours = this.formUtils.buildTimeOptions();
  store_id: any;
  store;
  form;
  terminalList = [];
  startDate;
  endDate;
  isLoading: boolean = false;
  now = moment().local().toDate();
  pageSize: Number = 20;
  pageNumber: Number = 1;
  formAvailable: boolean = false;
  dataCount: number;


  constructor(
    private posService: POSService,
    private formUtils: FormUtils,
    private orderService: OrderService,
    private storeService: StoreService) {

  }

  ngOnInit() {
    this.store_id = localStorage.getItem('currentStore');
    this.getTerminalList();

    this.startDate = moment().local().toDate();
    this.endDate = moment().local().toDate();
  }

  getForm() {
    return new UntypedFormGroup({
      start_date: new UntypedFormControl({
        value: moment().local().toDate(),
        disabled: false,
      }),
      end_date: new UntypedFormControl({
        value: moment().local().toDate(),
        disabled: false,
      }),
      from: new UntypedFormControl(this.hours[0]),
      to: new UntypedFormControl(this.hours[this.hours.length - 1]),
      station: new UntypedFormControl(this.terminalList[0]),
    });
  }


  getTerminalList() {
    this.orderService.getTerminalId(this.store_id).pipe(takeUntil(this.unsubscribe)).subscribe((response) => {

      if (response.length > 0) {
        this.terminalList.push({ name: 'All' });
        response.map(res => {
          this.terminalList.push({ name: res.name });
        });
        this.form = this.getForm();
        this.formAvailable = true;
        this.searchCashLog();

      } else {
        this.formAvailable = true;
        this.searchCashLog();

      }
    });
  }

  hourCompare(left, right) {
    return left.value === right.value;
  }

  searchCashLog() {
    this.posService.getCashLogDetails(
      this.store_id,
      this.form.value.station.name,
      this.form.value.start_date || this.now,
      this.form.value.end_date || this.now,
      this.form.value.from,
      this.form.value.to,
      this.pageSize,
      this.pageNumber
    ).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      this.dataSource = data.data;
      this.dataCount = data.count;
    });
  }

  yourHandler(q) {
    this.pageNumber = q.pageIndex + 1;
    this.pageSize = q.pageSize;
    this.searchCashLog();
  }

  reset() {
    this.form = this.getForm();
    this.pageSize = 10;
    this.pageNumber = 1;
    this.searchCashLog();
  }

  formatDate(date) {
    if (date) {
      return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('hh:mm A MM/DD/YYYY');
    }
  }

}
