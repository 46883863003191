import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-alerts-messages',
  templateUrl: './alerts-messages.component.html',
  styleUrls: ['./alerts-messages.component.scss']
})
export class AlertsMessagesComponent implements OnInit {
  showCancel: boolean = false;
  editSec: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<AlertsMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.for_which && this.data.for_which != 'drawer') {
      if (this.data.for_which != 'edit_order') {
        this.showCancel = true;
      } else if (this.data.for_which == 'edit_order') {
        this.editSec = true;
      }
    }
  }

  close(event){
    this.dialogRef.close(event);
  }
  save(event) {
    this.dialogRef.close(event)
  }
}
