<form [formGroup]="form" (onSubmit)="create()" class="fixActionRow">
  <h2 mat-dialog-title> {{ data.action.gather_number ? 'Edit Action' : 'Add Action'}}</h2>
  <span style="color: #696969;" class="ml-5">Set the attendant's response to a user selection</span>
  <mat-dialog-content id="addaction-dialog">
    <!-- Action name -->
    <mat-form-field appearance="fill" class="full-width" mat-focused>
      <mat-label>Action name</mat-label>
      <input matInput #label placeholder="Enter action name" formControlName="label">
    </mat-form-field>

    <!-- Greeting type -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Greeting type</mat-label>
      <mat-select formControlName="greeting_type">
        <mat-option value="OPEN">Open</mat-option>
        <mat-option value="CLOSED">Closed</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Action type -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Action type</mat-label>
      <mat-select #actionType formControlName="action_type"
        (ngModelChange)="isConnectToPhone($event); setValidation($event);">
        <mat-option value="TEXT_BACK">Text Back</mat-option>
        <mat-option value="CONNECT_TO">Connect To</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Phone number -->
    <mat-form-field appearance="fill" class="full-width" mat-focused *ngIf="isConnectTo">
      <mat-label>Phone Number</mat-label>
      <input matInput #connect_to placeholder="Enter phone number" formControlName="connect_to">
    </mat-form-field>

    <!-- Gather number -->
    <mat-form-field appearance="fill" class="full-width" mat-focused>
      <mat-label>Gather number</mat-label>
      <input type="number" matInput #gather_number formControlName="gather_number">
    </mat-form-field>

    <!-- Speak before -->
    <mat-form-field appearance="fill" class="full-width" mat-focused>
      <mat-label>Speak before</mat-label>
      <textarea rows="2" matInput #after_action_statement formControlName="greeting"></textarea>
    </mat-form-field>

    <!-- Textback message -->
    <mat-form-field appearance="fill" class="full-width" mat-focused>
      <mat-label>Textback message</mat-label>
      <textarea rows="2" matInput #text formControlName="text"></textarea>
    </mat-form-field>

    <!-- Speak after -->
    <mat-form-field appearance="fill" class="full-width" mat-focused>
      <mat-label>Speak after</mat-label>
      <textarea rows="2" matInput #greeting formControlName="after_action_statement"></textarea>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-raised-button class="apply-btn-colr" color="primary" (click)="create()"
      [disabled]=" isLoading || !form.valid">Apply</button>
  </mat-dialog-actions>
</form>