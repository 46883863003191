import {UntypedFormGroup} from '@angular/forms';

export class DataRow{
  type:string;
  data: any;
  form:UntypedFormGroup;

  constructor(type:string, data:any, form:UntypedFormGroup){
    this.type = type;
    this.data = data;
    this.form = form;
  }
}
