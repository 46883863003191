import {Component, OnInit} from '@angular/core';
import {StoreService} from '../_services/store.service';
import {Store} from '../_models/store';
import {MatDialog} from '@angular/material/dialog';
import {CampaignDialogComponent} from './campaign-dialog/campaign-dialog.component';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {

  store:Store;
  stats:any;
  isLoading = true;

  constructor(
    private storeService:StoreService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.isLoading = true
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store)
        this.storeService.getCampaignStats(this.store.id).subscribe(data => {
          this.stats = data
          this.isLoading = false;
        });
      }
    });
  }

  startCampaign(){
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(CampaignDialogComponent, {
      width: isMobile ? '100vw' : '900px',
      height: isMobile ? '100vh' : '700px',
      maxWidth: isMobile ? '100vw' : '80vw',
      panelClass: 'campaign-dialog',
      data: {'store': this.store, 'stats': this.stats, 'campaign': {}}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });

  }
}
