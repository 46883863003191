import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuResetOptions, Store } from 'src/app/_models/store';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { forkJoin } from 'rxjs';
import { DataRow } from 'src/app/_models/data-row';
import { MenuCategoryImageComponent } from '../menu-category-image/menu-category-image.component';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PrinterSettingsService } from 'src/app/_services/printer-settings.service';
import { GiftCardDefaulImageComponent } from 'src/app/info/store-settings/gift-card-defaul-image/gift-card-defaul-image.component';
import { MenuItemImageComponent } from '../menu-item-image/menu-item-image.component';
import { StoreService } from 'src/app/_services/store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { int } from '@zxing/library/esm/customTypings';

interface Option {
  id: number;
}
@Component({
  selector: 'app-menu-item-group',
  templateUrl: './menu-item-group.component.html',
  styleUrls: ['./menu-item-group.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class MenuItemGroupComponent implements OnInit {

  @Input() menuGroup;
  @Input() store: Store;

  @Output() refresh = new EventEmitter();
  @Output() menuGroupUpdate = new EventEmitter();
  @Output() menuGroupOrderUpdate = new EventEmitter();

  tempDs = new MatTableDataSource<any>();
  menuGroupsOrdered
  isLoading
  expandedElement = new Map<string, any>();
  portions = [];
  displayedColumns = ['name', 'star'];
  titleDict: { [key: string]: string } = {};

  selectedMenuItemGroup;
  selectedMenuItemGroupForm;
  selectIsLoading;

  menuItemGroupOrder;
  menuItemOrder;


  newMenuItemGroupForm: any = this.getNewMenuItemGroupForm();
  deleteItem: any;
  private destroySubject: Subject<void> = new Subject();

  resetOptions = MenuResetOptions;
  printerSettings: any;
  isTouchDevice: boolean;
  ascending: boolean = false;
  sorted: boolean = false;
  menuiconlist: any;
  filteredIconList: any;
  menuItemIdList: Option[] = [];
  filteredmenuItemId: Option[] = [];
  selectedJoinedWith: number | null = null;
  previouslySelectedValue: string | number | null = null;
  isIconHovered: boolean = false; isGroupLoading: boolean = false;

  constructor(private menuService: MenuServiceV2,
    private formBuilder: UntypedFormBuilder, private snackBar: MatSnackBar,
    private dialog: MatDialog, private printerService: PrinterSettingsService, private changeDetectorRef: ChangeDetectorRef, private storeSevice: StoreService) {
    this.isTouchDevice = this.checkIfTouchDevice();
  }

  checkIfTouchDevice(): boolean {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }

  ngOnInit() {
    console.log(this.expandedElement);
    if (this.menuGroup) {
      this.menuItemGroupOrder = [];
      this.loadMenuGroup(this.menuGroup, this.sorted ? (this.ascending ? 'asc' : 'desc') : '')
    }
    this.getPrinterHeartbeat();
    this.menuService.getMenuItemIds(this.store.id).pipe(
      takeUntil(this.destroySubject)
    ).subscribe((data: Option[]) => {
      this.menuItemIdList = data;
      this.filteredmenuItemId = [...this.menuItemIdList];
    });
    this.menuService.getMenuIconList().subscribe(data => {
      this.menuiconlist = data
      this.filteredIconList = this.menuiconlist.data;

    })
  }

  menuSorting() {
    this.ascending = !this.ascending;
    this.sorted = true;

    const groupRows = this.tempDs.data.filter((row: any) => row.type === 'menu_item_group');

    const sortedGroups = groupRows.sort((a: any, b: any) => {
      const nameA = a.data.name.toLowerCase();
      const nameB = b.data.name.toLowerCase();
      return this.ascending ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });

    const sortedIds = sortedGroups.map((row: any) => row.data.id);

    this.menuService.updateOrder(this.store.id, JSON.stringify({
      type: 'menu_item_group',
      order: sortedIds
    })).subscribe(() => {
      this.ngOnInit();
    });
  }



  ngOnDestroy(): void {
    this.destroySubject.next();
  }

  isAllSelected(controls, option) {
    var allSelected = true;
    Object.keys(controls).forEach(key => allSelected = allSelected && controls[key].controls[option].value)
    return allSelected
  }

  toggleAll(controls, option, value) {
    Object.keys(controls).forEach(key => controls[key].controls[option].setValue(value.checked))
  }

  isExpanded(parent, element) {
    let currentExpanded = this.expandedElement.get(parent)
    if (currentExpanded && currentExpanded.data.id === element.data.id) {
      return 'expanded';
    } else {
      return 'collapsed';
    }
  }

  loadMenuGroup(menuGroup, sort) {
    const rows = [];
    let obsMenuItemGroup = this.menuService.getStoreMenuGroupMenuItemGroup(menuGroup.store, menuGroup.id, sort)
    let obsMenuGroupWithMenuItems = this.menuService.getStoreMenuGroup(menuGroup.store, menuGroup.id, sort)

    forkJoin(obsMenuItemGroup, obsMenuGroupWithMenuItems).subscribe(data => {
      let menuItemGoup = data[0];
      let menuGroupWithMenuItems = data[1];

      this.menuGroup = menuItemGoup
      this.menuGroupUpdate.emit(menuItemGoup)
      let avlFormControls = []

      menuGroupWithMenuItems.menu_items.forEach(f => {
        avlFormControls.push(new UntypedFormGroup({
          id: new FormControl(f.id),
          name: new FormControl(f.name),
          is_available: new UntypedFormControl(f.is_available, Validators.required),
          is_taxable: new UntypedFormControl(f.is_taxable, Validators.required),
          is_pos_only: new UntypedFormControl(f.is_pos_only, Validators.required),
          available_qty: new UntypedFormControl(f.available_qty),
          reset: new UntypedFormControl(f.reset),
          igId: new FormControl(f.menu_item_group),
          hasImage: new UntypedFormControl(f.has_image),
        }));

        this.titleDict[f.id] = f.name
      })

      rows.push(new DataRow("menu_group", menuItemGoup, new UntypedFormGroup({
        id: new UntypedFormControl(menuItemGoup.id, Validators.required),
        name: new UntypedFormControl(menuItemGoup.name, Validators.required),
        subgroup: new UntypedFormControl(menuItemGoup.subgroup, Validators.required),
        assignto_printer: new UntypedFormControl(this.getPrintArray(menuItemGoup.printer_data)),
        menu_item_icon_id: new UntypedFormControl(menuItemGoup.menu_item_icon_id),
        availability: new UntypedFormArray(avlFormControls),
        color: new UntypedFormControl(menuItemGoup.color, Validators.required),
        text_color: new UntypedFormControl(menuItemGoup.text_color, Validators.required),
        hide_to_kds: new UntypedFormControl(menuItemGoup.hide_to_kds, Validators.required),
      })))

      menuItemGoup.menu_item_groups.map(mi => {
        this.menuItemGroupOrder.push(mi.id);
        return new DataRow('menu_item_group', mi, new UntypedFormGroup({
          id: new UntypedFormControl(mi.id, Validators.required),
          name: new UntypedFormControl(mi.name, Validators.required),
          starting_price: new UntypedFormControl(mi.starting_price, Validators.required),
          description: new UntypedFormControl(mi.description),
        }))
      }).forEach(mi => { rows.push(mi) })
      rows.push(new DataRow('add', { 'name': 'Add menu item' }, this.getNewMenuItemGroupForm()))
      this.tempDs.data = rows;
      this.changeDetectorRef.detectChanges();
    })
  }

  getPrintArray(dta) {
    let newArray = [];
    dta.forEach(item => {
      newArray.push(item.id); // Pushing modified data into newArray
    });
    return newArray;

  }

  expandRow(parent, element) {
    if (this.expandedElement.get(parent) === element) {
      this.expandedElement.set(parent, null)
    } else {
      this.menuItemOrder = [];
      this.expandedElement = this.expandedElement.set(parent, element);
      this.portions = [];

      if (element.type === 'menu_item_group') {
        this.loadStoreMenuItemGroup(element.data.id)
      } else if (element.type === 'add') {
        this.newMenuItemGroupForm = this.getNewMenuItemGroupForm()
      }
    }
  }

  loadStoreMenuItemGroup(menuItemId) {
    this.menuItemOrder = []
    this.menuService.getStoreMenuItemGroup(this.store.id, menuItemId).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.selectedMenuItemGroup = data
      this.selectedMenuItemGroupForm = new UntypedFormGroup({
        id: new UntypedFormControl(data.id, Validators.required),
        name: new UntypedFormControl(data.name, Validators.required),
        starting_price: new UntypedFormControl(data.starting_price, Validators.required),
        description: new UntypedFormControl(data.description),

        menu_items: new UntypedFormArray(data.menu_items.map(mi => {
          this.portions.push(mi.portion);
          this.menuItemOrder.push(mi.id);
          var formattedDateTime = null
          if (mi.reset_time) {
            var resetTimeDate = new Date(mi.reset_time);
            formattedDateTime = resetTimeDate.toLocaleString("en-US", {
              timeZone: localStorage.getItem('storeTimezone'),
              hour12: false,
            }).replace(',', '');
          }
          return new UntypedFormGroup({
            menu_group_id: new UntypedFormControl(data.id),
            id: new UntypedFormControl(mi.id, Validators.required),
            portion: new UntypedFormControl(mi.portion, Validators.required),
            description: new UntypedFormControl(mi.description),
            base_price: new UntypedFormControl(mi.base_price, Validators.required),
            barcode: new UntypedFormControl(mi.barcode),
            food_cost: new UntypedFormControl(mi.food_cost),
            is_available: new UntypedFormControl(mi.is_available, Validators.required),
            is_taxable: new UntypedFormControl(mi.is_taxable, Validators.required),
            is_pos_only: new UntypedFormControl(mi.is_pos_only, Validators.required),
            available_qty: new UntypedFormControl(mi.available_qty),
            reset: new UntypedFormControl(mi.reset),
            name: new UntypedFormControl(mi.name),
            menu_item_group_name: new UntypedFormControl(mi.menu_item_group_name),
            display_name: new UntypedFormControl(mi.display_name),
            joined_with: new UntypedFormControl(mi.joined_with || null),
            reset_time: new UntypedFormControl(formattedDateTime || null)
          })
        }))
      })
    })
  }

  getNewMenuItemGroupForm() {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(),
      menu_items: new UntypedFormArray([1].map(mi => {
        return new UntypedFormGroup({
          portion: new UntypedFormControl('', Validators.required),
          base_price: new UntypedFormControl('', Validators.required),
          barcode: new UntypedFormControl(null),
          reset: new UntypedFormControl(null),
          food_cost: new UntypedFormControl(''),
          is_available: new UntypedFormControl(true, Validators.required),
          is_taxable: new UntypedFormControl(true, Validators.required),
          is_pos_only: new UntypedFormControl(false, Validators.required),
          available_qty: new UntypedFormControl(null)
        })
      }))
    })
  }
  stringToSlug(text: string): string {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  generateBarcode(menuId: string, portion: string, displayName: string, type: boolean) {
    this.menuService.getGenerateBarcode(this.store.id, menuId).pipe(takeUntil(this.destroySubject)).subscribe({
      next: response => {
        const imageUrl = URL.createObjectURL(response);
        if (type) {
          // Download barcode
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.stringToSlug(portion)}-${this.stringToSlug(displayName)}.png`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.snackBar.open('Barcode Downloaded', 'OK', { duration: 2000 });
        } else {
          // Print barcode
          const printWindow = window.open('', '_blank', 'width=600,height=400');
          if (printWindow) {
            printWindow.document.write(`
              <html>
                <head>
                  <style>
                    @page {
                      margin: 0; /* Remove default margins for the printed page */
                    }
                    body {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100vh;
                      margin: 0;
                      padding: 0;
                    }
                    img {
                      width: 2.5in; /* Recommended width for UPC-A barcode */
                      height: auto; /* Maintain aspect ratio */
                      margin: 0.25in; /* Margin to ensure quiet zone around barcodes */
                    }
                  </style>
                </head>
                <body>
                  <img src="${imageUrl}" alt="UPC-A Barcode" />
                  <script>
                    window.onload = function() {
                      window.print();
                      window.onafterprint = function() {
                        window.close();
                      };
                    };
                  </script>
                </body>
              </html>
            `);
            printWindow.document.close();
          } else {
            this.snackBar.open('Please allow pop-ups for this website to print the barcode.', 'OK', { duration: 10000 });
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        this.snackBar.open(`Error: Invalid Barcode`, 'OK', { duration: 3000 });
      }
    });
  }

  updateMenuGroup(values) {
    console.log(values);
    this.menuService.updateMenuGroup(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(resposne => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.expandedElement.set(values.id, null);
      this.ngOnInit();
      this.menuGroupUpdate.emit({ "name": values.name, "subgroup": values.subgroup, "color": values.color, "text_color": values.text_color })
    })
  }

  filterOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredmenuItemId = this.menuItemIdList.filter(option =>
      option.id.toString().includes(filterValue)
    );
  }

  getSelectedOptionLabel(joinedWithValue: any): string {
    const selectedOption = this.menuItemIdList.find(option => option.id === joinedWithValue);
    return selectedOption ? selectedOption.id.toString() : 'Assign ID';
  }

  onOptionSelected(event: any): void {
    this.selectedJoinedWith = event.value;
  }

  updateMenuItem(values) {
    console.log(values);
    this.menuService.updateMenuItem(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.ngOnInit();
    })
  }

  addMenuItem(values) {
    console.log(values);
    this.menuService.addMenuItem(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.ngOnInit();
    })
  }

  addNewMenuItemGroup(values) {
    console.log(values, this.menuGroup);
    values['menu_group_id'] = this.menuGroup.id
    this.menuService.addMenuItemGroup(this.store.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      this.snackBar.open('Item has been added', 'OK', { duration: 2000 });

      this.ngOnInit();
    })
  }

  deleteMenuGroup(element) {
    this.deleteItem = element;
    const msg = "Are you sure to delete " + element.name + "?";
    this.confrimPopup('delete_menu_group', msg);
  }

  deleteMenuItem(element) {
    this.deleteItem = element;
    if (element.portion) {
      const msg = "Are you sure to delete " + element.portion + "?";
      this.confrimPopup('delete_menu_item', msg);
    } else {
      console.log(this.selectedMenuItemGroupForm.get('menu_items'));

      this.selectedMenuItemGroupForm.get('menu_items').controls.pop()
    }
  }

  deleteMenuItemFromNewForm() {
    if (this.newMenuItemGroupForm.get('menu_items').controls.length > 1) {
      this.newMenuItemGroupForm.get('menu_items').controls.pop()
    }
  }

  addPortionExMenuItemGroup(values) {
    console.log("add", values);
    this.selectedMenuItemGroupForm.get('menu_items').push(new UntypedFormGroup({
      portion: new UntypedFormControl('', Validators.required),
      base_price: new UntypedFormControl('', Validators.required),
      barcode: new UntypedFormControl(''),
      food_cost: new UntypedFormControl(''),
      is_available: new UntypedFormControl(true, Validators.required),
      is_taxable: new UntypedFormControl(true, Validators.required),
      is_pos_only: new UntypedFormControl(false, Validators.required),
      available_qty: new UntypedFormControl(null),
      reset: new UntypedFormControl(null)
    }))
  }

  addPortionNewMenuItemGroup() {
    this.newMenuItemGroupForm.get('menu_items').push(new UntypedFormGroup({
      portion: new UntypedFormControl('', Validators.required),
      base_price: new UntypedFormControl('', Validators.required),
      barcode: new UntypedFormControl(''),
      food_cost: new UntypedFormControl(''),
      is_available: new UntypedFormControl(true, Validators.required),
      is_taxable: new UntypedFormControl(true, Validators.required),
      is_pos_only: new UntypedFormControl(false, Validators.required),
      available_qty: new UntypedFormControl(null),
      reset: new UntypedFormControl(null)
    }))
  }

  updateMenuItemGroup(values) {
    console.log("update", values);
    this.menuService.updateStoreMenuItemGroup(this.store.id, this.selectedMenuItemGroup.id, JSON.stringify(values)).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.ngOnInit()
      this.loadStoreMenuItemGroup(values.id);
    })
  }

  deleteMenuItemGroup(value) {
    this.deleteItem = value;
    const msg = "Are you sure to delete " + value.name + "?";
    this.confrimPopup('delet_item_group', msg);
  }

  swap(array, ci, ni) {
    console.log(array, ci, ni);

    [array[ci], array[ni]] = [array[ni], array[ci]]
  }

  moveMenuItemGroup(direction, id) {
    const currentIndex = this.menuItemGroupOrder.indexOf(id);
    switch (direction) {
      case 'top': {
        this.menuItemGroupOrder.unshift(this.menuItemGroupOrder.splice(currentIndex, 1)[0]);
        break;
      }
      case 'up': {
        const newIndex = currentIndex - 1;
        this.swap(this.menuItemGroupOrder, currentIndex, newIndex);
        break;
      }
      case 'down': {
        const newIndex = currentIndex + 1;
        this.swap(this.menuItemGroupOrder, currentIndex, newIndex);
        break;
      }
      case 'bottom': {
        this.menuItemGroupOrder.push(this.menuItemGroupOrder.splice(currentIndex, 1)[0]);
        break;
      }
    }
    this.menuService.updateOrder(this.store.id, JSON.stringify({ 'type': 'menu_item_group', 'order': this.menuItemGroupOrder })).subscribe(data => {
      this.ngOnInit();
    })
  }

  moveMenuGroup(direction, id) {
    this.menuGroupOrderUpdate.emit({ direction, id })
  }

  moveMenuItem(direction, value) {
    console.log(this.menuItemOrder, direction, value);

    const currentIndex = this.menuItemOrder.indexOf(value.id);
    switch (direction) {
      case 'top': {
        this.menuItemOrder.unshift(this.menuItemOrder.splice(currentIndex, 1)[0]);
        break;
      }
      case 'up': {
        const newIndex = currentIndex - 1;
        this.swap(this.menuItemOrder, currentIndex, newIndex);
        break;
      }
      case 'down': {
        const newIndex = currentIndex + 1;
        this.swap(this.menuItemOrder, currentIndex, newIndex);
        break;
      }
      case 'bottom': {
        this.menuItemOrder.push(this.menuItemOrder.splice(currentIndex, 1)[0]);
        break;
      }
    }
    this.menuService.updateOrder(this.store.id, JSON.stringify({ 'type': 'menu_item', 'order': this.menuItemOrder })).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.loadStoreMenuItemGroup(value.menu_group_id);
    })
  }

  openImageDialog(id, all) {
    const isMobile = window.innerWidth <= 599;
    // if (!this.selectedMenuItemGroup && id && (this.selectedMenuItemGroup != id) ) {

    // }
    console.log('menu group item id', id)
    this.loadStoreMenuItemGroup(id);
    setTimeout(() => {
      const dialogRef = this.dialog.open(MenuCategoryImageComponent, {
        disableClose: true,
        data: {
          menuItemGroup: this.selectedMenuItemGroup
        },
        width: isMobile ? '100vw' : '800px',
        height: isMobile ? '100vh' : '',
        maxWidth: isMobile ? '100vw' : '80vw',
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.snackBar.open('Image has been uploaded', 'OK');
          this.loadMenuGroup(this.menuGroup, this.sorted ? (this.ascending ? 'asc' : 'desc') : '')
        }
      })
    }, 2000);
  }

  openImageViewDialog() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(MenuItemImageComponent, {
      disableClose: true,
      data: {
        menuItemGroup: this.selectedMenuItemGroup
      },
      width: isMobile ? '100vw' : '400px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    })
    dialogRef.afterClosed().subscribe(result => {
      this.loadStoreMenuItemGroup(this.selectedMenuItemGroup.id);
    })
  }

  duplicateMenuGroup(id) {
    this.menuService.createMenuGroupCopy(this.store.id, id).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.snackBar.open('Item copied successfully!', 'OK', { duration: 2000 });
      this.refresh.emit(true)
    });

  }

  delete() {
    this.confrimPopup('delete_item_image', 'Are you sure you want to delete?');
  }

  confrimPopup(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg,
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (name == 'delete_menu_group') {
          this.menuService.deleteMenuGroup(this.store.id, JSON.stringify(this.deleteItem)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
            this.refresh.emit(true)
          })
        } else if (name == 'delete_menu_item') {
          this.menuService.deleteMenuItem(this.store.id, JSON.stringify(this.deleteItem)).pipe(takeUntil(this.destroySubject)).subscribe(response => {
            this.loadStoreMenuItemGroup(this.deleteItem.menu_group_id)
          })
        } else if (name == 'delet_item_group') {
          this.menuService.deleteStoreMenuItemGroup(this.store.id, this.deleteItem.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.ngOnInit()
          })
        } else if (name == 'delete_item_image') {
          this.menuService.deleteImage(this.store.id, this.selectedMenuItemGroup.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.loadStoreMenuItemGroup(this.selectedMenuItemGroup.id);
          })
        } else if (name == 'image' || name == 'sub_image') {
          this.menuService.menuImageDelete(this.store.id, this.menuGroup.id, (name == 'image' ? 1 : 0)).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.loadMenuGroup(this.menuGroup, this.sorted ? (this.ascending ? 'asc' : 'desc') : '');
          })
        }
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.isGroupLoading = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    let a = event.container.data;
    let mio = [];
    a.map((mi: any) => {
      if (mi.type == "menu_item_group") {
        mio.push(mi.data.id);
      }
    });
    this.menuService.updateOrder(this.store.id, JSON.stringify({ 'type': 'menu_item_group', 'order': mio })).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      // this.loadStoreMenuItemGroup(this.menuGroup.id);
      this.isGroupLoading = false;
      this.sorted = false;
      this.ngOnInit();
    })
  }

  getPrinterHeartbeat() {
    this.printerService.getPrinterSettings(this.store.id).subscribe(data => {
      const allPrinterObje = { name: 'All', id: 'set_to_all' }
      this.printerSettings = [allPrinterObje, ...data];

    })
  }

  openCatandSubcatImageDialog(e, f) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(GiftCardDefaulImageComponent, {
      panelClass: 'dial-size',
      width: isMobile ? '100vw' : '500px',
      height: isMobile ? 'auto' : 'auto',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        vatSubcat: e,
        category: f
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadMenuGroup(this.menuGroup, this.sorted ? (this.ascending ? 'asc' : 'desc') : '');
    })
  }
  imageDelete(e) {
    console.log('eee', e);
    this.confrimPopup(e, 'Are you sure you want to delete?');
  }

  duplicateMenuItemcat(id) {
    this.menuService.createMenuCatCopy(this.store.id, id).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.snackBar.open('Item copied successfully!', 'OK', { duration: 2000 });
      this.ngOnInit()
    });

  }

  filterIconOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredIconList = this.menuiconlist.data.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getSelectedIconOptionLabel(optionId: number) {
    const selectedOption = this.menuiconlist.data.find(opt => opt.id === optionId);
    return selectedOption ? selectedOption.name : 'Select Icon';
  }

  onIconSelected(event: any) {
    // Your logic when an icon is selected
  }

  onMouseEnter(): void {
    this.isIconHovered = true; // Enable dragging
  }

  onMouseLeave(): void {
    this.isIconHovered = false; // Disabled dragging
  }
}
