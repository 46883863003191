<mat-tab-group>
  <mat-tab label="Batches">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="p-5 xs:p-2.5 xs:!overflow-x-scroll xs:m-auto">
      <div fxLayout="row flex-wrap ">
        <div fxLayout="row flex-wrap">
          <button mat-flat-button color="primary" class="apply-btn-colr xs:!w-[100%]" (click)="closeBatch()">Close Current Batch</button>
          <button mat-flat-button color="primary" class="apply-btn-colr ml-5 xs:!w-[100%] xs:ml-0 xs:mt-2" (click)="closeCustomBatch()">Set Custom
            Auto
            Batch</button>
        </div>
        <div class="ml-10 mt-2 xs:ml-0 xs:mt-4">
          <p> Auto Batch Time: {{showTime}} </p>
        </div>
      </div>


      <table class="styled-table" width="100">
        <tr>
          <th>Terminal ID</th>
          <th>Message Credit</th>
          <th>Amount Total</th>
          <th>Created At</th>
        </tr>
        <tr *ngFor="let row of batches">
          <td>{{row.terminal_id}}</td>
          <td>{{row.message_credit}}</td>
          <td>{{row.amt_total | currency: 'USD'}}</td>
          <td><span>{{ formatDateTime(row.created_at) }}</span>
           </td>
        </tr>
      </table>
    </div>
  </mat-tab>
  <mat-tab label="Logs">
    <div class="p-5 xs:p-2.5">
      <table  class="styled-table" width="100">
        <tr>
          <th>Terminal ID</th>
          <th>Action</th>
          <th>Order ID</th>
          <th>Amount</th>
          <th>Received At</th>
        </tr>
        <tr *ngFor="let l of logs">
          <td>{{l.terminal_id}}</td>
          <td>{{l.action}}</td>
          <td>{{l.order_id}}</td>
          <td>{{l.amount}}</td>
          <td>{{l.received_at}}</td>
        </tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
