import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

const mqttsession = localStorage.getItem('mqttsession');
if (!mqttsession){
  localStorage.setItem('mqttsession', Date.now().toString());
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Register the Angular Service Worker
    navigator.serviceWorker.register('/ngsw-worker.js')
      .then(registration => {
        console.log('Angular Service Worker registered with scope kkk:', registration.scope);

        // Register the custom service worker
        navigator.serviceWorker.register('/ngsw-service.js', { scope: '/' })
          .then(customRegistration => {
            console.log('Custom Service Worker registered with scope:', customRegistration.scope);
          })
          .catch(error => {
            console.error('Custom Service Worker registration failed:', error);
          });
      })
      .catch(error => {
        console.error('Angular Service Worker registration failed:', error);
      });
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
