<mat-form-field appearance="outline" class="width-20" *ngIf="showCategories || showMenuItems">
  <mat-label>Quantity</mat-label>
  <mat-select
    (selectionChange)="setQuantity($event.value)"
    [(ngModel)]="quantity">
    <mat-option>Select</mat-option>
    <mat-option *ngFor="let n of formQuantity"
                [value]="n"
                [attr.selected]="quantity == n ? true : null">
                  {{n}}
                </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="outline" class="width-30" *ngIf="showCategories || showMenuItems">
  <mat-label>Portion </mat-label>
  <mat-select
    (selectionChange)="setPortion($event.value)"
    [(ngModel)]="selectedPortion"
    [compareWith]="objectComparisonFunction"
    >
    <mat-option>Select</mat-option>
    <mat-option *ngFor="let p of portions" [value]="p">{{p.name}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="outline" class="width-30" *ngIf="showCategories || showMenuItems">
  <mat-label>Category</mat-label>
  <mat-select
    (selectionChange)="setCategorie($event.value)"
    [(ngModel)]="selectedPortion"
    [compareWith]="objectComparisonFunction"
    >
    <mat-option>Select</mat-option>
    <mat-option *ngFor="let p of categories" [value]="p">{{p.name}}</mat-option>
  </mat-select>
</mat-form-field>

<button mat-stroked-button class="width-30" *ngIf="showCategories || showMenuItems">
  Add
</button>


<!-- <app-item-auto-complete-chips
  *ngIf="categories && showCategories"
  [label]="'Cateogry'"
  [allItems]="categories"
  [selectedItems]="selectedCateogries"
  (emitSelected)="setCategories($event)"
  ></app-item-auto-complete-chips>

<app-item-auto-complete-chips
  *ngIf="menuItems && showMenuItems"
  [label]="'Menu Items'"
  [allItems]="menuItems"
  [selectedItems]="selectedMenuItems"
  (emitSelected)="setMenuItems($event)"
  ></app-item-auto-complete-chips> -->
