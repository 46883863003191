import {Component, Input, OnInit} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Store} from 'src/app/_models/store';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ContactComponent implements OnInit {

  columnsToDisplay = ['name', 'total', 'active', 'unsubscribed'];

  dataSource = [];
  expandedElement;

  @Input() store:Store;

  constructor(private storeService:StoreService,) { }

  ngOnInit() {
    if(this.store){
      this.storeService.getContactStats(this.store.id).subscribe(data => {
        data['name']  = 'All contacts';
        data['is_blocked']  = false;
        this.dataSource.push(data)
        this.dataSource.push({'name': 'Blocked',
          'total': data['blocked'],
          'is_blocked':true, 'store': this.store.id})
      })
    }
  }
}
