import {Component, OnInit} from '@angular/core';
import {FrontFacingMqttService} from 'src/app/_services/front-facing-mqtt.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-card-payment',
  templateUrl: './cancel-card-payment.component.html',
  styleUrls: ['./cancel-card-payment.component.scss'],
})
export class CancelCardPaymentComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<CancelCardPaymentComponent>,private ffmq: FrontFacingMqttService) { }

  ngOnInit() {

  }

  cancelPayment(e) {
    this.dialogRef.close(1);
    if (e==0){
      this.ffmq.publishPaymentCancel()
    }
  }

  dontCancel() {
    this.dialogRef.close(0);
  }



}
