<ng-template #noMessages>
  <div class="no-messages">
    <span class="title">No new message</span>
  </div>
</ng-template>

<mat-toolbar class="header">
  <button mat-icon-button (click)="toggleContactList()" *ngIf="width < 768">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="image-container">
    <img src="https://storage.googleapis.com/menus/static/frontend/img/avatar-person.svg" width="30px" height="30px" />
  </div>
  <p class="user" *ngIf="selectedUser">{{ selectedUserName() }}</p><br/>
  <p class="last-seen" *ngIf="selectedUser">{{ selectedUser.last_messaged  | niceDate}}</p>
  <span class="toolbar-spacer"></span>
  <button mat-icon-button
  [matMenuTriggerFor]="menu" [disabled]="!selectedUser">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="profile(selectedUser)">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item (click)="endConversation(selectedUser)">
      <mat-icon>last_page</mat-icon>
      <span>End Conversation</span>
  </button>
    <button mat-menu-item (click)="delete(selectedUser)">
      <mat-icon>delete</mat-icon>
      <span>Delete Conversation</span>
    </button>
  </mat-menu>
</mat-toolbar>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="main">
  <div
    class="messages-container"
    #scrollMe
    [scrollTop]="scrollMe.scrollHeight" infiniteScroll (scrolledUp)="onScrollUp()">
    <ul>
      <li *ngFor="let message of previousMessage"
      [style.flex-direction]="message.user_type === 'USER' ? 'row' :'row-reverse' ">
        <span [ngClass]="message.user_type === 'STORE' ? 'speech-bubble-own' : 'speech-bubble' ">
          <span class="store-user">
            {{ message.message }}
          <br/>
          <p class="store-time">{{message.created_at | date:'MMM dd EEE hh:mm a'}}</p></span>
        </span>
      </li>
      <li *ngFor="let message of currentMessage[selectedUser?.phone_number]"
      [style.flex-direction]="message.user_type === 'USER' ? 'row' :'row-reverse' ">
        <span [ngClass]="message.user_type === 'STORE' ? 'speech-bubble-own' : 'speech-bubble' ">
          <span class="store-user">
            <span class="message">{{message.message}}</span>
          </span>
          <br/>
          <p class="store-time">{{message.created_at | date:'MMM dd EEE hh:mm a'}}</p>
        </span>
      </li>
    </ul>
  </div>

  <div class="border border-gray-300 bg-gray-200 w-full flex flex-row p-3 mt-3">
    <input class="rounded-md p-1 w-full"  #messageInput maxlength="160" placeholder="Type a message" [disabled]="!selectedUser"
      (keydown.enter)="onSendMessage(messageInput.value); messageInput.value = '' " >
    <button color="primary" mat-icon-button (click)="onSendMessage(messageInput.value); messageInput.value = '' ">
      <mat-icon>send</mat-icon>
    </button>
    <div class="clear"></div>
  </div>
</div>
