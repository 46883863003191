import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface Emoji {
  name: string;
  value: string;
}
@Component({
  selector: "app-add-categories-dialog",
  templateUrl: "./add-categories-dialog.component.html",
  styleUrls: ["./add-categories-dialog.component.scss"],
})
export class AddCategoriesDialogComponent implements OnInit {
  searchControl = new UntypedFormControl('');
  subcategory = new UntypedFormControl('');
  category = new UntypedFormControl('');
  allSelected = false;
  stateCtrl = new UntypedFormControl('');
  addCategoryForm: UntypedFormGroup;
  getId: any;
  submitted: boolean;
  subcategory_list_data: any;
  storeId: string;
  showSelete: any = [];
  categoryContent: any;
  category_list_data: any;
  screen: boolean = false;
  hideCategory: boolean = false;
  hideProductWeight: boolean = false;
  emoji: any;
  filteredStates: Observable<Emoji[]>;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<AddCategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filterStates(state) : this.emoji.slice())),
    );
  }
  
  private _filterStates(value: string): Emoji[] {
    const filterValue = value.toLowerCase();

    return this.emoji.filter(state => state.name.toLowerCase().includes(filterValue));
  }
  ngOnInit() {
    this.categoryContent = this.data;
    this.storeId = localStorage.getItem("currentStore");
    this.screen = false;

    if (this.categoryContent.is_value == 0) {
      this.hideCategory = false;
      this.categoryList();
      this.addCategoryForm = this.fb.group({
        store_id: [],
        name: ["", Validators.required],
        priority: ["1"],
        hide_category: [""],
        new_screen: [""],
        category: [[]],
        font_color: ["#FFFFFF"],
        emoji: [""]
      });
    } else if (this.categoryContent.is_value == 1) {
      this.hideCategory = true;
      this.subcategoryList();
      this.addCategoryForm = this.fb.group({
        store_id: [],
        name: ["", Validators.required],
        priority: ["1"],
        hide_category: [""],
        new_screen: [""],
        // subcategory: [""],
        subcategory: [[]],
        font_color: ["#FFFFFF"],
        hide_product_weight: [""],
        searchText5:[''],
        emoji: [""], 
      });
    }
    this.emojiList();
  }
  onNoClick() {
    this.dialogRef.close();
  }



  categoryList() {
    this.digitalDisplayService
      .CategoriesOtionList(this.storeId)
      .subscribe((data) => {
        this.category_list_data = data.data;
      });
  }
  subcategoryList() {
    this.digitalDisplayService
      .SubCategoriesOtionList(this.storeId)
      .subscribe((data) => {
        this.subcategory_list_data = data.data;
      });
  }
  toggleAllSelection() {
    if (this.allSelected) {
      // Deselect all items in the search results
      this.searchControl.setValue([]);
      this.subcategory.setValue([]);
    } else {
      // Select all items in the search results
      const filteredIds = this.subcategory_list_data
        .filter(item => {
          const itemText = (item.display_name || '').toString().toLowerCase();
          const searchText = this.searchControl.value.toLowerCase();
          return itemText.includes(searchText);
        })
        .map(topping => topping.id);
      this.subcategory.setValue(filteredIds);
    }

    this.allSelected = !this.allSelected;
  }

  toggleSelection(itemId: any) {
    const selectedIds = this.subcategory.value || [];
    
    if (selectedIds.includes(itemId)) {
      // Item is currently selected, remove it
      this.subcategory.setValue(selectedIds.filter(id => id !== itemId));
    } else {
      // Item is not selected, add it
      this.subcategory.setValue([...selectedIds, itemId]);

    }
  }


  toggleAllSelection1() {
    if (this.allSelected) {
      this.searchControl.setValue([]);
      this.category.setValue([]);
    } else {
      // Select all items in the search results
      const filteredIds = this.category_list_data
        .filter(item => {
          const itemText = (item.name || '').toString().toLowerCase();
          const searchText = this.searchControl.value.toLowerCase();
          return itemText.includes(searchText);
        })
        .map(topping => topping.id);
      this.category.setValue(filteredIds);
    }
    this.allSelected = !this.allSelected;
  }

  toggleSelection1(itemId: any) {
    const selectedIds = this.category.value || [];
    
    if (selectedIds.includes(itemId)) {
      this.category.setValue(selectedIds.filter(id => id !== itemId));
    } else {
      this.category.setValue([...selectedIds, itemId]);
    }
  }

  addMasterCategorySubmit() {

    if (this.addCategoryForm.status == "VALID") {
      const keyData = {
        store_id:this.storeId,
        name: this.addCategoryForm.value.name,
        new_screen: this.screen,
        priority: this.addCategoryForm.value.priority,
        hide_category: this.hideCategory,
        // store: this.storeId,
        categorys: this.category.value,
        font_color: this.addCategoryForm.value.font_color,
        emoji:this.addCategoryForm.value.emoji
      };
      console.log('key data', keyData);
      this.digitalDisplayService
        .addMasterCategory(keyData)
        .subscribe((data: any) => {
          this.dialogRef.close(data);
        });
    }
  }
  addCategorySubmit() {
    if (this.addCategoryForm.status == "VALID") {
      const keyData = {
        store_id:this.storeId,
        name: this.addCategoryForm.value.name,
        new_screen: this.screen,
        priority: this.addCategoryForm.value.priority,
        hide_category: this.hideCategory,
        // store: this.storeId,
        // subcategorys: this.addCategoryForm.value.subcategory,
        subcategorys: this.subcategory.value,
        font_color: this.addCategoryForm.value.font_color,
        hide_product_weight: this.hideProductWeight,
        emoji:this.addCategoryForm.value.emoji
      };
      this.digitalDisplayService.addCategory(keyData).subscribe((data: any) => {
        this.dialogRef.close(data);
      });
    }
  }
  newScreenCheck(a) {
    this.screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.hideCategory = a.checked;
  }
  hideProductWeightCheck(a) {
    this.hideProductWeight = a.checked;
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emoji = data.data;

    });
  }
}
