<table class="table-auto w-full">
  <tr>
    <td class="text-base text-right">Subtotal</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.sub_total | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="+orderWithPayment?.payment.discount > 0">
    <td class="text-base text-right">Discount</td>
    <td class="item text-right text-base">-{{orderWithPayment?.payment.discount | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="+orderWithPayment?.payment.instant_discount > 0">
    <td class="text-base text-right">
      <button mat-icon-button color="primary" (click)="removeDiscount($event)" aria-label="Example icon button with a home icon" class="remove_icon">
        <mat-icon style="font-size: 20px !important;color: red;">remove_circle_outline</mat-icon>
      </button>
      <span class="relative bottom-[7px]">{{ orderWithPayment?.bot_order.instant_discount_description }}</span>
    </td>
    <td class="item text-right text-base">-{{orderWithPayment?.payment.instant_discount | currency:'USD'}}</td>
  </tr>

  <tr *ngIf="+orderWithPayment?.payment.promotional_discount > 0">
    <td class="text-base text-right">Promotional Discount</td>
    <td class="item text-right text-base">-{{orderWithPayment?.payment.promotional_discount | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="+orderWithPayment?.payment.instant_cash_discount > 0 && orderWithPayment?.payment.type =='CASH'">
    <td class="text-base text-right">Instant Cash Discount</td>
    <td class="item text-right text-base">-{{orderWithPayment?.payment.instant_cash_discount | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="!orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">Tax</td>
    <td class="item text-right text-base" *ngIf="orderWithPayment?.payment.type !='CASH'">{{orderWithPayment?.payment.tax | currency:'USD'}}</td>
    <td class="item text-right text-base" *ngIf="orderWithPayment?.payment.type =='CASH'">
      {{orderWithPayment?.payment.cash_tax | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="+orderWithPayment?.payment.service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">
      {{orderWithPayment?.payment.service_charge_title}}
    </td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.service_charge | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="+orderWithPayment?.payment.tip > 0">
    <td class="text-base text-right">Tip</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.tip | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="orderWithPayment?.bot_order.occasion == 'DELIVERY'">
    <td class="text-base text-right">Delivery</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.delivery_charge | currency:'USD'}}</td>
  </tr>
  <!-- <tr *ngIf="orderWithPayment?.payment.surcharge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">{{orderWithPayment?.store.getSurchargeLabel()}}</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.surcharge | currency:'USD'}}</td>
  </tr> -->

  <!-- SURCHARGE -->
  <tr *ngIf="orderWithPayment?.payment.surcharge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">{{orderWithPayment?.payment.only_credit_surcharge_title}}</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.surcharge | currency:'USD'}}</td>
  </tr>

  <tr *ngIf="orderWithPayment?.payment.pos_surcharge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">{{orderWithPayment?.store.getSurchargeLabel()}}</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.pos_surcharge | currency:'USD'}}</td>
  </tr>

  <!-- END -->

  <tr *ngIf="+orderWithPayment?.payment.store_credit > 0">
    <td class="text-base text-right">Store Credit</td>
    <td class="item text-right text-base">{{'-' + orderWithPayment?.payment.store_credit | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="!orderWithPayment?.bot_order.is_pos && +orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">{{orderWithPayment?.store.online_service_charge_title}}</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="orderWithPayment?.bot_order.is_pos && +orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">{{orderWithPayment?.store.pos_service_charge_title}}</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</td>
  </tr>
  <tr *ngIf="orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="text-base text-right">Total Taxes & Fees</td>
    <td class="item text-right text-base">{{orderWithPayment?.payment.consolidate_taxes_and_fees_credit | currency:'USD'}}</td>
  </tr>
  <tr>
    <td class="text-base text-right font-medium">Total</td>
    <td class="text-base text-right font-medium text-base" *ngIf="orderWithPayment?.payment.type !='CASH'">
      {{orderWithPayment?.payment.total | currency:'USD'}}</td>
    <td class="text-base text-right font-medium text-base" *ngIf="orderWithPayment?.payment.type =='CASH'">
      {{orderWithPayment?.payment.cash_due_total | currency:'USD'}}</td>
  </tr>
</table>
