import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from 'src/app/_models/store';
import { CampaignDataSource } from 'src/app/_datasources/campaign-datasource';
import { StoreService } from 'src/app/_services/store.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CampaignDialogComponent } from '../campaign-dialog/campaign-dialog.component';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-message-table',
  templateUrl: './campaign-message-table.component.html',
  styleUrls: ['./campaign-message-table.component.scss']
})
export class CampaignMessageTableComponent implements OnInit, AfterViewInit {

  @Input() stats;
  displayedColumns: string[] = ['name', 'start_time', 'end_time', 'status', 'progress', 'sent', 'delivered', 'error', 'reach', 'cost', 'action'];
  dataSource: CampaignDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  store: Store;
  search;
  startDate;
  endDate;

  constructor(
    private storeService: StoreService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new CampaignDataSource(this.storeService)
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        this.dataSource.loadCampaigns(+store.id);
      }
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadCampaigns()))
      .subscribe();
  }

  loadCampaigns() {
    this.dataSource.loadCampaigns(+this.store.id, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  searchOrders(any: any) {
    this.loadCampaigns()
  }

  reset() {
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.loadCampaigns();
  }

  edit(campaign) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(CampaignDialogComponent, {
      width: isMobile ? '100vw' : '900px',
      height: isMobile ? '100vh' : '700px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'store': this.store,
        'stats': this.stats,
        'campaign': campaign,
        'readonly': campaign.status === 'COMPLETED'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });
  }

  copy(campaign) {
    const isMobile = window.innerWidth <= 599;
    this.storeService.copyCampaign(campaign.id).subscribe(newCampaign => {
      const dialogRef = this.dialog.open(CampaignDialogComponent, {
        width: isMobile ? '100vw' : '900px',
        height: isMobile ? '100vh' : '700px',
        maxWidth: isMobile ? '100vw' : '80vw',
        panelClass: 'campaign-dialog',
        data: {
          'store': this.store,
          'stats': this.stats,
          'campaign': newCampaign,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit()
      });
    })
  }

  delete(element) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: isMobile ? '100vw' : '364px',
      minHeight: '20vh',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        message: 'Are you sure you want to delete?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.storeService.deleteCampaign(element.id).subscribe(data => {
          this.loadCampaigns()
        })
      }
    });
    // if(confirm("Are you sure you want to delete?")){
    //   this.storeService.deleteCampaign(element.id).subscribe(data => {
    //     this.loadCampaigns()
    //   })
    // }
  }

  resend(element) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: isMobile ? '100vw' : '364px',
      minHeight: '20vh',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        message: 'Are you sure you want to run this campaign now?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("trigger campaign");
      }
    });
    // if(confirm("Are you sure you want to run this campaign now?")){
    //   console.log("trigger campaign");
    // }
  }

}
