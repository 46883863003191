<form [formGroup]="form">
  <h2 mat-dialog-title>Field Mapping</h2>
  <mat-dialog-content>
    <div>
      <mat-form-field class="full-width">
        <input matInput placeholder="Name" formControlName="customListName" autofocus>
        <mat-hint>Custom list name</mat-hint>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" floatLabel="always" mat-focused class="full-width">
        <mat-label>Phone Number</mat-label>
        <mat-select #phoneNumber placeholder="Select Field" formControlName="phoneNumber">
          <mat-option *ngFor="let header of headers" [value]="header" [disabled]="name.value === header">
            {{ header }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="full-width">
        <mat-label>Name (Optional)</mat-label>
        <mat-select #name placeholder="Select Field" formControlName="name">
          <mat-option *ngFor="let header of headers" [value]="header" [disabled]="phoneNumber.value === header">
            {{ header }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" class="apply-btn-colr" (click)="handleSubmit()" [disabled]="form.invalid || importing">
      <mat-icon *ngIf="importing">
        <mat-spinner color="accent" diameter="20" ></mat-spinner>
      </mat-icon>
      Import
    </button>
  </mat-dialog-actions>
</form>
