import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {days} from '../../../../constants';
import {FormUtils} from '../../../../_helpers/form-utils';

@Component({
  selector: 'app-menu-category-availability-dialog',
  templateUrl: './menu-category-availability-dialog.component.html',
  styleUrls: ['./menu-category-availability-dialog.component.scss']
})
export class MenuCategoryAvailabilityDialogComponent implements OnInit {
  form: UntypedFormGroup;
  days:string[];
  hours;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formUtils:FormUtils,
    public dialogRef: MatDialogRef<MenuCategoryAvailabilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private menuService:MenuServiceV2
  ) {
    this.hours = formUtils.buildTimeOptions();
   }

  ngOnInit() {
    this.days = days;
    this.form = this.getFormData();
  }

  getFormData(){
    let formGroup = {}
    if(this.data.availabilitySetting && this.data.availabilitySetting?.day_availability?.length > 1){
      this.data.availabilitySetting.day_availability.map((day, i) => {
        let from12 = this.formUtils.get12HourValue(day.hours[0].from_hour, day.hours[0].from_min)
        let to12 = this.formUtils.get12HourValue(day.hours[0].to_hour, day.hours[0].to_min)

        formGroup[day.day] = this.formBuilder.group({
          day: [day.day],
          is_available: [day.is_available],
          from: [from12],
          to: [to12],
        })
      })
    }else{
      this.days.map((day, i) => {
        let from12 = this.formUtils.get12HourValue(10, 0)
        let to12 = this.formUtils.get12HourValue(22, 0)

        formGroup[i] = this.formBuilder.group({
          day: [i],
          is_available: [true],
          from: [from12],
          to: [to12],
        })
      })
    }


    if (this.data.from_where == 'from_gift') {
      return this.formBuilder.group({
        store: [this.data.store.id],
        availability_type: [this.data.availabilityType],
        day_availability: new UntypedFormGroup(formGroup)
      })
    } else {
      return this.formBuilder.group({
        store: [this.data.store.id],
        menu_category_name: [this.data.menuGroupName],
        availability_type: [this.data.availabilityType],
        day_availability: new UntypedFormGroup(formGroup)
      })
    }
    
  }

  getDaySetting(setting){
    return
  }

  hourCompare(left, right){
    return left.value === right.value;
  }

  onSubmit(){
    console.log(this.form.value);
    if (this.data.from_where == 'from_gift') {
      this.dialogRef.close(this.form.value);
    } else {
      this.menuService.updateMenuCategoryAvailability(this.form.value).subscribe(data => {
        console.log(data);
        this.dialogRef.close()
      })
    }

  }

}
