import {Pipe} from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe{
  transform(val, args) {
    if(val)
      return val.replace(/^\+1(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
  }
}
