<ng-container *ngIf="selectedMenuItemGroupForm">
  <form  style="padding: 0 24px 16px" class="form" [formGroup]="selectedMenuItemGroupForm" (ngSubmit)="updateMenuItemGroup(selectedMenuItemGroupForm.value)">
    <div class="flex flex-row gap-3 items-center  xs:!flex-wrap  items-center">
      <div class="image">
        <ng-template *ngIf="selectedMenuItemGroup.latest_image; then imageBlock else uploadBlock"></ng-template>
        <ng-template #imageBlock>
          <a (click)="delete()" style="cursor: pointer" *ngIf="selectedMenuItemGroup.latest_image[0].image">
            <mat-icon style=" float: right;margin-left: 98px; margin-top:5px;position: absolute;">delete</mat-icon>
          </a>
          <img [src]="selectedMenuItemGroup.latest_image[0].image" height="120" width="120" class="pointer"
               matTooltip="Click to change"
               (click)="openImageDialog()">
        </ng-template>
        <ng-template #uploadBlock>
          <button mat-stroked-button (click)="openImageDialog()">Upload Image</button>
        </ng-template>
      </div>
      <div class="metadata">
        <input type="hidden" formControlName="id">
        <mat-form-field class="width-100">
          <input matInput placeholder="Item name" formControlName="name">
        </mat-form-field>
        <!-- <mat-form-field class="width-25">
          <input matInput placeholder="Starting price" formControlName="starting_price">
        </mat-form-field> -->
        <mat-form-field class="width-100">
          <input matInput placeholder="Description" formControlName="description">
        </mat-form-field>
      </div>
    </div>

    <p class="text-xl">Item Portions</p>
    <p class="text-base">Enter the different portions and prices for this item. Use “Standard” if the item comes in only one size.</p>

    <div formArrayName="menu_items"
         *ngFor="let item of selectedMenuItemGroupForm.get('menu_items').controls; let i = index">
         <div class="flex flex-col border-bottom" [formGroupName]="i">
          <p>Portion ID : {{ item.get('id')?.value }}</p>
          <div class="flex flex-row xs:gap-2 items-center  flex-wrap  justify-between">
            <div class="flex flex-row gap-2">
              <mat-form-field class="w-[50%] xs:w-[42%]" subscriptSizing="dynamic">
                <input type="text" placeholder="Portion" matInput formControlName="portion"
                  [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of portions" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%]">
                <input matInput placeholder="Base price" type="number" formControlName="base_price">
              </mat-form-field>
            </div>
            <div class="flex flex-row gap-2">
              <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%]">
                <mat-select formControlName="reset" placeholder="Reset">
                  <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%]">
                <input matInput placeholder="Food Cost" type="number" formControlName="food_cost">
              </mat-form-field>
            </div>
            <div class="flex flex-row gap-2 items-center">
              <mat-checkbox class="" formControlName="is_available">Available</mat-checkbox>
              <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[30%] xs:ml-[15px]">
                <input matInput placeholder="Qty" formControlName="available_qty" type="number">
              </mat-form-field>
              <mat-checkbox class="" formControlName="is_taxable">Taxable</mat-checkbox>
            </div>
            <div class="flex flex-row gap-2 items-center xs:w-full">
              <mat-checkbox formControlName="is_pos_only" class="w-[85%] xs:w-[84%]">POS Only</mat-checkbox>
              <button class="delete" type="button" mat-icon-button [matMenuTriggerFor]="opsMenu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #opsMenu="matMenu">
                <button mat-menu-item type="button" (click)="moveMenuItem('top', item.value); false">Move to
                  top</button>
                <button mat-menu-item type="button" (click)="moveMenuItem('up', item.value); false">Move
                  up</button>
                <button mat-menu-item type="button" (click)="moveMenuItem('down', item.value); false">Move
                  down</button>
                <button mat-menu-item type="button" (click)="moveMenuItem('bottom', item.value); false">Move
                  to
                  bottom</button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="deleteMenuItem(item.value); false">Delete</button>
              </mat-menu>
            </div>
          </div>
          <div class="flex flex-row xs:gap-2 items-center  flex-wrap ">
            <div class="flex flex-row gap-2">
              <mat-form-field appearance="fill">
                <mat-label>Assign ID</mat-label>
                <mat-select formControlName="joined_with" 
                            (selectionChange)="onOptionSelected($event, item)" 
                            #select 
                            panelClass="custom-select-panel" 
                            class="w-[50%] xs:w-[42%]">
                  <mat-select-trigger>
                    {{ getSelectedOptionLabel(item.get('joined_with')?.value) }}
                  </mat-select-trigger>

                  <div class="sticky top-0 z-10 bg-white p-2">
                    <mat-form-field class="w-full p-0 cursor-default search-option">
                      <input matInput placeholder="Search..." 
                            (keyup)="filterOptions($event.target.value)" 
                            (click)="$event.stopPropagation()">
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-option *ngFor="let option of filteredmenuItemId" [value]="option.id">
                      {{ option.id }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>

              <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%] h-[67px]"
                *ngIf="store?.feature_flag['barcode_scanner_feature']">
                <input matInput placeholder="Barcode" type="text" formControlName="barcode">
              </mat-form-field>
            </div>
            <div class="flex flex-row gap-2">
              <button type="button" *ngIf="store?.feature_flag['barcode_scanner_feature']"
                class="relative left-4"
                style="border: 2px solid #0C83FF; padding: 5px; border-radius: 5px; color: #0C83FF"
                (click)="generateBarcode(item.get('id').value,item.get('portion').value,item.get('display_name').value,true)">
                Download Barcode</button>
              <button type="button" *ngIf="store?.feature_flag['barcode_scanner_feature']"
                class="relative left-8"
                style="border: 2px solid #0C83FF; padding: 5px; border-radius: 5px; color: #0C83FF"
                (click)="generateBarcode(item.get('id').value,item.get('portion').value,item.get('display_name').value,false)">
                Print Barcode</button>
                <span class="relative left-8 top-[6px]" *ngIf="item.get('reset_time')?.value">Reset Time: {{ item.get('reset_time')?.value }}</span>
            </div>
          </div>
        </div>
    </div>
    <div class="action button-center flex flex-row items-center justify-between my-5 xs:!mb-0 xs:!flex-col	xs:!flex-row-none">
      <div class="l button-center">
        <button class="pull-left button-center add-btn" mat-stroked-button color="primary" type="button" (click)="addPortionExMenuItemGroup(); false">Add Portion</button>
      </div>

      <div class="button-center flex flex-row gap-2 xs:!flex-col	xs:!flex-row-none xs:!gap-0">
        <button class="pull-right button-center cancel-btn" mat-stroked-button color="primary" type="button"
                (click)="expandedElement.set(menuGroup.id, null);ngOnInit(); false">Cancel</button>
        <button class="pull-right button-center save-btn" mat-stroked-button color="primary" type="submit">Save</button>
      </div>
    </div>
  </form>
</ng-container>
