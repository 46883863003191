import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import { PwaUpdateService } from './_services/pwa-update.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private sessionSubscription: Subscription;
  constructor(public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private updateService: PwaUpdateService,
  ){

      this.matIconRegistry.addSvgIcon('campaign', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/megaphone.svg'));
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
  }

  title = 'order-api-business';

  ngOnInit() {}
  

  ngOnDestroy() {}

}
