import {Component, OnInit, Output} from '@angular/core';
import {Store} from 'src/app/_models/store';
import {Subject} from 'rxjs';
import {StoreService} from 'src/app/_services/store.service';
import {MatDialog} from '@angular/material/dialog';
import {NewOfferDialogComponent} from '../new-offer-dialog/new-offer-dialog.component';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {

  store:Store;
  stats;

  @Output() refresh = new Subject<boolean>();

  constructor(public dialog: MatDialog, private storeService: StoreService) { }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.storeService.offerStats(this.store.id).subscribe(stats => this.stats = stats)
      }
    });
  }

  createNew(){
    const isMobile = window.innerWidth <= 768;
    const dialogRef = this.dialog.open(NewOfferDialogComponent, {
      width: isMobile ?'100vw' : '',
      height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '100vw',
      panelClass: 'offer-v2-dialog',
      data: {
        store: this.store
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refresh.next(true);
    });
  }

}
