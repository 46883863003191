import { Component, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { voiceListConstant } from '../voicelist-constant';
import { StoreService } from '../../_services/store.service';
import { AttendantSettings } from '../../_models/attendant_settings';
import { AttendantSettingsService } from '../../_services/attendant-settings.service';
import { UploadService } from '../../_services/upload.service';
import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-setting',
  templateUrl: './edit-setting.component.html',
  styleUrls: ['./edit-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditSettingComponent implements OnInit {

  form: UntypedFormGroup;
  store: number;
  isLoading = false;
  @Output() refresh: EventEmitter;

  type: string;
  title: string;
  voice: string;
  is_recording: boolean;
  message: string;
  audio_url: string;

  settings: AttendantSettings;

  selectedFiles;
  selectedFiles2;
  voiceList: any[];
  mp3Url: string;
  mp3Link: any[];
  voice1: any
  // Voice List
  // public voiceList = voiceListConstant;
  private sel;
  // public attendantButton = 'Attendant';
  // public recordingButton = 'Recording';
  public attendantButton = false
  voiceId: any;
  // public recordingButton
  playerVisible: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EditSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    public IVRSettings: AttendantSettingsService,
    private uploadService: UploadService
  ) {
    this.type = data.type,
      this.settings = data.settings
    this.voiceId = data.settings.voice_id
  }

  ngOnInit() {
    this.store = this.settings.store;
    if (this.type === 'voice') {
      this.voiceAttendant();
      this.voice1 = this.voiceId;
      console.log('22222222222222', this.voice1);

      this.mp3Link = [
        {
          // link: this.settings.mp3_link
          url: this.settings.mp3_link,
        },
      ];
    }
    else if (this.type === 'open') {
      this.title = "Greeting Message"
      this.message = this.settings.welcome_message;
      this.audio_url = this.settings.welcome_audio;
      this.is_recording = this.settings.use_welcome_audio;
    }
    else if (this.type === 'closed') {
      this.title = "Closed Message"
      this.message = this.settings.closed_hours_message;
      this.audio_url = this.settings.closed_hours_audio;
      this.is_recording = this.settings.use_closed_hours_audio;
    }
    else if (this.type === 'hold_queue') {
      this.title = "Hold Queue Message"
      this.message = this.settings.hold_queue_message;
      this.audio_url = this.settings.hold_queue_audio;
      this.is_recording = this.settings.use_hold_queue_audio;
    }
  }

  onChange($event) {
    console.log($event.value);
  }

  create() {
    this.isLoading = true;
    let files = new Set<File>();
    if (this.sel && this.sel.length > 0) {
      files.add(this.sel[0])
    }
    var payload = Object({ store: this.settings.store });
    if (this.type === 'voice') {
      payload.voice_id = this.voice1
      const getValue = this.voice1;
      const object = this.voiceList.find(obj => obj.voice_id === getValue);
      payload.voice_name = object.name;
      payload.mp3_link = object.preview_url
    }
    else if (this.type === 'open') {
      payload.use_welcome_audio = this.is_recording;
      payload.welcome_message = this.message
    }
    else if (this.type === 'closed') {
      payload.use_closed_hours_audio = this.is_recording;
      payload.closed_hours_message = this.message;
    }
    else if (this.type === 'hold_queue') {
      payload.use_hold_queue_audio = this.is_recording;
      payload.hold_queue_message = this.message;
    }

    let progress = this.uploadService.upload(`${environment.apiUrl}/backend/v2/ivr_setting/`, files, payload);
    forkJoin(Object.values(progress).map(p => p.progress)).subscribe(
      data => console.log(data),
      err => console.log(err),
    )
    if (this.type != 'voice') {
      setTimeout(() => {
        this.dialogRef.close()
      }, 12000)
    }
    if (this.type === 'voice') {
      this.actionChangeVoice(payload);
    }
  }

  voiceAttendant() {
    this.uploadService.getvoiceAttendantRecord().subscribe(data => {
      this.voiceList = data.voices;
    })

  }
  actionChangeVoice(payload) {
    let keySend = {
      voice_name: payload.voice_name,
      store: this.store,
      voice_id: payload.voice_id,
    }
    this.uploadService.actionChangeVoice(keySend).subscribe(data => {
      setTimeout(() => {
        this.dialogRef.close();
      }, 5000);
    });
  }
  playVoice($event: any) {
    const getValue = $event.value;
    const object = this.voiceList.find(obj => obj.voice_id === getValue);
    this.mp3Url = object.preview_url;
    this.mp3Link = [{ url: this.mp3Url }];
    this.playerVisible = false;
    setTimeout(() => { this.playerVisible = true; }, 0);
  }

  selectFile(event) {
    this.sel = event.target.files;
  }
}
