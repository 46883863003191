import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Store} from '../../_models/store';
import {MatDialog} from '@angular/material/dialog';
import {EditDialogComponent} from '../edit-dialog/edit-dialog.component';
import {days, occasion, pad, occasionNew} from 'src/app/constants';
import {StoreService} from 'src/app/_services/store.service';
import {StoreLeadTimeComponent} from '../store-lead-time/store-lead-time.component';
import {TablesideComponent} from '../tableside/tableside.component';
import {chunk} from '../../constants';
import {LastOrderBufferComponent} from '../last-order-buffer/last-order-buffer.component';
import {environment} from 'src/environments/environment';
import {WorkingHoursComponent} from '../edit-dialog/dynamic-form/working-hours/working-hours.component';
import {SpecialHoursComponent} from '../special-hours/special-hours.component';
import {ServiceAreaComponent} from '../service-area/service-area.component';
import {BusinessLocationComponent} from '../business-location/business-location.component';
import {DashboardSalesTaxComponent} from './dashboard-sales-tax/dashboard-sales-tax.component';
import { DashboardEmployeeTaxComponent } from './dashboard-employee-tax/dashboard-employee-tax.component';
import { HolidaysComponent } from 'src/app/holidays/holidays.component';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss']
})
export class StoreInfoComponent implements OnInit, OnChanges{

  @Input() store: Store;
  workingHours: string[] = new Array();
  days = days

  occasion = occasion
  tables;
  links;
  serviceArea;

  constructor(public dialog: MatDialog, public storeService: StoreService) { }

  ngOnInit() {
    if (this.store?.modules.includes('POS')) {
      this.occasion = occasionNew;
    } else {
      this.occasion = occasion;
    }
    for (const data in this.store.working) {
      const workingHour = this.store.working[data];
      this.workingHours[data] = this.getWorkingHourString(workingHour);
    }
    this.getTables();
    this.links = {
      menu: `${environment.orderAppUrl}/store/${this.store.url_slug}/`,
      gift: `${environment.orderAppUrl}/store/${this.store.url_slug}/offers/`,
      offer: `${environment.orderAppUrl}/store/${this.store.url_slug}/order-offers/`,
      referral: `${environment.orderAppUrl}/store/${this.store.url_slug}/referral-menu`
    }

    this.storeService.getServiceAreas(this.store.id).subscribe(data => {
      this.serviceArea = data;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.store = changes.store.currentValue;
    this.links = {
      'menu': `${environment.orderAppUrl}/store/${this.store.url_slug}/`,
      'gift': `${environment.orderAppUrl}/store/${this.store.url_slug}/offers/`,
      'offer': `${environment.orderAppUrl}/store/${this.store.url_slug}/order-offers/`,
      'referral': `${environment.orderAppUrl}/store/${this.store.url_slug}/referral-menu`
    }
  }

  getTables() {
    this.storeService.getTablesideTables(this.store.id).subscribe(data => {
      this.tables = chunk(data, 3).map(t => t.map(x => x.table_name).join(', '))
    })
  }

  getLastOrderBuffer() {
    this.storeService.getLastOrderBuffer(this.store.id).subscribe(data => {
      this.store.last_order_buffer = data
    })
  }

  getWorkingHourString(workingHour) {
    if (!workingHour) {
      return ''
    }
    if (workingHour.is_open === false) {
      return 'Closed';
    }
    const startHour = this.get12HourString(workingHour.from_hour, workingHour.from_min);
    const endHour = this.get12HourString(workingHour.to_hour, workingHour.to_min);
    return startHour + ' — ' + endHour;
  }

  getCustomLeadTimeString(occasion): any[] {
    return this.store.lead_times.filter(m => m.occasion === occasion)
  }

  get12HourString(hour: number, min: number) {
    const minString = pad(min, 2);
    if (hour === 12) {
      return `${hour}:${minString} pm`
    }
    else if (hour > 12) {
      return `${hour - 12}:${minString} pm`
    } else if (hour === 0) {
      return `${hour + 12}:${minString} am`
    } else {
      return `${hour}:${minString} am`
    }
  }

  edit(attributes) {    
    const dialogRef = this.dialog.open(EditDialogComponent, this.constructDialogConfig(attributes));
    console.log('dialogRef',dialogRef);
    
    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  openWorkingHours() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(WorkingHoursComponent, {
      width: isMobile ?'100vw' : '',
      height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '100vw',
      data: {
        store: this.store
      },
      panelClass: 'working-view'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  openSpecialHours() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(SpecialHoursComponent, {
      width: isMobile ?'100vw' : 'auto',
      height: isMobile ?'100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        store: this.store
      },
      panelClass: 'special-hour-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  openHolidays() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(HolidaysComponent, {
      width: isMobile ?'100vw' : 'auto',
      height: isMobile ?'100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'store': this.store
      },
      panelClass: 'Holidays-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }
  
  openTableside(attributes) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(TablesideComponent, {
      width: isMobile ?'100vw' : '750px',
      height: isMobile ?'100vh' : '490px',
      maxWidth: isMobile ? '100vw' : '750px',
      data: {
        store: this.store
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTables();
    });
  }

  openSalesTax() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(DashboardSalesTaxComponent, {
      width: isMobile ?'100vw' : '450px',
      height: isMobile ?'auto' : '250px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        store: this.store
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.local_tax_percent = result.local_tax_percent;
        this.store.state_tax_percent = result.state_tax_percent;
      }
    });
  }

  openLastOrderBuffer() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(LastOrderBufferComponent, {
      width: isMobile ?'100vw' : '500px',
      height: isMobile ?'100vh' : '500px',
      maxWidth: isMobile ? '100vw' : '500px',
      data: {
        store: this.store
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getLastOrderBuffer();
    });
  }

  constructDialogConfig(attributes) {
    const windowWidth = window.innerWidth;
    const config: any = {};

    config.data = [];
    config.data.attributes = attributes;
    config.data.store = this.store;

    if (attributes[0] === 'working') {
      config.width = '850px';
    } else {
      if (windowWidth < 768) {
        config.width = '100vw';
        config.height = '100vh';
        config.maxWidth = '100vw';
      } else {
        config.width = '500px';
        config.height = 'auto';
        config.maxWidth = '100vw';
      }
    }
    return config;
  }

  copyMessage(val: string) {
    console.log(val);

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  openLeadTime() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(StoreLeadTimeComponent, {
      width: isMobile? '100vw': '90vw',
      height: isMobile ? '100vh' : '90vh',
      maxWidth: isMobile? '100vw':'90vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  openServiceArea() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ServiceAreaComponent, {
      width: isMobile ?'100vw' : '',
      height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '100vw',
      data: {
        store: this.store
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });
  }

  openBusinessLocation() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(BusinessLocationComponent, {
      width: isMobile ?'100vw' : '500px',
      height: isMobile ? 'auto' : '400px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        store: this.store
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.storeService.refresh(this.store);
      this.ngOnInit()
    });
  }

  openEmployeeTax() {
    const dialogRef = this.dialog.open(DashboardEmployeeTaxComponent, {
      width: '450px',
      height: '250px',
      data: {
        'store': this.store
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }
}
