<div class="mock">
  <div class="content">
    <mat-toolbar>
      <mat-toolbar-row class="checkout-page-header">
        <button mat-icon-button>
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div class="center full-width info">
          <span class="title-position">{{ store?.store_name}}</span>
          <p class="address">{{ store?.address}}, {{store?.city}}, {{store?.state_geographic}} {{store?.zip_code}}</p>
          <span class="verified"><img src="https://storage.googleapis.com/menus/static/frontend/img/verified-check-png-8.png">Verified</span>
        </div>
        <button mat-icon-button>
          <mat-icon>share</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="row center">
      <p class="title">{{title || 'Sample Title'}}</p>
      <img [src]="image || 'https://via.placeholder.com/300x150?text=Sample+Image'" alt="Offer image" style="margin-bottom:20px; width: 100%">
      <div style="margin: 10px auto">
        <div class="meta">
          <i class="material-icons">access_time</i> &nbsp; Ends: {{endDate}}
        </div>

        <div class="meta">
          <i class="material-icons">local_offer</i> &nbsp; {{quantity}} Remaining
        </div>
        <div style="clear:both"></div>
      </div>

      <div style="text-align: justify; margin-bottom: 50px">
        <span style="font-size: 17px">{{description || 'Sample description'}}</span>
      </div>
    </div>

    <div class="row">
      <p class="disclaimer">
        {{disclaimer}}
      </p>
    </div>
    <div class="center">
      <button class="button-large-fill apply-btn-colr" mat-flat-button color="primary" type="submit">
          Claim Now
      </button>
    </div>
  </div>
</div>
