<div class="message-table-gift order-container full-y-width  xs:!overflow-x-scroll  xs:m-auto">
  <table mat-table width="100%" matSort matSortActive="order_counter" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Name">{{element.title}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Created">{{element.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Description</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Created" >{{element.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="total_sold">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Total Sold</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Total Sold" >{{element.total_sold}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="generated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Generated</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Generated">{{element.generated | currency:'USD'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Status</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Status" >{{element.is_expired ? 'EXPIRED' : ((element.limit > 0 && (element.counter == element.limit) && !element.is_expired) ? 'INACTIVE' : 'ACTIVE')}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="generated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Offer Type</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Offer Type">{{element.offer_type}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <button mat-icon-button [matMenuTriggerFor]="actionMenu">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="edit(element)">Edit</button>
            <button mat-menu-item (click)="delete(element)" disabled>Delete</button>
          </mat-menu>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
    [ngClass]="{ 'highlight-order-row': row === selectedRow}"
    (click)="onRowClicked(row)"></mat-row>
  </table>
</div>
<mat-paginator [length]="dataSource?.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>


