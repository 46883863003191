import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {environment} from 'src/environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {Store} from '../../_models/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: "app-gift-order-iframe",
  templateUrl: "./gift-order-iframe.component.html",
  styleUrls: ["./gift-order-iframe.component.scss"],
})
export class GiftOrderIframeComponent implements OnInit, OnDestroy {
  orderGiftUrl;
  orderGift: boolean;
  store: Store;
  address: string;
  namestore: string;
  zip: string;
  geograpy: string;
  city: string;
  displayedColumns: string[] = ["remaining", "transaction", "type"];
  data: any = [];
  initial: any;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    private receiptService: ReceiptService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const slug = localStorage.getItem("slug");
    this.address = localStorage.getItem("address");
    this.namestore = localStorage.getItem("company");
    this.initial = this.namestore
      .split(" ")
      .map((p) => p[0])
      .join("");
    this.zip = localStorage.getItem("zip_code");
    this.geograpy = localStorage.getItem("geo_state");
    this.city = localStorage.getItem("city");

    this.receiptService.current.pipe(takeUntil(this.destroySubject)).subscribe((botOrder) => {
      this.data = [botOrder];

      if (botOrder) {
        if (botOrder.order_hash != null) {
          if (this.data[0].transaction_type == 'Redemption') {
            this.orderGiftUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.orderAppUrl}/order/${botOrder.order_hash}?is_admin=1`
            );
            this.orderGift = false;

          } else {
            this.orderGiftUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.orderAppUrl}/store/${slug}/offer/order/${botOrder.order_hash}/status/success`
            );
            this.orderGift = false;
          }
        } else {
          this.orderGift = true;
          this.orderGiftUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${""}`
          );
        }
      } else {
        this.orderGiftUrl = null;
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }
}
