import {Component, OnInit} from '@angular/core';
import {LoyaltyPointsService} from '../_services/loyalty-points.service';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent implements OnInit {

  storeId: any;
  constructor(public loyaltyPointsService: LoyaltyPointsService) { }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.pointsCheck();
  }

  pointsCheck() {
    const sendData = {
      'store_id': this.storeId
    }
    this.loyaltyPointsService.getStorePointsCheck(sendData).subscribe((data: any) => {
    });

  }

}
