<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="gift-mobile items-start">
  <form [formGroup]="Daterange" (ngSubmit)="filterSubmit()" autocomplete="off" class="gift-order-table-filters">
    <!-- <mat-form-field appearance="fill" class="search">
      <mat-icon matSuffix>search</mat-icon>
      <mat-label>Search Orders</mat-label>
      <input formControlName="search" (keyup)="applyFilter($event)" name="search" matInput placeholder="Search orders"
        #input>
    </mat-form-field> -->

    <mat-form-field appearance="fill" class="search">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Search Orders</mat-label>
      <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input
        (input)="applyFilter($event)">
    </mat-form-field>

    <mat-form-field appearance="fill" class="start search">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startPicker" name="startDate" placeholder="Choose a date" readonly="true"
        formControlName="from_date">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="end search">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endPicker" placeholder="Choose a date" readonly="true" formControlName="to_date">
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
    </mat-form-field>

    <button mat-flat-button class="apply-btn-colr clear xs:!w-[100%] xs:mt-3 btn-all action-btn-colr create" type="submit" color="primary">SEARCH</button>
    <button mat-flat-button class="apply-btn-colr clear xs:!w-[100%] clear-gap xs:mt-3 btn-all action-btn-colr create" (click)="reset()" color="primary">CLEAR</button>
    <button mat-flat-button class="apply-btn-colr clear xs:!w-[100%] clear-gap xs:mt-3 xs:mb-3 sm:mb-3 btn-all btn-bottom action-btn-colr create" (click)="exportData()" color="primary">EXPORT</button>
    <div class="clear"></div>
  </form>

  <div class="order-container top-table">
    <div class="gift-card-order-table">
      <table style="width: 100%;" mat-table [dataSource]="giftDataSource" class="xs:mt-5 sm:mt-5">
        <ng-container matColumnDef="order_id">
          <th mat-header-cell *matHeaderCellDef> Order ID </th>
          <td mat-cell *matCellDef="let element"> {{element.order_id}} </td>
        </ng-container>
        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef> User Name </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>
        <ng-container matColumnDef="phone_no">
          <th mat-header-cell *matHeaderCellDef> Phone No. </th>
          <td mat-cell *matCellDef="let element"> {{element.phone_number | phone}} </td>
        </ng-container>
        <ng-container matColumnDef="placed">
          <th mat-header-cell *matHeaderCellDef> Placed </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'h:mm a MM/dd/yyyy'}} </td>
          <!-- <td mat-cell *matCellDef="let element"> {{element.created_at}} </td> -->

        </ng-container>
        <ng-container matColumnDef="redeem_id">
          <th mat-header-cell *matHeaderCellDef> Reedemed ID </th>
          <td mat-cell *matCellDef="let element"> {{element.redeem_id}} </td>
        </ng-container>
        
        <ng-container matColumnDef="remaining_balance">
          <th mat-header-cell *matHeaderCellDef> Remaining Balance </th>
          <td mat-cell *matCellDef="let element"> {{element.remaining_balance | currency: 'USD'}} </td>
        </ng-container>
        <ng-container matColumnDef="transaction_type">
          <th mat-header-cell *matHeaderCellDef> Type of Transaction </th>
          <td mat-cell *matCellDef="let element"> {{element.transaction_type}} </td>
        </ng-container>

        <ng-container matColumnDef="transaction_detail">
          <th mat-header-cell *matHeaderCellDef> Transaction Detail </th>
          <td mat-cell *matCellDef="let element"> {{element.transaction_detail | currency: 'USD'}} </td>
        </ng-container>

        <ng-container matColumnDef="gift_card_id">
          <th mat-header-cell *matHeaderCellDef> Gift Card ID </th>
          <td mat-cell *matCellDef="let element"> {{element.gift_card_id}} </td>
        </ng-container>

        <ng-container matColumnDef="target_store">
          <th mat-header-cell *matHeaderCellDef> Redeemed at </th>
          <td mat-cell *matCellDef="let element"> {{element.target_store}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedGiftColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedGiftColumns;" (click)="onRowClicked(row)"></tr>
      </table>
    </div>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    <!-- <mat-paginator [length]="giftDataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator> -->
  </div>
</div>
