<div class="customTipRow items-center">
  <mat-button-toggle-group
    class="w-[290px] 2xl:w-[370px] xs:!w-[96%] !rounded-l-full !rounded-r-full"
    [(ngModel)]="tipPercent" value="All">
    <mat-button-toggle
      class="w-full"
      [value]="tip.value"
      (click)="calculateTip(tip)"
      *ngFor="let tip of tips; let index = index"
    >{{tip.name}}</mat-button-toggle>
  </mat-button-toggle-group>

<!--  <button (click)="toggleCustomTip()" color="primary" mat-button type="button" class="my-2"><strong>Add custom tip</strong></button>-->

  <div class="customTip flex flex-row items-center mt-2" *ngIf="!customTipHidden">
      <mat-form-field class="pr-3" subscriptSizing="dynamic">
        <span matPrefix>&nbsp;$&nbsp;</span>
        <input type="number" #tip matInput placeholder="Enter a custom tip">
      </mat-form-field>
      <button mat-flat-button type="button" color="primary" class="tip-button" (click)="calculateCustomTip(tip.value)">Add</button>
  </div>
</div>
