export class FormBase<T> {
  value: T;
  id: string;
  key: string;
  label: string;
  required: boolean;
  disabled: boolean;
  order: number;
  controlType: string;
  spellcheck:boolean;


  constructor(options: {
    value?: T,
    id?: string,
    key?: string,
    label?: string,
    required?: boolean,
    disabled?: boolean,
    order?: number,
    controlType?: string,
    spellcheck?:boolean
  } = {}) {
    this.value = options.value;
    this.id = options.id;
    this.key = options.key || '';
    this.label = options.label || '';
    this.disabled = !!options.disabled;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.spellcheck = !!options.spellcheck
  }
}
