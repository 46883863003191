import {Injectable} from '@angular/core';
import {pad} from '../constants';

@Injectable()
export class FormUtils {

  constructor(){}

  ownerType = new Map([
    ['PRTNRGEN', 'Partnership (General)'],
    ['PRTNRLIM', 'Partnership (Limited)'],
    ['GOVT', 'Government'],
    ['PUBCORP', 'Public Corporation'],
    ['LLC', 'LLC'],
    ['PRIVCORP', 'Private Corporation'],
    ['SUBSCORP', 'Subchapter S Corporation'],
    ['TAXEXMPT', 'Tax Exempt'],
    ['NONPRFT', 'Non-Profit Org'],
    ['INDIVSOLE', 'Individual / Sole Proprietor'],
  ])

  ownerTitle = new Map([
    ['PRTNRGEN', ['PARTNER']],
    ['PRTNRLIM', ['PARTNER']],
    ['GOVT', ['OWNER', 'PARTNER', 'PRESIDENT', 'VICE_PRESIDENT', 'MEMBER_LLC', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO']],
    ['PUBCORP', ['PRESIDENT', 'VICE_PRESIDENT', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO']],
    ['LLC', ['PRESIDENT', 'VICE_PRESIDENT', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO', 'MEMBER_LLC']],
    ['PRIVCORP', ['PRESIDENT', 'VICE_PRESIDENT', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO']],
    ['SUBSCORP', ['PRESIDENT', 'VICE_PRESIDENT', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO']],
    ['TAXEXMPT', ['OWNER', 'PARTNER', 'PRESIDENT', 'VICE_PRESIDENT', 'MEMBER_LLC', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO']],
    ['NONPRFT', ['OWNER', 'PARTNER', 'PRESIDENT', 'VICE_PRESIDENT', 'MEMBER_LLC', 'SECRETARY', 'TREASURER', 'CEO', 'CFO', 'COO']],
    ['INDIVSOLE', ['OWNER']],
  ])

  getOwnerTypes(){
    return Array.from(this.ownerType.entries()).map(p => ({key: p[0], value: p[1]}))
  }

  getOwnerTitleUnique(){
    return ['CEO', 'CFO', 'COO', 'MEMBER_LLC', 'OWNER', 'PARTNER', 'PRESIDENT', 'SECRETARY', 'TREASURER',
      'VICE_PRESIDENT'].map(p => ({key: p, value:p}))
  }

  getOwnerTitle(ownerType:string){
    return this.ownerTitle.get(ownerType).map(p => ({key: p, value:p}))
  }

  getPlainPhoneNumber(value){
    let ph = value.replace(/[\s()-]/g, '')
    if(ph.substring(0, 2) !== '+1'){
      ph = '+1' + ph.substring(0,10)
    }
    return ph;
  }

  getPlainCurrencyNumber(value){
    return Number(value.replace(/[^0-9.-]+/g,''));
  }

  get12HourValue(hour, min){
    const minString = pad(min, 2);
    if(hour === 12){
      return `${hour}:${minString} pm`
    }
    else if(hour > 12){
      return `${hour - 12}:${minString} pm`
    }else if (hour === 0){
      return `${hour + 12}:${minString} am`
    }else{
      return `${hour}:${minString} am`
    }
  }

  buildTimeOptions(){
    const options = [];
    for(let hour = 0; hour < 12; hour++){
      for(let min = 0; min < 60; min += 5){
        const minString = pad(min, 2);
        const hourString = hour === 0 ? '12' : hour;
        options.push({hour, min, key:`${hourString}:${minString}`, value:`${hourString}:${minString} am`});
      }
    }

    for(let hour = 0; hour < 12; hour++){
      for(let min = 0; min < 60; min += 5){
        const minString = pad(min, 2);
        const hourString = hour === 0 ? '12' : hour;
        options.push({hour:hour + 12, min, key:`${hourString}:${minString}`, value:`${hourString}:${minString} pm`});
      }
    }
    return options;
  }
}
