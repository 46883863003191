import {Component, Inject, OnInit} from '@angular/core';
import {StoreDataService} from './store-data.service';
import {Store} from '../../_models/store';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
  providers: [StoreDataService]
})
export class EditDialogComponent implements OnInit {
  formControls: any[];
  editControlKeys: string[];
  store: Store;

  constructor(
    formDataService: StoreDataService,
    public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.editControlKeys = data.attributes;
      this.store = data.store;

      if (this.editControlKeys[0] !== 'working') {
        console.log('editControlKeys[0]',this.editControlKeys[0]);
        
        this.formControls = formDataService.getFormDataControls(this.store);
        console.log(' this.formControls', this.formControls);
        
      } else {
        this.formControls = formDataService.getWorkingHoursControls(this.store['working']);
      }
  }

  ngOnInit() {
  }

}
