import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from '../../../../_models/store';
import {FormBase} from '../dynamic-form-control/form-base';
import {days} from '../../../../constants';
import {StoreService} from '../../../../_services/store.service';
import {FormUtils} from '../../../../_helpers/form-utils';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class WorkingHoursComponent implements OnInit {
  @Input() formControls: FormBase<any>[] = [];
  @Input() editControlKeys: Array<string>;
  @Input() store: Store;
  @Input() form: UntypedFormGroup;

  workinHours;
  days:string[];
  hours;
  error

  openType = {
    'CLOSED': 'Closed',
    'OPEN': 'Open',
    '24_HOUR': '24 Hours'
  }
  isLoading = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private storeService: StoreService,
    private formUtils:FormUtils,
    public dialogRef: MatDialogRef<WorkingHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.hours = formUtils.buildTimeOptions();
    }

  ngOnInit() {
    this.days = days;
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.storeService.getWorkingHours(this.store.id).subscribe(data => {
          this.workinHours = data;
          this.form = this.getFormData(data)
          this.isLoading = false;
        })
      }
    })
  }

  getFormData(workingHours){
    let formgroup = {}
    Object.keys(workingHours).forEach((k,i) => {
      formgroup[k] = new UntypedFormGroup({
        store_id: new UntypedFormControl(this.store.id),
        open_type: new UntypedFormControl(workingHours[k]['open_type']),
        hours: this.getHourControls(workingHours[k]['hours'])
      })
    })
    return new UntypedFormGroup(formgroup)
  }

  getHourControls(hours){

    if(hours.length === 0){
      hours = [{from_hour: 10, from_min: 0, to_hour: 22, to_min: 0}]
    }
    return new UntypedFormArray(hours.map(h => {
      let from12 = this.formUtils.get12HourValue(h.from_hour, h.from_min)
      let fromOptions = {
        hour: h.from_hour,
        min: h.from_min,
        key: from12.replace(' am', '').replace(' pm', ''),
        value: from12
      }

      let to12 = this.formUtils.get12HourValue(h.to_hour, h.to_min)
      let toOptions = {
        hour: h.to_hour,
        min: h.to_min,
        key: to12.replace(' am', '').replace(' pm', ''),
        value: to12}


      return this.formBuilder.group({
        from: [h.from_time, Validators.required],
        to: [h.to_time, Validators.required],
      })
    }))
  }

  hourCompare(left, right){
    return left.value === right.value;
  }

  onToggleChangeIsOpen(index) {
    this.store['working'][index]['is_open'] = !this.store['working'][index]['is_open']
  }

  onToggleChangeIs24Hr(index) {
    this.store['working'][index]['is_24hr'] = !this.store['working'][index]['is_24hr']
  }

  delete(day, i){
    console.log(i);

    (this.form.controls[day].get('hours') as UntypedFormArray).removeAt(i)
  }

  addHours(i){
    (this.form.controls[i].get('hours') as UntypedFormArray).push(new UntypedFormGroup({
      from: new UntypedFormControl(null),
      to: new UntypedFormControl(null),
    }))
  }

  onSubmit(){
    Object.values(this.form.value).forEach(item => {
      const openType = (item as any).open_type;
      const hoursArray = (item as any).hours as any[];
      if (openType === '24_HOUR') {
        if (hoursArray && hoursArray.length > 0) {
          hoursArray[0].from = '12:00 AM';
          hoursArray[0].to = '11:59 PM';
          while (hoursArray.length > 1) {
            hoursArray.pop();
          }
        }
      }
    });
    this.storeService.saveWorkingHours(this.form.value).subscribe(data => {
      this.dialogRef.close()
    })
  }

}
