<!-- OLD TEMPLATE -->
<!-- <div class="grid grid-cols-4 gap-2 content-start mb-4" *ngFor="let item of orderWithPayment?.items; last as last"
  (click)="onSelect(item);">
  <div class="qty" (click)="$event.stopPropagation()">
    <mat-select placeholder="qty" panelClass="qty-select"
      class="border border-gray-600 p-2 rounded-md bg-gray-100 w-[60px]" [(ngModel)]="item.quantity"
      (selectionChange)="checkPOSPermissionModify(item)">
      <mat-option value="0">Remove</mat-option>
      <mat-option *ngFor="let n of formQuantity" [value]="n" [attr.selected]="item.quantity == n ? true : null">
        {{n}}
      </mat-option>
    </mat-select>
  </div>
  <div class="item col-span-2">
    <p class="text-base" mat-line> <span
        *ngIf="item.menu_item.portion != 'Standard' ">{{item.menu_item.portion}}&nbsp;</span>{{item.menu_item.display_name}}
      <span
        *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view'] && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">({{item.sale_price
        | currency: 'USD'}})</span>
    </p>
    <div class="modifiers">
      <p *ngIf="item.special_request">"{{item.special_request}}"</p>
      <div *ngFor="let mod of item.modifiers">
        <div *ngIf="mod.modifier; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <div *ngIf="mod.modifier">
            <p class="text-sm" *ngIf="orderWithPayment.store['feature_flag']['old_item_price_template_view']">
              <span *ngIf="mod.mod_type != 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{
                orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) :
                pizzaOptions.get(mod.mod_type) }}
                {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type != 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{
                orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) :
                pizzaOptions.get(mod.mod_type) }}
                {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type === 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type === 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type.toLowerCase() !='no'"> ({{mod.sale_price | currency:'USD'}})</span>
            </p>
            <p class="text-sm" *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']">
              <span *ngIf="mod.mod_type != 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{ pizzaOptions.get(mod.mod_type) }}
                {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type != 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{ pizzaOptions.get(mod.mod_type) }}
                {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type === 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
              <span *ngIf="mod.mod_type === 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
              <span
                *ngIf="mod.mod_type.toLowerCase() !='no' && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">
                ({{mod.sale_price | currency:'USD'}})</span>
              <span *ngIf="mod.mod_type.toLowerCase() !='no'" class="float-right relative left-[88px]">
                <span *ngIf="orderWithPayment.store.show_menu_price">
                  <span *ngIf="!mod.fixed_price"> {{ mod?.sale_price * mod?.quantity * item?.quantity | currency:'USD'
                    }}</span>
                  <span *ngIf="mod.fixed_price"> {{ mod?.sale_price * item?.quantity | currency:'USD' }}</span>
                </span>
                <span *ngIf="!orderWithPayment.store.show_menu_price">
                  <span *ngIf="uiSetting?.hide_not_applicable_price; else applicablePrice"> </span>
                </span>
              </span>
            </p>
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <i class="non-match">
            <p><span *ngIf="mod.mod_type != 'ADD'">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ?
                pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }}</span> {{mod.modifier_raw}}</p>
          </i>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="text-base text-right text-base">
    <span class="text-base" *ngIf="!item.sale_price">{{item?.getItemTotal() | currency:'USD' }}</span>
    <span class="text-base"
      *ngIf="+item.sale_price >= 0 && orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item.sale_price
      | currency: 'USD'}}</span>
    <span class="text-base"
      *ngIf="+item.sale_price >= 0 && !orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item.sale_price
      * item.quantity | currency: 'USD'}}</span>
  </div>
</div> -->

<!-- NEW TEMPLATE -->
<div class="grid grid-cols-4 gap-2 content-start mb-4" *ngFor="let item of orderWithPayment?.items; last as last"
  (click)="onSelect(item);">
  <div class="qty" (click)="$event.stopPropagation()">
    <mat-select placeholder="qty" panelClass="qty-select"
      class="border border-gray-600 p-2 rounded-md bg-gray-100 w-[60px]" [(ngModel)]="item.quantity"
      (selectionChange)="checkPOSPermissionModify(item)">
      <mat-option value="0">Remove</mat-option>
      <mat-option *ngFor="let n of formQuantity" [value]="n" [attr.selected]="item.quantity == n ? true : null">
        {{n}}
      </mat-option>
    </mat-select>
  </div>
  <div class="item-details col-span-3 flex flex-col justify-between">
    <div class="flex justify-between items-center">
      <p class="text-base pr-[1px]" mat-line>
        <span
          *ngIf="item.menu_item.portion != 'Standard' ">{{item.menu_item.portion}}&nbsp;</span>{{item.menu_item.display_name}}
        <span
          *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view'] && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">
          ({{item.sale_price | currency: 'USD'}})
        </span>
      </p>
      <span class="text-base text-right" *ngIf="item.sale_price >= 0">
        {{ item.sale_price * item.quantity | currency: 'USD' }}
      </span>
    </div>
    <div class="modifiers mt-2">
      
      <div *ngFor="let mod of item.modifiers">
        <div *ngIf="mod.modifier; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <div *ngIf="mod.modifier">
            <p class="text-sm" *ngIf="orderWithPayment.store['feature_flag']['old_item_price_template_view']">
              <span *ngIf="mod.mod_type != 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{
                orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) :
                pizzaOptions.get(mod.mod_type) }} {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type != 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{
                orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) :
                pizzaOptions.get(mod.mod_type) }} {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type === 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type === 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type.toLowerCase() != 'no'"> ({{mod.sale_price | currency:'USD'}})</span>
            </p>
            <p class="text-sm" *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']">
              <span *ngIf="mod.mod_type != 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{ pizzaOptions.get(mod.mod_type) }}
                {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type != 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{ pizzaOptions.get(mod.mod_type) }}
                {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type === 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span *ngIf="mod.mod_type === 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">
                {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}
              </span>
              <span
                *ngIf="mod.mod_type.toLowerCase() != 'no' && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">({{mod.sale_price
                | currency:'USD'}})</span>
              <span *ngIf="mod.mod_type.toLowerCase() != 'no'" class="float-right relative">
                <span *ngIf="orderWithPayment.store.show_menu_price">
                  <span *ngIf="!mod.fixed_price">{{ mod?.sale_price * mod?.quantity * item?.quantity | currency:'USD'
                    }}</span>
                  <span *ngIf="mod.fixed_price">{{ mod?.sale_price * item?.quantity | currency:'USD' }}</span>
                </span>
                <span *ngIf="!orderWithPayment.store.show_menu_price">
                  <span *ngIf="uiSetting?.hide_not_applicable_price; else applicablePrice"> </span>
                </span>
              </span>
            </p>
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <i class="text-gray-500">
            <p><span *ngIf="mod.mod_type != 'ADD'">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ?
                pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }}</span> {{mod.modifier_raw}}</p>
          </i>
        </ng-template>
      </div>
      <p *ngIf="item.special_request">"{{item.special_request}}"</p>
    </div>
  </div>
</div>

<div class="grid grid-cols-4 gap-3 content-start mb-4" *ngFor="let item of orderWithPayment?.misc_items; last as last"
  (click)="onMiscSelect(item)">
  <div class="qty" (click)="$event.stopPropagation()">
    {{item.quantity}}
  </div>
  <div class="item col-span-2">
    <p class="text-base" *ngIf="!item.is_gift_card">{{item.item_name}} <span
        *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view'] && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">({{item?.price
        | currency:'USD' }})</span></p>
    <p class="text-base" *ngIf="item.is_gift_card">Gift Card **{{ item.item_name.slice(-4) }}</p>
  </div>
  <div class="text-base text-right text-base">
    <span *ngIf="orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item?.price | currency:'USD'
      }} </span>
    <span *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item?.price * item.quantity
      | currency:'USD' }} </span>
  </div>
  <mat-divider *ngIf="!last" class="w-full col-span-4"></mat-divider>
</div>