import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {NmiSettlementAction, NmiSettlementPaged} from '../_models/nmi-settlement';
import * as moment from 'moment/moment';

@Injectable()
export class NMISettlementService {
  endpoint = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSettlements(storeId:string, params:any={}) {
    let hparams = new HttpParams();
    console.log(params);
    if (params.start_date) {
      hparams = hparams.set('start_date', moment(params.start_date).format('YYYY-MM-DD'));
    }

    if (params.end_date) {
      hparams = hparams.set('end_date', moment(params.end_date).format('YYYY-MM-DD'));
    }

    if(params.filter){
      hparams = hparams.set('filter', params.filter)
    }
    return this.http.get<NmiSettlementPaged>(
      this.endpoint + `/backend/v2/nmi-settlements/${storeId}/get_settlement_status/`, { params: hparams })
  }

  getNextPage(url: string){
    return this.http.get<NmiSettlementPaged>(url)
  }

  getSettlementsAggregate(storeId:string, params:any={}) {
    let hparams = new HttpParams();
    console.log(params);
    if (params.start_date) {
      hparams = hparams.set('start_date', moment(params.start_date).format('YYYY-MM-DD'));
    }

    if (params.end_date) {
      hparams = hparams.set('end_date', moment(params.end_date).format('YYYY-MM-DD'));
    }
    return this.http.get<NmiSettlementPaged>(
      this.endpoint + `/backend/v2/nmi-settlements/${storeId}/get_settlement_aggregate/`, { params: hparams })
  }
}
