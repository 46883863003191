import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OrdersWithPayment} from '../_models/order';

@Injectable()
export class CurrentOrderService {

  private source = new BehaviorSubject<OrdersWithPayment>(null);
  current = this.source.asObservable();

  constructor() {}

  changeCurrentOrder(orderWithPayment:OrdersWithPayment){
    this.source.next(orderWithPayment);
  }

}
