<div class="flex flex-col p-7">
  <p class="text-[33px] font-medium leading-4 tracking-[1.25px] text-center mt-9 text-[#0C83FF] px-7">Enter New Role Name</p>
  <div class="flex justify-center mt-10">
    <input class="role-input" placeholder="New Role" name="newrole" id="newrole" #newrole>
  </div>
  <div class="flex justify-center mt-12 mb-8">
    <button mat-button type="button" class="w-full !bg-[#0C83FF] !text-white !h-[90px] text-2xl" tabindex="-1" (click)="onSubmit(newrole.value)">
      <span class="" fxFlexAlign="center">Submit</span>
    </button>
  </div>
</div>