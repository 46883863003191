import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {OrderService} from '../_services/order.service';
import {TransationHistory} from '../_models/order-transaction-history';


export class TransactionHistoryDataSource implements DataSource<TransationHistory> {

  private historySubject = new BehaviorSubject<TransationHistory[]>([]);

  constructor(private orderService: OrderService) {}

  connect(collectionViewer: CollectionViewer): Observable<TransationHistory[]> {
      return this.historySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.historySubject.complete();
  }

  loadHistory(order_id, split_id) {
    if (split_id) {
      this.orderService.getSplitTransactionsHistory(order_id, split_id).subscribe(history => {
        this.historySubject.next(history);
      })
    } else {
      this.orderService.history(order_id).subscribe(history => {
        this.historySubject.next(history);
      });
    }
  }
}
