<form class="fixActionRow">
<mat-dialog-content id="edit-setting-dialog">
  <div *ngIf="type === 'voice'">
    <mat-form-field appearance="fill" class="full-width" mat-focused >
      <mat-label>Attendant voice</mat-label>
      <mat-select [(ngModel)]="voice1" (selectionChange)="playVoice($event)">
        <mat-option *ngFor="let voice1 of voiceList" value="{{voice1.voice_id}}">{{ voice1.name}}-{{voice1.labels.gender}}-{{voice1.labels.accent}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!playerVisible"  class="audio-box">
      <!-- <mat-advanced-audio-player [playlist]="mp3Link"></mat-advanced-audio-player> -->
      <ang-music-player  *ngIf="mp3Link" [audioList] = mp3Link></ang-music-player>

    </div>
    <div *ngIf="playerVisible"  class="audio-box">
      <ang-music-player [audioList]="mp3Link"></ang-music-player>
  </div>
  </div>
  <div *ngIf="type !== 'voice'">
    <h2 mat-dialog-title class="!p-0">{{title}}</h2>
    <p style="color: #9E9E9E" class="mb-5">Set what callers first hear when they dial your business hours.</p>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="attendantButton"
                              (change)="onChange($event)"
                              [(ngModel)]="is_recording"
                              class="but" #groupTime="matButtonToggleGroup">
      <mat-button-toggle [value]="false">Attendant</mat-button-toggle>
      <mat-button-toggle [value]="true">Recording</mat-button-toggle>
    </mat-button-toggle-group>
    <br>

    <mat-form-field appearance="fill" class="full-width" mat-focused *ngIf="!is_recording">
      <mat-label>{{title}}</mat-label>
      <textarea rows="5" matInput [(ngModel)]="message">{{ message }}</textarea>
    </mat-form-field>

    <label style="color: #909090; display: flex; align-items: center; margin-top:1.5rem;cursor:pointer"
            *ngIf="is_recording">
      <mat-icon style="margin-right: 1rem;cursor:pointer"> publish</mat-icon>
      <span>Upload File</span>
      <input (change)="selectFile($event)" accept="audio/wav,audio/mp3,audio/mpeg3,audio/mpeg" #fileInput type="file" id="file">
    </label>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
  <button mat-raised-button class="apply-btn-colr" color="primary"
          (click)="create()" >Save</button>
</mat-dialog-actions>
</form>