
export class Options {
  label: string;
  value: number;

  constructor(label: string, value: number) {
    this.label = label;
    this.value = value;
  }
}

export class BooleanOptions {
  label: string;
  value: boolean;

  constructor(label: string, value: boolean) {
    this.label = label;
    this.value = value;
  }
}
