<form [formGroup]="form" class="fixActionRow">
<h1 mat-dialog-title>Last order Buffer</h1>
<div mat-dialog-content>
    <div *ngFor="let o of occasion|keyvalue">
      <mat-form-field style="width: 70%" *ngIf="store.occasion_flags[o.value.toUpperCase()]" >
        <input
          type="number"
          [formControlName]="o.value.toUpperCase()"
          class="form-control"
          matInput
          placeholder="{{o.value|titlecase}} Buffer"
          max="1440"/>
      </mat-form-field> Minutes
    </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
  <button mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr" (click)="submit(form)" [disabled]="!form.valid">Apply</button>
</div>
</form>
