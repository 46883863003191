<table mat-table [dataSource]="dataSource" width="100%" class="screenTable xs:!table-fixed">
    <div *ngIf="storeType == 'Restaurant';">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>  ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
    </div>

    <div *ngIf="storeType == 'Leaflogix';">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> TV ID </th>
            <td mat-cell *matCellDef="let element"> {{element.tv_id}} </td>
        </ng-container>
    </div>

    <ng-container matColumnDef="screen_duration">
        <th mat-header-cell *matHeaderCellDef>Screen Duration</th>
        <td mat-cell *matCellDef="let element"> {{element.screen_duration}} </td>
    </ng-container>

    <div *ngIf="storeType == 'Restaurant';">
        <ng-container matColumnDef="column_pre_screen">
            <th mat-header-cell *matHeaderCellDef> Column Per Screen </th>
            <td mat-cell *matCellDef="let element"> {{element.columns_per_screen}} </td>
        </ng-container>
    </div>
    <div *ngIf="storeType == 'Leaflogix';">
        <ng-container matColumnDef="column_pre_screen">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Column Per Screen </th>
            <td mat-cell *matCellDef="let element" style="display: none;"> {{element.columns_per_screen}} </td>
        </ng-container>
    </div>
    <div *ngIf="storeType == 'Restaurant';">
        <ng-container matColumnDef="screen_view_type">
            <th mat-header-cell *matHeaderCellDef> Screen View Type</th>
            <td mat-cell *matCellDef="let element"> {{element.screen_view_type}} </td>
        </ng-container>
    </div>
    <div *ngIf="storeType == 'Leaflogix';">
        <ng-container matColumnDef="screen_view_type">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Screen View Type</th>
            <td mat-cell *matCellDef="let element" style="display: none;"> {{element.screen_view_type}} </td>
        </ng-container>
    </div>

    <ng-container matColumnDef="hide_key_display">
        <th mat-header-cell *matHeaderCellDef> Hide Key Display </th>
        <td mat-cell *matCellDef="let element"> {{element.hide_key_display}} </td>
    </ng-container>

    <ng-container matColumnDef="screen_url">
        <th mat-header-cell *matHeaderCellDef> Screen URL </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="storeType == 'Leaflogix';else all_store">
                <a
                    href="{{leaflogixendpoint}}/pos-display/tv/{{storeId}}/{{element.id}}/">{{leaflogixendpoint}}/pos-display/tv/{{storeId}}/{{element.id}}/</a>
            </span>
            <!-- pos-display/tv/645/31/ -->
            <ng-template #all_store>
                <a
                    href="{{endpoint}}/backend/tv/{{storeId}}/{{element.id}}/">{{endpoint}}/backend/tv/{{storeId}}/{{element.id}}/</a>
            </ng-template>

        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menuScreen="matMenu">
                <button mat-menu-item (click)="editColumn(element)">
                    <span class="material-icons-outlined edit-delete-icon">
                        edit
                    </span>&nbsp;
                    <span>Edit</span>
                </button>

                <button mat-menu-item (click)="deleteColumn(element)">
                    <span class="material-icons edit-delete-icon">
                        delete_outline
                    </span>&nbsp;
                    <span>Delete</span>
                </button>
                <button mat-menu-item (click)="previewScreen(element)">
                    <span class="material-icons-outlined edit-delete-icon">
                        preview
                    </span>&nbsp;
                    <span>Preview</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div mat-button class="add-screen" (click)="addColumn()">Add TV Screen</div>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]">
</mat-paginator>