<div class="content">
  <div class="controls">
    <button mat-flat-button color="primary" class="action-btn-colr" (click)="uploadFile()">Upload Custom List</button>
  </div>

  <div class="table-container">
    <table mat-table
           [dataSource]="dataSource" multiTemplateDataRows
           class="mat-elevation-z8">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element" style="float: right">
          <button mat-icon-button aria-label="expand row"
                  (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <app-campaign-custom-list-table [store]="store" [customList]="element"></app-campaign-custom-list-table>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

  </div>
</div>
