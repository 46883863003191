import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { ShowScreenService } from 'src/app/_services/show-screen.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tv-screen-preview',
  templateUrl: './tv-screen-preview.component.html',
  styleUrls: ['./tv-screen-preview.component.scss']
})
export class TvScreenPreviewComponent {
  safeUrl: SafeResourceUrl;

  storeId: any;
  screenData: any;
  endpoint: any;
  showUrl: any = [];
  orderUrl;
  orderUrls;
  public elementSrc = [];
  ertertr: any;
  src;
  aaa: any = false;
  @ViewChild("iframe11", { static: false }) iframe11: ElementRef;
  abc: any;
  storeType: any;

  iframeWidth: string = '1920px';
  iframeHeight: string = '1000px';
  iframeScale: string = '0.75';
  iframePosition: string = 'absolute';
  // iframeTop: string = '78%';
  iframeTop: string = '71%';
  iframeTransform: string = 'translate(-100%, -101%)';
  iframeLeft: string = '76%';
  cusClass: string = 'tvfullhd-hr';


  resolutions = [

    { label: 'TCL: 40-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'TCL: 40-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'TCL: 40-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'TCL: 43-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'TCL: 43-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'TCL: 43-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'Hisense: 40-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Hisense: 40-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Hisense: 40-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Hisense: 43-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Hisense: 43-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Hisense: 43-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'Insignia: 40-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Insignia: 40-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Insignia: 40-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Insignia: 43-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Insignia: 43-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Insignia: 43-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Philips: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Philips: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Philips: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'Philips: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Philips: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Philips: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'LG: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'LG: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'LG: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'LG: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'LG: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'LG: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'SONY: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'SONY: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'SONY: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },


    { label: 'SONY: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'SONY: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'SONY: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    { label: 'Samsung: 55-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Samsung: 55-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Samsung: 55-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },



    { label: 'Samsung: 50-inch TV: Full HD 1080p(1920 px by 1080 px):', width: '1920px', height: '1000px', scale: '0.75', position: 'absolute', top: '71%', left: '76%', transform: 'translate(-100%, -101%)', cus_class: "tvfullhd-hr", vir_class: "tvfullhd-vir" },
    { label: 'Samsung: 50-inch TV: 4K UHD 2160p(3840 px by 2160 px):', width: '3840px', height: '2004px', scale: '0.37', position: 'absolute', top: '16.5%', left: '23%', transform: 'translate(-100%, -101%)', cus_class: "tv4kuhd-hr", vir_class: "tv4kuhd-vir" },
    { label: 'Samsung: 50-inch TV: 8K UHD 4320p (7680 px by 4320 px):', width: '7680px', height: '4320px', scale: '0.18', position: 'absolute', top: '-88%', left: '-75%', transform: 'translate(-111%, -118%)', cus_class: "tv8kuhd-hr", vir_class: "tv8kuhd-vir" },

    // { label: '40-inch TV: 1143p:(2397 x 1143 pixels)', width: '2397px', height: '1143px', scale: '0.7', position: 'absolute', top: '78%', left: '76%', transform: 'translate(-100%, -101%)' },




    // { label: '40-inch TV: 686p:(1438 x 686 pixels)', width: '1438px', height: '686px', position: 'absolute', top: '100%', left: '100%', transform: 'translate(-111%, -118%)' },


  ];


  selectedResolution: any;
  selectedSize: string;
  selectedNativeResolution: any;
  id: any;
  type: any;
  leaflogixendpoint: string;
  iframeStyles: any;
  orientationSet: any = 'Horizontal';
  vir_class: string;
  constructor(
    private digitalDisplayService: DigitalDisplayService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private showScreenService: ShowScreenService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.leaflogixendpoint = environment.leaflogixApiEndpoint;

    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.id = params['id'];
      this.type = params['type'];
    });
    let urlCreate;
    if (this.type == '1') {
      urlCreate = `${this.leaflogixendpoint}/pos-display/tv/${this.storeId}/${this.id}/`;
    }
    else {
      urlCreate = `${this.endpoint}/backend/tv/${this.storeId}/${this.id}/`;
    }

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlCreate);

    this.selectedResolution = this.resolutions[4]; // Default to Custom 6
    this.updateIframe();


  }


  refreshScreen() {
    let urlCreate;
    if (this.type == '1') {
      urlCreate = `${this.leaflogixendpoint}/pos-display/tv/${this.storeId}/${this.id}/`;
    }
    else {
      urlCreate = `${this.endpoint}/backend/tv/${this.storeId}/${this.id}/`;
    }

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlCreate);
  }

  updateIframe() {
    if (this.selectedResolution) {
      const resolution = this.resolutions.find(res => res.label === this.selectedResolution);
      if (resolution) {
        this.iframeWidth = resolution.width;
        this.iframeHeight = resolution.height;
        this.iframeScale = resolution.scale;
        this.iframePosition = resolution.position;
        this.iframeTop = resolution.top;
        this.iframeLeft = resolution.left;
        this.iframeTransform = resolution.transform;
        this.cusClass = resolution.cus_class
        this.vir_class = resolution.vir_class

      }
    }
  }


  updateOrientation(event: any) {
    this.orientationSet = event.value;

    console.log("add css", event);

  }

}
