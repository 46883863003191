import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from '../../_services/dashboard.service';
import {StoreService} from '../../_services/store.service';
import {Store} from '../../_models/store';
import {AuthenticationService} from '../../_services/authentication.service';
import {ChangePasswordComponent} from '../../account/change-password/change-password.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';

@Component({
  selector: "app-insight",
  templateUrl: "./insight.component.html",
  styleUrls: ["./insight.component.scss"],
})
export class InsightComponent implements OnInit {
  @ViewChild('chartContainer') chartContainer!: ElementRef | undefined;
  isFullScreen: boolean = false;
  customGroup: any;
  eventValue: any;
  exportTime: any;
  chartElement: any;
  renderer: any;
  constructor(
    private dashboardService: DashboardService,
    private storeService: StoreService,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private _formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) { }

  store: Store;

  // Order Volume Sales Graph Variable
  orderVolumeTimePc = "monthly";
  orderVolumeOptions = ["hourly", "daily", "weekly", "monthly", "quarterly", "annual"];
  pcOrderVolumeData;

  orderVolumeTimeTxt = "monthly";
  orderVolumeTxtOptions = ["daily", "weekly", "monthly", "quarterly", "annual"];
  textOrderVolumeData;

  // Customer Traffic Graph Variables
  customerTrafficPcs = "monthly";
  customerTrafficOptions = ["hourly", "daily", "weekly", "monthly", "quarterly", "annual"];
  pcCustomerTrafficData;
  posOrOnlinePcs = 'pos'
  posOrOnline = ["pos", "online"];

  // Average Ticket Sales Graph
  averageCheckSizePcs = "monthly"
  averageCheckSizeOptions = ["hourly", "daily", "weekly", "monthly", "quarterly", "annual"];
  pcaverageCheckSizeData;

  // Labor Cost Graph Variables
  laborCostPcs = "monthly";
  laborCostOptions = ["hourly", "daily", "weekly", "monthly", "quarterly", "annual"];
  laborCostData;

  totalUserTimeTxt = 'monthly';
  totalUserOptions = ["daily", "weekly", "monthly", "quarterly", "annual"];
  textTotalUserData;

  Daterange: UntypedFormGroup;
  startDate: any;
  endDate: any;
  maxDateCheck: string;
  startTime: any;
  endTime: any;
  strHour: any;
  strHour2: any;
  showGraph: any = false
  showGraphName: any = ''
  chartContainerFull: any = '450'
  onChangeHour(event) {
    const currentTime = new Date();
    const hours = event.hour;
    let minutes = event.minute;
    let seconds = currentTime.getSeconds();
    this.strHour = hours + ':' + minutes;
  }

  onChangeHour2(event) {
    const currentTime = new Date();
    const hours = event.hour;
    let minutes = event.minute;
    let seconds = currentTime.getSeconds();
    this.strHour2 = hours + ':' + minutes;
  }

  orderVolumeChartOptions = {
    chartArea: { width: "90%" },
    legend: { position: "none" },
    width: 'auto',
    vaxis: {
      0: {
        gridlines: { color: "transparent" },
      },
      1: {
        gridlines: { color: "transparent" },
        format: "currency",
      },
    },
    seriesType: "bars",
    series: {
      1: { color: "#028a0f" },
      2: {
        type: "line",
        targetAxisIndex: 1,
        lineWidth: 5,
        pointSize: 10,
      },
    },
  };

  /* Customer Traffic Graph */
  customerTrafficChartOptions = {
    chartArea: { width: "90%" },
    isStacked: true,
    legend: { position: "none" },
    width: 'auto',
    vaxis: {
      0: {
        gridlines: { color: "transparent" },
      },
      1: {
        gridlines: { color: "transparent" },
        format: "currency",
      },
    },
    seriesType: "bars",
    series: {
      1: { color: "#028a0f" },
    },
  };

  // Average Ticket Sales Graph
  averageTicketSalesChart = {
    chartArea: { width: "90%" },
    legend: { position: "none" },
    width: 'auto',
    vaxis: {
      0: {
        gridlines: { color: "transparent" },
      },
      1: {
        gridlines: { color: "transparent" },
        format: "currency",
      },
    },
    series: {
      0: {
        type: "line",
        targetAxisIndex: 1,
        lineWidth: 5,
        pointSize: 10,
      },
      1: {
        type: "line",
        targetAxisIndex: 1,
        lineWidth: 5,
        pointSize: 10,
        color: "#028a0f"
      },
    },
  };

  // Labor Cost
  laborCostChartOptions = {
    chartArea: { width: "90%" },
    legend: { position: "none" },
    width: 'auto',
    vaxis: {
      0: {
        gridlines: { color: "transparent" },
      },
      1: {
        gridlines: { color: "transparent" },
        format: "currency",
      },
    },
    seriesType: "bars",
    series: {
      1: { color: "#028a0f" },
    },
  };

  ngOnInit() {
    this.Daterange = this._formBuilder.group({
      from_date: '',
      to_date: '',
      start_time: '',
      end_time: '',
    });
    this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = store;
        let obs = [];
        /* Text Order */
        if (store.enable_text_ordering) {
          this.dashboardService
            .getOrderVolume(this.store.id, this.orderVolumeTimeTxt, "text", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
            .subscribe((data) => {
              this.textOrderVolumeData = data;
            });

          this.dashboardService.getTotalUsers(this.store.id, this.totalUserTimeTxt, 'text', this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time)
            .subscribe(data => {
              this.textTotalUserData = data
            });
        }
        /* Pick and Click Order */
        if (store.enable_pick_and_click_ordering) {
          this.dashboardService
            .getOrderVolume(this.store.id, this.orderVolumeTimePc, "pc", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
            .subscribe((data) => {
              this.pcOrderVolumeData = data;
            });

          this.dashboardService
            .getCustomerTraffic(this.store.id, this.customerTrafficPcs, "pc", this.posOrOnlinePcs, this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
            .subscribe((data) => {
              this.pcCustomerTrafficData = data;
            });

          this.dashboardService
            .getAverageTicketSales(this.store.id, this.averageCheckSizePcs, "pc", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
            .subscribe((data) => {
              this.pcaverageCheckSizeData = data;
            });

          this.dashboardService
            .getLaborCostPercent(this.store.id, this.laborCostPcs, "pc", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
            .subscribe((data) => {
              this.laborCostData = data;
            });
        }
      }
    });
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser["lastLogin"] == null) {
      let dialogRef = this.dialog.open(ChangePasswordComponent, {
        data: {},
        width: "500px",
      } as any);
    }
  }

  getOrderVolume(event: MatRadioChange, orderType: string) {
    const startDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd') : "";
    const endDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd') : "";
    const startTime = this.strHour ? this.strHour : "0:00";
    const endTime = this.strHour2 ? this.strHour2 : "0:00";
    let a;
    if (startDate) {
      a = 'custom';
    } else {
      a = event.value;
    }

    this.dashboardService
      .getOrderVolume(this.store.id, a, orderType, startDate, endDate, startTime, endTime, event.value)
      .subscribe((data) => {
        if (orderType == "text") {
          this.textOrderVolumeData = data;
        } else {
          this.pcOrderVolumeData = data;
        }
        this.showGrapFull()
      });
  }

  getCustomerTraffic(event: MatRadioChange, orderType: string) {
    const startDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd') : "";
    const endDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd') : "";
    const startTime = this.strHour ? this.strHour : "0:00";
    const endTime = this.strHour2 ? this.strHour2 : "0:00";
    let a;
    if (startDate) {
      a = 'custom';
    } else {
      a = event.value;
    }

    this.dashboardService
      .getCustomerTraffic(this.store.id, a, orderType, this.posOrOnlinePcs, startDate, endDate, startTime, endTime, event.value)
      .subscribe((data) => {
        this.pcCustomerTrafficData = data;
        this.showGrapFull()
      });
  }

  getPosOnline(event: MatRadioChange, orderType: string) {
    if (!this.Daterange.value.from_date) {
      this.dashboardService
        .getCustomerTraffic(this.store.id, this.customerTrafficPcs, orderType, event.value, this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
        .subscribe((data) => {
          this.pcCustomerTrafficData = data;
          this.showGrapFull()
        });
    } else {
      this.customerTrafficPcs = 'custom';
      this.startDate = this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd');
      this.endDate = this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd');
      this.startTime = this.strHour,
        this.endTime = this.strHour2,
        this.dashboardService
          .getCustomerTraffic(this.store.id, this.customerTrafficPcs, orderType, event.value, this.startDate, this.endDate, this.startTime, this.endTime, this.eventValue)
          .subscribe((data) => {
            this.pcCustomerTrafficData = data;
            this.showGrapFull()
          });
    }
  }

  getAverageCheckSize(event: MatRadioChange, orderType: string) {
    const startDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd') : "";
    const endDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd') : "";
    const startTime = this.strHour ? this.strHour : "0:00";
    const endTime = this.strHour2 ? this.strHour2 : "0:00";
    let a;
    if (startDate) {
      a = 'custom';
    } else {
      a = event.value;
    }

    this.dashboardService
      .getAverageTicketSales(this.store.id, a, orderType, startDate, endDate, startTime, endTime, event.value)
      .subscribe((data) => {
        this.pcaverageCheckSizeData = data;
        this.showGrapFull()
      });
  }

  getLaborCost(event: MatRadioChange, orderType: string) {
    const startDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd') : "";
    const endDate = this.Daterange.value.from_date ? this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd') : "";
    const startTime = this.strHour ? this.strHour : "0:00";
    const endTime = this.strHour2 ? this.strHour2 : "0:00";
    let a;
    if (startDate) {
      a = 'custom';
    } else {
      a = event.value;
    }

    this.dashboardService
      .getLaborCostPercent(this.store.id, a, orderType, startDate, endDate, startTime, endTime, event.value)
      .subscribe((data) => {
        this.laborCostData = data;
        this.showGrapFull()
      });
  }

  getTotalUser(event: MatRadioChange, orderType) {
    this.dashboardService.getTotalUsers(this.store.id, event.value, orderType, this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time).subscribe(data => {
      this.textTotalUserData = data;
    })
  }

  filterSubmit() {
    this.startDate = this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd');
    this.startTime = this.strHour ? this.strHour : "0:00";
    this.endTime = this.strHour2 ? this.strHour2 : "0:00";
    this.orderVolumeTimePc = '';
    this.orderVolumeTimeTxt = '';
    this.customerTrafficPcs = '';
    this.averageCheckSizePcs = '';
    this.laborCostPcs = '';
    this.totalUserTimeTxt = '';
    /* TExt Order */
    if (this.store.enable_text_ordering) {
      this.dashboardService
        .getOrderVolume(this.store.id, 'custom', 'text', this.startDate, this.endDate, this.strHour, this.strHour2, this.customGroup)
        .subscribe((data) => {
          this.textOrderVolumeData = data;
        });

      this.dashboardService.getTotalUsers(this.store.id, 'custom', 'text', this.startDate, this.endDate, this.startTime, this.endTime).subscribe(data => {
        this.textTotalUserData = data;
      })

    }
    /* Pick And Click Order */
    if (this.store.enable_pick_and_click_ordering) {
      this.dashboardService
        .getOrderVolume(this.store.id, 'custom', 'pc', this.startDate, this.endDate, this.startTime, this.endTime, this.customGroup)
        .subscribe((data) => {
          this.pcOrderVolumeData = data;
        });

      this.dashboardService
        .getCustomerTraffic(this.store.id, 'custom', 'pc', this.posOrOnlinePcs, this.startDate, this.endDate, this.startTime, this.endTime, this.customGroup)
        .subscribe((data) => {
          this.pcCustomerTrafficData = data;
        });

      this.dashboardService
        .getAverageTicketSales(this.store.id, 'custom', 'pc', this.startDate, this.endDate, this.startTime, this.endTime, this.customGroup)
        .subscribe((data) => {
          this.pcaverageCheckSizeData = data;
        });

      this.dashboardService
        .getLaborCostPercent(this.store.id, 'custom', 'pc', this.startDate, this.endDate, this.startTime, this.endTime, this.customGroup)
        .subscribe((data) => {
          this.laborCostData = data;
        });
    }
  }

  clearDate() {
    this.Daterange.get('from_date').setValue('');
    this.Daterange.get('to_date').setValue('');
    this.exportTime = {
      hour: '0',
      minute: '0',
      format: 24,
      meriden: 'AM'
    };
    this.orderVolumeTimePc = 'monthly';
    this.orderVolumeTimeTxt = 'monthly';
    this.customerTrafficPcs = 'monthly';
    this.posOrOnlinePcs = 'pos';
    this.averageCheckSizePcs = 'monthly';
    this.laborCostPcs = "monthly";
    this.totalUserTimeTxt = "monthly";
    /* Text Order */
    if (this.store.enable_text_ordering) {
      this.dashboardService
        .getOrderVolume(this.store.id, this.orderVolumeTimeTxt, "text", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
        .subscribe((data) => {
          this.textOrderVolumeData = data;
        });

      this.dashboardService.getTotalUsers(this.store.id, this.totalUserTimeTxt, 'text', this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time)
        .subscribe(data => {
          this.textTotalUserData = data;
        })
    }

    /* Pick And Click Order */
    if (this.store.enable_pick_and_click_ordering) {
      this.dashboardService
        .getOrderVolume(this.store.id, this.orderVolumeTimePc, "pc", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
        .subscribe((data) => {
          this.pcOrderVolumeData = data;
        });

      this.dashboardService
        .getCustomerTraffic(this.store.id, this.customerTrafficPcs, "pc", this.posOrOnlinePcs, this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
        .subscribe((data) => {
          this.pcCustomerTrafficData = data;
        });

      this.dashboardService
        .getAverageTicketSales(this.store.id, this.averageCheckSizePcs, "pc", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
        .subscribe((data) => {
          this.pcaverageCheckSizeData = data;
        });

      this.dashboardService
        .getLaborCostPercent(this.store.id, this.laborCostPcs, "pc", this.Daterange.value.from_date, this.Daterange.value.to_date, this.Daterange.value.start_time, this.Daterange.value.end_time, this.Daterange.value.custom_group)
        .subscribe((data) => {
          this.laborCostData = data;
        });
    }

  }
  showFull(aaa) {
    if (aaa != '') {
      this.showGraph = true
      this.showGraphName = aaa;
      this.showGrapFull()
    } else {
      this.showGraph = false
      this.showGraphName = ''
      this.resetGrap();
    }

  }

  resetGrap(){
    this.orderVolumeChartOptions = {
      chartArea: { width: "90%" },
      legend: { position: "none" },
      width: 'auto',
      vaxis: {
        0: {
          gridlines: { color: "transparent" },
        },
        1: {
          gridlines: { color: "transparent" },
          format: "currency",
        },
      },
      seriesType: "bars",
      series: {
        1: { color: "#028a0f" },
        2: {
          type: "line",
          targetAxisIndex: 1,
          lineWidth: 5,
          pointSize: 10,
        },
      },
    };
    this.customerTrafficChartOptions = {
      chartArea: { width: "90%" },
      isStacked: true,
      legend: { position: "none" },
      width: 'auto',
      vaxis: {
        0: {
          gridlines: { color: "transparent" },
        },
        1: {
          gridlines: { color: "transparent" },
          format: "currency",
        },
      },
      seriesType: "bars",
      series: {
        1: { color: "#028a0f" },
      },
    };
    this.averageTicketSalesChart = {
      chartArea: { width: "90%" },
      legend: { position: "none" },
      width: 'auto',
      vaxis: {
        0: {
          gridlines: { color: "transparent" },
        },
        1: {
          gridlines: { color: "transparent" },
          format: "currency",
        },
      },
      series: {
        0: {
          type: "line",
          targetAxisIndex: 1,
          lineWidth: 5,
          pointSize: 10,
        },
        1: {
          type: "line",
          targetAxisIndex: 1,
          lineWidth: 5,
          pointSize: 10,
          color: "#028a0f"
        },
      },
    };
    this.laborCostChartOptions = {
      chartArea: { width: "90%" },
      legend: { position: "none" },
      width: 'auto',
      vaxis: {
        0: {
          gridlines: { color: "transparent" },
        },
        1: {
          gridlines: { color: "transparent" },
          format: "currency",
        },
      },
      seriesType: "bars",
      series: {
        1: { color: "#028a0f" },
      },
    };
  }
  showGrapFull(){
    if(this.showGraphName == 'graph1' || this.showGraphName== 'graph5' || this.showGraphName == 'graph6'){
      let widthGraph:any
      if(this.showGraphName == 'graph1'){
        widthGraph =50*this.pcOrderVolumeData.rows.length;
      }
      if(this.showGraphName == 'graph5' ){
        widthGraph =50*this.textOrderVolumeData.rows.length;
      }
      if(this.showGraphName == 'graph6'){
        widthGraph =50*this.textTotalUserData.rows.length;
      }
      
      widthGraph = widthGraph >= 1024 ? widthGraph : 1024;
      //this.chartContainerFull= Number(widthGraph)
      this.orderVolumeChartOptions = {
        chartArea: {
           width: "auto" 
          },
        legend: { position: "none" },
        width: String(widthGraph),
        vaxis: {
          0: {
            gridlines: { color: "transparent" },
          },
          1: {
            gridlines: { color: "transparent" },
            format: "currency",
          },
        },
        seriesType: "bars",
        series: {
          1: { color: "#028a0f" },
          2: {
            type: "line",
            targetAxisIndex: 1,
            lineWidth: 5,
            pointSize: 10,
          },
        },
      };
    }
    else if(this.showGraphName == 'graph2'){
      let widthGraph =50*this.pcCustomerTrafficData.rows.length;
      widthGraph = widthGraph >= 1024 ? widthGraph : 1024;
      this.customerTrafficChartOptions = {
        chartArea: { width: "auto" },
        isStacked: true,
        legend: { position: "none" },
        width: String(widthGraph),
        vaxis: {
          0: {
            gridlines: { color: "transparent" },
          },
          1: {
            gridlines: { color: "transparent" },
            format: "currency",
          },
        },
        seriesType: "bars",
        series: {
          1: { color: "#028a0f" },
        },
      };
    }
    else if (this.showGraphName == 'graph3'){
      let widthGraph =50*this.pcaverageCheckSizeData.rows.length;
      widthGraph = widthGraph >= 1024 ? widthGraph : 1024;
      this.averageTicketSalesChart = {
        chartArea: { width: "auto" },
        legend: { position: "none" },
        width: String(widthGraph),
        vaxis: {
          0: {
            gridlines: { color: "transparent" },
          },
          1: {
            gridlines: { color: "transparent" },
            format: "currency",
          },
        },
        series: {
          0: {
            type: "line",
            targetAxisIndex: 1,
            lineWidth: 5,
            pointSize: 10,
          },
          1: {
            type: "line",
            targetAxisIndex: 1,
            lineWidth: 5,
            pointSize: 10,
            color: "#028a0f"
          },
        },
      };
    }
    else if (this.showGraphName == 'graph4'){
      let widthGraph =50*this.laborCostData.rows.length;
      widthGraph = widthGraph >= 1024 ? widthGraph : 1024;
      this.laborCostChartOptions = {
        chartArea: { width: "auto" },
        legend: { position: "none" },
        width: String(widthGraph),
        vaxis: {
          0: {
            gridlines: { color: "transparent" },
          },
          1: {
            gridlines: { color: "transparent" },
            format: "currency",
          },
        },
        seriesType: "bars",
        series: {
          1: { color: "#028a0f" },
        },
      };
    }     
  }
}
