<div fxLayout="column">
  <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row wrap" fxLayoutAlign="start center"
    fxLayout="row wrap" fxFlex="100" class="message-table gift-mobile">
    <form class="order-message-table-filters flex-wrap">
      <mat-form-field appearance="fill" class="search">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search Orders</mat-label>
        <input [(ngModel)]="search_str" name="search_str" matInput placeholder="Search orders" #input
          (input)="searchOrders($event)">
      </mat-form-field>
      <mat-form-field appearance="fill" class="start">
        <mat-label>Start Date</mat-label>
        <input [(ngModel)]="from_date" name="from_date" matInput [matDatepicker]="startPicker"
          placeholder="Choose a start date" (dateInput)="searchOrders($event)">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="end">
        <mat-label>End Date</mat-label>
        <input [(ngModel)]="to_date" name="to_date" matInput [matDatepicker]="endPicker" placeholder="Choose a end date"
          (dateInput)="searchOrders($event)" [min]="startDate">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="apply-btn-colr clear action-btn-colr create" (click)="reset()" color="primary">CLEAR</button>
      <div class="clear"></div>
    </form>
  </div>
  <div class="cardholders-table-div  table-div full-y-width  xs:!overflow-x-scroll  xs:m-auto">
    <div class="cardholders-table-mobile-scroll">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="id"
        matSortDirection="desc" matSortDisableClear>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="phone_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
          <td mat-cell *matCellDef="let element">{{element.phone_number}}</td>
        </ng-container>
        <ng-container matColumnDef="bot_order_count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.bot_order_count >= 5">Platinum</span>
            <span *ngIf="element.bot_order_count == 4">Gold</span>
            <span *ngIf="element.bot_order_count >= 2 && element.bot_order_count <= 3">Silver</span>
            <span *ngIf="element.bot_order_count == 1">Bronze</span>
            <span *ngIf="element.bot_order_count == null || element.bot_order_count == 0">No Status</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="active_gift_card">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Active Gift Card </th>
          <td mat-cell *matCellDef="let element">{{element.active_gift_card}}</td>
        </ng-container>
        <ng-container matColumnDef="total_card_value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Card Value </th>
          <td mat-cell *matCellDef="let element">{{element.total_card_value | currency:'USD'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="current_value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Current Balance </th>
          <td mat-cell *matCellDef="let element">{{element.current_value | currency:'USD'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="last_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Visit </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="longDate(element) == -1"></span>
            <span *ngIf="longDate(element) == 0">Today</span>
            <span *ngIf="longDate(element) == 1">Yesterday</span>
            <span *ngIf="longDate(element) > 1">{{longDate(element) | number: '1.0-0'}} days ago</span>
          </td>
          <td mat-cell *matCellDef="let element" class="last-border-set">
            <div fxLayoutAlign="center center">
              <span class=" date-color-set date-too">
                <span class="set how-to-long-date">
                  {{element.StartDate}} - {{element.DueDate}}
                </span>
                <span class="not-set days-data" matTooltip="{{longDate(element)}} Days"
                  [matTooltipClass]="position.value">
                  {{longDate(element)}}d</span>
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="member_since">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Member Since </th>
          <td mat-cell *matCellDef="let element"> {{element.member_since | date}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"
              (click)="onRowClicked(element)">
              <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expand-table-cardholders">
                <div class="exapnd-row expand-td">
                  <div class="points-redeemed">
                    <p>Card Value Used: <span class="value">{{cardValueUsed | currency: 'USD'}}</span></p>
                  </div>
                  <div class="current-points">
                    <p>Current Balance: <span class="value">{{curretnBalance | currency: 'USD'}}</span></p>
                  </div>
                  <div class="input-points">
                    <!-- <input class="field" type="number" min="0" id="quantity" (keypress)="numberOnly($event)" [(ngModel)]="val" name="quantity"
                      #quantity> -->
                    <input class="field" type="text" pattern="[0-9]*\.?[0-9]+" id="quantity"
                      (keypress)="numberOnly($event)" [(ngModel)]="val" name="quantity" #quantity>

                  </div>
                  <div class="btn-add-sub xs:!flex md:!flex">
                    <button mat-raised-button color="primary" class="add-btn apply-btn-colr"
                      (click)="addSubGiftCardValue(quantity.value, 1)" [disabled]="!quantity.value">Add</button>
                    <button mat-raised-button color="accent" class="subtract-btn"
                      (click)="addSubGiftCardValue(quantity.value, 0)"
                      [disabled]="!quantity.value || !curretnBalance || quantity.value > curretnBalance">Subtract</button>
                  </div>
                </div>
                <mat-tab-group>
                  <mat-tab label="Gift Card Log History">
                    <div style="width: 100%;max-height: 200px;overflow-y: auto;">
                      <table mat-table [dataSource]="innerGiftLogDatasource" style="background-color: #ededed;">
                        <ng-container matColumnDef="operation_date">
                          <th mat-header-cell *matHeaderCellDef> Operation Date </th>
                          <td mat-cell *matCellDef="let element">{{element.updated_at | date: 'h:mm a MM/dd/yyyy'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="operation_type">
                          <th mat-header-cell *matHeaderCellDef> Operation Type </th>
                          <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.operation == 1">Credited</span>
                            <span *ngIf="element.operation == 0">Debited</span>

                          </td>
                        </ng-container>
                        <ng-container matColumnDef="operation_value">
                          <th mat-header-cell *matHeaderCellDef> Operation Value </th>
                          <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.operation == 1">+</span>
                            <span *ngIf="element.operation == 0">-</span>
                            {{element.value | currency:'USD'}}
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="innerGiftLogDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: innerGiftLogDisplayedColumns;"></tr>
                      </table>
                    </div>
                  </mat-tab>
                  <mat-tab label="Order History">
                    <div style="width: 100%;max-height: 200px;overflow-y: auto;">
                      <table mat-table [dataSource]="innerDatasource" style="background-color: #ededed;">
                        <ng-container matColumnDef="order_id">
                          <th mat-header-cell *matHeaderCellDef> Order Counter </th>
                          <td mat-cell *matCellDef="let element"> {{element.order_counter}} </td>
                        </ng-container>
                        <ng-container matColumnDef="order_date">
                          console.log(row);
                          <th mat-header-cell *matHeaderCellDef> Order Date </th>
                          <td mat-cell *matCellDef="let element"> {{element.submitted_at | date:
                            'MM/dd/yyyy'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="order_time">
                          <th mat-header-cell *matHeaderCellDef> Order Time </th>
                          <td mat-cell *matCellDef="let element"> {{element.submitted_at | date: 'hh:mm a'}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="payment_type">
                          <th mat-header-cell *matHeaderCellDef> Payment Type </th>
                          <td mat-cell *matCellDef="let element"> {{element.payment_type}} </td>
                        </ng-container>
                        <ng-container matColumnDef="total_spend">
                          <th mat-header-cell *matHeaderCellDef> Total Spend </th>
                          <td mat-cell *matCellDef="let element"> {{element.gift | currency: 'USD'}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="occasion">
                          <th mat-header-cell *matHeaderCellDef> Order Occasion </th>
                          <td mat-cell *matCellDef="let element"> {{element.occasion}} </td>
                        </ng-container>
                        <ng-container matColumnDef="pos_online">
                          <th mat-header-cell *matHeaderCellDef> Order Method </th>
                          <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.is_pos==false"> ONLINE </span>
                            <span *ngIf="element.is_pos==true"> POS </span>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="innerOrderHistoryDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: innerOrderHistoryDisplayedColumns;"></tr>
                      </table>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand" (click)="tableUserListHolder(1)"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
          [ngClass]="{ 'highlight-order-row': element?.id === selectedRow?.id }" (click)="onRowClicked(element)"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <mat-paginator [length]="filterCount" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" (page)="yourHandler($event)">
    </mat-paginator>
  </div>
</div>