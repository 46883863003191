import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class OpenOrderToggleService {

  private source = new BehaviorSubject<string>(null);
  orderDoneSubject: Subject<null> = new Subject<null>();
  current = this.source.asObservable();

  constructor() {}

  toggle(state) {
    this.source.next(state);
  }

}
