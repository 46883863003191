<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div *ngIf="!isLoading" fxLayout="row" fxLayout.lt-md="column" cols="3" class="mt-1">

  <div fxLayoutAlign.lg="flex-end" fxLayoutAlign.lt-md="center" class="section">
      <app-store-info [store]="store"></app-store-info>
  </div>

  <div fxLayoutAlign="flex-start" class="section pb-14" fxLayoutAlign.lt-md="center" >
      <app-store-settings class="app-store-settings mr-auto box_width"
      [store]="store"
      [storeMerchant]="storeMerchant"></app-store-settings>
  </div>

</div>

