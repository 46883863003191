import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {Store, MenuResetOptions} from 'src/app/_models/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrinterSettingsService } from 'src/app/_services/printer-settings.service';


@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AddNewComponent implements OnInit {

  @Input() store:Store;
  @Output() refresh = new EventEmitter();

  newMenuItemGroupForm;
  columnsToDisplay = ['addMenu']
  expandedElement
  newMenuDatasource = [{"addMenu": "Add menu category"}]
  resetOptions = MenuResetOptions;

  @ViewChild('f', {static: false}) ngForm;

  hasMultiple = false;
  printerSettings: any;
  storeId: any

  constructor(private menuService:MenuServiceV2, private snackBar: MatSnackBar,  private printerService: PrinterSettingsService,) { }

  ngOnInit() {
    this.newMenuItemGroupForm = this.getNewMenuItemGroupForm()
    this.storeId = localStorage.getItem('currentStore');
    this.getPrinterHeartbeat();
  }

  addNewMenuItemGroup(values){
    this.menuService.addMenuItemGroup(this.store.id, JSON.stringify(values)).subscribe(response => {
      this.snackBar.open('Item has been added', 'OK', { duration: 2000 });
      this.expandedElement = null;
      this.ngForm.resetForm()
      this.refresh.emit()
      this.newMenuItemGroupForm = this.getNewMenuItemGroupForm()
    }) 
  }

  getNewMenuItemGroupForm(){
    return new UntypedFormGroup({
      menu_group_name: new UntypedFormControl('', Validators.required),
      subgroup: new UntypedFormControl(''),
      assignto_printer: new UntypedFormControl([]),
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(),
      is_taxable: new UntypedFormControl(true, Validators.required),
      menu_items:new UntypedFormArray([1].map(mi => {
          return new UntypedFormGroup({
            portion: new UntypedFormControl('', Validators.required),
            base_price: new UntypedFormControl('', Validators.required),
            barcode: new UntypedFormControl(null),
            reset: new UntypedFormControl(null),
            food_cost: new UntypedFormControl(''),
            is_available: new UntypedFormControl(true, Validators.required),
            is_taxable: new UntypedFormControl(true, Validators.required),
            is_pos_only: new UntypedFormControl(false, Validators.required),
            available_qty: new UntypedFormControl(null)
          })
        }
      ))
    })
  }

  addPortionNewMenuItemGroup(){
    this.newMenuItemGroupForm.get('menu_items').push(new UntypedFormGroup({
      portion: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
      base_price: new UntypedFormControl('', Validators.required),
      barcode: new UntypedFormControl(null),
      reset: new UntypedFormControl(null),
      food_cost: new UntypedFormControl(''),
      is_available: new UntypedFormControl(true, Validators.required),
      is_taxable: new UntypedFormControl(true, Validators.required),
      is_pos_only: new UntypedFormControl(false, Validators.required),
      available_qty: new UntypedFormControl(null)
    }))
  }

  getPortionOptions(){
    return ['Standard', 'Small', 'Medium', 'Large']
  }

  deleteMenuItemFromNewForm(){
    if(this.newMenuItemGroupForm.get('menu_items').controls.length > 1){
      this.newMenuItemGroupForm.get('menu_items').controls.pop()
    }
  }

  getPrinterHeartbeat() {
    this.printerService.getPrinterSettings(this.storeId).subscribe(data => {
      const allPrinterObje = {name: 'All', id: 'set_to_all'}
      this.printerSettings = [allPrinterObje, ...data]
    })
  }

}
