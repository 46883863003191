import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from '../_services/loader.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

    constructor(public loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.totalRequests++;
        this.showLoader();

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.decreaseRequests();
          }
        },
          (err: any) => {
            this.decreaseRequests();
            throw err;
        }));
    }

    private decreaseRequests() {
      this.totalRequests--;
      if (this.totalRequests === 0) {
        this.hideLoader();
      }
    }

    private showLoader(): void {
      this.loaderService.show();
    }
    private hideLoader(): void {
      this.loaderService.hide();
    }

}
