<div class="fixed top-5 right-5 z-[1050]">
    <div *ngFor="let call of incomingCallArray"
        class="w-64 bg-white border border-gray-300 shadow-lg rounded-2xl p-6 space-y-4 gap-3 mb-4" [@slideInOut]>
        <div class="flex items-center space-x-2 gap-4">
            <div class="text-green-500 flex-shrink-0">
                <mat-icon class="text-[26px]">phone_callback</mat-icon>
            </div>
            <div class="flex flex-col">
                <span class="text-base font-medium leading-tight">{{ call.phone_number }}</span>
                <span class="text-base font-medium leading-tight mt-[5px]">{{ call.customer_name || 'No Name' }}</span>
                <span class="text-base font-medium"
                    [ngClass]="call.is_existing_customer ? 'text-green-500' : 'text-red-500'">
                    {{ call.is_existing_customer ? 'Existing Customer' : 'New Customer' }}
                </span>
            </div>
        </div>
        <div class="flex justify-between !mt-9">
            <button
                class="flex-1 bg-blue-600 text-white rounded-[20px] py-3 mr-2 text-sm font-medium shadow-none hover:bg-blue-500"
                (click)="handleAccept(call)">
                Accept
            </button>
            <button
                class="flex-1 bg-white text-gray-800 rounded-[20px] py-3 ml-2 text-sm font-medium shadow-none hover:bg-gray-400 border-[1px] border-[dimgrey]"
                (click)="handleIgnore(call)">
                Ignore
            </button>
        </div>
    </div>
</div>