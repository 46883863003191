import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {RefundPayment} from 'src/app/_models/payment';
import {POSService} from 'src/app/_services/pos-service';
import {PaymentService} from '../../_services/payment.service';
import {StoreService} from 'src/app/_services/store.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent implements OnInit, OnDestroy {

  loading;
  orderWithPayment: OrdersWithPayment;

  error;
  orderId;
  store;
  isLoading = false;
  form: UntypedFormGroup;

  private destroySubject: Subject<void> = new Subject();

  constructor(private orderService: OrderService,
              public dialogRef: MatDialogRef<CancelDialogComponent>,
              private paymentService: PaymentService,
              private storeService: StoreService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private posService: POSService) {
      this.orderId = this.data.botOrder.order_hash;
      this.store = this.data.store;
      this.orderWithPayment = this.data.order;
    }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      cancel_reason: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  cancelOrder() {
    this.isLoading = true;
    this.orderService.cancelOrder(this.orderId, this.form.value.cancel_reason, (this.data.split_detail ? this.data.split_detail[0].possplitpayment_details.id : '')).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      // this.refundCanceledOrder();
      this.isLoading = false;
      this.dialogRef.close(1);
    });

  }

  refundCanceledOrder() {
    let o;
    this.orderId = this.data.botOrder.id;
    if (this.data.botOrder.is_pos && this.data.botOrder.source !== 'POS_MANUAL_CREDIT') {
      const refund = this.constructRefundObject();
      o = this.posService.posRefund(this.data.terminalId, this.data.botOrder.order_hash, refund.refund_amount);
    } else {
      if (this.store.payment_gateway === 'STRIPE') {
        o = this.paymentService.refundStripe(this.constructRefundObject());
      } else if (this.store.payment_gateway === 'CARDCONNECT') {
        o = this.paymentService.refund(this.constructRefundObject());
      } else {
        o = this.paymentService.refundGateway(this.constructRefundObject());
      }
    }
    o.pipe(takeUntil(this.destroySubject)).subscribe(
      data => {
        this.loading = false;
        console.log('%%%% Refund done!');
        console.log(JSON.stringify(data));
      },
      error => {
        this.error = error;
        this.loading = false;
    });
  }

  private constructRefundObject(): RefundPayment {
      return new RefundPayment(this.orderId, this.data.botOrder.total);
  }
}
