<div class="container">
  <div class="controls">
    <div id="stats" class="drop-shadow-md" style="position: relative;">

      <div class="grid grid-cols-2 gap-2">
        <div class="flex flex-col text-center border-r border-gray-300">
          <span class="text-xl">{{stats?.total || 0}}</span>
          <span class="text-sm">Total Offer Redeemed</span>
        </div>

        <div class="flex flex-col text-center lab-text-blue">
          <span class="text-xl gift-color">{{+stats?.revenue_generated || 0 | currency: 'USD'}}</span>
          <span class="text-sm gift-color">Total Revenue Generated</span>
        </div>
      </div>
    </div>

    <button mat-flat-button color="primary" class="create apply-btn-colr" (click)="createNew()">
      CREATE NEW
    </button>
    <div style="clear: both"></div>
  </div>
  <div class="message-table">
    <app-offer-list-message-table [store]="store" [refresh]="refresh"></app-offer-list-message-table>
  </div>
</div>


