import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {StoreService} from 'src/app/_services/store.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-surcharge-detail-popup',
  templateUrl: './surcharge-detail-popup.component.html',
  styleUrls: ['./surcharge-detail-popup.component.scss']
})
export class SurchargeDetailPopupComponent implements OnInit {
  idStore: any;
  surChargeLabel: any;
  surChargePercent: any;
  surChargetext: any;
  options: UntypedFormGroup;
  constructor(
    private storeService: StoreService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SurchargeDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    // public dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.idStore = localStorage.getItem('currentStore');

    this.surChargeLabel = this.data.store.surcharge_label;
    this.surChargePercent = this.data.store.surcharge_percent;
    this.surChargetext = this.data.store.surcharge_text;

    this.options = this.fb.group({
      surcharge_label: this.surChargeLabel,
      surcharge_percent: this.surChargePercent,
      surcharge_text: this.surChargetext,
    });
  }

  editSurchargeLabelPercent() {
    const newSurchargeLabel = this.options.value.surcharge_label;
    const newSurchargePercent = this.options.value.surcharge_percent;
    const newSurchargeText = this.options.value.surcharge_text;
    let keySend = {
      surcharge_label: newSurchargeLabel,
      surcharge_percent: newSurchargePercent,
      surcharge_text: newSurchargeText
    };
    this.storeService.updateSurchargeDetail(this.idStore, keySend).subscribe((data: any) => {
      this.dialogRef.close(keySend);
    });


  }

}
