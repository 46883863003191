import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-dashboard-employee-tax',
  templateUrl: './dashboard-employee-tax.component.html',
  styleUrls: ['./dashboard-employee-tax.component.scss']
})
export class DashboardEmployeeTaxComponent implements OnInit {

  taxes: FormGroup;
  stateEmployeeTax: any;
  federalEmployeeTax: any;
  idStore: any;
  stateTax: any;
  federalTax: any;
  constructor(
    private fb: FormBuilder,
    public storeService: StoreService,
    public dialogRef: MatDialogRef<DashboardEmployeeTaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.idStore = localStorage.getItem('currentStore')
    if (this.data.store.store_data != null) {
      this.stateEmployeeTax = this.data.store.store_data.employee_state_tax_percent;
      this.federalEmployeeTax = this.data.store.store_data.employee_federal_tax_percent;
      this.taxes = this.fb.group({
        state_tax_percent: this.stateEmployeeTax,
        federal_tax_percent: this.federalEmployeeTax
      });
    } else {
      this.taxes = this.fb.group({
        state_tax_percent: '',
        federal_tax_percent: ''
      });
    }
    
  }

  editEmployeeTax() {
    this.stateTax = this.taxes.value.state_tax_percent;
    this.federalTax = this.taxes.value.federal_tax_percent;
    const keySend = {
      employee_state_tax_percent:  this.stateTax,
      employee_federal_tax_percent: this.federalTax
    }
    this.storeService.updateEmployeeTax(this.idStore, keySend).subscribe((data:any) => {
      this.dialogRef.close(keySend);
    });
  }

}
