import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Permission} from '../_models/permission';
import {Store} from '../_models/store';
import {PermissionService} from '../_services/permission.service';
import {StoreService} from '../_services/store.service';
import {UnreadService} from '../_services/unread-message.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  store: Store;
  unreadCounter;
  permission: Permission;
  @Input() opened: boolean;

  private destroySubject: Subject<void> = new Subject();

  constructor(private storeService: StoreService,
              private unreadService: UnreadService,
              private permissionService: PermissionService) { }

  ngOnInit() {
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store) {
        this.unreadService.current.pipe(takeUntil(this.destroySubject)).subscribe(counter => this.unreadCounter = counter);
      }
    });

    this.permissionService.current.pipe(takeUntil(this.destroySubject)).subscribe(permission => this.permission = permission);
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  onOrdersClick(url: string): void {
    // Get the div inside mat-sidenav-content
    const sidenavContent = document.querySelector('mat-sidenav-content .basic-container.smooth-scroll');
  
    // Check if the div exists
    if (sidenavContent) {
      // If the URL is /home/orders, add the 'color-scheme-dark' class
      if (url === '/home/orders' && this.store.feature_flag['dark_mode']) {
        sidenavContent.classList.add('color-scheme-dark');
        console.log('color-scheme-dark applied to the div.');
      } 
      // Else, remove the 'color-scheme-dark' class if it exists
      else {
        if (sidenavContent.classList.contains('color-scheme-dark')) {
          sidenavContent.classList.remove('color-scheme-dark');
          console.log('color-scheme-dark removed from the div.');
        }
      }
    } else {
      console.log('mat-sidenav-content div not found.');
    }
  }

}
