import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormBase} from './form-base';
import {Store} from '../../../../_models/store';
import {DialogConfig} from 'src/app/info/store-info/store-info-template-config';
import {OccasionAvailabilityComponent} from '../occasion-availability/occasion-availability.component';
import {StoreService} from 'src/app/_services/store.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss']
})
export class DynamicFormControlComponent implements OnInit {
  @Input() myFormControl: FormBase<any>;
  @Input() editControlKeys: string[];
  @Input() store: Store;
  @Input() form: UntypedFormGroup;
  @Input() dialogConfig: DialogConfig;

  width = 100;
  get isValid() { return this.form.controls[this.myFormControl.key].valid; }
  aa:any=[];
  storeID: any;
  occasionChoose: any;
  occasionData: any;

  constructor(
    private storeService: StoreService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.aa = this.editControlKeys;    
    this.storeID = localStorage.getItem('currentStore');
    if (this.dialogConfig) {
      this.width = this.dialogConfig.suffix !== '' ? 70 : 100;
    }
  }

  getNestedValue(key) {
    let value;
    const splits = key.split('.');
    splits.forEach(fkey => {
      if (!value) {
        value = this.store[fkey];
      } else {
        value = value[fkey];
      }
    });
    return value;
  }

  setOccasionAvaibility(event) {
    const isMobile = window.innerWidth <= 599;
    this.occasionChoose = event;
    this.storeService.getOccasionTime(this.storeID, this.occasionChoose).subscribe((data: any) => {
    });
    const dialogRef = this.dialog.open(OccasionAvailabilityComponent, {
      width: isMobile ?'100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        occcasionname: this.occasionChoose
      }
    });
    dialogRef.afterClosed();

  }

}
