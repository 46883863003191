import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuResetOptions, Store } from 'src/app/_models/store';
import { BooleanOptions, Options } from 'src/app/_models/Options';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';

@Component({
  selector: 'app-edit-modifier-dialog',
  templateUrl: './edit-modifier-dialog.component.html',
  styleUrls: ['./edit-modifier-dialog.component.scss']
})
export class EditModifierDialogComponent implements OnInit {
  mods: Options[];
  resetOptions = MenuResetOptions;
  private destroySubject: Subject<void> = new Subject();
  presenseOptions: BooleanOptions[];
  form: FormGroup;

  constructor(
    private menuService: MenuServiceV2,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditModifierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) { }

  onNoClick(event: Event): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  ngOnInit() {
    this.mods = [];
    this.mods.push(new Options('No Max', -1));
    for (let index = 1; index < 101; index++) {
      this.mods.push(new Options('' + index, index));
    }

    this.presenseOptions = [
      new BooleanOptions('Required', true),
      new BooleanOptions('Optional', false)
    ];

    this.form = this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      upcharge: [0, [Validators.required, Validators.min(0)]],
      has_quantity: [false],
      fixed_price: [false],
      food_cost: [0, Validators.min(0)],
      max_mod_units: [null],
      is_available: [true],
      reset: [null],
      has_half_mod: [false],
      half_mod_upcharge: [{ value: 0, disabled: true }, Validators.min(0)],
      is_pos_only: [false],
      no_charge: [0, Validators.min(0)],
      light_charge: [0, Validators.min(0)],
      extra_charge: [0, Validators.min(0)],
      onside_charge: [0, Validators.min(0)]
    });

    this.form.get('has_half_mod').valueChanges.subscribe((checked) => {
      const halfModUpcharge = this.form.get('half_mod_upcharge');
      if (checked) {
        halfModUpcharge.enable();
      } else {
        halfModUpcharge.disable();
      }
    });

    this.getModifier();
  }

  getModifier() {
    const currentStore = localStorage.getItem('currentStore');
    this.menuService
      .getModifierOption(currentStore, this.data.modifierOption.id)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((data) => {
        this.form.patchValue({
          id: data.id || '',
          name: data.name || '',
          upcharge: data.upcharge || 0,
          has_quantity: data.has_quantity || false,
          fixed_price: data.fixed_price || false,
          food_cost: data.food_cost || 0,
          max_mod_units: data.max_mod_units || null,
          is_available: data.is_available || true,
          reset: data.reset || null,
          has_half_mod: data.has_half_mod || false,
          half_mod_upcharge: data.half_mod_upcharge || 0,
          is_pos_only: data.is_pos_only || false,
          no_charge: data.no_tag_upcharge || 0,
          light_charge: data.light_tag_upcharge || 0,
          extra_charge: data.extra_tag_upcharge || 0,
          onside_charge: data.onside_tag_upcharge || 0
        });
        if (data.has_half_mod) {
          this.form.get('half_mod_upcharge').enable();
        } else {
          this.form.get('half_mod_upcharge').disable();
        }
      });
  }

  toggleAll(controls, option, value) {
    Object.keys(controls).forEach((key) => {
      controls[key].controls[option].setValue(value.checked);
      if (value.checked) {
        controls[key].controls['max_mod_units'].setValue(1);
        controls[key].controls['max_mod_units'].enable();
      } else {
        controls[key].controls['max_mod_units'].setValue(null);
        controls[key].controls['max_mod_units'].disable();
      }
    });
  }

  updateModifier(values) {
    const currentStore = localStorage.getItem('currentStore');
    this.menuService
      .updateModifier(currentStore, JSON.stringify(values))
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
        this.dialogRef.close();
      });
  }

  enableMaxMod(event, formGroup) {
    if (event.checked) {
      formGroup.controls.max_mod_units.setValue(1);
      formGroup.controls.max_mod_units.enable();
    } else {
      formGroup.controls.max_mod_units.setValue(null);
      formGroup.controls.max_mod_units.disable();
    }
  }
}
