
<mat-form-field class="full-width">
  <mat-chip-listbox #chipList aria-label="selection">
    <mat-chip-option
      *ngFor="let item of selectedItems"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(item)">
      {{item.name}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-option>
    <input
      [placeholder]="'New '+label"
      #itemInput
      [formControl]="itemCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-listbox>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{item.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
