import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-offer-redemption-list',
  templateUrl: './offer-redemption-list.component.html',
  styleUrls: ['./offer-redemption-list.component.scss']
})
export class OfferRedemptionListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
