<p class="red" [hidden]="isAck">
  A recent system update has caused disruptions in closed order data display,
  but our team of experts is working around the clock to resolve the issue and restore the affected
  systems to their normal state. Platform is operating as usual but simply not displaying completed
  orders for 3/15/23 and morning of 3/16/23 in the closed orders tab.
  Rest assured, systems will return to normal states and full data will be displayed with 12-24hrs.
  <br/>
  <button mat-button color="warn" (click)="ack()">Acknowledge</button>

</p>
