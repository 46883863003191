// core.module.ts
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { StoreService } from './_services/store.service';
import {BusinessService} from './business.service';
import {UnreadService} from './_services/unread-message.service';
import {AuthenticationService} from './_services/authentication.service';
import {PermissionService} from './_services/permission.service';
import {TerminalStatusService} from './_services/terminal-status.service';
import {RefreshService} from './_services/refresh.service';
import {OrderWebsocketService} from './_services/websocket.service';
import {MqttService} from 'ngx-mqtt';
import {DigitalDisplayService} from './_services/digital-display.service';
import {FrontFacingMqttService} from './_services/front-facing-mqtt.service';
import {RxdbService} from './_services/rxdb.service';
import {HttpClientModule} from '@angular/common/http';
import {UserService} from './user.service';
import {OrderService} from './_services/order.service';
import {CookieService} from 'ngx-cookie-service';
import {ReceiptService} from './_services/receipt.service';
import {PaymentService} from './_services/payment.service';
import {DashboardService} from './_services/dashboard.service';
import {FormUtils} from './_helpers/form-utils';
import {UploadService} from './_services/upload.service';
import {MenuService} from './_services/menu.service';
import {MenuServiceV2} from './_services/menu-v2.service';
import {LoaderService} from './_services/loader.service';
import {HostedMenuService} from './_services/hosted-menu.service';
import {AttendantSettingsService} from './_services/attendant-settings.service';
import {CounterService} from './_services/counter.service';
import {PrinterSettingsService} from './_services/printer-settings.service';
import {POSService} from './_services/pos-service';
import {PendingChangesGuard} from './_guards/changes.guard';
import {OccasionDetailService} from './_services/occasion-detail.service';
import {TerminalService} from './_services/terminal.service';
import {CurrentOrderService} from './_services/current-order.service';
import {POSOrderTabChangeService} from './_services/pos-order-tab-change.service';
import {POSMenuTabChangeService} from './_services/pos-menu-tab-change.service';
import {POSSummaryToggleService} from './_services/pos-summary-toggle.service';
import {OpenOrderToggleService} from './_services/open-order-toggle.service';
import {OrderTypeTabChangeService} from './_services/order-type-tab-change.service';
import {LoyaltyPointsService} from './_services/loyalty-points.service';
import {POSStreamService} from './_services/pos-stream.service';
import {LinkMqttService} from './_services/mqtt.service';
import {CampaignService} from './_services/campaign.service';
import {ErrorLoggingService} from './_services/error-logging.service';
import {NMISettlementService} from './_services/nmi-settlement.service';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {DraftOrderService} from './_services/draft-order.service';
import {PaymentGatewayService} from './_services/payment-gateway.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    StoreService,
    UnreadService,
    AuthenticationService,
    PermissionService,
    TerminalStatusService,
    RefreshService,
    OrderWebsocketService,
    MqttService,
    DigitalDisplayService,
    FrontFacingMqttService,
    RxdbService,
    HttpClientModule,
    UserService,
    OrderService,
    CookieService,
    ReceiptService,
    PaymentService,
    DashboardService,
    FormUtils,
    UploadService,
    MenuService,
    MenuServiceV2,
    LoaderService,
    HostedMenuService,
    AttendantSettingsService,
    CounterService,
    PrinterSettingsService,
    POSService,
    PendingChangesGuard,
    OccasionDetailService,
    TerminalService,
    PermissionService,
    CurrentOrderService,
    RefreshService,
    POSOrderTabChangeService,
    POSMenuTabChangeService,
    POSSummaryToggleService,
    OpenOrderToggleService,
    OrderTypeTabChangeService,
    LoyaltyPointsService,
    POSStreamService,
    TerminalStatusService,
    OrderWebsocketService,
    LinkMqttService,
    DigitalDisplayService,
    FrontFacingMqttService,
    CampaignService,
    ErrorLoggingService,
    NMISettlementService,
    DraftOrderService,
    PaymentGatewayService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
