<form (ngSubmit)="onSubmit()" [formGroup]="form" class="fixActionRow">
  <h2 mat-dialog-title>
    Set Custom Auto Batch Time.
  </h2>
  <mat-dialog-content id="campaign-dialog">
    <mat-form-field class="full-width" mat-focused>
      <mat-select formControlName="time" [(ngModel)]="selected">
        <mat-option *ngFor="let hour of hours" [value]="hour.value">{{hour.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="apply-btn-colr primary-button">Apply</button>
  </mat-dialog-actions>
</form>