import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from '../../../../_models/store';
import {FormBase} from '../dynamic-form-control/form-base';
import {days} from '../../../../constants';
import {StoreService} from '../../../../_services/store.service';
import {FormUtils} from '../../../../_helpers/form-utils';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-occasion-availability',
  templateUrl: './occasion-availability.component.html',
  styleUrls: ['./occasion-availability.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OccasionAvailabilityComponent implements OnInit {
  @Input() formControls: FormBase<any>[] = [];
  @Input() editControlKeys: Array<string>;
  @Input() store: Store;
  @Input() form: UntypedFormGroup;

  workinHours: any = [];
  days: string[];
  hours;
  error;
  storeID: any;
  workinToggle: any;
  occasionChoose: any;
  isLoading = true;
  toggleValue: any;
  worki: any = [];
  toggleAllSet: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private storeService: StoreService,
    private formUtils: FormUtils,
    public dialogRef: MatDialogRef<OccasionAvailabilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hours = formUtils.buildTimeOptions();
  }

  ngOnInit() {
    this.storeID = localStorage.getItem('currentStore');
    this.occasionChoose = this.data.occcasionname;
    this.days = days;

    this.storeService.getOccasionTime(this.storeID, this.occasionChoose).subscribe((data: any) => {

      this.workinHours = data;
      this.workinToggle = data;
      for (let i = 0; i <= 6; i++) {
        this.worki.push(this.workinToggle[i].is_available);
      }
      this.toggleAllSet = this.worki;
      this.form = this.getFormData(data);
      this.isLoading = false;
    })
  }

  getFormData(workingHours) {
    let formgroup = {}
    Object.keys(workingHours).forEach((k, i) => {
      formgroup[k] = new UntypedFormGroup({
        store_id: new UntypedFormControl(this.storeID),
        occasion_name: new UntypedFormControl(this.occasionChoose),
        is_available: new UntypedFormControl(workingHours[k].is_available),
        hours: this.getHourControls(workingHours[k]['hours'])
      })
    })

    return new UntypedFormGroup(formgroup)
  }

  getHourControls(hours) {

    if (hours.length === 0) {
      hours = [{ from_hour: 10, from_min: 0, to_hour: 22, to_min: 0 }]
    }
    return new UntypedFormArray(hours.map(h => {
      let from12 = this.formUtils.get12HourValue(h.from_hour, h.from_min)
      let fromOptions = {
        hour: h.from_hour,
        min: h.from_min,
        key: from12.replace(' am', '').replace(' pm', ''),
        value: from12
      }

      let to12 = this.formUtils.get12HourValue(h.to_hour, h.to_min)
      let toOptions = {
        hour: h.to_hour,
        min: h.to_min,
        key: to12.replace(' am', '').replace(' pm', ''),
        value: to12
      }


      return this.formBuilder.group({
        from: [h.from_time, Validators.required],
        to: [h.to_time, Validators.required],
      })
    }))
  }

  hourCompare(left, right) {
    return left.value === right.value;
  }

  // getToggleValue(event,i) {
  //   this.toggleValue = event.checked;
  //   this.form.value[i].is_available = this.toggleValue;
  //   console.log('togglee ');


  //   // if(i==0) {
  //   //   this.form.value[0].is_available = this.toggleValue;
  //   // }else if(i==1) {
  //   //   this.form.value[1].is_available = this.toggleValue;
  //   // }else if(i==2) {
  //   //   this.form.value[2].is_available = this.toggleValue;
  //   // }else if(i==3) {
  //   //   this.form.value[3].is_available = this.toggleValue;
  //   // }else if(i==4) {
  //   //   this.form.value[4].is_available = this.toggleValue;
  //   // }else if(i==5) {
  //   //   this.form.value[5].is_available = this.toggleValue;
  //   // }else if(i==6) {
  //   //   this.form.value[6].is_available = this.toggleValue;
  //   // }
  // }

  onSubmit() {
    this.storeService.saveDeliveryHours(this.form.value).subscribe(data => {
      this.dialogRef.close()
    })
  }

}

