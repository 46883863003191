import { Component, OnInit, ViewChild } from '@angular/core';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { AddCategoriesDialogComponent } from '../add-categories-dialog/add-categories-dialog.component';
import { AddMasterCategoryDialogComponent } from '../add-master-category-dialog/add-master-category-dialog.component';
import { CategoryDialogComponent } from '../category-dialog/category-dialog.component';
import { MasterCategoryDialogComponent } from '../master-category-dialog/master-category-dialog.component';
import { ScreenDeleteComponent } from '../screen-delete/screen-delete.component';
import { SubCategoryDialogComponent } from '../sub-category-dialog/sub-category-dialog.component';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { StoreService } from 'src/app/_services/store.service';
import { Store } from 'src/app/_models/store';
import { MenuCategorgDialogComponent } from '../menu-categorg-dialog/menu-categorg-dialog.component';
import {
  AllStoreEditMasterCategoryDialogComponent
} from '../all-store-edit-master-category-dialog/all-store-edit-master-category-dialog.component';
import { SubMenuCategoriesDialogComponent } from '../sub-menu-categories-dialog/sub-menu-categories-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDefaultMenuDialogComponent } from './update-default-menu-dialog/update-default-menu-dialog.component';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-default-menu-categories',
  templateUrl: './default-menu-categories.component.html',
  styleUrls: ['./default-menu-categories.component.scss'],
})
export class DefaultMenuCategoriesComponent {
  foods: Food[] = [
    { value: "steak-0", viewValue: "Steak" },
    { value: "pizza-1", viewValue: "Pizza" },
    { value: "tacos-2", viewValue: "Tacos" },
  ];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns: string[] = [
    "api_category_name",
    "status",
    "priority",
    "screen", "color", "action"
  ];

  // dataSource = [
  //   {canvas: 1, screen_id: 'Hydrogen', content_type: 1.0079, screen_url: 'H'},
  //   {canvas: 2, screen_id: 'Helium', content_type: 4.0026, screen_url: 'He'},
  // ];
  dataSource: MatTableDataSource<PeriodicElement>;
  panelOpenState = false;
  storeId: string;
  allCategorys: any;
  checkCategorie: any;
  subCategoryListData: any;
  // menuGroupsOrdered;
  store: Store;
  menuGroupOrder;
  isLoading = false;
  storeType: any;
  constructor(
    public dialog: MatDialog,
    private menuService: MenuServiceV2,
    public storeService: StoreService,
    private digitalDisplayService: DigitalDisplayService
  ) { }

  ngOnInit() {
    this.menuGroupOrder = []
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");
    this.getMasterCategory();
    if (this.storeType == "Leaflogix") {
      this.checkCategories();
      this.subcategoryList();
    }

    // this.storeService.current.subscribe(store => {
    //   if (store) {
    //     this.store = store;
    //     this.menuGroupReset();
    //   }
    // })
  }
  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }

  subcategoryList() {
    this.digitalDisplayService.SubCategoriesOtionList(this.storeId).subscribe(data => {
      this.subCategoryListData = data.data
    })
  }
  // menuGroupReset(){
  //   this.isLoading = true;
  //   this.menuService.getStoreMenuGroups(this.store.id).subscribe(data => {
  //     this.menuGroupsOrdered = data
  //     this.menuGroupsOrdered.forEach(mg => this.menuGroupOrder.push(mg.id));
  //     console.log(this.menuGroupOrder);
  //     this.isLoading = false;
  //   })
  // }
  // setMenuGroup(event){
  //   if(event){
  //     console.log(event);
  //     let mg = this.menuGroupsOrdered.find(m => m.id === event.id);
  //     mg.name = event.name;
  //     mg.subgroup = event.subgroup;
  //   }
  // }
  getMasterCategory() {
    this.isLoading = true;
    this.digitalDisplayService
      .getdefaultMenuCategorie(this.storeId)
      .subscribe((data) => {
        this.allCategorys = data.data;
        this.isLoading = false;
      });
  }
  // addMasterCategoryDialog() {
  //   const isMobile = window.innerWidth <= 599;
  //   const dialogRef = this.dialog.open(AddMasterCategoryDialogComponent, {
  //     data: {
  //       data: this.subCategoryListData,
  //       store: 'Store1',
  //     },
  //     width: isMobile ? '100vw' : '550px',
  //     height: isMobile ? '100vh' : '',
  //     maxWidth: isMobile ? '100vw' : '80vw',
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     // if (result && result.yesSubmit == "YesSubmit") {
  //     this.getMasterCategory();
  //     // }
  //   });
  // }
  // allStoreMasterCategoryDialog(a) {
  //   const isMobile = window.innerWidth <= 599;
  //   const dialogRef = this.dialog.open(AddMasterCategoryDialogComponent, {
  //     data: a,
  //     width: isMobile ? '100vw' : '550px',
  //     height: isMobile ? '100vh' : '',
  //     maxWidth: isMobile ? '100vw' : '80vw',
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     // if (result && result.yesSubmit == "YesSubmit") {
  //     this.getMasterCategory();
  //     // }
  //   });
  // }
  // addCategoryDialog(e) {
  //   const isMobile = window.innerWidth <= 599;
  //   const dialogRef = this.dialog.open(AddCategoriesDialogComponent, {
  //     data: {
  //       'is_value': e
  //     },
  //     width: isMobile ? '100vw' : '550px',
  //     height: isMobile ? '100vh' : '',
  //     maxWidth: isMobile ? '100vw' : '80vw',
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result && result.yesSubmit == "YesSubmit") {
  //       this.getMasterCategory();
  //     }
  //   });
  // }

  // deleteCategory(id) {
  //   const dialogRef = this.dialog.open(ScreenDeleteComponent, {
  //     data: {
  //       category: 1,
  //       id: id,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result && result.yesSubmit == "YesSubmit") {
  //       this.getMasterCategory();
  //     }
  //   });
  // }
  deleteMasterCategory(id) {
    const dialogRef = this.dialog.open(UpdateDefaultMenuDialogComponent, {
      data: {
        category: 1,
        id: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }


  setNewMenuCategory(id) {
    const dialogRef = this.dialog.open(UpdateDefaultMenuDialogComponent, {
      data: {
        category: 3,
        id: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }

  allStoreEditMasterCategory(first) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(UpdateDefaultMenuDialogComponent, {
      data: {
        category: 2,
        hide_mastercategory: first.hide_mastercategory,
        id: first.id,
        name: first.name,
        new_screen: first.new_screen,
        priority: first.priority,
        store: first.store,
        modifiergroups: first.modifiergroups,
        categorys: first.subcategorys,
        font_color: first.font_color,
        emoji: first.emoji,
        font_size: first.font_size,
        alignment: first.alignment,
        modifiergroups_subcat_alignment: first.modifiergroups_subcat_alignment,
        modifiergroups_subcat_font_size: first.modifiergroups_subcat_font_size,
        modifiergroups_subcat_font_color: first.modifiergroups_subcat_font_color,
        modifiergroups_subcat_background_color: first.modifiergroups_subcat_background_color
      },
      width: isMobile ? '100vw' : '43vw',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }


  editMasterCategory(first) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(MasterCategoryDialogComponent, {
      data: {
        hide_category: first.hide_category,
        id: first.id,
        name: first.name,
        new_screen: first.new_screen,
        priority: first.priority,
        store: first.store,
        categorys: first.categorys,
        font_color: first.font_color,
        emoji: first.emoji
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }


  editCategoryDialog(secound) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      data: {
        hide_category: secound.hide_category,
        id: secound.id,
        name: secound.name,
        new_screen: secound.new_screen,
        priority: secound.priority,
        store: secound.store,
        subcategorys: secound.subcategorys,
        font_color: secound.font_color,
        hide_product_weight: secound.hide_product_weight,
        emoji: secound.emoji
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }
  editSubCategoryDialog(subcat) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(SubCategoryDialogComponent, {
      data: {
        display_name: subcat.display_name,
        id: subcat.id,
        new_screen: subcat.new_screen,
        priority: subcat.priority,
        hide_category: subcat.hide_category,
        store: subcat.store,
        font_color: subcat.font_color,
        emoji: subcat.emoji

      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }
  editmenuCategoryDialog(subcat) {
    const dialogRef = this.dialog.open(SubMenuCategoriesDialogComponent, {
      data: {
        display_name: subcat.display_name,
        id: subcat.id,
        new_screen: subcat.new_screen,
        priority: subcat.priority,
        hide_category: subcat.hide_category,
        store: subcat.store,
        font_color: subcat.font_color,
        emoji: subcat.emoji
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getMasterCategory();
      }
    });
  }

  checkCategories() {
    this.digitalDisplayService
      .getCheckCategories(this.storeId)
      .subscribe((data: any) => {
        console.log("response check categories", data);
        this.checkCategorie = data;
        if (data.run_api == 1) {
          console.log("if 1");
          this.addSubCategory();
        }
      });
  }

  addSubCategory() {
    this.digitalDisplayService
      .subCategoriesAdd(this.storeId)
      .subscribe((data: any) => {
        console.log("A A A A A A", data);
      });
  }

}


export interface PeriodicElement {
  api_category_name: any;
  status: any;
  priority: any;
  screen: any;
  color: any;
  action: any;

}
