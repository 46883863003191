import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ReportDataSource} from 'src/app/_datasources/report-datasource';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';
import * as moment from 'moment';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {saveAs as importedSaveAs} from 'file-saver';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, AfterViewInit {

  startDate = moment().startOf('year').toDate();
  endDate = moment().toDate();
  displayedColumns = ["month", "orders", "sales", "action"]
  dataSource:ReportDataSource
  store:Store;
  start_date: any;
  end_date: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  isLoading: boolean = false;

  constructor(private storeService:StoreService,
    public datePipe: DatePipe,
    public dialog: MatDialog,private router: Router) { }

  ngOnInit() {
    this.dataSource = new ReportDataSource(this.storeService)
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store)
        this.dataSource.loadReports(+store.id, this.startDate, this.endDate);
      }
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadReports()))
      .subscribe();
  }

  loadReports() {
    this.dataSource.loadReports(+this.store.id, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  searchOrders(any:any){
    this.loadReports()
  }

  onRowClicked(row){}

  reset(){
    this.startDate = moment().startOf('year').toDate();
    this.endDate = moment().toDate();
    this.loadReports();

  }

  vieworDownload(element, e) {
    if (e == 1) {
      this.download(element, element.start_date, element.end_date);
      
    } else if (e == 2) {
      this.view(element, element.start_date, element.end_date);
    }
  }

  download(element, start, end){
    this.isLoading = true;
    this.storeService.getStoreDetailedReportsTSV(this.store.id, start, end).subscribe(data => {
      importedSaveAs(data, `${this.store.store_name}-labrador-statement-${start}-${end}.tsv`);
      this.isLoading = false;
    });
  }

  view(element, start, end){
    /* Open In a New Window */
    // const url =`${window.location.origin}/#/monthly/${this.store.id}/${start}/${end}`
    // window.open(url);

    const url = `/monthly/${this.store.id}/${start}/${end}`;
    this.router.navigate([url]);
  }
}
