import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-restaurant-images-dialog',
  templateUrl: './restaurant-images-dialog.component.html',
  styleUrls: ['./restaurant-images-dialog.component.scss']
})
export class RestaurantImagesDialogComponent implements OnInit {
  isLoading = false;
  addRestaurantForm: UntypedFormGroup;
  storeId: string;
  file: File;
  addEditUpdate: any;
  editMediaForm: UntypedFormGroup;
  rowId: any;
  subcategory_list_data: any;
  imageWidth: Array<any> = [];
  showModifierGroupSelete: any = [];
  ImgErrorShow: any = '';
  maxFileSize: number = 30;
  constructor(
    public fb: UntypedFormBuilder,
    public digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<RestaurantImagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.fileSizeLimit();
    this.subcategoryListAll();
    this.ModifierGroupListAll();
    for (let i = 1; i <= 100; i++) {
      this.imageWidth.push(i)
    }
    if (this.data.dialog == 1) {
      this.addRestaurantForm = this.fb.group({
        store: [this.storeId],
        name: ["", Validators.required],
        position: ["", Validators.required],
        category: [""],
        modifiergroups: [""],
        image_width: ["", Validators.required],
        image: ["", Validators.required],
        image_alignment: [""],
      });
    }
    else if (this.data.dialog == 2) {
      this.rowId = this.data.data.id;
      const editData = this.data.data;
      this.editMediaForm = this.fb.group({
        store: [this.storeId],
        name: [editData.name],
        position: [editData.position],
        category: [editData.category],
        modifiergroups: [editData.modifiergroups],
        image_width: [editData.image_width],
        image: [""],
        image_alignment: [editData.image_alignment],
      });
    } else if (this.data.dialog == 3) {
      this.rowId = this.data.data.id;
    }

    this.addEditUpdate = this.data.dialog;
  }
  subcategoryListAll() {
    this.digitalDisplayService
      .SubCategoriesOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.subcategory_list_data = data.data;
      });
  }
  ModifierGroupListAll() {
    this.digitalDisplayService
      .ModifierGroupOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.showModifierGroupSelete = data.data;
      });
  }
  addRestaurantFormSubmit() {
    const validationForm = this.addRestaurantForm.status;
    if (validationForm == "VALID") {
      const formData = new FormData();
      formData.append("store", this.storeId);
      formData.append("name", this.addRestaurantForm.value.name);
      formData.append("position", this.addRestaurantForm.value.position);
      formData.append("category", this.addRestaurantForm.value.category);
      formData.append("modifiergroups", this.addRestaurantForm.value.modifiergroups);
      formData.append("image_width", this.addRestaurantForm.value.image_width);
      formData.append("image_alignment", this.addRestaurantForm.value.image_alignment || 'center');
      if (this.file != null) {
        formData.append("image", this.file, this.file.name);
      }
      this.isLoading = true;
      this.digitalDisplayService.addrestauran(formData).subscribe((data: any) => {
        if (data.msg) {
          console.log('check',data.msg);
          this.ImgErrorShow = data.msg;
          this.isLoading = false;
        }
        else{
          const resData = { yesSubmit: "YesSubmit" };
          this.isLoading = false;
          this.dialogRef.close(resData);
        }
      });
    }
  }

  editMediaFormSubmit() {
    const formData = new FormData();
    formData.append("store", this.storeId);
    formData.append("name", this.editMediaForm.value.name);
    formData.append("position", this.editMediaForm.value.position);
    formData.append("category", this.editMediaForm.value.category);
    formData.append("modifiergroups", this.editMediaForm.value.modifiergroups);
    formData.append("image_width", this.editMediaForm.value.image_width);
    formData.append("image_alignment", this.editMediaForm.value.image_alignment);
    console.log('formData', formData);
    if (this.file != null) {
      formData.append("image", this.file, this.file.name);
    }
    this.isLoading = true;
    this.digitalDisplayService.editrestauran(formData, this.rowId).subscribe((data: any) => {
      if (data.msg) {
        console.log('check',data.msg);
        this.ImgErrorShow = data.msg;
        this.isLoading = false;
      }
      else{
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      }
    });

  }

  restaurantDelete() {
    this.isLoading = true;
    this.digitalDisplayService.deleterestauran(this.rowId).subscribe((data: any) => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;
      this.dialogRef.close(resData);
    });
  }

  fileSizeLimit() {
    this.digitalDisplayService.fileSizeLimit(this.storeId).subscribe((data: any) => {
      this.maxFileSize = data.data.file_size;
    });
  }

  onChange(event) {
    this.file = event.target.files[0];
    const maxFileSizeInBytes = this.maxFileSize * 1024 * 1024;
    if (this.file) {
      if (this.file.size > maxFileSizeInBytes) {
        this.ImgErrorShow = `File size exceeds the maximum limit of ${this.maxFileSize}MB.`;
        this.file = null;
      } else {
        this.ImgErrorShow = '';
      }
    }
  }
}
