import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-password-rest-confirm',
  templateUrl: './password-rest-confirm.component.html',
  styleUrls: ['./password-rest-confirm.component.scss']
})
export class PasswordRestConfirmComponent implements OnInit {
  loginForm: UntypedFormGroup;
  error = '';
  submitted:boolean;
  loading:boolean;
  uid:string;
  token:string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService:AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('uid');
    this.token = this.route.snapshot.paramMap.get('token');
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
  this.loading = true;
  this.authService.resetPasswordConfirm(this.uid, this.token, this.f.password.value, this.f.confirmPassword.value)
  .pipe(first())
  .subscribe(data => {
    this.router.navigate(['login'])
  },
  error => {
    this.error = "Invalid password"
    this.submitted = false
  })

  }

}
