<mat-form-field class="w-full" appearance="fill">
  <mat-label>{{controlName}}</mat-label>
  <mat-chip-grid #chipGrid aria-label="Fruit selection">
    <mat-chip-row *ngFor="let entity of selectedEntities" (removed)="remove(entity)">
      {{entity[key]}}
      <button matChipRemove [attr.aria-label]="'remove ' + entity">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="New Fruit..." #fruitInput [formControl]="ctrl"
         [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         (matChipInputTokenEnd)="add($event)"/>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
    <mat-option *ngFor="let fruit of filtered | async" [value]="fruit">
      {{fruit[key]}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
