import {Component, OnInit, ViewChild} from '@angular/core';
import {RedeemHistoryDataSource} from 'src/app/_datasources/offer-order-datasource';
import {Store} from 'src/app/_models/store';
import {BusinessService} from 'src/app/business.service';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {StoreService} from 'src/app/_services/store.service';
import {OrderService} from 'src/app/_services/order.service';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RedeemHistory} from 'src/app/_models/order';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-offers-message-table',
  templateUrl: './offers-message-table.component.html',
  styleUrls: ['./offers-message-table.component.scss']
})
export class OffersMessageTableComponent implements OnInit {
  displayedColumns: string[] = ['id', 'redeemed_at', 'phone_number', 'offer_id', 'amount', 'balance','gift_card_id', 'redeeme_at', 'payment_status', 'action'];
  dataSource :RedeemHistoryDataSource;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  store:Store;
  search;
  startDate;
  endDate;

  constructor(
    private businessService:BusinessService,
    private receiptService:ReceiptService,
    private storeService:StoreService,
    private orderService:OrderService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new RedeemHistoryDataSource(this.businessService)
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store)
        this.dataSource.loadRedeemHistory(+store.id);
      }
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadRedeemHistory()))
      .subscribe();
  }

  loadRedeemHistory() {
    this.dataSource.loadRedeemHistory(+this.store.id, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  searchOrders(any:any){
    this.loadRedeemHistory()
  }

  onRowClicked(row){
    let botOrder = Object.assign(new RedeemHistory(), row)
    this.receiptService.changeBotOrder(botOrder);
  }

  reset(){
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.loadRedeemHistory();
  }

  undo(element){
    const isMobile = window.innerWidth <= 768;
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      width: isMobile ?'100vw' : '',
      maxWidth: isMobile ? '100vw' : '100vw',
      disableClose: true,
      // width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to undo redemption "+element.redeem_id+"?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.businessService.undoRedemption(element).subscribe(response => {
          this.ngOnInit()
        })
      }
    });
    // if(confirm("Are you sure to undo redemption "+element.redeem_id+"?")) {
    //   console.log(element);

    //   this.businessService.undoRedemption(element).subscribe(response => {
    //     this.ngOnInit()
    //   })
    // }
  }
}
