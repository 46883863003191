import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {BotOrderDataSource} from 'src/app/_datasources/bot-order-datasource';
import {BusinessService} from 'src/app/business.service';
import {takeUntil, tap} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {BotOrder} from 'src/app/_models/order';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {OrderService} from 'src/app/_services/order.service';
import {RefundDialogComponent} from '../refund-dialog/refund-dialog.component';
import {AdjustDialogComponent} from '../adjust-dialog/adjust-dialog.component';
import * as moment from 'moment';
import {TransactionDialogComponent} from '../transaction-dialog/transaction-dialog.component';
import {TerminalService} from 'src/app/_services/terminal.service';
import {AddTipDialogComponent} from '../add-tip-dialog/add-tip-dialog.component';
import {CancelDialogComponent} from '../cancel-dialog/cancel-dialog.component';
import {POSService} from 'src/app/_services/pos-service';
import {PinConfirmComponent} from '../pin-confirm/pin-confirm.component';
import {PermissionService} from '../../_services/permission.service';
import {Permission} from '../../_models/permission';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-message-table',
  templateUrl: './message-table.component.html',
  styleUrls: ['./message-table.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class MessageTableComponent implements AfterViewInit, OnInit, OnDestroy {
  displayedColumns: string[] = null;
  dataSource: BotOrderDataSource;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  store: Store;
  search;
  startDate;
  endDate;
  myOrder = false;
  selectedRow: boolean;
  interval;
  private unsubscribe: Subject<null> = new Subject();
  @Output() openSideNav: EventEmitter<boolean> = new EventEmitter();
  currentHash = localStorage.getItem('order_hash');
  terminalId = localStorage.getItem('selectedTerminal');
  permission: Permission;
  pinPermission: Permission;

  constructor(
    private businessService: BusinessService,
    private receiptService: ReceiptService,
    private storeService: StoreService,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private terminalService: TerminalService,
    private posService: POSService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit() {
    const terminal_id = localStorage.getItem('selectedTerminal');
    this.terminalId = terminal_id;
    this.currentHash = localStorage.getItem('order_hash');
    this.dataSource = new BotOrderDataSource(this.businessService);
    this.permissionService.pinCurrentOperator.pipe(takeUntil(this.unsubscribe)).subscribe((permission) => {
      this.permission = permission;
    });
    this.storeService.current.pipe(takeUntil(this.unsubscribe)).subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        if (this.store.hasModule('POS')) {
          this.displayedColumns = ['updated_at', 'readyby' , 'order_counter', 'source', 'occasion', 'table_id', 'terminal_id', 'cashier_id' , 'payment_type', 'total',  'payment_status', 'action'];
        } else {
          this.displayedColumns = ['updated_at', 'order_counter', 'source', 'occasion', 'payment_type', 'total',  'payment_status', 'action'];
        }
        if (this.store.getFeatureFlag('enable_order_ready') && !this.displayedColumns.includes('ready')) {
          this.displayedColumns.unshift('ready');
        }
        this.loadBotOrders();
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.loadBotOrders();
          }, 30000);
        }
      }
    });


    if (this.currentHash) {
      this.orderService.getOrderByHash(this.currentHash)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        // this.dataSource = result.bot_order
        console.log('result', result.bot_order);
      });
    }

    // this.terminalService.current.subscribe(terminalId => this.terminalId = terminalId)
  }

  ngOnDestroy() {
    console.log('destroy');
    if (this.interval) {
      clearInterval(this.interval);
    }
  }


  ngAfterViewInit() {
    this.sort.sortChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadBotOrders())).pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  loadBotOrders() {
    console.log('my order', this.myOrder, localStorage.getItem('posLoggedUser'));

    this.dataSource.loadBotOrders(+this.store.id, this.search || '', this.startDate, this.endDate,
      this.sort.active || 'order_counter',
      this.sort.direction || 'desc', this.paginator.pageIndex, this.paginator.pageSize,
      this.myOrder ? localStorage.getItem('posLoggedUser') : '');
  }

  onRowClicked(row) {

    if (!this.selectedRow) {
      this.selectedRow = row;
    } else {
      this.selectedRow = row;
    }

    const botOrder = Object.assign(new BotOrder(), row);
    this.receiptService.changeBotOrder(botOrder);
    this.openSideNav.emit(true);
  }

  reset() {
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.loadBotOrders();
  }

  print(botOrder: BotOrder) {
    const terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : 'NA';
    this.orderService.printOrder(botOrder.order_hash, terminalName).pipe(takeUntil(this.unsubscribe)).subscribe(
      data => {
        this.snackBar.open('Order printed successfully', 'OK', {duration: 2000});
      },
      error => {
        this.snackBar.open('Unable to print order', 'OK', {duration: 2000});
      });
  }

  adjust(botOrder: BotOrder) {
    this.receiptService.changeBotOrder(botOrder);
    const dialogRef = this.dialog.open(AdjustDialogComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'mobile-pos',
      data: {botOrder, store: this.store, terminalId: this.terminalId}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.loadBotOrders();
    });
  }

  refund(botOrder: BotOrder) {
    this.receiptService.changeBotOrder(botOrder);
    const dialogRef = this.dialog.open(RefundDialogComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'refund-mobile',
      data: {botOrder, store: this.store, terminalId: this.terminalId}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.loadBotOrders();
    });
  }

  cancel(botOrder: BotOrder) {
    const isMobile = window.innerWidth <= 599;
    this.receiptService.changeBotOrder(botOrder);
    const dialogRef = this.dialog.open(CancelDialogComponent, {
      width: isMobile ? '100vw' : '520px',
      height: isMobile ? '100vh' : '90vh',
      maxWidth: isMobile ? '100vw' : '80vw',
      panelClass: 'mobile-pos',
      data: {botOrder, store: this.store, terminalId: this.terminalId}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.loadBotOrders();
    });
  }

  checkPOSPermissionAdjust(botOrder: BotOrder) {
    if (!this.store.hasModule('POS')) {
      this.adjust(botOrder);
      return;
    }
    if (this.permission.hasPermission('POS_FUNCTIONS', 'ADJUST')) {
      this.adjust(botOrder);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(resultPer => {
        this.pinPermission = resultPer.permission;
        if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'ADJUST')) {
          this.adjust(botOrder);
          this.orderService.updateDescription(botOrder.id, {description: resultPer.description}).pipe(takeUntil(this.unsubscribe)).subscribe((updated) => {
            console.log('________________', updated);
          });
        } else {
          this.alertPopup('This User/Manager does not have the required permission to perform this operation!' );
          // alert('This User/Manager does not have the required permission to perform this operation!' );
        }
      });
    }
  }

  checkPOSPermissionRefund(botOrder: BotOrder) {
    if (!this.store.hasModule('POS')) {
      this.refund(botOrder);
      return;
    }
    if (this.permission.hasPermission('POS_FUNCTIONS', 'REFUND')) {
      this.refund(botOrder);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(resultPer => {
        this.pinPermission = resultPer.permission;
        if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'REFUND')) {
          this.refund(botOrder);
          this.orderService.updateDescription(botOrder.id, {description: resultPer.description}).pipe(takeUntil(this.unsubscribe)).subscribe((updated) => {
            console.log('________________', updated);
          });
        } else {
          this.alertPopup('This User/Manager does not have the required permission to perform this operation!' );
          // alert('This User/Manager does not have the required permission to perform this operation!' );
        }
      });
    }
  }

  checkPOSPermissionCancel(botOrder: BotOrder) {
    if (this.permission.hasPermission('POS_FUNCTIONS', 'CANCEL')) {
      this.cancel(botOrder);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(resultPer => {
        this.pinPermission = resultPer.permission;
        if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'CANCEL')) {
          this.cancel(botOrder);
          this.orderService.updateDescription(botOrder.id, {description: resultPer.description}).pipe(takeUntil(this.unsubscribe)).subscribe((updated) => {
            console.log('________________', updated);
          });
        } else {
          this.alertPopup('This User/Manager does not have the required permission to perform this operation!' );
          // alert('This User/Manager does not have the required permission to perform this operation!' );
        }
      });
    }
  }

  addTip(botOrder: BotOrder) {
    this.receiptService.changeBotOrder(botOrder);
    const dialogRef = this.dialog.open(AddTipDialogComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'mobile-add-tip',
      data: {botOrder, store: this.store, terminalId: this.terminalId}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.loadBotOrders();
    });
  }

  transactions(botOrder: BotOrder) {
    this.receiptService.changeBotOrder(botOrder);
    const dialogRef = this.dialog.open(TransactionDialogComponent, {
      width: '700px',
      height: '600px',
      data: {botOrder, store: this.store}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.loadBotOrders();
    });
  }

  getFormattedDate(date) {
    date = moment(date).toDate();

    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const dateFormat = month + day + year;
    return dateFormat;
  }

  checkPOSStatus(botOrder: BotOrder) {
    console.log(botOrder);
    this.posService.posStatus(botOrder.source_id, {
      order_hash: botOrder.order_hash,
      date: this.getFormattedDate(botOrder.submitted_at)
    }).pipe(takeUntil(this.unsubscribe))
    .subscribe((status) => {
      this.alertPopup(`Status: ${status.result_code} Message: ${status.message}`);
        // alert(`Status: ${status.result_code} Message: ${status.message}`);
    });

  }

 formatDate(date) {
   return moment(date, 'HH:mm A MMM DD, YYYY').format('HH:mmA MM/DD/YYYY');
 }

  ready(botOrder: BotOrder) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure order is ready?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result) {
        this.orderService.orderReady(botOrder.order_hash).pipe(takeUntil(this.unsubscribe)).subscribe(
          data => {
            botOrder.ready_notified = true;
            this.snackBar.open('Text notification sent successfully', 'OK', {duration: 2000});
          },
          error => {
            this.snackBar.open('Unable to send text notification', 'OK', {duration: 2000});
          });
        } else {
          botOrder.ready_notified = false;
        }
    });
  }
  // if(confirm('Are you sure order is ready?')) {
  //   this.orderService.orderReady(botOrder.order_hash).subscribe(
  //     data => {
  //       botOrder.ready_notified = true;
  //       this.snackBar.open('Text notification sent successfully', 'OK', {duration: 2000})
  //     },
  //     error => {
  //       this.snackBar.open('Unable to send text notification', 'OK', {duration: 2000})
  //     })
  //   } else {
  //     botOrder.ready_notified = false;
  //   }
  // }

  alertPopup(msg) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
    });
  }
}
