import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../_services/dashboard.service';
import {StoreService} from '../_services/store.service';
import {Store} from '../_models/store';
import {AuthenticationService} from '../_services/authentication.service';
import {ChangePasswordComponent} from '../account/change-password/change-password.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private dashboardService:DashboardService,
    private storeService:StoreService,
    public dialog: MatDialog,
    private authService:AuthenticationService) { }

  store:Store;
  orderVolumeTime = 'month';
  orderVolumeOptions = ['hour', 'day', 'month']
  orderVolumeData;
  totalUserTime = 'month';
  totalUserOptions = ['day', 'week', 'month']
  totalUserData;

  orderVolumeChartOptions = {
    title: "Order Volume",
    chartArea: {width: '80%'},
    legend: {position: 'none'},
  };

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if(store){
        this.store = store;
      }
    });

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))

    if(currentUser['lastLogin'] == null){
      let dialogRef = this.dialog.open(ChangePasswordComponent, {data: {},'width': '500px'} as any)
    }
  }
}
