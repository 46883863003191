<mat-tab-group>
  <mat-tab label="SETTINGS">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="50%" fxFlex="50%">
          <div class="card">
            <app-settings></app-settings>
          </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="ACTIONS">
    <div class="coloredContainerX pad-2 xs:!p-2.5" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div class="rounded-sm bg-white border border-gray-300 xs:!overflow-x-scroll" fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="80%">
        <div class="card">
          <app-actions></app-actions>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>


