import { Component, OnInit, ViewChild } from '@angular/core';
import { RedeemHistoryDataSource } from 'src/app/_datasources/offer-order-datasource';
import { Store } from 'src/app/_models/store';
import { BusinessService } from 'src/app/business.service';
import { ReceiptService } from 'src/app/_services/receipt.service';
import { StoreService } from 'src/app/_services/store.service';
import { OrderService } from 'src/app/_services/order.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RedeemHistory } from 'src/app/_models/order';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { CashierRedemptionsDialogComponent } from './cashier-redemptions-dialog/cashier-redemptions-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { saveAs as importedSaveAs } from 'file-saver';


@Component({
  selector: 'app-redemptions',
  templateUrl: './redemptions.component.html',
  styleUrls: ['./redemptions.component.scss']
})
export class RedemptionsComponent implements OnInit {
  displayedColumns: string[] = ['id', 'redeemed_at', 'referee_by_phone_number', 'redeem_user__phone_number', 'redeem_id', 'redeemed_amount', 'payment_status'];
  dataSource: RedeemHistoryDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  store: Store;
  search;
  startDate;
  endDate;
  isLoading: boolean = false;
  selectedStoreName: string;
  constructor(
    private businessService: BusinessService,
    private receiptService: ReceiptService,
    private storeService: StoreService,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new RedeemHistoryDataSource(this.businessService)
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store)
        this.selectedStoreName = store.store_name

        this.dataSource.loadRedeemHistoryReferral(+store.id);
      }
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadRedeemHistoryReferral()))
      .subscribe();
  }

  loadRedeemHistoryReferral() {
    this.dataSource.loadRedeemHistoryReferral(+this.store.id, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  searchOrders(any: any) {
    this.loadRedeemHistoryReferral()
  }

  onRowClicked(row) {
    let botOrder = Object.assign(new RedeemHistory(), row)
    this.receiptService.changeBotOrder(botOrder);
  }

  reset() {
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.loadRedeemHistoryReferral();
  }

  acceptRedemption(element) {
    let dialogRef = this.dialog.open(CashierRedemptionsDialogComponent, {
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadRedeemHistoryReferral();
    });
  }

  exportData() {
    this.isLoading = true;

    this.businessService.getReferralCsv(
      +this.store.id,
      this.search,
      this.startDate,
      this.endDate,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    ).subscribe(history => {
      // Helper function to format dates as M_D_YYYY
      const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are 0-based
        const year = date.getFullYear();
        return `${month}_${day}_${year}`;
      };

      // Convert startDate and endDate to Date objects if they are provided
      const start = this.startDate ? new Date(this.startDate) : null;
      const end = this.endDate ? new Date(this.endDate) : null;

      // Format start and end dates if they exist
      const formattedStartDate = start ? formatDate(start) : '';
      const formattedEndDate = end ? formatDate(end) : '';

      // Replace spaces with underscores in selectedStoreName
      const formattedStoreName = this.selectedStoreName.replace(/ /g, '_');

      // Create the CSV name based on whether dates are provided
      let csvname = `${formattedStoreName}_referral-Reporting.csv`;
      if (formattedStartDate && formattedEndDate) {
        csvname = `${formattedStoreName}_${formattedStartDate}_to_${formattedEndDate}_referral-Reporting.csv`;
      } else if (formattedStartDate) {
        csvname = `${formattedStoreName}_${formattedStartDate}_referral-Reporting.csv`;
      } else if (formattedEndDate) {
        csvname = `${formattedStoreName}_${formattedEndDate}_referral-Reporting.csv`;
      }


      // Function to save the file
      importedSaveAs(history, csvname);
      this.isLoading = false;
    });
  }



}
