<div class="container hideOnxs">
  <div class="row row-flex">
    <input type="password" class="result hideOnxs" [ngModel]="result" readonly />
  </div>
  <mat-grid-list cols="1" class="mat-list-ht stationNumber chnageView">
    <mat-grid-tile class="pt-0 mat-grid-ht overflow-inherit numbering-width">
      <div>
        <div class="number-row">
          <div>
            <input matRipple type="button" name="" value="1" class="global" (click)="pressNum('1')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="2" class="global" (click)="pressNum('2')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="3" class="global" (click)="pressNum('3')" />
          </div>
        </div>
        <div class="number-row">
          <div>
            <input matRipple type="button" name="" value="4" class="global" (click)="pressNum('4')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="5" class="global" (click)="pressNum('5')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="6" class="global" (click)="pressNum('6')" />
          </div>
        </div>
        <div class="number-row">
          <div>
            <input matRipple type="button" name="" value="7" class="global" (click)="pressNum('7')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="8" class="global" (click)="pressNum('8')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="9" class="global" (click)="pressNum('9')" />
          </div>
        </div>
        <div class="number-row">
          <div>
            <input matRipple type="button" name="" value="0" class="global" (click)="pressNum('0')" />
          </div>
          <div>
            <input matRipple type="button" name="" value="C" class="global" (click)="allClear()" />
          </div>
          <div>
            <button mat-raised-button class="global" (click)="clear()">
              <span>
                <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                    fill="#636363" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
