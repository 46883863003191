import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FloorPlanService {

  endpoint = environment.apiUrl;
  floorplanurl = this.endpoint + '/backend/v2';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  public floorCreatedUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(private http: HttpClient) { }

 
  createFloor(payload: any) {
    return this.http.post(this.floorplanurl + `/floorplans/`, payload, this.httpOptions);
  }

  getFlooPlanlist(storeId: number) {
    let params = new HttpParams()
      .set('store_id', storeId.toString())
    return this.http.get(this.floorplanurl + `/floorplans`, { params: params });
  }

  updateFloor(payload:any) {
    return this.http.put(this.floorplanurl + `/floorplans/${payload.id}/`, payload);
  }

  deleteFlooPlan(id) {
    return this.http.delete(this.floorplanurl + `/floorplans/` + id + `/`);
  }

  createTable(payload: any) {
    return this.http.post(this.floorplanurl + `/tables/`, payload, this.httpOptions);
  }

  getFloorTableList(floor_id: any) {
    let params = new HttpParams()
      .set('floor_plan_id', floor_id.toString())
    return this.http.get(this.floorplanurl + `/tables/`, { params: params });
  }

  updateFloorSingleTable(payload:any) {
    return this.http.put(this.floorplanurl + `/tables/${payload.id}/`, payload);
  }

  updateFloorTables(payload: any) {
    return this.http.put(this.floorplanurl + `/tables/bulk-update/`, payload);
  }

  createTableCopy(payload: any) {
    return this.http.post(this.floorplanurl + `/tables/copy-table/`, payload);
  }

  deleteTable(tableId: string) {
    return this.http.delete<string>(this.floorplanurl + `/tables/${tableId}/`);
  }

  createObject(payload: any) {
    return this.http.post(this.floorplanurl + `/floor-objects/`, payload, this.httpOptions);
  }

  getFloorObjectList(floor_id: any) {
    let params = new HttpParams()
      .set('floor_plan_id', floor_id.toString())
    return this.http.get(this.floorplanurl + `/floor-objects/`, { params: params });
  }

  updateFloorSingleObject(payload:any) {
    console.log('payload', payload);
    
    return this.http.put(this.floorplanurl + `/floor-objects/${payload.id}/`, payload);
  }

  updateFloorObjects(payload: any) {
    return this.http.put(this.floorplanurl + `/floor-objects/bulk-update-obj/`, payload);
  }

  deleteObject(objectId: string) {
    return this.http.delete<string>(this.floorplanurl + `/floor-objects/${objectId}/`);
  }
  


}
