import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderService } from 'src/app/_services/order.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { RefreshService } from 'src/app/_services/refresh.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-bar-code-scanner',
  templateUrl: './bar-code-scanner.component.html',
  styleUrls: ['./bar-code-scanner.component.scss']
})
export class BarCodeScannerComponent implements OnInit, OnDestroy {
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | null = null;
  hasDevices: boolean = false;
  torchEnabled: boolean = false;
  startScanner: boolean = false;
  allowedFormats: BarcodeFormat[] = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODE_39,
    BarcodeFormat.EAN_13,
    BarcodeFormat.EAN_8,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
  ];
  isScanning: boolean = false;  // State to control scanning
  beepAudio = new Audio('assets/audio/alert.mp3'); // Path to beep sound
  public unsubscribe: Subject<null> = new Subject<null>();
  permissionDenied: boolean = false; // State to control permission message

  constructor(
    private orderService: OrderService,
    private posSummaryToggle: POSSummaryToggleService,
    private refreshService: RefreshService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<BarCodeScannerComponent>
  ) { }

  ngOnInit(): void {
    this.checkCameraPermission();
  }

  ngOnDestroy(): void {
    this.stopScanner();
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  checkCameraPermission(): void {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        // Permission granted, start scanning
        this.startScanning();
        // Stop all video tracks to release the camera
        stream.getVideoTracks().forEach(track => track.stop());
      })
      .catch((err) => {
        console.error('Camera permission denied:', err);
        // Handle permission denied case
        this.permissionDenied = true;
      });
  }

  startScanning(): void {
    this.isScanning = true;
    this.startScanner = true;
    this.permissionDenied = false; // Reset permission denied state
  }

  stopScanner(): void {
    this.isScanning = false;
    this.startScanner = false;
  }

  handleScanSuccess(result: string): void {
    console.log('Scan result:', result);
    this.beepAudio.play(); // Play beep sound

    // Pause the scanner to process the result
    this.startScanner = false;

    // Add the scanned item to the order and restart scanning after a delay
    this.updateIteminOrder(result);
  }

  closeDialog(): void {
    this.stopScanner();
    this.dialogRef.close();
  }

  handleScanError(error: any): void {
    console.error('Error during scan:', error);
  }

  handleScanFailure(): void {
    console.log('No barcode detected.');
  }

  camerasFoundHandler(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    console.log('Cameras found:', devices);
    // Select the first available device by default
    if (this.hasDevices && !this.selectedDevice) {
      this.selectedDevice = devices[0];
    }
  }

  camerasNotFoundHandler(): void {
    console.error('No cameras found.');
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  onDeviceSelect(deviceId: string): void {
    this.selectedDevice = this.availableDevices.find(device => device.deviceId === deviceId) || null;
    console.log('Selected device:', this.selectedDevice);
  }

  updateIteminOrder(itemCode: string) {
    let cuObs = null;
    let botOrder;
    const orderHash = localStorage.getItem('order_hash');
    this.orderService.getOrderByHash(orderHash).pipe(takeUntil(this.unsubscribe)).subscribe((resultOrder) => {
      botOrder = resultOrder;
      cuObs = this.orderService.addItemId(orderHash, itemCode, {
        modifiers: [],
        quantity: 1,
        special_request: null,
      });
      cuObs.pipe(takeUntil(this.unsubscribe)).subscribe(
        (retV) => {
          // this.posSummaryToggle.toggle('OPEN');
          this.refreshService.refreshModule("POS_ORDER_SUMMARY");
          setTimeout(() => {
            this.startScanner = true;
          }, 3000);
        },
        (err) => {
          this.snackBar.open('Item not found', 'OK', { duration: 2000 });
        }
      );
    });
  }
}
