export class DialogConfig{
  title:string;
  message:string;
  suffix:Object;
  constructor(title:string, message:string, suffix:Object){
    this.title = title;
    this.message = message;
    this.suffix = suffix
  }
}

export const templateConfig = {
  "store_name": new DialogConfig("Business Name", "Enter you business name as it will appear to your customers.", {}),
  "address": new DialogConfig("Business Location", "", {}),
  "delivery_radius" : new DialogConfig("Service Areas", "Enter the distance radius where you business provides services and deliveries.", {"delivery_radius":"Mile Radius"}),
  "working": new DialogConfig("Hours", "Enter your service hours here.", {}),
  "holiday": new DialogConfig("Holidays", "Enter your Holiday.", {}),
  "phone": new DialogConfig("Phone Numbers", "Manage your primary and any secondary phone numbers here", {}),
  "pickup_dine_in_lead_time": new DialogConfig("Lead Time", "Set your default and custom service leadtimes here.   ", {"pickup_dine_in_lead_time":"Minutes",
  "dine_in_lead_time":"Minutes",
  "delivery_lead_time": "Minutes"}),
  "gmb_location_id": new DialogConfig("URLs", "", ""),
  "printouts_per_order": new DialogConfig("Printouts Per Pickup Order", "", {}),
  "printouts_per_delivery_order": new DialogConfig("Printouts Per Delivery Order", "", {}),
  "company": new DialogConfig("Account Information", "", {}),
  "has_order_occasion_pickup": new DialogConfig("Order Occassion(s)", "", {}),
  "connect_number": new DialogConfig("Connect Number", "", {}),
  "ivr_welcome_message": new DialogConfig("IVR Welcome Message", "", {}),
  "service_charge": new DialogConfig("Charges", "", {"service_charge": "USD", "delivery_charge": "USD", "delivery_minimum": "USD"}),
  "printouts_per_curbside_order": new DialogConfig("Printouts Per Curbside Order", "", {}),
  "printouts_per_dine_in_order": new DialogConfig("Printouts Per Dine-In Order", "", {}),
  "ivr_settings.closed_hours_message": new DialogConfig("IVR Closed Hours Message", "", {}),
};

