<form [formGroup]="form" (onsubmit)="submit(form.value)" class="fixActionRow" >
  <h1 mat-dialog-title>Lead Times</h1>
  <div mat-dialog-content>
    <div class="form-row">
      <p class="title">Default Pickup Lead time</p>
      <mat-form-field class="lead-time-field" >
        <input matInput formControlName="pickup_dine_in_lead_time">
      </mat-form-field>
      <span class="ps-2">minutes</span>
      <button mat-button color="primary" (click)="addLeadTime('pickup')" class="ps-2">Add custom</button>
    </div>

    <div class="custom-lead-times" *ngIf="getCustomLeadTime('custom_pickup_lead_times').length > 0">
      <p>Custom Pickup Lead Time(s)</p>
      <div formArrayName="custom_pickup_lead_times" class="lead-times">
        <div class="row" *ngFor="let leadTime of getCustomLeadTime('custom_pickup_lead_times'); index as i" [formGroupName]="i">
          <div class="column">
            <mat-form-field [formGroup]="leadTime">
              <mat-label>Occasion</mat-label>
              <mat-select formControlName="occasion">
                <mat-option *ngFor="let o of occasion|keyvalue" [value]="+o.key">{{o.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>Day</mat-label>
              <mat-select formControlName="day">
                <mat-option *ngFor="let day of days; index as di" [value]="di">{{day}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>From</mat-label>
              <!-- <mat-select formControlName="from" [compareWith]="hourCompare">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
              </mat-select> -->
              <input matInput [ngxTimepicker]="timepicker" formControlName="from" [compareWith]="hourCompare">
              <ngx-material-timepicker #timepicker class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>To</mat-label>
              <!-- <mat-select formControlName="to" [compareWith]="hourCompare">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
              </mat-select> -->
              <input matInput [ngxTimepicker]="timepickerone" formControlName="to" [compareWith]="hourCompare">
              <ngx-material-timepicker #timepickerone class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field class="lead-time-field" >
              <mat-label>Lead time</mat-label>
              <input matInput formControlName="lead_time">
            </mat-form-field>
          </div>
          <div class="column">
            <button mat-icon-button (click)="deleteLeadTime('pickup', i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <p class="title">Default Dine-In Lead time</p>
      <mat-form-field class="lead-time-field" >
        <input matInput formControlName="dine_in_lead_time">
      </mat-form-field>
      <span class="ps-2">minutes</span>
      <button mat-button color="primary" (click)="addLeadTime('dine-in')" class="ps-2">Add custom</button>
    </div>

    <div class="custom-lead-times" *ngIf="getCustomLeadTime('custom_dine_in_lead_times').length > 0">
      <p>Custom Dine-In Lead Time(s)</p>
      <div formArrayName="custom_dine_in_lead_times" class="lead-times">
        <div class="row" *ngFor="let leadTime of getCustomLeadTime('custom_dine_in_lead_times'); index as i" [formGroupName]="i">
          <div class="column">
            <mat-form-field [formGroup]="leadTime">
              <mat-label>Occasion</mat-label>
              <mat-select formControlName="occasion">
                <mat-option *ngFor="let o of occasion|keyvalue" [value]="+o.key">{{o.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>Day</mat-label>
              <mat-select formControlName="day">
                <mat-option *ngFor="let day of days; index as di" [value]="di">{{day}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>From</mat-label>
              <!-- <mat-select formControlName="from" [compareWith]="hourCompare">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
              </mat-select> -->
              <input matInput [ngxTimepicker]="timepickertwo" formControlName="from" [compareWith]="hourCompare">
              <ngx-material-timepicker #timepickertwo class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>To</mat-label>
              <!-- <mat-select formControlName="to" [compareWith]="hourCompare">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
              </mat-select> -->
              <input matInput [ngxTimepicker]="timepickerthree" formControlName="to" [compareWith]="hourCompare">
              <ngx-material-timepicker #timepickerthree class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field class="lead-time-field" >
              <mat-label>Lead time</mat-label>
              <input matInput formControlName="lead_time">
            </mat-form-field>
          </div>
          <div class="column">
            <button mat-icon-button (click)="deleteLeadTime('dine-in',i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <p class="title">Default Delivery Lead time</p>
      <mat-form-field class="lead-time-field" >
        <input matInput formControlName="delivery_lead_time">
      </mat-form-field>
      <span class="ps-2">minutes</span>
      <button mat-button color="primary" (click)="addLeadTime('delivery')" class="ps-2">Add custom</button>
    </div>

    <div class="custom-lead-times" *ngIf="getCustomLeadTime('custom_delivery_lead_times').length > 0">
      <p>Custom Delivery Lead Time(s)</p>
      <div formArrayName="custom_delivery_lead_times" class="lead-times">
        <div class="row" *ngFor="let leadTime of getCustomLeadTime('custom_delivery_lead_times'); index as i" [formGroupName]="i">
          <div class="column">
            <mat-form-field [formGroup]="leadTime">
              <mat-label>Occasion</mat-label>
              <mat-select formControlName="occasion">
                <mat-option *ngFor="let o of occasion|keyvalue" [value]="+o.key">{{o.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>Day</mat-label>
              <mat-select formControlName="day">
                <mat-option *ngFor="let day of days; index as di" [value]="di">{{day}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>From</mat-label>
              <!-- <mat-select formControlName="from" [compareWith]="hourCompare">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
              </mat-select> -->
              <input matInput [ngxTimepicker]="timepickerhour" formControlName="from" [compareWith]="hourCompare">
              <ngx-material-timepicker #timepickerhour class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>To</mat-label>
              <!-- <mat-select formControlName="to" [compareWith]="hourCompare">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
              </mat-select> -->
              <input matInput [ngxTimepicker]="timepickerfive" formControlName="to" [compareWith]="hourCompare">
              <ngx-material-timepicker #timepickerfive class="special-hour-picker">
              </ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field class="lead-time-field" >
              <mat-label>Lead time</mat-label>
              <input matInput formControlName="lead_time">
            </mat-form-field>
          </div>
          <div class="column">
            <button mat-icon-button (click)="deleteLeadTime('delivery', i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr"(click)="submit(form)">Apply</button>
  </div>
</form>
