import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { AlertsMessagesComponent } from '../alerts-messages/alerts-messages.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrls: ['./autocomplete-chips.component.scss']
})
export class AutocompleteChipsComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  filtered;
  ctrl:UntypedFormControl = new UntypedFormControl();

  @Input() controlName;
  @Input() selectedEntities;
  @Input() entities;
  @Input() key

  @Output() output = new EventEmitter<any>();
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  
  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.filtered = this.ctrl.valueChanges.pipe(
      startWith(''),
      map((searchTerm: string) => this._filter(searchTerm)));

  }

  _filter(searchTerm){
    if(searchTerm){
      var filterTerm = ''
      if(typeof searchTerm === 'object'){
        filterTerm = searchTerm[this.key].toLowerCase()
      }else{
        filterTerm = searchTerm.toLowerCase()
      }
      return this.entities.filter(entity => entity[this.key].toLowerCase().indexOf(filterTerm) === 0)
    }else{
      return this.entities;
    }
  }

  add(event){
    console.log(event);

  }

  select(event: MatAutocompleteSelectedEvent){
    let value = event.option.value;
    if(!this.selectedEntities.some(e => e.id === value.id)){
      this.confirm(value, 0);
    } else {
      this.confirm(value, 1);

    }
  }

  confirm(value, f) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      data: {
        message: f == 0 ? 'Are you sure you want to add the item.' : 'Already available.',
        for_which: f == 0 ? 'confirm' : ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(!this.selectedEntities.some(e => e.id === value.id)){
          this.selectedEntities.push(value);
          this.output.emit(this.selectedEntities);
          this.ctrl.setValue('sadad');
          this.fruitInput.nativeElement.value = null;
        }
      }
      this.fruitInput.nativeElement.value = null;

    });
  }

  remove(entity){
    const index = this.selectedEntities.indexOf(entity);
    if (index >= 0) {
      this.selectedEntities.splice(index, 1);
    }
  }

}
