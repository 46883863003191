<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<span *ngIf="splitRow == undefined">
  <table width="100%" class="price-breakdown-table" cellspacing="10px;" *ngIf="orderWithPayment?.bot_order.is_temp">
    <tr>
      <td class="item">Total Item Count</td>
      <td class="item right-align">{{getItemCounts() }}</td>
    </tr>
    <tr *ngIf="orderWithPayment?.bot_order.promo_code">
      <td class="item">Promo Code</td>
      <td class="item right-align">{{orderWithPayment?.bot_order.promo_code }}</td>
    </tr>
    <tr>
      <td class="item">Subtotal</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.payment.sub_total | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.discount > 0">
      <td class="item">Discount</td>
      <td class="item right-align">
        <span>-{{orderWithPayment?.payment.discount | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.promotional_discount > 0">
      <td class="item">Promotional Discount</td>
      <td class="item right-align">
        <span>-{{orderWithPayment?.payment.promotional_discount | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.instant_cash_discount > 0 && isCashOrOthers()">
      <td class="item">Instant Cash Discount</td>
      <td class="item right-align">
        <span>-{{orderWithPayment?.payment.instant_cash_discount | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.instant_discount > 0">
      <td class="item">{{ orderWithPayment?.bot_order.instant_discount_description }}</td>
      <td class="item right-align">
        <span>-{{orderWithPayment?.payment.instant_discount | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="isNonCash() && !orderWithPayment?.store.consolidate_taxes_and_fees">
      <td class="item">Tax</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.payment.tax | currency:'USD'}}</span>
      </td>
    </tr>
    <!-- <tr *ngIf="+orderWithPayment?.payment.store_credit > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
    <td class="item">Tax</td>
    <td class="item right-align">
      <span>{{orderWithPayment?.payment.tax | currency:'USD'}}</span>
    </td>
  </tr> -->
    <tr *ngIf="isCashOrOthers() && !orderWithPayment?.store.consolidate_taxes_and_fees">
      <td class="item">Tax</td>
      <td class="item right-align">{{orderWithPayment?.payment.cash_tax | currency:'USD'}}</td>
    </tr>
    <tr *ngIf="orderWithPayment?.bot_order.occasion == 'DELIVERY' && orderWithPayment?.payment.delivery_charge > 0">
      <td class="item">Delivery</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.payment.delivery_charge | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
      <td class="item">{{orderWithPayment?.payment.service_charge_title}}</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.payment.service_charge | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="orderWithPayment?.payment.surcharge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
      <td class="item" *ngIf="orderWithPayment?.payment.type != 'CASH' && orderWithPayment?.payment.type != 'GIFT'">
        {{orderWithPayment?.payment.only_credit_surcharge_title}}</td>

      <td class="item right-align"
        *ngIf="orderWithPayment?.payment.type != 'CASH' && orderWithPayment?.payment.type != 'GIFT'">
        <span>{{orderWithPayment?.payment.surcharge | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.store_credit > 0">
      <td class="item">Store Credit</td>
      <td class="item right-align">
        <span>{{'-' + orderWithPayment?.payment.store_credit | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="orderWithPayment?.payment.pos_service_charge > 0 && !orderWithPayment?.store.consolidate_taxes_and_fees">
      <td class="item">{{orderWithPayment?.store.service_charge_title}}</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.payment.pos_service_charge | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="orderWithPayment?.store.consolidate_taxes_and_fees">
      <td class="item">Total Taxes & Fees</td>
      <td class="item right-align">
        <span
          *ngIf="orderWithPayment?.payment.type != 'CASH'">{{orderWithPayment?.payment.consolidate_taxes_and_fees_credit
          | currency:'USD'}}</span>
        <span
          *ngIf="orderWithPayment?.payment.type == 'CASH'">{{orderWithPayment?.payment.consolidate_taxes_and_fees_cash |
          currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="orderWithPayment?.bot_order.has_adjustment && orderWithPayment?.bot_order.adjusted_total > 0">
      <td class="item">Adjusted</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.bot_order.adjusted_total | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="+orderWithPayment?.payment.tip > 0">
      <td class="item">Tip</td>
      <td class="item right-align">
        <span>{{orderWithPayment?.payment.tip | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="orderWithPayment?.bot_order.has_refund && orderWithPayment?.bot_order.refund_total > 0">
      <td class="item">Refunded</td>
      <td class="item right-align">
        <span>-{{orderWithPayment?.bot_order.refund_total | currency:'USD'}}</span>
      </td>
    </tr>
    <!-- <tr *ngIf="+orderWithPayment?.payment.store_credit > 0">
    <td class="item total">Total</td>
    <td class="item total right-align">
      <span *ngIf="+orderWithPayment?.payment.store_credit > 0 && orderWithPayment?.payment.total == 0">{{ 0 | currency:'USD'}}</span>
      <span *ngIf="+orderWithPayment?.payment.store_credit > 0 && orderWithPayment?.payment.total > 0">{{orderWithPayment?.payment.total | currency:'USD'}}</span>
    </td>
  </tr> -->
    <tr *ngIf="isNonCash()">
      <td class="item total">Total</td>
      <td class="item total right-align">
        <span>{{orderWithPayment?.payment.total | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="isCashOrOthers()">
      <td class="item total">Total</td>
      <td class="item total right-align">{{orderWithPayment?.payment.cash_due_total | currency:'USD'}}</td>
    </tr>
  </table>
  <p class="text-order" *ngIf="!orderWithPayment?.bot_order.is_temp">
    <span class="line-item" *ngFor="let order of orderWithPayment?.bot_order.order.split('.nl.')">{{order.trim()}}</span>
  </p>
</span>

<span *ngIf="splitRow">
  <table width="100%" class="price-breakdown-table" cellspacing="10px;" *ngIf="orderWithPayment?.bot_order.is_temp">
    <tr>
      <td class="item">Subtotal</td>
      <td class="item right-align">
        <span>{{ splitRow.split_amount | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="splitRow.adjust > 0">
      <td class="item">Adjusted</td>
      <td class="item right-align">
        <span>{{splitRow.adjust | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="splitRow.tip > 0">
      <td class="item">Tip</td>
      <td class="item right-align">
        <span>{{splitRow.tip | currency:'USD'}}</span>
      </td>
    </tr>
    <tr *ngIf="splitRow.refund > 0">
      <td class="item">Refunded</td>
      <td class="item right-align">
        <span>-{{splitRow.refund | currency:'USD'}}</span>
      </td>
    </tr>
    <tr>
      <td class="item total">Total</td>
      <td class="item total right-align">{{ splitRow.total | currency:'USD'}}</td>
    </tr>
  </table>
</span>