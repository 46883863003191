import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '../_models/store';
import {OrderService} from '../_services/order.service';
import {StoreService} from '../_services/store.service';
import {CustomBatchTimeComponent} from './custom-batch-time/custom-batch-time.component';
import {POSService} from '../_services/pos-service';
import {AlertsMessagesComponent} from '../alerts-messages/alerts-messages.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-pos-settings',
  templateUrl: './pos-settings.component.html',
  styleUrls: ['./pos-settings.component.scss']
})
export class PosSettingsComponent implements OnInit, OnDestroy {

  constructor(private orderService: OrderService,
              private storeService: StoreService,
              public dialog: MatDialog,
              private posService: POSService) { }

  store: Store;
  batches;
  loading = false;
  time: any;
  storeid: any;
  showTime: string;
  changeTime: any;
  logs: any[];
  private destroySubject: Subject<void> = new Subject();

  ngOnInit() {
    this.storeid = localStorage.getItem('currentStore');
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      this.orderService.posClosedBatches(this.store.id).pipe(takeUntil(this.destroySubject)).subscribe(history => {
        this.batches = history;
      });
    });

    this.orderService.customClosedBatchTime(this.storeid).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.time = data;
      if (this.time.batch_time != '') {
        this.changeTime = data.batch_time.split(':');
        let hours = this.changeTime[0];
        const minutes = this.changeTime[1];
        // Check whether AM or PM
        const newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;
        // To display "0" as "12"
        hours = hours ? hours : 12;
        // Combine hour minute
        const getString = hours + ':' + minutes + ' ' + newformat;
        this.showTime = getString;
      } else {
        this.showTime = '11:59 PM' ;
      }
    });

    this.posService.getPosLinkLogs(this.storeid).pipe(takeUntil(this.destroySubject)).subscribe(logs => {
      this.logs = logs;
    });
  }

  formatDateTime(dateString: string): string {
    return moment.parseZone(dateString).format('YYYY-MM-DD hh:mm:ss A');
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  closeBatch() {
    this.loading = true;
    this.orderService.posCloseBatch(this.store.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(AlertsMessagesComponent, {
        disableClose: true,
        width: isMobile ? '100vw' : '',
        minHeight: '20vh',
        // maxWidth: isMobile ? '100vw' : '100vw',
        // height: isMobile ? '77vh' : '',
        maxWidth: isMobile ? '100vw' : '100vw',
        data: {
          message: 'Batch closed successfully'
        }
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        if (result) {
          this.ngOnInit();
          this.loading = false;
        }
      });
      // alert('Batch closed successfully');
    });
  }

  closeCustomBatch() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(CustomBatchTimeComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? 'auto' : 'auto',
      // maxWidth: isMobile ? '100vw' : '100vw',
      // height: isMobile ? '77vh' : '',
      maxWidth: isMobile ? '100vw' : '100vw',
      data: {
        custom: this.time
      }

    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.ngOnInit();
    });

  }

}
