<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="p-6">
  <div class="flex flex-col">
    <div class="flex items-center justify-between ml-4 mb-4">
      <button mat-button type="button" (click)="back()" class="!justify-start flex items-center gap-2" tabindex="-1">
        <mat-icon class="bottom-0.5">arrow_back_ios_new</mat-icon>
        Back
      </button>
      <p class="text-center flex-grow font-normal mt-[.5%]">Manual Card Payment</p>
    </div>
    <div class="flex gap-2.5">
      <mat-form-field class="example-full-width xs:!block xs:w-[57%]" appearance="fill">
        <mat-label>Phone Number</mat-label>
        <input #phone appPhoneMask matInput autofocus [(ngModel)]="phoneNumber" placeholder="Add phone number"
          (ngModelChange)="updatePhoneNumber()" [errorStateMatcher]="errorMatcher"
          [value]="orderWithPayment?.getFormattedPhoneNumber()" [disabled]="number_verified" (keyup)="onKeyUp($event)">
        <button *ngIf="phone.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearPhoneNumber(phone)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-flat-button *ngIf="!otp_field" color="primary" class="!rounded-2xl px-5 py-0.5 top-2"
        [disabled]="!isValidPhone" (click)="addToWallet()">Add
        to Wallet</button>
      <button *ngIf="otp_field && !number_verified" mat-stroked-button color="primary"
        class="!rounded-2xl !px-[30px] py-0.5 top-2" (click)="addToWallet()">Resend</button>
      <button *ngIf="number_verified" mat-stroked-button color="primary" class="!rounded-2xl !px-[30px] py-0.5 top-2"
        (click)="changePhone()">Change</button>
    </div>
    <div class="xs:!mt-5 flex gap-1" *ngIf="otp_field && !number_verified">
      <mat-form-field class="example-full-width xs:!block lg: w-[13.2%] xs:w-[57%]" appearance="fill">
        <mat-label>Verification code</mat-label>
        <input autofocus required matInput placeholder="Verification code" [(ngModel)]="otp" type="number">
      </mat-form-field>
      &nbsp;
      <button mat-flat-button color="primary" class="!rounded-2xl !px-9 py-0.5 top-2"
        (click)="verifyOTP()">Verify</button>
    </div>
    <br />
  </div>

  <div class="flex flex-col" *ngIf="store?.payment_gateway !== 'WORLDNET'">
    <div *ngIf="cardsOnFile" class="opt-container">
      <p class="card-title">Existing Payment Options</p>
      <div *ngFor="let card of cardsOnFile.cards" class="flex justify-between items-center border-b py-2">
        <div class="text-left">
          {{card.card_type | titlecase }} &bull;&bull;&bull;&bull; {{card.last_4}}
        </div>
        <div class="flex items-center space-x-2">
          <button mat-flat-button (click)="useCard(card)" color="primary" class="btn-primary">Use Card</button>
          <div (click)="deleteCard(card)" class="cursor-pointer">
            <mat-icon>delete_forever</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="store?.payment_gateway === 'PAYROC'" mat-flat-button color="primary" class="one-timebtn"
      id="payroc-button" [disabled]="!isValidPhone">
      <span *ngIf="!number_verified">One Time Payment</span>
      <span *ngIf="number_verified">Make Payment & Add Card To Wallet</span>
    </button>

    <button *ngIf="(store?.payment_gateway === 'NMIPR' || store?.payment_gateway === 'NMI')" mat-flat-button
      color="primary" class="one-timebtn" id="payroc-button" (click)="openCollectJsLightBox($event)"
      [disabled]="!isValidPhone">
      <span *ngIf="!number_verified">One Time Payment</span>
      <span *ngIf="number_verified">Make Payment & Add Card To Wallet</span>
    </button>

    <button *ngIf="(store?.payment_gateway === 'PAYROC_CLOUD' || store?.payment_gateway === 'WORLDNET')" mat-flat-button
      color="primary" class="one-timebtn" id="payroc-cloud-button" (click)="redirectToWorldnet(number_verified)"
      [disabled]="!isValidPhone">
      <span *ngIf="!number_verified">One Time Payment</span>
      <span *ngIf="number_verified">Make Payment & Add Card To Wallet</span>
    </button>

    <p class="failure" *ngIf="failure">Unable to complete the request. Sorry we could not process your order. Please
      update or change the payment type and resubmit your order.</p>
    <div class="opt-in-checkbox">
      <mat-checkbox [(ngModel)]="optIn">Opt-in to receive automated offers & messages</mat-checkbox>
    </div>
  </div>



  <div class="flex flex-col" *ngIf="store?.payment_gateway === 'WORLDNET'">
    <app-worldnet-dialog *ngIf="worldnetSessionToken"
      [data]="{ sessionToken: worldnetSessionToken, phone_number: phoneNumber }"
      (paymentDetailsEmitter)="handlePaymentDetails($event)"></app-worldnet-dialog>

    <div class="failure text-center" *ngIf="failure">
      <p class="font-medium">Status: {{result?.result_code}} - {{result?.message}}</p>
    </div>
  </div>

  <div class="mt-8" *ngIf="cardsOnFile?.cards?.length">
    <p class="text-lg font-semibold text-center mb-4">Users Cards On File</p>

    <div *ngFor="let card of cardsOnFile.cards">
      <form class="grid grid-cols-12 gap-4 items-center rounded-md mb-4">
        <div class="col-span-3">
          <input type="text" [value]="isMobile ? card.last_4 : card.card_type + ' ****' + card.last_4"
            class="w-full bg-gray-200 text-gray-700 font-medium h-[56px] rounded-md px-4 border border-gray-300"
            disabled />
        </div>
        <div class="col-span-2 xs:hidden">
          <input type="text" [value]="card.expiry"
            class="w-full bg-gray-200 text-gray-700 text-center h-[56px] rounded-md px-4 border border-gray-300"
            disabled />
        </div>
        <div class="col-span-2 xs:hidden">
          <input type="text" [value]="card.cvc ? '***' : '****'"
            class="w-full bg-gray-200 text-gray-700 text-center h-[56px] rounded-md px-4 border border-gray-300"
            disabled />
        </div>
        <div class="col-span-3 xs:col-span-4">
          <input type="tel" [placeholder]="'Enter Zip Code'" [(ngModel)]="card.zip_code" name="zipCode"
       pattern="[0-9]*" inputmode="numeric"
       class="w-full bg-white text-gray-700 text-center h-[56px] rounded-md px-4 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
        <div class="col-span-2 flex items-center xs:col-span-5">
          <button class="bg-blue-600 text-white h-[56px] rounded-md hover:bg-blue-700 w-full px-4 xs:text-xs" type="button"
            (click)="useCard(card)" [disabled]="!card.zip_code || card.zip_code.trim() === ''">
            Charge Card On File
          </button>
          <div (click)="deleteCard(card)" class="cursor-pointer ml-2">
            <mat-icon>delete_forever</mat-icon>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>