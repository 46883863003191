import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from 'src/app/_services/payment.service';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private paymentService:PaymentService,
    private router: Router) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.paymentService.connectStripe(params['state'], params['code']).subscribe(data => {
        this.router.navigate(['home', 'account'])
      }, error => {

      })
    })

  }

}
