import {Component, Inject, OnInit} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';

@Component({
  selector: 'app-gift-card-defaul-image',
  templateUrl: './gift-card-defaul-image.component.html',
  styleUrls: ['./gift-card-defaul-image.component.scss']
})
export class GiftCardDefaulImageComponent implements OnInit {
  store_id: any;
  uploadImage: File;
  Data: any = new FormData();
  isLoading:boolean = false;
  imagePreview: string | ArrayBuffer | null = null;
  constructor(
    private storeService: StoreService,
    private menuService: MenuServiceV2,
    public dialogRef: MatDialogRef<GiftCardDefaulImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.store_id = localStorage.getItem('currentStore');
  }

  onImageSelected(event) {
    this.uploadImage = event.target.files[0];
    this.Data.append("gift_card_default_image",this.uploadImage, this.uploadImage.name);
  }

  giftImageUpload() {
    this.isLoading = true;
    this.storeService.giftCardDefaultImage(this.store_id, this.Data).subscribe((data:any) => {
      this.isLoading = false;
      if (data) {
        this.dialogRef.close(1);
      }
    })
  }
  onItemImageSelected(event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      this.uploadImage = event.target.files[0];
      const act = this.data.category == 'category' ? 'image' : 'sub_image'
      this.Data.append(act, this.uploadImage, this.uploadImage.name);
      const showImage = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(showImage);
    }
  }
  itemImageUpload() {
    this.isLoading = true;
    this.menuService.menuImageUpload(this.store_id, this.data.vatSubcat.data.id, this.Data).subscribe((data:any) => {
      this.isLoading = false;
      if (data) {
        this.dialogRef.close(1);
      }
    })
  }
}
