import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BotOrderDataSource} from 'src/app/_datasources/bot-order-datasource';
import {BusinessService} from 'src/app/business.service';
import {takeUntil, tap} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {ReceiptService} from 'src/app/_services/receipt.service';

import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {OrderService} from 'src/app/_services/order.service';
import {BotOrder} from 'src/app/_models/order';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Counter, CounterService} from 'src/app/_services/counter.service';
import {CancelDialogComponent} from '../cancel-dialog/cancel-dialog.component';
import {RefreshService} from 'src/app/_services/refresh.service';
import {OpenOrderToggleService} from 'src/app/_services/open-order-toggle.service';
import {SplitPaymentComponent} from '../split-payment/split-payment.component';
import {PinConfirmComponent} from '../pin-confirm/pin-confirm.component';
import {PermissionService} from '../../_services/permission.service';
import {Permission} from '../../_models/permission';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

// import { SplitPaymentComponent } from '../split-payment/split-payment.component';

@Component({
  selector: 'app-open-tab',
  templateUrl: './open-tab.component.html',
  styleUrls: ['./open-tab.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OpenTabComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = null;
  dataSource: BotOrderDataSource;

  @Output() toggleSummaryDrawer: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  store: Store;
  search;
  startDate;
  endDate;
  expandedRowId;
  selectedOrderMiscFromGroup;
  selectedRow;
  interval;
  refSub;
  myOrder = false;
  private destroySubject: Subject<void> = new Subject();
  @Output() openSideNav: EventEmitter<boolean> = new EventEmitter();
  permission: Permission;
  pinPermission: Permission;

  constructor(
    private businessService: BusinessService,
    private receiptService: ReceiptService,
    private storeService: StoreService,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public counterService: CounterService,
    private refreshService: RefreshService,
    private openOrderToggle: OpenOrderToggleService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit() {
    this.dataSource = new BotOrderDataSource(this.businessService);
    this.selectedOrderMiscFromGroup = new UntypedFormArray([]);
    this.permissionService.pinCurrentOperator.pipe(takeUntil(this.destroySubject)).subscribe((permission) => {
      this.permission = permission;
    });
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);

        if (this.store.hasModule('POS')) {
          this.displayedColumns = ['order_name', 'placed', 'order_counter',
          'source', 'occasion', 'table_id', 'terminal_id', 'cashier_id', 'payment_type', 'total', 'status', 'action'];
        } else {
          this.displayedColumns = ['updated_at', 'order_name', 'order_counter',
          'source', 'occasion', 'payment_type', 'total', 'status', 'action'];
        }

        this.loadOpenOrders();
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.loadOpenOrders();
            if (this.selectedRow) {
              this.receiptService.changeBotOrder(this.selectedRow);
            }
          }, 30000);
        }
      }
    });
    this.refSub = this.refreshService.current.pipe(takeUntil(this.destroySubject)).subscribe(module => {
      if (module === 'OPEN_TAB') {
        this.reset();
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    console.log('destroy');
    if (this.refSub) {
      this.refSub.unsubscribe();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  collapseAll() {
    this.expandedRowId = null;
  }

  getFormGroup(data) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(data ? data.id : null),
      quantity:  new UntypedFormControl(data ? data.quantity : 1, Validators.required),
      item_name: new UntypedFormControl(data ? data.item_name : null, Validators.required),
      price: new UntypedFormControl(data ? data.price : null, Validators.required),
      is_taxable: new UntypedFormControl(data ? data.is_taxable : true)
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.pipe(takeUntil(this.destroySubject)).subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOpenOrders())).pipe(takeUntil(this.destroySubject))
      .subscribe();
  }

  loadOpenOrders() {
    this.dataSource.loadOpenOrders(+this.store.id, this.search, this.startDate, this.endDate,
      this.sort.active, this.sort.direction || 'desc', this.paginator.pageIndex,
      this.paginator.pageSize,
      this.myOrder ? localStorage.getItem('posLoggedUser') : '');
    const openOrderIds = this.dataSource.getIds();
    if (this.selectedRow && !openOrderIds.includes(this.selectedRow.id)) {
      this.selectedRow = null;
      this.expandedRowId = null;
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (!data) {
        this.counterService.emit(new Counter('openOrders', this.dataSource.getTotal()));
      }
    });
  }

  onRowClicked(row) {
    console.log(row);

    this.selectedRow = row;

    this.selectedOrderMiscFromGroup = new UntypedFormArray([]);
    const botOrder = Object.assign(new BotOrder(), row);
    this.storeService.getMiscItems(botOrder.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      data.forEach(element => {
        this.selectedOrderMiscFromGroup.push(this.getFormGroup(element));
      });
    });
    this.receiptService.changeBotOrder(botOrder);
    // this.openSideNav.emit(true);
    this.orderService.changeOrderSubject.next(row);
    this.openOrderToggle.toggle('OPEN');
    // this.toggleSummaryDrawer.next();
  }

  refreshOrder(botOrder) {
    this.receiptService.changeBotOrder(botOrder);
  }

  reset() {
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.resetOrderSummary();
    this.loadOpenOrders();
  }

  print(botOrder: BotOrder) {
    const terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : 'NA';
    this.orderService.printOrder(botOrder.order_hash, terminalName).pipe(takeUntil(this.destroySubject)).subscribe(
       data => {
         this.snackBar.open('Order printed successfully', 'OK', {duration: 2000});
       },
       error => {
         this.snackBar.open('Unable to print order', 'OK', {duration: 2000});
       });
   }

  closeTab(botOrder: BotOrder) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure to close the tab?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        this.orderService.submitTableside(botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
          this.resetOrderSummary();
        }, error => {
          this.snackBar.open(`Unable to close the order, Reason: ${error.error.message}`, 'OK');
        });
      }
    });
    // if(confirm('Are you sure to close the tab?')){
    //   this.orderService.submitTableside(botOrder.order_hash).subscribe(data => {
    //     this.resetOrderSummary();
    //   },error => {
    //     this.snackBar.open(`Unable to close the order, Reason: ${error.error.message}`, "OK")
    //   });
    // }
  }

  add() {
    this.selectedOrderMiscFromGroup.push(this.getFormGroup(null));
  }

  delete(index, miscId) {
    if (miscId) {
      this.orderService.deleteMiscItem(miscId).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        this.selectedOrderMiscFromGroup.removeAt(index);
        this.receiptService.changeBotOrder(this.selectedRow);
      });
    } else {
      this.selectedOrderMiscFromGroup.removeAt(index);
    }
  }

  submitMisc(botOrder: BotOrder) {
    const payload = {
      bot_order_id: botOrder.id,
      items: this.selectedOrderMiscFromGroup.value
    };
    this.storeService.addMiscItems(payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.refreshService.refreshModule('POS_ORDER_SUMMARY');
    });
  }

  resetOrderSummary() {
    this.loadOpenOrders();
    this.orderService.changeOrderSubject.next(null);
    this.openOrderToggle.toggle('CLOSE');
  }

  cancel(botOrder: BotOrder) {
    const isMobile = window.innerWidth <= 599;
    this.receiptService.changeBotOrder(botOrder);
    const dialogRef = this.dialog.open(CancelDialogComponent, {
      width: isMobile ? '100vw' : '520px',
      height: isMobile ? '100vh' : '90vh',
      maxWidth: isMobile ? '100vw' : '80vw',
      panelClass: 'mobile-pos',
      data: {botOrder, store: this.store}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.resetOrderSummary();
    });
  }

  checkPOSPermission(botOrder: BotOrder) {
    if (this.permission.hasPermission('POS_FUNCTIONS', 'CANCEL')) {
      this.cancel(botOrder);
      this.resetOrderSummary();
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
        this.pinPermission = resultPer.permission;
        if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'CANCEL')) {
          this.cancel(botOrder);
          this.orderService.updateDescription(botOrder.id, {description: resultPer.description}).pipe(takeUntil(this.destroySubject)).subscribe((updated) => {
            console.log('________________', updated);
          });
          this.resetOrderSummary();
        } else {
          const dialogRef = this.dialog.open(AlertsMessagesComponent, {
            disableClose: true,
            width: '364px',
            minHeight: '20vh',
            data: {
              message: 'This User/Manager does not have the required permission to perform this operation!'
            }
          });
          dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
          });
          // alert('This User/Manager does not have the required permission to perform this operation!' );
        }
      });
    }
  }

  openSplitPayment(botOrder: BotOrder) {
    this.orderService.getOrder(botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(orderWithPayment => {

      alert('hhhhhhhhhhhhhhhhhhhhhh')
      const dialogRef = this.dialog.open(SplitPaymentComponent, {
        width: '630px', height: '600px',
        data: {orderWithPayment},
        disableClose: true,
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        dialogRef.close(result);
      });
    });
  }
}
