import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-order-sub-cat-grid',
  templateUrl: './order-sub-cat-grid.component.html',
  styleUrls: ['./order-sub-cat-grid.component.scss']
})
export class OrderSubCatGridComponent implements OnInit {
  @Input() validUserData = "";
  @Input() subcat: any;
  @Input() selected: string;
  @Input() storeMenuFeatureFlag: boolean;
  breakpoint: number;
  heightBox: any;
  @Output() onSubSelectHandler: EventEmitter<any> = new EventEmitter<any>();
  constructor() {

  }

  ngOnInit() {
    if (this.storeMenuFeatureFlag) {
      if (window.innerWidth <= 767) {
        this.breakpoint = 3;
        this.heightBox = '100px';
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
        this.breakpoint = 5;
        this.heightBox = '130px';
      } else if (window.innerWidth >= 1024 && window.innerWidth <= 1279) {
        this.breakpoint = 5;
        this.heightBox = '135px';
      } else if (window.innerWidth >= 1280 && window.innerWidth <= 1535) {
        this.breakpoint = 6;
        this.heightBox = '162px';
      } else {
        this.breakpoint = 6;
        this.heightBox = '240px';
      }
    }
    else {
      if (window.innerWidth <= 499) {
        this.breakpoint = 3;
        this.heightBox = '65px';
      } else if (window.innerWidth >= 500 && window.innerWidth <= 599) {
        this.breakpoint = 3;
        this.heightBox = '70px';
      } else if (window.innerWidth >= 600 && window.innerWidth <= 900) {
        this.breakpoint = 4;
        this.heightBox = '80px';
      } else if (window.innerWidth >= 901 && window.innerWidth <= 1100) {
        this.breakpoint = 4;
        this.heightBox = '100px';
      } else {
        this.breakpoint = 5;
        this.heightBox = '100px';
      }
    }
  }

  onResize(event) {
    if (this.storeMenuFeatureFlag) {
      if (window.innerWidth <= 767) {
        this.breakpoint = 3;
        this.heightBox = '100px';
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
        this.breakpoint = 5;
        this.heightBox = '130px';
      } else if (window.innerWidth >= 1024 && window.innerWidth <= 1279) {
        this.breakpoint = 5;
        this.heightBox = '135px';
      } else if (window.innerWidth >= 1280 && window.innerWidth <= 1535) {
        this.breakpoint = 6;
        this.heightBox = '162px';
      } else {
        this.breakpoint = 6;
        this.heightBox = '240px';
      }
    }
    else {
      if (event.target.innerWidth <= 499) {
        this.breakpoint = 3;
        this.heightBox = '65px';
      } else if (event.target.innerWidth >= 500 && event.target.innerWidth <= 599) {
        this.breakpoint = 3;
        this.heightBox = '75px';
      } else if (event.target.innerWidth >= 600 && event.target.innerWidth <= 900) {
        this.breakpoint = 4;
        this.heightBox = '80px';
      } else if (event.target.innerWidth >= 901 && event.target.innerWidth <= 1100) {
        this.breakpoint = 4;
        this.heightBox = '100px';
      } else {
        this.breakpoint = 5;
        this.heightBox = '100px';
      }
    }
  }

}
