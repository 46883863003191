<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<!-- <div *ngIf="!isLoading"> -->
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="fixActionRow">
    <h1 mat-dialog-title>Hours</h1>
    <div class="ml-6">
      <p class="message">Enter your service hours here.</p>
    </div>
    <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
      <div *ngIf="!isLoading">
      <div [formGroup]="form">
        <div fxLayout="row" class="tables" [ngClass.lt-md]="{'table_mobile': true}" fxLayout.lt-md="row" cols="2"
          *ngFor="let day of days; let di = index">
          <ng-container [formGroup]="form.controls[di]">
            <div fxLayout="row" fxFlex class="cels-data">
              <div class="days-design">{{day}}</div>
              <div class="tog-fielsd">
                <mat-slide-toggle formControlName="is_available"></mat-slide-toggle>
              </div>
              <div class="clears"></div>
            </div>
  
            <div>
              <div fxLayout="row" class="hourss" formArrayName="hours"
                *ngFor="let hour_in_day of form.controls[di].get('hours').controls; let hi = index">
                <ng-container [formGroupName]="hi">
                  <div class="field-right field-top">Available</div>
  
                  <div class="field-right from-to-field" >
                    <mat-form-field>
                      <mat-label>From</mat-label>
                      <input matInput [ngxTimepicker]="timepicker" formControlName="from">
                      <ngx-material-timepicker #timepicker class="special-hour-picker colour">
                      </ngx-material-timepicker>
                    </mat-form-field>
                  </div>
  
                  <div class="field-right field-top">To</div>
  
                  <div class="field-right from-to-field">
                    <mat-form-field>
                      <mat-label>To</mat-label>
                      <input matInput [ngxTimepicker]="timepickerone" formControlName="to">
                      <ngx-material-timepicker #timepickerone class="special-hour-picker">
                      </ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      </div>
    </div>
    <div *ngIf="error" class="form-row alert alert alert-danger ">
      Invalid edit request
    </div>
    <div class="spacer"></div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button class="primary-button color apply-btn-colr">Apply</button>
    </mat-dialog-actions>
  </form>

<!-- </div> -->