<mat-progress-bar mode="indeterminate" *ngIf="isLoading" style="margin-bottom:10px"></mat-progress-bar>
<form [formGroup]="form" (ngSubmit)="submit(form)">
	<mat-dialog-content *ngIf="store.feature_flag['multiple_default_load']">
		<mat-form-field class="!block">
			<mat-select placeholder="Station" formControlName="terminal_id">
				<mat-option ng-selected *ngFor="let terminal of terminalList"
					value="{{terminal.id}}">{{terminal.name}}</mat-option>
			</mat-select>
			<mat-error class="help-block form-error text-danger small" *ngIf="form.controls['terminal_id'].errors?.required &&
	  form.controls['terminal_id'].touched">Please choose station</mat-error>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-content>
		<mat-form-field class="!block">
			<mat-select placeholder="Default Order Occasions" formControlName="default_order_occasion">
				<mat-option ng-selected *ngFor="let occasion of allOccasions"
					value="{{occasion}}">{{occasion}}</mat-option>
			</mat-select>
			<mat-error class="help-block form-error text-danger small" *ngIf="form.controls['default_order_occasion'].errors?.required &&
	  form.controls['default_order_occasion'].touched">Please choose default order occasion</mat-error>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-content>
		<mat-form-field class="!block">
			<mat-select placeholder="Default Time" formControlName="default_time">
				<mat-option ng-selected *ngFor="let time of allTime" value="{{time}}">{{time}}</mat-option>
			</mat-select>
			<mat-error class="help-block form-error text-danger small" *ngIf="form.controls['default_time'].errors?.required &&
	  form.controls['default_time'].touched">Please choose default time</mat-error>
		</mat-form-field>
	</mat-dialog-content>
	<!-- <mat-dialog-actions align="end">
		<button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">OK</button>
	</mat-dialog-actions> -->
	<mat-dialog-actions align="end">
		<a (click)="closePOP()" mat-stroked-button>Cancel</a>
		<button mat-raised-button type="submit" [disabled]="!form.valid" class="primary-button color apply-btn-colr">Apply</button>
	  </mat-dialog-actions>
</form>