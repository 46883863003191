<!-- <div class="grid-container">
  <iframe src="orderUrl" seamless width="375" style="margin-top: -102px !important;"></iframe>

</div> -->

<div class="set-top">
  <div class="set-font">
    <div class="text-set"> Dimensions</div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label class="set-font">Screen Resolution</mat-label>
      <mat-select [(value)]="selectedResolution" (selectionChange)="updateIframe()">
        <mat-option *ngFor="let resolution of resolutions" [value]="resolution.label">
          {{ resolution.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>&nbsp;&nbsp;&nbsp;

  <div class="set-font">
    <div class="text-set"> Orientation</div>
    <mat-form-field appearance="fill" class="full-width1">
      <mat-label class="set-font">Orientation</mat-label>
      <mat-select (selectionChange)="updateOrientation($event)">
        <mat-option value="Horizontal">Horizontal</mat-option>
        <mat-option value="Vertical">Vertical</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <span (click)="refreshScreen()" class="material-icons-outlined refresh-btn">
      sync
    </span>
  </div>
</div>

<!-- style="overflow:auto; position:relative;" -->
<!-- <div>
  <iframe id="frame" [src]="safeUrl" [style.width]="iframeWidth" [style.height]="iframeHeight"
    [style.scale]="iframeScale" [style.position]="iframePosition" [style.top]="iframeTop" [style.left]="iframeLeft"
    [style.transform]="iframeTransform" [ngClass]="cusClass"></iframe>
</div> -->

<div style="height: 100%; width: 100%; position: relative; overflow: auto;" *ngIf="orientationSet == 'Horizontal'">
  <!-- <div class="tv-frame centered-elem"> -->
  <div class="tv-frame1">
    <div class="screen-hr">
      <!-- <iframe id="frame" [src]="safeUrl" [style.width]="iframeWidth" [style.height]="iframeHeight"
        [style.scale]="iframeScale" [style.position]="iframePosition" [style.top]="iframeTop" [style.left]="iframeLeft"
        [style.transform]="iframeTransform" [ngClass]="cusClass"></iframe> -->
      <iframe id="frame" [src]="safeUrl" [style.width]="iframeWidth" [style.height]="iframeHeight"
        [ngClass]="cusClass"></iframe>
    </div>
    <!-- <div class="brand">
    <p class="centered-text brand-text">BRANDNAME</p>
  </div> -->
  </div>
</div>
<div style="height: 100%; width: 100%; position: relative; overflow: auto;" *ngIf="orientationSet == 'Vertical'">
  <div class="tv-frame1" [style.height]="iframeWidth">
    <div class="screen-vir">
      <!-- <iframe id="frame" [src]="safeUrl" [style.width]="iframeHeight" [style.height]="iframeWidth"
        [style.scale]="iframeScale" [style.position]="iframePosition" [style.top]="iframeTop" [style.left]="iframeLeft"
        [style.transform]="iframeTransform" [ngClass]="vir_class"></iframe> -->
      <iframe id="frame" [src]="safeUrl" [style.width]="iframeHeight" [style.height]="iframeWidth"
        [ngClass]="vir_class"></iframe>
    </div>
  </div>
</div>


<div class="under-tv centered-elem">
  <div class="left">
    <div class="connector">

    </div>
    <div class="legs-1">
      <div class="leg-1"></div>
      <div class="leg-2"></div>
    </div>
  </div>

  <div class="right">
    <div class="connector"></div>
    <div class="legs-2">
      <div class="leg-1"></div>
      <div class="leg-2"></div>
    </div>
  </div>
</div>