import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IMqttMessage, IPublishOptions, MqttService} from 'ngx-mqtt';
import {Subject, Subscription} from 'rxjs';
import {IClientSubscribeOptions} from 'mqtt/types/lib/client-options';
import {takeUntil} from 'rxjs/operators';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-terminal-status',
  templateUrl: './terminal-status.component.html',
  styleUrls: ['./terminal-status.component.scss']
})
export class TerminalStatusComponent implements OnInit, OnDestroy {

  isSocketActive = false;
  isDeviceActive = false;
  pingIntervalId;
  isListening;
  listenSub: Subscription;
  lastSeen: Date = new Date();
  batteryPercent;

  @ViewChild('tooltipError',{static: false}) tooltipError: MatTooltip;

  private destroySubject: Subject<void> = new Subject();

  constructor(
    private mqttService: MqttService,
  ) {
    this.pingIntervalId = setInterval(this.ping.bind(this), 10000);
    this.mqttService.onConnect.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      this.isSocketActive = true;
    });

    this.mqttService.onReconnect.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      this.isListening = false;
    });

    this.mqttService.onClose.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      this.isSocketActive = false;
      this.isListening = false;
    });
  }

  ngOnInit() {
    this.reconnectMqttServer();
  }

  ping(){
    const payload = JSON.stringify({command: 'PING'});
    const terminalId = localStorage.getItem('selectedTerminal');
    this.publish(payload);
    if(this.getIdleTimeInMinutes() > 1 && terminalId && terminalId.startsWith('CHB')){
      this.isDeviceActive = false;
      if(this.tooltipError){this.tooltipError.show();}
    }
    if(!this.isListening){
      this.listen();
    }
  }

  getIdleTimeInMinutes(){
    if(!this.lastSeen){
      this.lastSeen = new Date();
    }
    const now = new Date();
    const diffMs =  (now.getTime() - this.lastSeen.getTime());
    return Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  }

  reconnectMqttServer(){
    this.mqttService.connect();
    const payload = {
      command: 'RECONNECT'
    };
    this.publish(JSON.stringify(payload));
  }

  publish(payload: string){
    const qos = 2;
    const terminalId = localStorage.getItem('selectedTerminal');
    if(terminalId){
      this.mqttService.unsafePublish(`/link/${terminalId}/sub`, payload, {qos} as IPublishOptions);
    }
  }

  listen(){
    try{
      const qos = 2;
      const terminalId = localStorage.getItem('selectedTerminal');
      if(terminalId){
        if(this.listenSub){
          this.listenSub.unsubscribe();
        }

        this.listenSub = this.mqttService.observe(`/link/${terminalId}/pub`,
          {qos} as IClientSubscribeOptions).pipe(takeUntil(this.destroySubject)).subscribe((message: IMqttMessage) => {
          console.log(message.payload.toString());
          console.log(this.getIdleTimeInMinutes());

          const payload = JSON.parse(message.payload.toString());
          if(payload.battery) {
            this.batteryPercent = +payload.battery;
          }
          this.lastSeen = new Date();
          this.isDeviceActive = true;
          this.isListening = true;
          this.isSocketActive = true;
        });
      }
    }catch (e){
      console.log('error listening');
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    if(this.pingIntervalId){
      clearInterval(this.pingIntervalId);
    }
  }
}
