import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AdjustPayment, RefundPayment} from '../_models/payment';

@Injectable()
export class PaymentService {
  endPoint = environment.apiUrl;
  paymentEndpoint = `${this.endPoint}/backend/payment`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient) {}

  refund(payment: RefundPayment | AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/refund/`, payment)
      .pipe(response => {
        return response;
      });
  }

  refundStripe(payment: RefundPayment | AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/refund/stripe`, payment)
      .pipe(response => {
        return response;
      });
  }

  refundGateway(payment: RefundPayment | AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/gateway/refund/`, payment)
      .pipe(response => {
        return response;
      });
  }

  adjust(payment: AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/adjust/`, payment)
      .pipe(response => {
        return response;
      });
  }

  adjustTip(payment: AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/add_tip/`, payment)
      .pipe(response => {
        return response;
      });
  }

  adjustGateway(payment: AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/gateway/adjust/`, payment)
      .pipe(response => {
        return response;
      });
  }

  addTipGateway(payment: AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/gateway/add_tip/`, payment)
      .pipe(response => {
        return response;
      });
  }

  adjustStripe(payment: AdjustPayment ) {
    return this.http.post<any>(`${this.paymentEndpoint}/adjust/stripe`, payment)
      .pipe(response => {
        return response;
      });
  }



  connectStripe(storeId, code){
    return this.http.get(`${this.paymentEndpoint}/stripe/store/${storeId}/configure/?code=${code}`, this.httpOptions);
  }

  getStripeStore(storeId){
    return this.http.get(`${this.paymentEndpoint}/stripe/store/${storeId}/get/`, this.httpOptions);
  }

  getStripeLoginLink(storeId){
    return this.http.get(`${this.paymentEndpoint}/stripe/store/${storeId}/login_link/`, this.httpOptions);
  }

  getCardConnectAuths(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${orderId}/auth_transactions/`);
  }

  getCardConnectVoids(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${orderId}/void_transactions/`);
  }

  getCardConnectRefunds(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${orderId}/refund_transactions/`);
  }

  getCardConnectAdjust(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${orderId}/adjust_transactions/`);
  }

  getCardConnectPending(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${orderId}/refund_pending_transactions/`);
  }

  cancelCardConnectPending(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${orderId}/cancel_refund_pending_transactions/`);
  }

  getPGTransactions(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/pg/transactions/${orderId}/transactions/`);
  }

  refundPGTransactions(transactionId){
    return this.http.post<any>(`${this.endPoint}/backend/v2/pg/transactions/${transactionId}/refund_transaction/`, {}, this.httpOptions);
  }

  refundPendingPGTransactions(transactionId){
    return this.http.post<any>(`${this.endPoint}/backend/v2/pg/transactions/${transactionId}/refund_pending_transactions/`, {}, this.httpOptions);
  }

  cancelRefundPendingPGTransactions(transactionId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/pg/transactions/${transactionId}/cancel_refund_pending_transactions/`, this.httpOptions);
  }

  getPOSTransactions(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/pos/transactions/${orderId}/transactions/`);
  }

  getPOSHistory(orderId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/pos/transactions/${orderId}/history/`);
  }

  getSplitTransactions(orderId, includeTip=false){
    return this.http.get<any>(`${this.endPoint}/backend/v2/pos/split/${orderId}/transactions/?is_tip=${includeTip}`, this.httpOptions);
  }

  tipTransactions(transactionId){
    return this.http.get<any>(`${this.endPoint}/backend/v2/transactions/${transactionId}/tip_transactions/`, this.httpOptions);
  }

}
