import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CampaignDialogComponent} from 'src/app/campaign/campaign-dialog/campaign-dialog.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UploadService} from 'src/app/_services/upload.service';
import {environment} from 'src/environments/environment';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {disclaimer} from 'src/app/constants';


@Component({
  selector: 'app-new-gift-cards-dialog',
  templateUrl: './new-gift-cards-dialog.component.html',
  styleUrls: ['./new-gift-cards-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewGiftCardsDialogComponent implements OnInit {

  form: UntypedFormGroup;
  isLoading;
  store;
  image: File[];
  imageStr: string
  corpList: any;
  selectedCorp: any;

  constructor(
    public dialogRef: MatDialogRef<CampaignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    private uploadService: UploadService
  ) {
    this.store = data.store;
  }

  ngOnInit() {
    if (this.data.gift) {
      this.storeService.getCorporateList(this.store.id).subscribe((data:any) => {
        this.corpList = data.data;
        this.form = this.getFormData(this.data.gift);
        this.imageStr = this.data.gift.image;
      });
    } else {
      this.corporateList();
      this.form = this.getFormData(null);
    }
    
  }

  corporateList() {
    this.storeService.getCorporateList(this.store.id).subscribe((data:any) => {
      this.corpList = data.data;
    });
  }

  getFormData(data) {
    let expiry = data && data.ends ? moment(data.ends).toDate() : undefined
    let fg = new UntypedFormGroup({
      store_id: new UntypedFormControl(this.store.id),
      id: new UntypedFormControl(data ? data.id : undefined),
      title: new UntypedFormControl(data ? data.title : undefined, Validators.required),
      description: new UntypedFormControl(data ? data.description : undefined, Validators.required),
      face_value: new UntypedFormControl(data ? data.list_price : undefined, Validators.required),
      sale_price: new UntypedFormControl(data ? data.sale_price : undefined, Validators.required),
      expires: new UntypedFormControl(data ? expiry : undefined),
      quantity: new UntypedFormControl(data ? data.limit : undefined),
      disclaimer: new UntypedFormControl(data ? data.disclaimer : disclaimer.replace("<merchant name>", this.store.store_name), Validators.required),
      offer_type: new UntypedFormControl(data ? data.offer_type : undefined, Validators.required),
      corporate: new UntypedFormControl(null),
    });
    if (data) {
      fg.controls['face_value'].disable();
      fg.controls['sale_price'].disable();

      if (this.corpList) {
        const find = this.corpList.filter((cr) => cr.id == this.data.gift.corporate)
        if (find[0]) {
          this.selectedCorp = find[0].id;
        }
      }
      
    }
    
    return fg;
  }

  corpChange(e) {
    this.form.get('corporate').setValue(e.value);
  }

  create() {
    console.log(this.image);
    let files = new Set<File>();
    if (this.image && this.image.length > 0) {
      files.add(this.image[0])
    }

    let payload = this.form.getRawValue();
    if (payload.expires) {
      payload.expires = moment(payload.expires).toISOString().slice(0, 10);
    }


    if (payload.offer_type === 'FACE') {
      payload.sale_price = payload.face_value
    }

    /* When Type is CUSTOM */
    if (payload.offer_type === 'CUSTOM') {
      payload.sale_price = 0;
      payload.face_value = 0;
    }

    if (payload.corporate == undefined || payload.corporate == '') {
      payload.corporate = null;
    }

    let progress = this.uploadService.upload(`${environment.apiUrl}/backend/v2/gift/`, files, payload)
    console.log('progress',progress);

    forkJoin(Object.values(progress).map(p => p.progress)).subscribe(
      data => console.log(data),
      err => console.log(err),
      () => this.dialogRef.close())
  }

  setImageStr(image) {
    this.imageStr = image;
  }

  setImageFile(image) {
    this.image = image;
  }

  changeValidation(event) {
    if (event.value === 'FACE') {
      this.form.get('sale_price').setValidators(null);
    } else if (event.value === 'CUSTOM') {
      this.form.get('sale_price').setValidators(null);
      this.form.get('face_value').setValidators(null);
    } else {
      this.form.get('sale_price').setValidators(Validators.required);
      this.form.get('face_value').setValidators(Validators.required);
    }
    this.form.get('sale_price').updateValueAndValidity();
    this.form.get('face_value').updateValueAndValidity();
  }
}
