import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CampaignService} from '../../../_services/campaign.service';
import {User} from '../../../_models/contacts';
import {Store} from '../../../_models/store';
import {MatSort} from '@angular/material/sort';
import {ContactDialogComponent} from '../../contact/contact-dialog/contact-dialog.component';
import {merge, of as observableOf, Subject} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-campaign-custom-list-table',
  templateUrl: './campaign-custom-list-table.component.html',
  styleUrls: ['./campaign-custom-list-table.component.scss']
})
export class CampaignCustomListTableComponent implements OnInit, AfterViewInit {

  @Input() customList;
  displayedColumns: string[] = ['phone_number', 'created', 'name'];
  data: User[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  unsubscribe = new Subject();
  @Input() store: Store;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  filter;
  @Input() isBlocked;
  @Output() refreshParent = new EventEmitter();


  customListUsers = [];
  constructor(private campaignService: CampaignService,
              private dialog: MatDialog) { }

  ngOnInit() {
    console.log("loading users");
    if(this.customList){
      console.log(this.customList);
      this.campaignService.getCampaignListUsers(this.customList.id).subscribe(data => {
        this.data = data.users;
        this.resultsLength = data.stats.total;
      });
    }
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          console.log("searching");
          this.isLoadingResults = true;
          return this.campaignService.getCampaignListUsers(this.customList.id,
            this.paginator.pageIndex, this.paginator.pageSize, this.filter);
        }),
        map(data => {
          console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.stats.total;
          return data.users;
        }),
        catchError((e) => {
          console.log(e);
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  openEditDailog(user) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: isMobile ? '100vw' : '500px',
      height: isMobile ? '100vh' : '620px',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'store': this.store,
        'user': user}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshParent.emit(true);
      this.ngAfterViewInit();
    });
  }
}
