<form name="addTimeFrom" [formGroup]="addTimeFrom" (ngSubmit)="addTimeFromSubmit()">
  <h1 mat-dialog-title>Add Clock Time</h1>
  <div mat-dialog-content class="xs:!pl-2.5">
    <div fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="50%" class="field-gap">
          <mat-form-field appearance="fill" class="field-size">
            <mat-label>User Name</mat-label>
            <mat-select formControlName="user_name">
              <mat-option *ngFor="let userName of userNames" [value]="userName">
                {{ userName.first_name }} {{userName.last_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="50%">
          <mat-form-field appearance="fill" class="field-size">
            <mat-label>Number Of Breaks</mat-label>
            <input matInput placeholder="" formControlName="number_of_breaks" type="number">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50%" class="field-gap">
          <mat-form-field appearance="fill" class="field-size xs:text-xs">
            <mat-label>Clocked-In</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker1" formControlName="clocked_in"
              [min]="clockedDateTime" [dateTimeFormat]="clockedDateTime">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1 enableMeridian="true">
              <ngx-mat-datepicker-actions>
                <button mat-button ngxMatDatepickerCancel mat-stroked-button>Cancel</button>
                <button mat-raised-button color="primary" class="apply-btn-colr" ngxMatDatepickerApply>Apply</button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div fxFlex="50%">
          <mat-form-field appearance="fill" class="field-size xs:text-xs">
            <mat-label>Clocked-Out</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker2"
              formControlName="clocked_out" [min]="addTimeFrom.get('clocked_in').value" [dateTimeFormat]="clockedOutDateTime1">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker2 enableMeridian="true">
              <ngx-mat-datepicker-actions>
                <button mat-button ngxMatDatepickerCancel mat-stroked-button>Cancel</button>
                <button mat-raised-button color="primary" class="apply-btn-colr" ngxMatDatepickerApply>Apply</button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50%" class="field-gap">
          <mat-form-field appearance="fill" class="field-size xs:text-xs">
            <mat-label>Start Break</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker3" formControlName="start_break"
              [min]="addTimeFrom.get('clocked_in').value" [max]="addTimeFrom.get('clocked_out').value" [dateTimeFormat]="startTime1">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker3 enableMeridian="true">
              <ngx-mat-datepicker-actions>
                <button mat-button ngxMatDatepickerCancel mat-stroked-button>Cancel</button>
                <button mat-raised-button color="primary" class="apply-btn-colr" ngxMatDatepickerApply>Apply</button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div fxFlex="50%">
          <mat-form-field appearance="fill" class="field-size xs:text-xs">
            <mat-label>End Break</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker4" formControlName="end_break" [min]="addTimeFrom.get('start_break').value"
              [max]="addTimeFrom.get('clocked_out').value" [dateTimeFormat]="endTime1">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker4 enableMeridian="true">
              <ngx-mat-datepicker-actions>
                <button mat-button mat-stroked-button ngxMatDatepickerCancel>Cancel</button>
                <button mat-raised-button color="primary" class="apply-btn-colr" ngxMatDatepickerApply>Apply</button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row" style="padding-bottom: 17px;">
    <button mat-stroked-button mat-dialog-close class="xs:mr-5 sm:mr-5">Cancel</button>
    <button mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr"
      [disabled]="!addTimeFrom.valid">Submit</button>
  </mat-dialog-actions>
</form>