import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import {LoyaltyPointsService} from 'src/app/_services/loyalty-points.service';

@Component({
  selector: 'app-total-user-chart',
  templateUrl: './total-user-chart.component.html',
  styleUrls: ['./total-user-chart.component.scss']
})
export class TotalUserChartComponent implements OnInit {

  chart: any;
  @ViewChild('dChart', { static: false } as any) dChart: ElementRef;
  // @Input() jsonStatusArray: any = [0.2, 0.1, 0.2, 0.69];
  // @Input() cutOut: number = 85;
  // @Input() chartStatusLabels: any = [
  //   'label 1',
  //   'label 2',
  //   'label 3',
  //   'label 4',
  //   'label 5',
  // ];
  // // @Input() cutOut: number = 75;
  // @Input() statusBackgroundColors: any = [
  //   '#E15D44',
  //   '#55B4B0',
  //   '#DFCFBE',
  //   '#9B2335',
  //   '#5B5EA6',
  // ];



  @Input() chartStatusLabels: any = [];
  jsonStatusArray: any = [];
  @Input() cutOut: number = 85;
  statusBackgroundColors: any = [];


  constructor(public loyaltyPointsService: LoyaltyPointsService) {
    this.loyaltyPointsService.getRefresh().subscribe((value: any) => {
      if (this.chart) this.chart.destroy();
      if (value) {
        this.statusBackgroundColors = value.colors;
        this.jsonStatusArray = value.numbers;
        if(value.isUpdate == 1){
          this.updateLoyaltyGraph();
        }
      }
    })
  }

  ngOnInit() {
    this.updateLoyaltyGraph();
  }

  updateLoyaltyGraph() {
    let cvs: any;
    cvs = this.dChart.nativeElement;
    this.chart = new Chart(cvs, {
      type: 'doughnut',
      data: {
        // labels: this.chartLabels,
        datasets: [
          {
            data: this.jsonStatusArray,
            backgroundColor: this.statusBackgroundColors,
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: false,
        legend: {
          display: false,
        },
        cutoutPercentage: this.cutOut,
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
      },
    });

  }

}
