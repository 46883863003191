<div class="floor-plan p-4 h-full w-full">
    <div class="flex justify-between items-center mb-4">
        <div class="flex-1 text-center">
            <span class="text-xl font-bold ml-[12%]">{{ data.floor.name }}</span>
        </div>

        <div class="flex justify-end">
            <button class="apply-btn-colr text-white px-4 py-2 rounded-md hover:bg-green-600" (click)="addObject()">Add Object</button>
            <button class="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 ml-2.5" (click)="addTable()">Add Table</button>
        </div>
    </div>
    <div class="w-full h-[80vh] flex items-center justify-center border-2 border-black">
        <div #stageContainer class="relative"></div>
    </div>
    <div class="flex justify-end space-x-4 mt-4">
        <button class="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            (click)="onCancel()">Cancel</button>
        <button class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            (click)="onSubmit()">Submit</button>
    </div>
</div>