import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({ providedIn: 'root' })
export class LoyaltyPointsService {

  endpoint = environment.apiUrl;
  loyaltyurl = this.endpoint + '/backend';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private refreshLoyalty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public getRefresh(): Observable<any> {

    return this.refreshLoyalty.asObservable();
  }

  public setRefresh(value: any): void {

    this.refreshLoyalty.next(value);
  }

  constructor(private http: HttpClient) {}


  getStorePointsCheck(payload) {
    return this.http.post(this.loyaltyurl + `/add-loyalty-points-check-store/`, payload,  this.httpOptions);
  }

  getTierlist(store) {
    return this.http.get(this.loyaltyurl + `/points_setting_filter_get/${store}/`, this.httpOptions);
  }

  updatePointsValue(payload) {
    return this.http.post(this.loyaltyurl + `/update_points_setting/`, payload, this.httpOptions);
  }

  updateLoyaltyExpireDays(payload) {
    return this.http.post(this.loyaltyurl + `/update_loyalty_expire_days/`, payload, this.httpOptions);
  }

  createTierPoints(payload) {
    return this.http.post(this.loyaltyurl + `/add-loyalty-points-setting/`, payload, this.httpOptions);
  }

  deletedTier(payload) {
    return this.http.post(this.loyaltyurl + `/deleted_points_setting/`, payload, this.httpOptions);
  }

  getListOfUsers(storeid: any) {
    return this.http.get(this.loyaltyurl + `/${storeid}/total_first_user_visit/`);
  }

  getTotalPoints(storeid: any) {
    return this.http.get(this.loyaltyurl + `/all_points/${storeid}/`);
  }

  // getTableUserList(storeid: number) {
  //   return this.http.get(this.loyaltyurl + `/store/user/${storeid}/list`)
  // }

  getTableUserList(storeid: number, pageNumber: any, pageSize: any, filterValue: any, sort_id:any, sort_dir:any, fromDate:any, toDate: any) {
    const params = new HttpParams()
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    .set('search_key', filterValue)
    .set('sort_id', sort_id)
    .set('sort_dir', sort_dir)
    .set('from_date', fromDate)
    .set('to_date', toDate)
    return this.http.get(this.loyaltyurl + `/store/user/${storeid}/list/`, { params });
  }

  getLastThreeOrder(payload: any, fromDateInner:any, toDateInner: any) {
    const params = new HttpParams()
    .set('from_date_inner', fromDateInner)
    .set('to_date_inner', toDateInner)
    return this.http.put(this.loyaltyurl + `/store/user/point/list/`, JSON.stringify(payload), { params });
  }

  userPointsAdd(payload: any) {
    return this.http.put(this.loyaltyurl + `/store/user/point/add/`, JSON.stringify(payload), this.httpOptions);
  }

  userPointsSubtract(payload: any) {
    return this.http.put(this.loyaltyurl + `/store/user/point/sub/`, JSON.stringify(payload), this.httpOptions);
  }
}
