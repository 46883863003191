import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from 'src/app/_services/user.service';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {PermissionService} from '../../_services/permission.service';
import {Permission} from '../../_models/permission';
import {Subscription} from 'rxjs';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-pin-confirm',
  templateUrl: './pin-confirm.component.html',
  styleUrls: ['./pin-confirm.component.scss']
})
export class PinConfirmComponent implements OnInit {

  form: UntypedFormGroup;
  pin;
  allUserPins: any = [];
  allUserData: any;
  getUserPin: any;
  store: Store;
  userObject: any;
  errorMessage = false;
  permission: Permission;
  storeSub: Subscription;
  description: any;
  constructor(public dialogRef: MatDialogRef<PinConfirmComponent>,
              private formBuilder: UntypedFormBuilder,
              private userService: UserService,
              private storeService: StoreService,
              private permissionService: PermissionService,
              public dialog: MatDialog,) {
                this.storeSub = this.storeService.current.subscribe((store) => {
                  if (store) {
                    this.store = Object.assign(new Store(), store);
                    this.login();
                  }
                });
              }

  ngOnInit() {
    this.form = this.formBuilder.group({
      pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      description: ['', [Validators.required]]
    });
    this.login()
  }

  onSubmit(pin, descr){
    this.description = descr;
    let per = this.loginUser(pin);
    // this.dialogRef.close({permission:per, 'description':descr});
  }

  close(){
    this.dialogRef.close({cancel:"canceled"});
  }

  login() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.getUserPin = this.userService
      .getAllUser(this.store.id, user["username"])
      .subscribe((data) => {
        console.log("data...", data)
        this.allUserData = data;
        this.allUserPins = data.map((item) => {
          return item["user_profile"].pin;
        });
      });
  }

  loginUser(pin) {
    const validPin = this.allUserPins.includes(pin);
    if (validPin) {
      let result = this.permissionService.getUserPermissionByPINSync(this.store.id, pin).then(per =>{
        this.dialogRef.close({'permission':per, 'description':this.description});
      });
    } else {
      let dialogRef = this.dialog.open(AlertsMessagesComponent, {
        disableClose: true,
        width: '364px',
        minHeight: '20vh',
        data: {
          message: pin + ' : Incorrect PIN! Please retry!'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.errorMessage = true;
          this.form.reset();
        }
      });
      // alert(pin + ' : Incorrect PIN! Please retry!' );

    }
    // return validPin;
  }

}
