import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HostedMenuService{
  endpoint = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };


  constructor(private http: HttpClient){}

  getHostedMenu(storeId){
    return this.http.get<any[]>(this.endpoint + `/backend/v2/wizard/hosted_menu/${storeId}/`, this.httpOptions);
  }

  deleteHostedMenu(menuId){
    return this.http.delete<any[]>(this.endpoint + `/backend/v2/wizard/hosted_menu/${menuId}/`, this.httpOptions);
  }
}
