<div class="flex flex-col p-5">
  <div class="pb-12">
    <p class="p-2" *ngIf="data.for_which != 'drawer'">{{data.message}}</p>
  <p class="p-2 text-xl font-normal" *ngIf="data.for_which == 'drawer'">{{data.message}}</p>
  </div>
  <div class="flex justify-end">
    <span *ngIf="data.for_which != 'drawer'">
      <button mat-stroked-button (click)="close(false)" *ngIf="showCancel">Cancel</button>
      <button mat-flat-button color="primary" class="confirm-btn ml-2.5" (click)="save(true)" *ngIf="showCancel">Confirm</button>
      <button mat-flat-button color="primary" class="apply-btn-colr" (click)="save(true)"
        *ngIf="!showCancel && !editSec">Ok</button>
      <button mat-stroked-button (click)="close(false)" *ngIf="editSec">No</button>
      <button mat-flat-button color="primary" class="apply-btn-colr ml-2.5" (click)="save(true)" *ngIf="editSec">Yes</button>
    </span>

    <span *ngIf="data.for_which == 'drawer'">
      <button mat-flat-button color="primary" class="apply-btn-colr !h-[51px] w-[100px] text-lg" (click)="save(true)"
        *ngIf="!showCancel && !editSec">Ok</button>
    </span>
  </div>
</div>

