<form class="fixActionRow">
  <h2 mat-dialog-title>
    Edit Contact
  </h2>
  <mat-dialog-content id="campaign-dialog">
    <form [formGroup]="form" (onSubmit)="update()">
      <div *ngIf="user?.phone_number; then disabled else new"></div>
      <ng-template #disabled>
        <mat-form-field class="full-width" mat-focused>
          <mat-label>Contact Number</mat-label>
          <input matInput placeholder="Contact Number" disabled [value]="user?.phone_number || '' | phone">
        </mat-form-field>
      </ng-template>
      <ng-template #new>
        <mat-form-field class="full-width" mat-focused>
          <mat-label>Contact Number</mat-label>
          <input formControlName="phone_number" appPhoneMask matInput placeholder="Contact Number">
        </mat-form-field>
      </ng-template>



      <mat-form-field class="full-width" mat-focused>
        <mat-label>Name</mat-label>
        <input matInput #name placeholder="Name" formControlName="name">
      </mat-form-field>

      <mat-checkbox formControlName="is_blocked">Block</mat-checkbox>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="actions">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-raised-button class="apply-btn-colr" color="primary" (click)="update()"
        [disabled]=" isLoading || !form.valid ">Update</button>
    </div>
  </mat-dialog-actions>
</form>