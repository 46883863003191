import { Component, Inject, OnInit } from '@angular/core';
import { StoreService } from 'src/app/_services/store.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadService } from 'src/app/_services/upload.service';

@Component({
  selector: 'app-store-favicon',
  templateUrl: './store-favicon.component.html',
  styleUrls: ['./store-favicon.component.scss']
})
export class StoreFaviconComponent implements OnInit {
  file: File | null = null;
  storeId: string | null = null;
  isLoading = false;
  file1: any;

  constructor(
    private storeService: StoreService,
    public uploadService: UploadService,
    public dialogRef: MatDialogRef<StoreFaviconComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.file1 = this.data.data;
    console.log('Previous file:', this.file1);
  }

  onFilesAdded(event: any) {
    const selectedFile = event.target.files[0] || null;
    if (selectedFile) {
      this.file = selectedFile;
    }
  }

  uploadFile() {
    if (!this.file) return;

    const formData = new FormData();
    formData.append("document", this.file, this.file.name);
    formData.append("store_id", this.storeId);
    formData.append("version", "");

    this.isLoading = true;
    this.uploadService.favUpload(formData).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;
      this.dialogRef.close(resData);
    }, error => {
      this.isLoading = false;
    });
  }
}