import {Component, Input, OnInit} from '@angular/core';
import {OrdersWithPayment} from 'src/app/_models/order';

@Component({
  selector: 'app-order-payment-breakup',
  templateUrl: './order-payment-breakup.component.html',
  styleUrls: ['./order-payment-breakup.component.scss']
})
export class OrderPaymentBreakupComponent implements OnInit {

  @Input() orderWithPayment: OrdersWithPayment;
  @Input() splitRow: any;

  isLoading = false;

  constructor() { }

  ngOnInit() {
    
  }

  isCashOrOthers(){
    return this.orderWithPayment.payment.type === 'CASH' ||
    this.orderWithPayment.payment.type === 'GIFT' ||
    this.orderWithPayment.payment.type === 'OTHER';
  }

  isNonCash(){
    return this.orderWithPayment.payment.type === 'CREDIT' || this.orderWithPayment.payment.type === 'SPLIT' || this.orderWithPayment.payment.type === 'STORE_CREDIT' || this.orderWithPayment.payment.type === 'CREDIT_KEYED';
  }

  getItemCounts(){
    const item_count = this.orderWithPayment.items.reduce((sum, item) => sum + +item.quantity, 0);
    const misc_item_count = this.orderWithPayment.misc_items.reduce((sum, item) => sum + +item.quantity, 0);
    return item_count + misc_item_count
  }


}
