import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ColumnManagementDialogComponent } from './column-management-dialog/column-management-dialog.component';

export interface PeriodicElement {
  id: any;
  screen_duration: any;
  column_pre_screen: any;
  screen_url: any;
  screen_view_type: any;
  hide_key_display: any;
  action: any;
}

@Component({
  selector: 'app-column-management',
  templateUrl: './column-management.component.html',
  styleUrls: ['./column-management.component.scss']
})
export class ColumnManagementComponent {
  displayedColumns: string[] = [
    "id",
    "screen_duration",
    "column_pre_screen",
    "screen_url",
    "screen_view_type",
    "hide_key_display",
    "action",
  ];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  storeId: any;
  storeArr: any;
  screenData: any;
  endpoint: any;
  leaflogixendpoint: any;
  showUrl: any = [];
  orderUrl;
  orderUrls;
  public elementSrc = [];
  ertertr: any;
  src;
  aaa: any = false;
  @ViewChild("iframe11", { static: false }) iframe11: ElementRef;
  abc: any;
  storeType: any;
  constructor(
    private digitalDisplayService: DigitalDisplayService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
  }
  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.leaflogixendpoint = environment.leaflogixApiEndpoint;
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");
    this.getColumnList();
    this.storeArr = environment.storeArr
  }

  addColumn() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(ColumnManagementDialogComponent, {
      data: {
        view: 'add',
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getColumnList();
      }
    });
  }
  editColumn(data) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(ColumnManagementDialogComponent, {
      data: {
        view: 'edit',
        data: data
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getColumnList();
      }
    });
  }

  previewScreen(element) {
    let urlCreate: string;
    let type: string;

    if (this.storeType === "Leaflogix") {
      urlCreate = `${this.leaflogixendpoint}/pos-display/tv/${this.storeId}/${element.id}/`;
      type = '1';
    } else {
      urlCreate = `${this.endpoint}/backend/tv/${this.storeId}/${element.id}/`;
      type = '2';
    }

    const routerUrl = `${window.location.origin}/#/tv-management-screen/${this.storeId}/${element.id}/${type}`;
    window.open(routerUrl, '_blank');
  }

  deleteColumn(data) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(ColumnManagementDialogComponent, {
      data: {
        view: 'delete',
        id: data.id
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getColumnList();
      }
    });
  }

  getColumnList() {
    this.digitalDisplayService.getTVScreen(this.storeId).subscribe((data: any) => {
      this.screenData = data.data;
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.screenData);
      this.dataSource.paginator = this.paginator;
    });
  }
}
