import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '../_models/store';
import {Category, Menu} from '../_models/menu';

@Injectable()
export class MenuService {

  constructor(private http: HttpClient) { }
  endpoint = environment.apiUrl;
  menuEndpoint = `${this.endpoint}/backend/business/store/`;
  graphMenu = this.endpoint;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private source = new BehaviorSubject<Menu>(null);
  private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private refresh1: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  current = this.source.asObservable();

  public getRefresh(): Observable<any> {

    return this.refresh.asObservable();
  }

  public setRefresh(value: any): void {

    this.refresh.next(value);
  }

  public getRefresh1(): Observable<any> {

    return this.refresh1.asObservable();
  }

  public setRefresh1(value: any): void {

    this.refresh1.next(value);
  }

  getStoreMenu(store: Store) {
    return this.http.get(this.menuEndpoint + `${store.id}/menu_management/menu_group/`, this.httpOptions);
  }

  addMenu(store: Store, payload: any) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_group/add/`,
      payload, this.httpOptions);
  }

  updateCategory(store: Store, payload: Category) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_group/${payload.id}/update/`,
      payload, this.httpOptions);
  }

  updateSection(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_group/update_menu_section/`,
      payload, this.httpOptions);
  }

  deleteCategory(store: Store, payload) {
    return this.http.get(this.menuEndpoint + `${store.id}/menu_management/menu_group/${payload.id}/delete`, this.httpOptions);
  }

  deleteMenu(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_group/delete/`, payload, this.httpOptions);
  }

  getStoreMenuItems(store: Store) {
    return this.http.get(this.menuEndpoint + `${store.id}/menu_management/menu_items/`, this.httpOptions);
  }

  getStoreMenuItemsV2(store: Store) {
    return this.http.get(this.menuEndpoint + `${store.id}/menu_management/menu_items/v2/`, this.httpOptions);
  }


  addMenuItemGroup(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_item_group/${payload.menu_group.id}/add/`,
      payload, this.httpOptions);
  }

  updateMenuItemGroup(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_item_group/${payload.id}/update/`,
      payload, this.httpOptions);
  }

  deleteMenuItemGroup(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_item_group/${payload.id}/delete/`,
      payload, this.httpOptions);
  }

  getStoreModifierOptions(store: Store) {
    return this.http.get(this.menuEndpoint + `${store.id}/menu_management/modifier_options/`, this.httpOptions);
  }

  addModifierOption(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/modifier_options/${payload.modifier_group}/add/`,
      payload, this.httpOptions);
  }

  updateModifierOption(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/modifier_options/${payload.id}/update/`,
      payload, this.httpOptions);
  }

  deleteModOption(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/modifier_options/${payload.id}/delete/`,
      payload, this.httpOptions);
  }

  deleteModGroup(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/modifier_group/${payload.id}/delete/`,
      payload, this.httpOptions);
  }

  addModifierGroup(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/modifier_group/add/`,
      payload, this.httpOptions);
  }

  updateModifierGroup(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/modifier_group/${payload.id}/update/`,
      payload, this.httpOptions);
  }

  getStoreItemModifierMapping(store: Store) {
    return this.http.get(this.menuEndpoint + `${store.id}/menu_management/menu_item_modifier_group_mapping/menu_items`, this.httpOptions);
  }

  updateMenuItemModGroupMapping(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_item_modifier_group_mapping/${payload.id}/update/`,
      payload, this.httpOptions);
  }

  updateMenuItem(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_items/${payload.id}/update/`,
      payload, this.httpOptions);
  }

  deleteMenuItem(store: Store, payload) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_items/${payload.id}/delete/`,
      payload, this.httpOptions);
  }

  addMenuItem(store: Store, payload: any) {
    return this.http.post(this.menuEndpoint + `${store.id}/menu_management/menu_items/${payload.menu_item_group_id}/add/`,
      payload, this.httpOptions);
  }

  getAssignedModGroups(store: Store, selectedItemId: any) {
    return this.http.get(this.menuEndpoint +
      `${store.id}/menu_management/menu_item_modifier_group_mapping/${selectedItemId}/modifier_groups/`,
      this.httpOptions);
  }

  addModGroupMapping(store: Store, payload) {
    return this.http.post(this.menuEndpoint +
      `${store.id}/menu_management/menu_item_modifier_group_mapping/${payload.menu_item.id}/add/`,
      payload, this.httpOptions);
  }

  deleteItemModMapping(store: Store, payload) {
    return this.http.post(this.menuEndpoint +
      `${store.id}/menu_management/menu_item_modifier_group_mapping/${payload.id}/delete/`,
      this.httpOptions);
  }


  updateMenuGroup(store: Store, menuGroupId, payload) {
    return this.http.post(`${this.menuEndpoint}${store.id}/menu_management/menu_group/${menuGroupId}/update/`, payload, this.httpOptions);
  }

  getMenuChartGraph(store: Store, data: any) {
    return this.http.get(this.graphMenu + `/backend/insights/${store}/product-mix-menu-breakout/` + '?start_date=' + data.start_date + '&end_date=' + data.end_date + '&start_time=' + data.start_time + '&end_time=' + data.end_time, this.httpOptions);
  }

  getGrossChartGraph(store: Store, data: any) {
    return this.http.get(this.graphMenu + `/backend/insights/${store}/product-mix-gross-sales/` + '?start_date=' + data.start_date + '&end_date=' + data.end_date + '&start_time=' + data.start_time + '&end_time=' + data.end_time, this.httpOptions);
  }

  getLineChartGraph(store: Store, data: any) {
    return this.http.get(this.graphMenu + `/backend/insights/${store}/product-mix-category-performance/` + '?start_date=' + data.start_date + '&end_date=' + data.end_date, this.httpOptions);
  }

  getProductMixTable(store: Store, data: any) {
    return this.http.get(this.graphMenu + `/backend/insights/${store}/product-mix-table-data/` + '?start_date=' + data.start_date + '&end_date=' + data.end_date + '&start_time=' + data.start_time + '&end_time=' + data.end_time, this.httpOptions);
  }

  getManageFilters(store: Store) {
    return this.http.get(this.graphMenu + `/backend/insights/${store}/mix-reporting-filter-get/`, this.httpOptions);
  }

  updateManageFilter(store: Store, data: any) {
    return this.http.post(this.graphMenu + `/backend/insights/${store}/mix-reporting-filter-update/`, data, this.httpOptions);
  }

  downloadCsvFile(data: any) {
    return this.http.post(this.graphMenu + `/backend/insights/export-csv-product-mixing/`, data, this.httpOptions);
  }
}
