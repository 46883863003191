import {Injectable} from '@angular/core';
import {DraftItem, DraftMiscItem, DraftOccasion, DraftOrder, InstantDiscount, Tip} from '../_models/order-draft';
import {BehaviorSubject, Subject} from 'rxjs';
import {Order} from '../_models/order';

@Injectable({
  providedIn: 'root'
})
export class DraftOrderService {

  draftOrder: DraftOrder = null;
  draftOccasion: DraftOccasion = new DraftOccasion({
    occasion: 'PICKUP',
    time: 'ASAP',
  });

  private source = new BehaviorSubject<DraftOrder>(null);
  current = this.source.asObservable();

  private currentOrderId = new BehaviorSubject<string>(null);
  currentId = this.currentOrderId.asObservable();

  private showDraftOrder = new BehaviorSubject<boolean>(true);
  currentShowDraftOrder = this.showDraftOrder.asObservable();

  private draftOccasionSource = new BehaviorSubject<DraftOccasion>(this.draftOccasion);
  currentOccasion = this.draftOccasionSource.asObservable();

  editOrderSubject = new BehaviorSubject<DraftItem>(null);
  editMiscSubject = new BehaviorSubject<DraftMiscItem>(null);

  toggleDraftOrder(show: boolean){
    this.showDraftOrder.next(show);
  }

  newOrder(){
    this.draftOrder = new DraftOrder();
    this.source.next(this.draftOrder);
    this.showDraftOrder.next(true);
  }

  updateItem(item: DraftItem){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.items.push(item);
    this.source.next(this.draftOrder);
  }

  replaceItem(position: number, newItem: DraftItem) {
    if (this.draftOrder == null) {
      this.draftOrder = new DraftOrder();
    }

    if (position >= 0 && position < this.draftOrder.items.length) {
      this.draftOrder.items[position] = newItem;
    } else {
      console.error('Position out of bounds');
    }
    this.source.next(this.draftOrder);
  }

  updateMiscItem(item: DraftMiscItem){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.misc_items.push(item);
    this.source.next(this.draftOrder);
  }

  updateMiscItems(items: DraftMiscItem[]){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.misc_items = items;
    this.source.next(this.draftOrder);
  }

  updateOrderId(id: string){
    this.currentOrderId.next(id);
  }

  updateOccasion(occasion: DraftOccasion){
    if(this.draftOrder == null) {
      this.draftOrder = new DraftOrder();
    }
    this.draftOccasion = occasion;
    this.draftOrder.occasion = occasion;
    this.draftOccasionSource.next(occasion);
  }

  getCurrentDraftOrder(): DraftOrder {
    return this.draftOrder;
  }

  updateName(name: string){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.name = name;
    this.source.next(this.draftOrder);

  }

  updatePhone(phone: string){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.phone = phone;
    this.source.next(this.draftOrder);
  }

  updateTip(tip: Tip){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.tip = tip;
    this.source.next(this.draftOrder);

  }

  resetDraftOrder() {
    this.draftOrder = new DraftOrder();
    this.source.next(this.draftOrder);
  }

  updateSpecialRequest(specialRequest: string) {
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.speciial_request = specialRequest;
  }

  updateServiceCharge(serviceCharge: number) {
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.service_charge = serviceCharge;
    this.source.next(this.draftOrder);

  }

  updateOffer(promoCode: string) {
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.promo_code = promoCode;
  }

  removeItem(item: DraftItem){
    this.draftOrder.items = this.draftOrder.items.filter(i => i !== item);
    this.source.next(this.draftOrder)
  }

  updateTeminalId(terminalId: string) {
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.terminal_id = terminalId;
  }

  updateCashierId(cashierId: string){
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.cashier_id = cashierId;
  }

  updateInstantDiscount(instantDiscount: InstantDiscount) {
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.instant_discount = instantDiscount;
    this.source.next(this.draftOrder);
  }

  updateOfferDiscount(offerDiscount: number) {
    if(this.draftOrder == null){
      this.draftOrder = new DraftOrder();
    }
    this.draftOrder.offer_discount = offerDiscount;
    this.source.next(this.draftOrder);
  }

  removeInstantDiscount() {
    console.log('Remove discount service');
    this.draftOrder.instant_discount = null;
  }

  getSubTotal() {
    return this.draftOrder.getSubtotal();

  }

  updateItemQuantity(menuItemId: number, newQuantity: number) {
    if (this.draftOrder == null) {
      this.draftOrder = new DraftOrder();
    }

    const updated = this.draftOrder.updateDraftItemQuantity(menuItemId, newQuantity);
    if (updated) {
      this.source.next(this.draftOrder);
    } else {
      console.error('Item not found');
    }
  }

  updateCashDiscount(cashDisountPer: number) {
    if (this.draftOrder == null) {
      this.draftOrder = new DraftOrder();
    }

    this.draftOrder.cash_discount = cashDisountPer;
  }
}
