<div class="container">
  <div class="controls">
    <div id="stats" class="drop-shadow-md">
      <div class="grid grid-cols-3 gap-2">
        <div class="flex flex-col text-center border-r border-gray-300">
          <span class="text-xl xs:!text-sm sm:!text-sm">{{stats?.count}}</span>
          <span class="text-sm xs:!text-xs sm:!text-xs">Total Cards Sold</span>
        </div>
        <div class="flex flex-col text-center lab-text-blue border-r border-gray-300">
          <span class="text-xl xs:!text-sm sm:!text-sm gift-color">{{stats?.total | currency: 'USD'}}</span>
          <span class="text-sm xs:!text-xs sm:!text-xs gift-color">Total Card Value</span>
        </div>

        <div class="flex flex-col text-center">
          <span class="text-xl xs:!text-sm sm:!text-sm">{{stats?.balance | currency: 'USD'}}</span>
          <span class="text-sm xs:!text-xs sm:!text-xs">Current Balance</span>
        </div>
      </div>
    </div>

    <button mat-flat-button color="primary" class="create apply-btn-colr action-btn-colr xs:!w-[90%]" (click)="createNew()">
      CREATE NEW
    </button>
    <div style="clear: both"></div>
  </div>
  <div class="message-table">
    <app-gift-cards-message-table [store]="store" [refresh]="refresh"></app-gift-cards-message-table>
  </div>
</div>


