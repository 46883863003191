<div *ngIf="storeMenuFeatureFlag; then newSubCatButton else oldSubCatButton"></div>
<ng-template #newSubCatButton>
  <mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" [rowHeight]="heightBox" [gutterSize]="'10px'"
    class="grid-list mt-2 xs:mt-[5px] xs:mr-[2px] xs:mb-[7px] xs:ml-[5px]">
    <mat-grid-tile *ngFor="let sub_ctaegory of subcat" class="submenu-shadow"
      (click)="onSubSelectHandler.next(sub_ctaegory)">
      <button class="menu-item2 submenu-btn-border relative">
        <div *ngIf="sub_ctaegory.image; then imageShow else noImage"></div>
        <ng-template #imageShow>
          <img src="{{ sub_ctaegory.image }}" class="absolute inset-0 w-full h-full object-cover" />
          <span class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white px-2 py-1">{{
            sub_ctaegory.name }}</span>
        </ng-template>
        <ng-template #noImage>
          <span class="relative p-2">{{ sub_ctaegory.name }}</span>
        </ng-template>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>
<ng-template #oldSubCatButton>
  <mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" [rowHeight]="heightBox" [gutterSize]="'10px'"
    class="grid-list mt-2">
    <mat-grid-tile *ngFor="let sub_ctaegory of subcat" class="submenu-shadow"
      (click)="onSubSelectHandler.next(sub_ctaegory)">
      <button class="menu-item2 submenu-btn-border">
        <span>{{ sub_ctaegory.name }}</span>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>
