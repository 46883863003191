import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MenuService} from 'src/app/_services/menu.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  selectedItemsList: any;
  itemSalesTotal: any;
  itemSalesPos: any;
  itemSalesOnline: any;
  grossSalesTotal: any;
  grossSalesPos: any;
  grossSalesOnline: any;
  salesMixTotalPer: any;
  salesMixOnlinePer: any;
  salesMixPosPer: any;
  qtyMixTotalPer: any;
  qtyMixOnlinePer: any;
  qtyMixPosPer: any;

  constructor(public menuService: MenuService, public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     }

  idStore: any;
  itemss: any;

  ngOnInit() {
    this.itemss = this.data
    this.idStore = localStorage.getItem('currentStore');
  }

  cancelFilterButton(): void {
    this.dialogRef.close();
  }



  updateManage() {

    let keyValue = {
      item_sales_total: this.itemSalesTotal,
      item_sales_pos: this.itemSalesPos,
      item_sales_online: this.itemSalesOnline,
      gross_sales_total: this.grossSalesTotal,
      gross_sales_online: this.grossSalesOnline,
      gross_sales_pos: this.grossSalesPos,
      sales_mix_total: this.salesMixTotalPer,
      sales_mix_online: this.salesMixOnlinePer,
      sales_mix_pos: this.salesMixPosPer,
      qty_mix_total: this.qtyMixTotalPer,
      qty_mix_online: this.qtyMixOnlinePer,
      qty_mix_pos: this.qtyMixPosPer
    }

    this.menuService.updateManageFilter(this.idStore, keyValue).subscribe((data: any) => {
      // this.itemss = data.data
      this.dialogRef.close();
    })
  }

  changeSelection() {
    this.selectedItemsList = this.itemss.filter((value) => {

      this.itemSalesTotal = value.item_sales_total;
      this.itemSalesOnline = value.item_sales_online;
      this.itemSalesPos = value.item_sales_pos;

      this.grossSalesTotal = value.gross_sales_total;
      this.grossSalesOnline = value.gross_sales_online;
      this.grossSalesPos = value.gross_sales_pos;

      this.salesMixTotalPer = value.sales_mix_total;
      this.salesMixOnlinePer = value.sales_mix_online;
      this.salesMixPosPer = value.sales_mix_pos;

      this.qtyMixTotalPer = value.qty_mix_total;
      this.qtyMixOnlinePer = value.qty_mix_online;
      this.qtyMixPosPer = value.qty_mix_pos;
    });
  }




}
