import {Injectable} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {
  ItemModifierMappingType,
  MenuGroupType,
  MenuItemGroupType,
  MenuItemType,
  ModifierGroupType,
  ModifierOptionType
} from '../_models/menu-v2';
import {DraftOrder} from '../_models/order-draft';
import {OrdersWithPayment} from '../_models/order';



@Injectable({providedIn: 'root'})
export class MenuIndexService {
  constructor(private http: HttpClient) {}

  endpoint = environment.apiUrl;

  getMenuGroup(storeId: string){
    return this.http.get<MenuGroupType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/get_menu_groups/`);
  }

  getMenuItemGroup(storeId: string){
    return this.http.get<MenuItemGroupType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/get_menu_item_groups/`);
  }

  getMenuItems(storeId: string){
    return this.http.get<MenuItemType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/get_menu_items/`);
  }

  getModifierGroup(storeId: string){
    return this.http.get<ModifierGroupType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/get_modifier_groups/`);
  }

  getModifierOption(storeId: string){
    return this.http.get<ModifierOptionType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/get_modifier_options/`);
  }

  getMenuItemModifierMapping(storeId: string){
    return this.http.get<ItemModifierMappingType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/get_menu_item_modifier_mapping/`);
  }

  getMenuItem(menuItemId: string){
    return this.http.get<MenuItemType>(`${this.endpoint}/backend/v2/pos-menu-index/${menuItemId}/get_menu_item/`);
  }

  updateMenuItemGroupOrder(menuGroupId: string, order: string){
    return this.http.get(`${this.endpoint}/backend/v2/pos-menu-index/${menuGroupId}/update_menu_item_group_order/`, {params: {order}});
  }

  getOrderMenuItems(orderId: string){
    return this.http.get<MenuItemType[]>(`${this.endpoint}/backend/v2/pos-menu-index/${orderId}/get_order_menu_items/`);
  }

  newOrder(storeId:string, order: DraftOrder){
   return this.http.post<OrdersWithPayment>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/create_order_from_draft/`, order);
  }

  applyPromotion(storeId: string, payload: DraftOrder) {
    return this.http.post<any>(`${this.endpoint}/backend/v2/pos-menu-index/${storeId}/apply_promotion/`, payload);
  }
}
