import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {OrdersWithPayment} from 'src/app/_models/order';
import {RefundPayment} from 'src/app/_models/payment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentService} from '../../_services/payment.service';
import {POSService} from 'src/app/_services/pos-service';
import {OrderService} from 'src/app/_services/order.service';
import {POSStreamService} from 'src/app/_services/pos-stream.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-refund',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent implements OnInit, OnDestroy {
  refundForm: UntypedFormGroup;
  refundChoice: string;
  total: number;
  orderId: number;
  orderWithPayment: OrdersWithPayment;
  loading = false;
  submitted = false;
  error = '';
  store;
  refundAmount: number;

  posTxns;
  ccTxns;
  pgTxns;

  totalRefunded;
  canRefund;

  splitTranscations;
  tipTransactions;

  terminalId;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<RefundDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private posService: POSService,
    private orderService: OrderService,
    private posStreamService: POSStreamService,
    public dialog: MatDialog,
  ) {
    this.orderWithPayment = data.orderWithPayment;
    this.total = this.data.botOrder.total;
    this.orderId = this.data.botOrder.id;
    this.store = this.data.store;
    this.terminalId = localStorage.getItem('selectedTerminal');
  }

  onCloseClick(): void {
    this.dialogRef.close([]);
  }

  isSplit() {
    return this.data.botOrder.payment_type === 'SPLIT';
  }

  canPartialRefund() {
    return this.store.pos_payment_gateway === 'NMI_BBPOS';
  }

  ngOnInit() {
    if (this.data.botOrder.is_pos) {
      this.paymentService.getPOSTransactions(this.data.botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(txns => {
        this.posTxns = txns;
        this.totalRefunded = txns.filter(t => t.trans_type === 'refund').map(t => +t.amount).reduce((n, amount) => n + amount, 0);
        this.canRefund = this.total - this.totalRefunded > 0 ? this.total - this.totalRefunded : 0 ;
        this.canRefund = Math.round(this.canRefund * 100) / 100;
      });
    } else {
      if (this.store.payment_gateway === 'CARDCONNECT') {
        this.paymentService.getCardConnectRefunds(this.data.botOrder.id).pipe(takeUntil(this.destroySubject)).subscribe(txns => {
          this.ccTxns = txns;
          this.totalRefunded = txns.reduce((t, amount) => t + amount, 0);
          this.canRefund = this.total - this.totalRefunded > 0 ? this.total - this.totalRefunded : 0 ;
          this.canRefund = Math.round(this.canRefund * 100) / 100;
        });
      } else {
        this.paymentService.getPGTransactions(this.data.botOrder.id).pipe(takeUntil(this.destroySubject)).subscribe(txns => {
          this.pgTxns = txns;
          this.totalRefunded = txns.filter(t => t.is_refund && !t.is_test).map(t => +t.amount).reduce((n, amount) => n + amount, 0);
          this.canRefund = this.total - this.totalRefunded > 0 ? this.total - this.totalRefunded : 0 ;
          this.canRefund = Math.round(this.canRefund * 100) / 100;
        });
      }
    }

    if (this.isSplit()) {
      this.loadSplitTransactions();
    }


    this.refundForm = this.formBuilder.group({
      refundAmount: ['', Validators.max(this.total)],
      refundChoice: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  // convenience getter for easy access to form fields
  get f() { return this.refundForm.value; }

  private constructRefundObject(): RefundPayment {

    if (this.f.refundChoice === 'partialRefund') {
      return new RefundPayment(this.orderId, this.f.refundAmount);
    } else {
      return new RefundPayment(this.orderId, this.canRefund);
    }
  }

  loadSplitTransactions() {
    this.paymentService.getSplitTransactions(this.data.botOrder.order_hash, true).pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.splitTranscations = result;
    });
  }


  onSubmit() {
    console.log('onSubmit');
    this.submitted = true;
    if (this.isSplit()) {
      this.onCloseClick();
    }

    // stop here if form is invalid
    if (this.refundForm.invalid) {
      return;
    }

    if (this.f.refundAmount > this.canRefund) {
      const dialogRef = this.dialog.open(AlertsMessagesComponent, {
        disableClose: true,
        width: '364px',
        minHeight: '20vh',
        data: {
          message: `Refund amount cannot exceed $${this.canRefund}`
        }
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        return;
      });
      // alert(`Refund amount cannot exceed $${this.canRefund}`);
      // return;
    }

    this.loading = true;
    let o;
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      const refund = this.constructRefundObject();
      const payload = {
        order_id: this.data.botOrder.order_hash,
        amount: refund.refund_amount
      };
      o = this.posStreamService.void(this.terminalId, payload);
    } else if (this.data.botOrder.is_pos && this.data.botOrder.source !== 'POS_MANUAL_CREDIT') {
      const refund = this.constructRefundObject();
      o = this.posService.posRefund(this.data.terminalId, this.data.botOrder.order_hash, refund.refund_amount);
    } else {
      if (this.store.payment_gateway === 'STRIPE') {
        o = this.paymentService.refundStripe(this.constructRefundObject());
      } else if (this.store.payment_gateway === 'CARDCONNECT') {
        o = this.paymentService.refund(this.constructRefundObject());
      } else {
        o = this.paymentService.refundGateway(this.constructRefundObject());
      }
    }


    o.pipe(takeUntil(this.destroySubject)).subscribe(
      data => {
        this.loading = false;
        console.log(JSON.stringify(data));
        this.onCloseClick();
      },
      error => {
        this.error = error;
        this.loading = false;
    });
  }

  refundSplit(split) {
    this.loading = true;
    console.log(split);
    if (split.payment_type === 'CASH') {
      this.orderService.voidTransaction(this.data.botOrder.order_hash, {id: split.value.id}).pipe(takeUntil(this.destroySubject)).subscribe(d => {
        this.loading = false;
      });
    } else if (split.payment_type === 'CREDIT' || split.payment_type === 'SPLIT') {
      const payload = {
        amount: split.split_amount,
        split_id: split.id,
        terminal_id: localStorage.getItem('selectedTerminal'),
        order_hash: this.data.botOrder.order_hash
      };

      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        this.posStreamService.voidSplitOrder(this.terminalId, payload).pipe(takeUntil(this.destroySubject)).subscribe(d => {
          this.loading = false;
          this.loadSplitTransactions();
        }, error => {
          this.loading = false;
        });
      } else {
        this.orderService.refundSplitTransaction(this.data.botOrder.order_hash, payload).pipe(takeUntil(this.destroySubject)).subscribe(d => {
          this.loading = false;
          this.loadSplitTransactions();
        }, error => {
          this.loading = false;
        });
      }
    }
  }
}
