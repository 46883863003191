import {MenuItemGroupType} from '../_models/menu-v2';
import {RxCollection, RxDocument, RxJsonSchema} from 'rxdb';


export type MenuItemGroupDocument = RxDocument<MenuItemGroupType>;
export type MenuItemGroupCollection = RxCollection<MenuItemGroupType>;


export const menuItemGroupSchema: RxJsonSchema<MenuItemGroupType> = {
  title: 'menu item group schema',
  description: 'describes a simple menu item group',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    name: {
      type: 'string',
    },
    menu_group: {
      type: ['number', 'null']
    },
    starting_price: {
      type: ['string', 'null']
    },
    description: {
      type: ['string', 'null']
    },
    order: {
      type: 'number',
      multipleOf: 1,
      minimum: 0,
      maximum: 999
    },
    latest_image: {
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        thumbnail: {
          type: 'string'
        },
        image: {
          type: 'string'
        }
      }
    }
  },
  required: ['name', 'menu_group', 'starting_price'],
  indexes: ['order']
};
