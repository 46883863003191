<div class="example-container">
  <form [formGroup]="options" (ngSubmit)="editSurchargeLabelPercent()">
    <div>
      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>Surcharge Label</mat-label>
        <input matInput placeholder="" formControlName="surcharge_label">
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>Subcharge Percent</mat-label>
        <input step="0.1" type="number" matInput placeholder="" formControlName="surcharge_percent">
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>Subcharge Text</mat-label>
        <textarea matInput formControlName="surcharge_text"></textarea>
      </mat-form-field>
    </div>
    <button mat-raised-button type="submit">Save</button>
  </form>
</div>