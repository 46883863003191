import {ItemModifierMappingType} from '../_models/menu-v2';
import {RxCollection, RxDocument, RxJsonSchema} from 'rxdb';


export type ItemModifierDocument = RxDocument<ItemModifierMappingType>;
export type ItemModifierCollection = RxCollection<ItemModifierMappingType>;


export const itemModifierSchema: RxJsonSchema<ItemModifierMappingType> = {
  title: 'menu item schema',
  description: 'describes a simple menu item',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    menu_item: {
      type: 'string',
    },
    modifier_group: {
      type: 'string'
    },
    index: {
      type: 'number',
      multipleOf: 1,
      minimum: 0,
      maximum: 999
    }
  },
  required: ['id', 'menu_item', 'modifier_group'],
  indexes: ['index']
};
