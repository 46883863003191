import { Component, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-worldnet-dialog',
  templateUrl: './worldnet-dialog.component.html',
  styleUrls: ['./worldnet-dialog.component.scss']
})
export class WorldnetDialogComponent implements AfterViewInit {
  @ViewChild('iframeRef') iframeRef!: ElementRef;
  private loadCounter = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { formHtml: string },
    private dialogRef: MatDialogRef<WorldnetDialogComponent>
  ) {}

  ngAfterViewInit(): void {
    const iframe: HTMLIFrameElement = this.iframeRef.nativeElement;
    const iframeDoc = iframe.contentWindow!.document;

    iframeDoc.open();
    iframeDoc.write(this.data.formHtml);
    iframeDoc.close();

    const form = iframeDoc.getElementsByTagName('form')[0];
    form.submit();

    // Listen for iframe load events
    iframe.onload = () => {
      this.loadCounter += 1;
      if (this.loadCounter === 2) {
        this.dialogRef.close(true); // Close the dialog on the second load
      }
    };
  }
}
