import {Component, OnInit} from '@angular/core';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {environment} from 'src/environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-time-acceptance-order-iframe',
  templateUrl: './time-acceptance-order-iframe.component.html',
  styleUrls: ['./time-acceptance-order-iframe.component.scss']
})
export class TimeAcceptanceOrderIframeComponent implements OnInit {

  orderUrl;
  constructor(private receiptService:ReceiptService, private sanitizer:DomSanitizer) { }

  ngOnInit() {
    this.receiptService.current.subscribe(botOrder => {
      if(botOrder){
        this.orderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.orderAppUrl}/order/${botOrder.order_hash}/status/success`);
        console.log('this.orderUrl',this.orderUrl)
      }else{
        this.orderUrl = null;
      }
    })
  }

}
