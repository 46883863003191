<mat-tab-group>
  <mat-tab label="PRODUCT MIX">
    <ng-template matTabContent>
      <app-product-mix></app-product-mix>
    </ng-template>
  </mat-tab>
  <mat-tab label="KEY PERFORMANCE INDICATORS">
    <ng-template matTabContent>
      <app-insight></app-insight>
    </ng-template>
  </mat-tab>
</mat-tab-group>