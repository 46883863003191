import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { Store } from 'src/app/_models/store';
import { OrderService } from 'src/app/_services/order.service';
import { StoreService } from 'src/app/_services/store.service';
import { get } from 'scriptjs';
import { CardDetail } from 'src/app/_models/payment';
import { CardDetails, OrdersWithPayment } from 'src/app/_models/order';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MyErrorStateMatcher } from 'src/app/_helpers/error-matcher';
import Utils from 'src/app/utils';
import { POSService } from 'src/app/_services/pos-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RefreshService } from 'src/app/_services/refresh.service';
import { OpenOrderToggleService } from 'src/app/_services/open-order-toggle.service';
import { PaymentOption } from '../../_models/payment-options';
import { ManualPrintControlComponent } from '../order-summary/manual-print-control/manual-print-control.component';
import { PaymentGatewayService } from '../../_services/payment-gateway.service';
import { WorldnetDialogComponent } from '../worldnet-dialog/worldnet-dialog.component';

declare var iTransact;
declare var CollectJS;

@Component({
  selector: 'app-manual-card-dialog',
  templateUrl: './manual-card-dialog.component.html',
  styleUrls: ['./manual-card-dialog.component.scss']
})
export class ManualCardDialogComponent implements OnInit {

  store: Store;
  isLoading = false;
  phoneNumber: string;
  cardDetail: CardDetail;

  orderWithPayment: OrdersWithPayment;
  orderHash: string;
  errorMatcher = new MyErrorStateMatcher();

  result;
  public unsubscribe: Subject<null> = new Subject<null>();
  optIn = true;
  failure = false;
  cardsOnFile;
  isValidPhone = false;

  loadAPI: Promise<any>;
  cardDetails: CardDetails[] = [];
  otp_field = false;
  otp: number;
  number_verified = false;
  isOneTime = true;

  constructor(
    public dialogRef: MatDialogRef<ManualCardDialogComponent>,
    private storeService: StoreService,
    private orderService: OrderService,
    private posService: POSService,
    private ref: ChangeDetectorRef,
    private refreshService: RefreshService,
    private openOrderSummaryToggleService: OpenOrderToggleService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    public paymentGatewayService: PaymentGatewayService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.orderWithPayment = data.orderWithPayment;
    this.orderHash = this.orderWithPayment.bot_order.order_hash;
    if (!this.orderWithPayment.isPhoneDefault()) {
      if (this.orderWithPayment.bot_order.phone_number.length === 12) {
        this.phoneNumber = Utils.getFormattedPhoneNumber(this.orderWithPayment.bot_order.phone_number);
        this.isValidPhone = true;
      }
    }

    this.loadAPI = new Promise((resolve) => {
      console.log('loading nmi script');
      const node = this.loadNMIScript(this.data.orderWithPayment.store.nmi_public_key);
      if (node) {
        node.onload = () => {
          resolve(true);
        };
      } else {
        resolve(true);
      }
    });
  }

  ngOnInit() {
    this.storeService.current.pipe(takeUntil(this.unsubscribe)).subscribe(store => {
      if (store) {
        console.log(store);
        this.store = store;
        this.getPreferredPayment();
        if (this.store.payment_gateway === 'PAYROC') {
          this.loadPayrocScript(this.store.payroc_username);
        }
      }
    });

    this.loadAPI.then((flag) => {
      console.log(flag);
      CollectJS.configure({
        paymentType: 'cc',
        primaryColor: '#1e88e5',
        buttonText: 'Add Card',
        instructionText: 'Enter Card Info Below',
        theme: 'material',
        callback: function (response) {
          console.log(response);
          const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
          this.orderService.addNewCardGateway(
            this.store.id, plainPhoneNumber, response.token, this.store.payment_gateway, this.isOneTime, response.card).pipe(takeUntil(this.unsubscribe)).subscribe(cd => {
              this.getPreferredPayment();
              const cardDetails = Object.assign(new CardDetails(), cd);
              this.cardDetails.push(cardDetails);
              const paymentOption = new PaymentOption(false, true, this.useStoreCredit, cardDetails, this.storeCreditBalance);
              this.isLoading = false;

              const payload = {
                is_cash: false,
                is_card: true,
                is_store_credit: false,
                guest_payment_id: cardDetails.id
              };
              this.orderService.updatePreferredPayment(this.orderHash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
                this.ngZone.run(() => {
                  if (this.data.is_split) {
                    this.processSplitPayment(localStorage.getItem('selectedTerminal'), this.data.order_id, this.data.toCharge, this.data.split_id);
                  } else {
                    this.processPayment('DUMMY', this.orderHash, this.orderWithPayment.payment.total);
                  }
                });
              });
              this.isLoading = true;

              CollectJS.closePaymentRequest();
            });
        }.bind(this)
      });
    });
  }

  goBack() {
    this.dialogRef.close(false);
  }

  loadPayrocScript(payrocUsername) {
    const options = {
      apiUsername: payrocUsername,
      name: 'Please add card details below:',
      description: '',
      onToken: this.submit.bind(this),
      requireEmail: false
    };

    get('https://js.itransact.com/itransact.js', () => {
      const handler = iTransact.Checkout.init(options);
      document.getElementById('payroc-button').addEventListener('click', event => {
        if (this.number_verified) {
          handler.render({
            panelButtonText: 'Add Card',
            theme: '#467adb',
            email: 'support@labrador.ai'
          });
        } else {
          handler.render({
            panelButtonText: 'Process Payment',
            theme: '#467adb',
            email: 'support@labrador.ai'
          });
        }

        event.preventDefault();
      });
    });
  }

  loadNMIScript(nmiPublicKey) {
    console.log(nmiPublicKey);
    let node;
    let isFound = false;
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; ++i) {
      // Check if script is already there in html
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
        isFound = true;
      }
    }

    if (!isFound) {
      console.log('not found');
      const dynamicScript = 'https://secure.networkmerchants.com/token/Collect.js';
      node = document.createElement('script');
      node.src = dynamicScript;
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      node.setAttribute('data-tokenization-key', nmiPublicKey);
      document.getElementsByTagName('head')[0].appendChild(node);
      return node;
    }
    return node;
  }

  openCollectJsLightBox(event) {
    CollectJS.startPaymentRequest(event);
  }

  submit(data) {
    console.log(data);
    this.isLoading = true;
    this.ref.detectChanges();

    const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
    this.orderService.addNewCardGateway(this.store.id, plainPhoneNumber, data.token, this.store.payment_gateway, this.isOneTime).pipe(takeUntil(this.unsubscribe)).subscribe(
      response => {
        const cardDetail = Object.assign(new CardDetail(), response);
        const payload = {
          is_cash: false,
          is_card: true,
          is_store_credit: false,
          guest_payment_id: cardDetail.id,
          cardDetail
        };
        this.orderService.updatePreferredPayment(this.orderHash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(d => {
          this.ngZone.run(() => {
            if (this.data.is_split) {
              this.processSplitPayment(localStorage.getItem('selectedTerminal'), this.data.order_id, this.data.toCharge, this.data.split_id);
            } else {
              this.processPayment('DUMMY', this.orderHash, this.orderWithPayment.payment.total);
            }
          });
        });
      },
      error => {
        console.log('card failed');
      });
  }

  getPreferredPayment() {
    if (this.phoneNumber) {
      console.log('get preferred payment');
      console.log(this.store);
      const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
      this.orderService.getCards(this.store.id, plainPhoneNumber, this.store.payment_gateway).pipe(takeUntil(this.unsubscribe)).subscribe(cards => {
        this.cardsOnFile = cards;
      }, error => {
        console.log('no existing cards');
      });
    }
  }

  deleteCard(toDelete) {
    if (confirm('Are you sure you want to delete?')) {
      this.orderService.deleteCard(toDelete).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.getPreferredPayment();
      });
    }
  }

  updatePhoneNumber() {
    if (Utils.getPlainPhoneNumberFromString(this.phoneNumber).length === 12) {
      const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
      this.orderService.updatePhoneNumber(this.orderHash, plainPhoneNumber,
        this.orderWithPayment.bot_order.order_name, true).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
          this.getPreferredPayment();
          this.isValidPhone = true;
        }, error => {
          console.log('no card found');
        });
    }
  }

  processPayment(terminalId, orderHash, total) {
    this.isLoading = true;
    const payload = {
      order_hash: orderHash,
      amount: total,
      payment_type: 'MANUAL_CREDIT',
      is_open_tab: this.data.isOpenTab,
      cashier_id: localStorage.getItem('posLoggedUser'),
      pin_user: localStorage.getItem('pinUser'),
      terminal_name: localStorage.getItem('selectedTerminalName')
    };
    this.posService.posAuthorizeManual(terminalId, payload, this.optIn)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        this.result = result;
        this.isLoading = false;
        this.dialogRef.close(true);
        localStorage.removeItem('order_hash');
        this.orderService.orderDoneSubject.next();
        this.openOrderSummaryToggleService.toggle('CLOSE');
        this.refreshService.refreshModule('OPEN_TAB');
        this.ref.detectChanges();
        this.manualReceiptOptions();
      }, error => {
        this.isLoading = false;
        this.failure = true;
        this.ref.detectChanges();

      });
  }

  processSplitPayment(terminalId, orderHash, total, split_id) {
    this.isLoading = true;
    const payload = {
      order_hash: orderHash,
      amount: total,
      split_amount: total,
      payment_type: 'SPLIT',
      is_open_tab: this.data.isOpenTab,
      terminal_id: localStorage.getItem('selectedTerminal'),
      cashier_id: localStorage.getItem('posLoggedUser'),
      terminal_name: localStorage.getItem('selectedTerminalName'),
      split_id,
      pin_user: localStorage.getItem('pinUser'),
      p_type: 'MANUAL_CREDIT'
    };

    this.orderService.splitAuthorize(terminalId, payload, this.optIn)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result.result_code === 'Approved') {
          this.orderService.processSplit(orderHash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(d => {
            this.result = result;
            this.isLoading = false;
            this.dialogRef.close(true);
            this.manualReceiptOptions();
          });
        } else {
          this.isLoading = false;
          this.result = { result_code: 'Failure', message: 'Unable to complete the request. Server did not respond.' };
        }
      }, error => {
        this.isLoading = false;
        this.result = { result_code: 'Failure', message: 'Unable to complete the request. Server did not respond.' };
      });
  }

  useCard(card) {
    const payload = {
      is_cash: false,
      is_card: true,
      is_store_credit: false,
      guest_payment_id: card.id
    };
    this.orderService.updatePreferredPayment(this.orderHash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      // this.getPreferredPayment();
      this.ngZone.run(() => {
        if (this.data.is_split) {
          this.processSplitPayment(localStorage.getItem('selectedTerminal'), this.data.order_id, this.data.toCharge, this.data.split_id);
        } else {
          this.processPayment('DUMMY', this.orderHash, this.orderWithPayment.payment.total);
        }
      });
    });

  }

  addToWallet() {
    const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
    const keyData = {
      phone_number: plainPhoneNumber
    };
    this.orderService.numberVerifyForCard(keyData, this.orderHash).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      console.log('OTP SENT');
      this.otp_field = true;

    });
  }

  verifyOTP() {
    const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(this.phoneNumber);
    const keyData = {
      phone_number: plainPhoneNumber,
      otp: this.otp
    };
    this.orderService.verifyCardOtp(keyData, this.orderHash).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.number_verified = true;
      this.isOneTime = false;
    });
  }

  changePhone() {
    this.orderWithPayment.bot_order.phone_number_verified = false;
    this.number_verified = false;
    this.otp = null;
    this.otp_field = false;
  }

  manualReceiptOptions() {
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(ManualPrintControlComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        value: 1,
        order_hash: this.orderHash,
        key: 'manual_card',
        is_split: this.data.is_split,
        split_id: this.data.split_id
      }
    });
    dialogRef.afterClosed().subscribe((selectVal: any) => {
    });
  }

  back() {
    this.dialogRef.close();
  }

  redirectToWorldnet(isOneTime: boolean) {
    console.log("redirect to worldnet");
    this.paymentGatewayService.getHPPUrl(+this.store.id, this.orderHash, isOneTime).subscribe((data: any) => {
      console.log(data);

      let formHtml = `<form method="POST" action="https://payments.worldnettps.com/merchant/securecardpage">`;
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formHtml += `<input type="hidden" name="${key}" value="${data[key]}" />`;
        }
      }
      formHtml += `</form>`;

      const dialogRef = this.dialog.open(WorldnetDialogComponent, {
        data: { formHtml },
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
      });

      dialogRef.afterClosed().subscribe(close => {
        this.ngZone.run(() => {
          console.log('Worldnet Dialog Closed', close);
          if (close) {
            this.processPayment('DUMMY', this.orderHash, this.orderWithPayment.payment.total);
          }
        });
      })
    });
  }


}
