import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrinterSetting, Store } from 'src/app/_models/store';
import { MatDialog } from '@angular/material/dialog';
import { EditPrinterComponent } from './edit-printer/edit-printer.component';
import { StoreService } from 'src/app/_services/store.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-store-setting-printers',
  templateUrl: './store-setting-printers.component.html',
  styleUrls: ['./store-setting-printers.component.scss']
})
export class StoreSettingPrintersComponent implements OnInit {

  panelOpenState = false;

  @Input() store: Store;

  @Input() printer: PrinterSetting;

  @Output('parentFunction') parentFunction: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog, private storeService: StoreService) { }

  ngOnInit() {
  }

  edit(printer) {
    const obs = [
      this.storeService.getTablesideTables(this.store.id),
      this.storeService.getPrinterMenu(this.store.id),
      this.storeService.getPrinterOccasion(this.store.id),
    ]

    forkJoin(obs).subscribe((response) => {
      const isMobile = window.innerWidth <= 599;
      const tables = response[0];
      const menus = response[1];
      const occasions = response[2]
      const ref = this.dialog.open(EditPrinterComponent, {
        width: isMobile ? '100vw' : '700px',
        height: isMobile ? '100vh' : 'auto',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {
          printer,
          store: this.store,
          tableside_table: tables,
          menu_category: menus,
          occasion: occasions
        }
      })
      ref.afterClosed().subscribe(data => {
        if (data) {
          this.parentFunction.emit();
          this.printer = Object.assign(new PrinterSetting(), data);
        }
      })
    })

  }

  onToggleChange(setting) { }

}
