import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnChanges {

  @Input() user;
  @Input() sidebar;
  @Input() store:Store;
  @Output() profileUpdateEvent = new EventEmitter<any>();

  form:UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
    private storeService:StoreService) { }

  ngOnChanges(change) {
    if(change.user){
      this.user = change.user.currentValue.user;
      this.form = new UntypedFormGroup({
        phone_number: new UntypedFormControl(this.user.phone_number || '', Validators.required),
        store: new UntypedFormControl(this.store.id),
        name: new UntypedFormControl(this.user.name || ''),
        is_blocked: new UntypedFormControl(this.user.is_blocked || false)
      })
    }
  }

  onSubmit(){
    if(this.form.valid){
      this.storeService.updateContact(this.form.value).subscribe(data => {
        this.profileUpdateEvent.emit(data)
        this.sidebar.toggle()
      })
    }
  }

}
