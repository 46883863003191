import { Component, OnInit } from '@angular/core';
import { StoreService } from '../_services/store.service';
import { CoPilotMerchant, Store, StoreMerchant } from '../_models/store';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { HttpClient } from '@angular/common/http';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../_services/payment.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreLogoComponent } from './store-logo/store-logo.component';
import { StoreFaviconComponent } from './store-favicon/store-favicon.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  isLoading: boolean = true;
  store: Store;
  storeMerchant: StoreMerchant;
  sameMailAddress: boolean = true;
  user;
  merchantErrors;

  stripeClientId = environment.stripeClientId;
  stripeDetails;
  stripeLoginDetails;
  rawStoreMenu
  logo: any;
  storeId: any;
  favicon_icon: any;
  favicon_id: any;
  logo_id: any;

  constructor(
    private storeService: StoreService,
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private http: HttpClient,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.storeService.current.subscribe(store => {
      this.isLoading = true;
      if (store) {
        this.store = Object.assign(new Store(), store)
        this.storeService.getMerchant(this.store).subscribe(data => {
          this.storeMerchant = Object.assign(new StoreMerchant(), data, {
            store: Object.assign(new Store(), data['store']),
            copilot_merchant: Object.assign(new CoPilotMerchant(), data['copilot_merchant'])
          }, error => {
            this.isLoading = false;
          })

          this.storeService.rawMenuVersion(this.store.id).subscribe(data => {
            this.rawStoreMenu = data
          })

          if (this.storeMerchant.copilot_merchant.gateway_boarding_status != 'BOARDED') {
            this.storeService.refreshMerchant(this.store).pipe(first()).subscribe(data => {
              this.storeMerchant.copilot_merchant.boarding_process_status = data['boarding_process_status']
              this.storeMerchant.copilot_merchant.gateway_boarding_status = data['gateway_boarding_status'];
              this.isLoading = false;
            }, error => {
              this.isLoading = false;
            })
          } else {
            this.isLoading = false;
          }
        })



        this.paymentService.getStripeStore(this.store.id).subscribe(details => {
          console.log(details);

          this.stripeDetails = details
          if (this.stripeDetails['stripe_user_id']) {
            this.paymentService.getStripeLoginLink(this.store.id).subscribe(loginDetails => {
              console.log(loginDetails);
              this.stripeLoginDetails = loginDetails;
            })
          }
        });
        console.log(store);
        this.logoGet();
        this.getFaviconLogo();
      }
    })
    let currentUser = localStorage.getItem("currentUser")
    if (currentUser) {
      this.user = JSON.parse(currentUser)
    }


  }
  getFaviconLogo() {
    this.storeService.getFaviconLogo(this.storeId).subscribe(data => {
      if (data == '') {
        this.favicon_icon = '';
        this.favicon_id = '';
      }
      else {
        this.favicon_icon = data.favicon_file;
        this.favicon_id = data.id;
      }

      console.log('ssssssssssssss', this.logo);

    }, error => {

    })
  }

  deleteFav() {
    this.storeService.deleteFaviconLogo(this.favicon_id).subscribe(data => {
      this.getFaviconLogo();
    })
  }



  logoGet() {
    this.storeService.getStoreLogo(this.storeId).subscribe(data => {
      if (data == '') {
        this.logo = '';
        this.logo_id = '';
      }
      else {
        this.logo = data.logo_file;
        this.logo_id = data.id;
      }

    }, error => {

    })
  }

  deleteLogo() {
    this.storeService.deleteLogo(this.logo_id).subscribe(data => {
      this.logoGet();
    })
  }

  public openUploadDialog1(_for: string, multiple: boolean, version: number) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(StoreLogoComponent, {
      data: {
        storeMerchant: this.storeMerchant,
        _for: _for,
        multiple: multiple,
        version: version,
        // action: 'logo',
        data: this.logo
      },
      width: isMobile ? '100vw' : '50%', // Set the width property here
      height: '50%',
      maxWidth: isMobile ? '100vw' : '100vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.logoGet();
      }
    });
  }


  public openUploadDialogFav(_for: string, multiple: boolean, version: number) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(StoreFaviconComponent, {
      data: {
        storeMerchant: this.storeMerchant,
        _for: _for,
        multiple: multiple,
        version: version,
        action: 'fav',
        data: this.favicon_icon
      },
      width: isMobile ? '100vw' : '50%', // Set the width property here
      height: '50%',
      maxWidth: isMobile ? '100vw' : '100vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getFaviconLogo();
      }
    });
  }



  editStoreMerchant(title: string, editKey: string, fields: string[]) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(EditDialogComponent,
      {
        width: isMobile ? '100vw' : '',
        height: isMobile ? '100vh' : 'auto',
        // maxWidth: isMobile ? '100vw' : '100vw',
        // height: isMobile ? '77vh' : '',
        maxWidth: isMobile ? '100vw' : '100vw',
        data: {
          'editKey': editKey,
          'storeMerchant': this.storeMerchant,
          'title': title,
          'fields': fields,
        },
      } as any)
    dialogRef.afterClosed().subscribe(result => {
      this.merchantErrors = result.copilot_merchant
      this.storeService.refresh(this.store);
    });
  }

  activateMerchant(title: string, editKey: string, fields: string[]) {
    let dialogRef = this.dialog.open(EditDialogComponent,
      {
        data: {
          'editKey': editKey,
          'storeMerchant': this.storeMerchant,
          'title': title,
          'fields': fields,
        },
        'width': '500px'
      } as any)

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true;
      this.storeService.createMerchant(this.store).subscribe(errors => {
        this.merchantErrors = errors
        this.storeService.refresh(this.store);
        this.isLoading = false;
      })
    });
  }

  public openUploadDialog(_for: string, multiple: boolean, version: number) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(UploadDialogComponent, {
      data: {
        storeMerchant: this.storeMerchant,
        _for: _for,
        multiple: multiple,
        version: version
      },
      width: isMobile ? '100vw' : '50%', // Set the width property here
      height: '50%',
      maxWidth: isMobile ? '100vw' : '100vw',
    })

    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    })
  }
  UploadFaviconDialogComponent
  changePassword() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: {},
      width: '500px',
      maxWidth: isMobile ? '100vw' : '500px',
      height: 'auto',
    } as any);

    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }


  connectWithStripe() {
    let url = `https://connect.stripe.com/express/oauth/authorize?client_id=${this.stripeClientId}&state=${this.store.id}&stripe_user[business_name]=${this.store.store_name}&stripe_user[email]=${this.store.contact_email}&requested_capabilities[]=card_payments&requested_capabilities[]=transfers?redirect_uri=http://localhost:4201/home/account/stripe`
    console.log(url);
    window.location.href = url;
  }

  loginToStripe() {
    window.location.href = this.stripeLoginDetails.url
  }
}
