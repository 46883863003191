import {Component, Inject, OnInit, Optional} from '@angular/core';
import {EditRoleComponent} from '../edit-role/edit-role.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../_services/user.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  columnsToDisplay = ['role_name', 'action']
  rolesList : any;
  store: any;
  role : any;
  constructor(
    public dialogRef: MatDialogRef<RolesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private userService: UserService){
      this.rolesList = data['roles'];
      this.store = data['store']
    }

  ngOnInit() {
  }

  editRole(role) {
    const dialogRef = this.dialog.open(EditRoleComponent, {
      width: '630px', height: '260px',
      data: {'role': role, 'store': this.store}
    });
  }

  deleteRole(role) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to delete "+role.role_name+"?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        role.role_name = ''
        this.userService.editRole(role, this.store.id).toPromise().then(
          result => {
            console.log(result)
          });
        this.dialogRef.close();
      }
    });
    // if(confirm("Are you sure to delete "+role.role_name+"?")){
    //   role.role_name = ''
    //   this.userService.editRole(role, this.store.id).toPromise().then(
    //     result => {
    //       console.log(result)
    //     });
    //   this.dialogRef.close();
    // }
  }
}
