import {Component, OnInit, ViewChild} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from '../../environments/environment';
import {StoreService} from '../_services/store.service';
import {Store} from '../_models/store';
import {UnreadService} from '../_services/unread-message.service';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  store:Store;
  messagedUsers:any;
  selectedUser:any;
  previousMessages:any;
  currentMessage:Object = {};
  toDelete:any;
  userListOpened:boolean = true;
  @ViewChild('contactList',{static: false}) contactList: MatSidenav;



  private storeMessageSocket:WebSocketSubject<any>;
  isLoading = false;

  constructor(private storeService:StoreService,
    private unreadService:UnreadService) { }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.storeMessageSocket = webSocket(`${environment.websocketHost}/ws/message/${this.store.url_slug}/`);
        this.storeMessageSocket.subscribe(
          (message) => {
            console.log(message);

            if(message.phone_number in this.currentMessage){
              this.currentMessage[message.phone_number].push(message)
            }else{
              this.currentMessage[message.phone_number] = [message]
            }

            this.currentMessage = Object.assign({}, this.currentMessage);
            this.unreadService.unreadMessages(this.store.id)
          },
          (err) => {
            console.log(err);
          },
          () =>{
            console.log("Complete");
          }
        )
      }
    });
  }

  selectGroup(user){
    this.isLoading = true;
    this.storeService.getMessageList(this.store.id, user.id).subscribe(data =>{
      this.selectedUser = data
      this.isLoading = false;
    })
  }

  onUserSelected(user){
    this.isLoading = true
    if(user){
      if(user && user.id){
        this.storeService.getMessageList(this.store.id, user.id).subscribe(data => {
          this.selectedUser = user;
          this.previousMessages = data

          this.currentMessage[user.phone_number] = []
          this.currentMessage = Object.assign({}, this.currentMessage);

          this.isLoading = false
        })
        this.unreadService.unreadMessages(this.store.id)
      }else{
        this.storeService.newMessageList(this.store.id, user.phone_number).subscribe(data => {
          this.previousMessages = [];

          this.currentMessage[user.phone_number] = []
          this.currentMessage = Object.assign({}, this.currentMessage);

          this.selectedUser = data;
          this.isLoading = false;
        })
      }
    }
  }

  profileUpdate(event){
    console.log(event);

    this.selectedUser.user.name=event.name;
  }

  onSendMessage(message){
    this.storeMessageSocket.next(message)
    console.log(message);
  }

  onDelete(user){
    this.toDelete = user;
    delete this.currentMessage[user.phone_number]
    this.currentMessage = Object.assign({}, this.currentMessage);
    this.previousMessages = []
    this.selectedUser = null
  }

  public get width() {
    return window.innerWidth;
  }

}
