<div class="example-container">
  <form [formGroup]="taxaes" class="p-6	" (ngSubmit)="editStateLocalTax()">
    <div>

      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>Local Sales Tax </mat-label>
        <input step="0.1" type="number" matInput placeholder="" formControlName="local_tax_percent">
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>State Sales Tax</mat-label>
        <input step="0.1" type="number" matInput placeholder="" formControlName="state_tax_percent">
      </mat-form-field>

    </div>
    <button mat-raised-button type="submit">Save</button>
  </form>
</div>