<div class="count-down-timer">
  <p>Countdown to {{ schedule | date:'hh:mmaaa M/d/yy' }}</p>
  <div class="wrapper">
    <div class="description">
      <p>Days</p>
      <p>Hours</p>
      <p>Minutes</p>
      <p>Seconds</p>
    </div>
    <div class="times">
      <p #days>{{daysToDday || 0}}</p>
      <p #hours>{{hoursToDday || 0}}</p>
      <p #minutes>{{minutesToDday || 0}}</p>
      <p #seconds>{{secondsToDday || 0}}</p>
    </div>
  </div>
</div>
