<form class="order-message-table-filters flex-wrap">
  <div class="flex flex-wrap items-start">
  <mat-form-field appearance="fill" class="search">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Search Orders</mat-label>
    <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input (input)="searchOrders($event)">
  </mat-form-field>

  <mat-form-field appearance="fill" class="start">
    <mat-label>Start Date</mat-label>
    <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker" placeholder="Choose a start date" (dateInput)="searchOrders($event)">
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #startPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill" class="end">
    <mat-label>End Date</mat-label>
    <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker" placeholder="Choose a end date" (dateInput)="searchOrders($event)" [min]="startDate">
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
  </mat-form-field>
</div>
  <button mat-flat-button class="apply-btn-colr clear action-btn-colr create" (click)="reset()" color="primary">CLEAR</button>
  <div class="clear"></div>
</form>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
<mat-spinner></mat-spinner>
</div>

<div class="order-container full-y-width  xs:!overflow-x-scroll  xs:m-auto">
<table mat-table width="100%" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Order </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }">  {{element.id}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="redeemed_at">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Redeemed </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Placed"> {{ element.redeemed_at | date:'MMM dd EEE hh:mm a'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone_number">
    <mat-header-cell *matHeaderCellDef mat-sort-header> User </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="User">{{element.offer_item.offer_order.phone_number}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="offer_id">
    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Total">
        {{element.redeem_id}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Payment"> {{element.redeemed_amount | currency:'USD'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="balance">
      <mat-header-cell *matHeaderCellDef>Balance</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Balance">
        {{(element.offer_item.total_list_price - element.offer_item.redeemed_amount) | currency:'USD'}}
      </mat-cell>
  </ng-container>

  <ng-container matColumnDef="gift_card_id">
    <mat-header-cell *matHeaderCellDef>Gift Card ID</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Balance">
      {{element.offer_item.id}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="redeeme_at">
    <mat-header-cell *matHeaderCellDef fxHide.lt-sm>Redeemed at</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Balance" fxHide.lt-sm>
      {{element.target_store}}
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="payment_status">
    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Status">
      <mat-icon *ngIf="element.is_reversed" matTooltip="Reversed">sync</mat-icon>
      <mat-icon *ngIf="!element.is_reversed" matTooltip="Done">done</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="undo(element)" [disabled]="element.is_reversed">Undo</button>
        </mat-menu>
      </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

</div>
<mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>


