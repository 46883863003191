import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from 'src/app/_models/store';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {BooleanOptions, Options} from 'src/app/_models/Options';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-new-modifier-group',
  templateUrl: './add-new-modifier-group.component.html',
  styleUrls: ['./add-new-modifier-group.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AddNewModifierGroupComponent implements OnInit {

  @Input() store:Store;
  @Output() refresh = new EventEmitter();

  form:UntypedFormGroup
  columnsToDisplay = ['addModGroup']
  expandedElement
  datasource = [{"addModGroup": "Add modifier group"}]
  mods: Options[];
  presenseOptions: BooleanOptions[];

  @ViewChild('f', {static: false}) ngForm;

  constructor(private formBuilder: UntypedFormBuilder, private menuService:MenuServiceV2, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.mods = [];
    this.mods.push(new Options('No Max', -1));

    for (let index = 1; index < 11; index++) {
      this.mods.push(new Options('' + index, index));
    }

    this.presenseOptions = [];
    this.presenseOptions.push(new BooleanOptions('Required', true));
    this.presenseOptions.push(new BooleanOptions('Optional', false));

    this.form = this.formBuilder.group({
      modifier_group_name: new UntypedFormControl('', Validators.required),
      max_mod:  new UntypedFormControl(-1, Validators.required),
      is_required: new UntypedFormControl(false),
      modifier_name: new UntypedFormControl('', Validators.required),
      upcharge: new UntypedFormControl('', Validators.required),
      food_cost: new UntypedFormControl('')
    })
  }
  save(form){
    if(form.valid){
      this.menuService.addModGroup(this.store.id, JSON.stringify(form.value)).subscribe(response => {
        this.snackBar.open('Item has been added', 'OK', { duration: 2000 });
        this.ngForm.resetForm()
        this.refresh.emit();
      })
    }
  }

}
