import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {BotOrder, Modifier, Order, OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  botOrder: BotOrder;
  initial: string;
  @Input() orderWithPayment: OrdersWithPayment;
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  displayedSplitColumns: string[] = [ 'item', 'itemTotal'];
  estimatedTime;
  pizzaOptions: Map<string, string> = new Map(Object.entries({
    ADD: 'WHOLE',
    LHLF: 'LEFT',
    RHLF: 'RIGHT'
  }));
  @Input() onlyItems = false;
  private destroySubject: Subject<void> = new Subject();
  @Input() splitOrder: any;

  constructor(private receiptService: ReceiptService, private orderService: OrderService) { }
  ngOnInit() {
    this.receiptService.current.pipe(takeUntil(this.destroySubject)).subscribe(botOrder => {
      console.log(botOrder);

      if (botOrder) {
        this.orderService.getOrder(botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
          this.orderWithPayment = Object.assign(new OrdersWithPayment(), data, {
            items: data.items.map(o => Object.assign(new Order(), o, {
              modifiers: o.modifiers.map(x  => Object.assign(new Modifier(), x))
            }))
          });
          this.initial = this.orderWithPayment.store.store_name.split(' ').map(p => p[0]).join('');
        });
      }

      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

}
