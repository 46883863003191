import {Injectable} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {ParseResult} from 'ngx-papaparse/lib/interfaces/parse-result';
import {Observable} from 'rxjs';
import {CsvData} from '../_interfaces/csv-data';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private papa: Papa) { }

  parseFile(file: File, delimiter: string = ','): Observable<CsvData> {
    return Observable.create((obs) => {
      this.papa.parse(file, {
        delimiter,
        complete: (results: ParseResult) => {
          const headers: string[] = results.data[0];
          const data = results.data.slice(1).filter((row: string[]) => {
            return row.length > 1 && row[0] !== '';
          });
          obs.next({ headers, data });
        }
      });
    });
  }
}
