import { Constants } from '../constants';
import { MenuItem } from './order';

export class Account {
  id: string;
  company: string;
  token: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  ftp_username: string;
}

export class StoreData {
  employee_state_tax_percent: number;
  employee_federal_tax_percent: number;
  tip_value_min_order: number;
  tip_values: any[];
}
export class Store {
  id: string;
  account: Account;
  store_name: string;
  store_number: number;
  address: string;
  state_geographic: string;
  phone: string;
  secondary_phone: string;
  ivr_phone: string;
  connect_number: string;
  sms_number: string;
  sms_webhook: string;
  time_zone: string;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  has_order_time_now: boolean;
  has_order_time_later: boolean;
  has_order_occasion_pickup: boolean;
  has_order_occasion_delivery: boolean;
  has_order_occasion_dine_in: boolean;
  has_order_occasion_curbside: boolean;
  has_order_occasion_tableside: boolean;
  has_order_occasion_togo: boolean;
  has_order_occasion_forhere: boolean;
  pickup_dine_in_lead_time: number;
  pin_timeout: number;
  pos_setting: any;
  dine_in_lead_time: number;
  delivery_lead_time: number;
  delivery_radius: number;
  delivery_charge: number;
  delivery_minimum: number;
  gmb_location: boolean;
  gmb_location_id: string;
  gmb_icon_file: string;
  gmb_cover_file: string;
  gmb_menu_url: string;
  gmb_order_url: string;
  service_toggle: boolean;
  has_menu: boolean;
  printouts_per_order: number;
  working: Map<string, WorkingDay>;
  voided_check: any;
  verified: boolean;
  has_structured_menu: boolean;
  enable_parser: boolean;
  printer_id: string;
  ivr_welcome_message: string;
  payment_gateway: string;
  service_charge: number;
  url_slug: string;
  enable_text_ordering: boolean;
  enable_pick_and_click_ordering: boolean;
  printouts_per_delivery_order: number;
  printouts_per_curbside_order: number;
  printouts_per_dine_in_order: number;
  lead_times: any[]
  last_order_buffer: Map<number, number>;
  occasion_flags: Map<string, boolean>;
  modules: string[];
  zip_code;
  city;
  feature_flag: Map<string, boolean>;
  special_hour: any[]
  tip_percent_option: any[];
  service_charge_label: string;
  service_charge_description: string;
  has_card_payment: boolean;
  toggle_tip: boolean;
  enable_pos_pay_later: boolean;
  enable_default_service_charge: boolean;
  service_charge_percent_option: any[]
  pos_service_charge_title: string;
  payroc_username: string;
  enable_consumer_choice_tip: boolean;
  enable_consumer_choice: boolean;
  surcharge_label: any;
  surcharge_percent: any;
  // surcharge_label: any;
  surcharge_text: any;
  online_service_charge_title: any;
  default_online_service_charge: any;
  default_pos_service_charge: any;
  local_tax_percent: any;
  state_tax_percent: any;
  disclaimer_message: any;
  disclaimer_show: boolean;
  disclaimer_from_time: any;
  disclaimer_to_time: any;

  enable_cash_payment: boolean;
  pos_payment_gateway: string;
  service_charge_label_pos: string;
  //holiday_list:any
  nmi_public_key: string;
  service_charge_pos: any;
  consolidate_taxes_and_fees: any;
  enable_manual_print_control: boolean;
  enable_pos_credit_service_charge_dollar: boolean;
  enable_online_credit_service_charge_dollar: boolean;
  surcharge_label_dollar: any;
  pos_surcharge_percent_label: any;
  pos_surcharge_percent: any;
  surcharge_dollar: any;
  pos_surcharge_dollar_label: any;
  pos_surcharge_dollar: any;
  store_pause_time: any;
  pause_store: boolean;
  pause_store_whole_day: boolean;
  store_pause_date_time: any;
  store_whole_day_pause_date_time: any;
  gift_card_default_image: any;
  store_type: any;
  occasion_pickup_message: any;
  occasion_dinein_message: any;
  occasion_delivery_message: any;
  occasion_curbside_message: any;
  occasion_tableside_message: any;
  store_clock_out_time: number;
  store_data: StoreData;
  holiday_list: any[];
  online_order_link: any[];
  getServiceChargeLabel() {
    return this.service_charge_label_pos && this.service_charge_label_pos.length > 0 ? this.service_charge_label_pos : 'Service Fee'
  }

  getSurchargeLabel() {
    if (this.enable_pos_credit_service_charge_dollar || this.enable_online_credit_service_charge_dollar) {
      return this.surcharge_label_dollar && this.surcharge_label_dollar.length > 0 ? this.surcharge_label_dollar : 'Surcharge'
    } else {
      return this.surcharge_label && this.surcharge_label.length > 0 ? this.surcharge_label : 'Surcharge'
    }

  }

  getTodaysWorkingConfig() {
    let today = new Date()
    var dayIndex = today.getDay() - 1;
    if (dayIndex < 0) {
      dayIndex = 6;
    }
    return this.getSlot(dayIndex)
  }

  getSlot(dayIndex) {
    let workingHours = this.working[dayIndex]['hours']
    var slot;

    if (workingHours.length === 1) {
      slot = workingHours[0]
    } else {
      let now = new Date()
      slot = workingHours.find(w => w.from_hour >= now.getHours())
      if (!slot) {
        slot = workingHours[workingHours.length - 1]
      }
    }
    slot['is_open'] = this.working[dayIndex]['open_type'] !== 'CLOSED'
    return slot;
  }

  getFeatureFlag(key) {
    if (key in this.feature_flag) {
      return this.feature_flag[key]
    } else {
      return false;
    }
  }
  getTodayIndex() {
    let today = new Date()
    var dayIndex = today.getDay() - 1;
    if (dayIndex < 0) {
      dayIndex = 6;
    }
    return dayIndex;
  }

  getLeadTime(occasion) {
    let leadTime = 0;
    let now = new Date();
    let dayIndex = this.getTodayIndex()
    if (occasion === 'PICKUP' || occasion === 'CURBSIDE') {
      leadTime = this.pickup_dine_in_lead_time;
    } else if (occasion === 'DELIVERY') {
      leadTime = this.delivery_lead_time;
    }

    let leadTimes: any[] = this.lead_times.filter(x => x.day === dayIndex && x.occasion === Constants.occasionRi[occasion])

    leadTimes.forEach(lt => {
      let start = new Date()
      start.setHours(lt.from_hour)
      start.setMinutes(lt.from_min)
      let end = new Date()
      end.setHours(lt.to_hour)
      end.setMinutes(lt.to_min)
      if (start < now && end > now) {
        leadTime = lt.lead_time;
      }
    });
    return leadTime;
  }

  hasModule(module) {
    return this.modules.includes(module)
  }

  getOccasions() {
    const filteredOccasions: string[] = [];
    Object.entries(this.occasion_flags).forEach(
      ([occasion, available]) => available && filteredOccasions.push(occasion)
    );
    return filteredOccasions;
  }

  getSource() {
    let sources = []
    if (this.enable_text_ordering) {
      sources.push('TEXT');
    }

    if (this.hasModule('POS')) {
      sources.push('POS');
    }

    if (this.enable_pick_and_click_ordering) {
      sources.push('ONLINE');
    }
    return sources;
  }

  getCloseStatusTypes() {
    let closeStatusTypes = [];
    closeStatusTypes.push(...['CLOSED', 'CANCELLED', 'REFUND', 'TIP_ADD', 'ADJUSTMENT'])

    return closeStatusTypes;
  }

  getOpenStatusTypes() {
    let openStatusTypes = [];
    openStatusTypes.push(...['OPEN'])

    return openStatusTypes;
  }

  getPaymentTypes() {
    let paymentTypes = [];
    if (this.has_card_payment) {
      paymentTypes.push('CREDIT');
    }

    if (this.enable_cash_payment || (this.modules.length > 1 && this.modules.includes('POS'))) {
      paymentTypes.push('CASH');
    }

    paymentTypes.push(...['STORE_CREDIT', 'SPLIT', 'GIFT', 'CREDIT_KEYED', 'PAY_LATER', 'OTHER']);
    return paymentTypes;
  }

  getOrderTiming() {
    let order_time = [];
    if (this.has_order_time_now) {
      order_time.push('NOW');
    }

    if (this.has_order_time_later) {
      order_time.push('LATER');
    }
    return order_time;
  }
}

export class PosSetting {
  default_load: boolean;
  closed_orders: boolean;
  open_orders: boolean;
  default_order_occasion: string;
  default_time: string;
  id: number;
  store: Store;
}


export class WorkingDay {
  is_open: boolean;
  from_hour: number;
  from_min: number;
  to_hour: number;
  to_min: number;

  get12HrStartTime() {
    return this.get12HourString(this.from_hour, this.from_min)
  }

  get12HrEndTime() {
    return this.get12HourString(this.to_hour, this.to_min)
  }

  get12HourString(hour: number, min: number) {
    const hours = hour > 12 ? hour / 12 : hour;
    const mins = min === 0 ? '00' : min;
    return hours + ' : ' + mins + ' ' + (hour > 12 ? 'PM' : 'AM');
  }
}

export class StoreMenuGroup {
  group_name: string;
  items: MenuItem[];
}
export class StoreMenuItems {
  menu_items: StoreMenuGroup[];
  store: Store;
  menu_order: string[];
}

export class MenuItemGroup {
  id: number;
  name: string;
  description: string;
  starting_price: number;

}
export class StoreMenuItemsV2 {
  menu_items: Map<string, MenuItemGroup>;
  store: Store;
  menu_order: string[];
}

export class CoPilotMerchant {
  id: number;
  dba_name: string;
  legal_business_name: string;
  tax_filing_method: string;
  tax_filing_name: string;
  website_address: string;
  business_phone: string;
  business_address_address: string;
  business_address_city: string;
  business_address_state: string;
  business_address_zip: string;
  mailing_address_address: string;
  mailing_address_city: string;
  mailing_address_state: string;
  mailing_address_zip: string;
  deposit_bank_name: string;
  deposit_bank_account_number: string;
  deposit_bank_account_type: string;
  deposit_bank_routing_number: string;
  withdrawal_bank_name: string;
  withdrawal_bank_account_number: string;
  withdrawal_bank_account_type: string;
  withdrawal_bank_routing_number: string;
  owner_name: string;
  owner_type: string;
  owner_title: string;
  owner_email: string;
  owner_ssn: string;
  owner_phone: string;
  owner_mobile_phone: string;
  owner_address: string;
  owner_city: string;
  owner_address_state: string;
  owner_zip: string;
  voided_check: string;
  merchant_id: string;
  signature_url: string;
  gateway_boarding_status: string;
  boarding_process_status: string;
  created_at: string;
  updated_at: string;
}

export class StoreMerchant {
  id: number;
  store: Store;
  copilot_merchant: CoPilotMerchant;
}


export class PrinterSetting {
  store: number;
  name: string;
  printer_id: string;
  pickup_count: number;
  delivery_count: number;
  curbside_count: number;
  dine_in_count: number;
  tableside_count: number;
  kitchen_count: number;
  has_kitchen_format: boolean
  created_at: Date;
  updated_at: Date;
  heartbeat: PrinterHeartbeat;
}


export class PrinterHeartbeat {
  store: number;
  printer_id: string;
  last_seen: Date;
  is_online: boolean
  name: string;
  created_at: Date;
  updated_at: Date;
}

export const MenuResetOptions = [
  { key: 1, value: 'Never' },
  { key: 2, value: 'In one hour' },
  { key: 3, value: 'Start of next day ' },
  { key: 4, value: 'Start of next week' },
  { key: 5, value: 'Start of next month' },
];


export class IncomingCall {
  id: number;
  call_id: string;
  customer_name: string;
  is_existing_customer: boolean;
  phone_number: string;
  store_id: number;
}
