<div>
  <div class="uper-text">
    Cashier Verification
  </div>
  <div class="body-text1 input-set">
    <input class="verify-box" [(ngModel)]="otpValues[0]" maxlength="1" (input)="focusNext(1)" #input1>
    <input class="verify-box" [(ngModel)]="otpValues[1]" maxlength="1" (input)="focusNext(2)" #input2>
    <input class="verify-box" [(ngModel)]="otpValues[2]" maxlength="1" (input)="focusNext(3)" #input3>
    <input class="verify-box" [(ngModel)]="otpValues[3]" maxlength="1" (input)="focusNext(4)" #input4>
    <input class="verify-box" [(ngModel)]="otpValues[4]" maxlength="1" (input)="focusNext(5)" #input5>
    <!-- <input class="verify-box" [(ngModel)]="otpValues[5]" maxlength="1" (input)="onLastInput()" #input6> -->
  </div>
  <div class="bottom-text">
    Enter Verification PIN
  </div>
  <div style="text-align: center;">
    <!-- <button class="mat-raised-button" mat-dialog-close>Cancel</button> -->
    <button class="mat-raised-button mat-primary button-set" (click)="cashierVerification()">Verify</button>
  </div>
</div>