<form [formGroup]="headersetting" (ngSubmit)="editAttentionContent()" class="fixActionRow">
  <div mat-dialog-content class="container">
    <div>
      <!-- <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>Disclaimer Text</mat-label>
        <textarea matInput formControlName="attention"></textarea>
      </mat-form-field> -->

      <div>
        <angular-editor matInput formControlName="attention" [(ngModel)]="htmlContent" [config]="config">
        </angular-editor>
      </div>
      <mat-form-field appearance="fill" style="width: 100%;padding-top: 20px;" class="form-fieldd">
        <mat-label>Start Date & Time</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Choose a star date and time"
          formControlName="startdate">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>End Date & Time</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Choose a end date and time"
          formControlName="enddate">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-raised-button type="submit" color="primary" class="apply-btn-colr">Save</button>
    </div>
</form>