import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-sub-menu-categories-dialog',
  templateUrl: './sub-menu-categories-dialog.component.html',
  styleUrls: ['./sub-menu-categories-dialog.component.scss']
})
export class SubMenuCategoriesDialogComponent implements OnInit {
  getId: any;
  editCategoryForm: UntypedFormGroup;
  submitted: boolean;
  newScreen: boolean;
  hideCategory: boolean;
  hideProductWeight: boolean = false;
  emojiLists: any;
  storeId: any;
  emoji: any;
  subcategory_list_data: any;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<SubMenuCategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    console.log('data 11111111111', data);

  }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.subCategoryList();
    this.getId = this.data.id,
      this.newScreen = this.data.new_screen;
    this.hideCategory = this.data.hide_category;
    // this.editSubCategoryForm = this.fb.group({
    //   // tv_id: [this.data.tv_id],
    //   hide_category: [this.data.hide_category],
    //   display_name: [this.data.display_name],
    //   new_screen: [this.data.new_screen],
    //   priority: [this.data.priority],
    //   font_color: [this.data.font_color],
    //   emoji: [this.data.emoji],
    //   store_id: [this.storeId],
    // })

    this.editCategoryForm = this.fb.group({
      store_id: [this.storeId],
      display_name: [this.data.display_name],
      priority: [this.data.priority],
      hide_category: [this.data.hide_category],
      new_screen: [this.data.new_screen],
      menuitems: [""],
      font_color: [this.data.font_color],
      hide_product_weight: [""],
      emoji: [this.data.emoji]
    });

    this.emojiList();
  }
  onNoClick() {
    this.dialogRef.close();
  }
  subCategoryList() {
    this.digitalDisplayService
      .subCategoryList(this.storeId).subscribe((data:any) => {
        this.subcategory_list_data = data.data;
        // this.subcategory_list_data = data.data;
      });
  }
  editSubCategoryFormSubmit() {
    this.submitted = true;
    if (this.editCategoryForm.invalid) {
      return;
    }
    this.digitalDisplayService.editSubCategory(this.getId, this.editCategoryForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.dialogRef.close(resData);
    })
  }

  newScreenCheck(a) {
    this.editCategoryForm.value.new_screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.editCategoryForm.value.hide_category = a.checked;
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emojiLists = data.data;

    });
  }
}
