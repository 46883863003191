import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DatePipe} from '@angular/common';

@Directive({
  selector: '[dateTimeFormat]'
})
export class DateTimeFormatDirective implements OnChanges {
  @Input('dateTimeFormat') value: Date;

  constructor(private el: ElementRef, private datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const formattedValue = this.datePipe.transform(this.value, 'MM/dd/yyyy, HH:mm');
      this.el.nativeElement.value = formattedValue;
    }
  }
}
