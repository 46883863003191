<form class="edit-screen-form fixActionRow"  name="editCssForm" [formGroup]="editCssForm" (ngSubmit)="editCssFormFormSubmit()">
    <h1 mat-dialog-title>Advance Css</h1>

    <div class="input-gap xs:!w-1/2 xs:!-ml-1" style="padding: 0px 22px;">
        <mat-form-field appearance="fill" fxFlex="100" class="xs:!w-full">
            <mat-label>CSS</mat-label>
            <textarea matInput style="height: 100px;" formControlName="advance_css"></textarea>
        </mat-form-field>
    </div>

    <mat-dialog-actions align="end" class="button-box-right form-row">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
        <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
</form>