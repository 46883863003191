<div class="example-container">
  <div class="example-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="example-table-container">

    <div class="row" *ngIf="!isBlocked">
      <button mat-flat-button color="primary" class="action-btn-colr"
        (click)="openEditDailog({name:'', is_blocked:false, phone_number:''})">Add Contact</button>

      <button mat-flat-button id="bulk_import_button" color="primary" class="action-btn-colr" (click)="openBulkImportDialog()">Bulk Import</button>
    </div>

    <div class="row mt-2">
      <mat-form-field class="width-70">
        <mat-label>Search contacts</mat-label>
        <input [(ngModel)]="filter"
          matInput placeholder="Search" (input)="ngAfterViewInit()">
      </mat-form-field>
    </div>

    <table mat-table [dataSource]="data" class="example-table"
           matSort matSortActive="created" matSortDisableClear
           matSortDirection="desc">

      <ng-container matColumnDef="phone_number">
        <th mat-header-cell *matHeaderCellDef>Contact Phone</th>
        <td mat-cell *matCellDef="let row">{{row.phone_number | phone}}</td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Contact Since</th>
        <td mat-cell *matCellDef="let row">{{row.created_at | date:'MMM dd EEE hh:mm a'}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Contact Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="openEditDailog(row)"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
</div>
