import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentGatewayService{
  endpoint = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient){}

  getHPPUrl(store_id: number, orderHash: string, isOneTime: boolean = true) {
    const params = {
      order_hash: orderHash, store_id: store_id, is_one_time: isOneTime
    }
    return this.http.get(`${this.endpoint}/backend/gateway/worldnet/hpp-payload/`, {params: params});
  }

  getHostedToken(dataAPI: any): Observable<any> {
    const url = `${this.endpoint}/backend/gateway/worldnet/hosted_token/`;
    return this.http.post(url, dataAPI);
  }

}
