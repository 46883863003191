<div class="p-5">
  <button class="w-[27%] h-[37px] text-[18px] rounded-md text-black bg-white border-gray-300 border-[1px] mr-3 mb-[6px]" (click)="closeDialog()">Back</button>
  <span class="flex flex-col items-center justify-center h-full mb-3.5">{{ data.menuItemGroup.name }}</span>
  <div *ngFor="let image of imageList;" class="mb-3.5">
    <a (click)="delete(image.id, 'single_image')" style="cursor: pointer">
      <mat-icon style=" float: right;margin-left: 120px; margin-top:25px;position: absolute;">delete</mat-icon>
    </a>
    <img class="rounded-lg" [src]="image.thumbnail" [alt]="">
  </div>
  <a (click)="delete('', 'all')" style="cursor: pointer"
    class="flex flex-col items-center justify-center h-full mb-3.5 mt-3.5">Delete All Photos
  </a>
</div>