import { Component, Input, OnInit,SimpleChanges } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { OrdersWithPayment } from 'src/app/_models/order';
import { OrderService } from 'src/app/_services/order.service';
import { RefreshService } from 'src/app/_services/refresh.service';
import { Modules } from 'src/app/constants';

@Component({
  selector: 'app-payment-breakdown',
  templateUrl: './payment-breakdown.component.html',
  styleUrls: ['./payment-breakdown.component.scss']
})
export class PaymentBreakdownComponent implements OnInit {

  @Input() orderWithPayment:OrdersWithPayment;
  router: any;

  constructor(private orderService: OrderService,  public dialog: MatDialog, private refreshService: RefreshService) { }

  ngOnInit() {
    setTimeout(() => {
      () => window.location.reload()
      console.log('reloadddd');
    }, 1500);

  }

  removeDiscount(event: Event) {
    event.preventDefault();
    this.orderService.removeQuickDiscount(this.orderWithPayment.bot_order.order_hash).subscribe((data: any) => {
      this.refreshService.refreshModule(Modules.POS_ORDER_SUMMARY);

    });
  }

  // reloadComponent() {
  //   let currentUrl = this.router.url;
  //       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //       this.router.onSameUrlNavigation = 'reload';
  //       this.router.navigate([currentUrl]);
  //   }
  ngOnChanges(changes: SimpleChanges) {
    console.log('hhhhhhhhhhhhhhhh orderWithPayment')
    if (changes.orderWithPayment) {
      console.log('orderWithPayment ')
      this.orderWithPayment = changes.orderWithPayment.currentValue;
    }
  }
}
