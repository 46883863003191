<form [formGroup]="form" (ngSubmit)="onSubmit()" class="fixActionRow">
  <h1 mat-dialog-title>Custom Availability</h1>
  <div class="ml-6">
    <p class="message">Enter custom availability.</p>
  </div>
  <div mat-dialog-content [ngClass.xs]="{'scroll_xs': true}">
    <div formGroupName="day_availability">
      <div fxLayout="row" class="table" [ngClass.lt-md]="{'table_mobile': true}" fxLayout.lt-md="row" cols="2"
           *ngFor="let day of days; let di = index">
        <ng-container [formGroupName]="di">
          <div fxFlex class="cell">
            <div class="header">{{day}}</div>
          </div>
          <div fxFlex class="cell">
            <input type="hidden" formControlName="day">
            <mat-slide-toggle formControlName="is_available"></mat-slide-toggle>
          </div>

        <div fxFlex class="cell">
          <mat-form-field *ngIf="form?.value.day_availability[di]?.is_available">
            <mat-label>From</mat-label>
            <input matInput [ngxTimepicker]="timepicker" formControlName="from">
            <ngx-material-timepicker #timepicker class="special-hour-picker">
            </ngx-material-timepicker>
          </mat-form-field>
        </div>

        <div class="cell">
          <mat-form-field *ngIf="form?.value.day_availability[di].is_available">
            <mat-label>To</mat-label>
            <input matInput [ngxTimepicker]="timepickerone" formControlName="to">
            <ngx-material-timepicker #timepickerone class="special-hour-picker">
            </ngx-material-timepicker>
          </mat-form-field>
        </div>
        </ng-container>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="primary-color primary-button apply-btn-colr">Apply</button>
  </mat-dialog-actions>
</form>
