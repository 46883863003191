<div class="status2-container">
  <mat-table width="100%"  [dataSource]="rolesList">
    <ng-container matColumnDef="role_name">
      <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.role_name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="editRole(element)">Edit</button>
          <button mat-menu-item (click)="deleteRole(element)">Delete</button>
        </mat-menu>
      </mat-cell>
  </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;"
    [ngClass]="{ 'highlight-order-row': row === selectedRow }" (click)="onRowClicked(row)"></mat-row>
  </mat-table>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close class="ok">OK</button>
</div>