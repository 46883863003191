import {Component, OnInit} from '@angular/core';
import {ReceiptService} from 'src/app/_services/receipt.service';
import {environment} from 'src/environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-order-app-iframe',
  templateUrl: './order-app-iframe.component.html',
  styleUrls: ['./order-app-iframe.component.scss']
})
export class OrderAppIframeComponent implements OnInit {

  orderUrl;

  constructor(private receiptService:ReceiptService, private sanitizer:DomSanitizer) { }


  ngOnInit() {
    this.receiptService.current.subscribe(botOrder => {
      if(botOrder){
        this.orderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.orderAppUrl}/order/${botOrder.order_hash}?is_admin=1`);
      }else{
        this.orderUrl = null;
      }
    })
  }
}
