<!-- <app-progress-spinner></app-progress-spinner> -->
<div class="menu-container">
  <mat-tab-group>
    <mat-tab label="1: CATEGORIES & MENU ITEMS">
      <ng-template matTabContent>
        <div class="item-container">
          <app-menu-category (refresh)="refresh()"></app-menu-category>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="2: MODIFIERS">
      <ng-template matTabContent>
        <div class="item-container">
          <app-mod-groups [store]="store"></app-mod-groups>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="3: ASSIGN MODIFIERS">
      <ng-template matTabContent>
        <div class="item-container">
          <app-assign-mods [store]="store"></app-assign-mods>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
