import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse,} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(private http: HttpClient) { }

  getvoiceAttendantRecord() {
    return this.http.get<any>(`${environment.apiUrl}/backend/voice/list/`);
  }
  actionChangeVoice(data: any) {
    return this.http.put<any>(`${environment.apiUrl}/backend/v2/ivr_setting/ivr_change/voice/`, data);
  }

  favUpload(data:any){
    return this.http.post<any>(`${environment.apiUrl}/backend/v2/stores/favicon/`, data);
  }

  logoUpload(data:any){
    return this.http.post<any>(`${environment.apiUrl}/backend/v2/stores/logo/`, data);
  }


  upload(url: string, files: Set<File>, data: Object): { [key: string]: { progress: Observable<number> } } {

    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number> } } = {};


    if (files.size <= 0) {
      const postFormData: FormData = new FormData();
      for (var key in data) {
        postFormData.append(key, data[key])
      }
      const req = new HttpRequest('POST', url, postFormData);
      let progress = new Subject<number>();
      this.http.request(req).subscribe(e => {
        progress.next(100);
        progress.complete();
      })
      status['form'] = { progress: progress.asObservable() }
    } else {
      files.forEach(file => {
        // create a new multipart-form for every file
        const formData: FormData = new FormData();
        for (var key in data) {
          formData.append(key, data[key])
        }
        formData.append('document', file, file.name);

        // create a http-post request and pass the form
        // tell it to report the upload progress
        const req = new HttpRequest('POST', url, formData, {
          reportProgress: true
        });

        // create a new progress-subject for every file
        const progress = new Subject<number>();

        // send the http-request and subscribe for progress-updates
        this.http.request(req).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {

            // calculate the progress percentage
            const percentDone = Math.round(100 * event.loaded / event.total);

            // pass the percentage into the progress-stream
            progress.next(percentDone);
          } else if (event instanceof HttpResponse) {

            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            progress.complete();
          }
        });

        // Save every progress-observable in a map of all observables
        status[file.name] = {
          progress: progress.asObservable()
        };
      });
    }
    // return the map of progress.observables
    return status;
  }
}
