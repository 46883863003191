import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class UnreadService{

  endpoint = environment.apiUrl;
  private source = new BehaviorSubject<number>(null);
  current = this.source.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient){}

  unreadMessages(storeId:any){
    this.http.get<any>(`${this.endpoint}/backend/message/${storeId}/unread`, this.httpOptions).subscribe(data => this.updateUnreadCounter(data))
  }

  updateUnreadCounter(counter:any){
    this.source.next(counter);
  }


}
