import {RedeemHistory, RedeemHistorys} from '../_models/order';
import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {BusinessService} from '../business.service';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';


export class RedeemHistoryDataSource implements DataSource<RedeemHistory> {

  private redeemHistorySubject = new BehaviorSubject<RedeemHistory[]>([]);
  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private businessService: BusinessService) {}

  connect(collectionViewer: CollectionViewer): Observable<RedeemHistory[]> {
      return this.redeemHistorySubject.asObservable();
  }

  getTotal(){
    return this.totalSubject.getValue();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.redeemHistorySubject.complete();
      this.loadingSubject.complete();
  }

  loadRedeemHistory(storeId:number, filter = '',
  startDate=null, endDate=null,
  sortField='id', sortOrder='desc',
  pageNumber=0, pageSize=10) {
      this.loadingSubject.next(true);
      this.businessService.getRedeemHistory(storeId, filter, startDate, endDate, sortField, sortOrder, pageNumber, pageSize).pipe(
          catchError(() => of(new RedeemHistorys())),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(history => {
        this.redeemHistorySubject.next(history.orders);
        this.totalSubject.next(history.total);
      });
  }
  loadRedeemHistoryReferral(storeId:number, filter = '',
  startDate=null, endDate=null,
  sortField='id', sortOrder='desc',
  pageNumber=0, pageSize=10) {
      this.loadingSubject.next(true);
      this.businessService.getRedeemHistoyReferral(storeId, filter, startDate, endDate, sortField, sortOrder, pageNumber, pageSize).pipe(
          catchError(() => of(new RedeemHistorys())),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(history => {
        this.redeemHistorySubject.next(history.orders);
        this.totalSubject.next(history.total);
      });
  }

  
}
