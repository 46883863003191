import {Component, OnInit} from '@angular/core';
import {StoreService} from '../_services/store.service';
import {Store} from '../_models/store';

@Component({
  selector: 'app-attendant',
  templateUrl: './attendant.component.html',
  styleUrls: ['./attendant.component.scss']
})
export class AttendantComponent implements OnInit {

  store:Store;

  constructor(private storeService:StoreService) { }

  ngOnInit() {
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store)
      }
    })
  }

}
