import {Component, Input, OnInit} from '@angular/core';
import {Store} from 'src/app/_models/store';

@Component({
  selector: 'app-gift-cards-phone-preview',
  templateUrl: './gift-cards-phone-preview.component.html',
  styleUrls: ['./gift-cards-phone-preview.component.scss']
})
export class GiftCardsPhonePreviewComponent implements OnInit {

  @Input() store:Store;
  @Input() selectedOffer;
  @Input() image;

  @Input() title;
  @Input() description;
  @Input() disclaimer;
  @Input() endDate;
  @Input() quantity;

  constructor() {

  }

  ngOnInit() {
    console.log(this.image);

  }

}
