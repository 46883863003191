<div class="p-5">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()"
        class="flex flex-col mb-5 w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
        <h1 class="text-lg font-semibold mb-4">Set Review Message</h1>
        <mat-checkbox formControlName="send_feedback_message" class="mb-4">
            Send feedback message
        </mat-checkbox>
        <mat-form-field appearance="fill" class="mb-4">
            <mat-label>Review message</mat-label>
            <textarea matInput formControlName="feedback_message" rows="3"
                placeholder="Set your review message"></textarea>
        </mat-form-field>
        <button mat-raised-button style="background-color: #1e88e5; color: white;" type="submit" class="w-[100px]">
            Update
        </button>
    </form>
    <form class="flex flex-wrap pt-3 pb-2.5">
        <div class="flex flex-wrap items-start">
            <mat-form-field appearance="fill" class="search mr-4">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Orders</mat-label>
                <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input
                    (input)="searchOrders($event)">
            </mat-form-field>

            <mat-form-field appearance="fill" class="start mr-4">
                <mat-label>Start Date</mat-label>
                <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker"
                    placeholder="Choose a start date" (dateInput)="searchOrders($event)">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="end mr-4">
                <mat-label>End Date</mat-label>
                <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker"
                    placeholder="Choose a end date" (dateInput)="searchOrders($event)" [min]="startDate">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
            </mat-form-field>
        </div>
        <button mat-flat-button class="mt-2.5" (click)="reset()" style="background-color: #1e88e5; color: white;">CLEAR</button>
        <div class="clear"></div>
    </form>

    <div>
        <div class="order-container full-y-width  xs:!overflow-x-scroll  xs:m-auto">
            <table mat-table width="100%" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear
                [dataSource]="dataSource">

                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }">
                        {{element.id}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="phone_number">
                    <mat-header-cell *matHeaderCellDef> Phone Number </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }">
                        {{element.phone_number}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order_counter">
                    <mat-header-cell *matHeaderCellDef> Order Counter </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }">
                        {{element.order_counter}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="rating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Rating </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Rating"> {{ element.rating }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="feedback">
                    <mat-header-cell *matHeaderCellDef> Feedback </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Feedback">{{element.feedback}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_at">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Created At </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Created">
                        {{element.created_at | date: 'h:mm a MM/dd/yyyy'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="button">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="">
                        <button mat-raised-button style="background-color: #1e88e5; color: white;" (click)="goToMessages(element)">
                            Contact User
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
        </div>
        <mat-paginator [length]="totalFeedbacks" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
    </div>
</div>