<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="flex flex-col p-7">
    <div class="pb-10 flex">
        <button
            class="w-[25%] h-[72px] text-2xl rounded text-white mr-3.5 bg-[#4caf50] {{ startDrawerbtnvalidity() ? 'opacity-50 bg-[#c9c9c9]' : '' }}"
            (click)="startDrawer()" [disabled]="startDrawerbtnvalidity()">Start
            Drawer</button>
        <button
            class="w-[25%] h-[72px] text-2xl rounded text-white mr-3.5 bg-[#4caf50]  {{ cashInOutbtnvalidity() ? 'opacity-50 bg-[#c9c9c9]' : '' }}"
            (click)="payCashIn()" [disabled]="cashInOutbtnvalidity()">Pay
            Cash IN</button>
        <button
            class="w-[25%] h-[72px] text-2xl rounded text-white mr-3.5 bg-[#FFC300] {{ cashInOutbtnvalidity() ? 'opacity-50 bg-[#c9c9c9]' : '' }}"
            (click)="payCashOut()" [disabled]="cashInOutbtnvalidity()">Pay
            Cash OUT</button>
        <button
            class="w-[25%] h-[72px] text-2xl rounded text-white bg-[#FF5733] {{ cashInOutbtnvalidity() ? 'opacity-50 bg-[#c9c9c9]' : '' }}"
            (click)="endDrawer()" [disabled]="cashInOutbtnvalidity() || disablebtn">End
            Drawer</button>
    </div>
    <div class="pb-10">
        <p class="text-center text-3xl text-[dimgrey] font-medium" *ngIf="!data.status">{{data.terminal_name}} Drawer:
            Enter Total Starting Cash By Denomination</p>
        <p class="text-center text-3xl text-[dimgrey] font-medium" *ngIf="data.status">{{data.terminal_name}}: Enter
            Total Cash By Denomination</p>
    </div>
    <div>
        <div class="flex flex-row pb-7">
            <p class="text-center font-medium basis-1/4 text-[dimgrey]"> <span class="text-3xl">Total Bills:</span>
                <br>
                <span class="text-2xl">{{totalBills | currency}}</span>
            </p>
            <div class="basis-3/4 flex">
                <form [formGroup]="dollarForm" class="flex flex-row">
                    <!-- Dollar 100 -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('dollar_100', 'dollar')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('dollar_100', 'dollar')">-</button>
                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $100</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="dollar_100">
                        </div>

                    </div>

                    <!-- Dollar 50 -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('dollar_50', 'dollar')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('dollar_50', 'dollar')">-</button>

                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $50</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="dollar_50">
                        </div>

                    </div>
                    <!-- Dollar 20 -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('dollar_20', 'dollar')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('dollar_20', 'dollar')">-</button>

                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $20</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="dollar_20">
                        </div>

                    </div>

                    <!-- Dollar 10 -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('dollar_10', 'dollar')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('dollar_10', 'dollar')">-</button>

                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $10</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="dollar_10">
                        </div>

                    </div>

                    <!-- Dollar 5 -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('dollar_5', 'dollar')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('dollar_5', 'dollar')">-</button>

                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $5</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="dollar_5">
                        </div>

                    </div>

                    <!-- Dollar 1 -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('dollar_1', 'dollar')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('dollar_1', 'dollar')">-</button>

                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $1</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="dollar_1">
                        </div>

                    </div>
                </form>
            </div>
        </div>

        <div class="flex flex-row pb-7">
            <p class="text-center font-medium basis-1/4 text-[dimgrey]"> <span class="text-3xl">Total Change:</span>
                <br> <span class="text-2xl">{{totalChange | currency}}</span>
            </p>
            <div class="basis-3/4 flex">
                <form [formGroup]="centForm" class="flex flex-row">
                    <!-- Cent 50 (Half) -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('cent_50', 'cent')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('cent_50', 'cent')">-</button>
                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $0.50</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="cent_50">
                        </div>
                    </div>
                    <!-- Cent 25 (Quarter) -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('cent_25', 'cent')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('cent_25', 'cent')">-</button>
                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $0.25</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="cent_25">
                        </div>
                    </div>

                    <!-- Cent 0.10 (Dimes) -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('cent_10', 'cent')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('cent_10', 'cent')">-</button>
                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $0.10</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="cent_10">
                        </div>
                    </div>

                    <!-- Cent 0.05 (Nickels) -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('cent_5', 'cent')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('cent_5', 'cent')">-</button>
                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $0.05</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="cent_5">
                        </div>
                    </div>

                    <!-- Cent 0.01 (Penny) -->
                    <div class="flex mr-4">
                        <div class="flex flex-col justify-center mr-0.5">
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] bg-neutral-300 text-xl text-black"
                                (click)="incrementQuantity('cent_1', 'cent')">+</button>
                            <button
                                class="border-2 border-gray-200 rounded w-[55px] h-[52px] mt-1 bg-neutral-300 text-[34px] text-black leading-3"
                                (click)="decrementQuantity('cent_1', 'cent')">-</button>
                        </div>
                        <div class="w-[86px] input-border border-2 border-gray-200 rounded">
                            <p
                                class="text-center rounded text-2xl text-[dimgrey] font-medium border-b-2 border-b-gray-200 text-white bg-[#4caf50]">
                                $0.01</p>
                            <input class="text-center text-3xl text-[dimgrey] font-medium h-[71px] w-full" tabindex="-1"
                                type="number" matInput formControlName="cent_1">
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="flex flex-row pb-1">
            <p class="text-center font-medium basis-1/4"> <span class="text-3xl text-[dimgrey]">Total:</span> <br>
                <span class="text-[54px] text-[#4caf50]">{{total | currency}}</span>
            </p>
            <div class="basis-3/4"></div>
        </div>

    </div>
</div>