import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PrinterSetting} from '../_models/store';

@Injectable({ providedIn: 'root' })
export class PrinterSettingsService {
  endPoint = environment.apiUrl;
  attendantEndpointV2 = `${this.endPoint}/backend/v2/printer`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient){}

  getPrinterSettings(storeID:string){
    return this.http.get<PrinterSetting[]>(`${this.attendantEndpointV2}/${storeID}/`)
  }

  updatePrinterSetting(payload){
    return this.http.post<PrinterSetting>(`${this.attendantEndpointV2}/`, JSON.stringify(payload), this.httpOptions)
  }

}

