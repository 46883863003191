<mat-tab-group>
  <mat-tab label="Live">
    <app-insight></app-insight>
  </mat-tab>
  <mat-tab label="Reports">
    <app-mini-report></app-mini-report>
  </mat-tab>
  <mat-tab label="Statements">
    <app-report></app-report>
  </mat-tab>
</mat-tab-group>
