<div class="main">
  <div style="margin: 10px 0;">
    <mat-form-field style="width: 85%; margin:0 20px;">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Enter phone number" (keyup)='getContactList($event.target.value)' #searchBox>
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div class="item" *ngFor="let contact of contactOrder"
    (click)="onUserSelected(contact); markAsRead(contact);"
    [ngClass]="{ active: contact === selectedUser?.phone_number }">
    <div class="image-container">
      <img src="https://storage.googleapis.com/menus/static/frontend/img/avatar-person.svg" width="30px" height="30px" />
    </div>
    <div class="contacts-info">
      <p class="status-title" [ngClass]='{bold: unreadCounter[contact]}'>
        {{contactList[contact].user && contactList[contact].user.name ? contactList[contact].user.name : "*" + contact.substring(contact.length - 5) }}
      </p>
      <!-- <p class="last_message"></p> -->
    </div>
    <div class="time-info">
      <p class="last-time" *ngIf="!unreadCounter[contact] && contactList[contact].last_messaged">{{contactList[contact].last_messaged | niceDate }}</p>
      <p class="counter" *ngIf="unreadCounter[contact] > 0"
        matBadgeSize="small"
        matBadgePosition="after"
        matBadgeOverlap="false"
        [matBadge]='unreadCounter[contact]'></p>
    </div>
    <div style="clear: both;"></div>
  </div>
  <!-- <button mat-fab color="primary" class="new-conv">
    <mat-icon>add_comment</mat-icon>
  </button> -->
</div>
