<form>
  <div ngClass="card" [ngClass.xs]="{'card-xs': true, 'card': true}">
    <img src="https://storage.googleapis.com/menus/static/frontend/img/order-business-store-info.png"
      alt="Photo of a business store">
    <div class="content">
      <mat-action-list class="store-info">
        <mat-list-item style="margin-bottom: 10px;" (click)="edit(['store_name'])">
          <span class="text-xl" matListItemTitle>{{store.store_name}}</span>
          <mat-icon matListItemTitle class="edit-icon material-icons-outlined">edit</mat-icon>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item (click)="openBusinessLocation()">
          <mat-icon matListItemIcon class="!text-blue-500">location_on</mat-icon>
          <p matListItemTitle class="mt-1">{{store.address}}</p>
        </mat-list-item>

        <mat-list-item (click)="edit(['phone', 'secondary_phone'])">
          <mat-icon matListItemIcon class="!text-blue-500">call</mat-icon>
          <p matListItemTitle class="mt-1">{{store?.phone | phone }}</p>
          <p matListItemLine class="mt-1">{{store?.secondary_phone | phone }}</p>
        </mat-list-item>

        <mat-list-item (click)="openWorkingHours()">
          <mat-icon matListItemIcon class="!text-blue-500 mt-px	">access_time</mat-icon>
          <div *ngFor="let day of store.working | keyvalue">
            <ng-container [ngSwitch]="day.value.open_type">
              <div *ngSwitchCase='"OPEN"'>
                <div matListItemLine *ngFor="let dayhour of day.value.hours; let i = index" class="row">
                  <div class="col key">{{ i === 0 ? days[day.key] : "&nbsp;"}}</div>
                  <div class="col value">{{getWorkingHourString(dayhour)}}</div>
                </div>
              </div>
              <div matListItemLine *ngSwitchCase='"CLOSED"' class="row">
                <div class="col key">{{days[day.key]}}</div>
                <div class="col value">Closed</div>
              </div>
              <div matListItemLine *ngSwitchCase='"24_HOUR"' class="row">
                <div class="col key">{{ days[day.key]}}</div>
                <div class="col value">24 Hours</div>
              </div>
            </ng-container>
          </div>
        </mat-list-item>

        <mat-list-item (click)="openSpecialHours()">
          <mat-icon matListItemIcon class="!text-blue-500">more_time</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Special Hours</p>
          <div matListItemLine *ngFor="let hour of store.special_hour" class="col-span-2">
            <div class="col key">{{ hour.special_date | date:'shortDate' }} - {{ hour.special_end_date | date:'shortDate' }}</div>
            <div class="col value" *ngIf="hour.is_open">{{getWorkingHourString(hour.hour)}}</div>
            <div class="col value" *ngIf="!hour.is_open">Closed</div>
          </div>
        </mat-list-item>

        <mat-list-item (click)="openHolidays()">
          <mat-icon matListItemIcon class="!text-blue-500">beach_access</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Holidays</p>
          <div matListItemLine *ngFor="let hour of store.holiday_list" class="col-span-2">
            <div class="col key">{{ hour.holiday_name }}&nbsp; &nbsp; &nbsp;  
              <span *ngIf="!hour.is_open">Closed</span> 
              <span *ngIf="hour.is_open">Open</span>
            </div>
          </div>
          <!-- <mat-icon class="edit-icon material-icons-outlined" (click)="openHolidays()">edit</mat-icon> -->
        </mat-list-item>

        <mat-list-item class="url-section">
          <mat-icon matListItemIcon class="!text-blue-500">link</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Copy Links</p>
          <p matListItemLine class="row">Menu link <mat-icon matTooltip="Click to copy" (click)="copyMessage(links['menu'])"
              class="edit-icon material-icons-outlined copy-icon">file_copy</mat-icon></p>
          <p matListItemLine class="row">Giftcard link <mat-icon matTooltip="Click to copy"
              (click)="copyMessage(links['gift'])" class="edit-icon material-icons-outlined copy-icon">file_copy
            </mat-icon></p>
          <p matListItemLine class="row">Offers link<mat-icon matTooltip="Click to copy"
              (click)="copyMessage(links['offer'])" class="edit-icon material-icons-outlined copy-icon">file_copy
            </mat-icon></p>
          <p matListItemLine class="row">Referral link<mat-icon matTooltip="Click to copy"
              (click)="copyMessage(links['referral'])" class="edit-icon material-icons-outlined copy-icon">file_copy
            </mat-icon></p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item (click)="openServiceArea()">
          <mat-icon matListItemIcon class="!text-blue-500">my_location</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Service Areas</p>
          <span *ngIf="serviceArea && serviceArea.service_type === 'RADIUS'">
            <span matListItemLine>Servicing customers within {{serviceArea.radius}} miles</span>
          </span>
          <ng-container *ngIf="serviceArea && serviceArea.service_type === 'AREA'">
            <p matListItemLine class="row" *ngFor="let area of serviceArea.areas">
              <span class="col key">{{area.name}} — {{area.charge | currency:'USD'}}</span>
            </p>
          </ng-container>
        </mat-list-item>

        <mat-list-item (click)="edit(['has_order_occasion_pickup', 'has_order_occasion_delivery', 'has_order_occasion_dine_in', 'has_order_occasion_curbside', 'has_order_occasion_tableside', 'has_order_occasion_togo', 'has_order_occasion_forhere'])">
          <mat-icon matListItemIcon class="!text-blue-500">local_dining</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Order Occasions</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_pickup">Pickup</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_delivery">Delivery</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_dine_in">Dine-In</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_curbside">Curbside</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_tableside">Tableside</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_togo && store?.modules.includes('POS')">To-Go</p>
          <p matListItemLine class="row" *ngIf="store.has_order_occasion_forhere && store?.modules.includes('POS')">For-Here</p>
        </mat-list-item>

        <mat-list-item (click)="openLeadTime()">
          <mat-icon matListItemIcon class="!text-blue-500">access_alarms</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Leadtimes</p>
          <p matListItemLine class="row">
            <span class="col key">Pickup</span>
            <span class="col value">{{store.pickup_dine_in_lead_time}} min</span>
          </p>
          <p matListItemLine class="row">
            <span class="col key">Dine-In</span>
            <span class="col value">{{store.dine_in_lead_time}} min</span>
          </p>
          <p matListItemLine class="row">
            <span class="col key">Delivery</span>
            <span class="col value">{{store.delivery_lead_time}} min</span>
          </p>
          <p matListItemLine *ngIf="store.lead_times.length > 0">&nbsp;</p>

          <p matListItemTitle class="mt-1 mb-1" *ngIf="store.lead_times.length > 0">Custom Leadtimes</p>
          <p matListItemLine *ngIf="getCustomLeadTimeString(1).length > 0">Pickup</p>
          <p matListItemLine class="row" *ngFor="let l of getCustomLeadTimeString(1)">
            <span class="col key1">{{days[l.day]}}</span>
            <span class="col value2">{{get12HourString(l.from_hour, l.from_min)}} — {{get12HourString(l.to_hour,
              l.to_min)}}</span>
            <span class="col value1">{{l.lead_time}} min</span>
          </p>

          <p matListItemLine *ngIf="getCustomLeadTimeString(2).length > 0">&nbsp;</p>
          <p matListItemLine *ngIf="getCustomLeadTimeString(2).length > 0">Dine-In</p>
          <p matListItemLine class="row" *ngFor="let l of getCustomLeadTimeString(2)">
            <span class="col key1">{{days[l.day]}}</span>
            <span class="col value2">{{get12HourString(l.from_hour, l.from_min)}} — {{get12HourString(l.to_hour,
              l.to_min)}}</span>
            <span class="col value1">{{l.lead_time}} min</span>
          </p>

          <p matListItemLine *ngIf="getCustomLeadTimeString(3).length > 0">&nbsp;</p>
          <p matListItemLine *ngIf="getCustomLeadTimeString(3).length > 0">Delivery</p>
          <p matListItemLine class="row" *ngFor="let l of getCustomLeadTimeString(3)">
            <span class="col key1">{{days[l.day]}}</span>
            <span class="col value2">{{get12HourString(l.from_hour, l.from_min)}} — {{get12HourString(l.to_hour,
              l.to_min)}}</span>
            <span class="col value1">{{l.lead_time}} min</span>
          </p>
        </mat-list-item>

        <mat-list-item (click)="openLastOrderBuffer()">
          <mat-icon matListItemIcon class="!text-blue-500">timer_off</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Last order</p>
          <ng-container *ngFor="let o of occasion | keyvalue">
          <p matListItemLine  class="row" *ngIf="store.last_order_buffer[o.value.toUpperCase()] > 0">
            <span class="col key">{{o.value | titlecase}}</span>
            <span class="col value">{{store.last_order_buffer[o.value.toUpperCase()]}} min</span>
          </p>
          </ng-container>

        </mat-list-item>

        <mat-list-item (click)="edit(['service_charge', 'delivery_charge', 'delivery_minimum'])">
          <mat-icon matListItemIcon class="!text-blue-500">attach_money</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Charges</p>
          <p matListItemLine class="row">
            <span class="col key">Delivery charge</span>
            <span class="col value">{{store.delivery_charge | currency: 'USD'}}</span>
          </p>
          <p matListItemLine class="col-span-2">
            <span class="col key">Delivery minimum</span>
            <span class="col value">{{store.delivery_minimum | currency: 'USD'}}</span>
          </p>
        </mat-list-item>

        <mat-list-item *ngIf="store.has_order_occasion_tableside || store.has_order_occasion_dine_in" (click)="openTableside()">
          <mat-icon matListItemIcon class="!text-blue-500">deck</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Tableside</p>
          <p matListItemLine class="row" *ngFor="let table of tables">{{table}}</p>
        </mat-list-item>

        <mat-list-item (click)="openSalesTax()">
          <mat-icon matListItemIcon class="!text-blue-500">title</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Sales Tax</p>
          <p matListItemLine class="row">
            <span class="col key">Local Sales Tax</span>
            <span class="col value">{{ store.local_tax_percent }}</span>
          </p>
          <p matListItemLine class="row">
            <span class="col key">State Sales Tax</span>
            <span class="col value">{{store.state_tax_percent}}</span>
          </p>
        </mat-list-item>

        <mat-list-item (click)="openEmployeeTax()">
          <mat-icon matListItemIcon class="!text-blue-500">title</mat-icon>
          <p matListItemTitle class="mt-1 mb-1">Employee Tax</p>
          <p matListItemTitle class="row">
            <span>
              <span class="col key">State Tax</span>
              <span class="col value">{{this.store?.store_data?.employee_state_tax_percent}}</span>
            </span>
          </p>
          <p matListItemTitle class="row">
            <span>
              <span class="col key">Federal Tax</span>
              <span class="col value">{{this.store?.store_data?.employee_federal_tax_percent}}</span>
            </span>
          </p>
        </mat-list-item>

      </mat-action-list>
    </div>
  </div>
</form>
