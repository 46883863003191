<div class="flex flex-row justify-between mb-2">
  <p>{{orderWithPayment?.bot_order.order_name || 'No Name'}}</p>
  <p>{{orderWithPayment?.bot_order.phone_number | phone}}</p>
</div>

<div class="order-name">
  <p>{{ getOccasionDetails(orderWithPayment?.bot_order) }}</p>
</div>

<span *ngIf="splitItems == undefined">
  <table mat-table [dataSource]="orderWithPayment?.items" cellspacing="10" cellpadding="10" class="other_show dark-bg-color">
    <ng-container matColumnDef="quantity">
      <td mat-cell *matCellDef="let item" class="!pb-[0px] !pt-[15px]">
        {{item.quantity}}
      </td>
    </ng-container>

    <ng-container matColumnDef="item">
      <td mat-cell *matCellDef="let item" class="!pb-[0px] !pt-[15px] dark-bg-color">
        <div class="flex flex-row w-full">
          <div class="align-left w-4/5 mr-2">
            <b mat-line><span *ngIf="item.menu_item.portion != 'Standard' ">{{item.menu_item.portion}}&nbsp;</span>{{item.menu_item.display_name}} <span *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view'] && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">({{item.sale_price | currency: 'USD'}})</span></b>
          </div>
          <div class="align-right w-1/5">
            <b mat-line>
              <span *ngIf="!item.sale_price">
                <span>{{item?.getItemTotal() | currency:'USD' }}</span>
              </span>
              <span *ngIf="+item.sale_price >= 0">
                <span *ngIf="orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item.sale_price | currency:'USD'}}</span>
                <span *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item.sale_price * item.quantity | currency:'USD'}}</span>
              </span>
            </b>
          </div>
        </div>
        <div class="modifiers">
          <p *ngIf="item.special_request">"{{item.special_request}}"</p>
          <div *ngFor="let mod of item.modifiers">
            <div *ngIf="mod.modifier; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
              <div *ngIf="mod.modifier" class="flex flex-row">
                <p *ngIf="orderWithPayment.store['feature_flag']['old_item_price_template_view']">
                  <span *ngIf="mod.mod_type != 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }} {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                  <span *ngIf="mod.mod_type != 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }} {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                  <span *ngIf="mod.mod_type === 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">{{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                  <span *ngIf="mod.mod_type === 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">{{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                  <span *ngIf="mod.mod_type.toLowerCase() !='no'">
                    <span>({{mod.sale_price | currency:'USD'}})</span>
                  </span>
                </p>
                <div *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']" class="flex flex-row w-full">
                  <p class="w-4/5 !my-0.5 !ml-3">
                    <span *ngIf="mod.mod_type != 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }} {{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                    <span *ngIf="mod.mod_type != 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }} {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                    <span *ngIf="mod.mod_type === 'ADD' && orderWithPayment.store.feature_flag.display_single_item_1x">{{ mod.quantity > 0 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                    <span *ngIf="mod.mod_type === 'ADD' && !orderWithPayment.store.feature_flag.display_single_item_1x">{{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod?.mod_tag}} {{mod.modifier.name}}</span>
                    <span *ngIf="mod.mod_type.toLowerCase() !='no' && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">
                      <span>({{mod.sale_price | currency:'USD'}})</span>
                    </span>
                  </p>
                  <p class="w-1/5 !my-0.5">
                    <span *ngIf="mod.mod_type.toLowerCase() !='no'" class="float-right">
                      <span *ngIf="orderWithPayment.store.show_menu_price">
                        <span *ngIf="!mod.fixed_price"> {{ mod?.sale_price * mod?.quantity * item?.quantity | currency:'USD' }}</span>
                        <span *ngIf="mod.fixed_price"> {{ mod?.sale_price * item?.quantity | currency:'USD' }}</span>
                      </span>
                      <span *ngIf="!orderWithPayment.store.show_menu_price">
                        <span *ngIf="uiSetting?.hide_not_applicable_price; else applicablePrice"> </span>
                      </span>
                    </span>
                  </p>
                </div>
                <!-- <p *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']">
                </p> -->
              </div>
            </ng-template>
            <ng-template #applicablePrice>
              <span>(N/A)</span>
            </ng-template>
            <ng-template #elseBlock>
              <i class="non-match">
                <p><span *ngIf="mod.mod_type != 'ADD'">{{ pizzaOptions.get(mod.mod_type) }}</span> {{mod.modifier_raw}}</p>
              </i>
            </ng-template>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="itemTotal">
      <td mat-cell *matCellDef="let item" class="align-right">
        <span *ngIf="!item.sale_price">
          <span>{{item?.getItemTotal() | currency:'USD' }}</span>
        </span>

        <span *ngIf="+item.sale_price >= 0">
          <span>{{item.sale_price | currency:'USD'}}</span>
        </span>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="delete">
      <td mat-cell *matCellDef="let item">
        <button mat-icon-button>
          <mat-icon aria-label="">clear</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr class="even:bg-gray-50 odd:bg-white border-b-[1px] border-gray-300" mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{' border-t-[1px] border-gray-300 ': orderWithPayment.items.indexOf(row) === 0}"></tr>
  </table>

  <table mat-table [dataSource]="orderWithPayment?.misc_items" cellspacing="" cellpadding="10"
    style="max-width: 270px;">
    <ng-container matColumnDef="quantity">
      <td mat-cell *matCellDef="let item" class="!align-middle dark-bg-color">
        {{item.quantity}}
      </td>
    </ng-container>

    <ng-container matColumnDef="item">
      <td mat-cell *matCellDef="let item" class="!align-middle dark-bg-color">
        <div class="flex flex-row w-full">
          <div class="w-4/5 mr-2">
            <span *ngIf="!item.is_gift_card"><b mat-line><span>{{item.item_name}}</span> <span *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view'] && !orderWithPayment.store['feature_flag']['hide_item_mod_price']">({{item?.price | currency:'USD' }})</span></b></span>
            <span *ngIf="item.is_gift_card"><b mat-line><span>Gift Card **{{ item.item_name.slice(-4) }}</span></b></span>
          </div>
          <div class="align-right w-1/5">
            <b mat-line>
                <span *ngIf="orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item?.price | currency:'USD'}}</span>
                <span *ngIf="!orderWithPayment.store['feature_flag']['old_item_price_template_view']">{{item?.price * item.quantity | currency:'USD'}}</span>
            </b>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="itemTotal">
      <td mat-cell *matCellDef="let item" class="align-right w-[27%]">{{item?.price | currency:'USD' }} </td>
    </ng-container> -->
    <tr mat-row *matRowDef="let row; columns: displayedMiscColumns;" class="border-b-[1px] border-gray-300"></tr>
  </table>
</span>

<span *ngIf="splitItems">
  <table mat-table [dataSource]="splitItems" cellspacing="10" cellpadding="10" class="other_show">
    <ng-container matColumnDef="item">
      <td mat-cell *matCellDef="let item" class="dark-bg-color">
        <b mat-line> <span>{{item.item_name}}</span></b>
        <div class="modifiers">
          <p *ngIf="item.special_request">"{{item.special_request}}"</p>
          <div *ngFor="let mod of item.modifiers">
            <div *ngIf="mod; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
              <div *ngIf="mod">
                <p>
                  <span *ngIf="mod">{{mod}}</span>
                </p>
              </div>
            </ng-template>
            <ng-template #applicablePrice>
              <span>(N/A)</span>
            </ng-template>
            <ng-template #elseBlock>
              <i class="non-match">
                <p><span *ngIf="mod">{{mod}}</span></p>
              </i>
            </ng-template>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="itemTotal">
      <td mat-cell *matCellDef="let item" class="align-right w-[27%] dark-bg-color">
        <span *ngIf="+item.amount >= 0">
          <span>{{item.amount | currency:'USD'}}</span>
        </span>
      </td>
    </ng-container>
    <tr class="even:bg-gray-50 odd:bg-white" mat-row *matRowDef="let row; columns: displayedSplitItemColumns;"></tr>
  </table>
</span>