<span>
  <div class="add-media-button">
    <button mat-stroked-button class="media action-btn-colr" (click)="addVideo()"> Add Video</button>
  </div>
</span>
<table mat-table [dataSource]="dataSource" width="100%" class="screenTable">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="row">
    <th mat-header-cell *matHeaderCellDef> Row </th>
    <td mat-cell *matCellDef="let element"> {{element.row}} </td>
  </ng-container>

  <ng-container matColumnDef="video">
    <th mat-header-cell *matHeaderCellDef> Video </th>
    <td mat-cell *matCellDef="let element">
      <a href="{{element.video}}" target="_blank"
        style="color:#4088e5;text-decoration:none">
        <!-- <img src="{{element.image}}"
          style="height:30px;width:30px;vertical-align: bottom;" />  -->
          <span style="vertical-align:super;"> Open
          File</span>
        </a></td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menuScreen="matMenu">
        <button mat-menu-item (click)="editVideo(element)">
          <span class="material-icons-outlined edit-delete-icon">
            edit
          </span>&nbsp;
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="deleteVideo(element)">
          <span class="material-icons edit-delete-icon">
            delete_outline
          </span>&nbsp;
          <span>Delete</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]">
</mat-paginator>