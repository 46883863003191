import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export class Counter{
  key: string;
  value: number;
  constructor(key:string, value:number){
    this.key = key;
    this.value = value;
  }
}

@Injectable()
export class CounterService{

  private source = new BehaviorSubject<Counter>(null);
  current = this.source.asObservable();

  constructor(){}

  emit(counter: Counter) {
    this.source.next(counter);
  }

}
