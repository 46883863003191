<span *ngIf="isSearch">
  <div [ngStyle]="{'max-height': maxHeightItem + 'px'}"
    class="order-item-grid grid grid-cols-5 xs:grid-cols-3 gap-1 overflow-y-auto overflow-x-hidden xs:!m-1 xs:!h-auto 2xl:h-auto place-content-start">
    <button class="border border-gray-300 bg-white h-24 xs:!h-20 text-lg xs:!text-xs xs:rounded-md min-h-20 hover:bg-gray-100
    text-ellipsis p-2 drop-shadow-md dark-bg-color"
      [ngClass]="{ selected: selected === item.id, 'disabled-cta': !validUserData?.sessionId }"
      *ngFor="let item of items" (mousedown)="onMouseDon(item)" (mouseup)="onMoseUp()" (mouseleave)="onMouseLeave()"
      (touchstart)="onMouseDon(item)" (touchend)="onMoseUp()" (click)="onSelectHandler.next(item)">
      <span>{{ item.name }}</span>
      <span *ngIf="item.starting_price">({{ item.starting_price | currency }})</span>
    </button>
  </div>
</span>

<span *ngIf="!isSearch">
  <div *ngIf="storeDynamivHeightFeatureFlag; then dynamicItemHeight else notDynamicItemHeight"></div>
  <ng-template #dynamicItemHeight>
    <div [ngStyle]="{'max-height': maxHeightItem + 'px'}" class="order-item-grid grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3  
    gap-1 overflow-x-hidden overflow-y-hidden xs:!m-1 place-content-start" #swipeContainer>

      <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md hover:bg-gray-100
    text-ellipsis p-2 drop-shadow-md dark-bg-color"
        [ngClass]="{ selected: selected === item.id, 'disabled-cta': !validUserData?.sessionId }"
        *ngFor="let item of getPaginatedCategories()" (mousedown)="onMouseDon(item)" (mouseup)="onMoseUp()"
        (mouseleave)="onMouseLeave()" (touchstart)="onMouseDon(item)" (touchend)="onMoseUp()"
        (click)="onSelectHandler.next(item)" [style.height.px]="buttonHeight">
        <span>{{ item.name }}</span><br>
        <span *ngIf="item.starting_price">({{ item.starting_price | currency }})</span>
      </button>

    </div>
    <div class="flex justify-center mt-3 space-x-2">
      <label *ngFor="let page of totalPagesArray; let i = index">
        <input type="radio" name="pagination" [checked]="i === currentPage" (change)="goToPage(i)" class="appearance-none w-3 h-3 rounded-full border border-gray-500 
                 cursor-pointer checked:bg-black checked:border-black">
      </label>
    </div>
  </ng-template>
  <ng-template #notDynamicItemHeight>
    <div [ngStyle]="{'max-height': maxHeightItem + 'px'}"
      class="order-item-grid grid grid-cols-5 xs:grid-cols-3 gap-1 overflow-y-auto overflow-x-hidden xs:!m-1 xs:!h-auto 2xl:h-auto place-content-start">
      <button class="border border-gray-300 bg-white h-24 xs:!h-20 text-lg xs:!text-xs xs:rounded-md min-h-20 hover:bg-gray-100
      text-ellipsis p-2 drop-shadow-md dark-bg-color"
        [ngClass]="{ selected: selected === item.id, 'disabled-cta': !validUserData?.sessionId }"
        *ngFor="let item of items" (mousedown)="onMouseDon(item)" (mouseup)="onMoseUp()" (mouseleave)="onMouseLeave()"
        (touchstart)="onMouseDon(item)" (touchend)="onMoseUp()" (click)="onSelectHandler.next(item)">
        <span>{{ item.name }}</span>
        <span *ngIf="item.starting_price">({{ item.starting_price | currency }})</span>
      </button>
    </div>
  </ng-template>
</span>