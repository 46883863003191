import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm: UntypedFormGroup;
  error = '';
  submitted:boolean;
  loading:boolean;

  constructor(private formBuilder: UntypedFormBuilder, private authService:AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
  });

  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    if (this.loginForm.invalid) {
        return;
    }
    this.loading = true;
    this.authService.resetPassword(this.f.email.value).pipe(first()).subscribe(data => {
      this.submitted = true
    });
  }

}
