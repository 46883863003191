import {TimeClock, TimeClocks} from '../_models/time-clock';
import {User} from '../_models/user';
import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {UserService} from '../_services/user.service';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';


export class TimeClockDataSource implements DataSource<User> {

  private timeClockSubject = new BehaviorSubject<TimeClock[]>([]);
  private userSubject = new BehaviorSubject<User[]>([]);
  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private userService: UserService) {}

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
      return this.userSubject.asObservable();
  }

  getTotal(){
    return this.totalSubject.getValue();
  }

  getIds(){
    return this.timeClockSubject.getValue().map(user => user.id);
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.userSubject.complete();
      this.loadingSubject.complete();
  }

  loadTotalClocks(storeId:number, filter = '',
  sortField='id', sortOrder='desc',
  pageNumber=0, pageSize=10, start_date, end_date) {
      this.loadingSubject.next(true);
      this.userService.getAllTimeRecords(storeId, filter, sortField, sortOrder, pageNumber, pageSize, start_date, end_date).pipe(
          catchError(() => of(new TimeClocks())),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(users => {
        this.userSubject.next(users);
        this.totalSubject.next(users.total);
      });
  }
}
