import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {User} from '../_models/user';
import {environment} from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, rememberMe:boolean) {
      const mqttSession = localStorage.getItem('mqttsession');
      localStorage.clear();
      if(mqttSession){
        localStorage.setItem('mqttsession', mqttSession);
      } else {
        localStorage.setItem('mqttsession', Date.now().toString());
      }


      return this.http.post<any>(`${environment.apiUrl}/backend/api/token/`, { username, password })
      .pipe(map(response => {
          // login successful if there's a jwt token in the response
          if (response && response.access) {
            const token_parts = response.access.split(/\./);
            const token_decoded = JSON.parse(window.atob(token_parts[1]));
            const user = new User(token_decoded.id, token_decoded.username,
              token_decoded.first_name, token_decoded.last_name, token_decoded.last_login, response.access)
            localStorage.setItem('currentUser', JSON.stringify(user))
            localStorage.setItem('remember',rememberMe.toString())
            this.currentUserSubject.next(user);
          }
          return response;
      }));
    }

    logout() {
        // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('remember')
      this.currentUserSubject.next(null);
    }


    resetPassword(email:string){
      return this.http.post<any>(`${environment.apiUrl}/backend/rest-auth/password/reset/`, {email})
    }

    resetPasswordConfirm(uid:string, token:string, new_password1:string, new_password2:string){
      return this.http.post<any>(`${environment.apiUrl}/backend/rest-auth/password/reset/confirm/`, {uid, token, new_password1, new_password2})
    }

    changePassword(new_password1, new_password2){
      return this.http.post<any>(`${environment.apiUrl}/backend/rest-auth/password/change/`, {new_password1, new_password2})
    }

    isFirstLogin(){
      return this.http.get<any>(`${environment.apiUrl}/backend/rest-auth/user/`)
    }
}
