import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormBase} from './dynamic-form-control/form-base';


@Injectable()
export class DynamicFormService {
  constructor() { }

  toFormGroup(formControls: FormBase<any>[] ) {
    const group: any = {};

    formControls.forEach(formControl => {

      if (formControl instanceof Array) {

        (formControl as Array<any>).forEach(control => {
          group[control.id] = control.required ? new UntypedFormControl(control.value || '', Validators.required)
            : new UntypedFormControl(control.value || '');
        });
      } else {
        group[formControl.key] = formControl.required ? new UntypedFormControl(formControl.value || '', Validators.required)
          : new UntypedFormControl(formControl.value || '');
      }

    });
    return new UntypedFormGroup(group);
  }
}
