import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import { SwUpdate } from '@angular/service-worker';
import { PwaUpdateService } from './_services/pwa-update.service';
import { PwaSessionService } from './_services/pwa-session.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private sessionSubscription: Subscription;
  constructor(public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private swUpdate: SwUpdate,
    private updateService: PwaUpdateService,
    private sessionService: PwaSessionService){

      this.matIconRegistry.addSvgIcon('campaign', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/megaphone.svg'));
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
  }

  title = 'order-api-business';

  ngOnInit() {
    this.updateService;

    // Periodically refresh the session
    this.sessionSubscription = interval(15 * 60 * 1000).subscribe(() => {
      this.sessionService.refreshSession();
    });
  }
  

  ngOnDestroy() {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }
  }

}
