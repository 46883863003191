
<div class="flex flex-row justify-between">
  <p class="p-2">Every dollar spends equals a point earned! Manage Tiers and Rewards Below:</p>
  <button *ngIf="pointsList.length == 0"
          class="bg-lime-600 text-white py-2 px-5 rounded-md"
          (click)="noTierRow()">Add Tier</button>
</div>
<div class="flex flex-col" *ngIf="pointsList.length != 0">
  <div class="grid grid-cols-7 gap-3 items-center mb-3" *ngFor="let point of pointsList">
    <mat-form-field appearance="fill" class="w-full" subscriptSizing="dynamic">
      <input type="number" min="0" id="username" name="username" #username matInput value="{{point.points}}"
             oninput="validity.valid||(value='')">
    </mat-form-field>

    <div class="lab-text-blue">Points Unlocks</div>

    <mat-form-field appearance="fill" class="w-full" subscriptSizing="dynamic">
      <input type="number" min="0" oninput="validity.valid||(value='')" id="username2" name="username2" #username2 matInput value="{{point.card_value}}">
    </mat-form-field>

    <div class="lab-text-blue">Dollar Gift Card</div>
<!-- <div class="d-set"> -->
    <button class=" apply-btn-colr lab-bg-blue text-white py-2 px-5 rounded-md h-10 btn-w xs:w-full sm:w-full"
            (click)="updatePointValue(username.value, username2.value, point)">Save</button>

    <button class="bg-lime-600 text-white py-2 px-5 rounded-md h-10 btn-w xs:w-full sm:w-full" (click)="addTierRow(point)">Add Tier</button>

    <button class="bg-red-600 text-white py-2 px-5 rounded-md h-10 btn-w xs:w-full sm:w-full" (click)="deleteTierRow(point)">Delete</button>
  <!-- </div> -->
  </div>
</div>
