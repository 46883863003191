import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cash-drawer-confirm',
  templateUrl: './cash-drawer-confirm.component.html',
  styleUrls: ['./cash-drawer-confirm.component.scss']
})
export class CashDrawerConfirmComponent {

  reason: string;
  placeHolderText: string = 'What is the reason for the Cash Pay';
  showData: any;
  constructor(
    public dialogRef: MatDialogRef<CashDrawerConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.showData = data;
    
  }

  ngOnInit() {
    if (this.showData.key == 'pay_cash_in') {
      this.placeHolderText = this.placeHolderText + ' IN';
    } else if (this.showData.key == 'pay_cash_out') {
      this.placeHolderText = this.placeHolderText + ' OUT';
    } else if (this.showData.key == 'end_drawer') {
      if (this.data.amount > 0) {
        this.placeHolderText = 'What is the reason for positive discrepency?';
      } else {
        this.placeHolderText = 'What is the reason for negative discrepency?';
      }
    }
  }

  cancel() {
    const data = {
      'key': false,
      'reason': ''
    }
    this.dialogRef.close(false);
  }

  confirm() {
    const data = {
      'key': true,
      'reason': this.reason ? this.reason : 'NA'
    }
    this.dialogRef.close(data);
  }
}


