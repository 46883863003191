import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {AttendantSettingsService} from '../_services/attendant-settings.service';
import {AttendantActions} from '../_models/attendant_action';

export class AttendantActionDataSource implements DataSource<AttendantActions> {

  private redeemHistorySubject = new BehaviorSubject<AttendantActions[]>([]);
  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private IVRSetting: AttendantSettingsService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<AttendantActions[]> {
      return this.redeemHistorySubject.asObservable();
  }

  getTotal(){
    return this.totalSubject.getValue();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.redeemHistorySubject.complete();
      this.loadingSubject.complete();
  }

  loadAttendantActions(storeId: string) {
      this.loadingSubject.next(true);
      this.IVRSetting.getIvrActions(storeId).pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(data => {
       this.redeemHistorySubject.next(data.sort((a,b) => b.greeting_type < a.greeting_type ? -1 : 1));
      });
  }

}
