import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from 'src/app/_services/store.service';

@Component({
  selector: 'app-dashboard-sales-tax',
  templateUrl: './dashboard-sales-tax.component.html',
  styleUrls: ['./dashboard-sales-tax.component.scss']
})
export class DashboardSalesTaxComponent implements OnInit {
  taxaes: UntypedFormGroup;
  idStore: any;
  salesTax: any;
  localeTax: any;
  stateSalesTax: any;
  localeSalesTax: any;
  constructor(private fb: UntypedFormBuilder,
    public storeService: StoreService,
    public dialogRef: MatDialogRef<DashboardSalesTaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.stateSalesTax = this.data.store.state_tax_percent;
    this.localeSalesTax = this.data.store.local_tax_percent;
    this.idStore = localStorage.getItem('currentStore')
    this.taxaes = this.fb.group({
      state_tax_percent: this.stateSalesTax,
      local_tax_percent: this.localeSalesTax
    });
  }

  editStateLocalTax() {
    this.salesTax = this.taxaes.value.state_tax_percent;
    this.localeTax = this.taxaes.value.local_tax_percent;
    const keySend = {
      state_tax_percent: this.salesTax,
      local_tax_percent: this.localeTax
    }
    this.storeService.updateSalesTax(this.idStore, keySend).subscribe((response: any) => {
      this.dialogRef.close(keySend);
    })
  }

}
