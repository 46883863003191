import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './_guards/auth.gaurd';
import {LoginLayoutComponent} from './login-layout/login-layout.component';
import {HomeLayoutComponent} from './home-layout/home-layout.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {PasswordRestConfirmComponent} from './login/password-rest-confirm/password-rest-confirm.component';
import {ReportDialogComponent} from './dashboard/report/report-dialog/report-dialog.component';
import {StoreMenuComponent} from './info/store-menu/store-menu.component';
import {SkillComponent} from './info/skill/skill.component';
import {TvScreenPreviewComponent} from './digital-display/column-management/tv-screen-preview/tv-screen-preview.component';
import {DigitalScreensComponent} from './digital-display/digital-screens/digital-screens.component';
import {DigitalDisplayComponent} from './digital-display/digital-display.component';
import {SettlementsComponent} from './settlements/settlements.component';
import { ReferralComponent } from './referral/referral.component';
import { FeedbackComponent } from './feedback/feedback.component';

const routes: Routes = [
  { path: 'login', component: LoginLayoutComponent,
  children:[
    { path: '', component: LoginComponent },
    { path: 'password-reset', component: ForgotPasswordComponent },
    { path: 'password-reset/:uid/:token', component: PasswordRestConfirmComponent }
  ]},
  // {path: 'monthly/:store_id/:month/:year', component: ReportDialogComponent},
  // { path: 'digital-display-screen/:storeId/:tv_id/:type', component: DigitalScreensComponent },
  // { path: 'tv-management-screen/:storeId/:id/:type', component: TvScreenPreviewComponent },
  {
    path: 'home', component: HomeLayoutComponent,

    children:[
      { path: 'info', loadChildren: () => import('./info/info.module').then(m => m.InfoModule) },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)},
      { path: 'menu', loadChildren: () => import('./store-menu/menu.module').then(m => m.StoreMenuModule)},
      { path: 'offers', loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule) },
      { path: 'insights', loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule) },
      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
      { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
      { path: 'messages', loadChildren: () => import('./message/message.module').then(m => m.MessageModule) },
      { path: 'campaign', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'attendant', loadChildren: () => import('./attendant/attendant.module').then(m => m.AttendantModule) },
      { path: 'loyalty', loadChildren: () => import('./loyalty/loyalty.module').then(m => m.LoyaltyModule) },
      { path: 'offers-v2', loadChildren: () => import('./offers-v2/offers-v2.module').then(m => m.OffersV2Module) },
      { path: 'time-clock', loadChildren: () => import('./time-clock/time-clock.module').then(m => m.TimeClockModule) },
      { path: 'pos-settings', loadChildren: () => import('./pos-settings/pos-settings.module').then(m => m.PosSettingsModule) },
      { path: 'digital-display', loadChildren: () => import('./digital-display/digital-display.module').then(m => m.DigitalDisplayModule) },
      { path: 'settlements', loadChildren: () => import('./settlements/settlements.module').then(m => m.SettlementsModule) },
      { path: 'referral', loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule) },
      { path: 'skill', component: SkillComponent},
      { path: 'floor-plan', loadChildren: () => import('./floor-plan/floor-plan.module').then(m => m.FloorPlanModule) },
      { path: 'feedback', loadChildren: () => import ('./feedback/feedback.module').then(m => m.FeedbackModule)},
    ]
  },
  { path: '**', redirectTo: 'login' }
];

export const routing = RouterModule.forRoot(routes, {});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    BrowserAnimationsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
