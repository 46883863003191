
<form [formGroup]="adjustForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Adjust Order Price</h1>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div mat-dialog-content>
      <span style="color: #666666">Make price increase adjustments to the order for special requests here.</span>
      <div style="clear: both; margin-top: 10px;"></div>
      <div class="left-sec">
          <app-receipt [orderWithPayment]="orderWithPayment" [onlyItems]="true"></app-receipt>
      </div>
      <div class="right-sec">
        <div class="center-box box-height">
          <div class="form-group item-pad">
            <mat-form-field appearance="outline" class="tip-field">
              <!-- <span style="color:gray;float: left;" matPrefix>$ &nbsp;</span> -->
              <mat-label>Increase Amount</mat-label>
              <input type="text" formControlName="newSubTotal" class="form-control" [ngClass]="{ 'is-invalid': submitted }" matInput
                      placeholder="$ Enter increase value" autofocus required="true">
            </mat-form-field>
          </div>
          <label class="primary-color">Enter increase value above. Taxes will be automatically applied accordingly</label>
          <div *ngIf="error" class="alert alert-danger item-pad">Invalid adjustment request</div>
        </div>
      </div>
  </div>
  <mat-dialog-actions class="btn-align">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" type="submit">Submit</button>
  </mat-dialog-actions>
</form>
