<form class="master-cat-from" name="editMasterCategoryForm" [formGroup]="editMasterCategoryForm" (ngSubmit)="editMasterCategoryFormSubmit()" class="fixActionRow">
  <h1 mat-dialog-title>Edit Master Category</h1>
  <div mat-dialog-content style="overflow: hidden;">
    <div fxLayout="row" class="!mt-4" style="width: 100%;">
      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>
      </div>
      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Priority</mat-label>
          <input matInput placeholder="" type="number" formControlName="priority">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" class="!mt-4" style="width: 100%;">
      <!-- <div class="input-gap" style="width: 50%;">
        <mat-form-field style="width: 100%;">
          <mat-label>Categories</mat-label>
          <mat-select formControlName="categorys" multiple>
            <mat-option *ngFor="let topping of category_list_data" [value]="topping.id">
              {{topping.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->



      <div class="input-gap" style="width: 50%;">
        <mat-form-field style="width: 100%;">
          <mat-label>Categories</mat-label>

          <mat-select formControlName="categorys">
            <div class="search-filter-input" style="font-size: 15px;">
              <mat-form-field fxFlex="100" style="padding: 0px 5px 10px 5px;">
                <mat-label>Search For Categories</mat-label>
                <input matInput [formControl]="searchControl" placeholder="Search">
                <mat-icon style="padding: 2px !important;" class="admin-icons" matPrefix>search</mat-icon>
              </mat-form-field>
            </div>
            <mat-option (click)="toggleAllSelection1()" [value]="null">
              {{ allSelected ? 'Deselect All' : 'Select All' }}
            </mat-option>
            <mat-option *ngFor="let topping of category_list_data | filter: searchControl.value" [value]="topping.id">
              <!-- {{topping.display_name}} -->
              <mat-checkbox [checked]="categorys.value.includes(topping.id)" (change)="toggleSelection1(topping.id)">{{
                topping.name }}</mat-checkbox>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>



      <div class="input-gap" style="width: 50%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Font-color</mat-label>
          <input matInput placeholder="" type="text" formControlName="font_color">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" class="!mt-4 xs:!block" style="width: 100%;">
      <div class="input-gap" style="width: 100%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Emoji</mat-label>
          <mat-select formControlName="emoji">
            <div class="search-filter-input" style="font-size: 15px;">
              <mat-form-field fxFlex="100" style="padding: 0px 10px 10px 10px;">
                <mat-label>Search For Emoji</mat-label>
                <input matInput [formControl]="searchControl" placeholder="Search">
                <mat-icon class="admin-icons" matPrefix>search</mat-icon>
              </mat-form-field>
            </div>
            <mat-option *ngFor="let state of emojiLists | filter: searchControl.value" [value]="state.value">
              <img style="float: left; margin-right: 10px; width: 30px; height: 30px; max-width: 30px;" class="example-option-img" aria-hidden [src]="state.value" height="15">
              <span style="line-height: 35px;">{{state.name}}</span>
            </mat-option>
          </mat-select>
          <!-- <input matInput aria-label="Emoji" [matAutocomplete]="auto" formControlName="emoji">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let state of emojiLists" [value]="state.value">
              <img class="example-option-img" aria-hidden [src]="state.value" height="25">
              <span>{{state.name}}</span>
            </mat-option>
          </mat-autocomplete> -->
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" class="!mt-4" style="width: 100%;">
      <div class="input-gap" style="width: 50%;">
        <mat-checkbox (change)="newScreenCheck($event)" [checked]="newScreen">New screen</mat-checkbox>
      </div>
      <div class="input-gap" style="width: 50%;">
        <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide category</mat-checkbox>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
  </mat-dialog-actions>
</form>