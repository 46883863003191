import { Component, OnInit } from '@angular/core';
import { LoyaltyPointsService } from '../_services/loyalty-points.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  storeId: any;
  constructor() { }
  ngOnInit() {
  }

}
