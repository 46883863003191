import {FormBase} from './form-base';

export class FormTextArea extends FormBase<string> {
  controlType = 'textarea';
  type: string;

  spellcheck = false
  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
