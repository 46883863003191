import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-update-default-menu-dialog',
  templateUrl: './update-default-menu-dialog.component.html',
  styleUrls: ['./update-default-menu-dialog.component.scss']
})
export class UpdateDefaultMenuDialogComponent {
  getID: any;
  deleteID: any;
  submitted = false;
  isLoading = false;
  editMasterCategoryForm: UntypedFormGroup;
  getId: any;
  newScreen: boolean;
  hideCategory: boolean;
  storeId: any;
  category_list_data: any;
  searchEmoji;
  // categorys = new FormControl('');
  showSelete: any = [];
  showModifierGroupSelete: any = [];
  emojiLists: any;
  subcategory_list_data: any;
  searchModifierGroup:any;
  searchSubCategory:any;

  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<UpdateDefaultMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('id get 111111111111', data);

  }

  ngOnInit() {
    this.getID = this.data.category;
    this.deleteID = this.data.id;


    this.storeId = localStorage.getItem('currentStore');
    // this.categoryList();
    this.subcategoryListAll();
    this.ModifierGroupListAll();

    this.cat()
    this.ModifierGroup()
    this.newScreen = this.data.new_screen;
    this.hideCategory = this.data.hide_mastercategory;
    this.editMasterCategoryForm = this.fb.group({
      name: [this.data.name],
      priority: [this.data.priority],
      new_screen: [this.data.new_screen],
      hide_mastercategory: [this.hideCategory],
      subcategorys: [this.showSelete],
      font_color: [this.data.font_color],
      emoji: [this.data.emoji],
      modifiergroups: [this.showModifierGroupSelete],

      store: [this.storeId],
      emoji_search: [],
      modifierGroup_search:[],
      SubCategory_search:[],
      font_size: [this.data.font_size],
      alignment: [this.data.alignment],
      modifiergroups_subcat_alignment: [this.data.modifiergroups_subcat_alignment],
      modifiergroups_subcat_font_size: [this.data.modifiergroups_subcat_font_size],
      modifiergroups_subcat_font_color: [this.data.modifiergroups_subcat_font_color],
      modifiergroups_subcat_background_color: [this.data.modifiergroups_subcat_background_color]
    })
    this.emojiList();

  }
  onNoClick() {
    this.dialogRef.close();
  }

  categoryDelete() {
    if (this.getID == 1) {
      this.digitalDisplayService.deletedefaultMenuCategorie(this.deleteID).subscribe(data => {
        const resData = { yesSubmit: "YesSubmit" };
        this.dialogRef.close(resData);
      })
    }
  }

  defaultToNewMenuCategory() {
    this.isLoading = true;
    let keySend = {
      menu_cat_id: this.data.id
    }
    this.digitalDisplayService.defaultToNewMenuCategory(keySend).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;
      this.dialogRef.close(resData);
    })
  }

  cat() {

    for (let j = 0; j < this.data.categorys.length; j++) {
      const element = this.data.categorys[j];
      this.showSelete.push(element)
      console.log('sssssssss', this.showSelete);
    }
  }
  ModifierGroup() {

    for (let j = 0; j < this.data.modifiergroups.length; j++) {
      const element = this.data.modifiergroups[j];
      this.showModifierGroupSelete.push(element)
      console.log('sssssssss', this.showModifierGroupSelete);
    }
  }


  subcategoryListAll() {
    this.digitalDisplayService
      .SubCategoriesOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.showSelete = data.data;

      });
  }
  ModifierGroupListAll() {
    this.digitalDisplayService
      .ModifierGroupOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.showModifierGroupSelete = data.data;
        console.log("11111111111", this.showSelete);

      });
  }
  newScreenCheck(a) {
    this.editMasterCategoryForm.value.new_screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.editMasterCategoryForm.value.hide_mastercategory = a.checked;
  }
  editMasterCategoryFormSubmit() {
    this.isLoading = true;
    this.submitted = true;
    if (this.editMasterCategoryForm.invalid) {
      return;
    }
    this.digitalDisplayService.updatedefaultMenuCategorie(this.data.id, this.editMasterCategoryForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;
      this.dialogRef.close(resData);
    })
  }
  // categoryList() {
  //   this.digitalDisplayService.SubCategoriesOtionListMaster(this.storeId).subscribe(data => {
  //     this.category_list_data = data.data
  //   })
  // }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emojiLists = data.data;

    });
  }
}
