import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CsvData} from '../../../_interfaces/csv-data';
import {takeUntil} from 'rxjs/operators';
import {StoreService} from '../../../_services/store.service';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-contact-field-mapping-dialog',
  templateUrl: './contact-field-mapping-dialog.component.html',
  styleUrls: ['./contact-field-mapping-dialog.component.scss']
})
export class ContactFieldMappingDialogComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public headers: string[];
  public importing = false;
  private unsubscribe: Subject<null> = new Subject();
  public customList = false;

  constructor(
    public dialogRef: MatDialogRef<ContactFieldMappingDialogComponent>,
    public storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.headers = [...this.data.csvData.headers];
    this.form = new UntypedFormGroup({
      customListName: new UntypedFormControl(null),
      phoneNumber: new UntypedFormControl(null, Validators.required),
      name: new UntypedFormControl()
    });
  }

  handleSubmit() {
    const {phoneNumber, name} = this.form.value;
    const csvData: CsvData = this.data.csvData;

    if (!phoneNumber) {
      return;
    }

    const nameIndex: number | boolean = name ? csvData.headers.indexOf(name) : false;
    const phoneIndex: number = csvData.headers.indexOf(phoneNumber);

    const contactsData = csvData.data.map((row: string[]) => ({
      name: typeof nameIndex === 'boolean' ? '' : row[nameIndex],
      phone_number: row[phoneIndex],
      store: this.data.store.id
    }));

    this.importing = true;
    if(this.data.customList){
      this.storeService.uploadCustomList({name: this.form.value.customListName,
        contacts: contactsData, store_id: this.data.store.id}).pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.importing = false;
          this.dialogRef.close(true);
        }, err => {
          this.importing = false;
        });
    }else{
      this.storeService.uploadContactsList({contacts: contactsData, store_id: this.data.store.id}).pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.importing = false;
          this.dialogRef.close(true);
        }, err => {
          this.importing = false;
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
