import { Component, ViewChild } from '@angular/core';
import { FeedbacksService } from 'src/app/_services/feedbacks.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StoreService } from 'src/app/_services/store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent {
  storeId: any;
  feedbackList: any = [];
  totalFeedbacks: number;
  displayedColumns = ['id', 'phone_number', 'order_counter', 'rating', 'feedback', 'created_at', 'button'];
  dataSource : any = [];
  search;
  startDate;
  endDate;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;
  formGroup: FormGroup;

  constructor(
    public fdService: FeedbacksService,
    private fb: FormBuilder,
    private storeService: StoreService,
    private snackBar: MatSnackBar,
    private router: Router
  ){
    this.formGroup = this.fb.group({
      send_feedback_message: [false],
      feedback_message: [''],
    });
  }
  
  
  ngOnInit(){
    this.storeId = localStorage.getItem('currentStore');
    this.loadMerchantDetails(this.storeId);
  }

  loadMerchantDetails(storeId) {
    this.storeService.getMerchant(storeId).subscribe((data) => {
      if (data) {
        this.formGroup.patchValue({
          send_feedback_message: data['store'].store_data.send_feedback_message,
          feedback_message: data['store'].store_data.feedback_message
        });
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Subscribing to sort and pagination changes
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.feedbackLists()))
      .subscribe();

    // Initial load
    this.feedbackLists();
  }

  feedbackLists() {
    this.fdService.getFeedbacklist(this.storeId, this.search, this.startDate, this.endDate, this.sort.active,
      this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize).subscribe((data: any) => {
      if (data && data.feedbacks) { // Check if data and feedbacks exist
        this.feedbackList = data;
        this.totalFeedbacks = data.total_feedbacks;
        this.dataSource = new MatTableDataSource(this.feedbackList.feedbacks); // Properly initialize the data source
        console.log('Feedback data:', this.dataSource);
      } else {
        console.error('Invalid feedback data structure:', data);
      }
    })
  }

  onSubmit() {
    this.storeService.updateFeedbackMessageTax(this.storeId, this.formGroup.value).subscribe(
      (data: any) => {
        this.loadMerchantDetails(this.storeId);
        this.snackBar.open('Feedback message updated successfully!', 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      },
      (error) => {
        console.error('Error updating feedback message:', error);
      }
    );
  }

  searchOrders(any:any){
    this.paginator.pageIndex = 0;
    this.feedbackLists();
  }

  goToMessages(row) {
    localStorage.setItem('chat_with', row.phone_number);
    this.router.navigate(['/home/messages']);
  }

  reset(){
    this.search = '';
    this.startDate = null;
    this.endDate = null;
    this.paginator.pageIndex = 0;
    this.feedbackLists();
  }

}
