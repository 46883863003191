
  <h1 mat-dialog-title>Transactions</h1>
  <p>You can find a history of the transaction modification here.</p>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div mat-dialog-content>
    <div *ngIf="botOrder.is_pos && botOrder.source !== 'POS_MANUAL_CREDIT'">
      <div *ngIf="botOrder.payment_type === 'SPLIT'">
        <p>Split Transactions</p>
        <table class="styled-table">
          <tr>
            <th>Time</th>
            <th>Type</th>
            <th>Amount</th>
            <th></th>
          </tr>
          <tr *ngFor="let record of splitTranscations">
            <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
            <td >Split - {{ record.is_tip ? 'TIP' : record.payment_type}}</td>
            <td>{{record.split_amount}}</td>
            <td></td>
          </tr>
        </table>
      </div>
      <div>
        <p>POS Terminal Transactions</p>
        <table class="styled-table">
          <tr>
            <th>Time</th>
            <th>Type</th>
            <th>Amount</th>
            <th></th>
          </tr>
          <tr *ngFor="let record of posTransactions">
            <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
            <td>
              <span *ngIf="record.is_refund">Refund</span>
              <span *ngIf="record.is_adjust">Adjustment</span>
              <span *ngIf="record.is_tip">Tip</span>
              <span *ngIf="record.is_gift">Gift</span>
              <span *ngIf="record.is_other">Other</span>
              <span *ngIf="!record.is_refund && !record.is_adjust && !record.is_tip">{{ record.trans_type | titlecase }}</span>
            </td>
            <td *ngIf="!record.is_tip">{{ record.amount | currency: 'USD' }}</td>
            <td *ngIf="record.is_tip">{{ record.tip_amount | currency: 'USD' }}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <table class="styled-table" *ngIf="store.payment_gateway === 'CARDCONNECT'">
      <tr>
        <th>Time</th>
        <th>Type</th>
        <th>Amount</th>
        <th>Transaction ID</th>
        <th></th>
      </tr>
      <tr *ngFor="let record of auths">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>Authorization</td>
        <td>{{ record.amount | currency: 'USD' }}</td>
        <td>{{ record.retref }}</td>
        <td>
          <!-- <button mat-flat-button disabled>Refund</button> -->
        </td>
      </tr>

      <tr *ngFor="let record of adjusts">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>Adjustment</td>
        <td>{{ record.amount | currency: 'USD' }}</td>
        <td>{{ record.ccresp.retref }}</td>
        <td></td>
      </tr>

      <tr *ngFor="let record of voids">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>Void</td>
        <td>{{ record.authcode }}</td>
        <td>{{ record.retref }}</td>
        <td></td>
      </tr>

      <tr *ngFor="let record of refunds">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>Refund</td>
        <td>{{ record.amount | currency: 'USD' }}</td>
        <td>{{ record.retref }}</td>
        <td></td>
      </tr>

      <tr *ngFor="let record of pendings">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>Refund Pending</td>
        <td>{{ record.amount | currency: 'USD' }}</td>
        <td></td>
        <td></td>
      </tr>
    </table>


    <table class="styled-table" *ngIf="!botOrder.is_pos && store.payment_gateway !== 'CARDCONNECT'">
      <tr>
        <th>Time</th>
        <th>Type</th>
        <th>Amount</th>
        <th>ID</th>
        <th></th>
      </tr>

      <tr *ngFor="let record of transactions">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>
          {{record.is_refund ? 'REFUND' : record.is_adjust ? 'ADJUST' : 'AUTH'}}
        </td>
        <td>{{ record.amount | currency: 'USD' }}</td>
        <td>{{ record.transaction_id }}</td>
        <td>

          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item *ngIf="!record.is_refund && !record.id_adjust"
            (click)="refundTransaction(record)">
              <span>Undo</span>
            </button>
          </mat-menu>
        </td>
      </tr>

      <tr *ngFor="let record of pendingTransactions">
        <td>{{ record.created_at | date: 'hh:mmaaa MM/dd/yyyy'}}</td>
        <td>
          REFUND
        </td>
        <td>{{ record.amount | currency: 'USD' }}</td>
        <td>PENDING</td>
        <td>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item *ngIf="!record.is_refund && !record.id_adjust"
            (click)="cancelRefundTransaction(record)">
              <span>Undo</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </table>
  </div>
  <mat-dialog-actions align="end" >
    <button mat-flat-button color="primary" mat-dialog-close>Close</button>
  </mat-dialog-actions>

