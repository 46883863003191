import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form:UntypedFormGroup;
  error:string;
  submitted:boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService:AuthenticationService,
    private router: Router,
    public dialogRef: MatDialogRef<ChangePasswordComponent>) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

  }

  get f() { return this.form.controls; }

  onSubmit(){
    this.submitted = true;
    this.authService.changePassword(this.f.password.value, this.f.confirmPassword.value).pipe(first())
    .subscribe(
      data => {
        this.dialogRef.close()
        this.router.navigate(['login'])
    },
    error=> {
      console.log(error)
      // this.error = error.value
    })
  }
}
