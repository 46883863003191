import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-add-screen-dialog",
  templateUrl: "./add-screen-dialog.component.html",
  styleUrls: ["./add-screen-dialog.component.scss"],
})
export class AddScreenDialogComponent implements OnInit {
  isLoading = false;
  submitted = false;
  addScreenForm: UntypedFormGroup;
  getId: any;
  storeId: string;
  file: File = null;
  shortLink: string = "";
  emoji: any;
  image_list: any;
  video_list: any;
  header: boolean = false;
  display: boolean = false;
  hide_color: boolean = false;
  decimal_check: boolean = false;
  showHeader: boolean = false;
  hideKeyDisplay: boolean = false;
  hideColorsCheck: boolean = false;
  decimalPointsCheck: boolean = false;
  imageWidth: Array<any> = [];
  storeType: any;
  optionValue: any;
  rowdata: any;
  ImgErrorShow: any = '';
  maxFileSize: number = 30;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<AddScreenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.optionValue = 'MENU';
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");
    this.addScreenForm = this.fb.group({
      store: [this.storeId],
      tv_id: ["", Validators.required],
      content_type: ["MENU", Validators.required],
      canvas: ["1"],
      screen_width: ["", Validators.required],
      screen_height: ["", Validators.required],
      screen_bgcolor: ["#000", Validators.required],
      rows_per_screen: [""],
      rotate: [""],
      refresh_seconds: ["30"],
      padding: ["20"],
      product_font_size: ["20"],
      product_description_size: ["15"],
      product_price_font_size: ["20"],
      price_header_font_size: ["20"],
      product_weight_font_size: ["15"],
      sub_category_font_size: ["30"],
      category_font_size: ["32"],
      master_category_font_size: ["35"],
      master_category_alignment: [""],
      hybrid_color: ["#77aa33"],
      indica_color: ["#7b2d98"],
      header_font_color: ["#ffffff"],
      product_font_color: ["#ffffff"],
      description_font_color: ["#ffffff"],
      price_font_color: ["#ffffff"],
      tag_font_color: ["#ffffff"],
      weight_font_color: ["#ffffff"],
      sativa_color: ["#e98d00"],
      strain_font_size: ["16"],
      strain_type_size: ["25"],

      font_family: [""],
      header_text: [""],
      header_emoji: [""],
      media_file: [""],
      strain_right_margin: ["10"],
      image: [""],
      video: [""],
      hide_key_display: [""],
      show_header: [""],
      hide_tag_colors: [""],
      decimal_point_hide: [""],
      image_width: [""],
    });
    this.fileSizeLimit();
    this.emojiList();
    this.imageList();
    this.videoList();

    for (let i = 1; i <= 100; i++) {
      this.imageWidth.push(i)
    }
  }
  onNoClick() {
    this.dialogRef.close(0);
  }


  addScreenFormSubmit() {

    const validationForm = this.addScreenForm.status;
    if (validationForm == "VALID") {
      const formData = new FormData();
      if (this.storeType == "Leaflogix") {
        formData.append("store_id", this.storeId);
      }
      else {
        formData.append("store", this.storeId);
      }
      formData.append("tv_id", this.addScreenForm.value.tv_id);
      formData.append("content_type", this.addScreenForm.value.content_type);
      formData.append("canvas", this.addScreenForm.value.canvas);
      formData.append("screen_width", this.addScreenForm.value.screen_width);
      formData.append("screen_height", this.addScreenForm.value.screen_height);
      formData.append(
        "screen_bgcolor",
        this.addScreenForm.value.screen_bgcolor
      );
      if (this.addScreenForm.value.rows_per_screen == null) {
        this.rowdata = 10;
        formData.append(
          "rows_per_screen", this.rowdata);
      }
      else {
        formData.append(
          "rows_per_screen",
          this.addScreenForm.value.rows_per_screen
        );
      }


      if (this.addScreenForm.value.rotate == null) {
        this.rowdata = 0;
        formData.append(
          "rotate", this.rowdata);
      }
      else {
        formData.append("rotate", this.addScreenForm.value.rotate);
      }
      formData.append(
        "refresh_seconds",
        this.addScreenForm.value.refresh_seconds
      );
      formData.append("padding", this.addScreenForm.value.padding);
      formData.append(
        "product_font_size",
        this.addScreenForm.value.product_font_size
      );
      formData.append(
        "product_description_size",
        this.addScreenForm.value.product_description_size
      );

      formData.append(
        "product_price_font_size",
        this.addScreenForm.value.product_price_font_size
      );

      formData.append(
        "price_header_font_size",
        this.addScreenForm.value.price_header_font_size
      );

      formData.append(
        "product_weight_font_size",
        this.addScreenForm.value.product_weight_font_size
      );
      formData.append(
        "sub_category_font_size",
        this.addScreenForm.value.sub_category_font_size
      );
      formData.append(
        "category_font_size",
        this.addScreenForm.value.category_font_size
      );
      formData.append(
        "master_category_font_size",
        this.addScreenForm.value.master_category_font_size
      );
      formData.append(
        "master_category_alignment",
        this.addScreenForm.value.master_category_alignment
      );
      formData.append("hybrid_color", this.addScreenForm.value.hybrid_color);
      formData.append("indica_color", this.addScreenForm.value.indica_color);
      formData.append("header_font_color", this.addScreenForm.value.header_font_color);
      formData.append("sativa_color", this.addScreenForm.value.sativa_color);
      formData.append(
        "description_font_color",
        this.addScreenForm.value.description_font_color
      );
      formData.append(
        "price_font_color",
        this.addScreenForm.value.price_font_color
      );
      formData.append(
        "tag_font_color",
        this.addScreenForm.value.tag_font_color
      );
      formData.append(
        "product_font_color",
        this.addScreenForm.value.product_font_color
      );
      formData.append(
        "weight_font_color",
        this.addScreenForm.value.weight_font_color
      );
      formData.append(
        "strain_font_size",
        this.addScreenForm.value.strain_font_size
      );
      formData.append(
        "strain_type_size",
        this.addScreenForm.value.strain_type_size
      );
      formData.append("font_family", this.addScreenForm.value.font_family);
      formData.append("header_text", this.addScreenForm.value.header_text);
      formData.append("header_emoji", this.addScreenForm.value.header_emoji);
      if (this.file != null) {
        formData.append("media_file", this.file, this.file.name);
      }
      formData.append(
        "strain_right_margin",
        this.addScreenForm.value.strain_right_margin
      );
      if (this.addScreenForm.value.image == "") {
        this.addScreenForm.value.image = [];
      } else {
        formData.append("image", this.addScreenForm.value.image);
      }
      formData.append("hide_key_display", `${this.hideKeyDisplay}`);
      formData.append("show_header", `${this.showHeader}`);
      formData.append("hide_tag_colors", `${this.hideColorsCheck}`);
      formData.append("decimal_point_hide", `${this.decimalPointsCheck}`);
      if (this.addScreenForm.value.video == "") {
        this.addScreenForm.value.video = null;
      }
      else {
        formData.append("video", this.addScreenForm.value.video);
      }
      formData.append("image_width", this.addScreenForm.value.image_width);
      this.isLoading = true;
      this.digitalDisplayService
        .addDisplayScreen(formData)
        .subscribe((data) => {
          if (data.msg) {
            console.log('check', data.msg);
            this.ImgErrorShow = data.msg;
            this.isLoading = false;
          }
          else {
            const resData = { yesSubmit: "YesSubmit" };
            this.isLoading = false;
            this.dialogRef.close(resData);
          }
        });
    }
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emoji = data.data;
    });
  }

  fileSizeLimit() {
    this.digitalDisplayService.fileSizeLimit(this.storeId).subscribe((data: any) => {
      this.maxFileSize = data.data.file_size;
    });
  }

  imageList() {
    this.digitalDisplayService
      .ImageMediaListGet(this.storeId)
      .subscribe((data: any) => {
        this.image_list = data.data;
      });
  }
  videoList() {
    this.digitalDisplayService
      .videoListGet(this.storeId)
      .subscribe((data: any) => {
        this.video_list = data.data;
      });
  }
  onChange(event) {
    this.file = event.target.files[0];
    const maxFileSizeInBytes = this.maxFileSize * 1024 * 1024;
    if (this.file) {
      if (this.file.size > maxFileSizeInBytes) {
        this.ImgErrorShow = `File size exceeds the maximum limit of ${this.maxFileSize}MB.`;
        this.file = null;
      } else {
        this.ImgErrorShow = '';
      }
    }
  }

  headerCheck(a) {
    this.showHeader = a.checked;
  }
  displayCheck(a) {
    this.hideKeyDisplay = a.checked;
  }
  hideColorCheck(a) {
    this.hideColorsCheck = a.checked;
  }
  decimalPointCheck(a) {
    this.decimalPointsCheck = a.checked;
  }
}
