import {MenuGroupType} from '../_models/menu-v2';
import {RxCollection, RxDocument, RxJsonSchema} from 'rxdb';

export type MenuGroupDocMethods = {
  getMenuGroup: (id: number) => MenuGroupType;
};
export type MenuGroupDocument = RxDocument<MenuGroupType, MenuGroupDocMethods>;

export type MenuGroupCollectionMethods = {
  insertMenuGroup: (data: MenuGroupType) => Promise<MenuGroupDocument>;
};

export type MenuGroupCollection = RxCollection<MenuGroupType, MenuGroupCollectionMethods>;


export const menuGroupSchema: RxJsonSchema<MenuGroupType> = {
  title: 'menuGroup schema',
  description: 'describes a simple menuGroup',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    name: {
      type: 'string',
    },
    subgroup: {
      type: ['string', 'null']
    },
    store:{
      type: 'number'
    },
    parent: {
      type: 'string',
    },
    order: {
      type: 'number',
      multipleOf: 1,
      minimum: 0,
      maximum: 999
    },
    is_deleted: {
      type: 'boolean',
    },
    printer_data: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          printer_id: {
            type: 'string',
          },
          id: {
            type: 'number',
          },
          name: {
            type: 'string',
          },
        },
        required: ['printer_id', 'id', 'name'],
      },
    },
    color: {
      type: 'string',
    },
    text_color: {
      type: 'string',
    },
    image: {
      type: 'string',
    },
    sub_image: {
      type: 'string',
    },
    menu_item_icon_id: {
      type: 'number',
    },
  },
  required: ['name', 'subgroup', 'parent', 'order', 'is_deleted', 'color', 'text_color', 'image', 'sub_image'],
  indexes: ['order']
};
