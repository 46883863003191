import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../_services/store.service';
import {Store} from '../../_models/store';
import {MatDialog} from '@angular/material/dialog';
import {AddActionComponent} from '../add-action/add-action.component';
import {AttendantActionDataSource} from '../../_datasources/attendant-action-datasource';
import {AttendantSettingsService} from '../../_services/attendant-settings.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})

export class ActionsComponent implements OnInit {

  store:Store;
  isLoading = true;
  displayedColumns: string[] = ['greeting_type', 'trigger', 'label', 'greeting', 'edit'];
  dataSource: AttendantActionDataSource;

  constructor(
    private IVRSetting: AttendantSettingsService,
    private storeService:StoreService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.dataSource = new AttendantActionDataSource(this.IVRSetting)
    this.storeService.current.subscribe(store =>{
      if(store){
        this.store = Object.assign(new Store(), store)
        this.dataSource.loadAttendantActions(store.id);
      }
    });
  }

  loadAttendantActions() {
    this.dataSource.loadAttendantActions(this.store.id);
  }

  addAction(actionData){
    const isMobile = window.innerWidth <= 599;
    if(actionData == 'ADD'){
      var action: any = {};
    } else {
      var action: any = actionData;
    }
    const dialogRef = this.dialog.open(AddActionComponent, {
      panelClass: 'addaction-dialog',
      width: isMobile ? '100vw' : '500px',
      height: isMobile ? '100vh' : '620px',
      maxWidth: isMobile ? '100vw' : '80vw',
       data: {
        'store': this.store.id,
        'action': action
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });
  }

  deleteAction(element){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to delete?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.IVRSetting.deleteIvrAction(element.id).subscribe(data => {
          this.loadAttendantActions()
        })
      }
    });
    // if(confirm("Are you sure you want to delete?")){
    //   this.IVRSetting.deleteIvrAction(element.id).subscribe(data => {
    //     this.loadAttendantActions()
    //   })
    // }
  }

}
