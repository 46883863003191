<div class="flex flex-row items-center xs:!mt-5 xs:!mb-2.5">
  <span>{{availability[categoryAvailability]}}</span>
  <button mat-icon-button type="button" [matMenuTriggerFor]="menu" [disabled]="!menuGroupName">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="setAvailability('ALL_DAY_EVERYDAY')">
      <span>All day, every day</span>
    </button>
    <button mat-menu-item (click)="setAvailability('NOT_AVAILABLE')">
      <span>Not available</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="availabilityDialog()">
      <span>Custom</span>
    </button>
  </mat-menu>
</div>
