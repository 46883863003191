export class AttendantSettings {
  id: number;
  store: number;
  closed_hours_message?: any;
  welcome_message?: any;
  voice: string
  created_at: string;
  updated_at: string;
  closed_hours_audio?: any;
  welcome_audio?: any;
  use_welcome_audio?: boolean;
  use_closed_hours_audio?: boolean;
  mp3_link: string;
  hold_queue_message?: any;
  hold_queue_audio?: any;
  use_hold_queue_audio?: boolean;
}

