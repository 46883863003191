<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<form [formGroup]="form" class="form-message-design fixActionRow">
<h1 mat-dialog-title>Order Ready Messages</h1>
<div class="container xs:!pb-32" mat-dialog-content>
  <div class="flex flex-row justify-between w-full mb-3">
    <label class="dim-color lean-font">Enable Order Ready Messages</label>
    <mat-slide-toggle class="example-margin" (change)="onChangeToggle($event)" [checked]="toggleValue">
    </mat-slide-toggle>
  </div>
  <div class="flex flex-row items-center justify-between mb-3">
    <p>PICKUP</p>
    <mat-form-field subscriptSizing="dynamic" class="w-80">
      <input matInput formControlName="pickup_message" />
    </mat-form-field>
  </div>
  <div class="flex flex-row items-center justify-between mb-3">
    <p>DINE-IN</p>
    <mat-form-field subscriptSizing="dynamic" class="w-80">
      <input matInput formControlName="dinein_message" />
    </mat-form-field>
  </div>
  <div class="flex flex-row items-center justify-between mb-3">
    <p>DELIVERY</p>
    <mat-form-field subscriptSizing="dynamic" class="w-80">
      <input matInput formControlName="delivery_message" />
    </mat-form-field>
  </div>
  <div class="flex flex-row items-center justify-between mb-3">
    <p>CURBSIDE</p>
    <mat-form-field subscriptSizing="dynamic" class="w-80">
      <input matInput formControlName="curbside_message" />
    </mat-form-field>
  </div>
  <div class="flex flex-row items-center justify-between mb-3">
    <p>TABLESIDE</p>
    <mat-form-field subscriptSizing="dynamic" class="w-80">
      <input matInput formControlName="tableside_message" />
    </mat-form-field>
  </div>
</div>
<mat-dialog-actions align="end" class="p-5 xs:!pt-0">
  <button mat-stroked-button mat-dialog-close class="mr-1 mb-3">Cancel</button>
  <button mat-flat-button color="primary" type="button" (click)="submitMessagesForm()" class="mb-3 mr-3 apply-btn-colr"
    [disabled]="isLoading">Apply</button>
</mat-dialog-actions>
</form>