import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PermissionService } from 'src/app/_services/permission.service';
import { Permission } from 'src/app/_models/permission';
import { Store } from 'src/app/_models/store';
import { BotOrder, Modifier, OccasionDetail, Order, OrdersWithPayment } from 'src/app/_models/order';
import { StoreService } from 'src/app/_services/store.service';
import { OrderService } from 'src/app/_services/order.service';
import * as moment from 'moment';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrdersV2DataSource } from 'src/app/_datasources/order-v2-datasource';
import { CancelDialogComponent } from 'src/app/orders/cancel-dialog/cancel-dialog.component';
import { PinConfirmComponent } from 'src/app/orders/pin-confirm/pin-confirm.component';
import { POSOrderTabChangeService } from 'src/app/_services/pos-order-tab-change.service';
import { CurrentOrderService } from 'src/app/_services/current-order.service';
import { OrderTypeTabChangeService } from 'src/app/_services/order-type-tab-change.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { AlertAudioService } from 'src/app/_services/alert-audio.service';
import { OrderAcceptanceDialogComponent } from './order-acceptance-dialog/order-acceptance-dialog.component';
import { ReceiptService } from 'src/app/_services/receipt.service';
import { OpenOrderToggleService } from 'src/app/_services/open-order-toggle.service';
import { PaymentOptionsDialogComponent } from 'src/app/orders/payment-options-dialog/payment-options-dialog.component';
import { CheckoutDialogComponent } from 'src/app/orders/checkout-dialog/checkout-dialog.component';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, merge, Subject, Subscription } from 'rxjs';
import { RefreshService } from 'src/app/_services/refresh.service';
import { Modules } from 'src/app/constants';
import { CardPaymentMqttComponent } from 'src/app/orders/card-payment-mqtt/card-payment-mqtt.component';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class OrderTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filters;
  @Input() isOpenOrders;
  @Input() store: Store;
  @Input() refresh;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('f', { static: false }) ngForm;


  expandedElement: BotOrder | null;

  isLoading = false;
  isPaymentLoading = false;

  dataSource: OrdersV2DataSource;
  permission: Permission;
  pinPermission: Permission;
  columnsToDisplay;
  columnsToDisplayWithExpand;
  posLoggedUser = localStorage.getItem('posLoggedUser') ? localStorage.getItem('posLoggedUser') : '';
  columnNames = {
    ready: 'Ready',
    order_name: 'Name',
    placed: 'Placed',
    order_counter: 'Order #',
    source: 'Source',
    occasion: 'Occasion',
    table_id: 'Table ID',
    terminal_id: 'Station ID',
    cashier_id: 'Server Name',
    payment_type: 'Payment',
    total: 'Total',
    status: 'Status',
    action: '',
  };

  order: OrdersWithPayment;
  paymentForm: UntypedFormGroup;
  paymentHint: string = 'Please select a payment operation';

  occasionDetail: OccasionDetail;
  setOccasionDetail: OccasionDetail;
  changed = false;
  newBids = [];

  paymentFailed = false;
  paymentSuccess = false;
  history = [];
  public terminalId;

  /* declare here */

  selectedRow: any;
  expandedRowId;
  selectedOrderMiscFromGroup;
  phoneNumber: any;
  storeCredit: any;
  paymentGateway: string;
  walletInsufficient: boolean = true;
  post_store_credit: any;
  adjustPinUser: any
  private filterSubject = new BehaviorSubject<string>('');
  private filterSubscription: Subscription;
  splitDataSource: any;
  columnsToSplitDisplay = ['time', 'payment_type', 'amount', 'card_number', 'name_on_card', 'transaction_id'];
  expandedSplitRowId;
  splitItems:any;
  splitRow:any;
  selectedSplitRow: any;

  private destroySubject: Subject<void> = new Subject();
  dialData: {};
  isMobileView: boolean = false;


  constructor(private permissionService: PermissionService,
    private storeService: StoreService,
    private orderService: OrderService,
    private formbuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private orderTabChangeService: POSOrderTabChangeService,
    private currentOrderService: CurrentOrderService,
    private orderTypeTabChangeService: OrderTypeTabChangeService,
    private posSummaryToggleService: POSSummaryToggleService,
    private alertAudioService: AlertAudioService,
    private receiptService: ReceiptService,
    private openOrderToggle: OpenOrderToggleService,
    private refreshService: RefreshService,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.isLoading = true;
    this.dataSource = new OrdersV2DataSource(this.orderService);
    this.permissionService.pinCurrentOperator.pipe(takeUntil(this.destroySubject)).subscribe((permission) => {
      this.permission = permission;
    });
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        if (this.isOpenOrders) {
          console.log('is open order');

          this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((state: BreakpointState) => {
            this.isMobileView = state.matches;
            if (state.matches) {
              this.columnsToDisplay = ['order_counter', 'order_name', 'table_id', 'cashier_id'];
            } else {
              if (this.store.hasModule('POS')) {
                this.columnsToDisplay = ['order_name', 'placed', 'order_counter',
                  'source', 'occasion', 'table_id', 'terminal_id', 'cashier_id', 'payment_type', 'total', 'status'];
              } else {
                this.columnsToDisplay = ['ready', 'placed', 'order_name', 'order_counter',
                  'source', 'occasion', 'payment_type', 'total', 'status'];
              }
            }
          });
        } else {
          console.log('is closed order');

          if (this.store.hasModule("POS")) {
            this.columnsToDisplay = ['updated_at', 'order_counter', 'source', 'occasion', 'table_id', 'terminal_id', 'cashier_id', 'payment_type', 'total', 'payment_status']
          } else {
            this.columnsToDisplay = ['updated_at', 'order_counter', 'source', 'occasion', 'payment_type', 'total', 'payment_status']
          }

        }
        if (this.store.getFeatureFlag('enable_order_ready') && !this.columnsToDisplay.includes('ready')) {
          this.columnsToDisplay.unshift('ready')
        }
      }
      this.loadOrders();
    });
    this.paymentForm = this.formbuilder.group({
      history_id: ['', Validators.required],
      order_id: ['', Validators.required],
      payment_type: ['', Validators.required],
      amount: ['', Validators.required],
      note: '',
      split_id: [null] // Add split_id field to the form group

    });
    if (this.order) {
      this.occasionDetail = this.order.getOccasionDetail();
    }

    this.terminalId = localStorage.getItem("selectedTerminal");
    this.selectedOrderMiscFromGroup = new UntypedFormArray([]);


    this.refreshService.current.pipe(takeUntil(this.destroySubject)).subscribe(tab => {
      if ((tab === Modules.CLOSED_TAB) || !this.isOpenOrders) {
        this.loadClosedOrders();
      } else {
        this.loadOpenOrders();
      }
    })

    this.orderService.loadClosedOpenSubject.pipe(takeUntil(this.destroySubject)).subscribe((val: any) => {
      if (val) {
        if (this.isOpenOrders) {
          this.loadOpenOrders();
        } else {
          this.loadClosedOrders();
          this.orderService.getOrder(this.order.bot_order.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.order = Object.assign(new OrdersWithPayment(), data, {
              bot_order: Object.assign(new BotOrder(), data.bot_order, {
                occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
                created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
                updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
                submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
              }),
              store: Object.assign(new Store(), data.store),
              items: data.items.map(o => Object.assign(new Order(), o, {
                modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
              })),
              payment: OrderHelpers.parsePayment(data.payment)
            });
            this.paymentForm.get('order_id').setValue(this.order.bot_order.order_hash);
            this.loadHistory(this.order.bot_order.order_hash);
          });
        }
        this.orderService.loadClosedOpenSubject.next(false);
      }
    });

    this.paymentForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((formValues) => {
      const historyId = formValues.history_id;
      const matchingPayment = this.history.find(payment => payment.id === historyId);
      if (matchingPayment) {
        if (matchingPayment.payment_type == "STORE_CREDIT") {
          if (this.order.bot_order.phone_number) {
            this.phoneNumber = this.getFormattedPhoneNumber(this.order.bot_order.phone_number);
          }
          this.paymentGateway = this.order.store.payment_gateway;
          this.orderService.getCards(this.store.id, this.getPlainPhoneNumber(), this.paymentGateway).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.checkPostAdjustStoreCredit(data['store_credit'], formValues);
          },
            (error) => {
              this.checkPostAdjustStoreCredit(error.store_credit, formValues);
            })
        } else {
          this.walletInsufficient = true;
        }
      }
    });
    this.orderService.openOrderComplete.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.loadOpenOrders()
      }
    })
  }

  checkPostAdjustStoreCredit(d, e) {
    if (d >= 0) {
      if (d < Number(e.amount)) {
        this.walletInsufficient = false;
        this.snackBar.open('The user does not have sufficient store credit', 'OK', { duration: 2000 });
      } else {
        this.walletInsufficient = true;
      }
    }
  }

  ngAfterViewInit() {
    if (this.isOpenOrders) {
      this.sort.sortChange.pipe(takeUntil(this.destroySubject)).subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadOpenOrders())).pipe(takeUntil(this.destroySubject))
        .subscribe();
    } else {
      this.sort.sortChange.pipe(takeUntil(this.destroySubject)).subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadClosedOrders())).pipe(takeUntil(this.destroySubject))
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      this.filters = changes.filters.currentValue;
      this.loadOrders();
    }

    if (changes.refresh) {
      this.loadOrders();
    }
  }

  loadOrders() {
    if (this.isOpenOrders) {
      this.loadOpenOrders();
    } else {
      this.loadClosedOrders();
    }
  }

  loadHistory(order_hash) {
    this.orderService.history(order_hash).pipe(takeUntil(this.destroySubject)).subscribe(history => {
      this.history = history
        .filter(order => order.action !== 'CANCELLED')
        .sort((a, b) => (a.action === 'SALE' ? -1 : b.action === 'SALE' ? 1 : 0)); // Move "SALE" to the top
  
      if (this.history.length >= 1) {
        this.paymentForm.get('history_id').setValue(this.history[0].id);
      }
    });
  }

  loadClosedOrders() {
    this.isLoading = true;
    if (this.dataSource && this.store && this.sort) {
      this.filterSubject.next(this.filters.filter || '');
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      if (this.dataSource && this.store && this.sort) {
        this.filterSubscription = this.filterSubject.pipe(debounceTime(800)).pipe(takeUntil(this.destroySubject)).subscribe((debouncedFilter) => {
          this.dataSource.closedOrders(+this.store.id, this.filters.filter || '', this.filters.start_date, this.filters.end_date,
          this.sort.active || 'order_counter', this.sort.direction || 'desc',
          this.paginator.pageIndex, this.paginator.pageSize, this.posLoggedUser,
          this.filters.occasion, this.filters.payment, this.filters.source, this.filters.status, this.filters.server_id);
          this.isLoading = false;
        });
      } else {
        console.log('datasource not init');
      }
    } else {
      console.log('datasource not init');
    }

    this.selectedRow = null;
    this.expandedRowId = null;
  }

  loadOpenOrders() {
    this.isLoading = true;
    if (this.dataSource && this.store && this.sort) {
      this.dataSource.openOrders(+this.store.id, this.filters.filter || '', this.filters.start_date, this.filters.end_date,
        this.sort.active || 'order_counter', this.sort.direction || 'desc',
        this.paginator.pageIndex, this.paginator.pageSize, this.posLoggedUser,
        this.filters.occasion, this.filters.payment, this.filters.source, this.filters.status, this.filters.server_id);
        this.isLoading = false;
    }
    // const openOrderIds = this.dataSource.getIds();
    // if (this.selectedRow && !openOrderIds.includes(this.selectedRow.id)) {
    this.selectedRow = null;
    this.expandedRowId = null;
    // }
  }

  onRowClicked(row, e) {
    console.log(row);
    this.removeBid(row.id);
    if (e != 0 && this.ngForm) {
      this.ngForm.resetForm();
    }
    this.order = undefined;
    this.orderService.getOrder(row.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.order = Object.assign(new OrdersWithPayment(), data, {
        bot_order: Object.assign(new BotOrder(), data.bot_order, {
          occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
          created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
          updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
          submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
        }),
        store: Object.assign(new Store(), data.store),
        items: data.items.map(o => Object.assign(new Order(), o, {
          modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
        })),
        payment: OrderHelpers.parsePayment(data.payment)
      });
      this.paymentForm.get('order_id').setValue(this.order.bot_order.order_hash);

      this.loadHistory(this.order.bot_order.order_hash);
      if (this.order.bot_order.payment_type == 'SPLIT') {
        this.expandedSplitRowId = null;
        this.loadSplitOrderDetails(this.order.bot_order.order_hash);
      } else {
        this.expandedSplitRowId = null;
      }
      if (this.order.payment.type == "STORE_CREDIT") {
        if (this.order.bot_order.phone_number) {
          this.phoneNumber = this.getFormattedPhoneNumber(this.order.bot_order.phone_number);
        }
        this.paymentGateway = this.order.store.payment_gateway;
        if (this.order.store.has_card_payment) {
          this.orderService.getCards(this.store.id, this.getPlainPhoneNumber(), this.paymentGateway).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.storeCredit = data['store_credit']
          },
            (error) => {
              this.storeCredit = error.store_credit;
            })
        }
        console.log('Store Credit', this.storeCredit);

      }

    });

  }

  getPlainPhoneNumber() {
    if (this.phoneNumber) {
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0, 10)
    }
    return "";
  }
  getFormattedPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4')
  }

  getFormGroup(data) {
    return new UntypedFormGroup({
      'id': new UntypedFormControl(data ? data.id : null),
      'quantity': new UntypedFormControl(data ? data.quantity : 1, Validators.required),
      'item_name': new UntypedFormControl(data ? data.item_name : null, Validators.required),
      'price': new UntypedFormControl(data ? data.price : null, Validators.required),
      'is_taxable': new UntypedFormControl(data ? data.is_taxable : true)
    });
  }


  openConsumerChoiceDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(PaymentOptionsDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: {
        command: 'SALE',
        openOrder: true,
        order_id: this.order.bot_order.order_hash,
        orderWithPayment: this.order,
        terminalId: this.terminalId,
        total: this.order.payment.total,
        subTotal: this.order.payment.sub_total,
        cashier_id: localStorage.getItem('posLoggedUser'),
        terminal_name: localStorage.getItem('selectedTerminalName')
      }
    });

    dialogRef.afterClosed().subscribe(isComplete => {
      console.log(isComplete);

      if (isComplete) {
        this.afterOrderClosed();
      }
    });
  }

  openBBPOSCheckout() {
    console.log('WS implementation');
    let data = {
      terminal_id: localStorage.getItem('selectedTerminal'),
      command: 'SALE',
      order_id: this.order.bot_order.order_hash,
      amount: this.order.getTotal(),
      orderWithPayment: this.order,
      terminalId: this.terminalId,
      total: this.order.payment.total,
      cashTotal: this.order.payment.cash_due_total,
      subTotal: this.order.payment.sub_total,
      payment_type: 'CREDIT',
      is_open_tab: false,
      cashier_id: localStorage.getItem("posLoggedUser"),
      pin_user: localStorage.getItem('pinUser'),
      terminal_name: localStorage.getItem('selectedTerminalName')
    };
    let dialogRef = this.dialog.open(CardPaymentMqttComponent, {
      width: '630px', data: data, disableClose: true,
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        this.afterOrderClosed();
      }
    });
  }

  openRegularCheckout() {
    const orderHash = localStorage.getItem('order_hash');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      width: '630px', height: '630px',
      disableClose: true,
      data: {
        orderWithPayment: this.order,
        terminalId: this.terminalId,
        total: this.order?.payment?.total,
        cashTotal: this.order?.payment?.cash_due_total,
        subTotal: this.order?.payment?.sub_total,
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(isComplete => {
    });
  }

  afterOrderClosed() {
    localStorage.removeItem('order_hash')
    if (this.occasionDetail != null) {
      this.setOccasionDetail = this.occasionDetail;
    }
    this.order = null;
    if (this.store.pos_setting && !this.store.pos_setting["default_load"]) {
      this.occasionDetail = null;
    }
    this.ngOnInit()
    this.orderService.orderDoneSubject.next();
    localStorage.removeItem('setOccasionDetail');
  }

  onPaymentTypeChange(paymentType) {
    this.paymentForm.get('amount').enable();
    switch (paymentType) {
      case 'TIP':
        this.paymentHint = 'Enter tip amount above. Tips will not be taxed and are added to the Order Total';
        break;
      case 'ADJUST':
        this.paymentHint = 'Enter increase value above. Taxes will be automatically applied accordingly';
        break;
      case 'REFUND':
        this.paymentHint = 'Submit a partial refund';
        break;
      case 'FULL_REFUND':
        this.paymentForm.get('amount').disable();
        this.paymentHint = 'Submit a full refund';
        break;
      default:
        return 'Please select a payment operation';
    }
  }

  checkPermissions() {
    let payload = this.paymentForm.value;
    console.log(payload);

    let ops = payload.payment_type;
    if (ops === 'PARTIAL_REFUND' || ops === 'FULL_REFUND') {
      ops = 'REFUND';
    }

    if (ops === 'TIP_ADJUST') {
      ops = 'ADJUST';
    }

    if (!this.store.hasModule('POS')) {
      console.log('Only online Store', this.permission);
      this.processPayment();
    }
    else {
      console.log('POS + ONLINE Store');

      if (this.permission.hasPermission('POS_FUNCTIONS', ops)) {
        this.processPayment();
      } else {
        const isMobile = window.innerWidth <= 599;
        const dialogRef = this.dialog.open(PinConfirmComponent, {
          width: isMobile ? '100vw' : '500px',
          height: isMobile ? '100vh' : '410px',
          maxWidth: isMobile ? '100vw' : '80vw',
          data: {}
        });
        dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
          this.pinPermission = resultPer['permission'];
          if (this.pinPermission) {
            if (this.pinPermission.hasPermission('POS_FUNCTIONS', ops)) {
              this.processPayment();
              this.orderService.updateDescription(this.expandedElement.id, { description: resultPer['description'] }).pipe(takeUntil(this.destroySubject)).subscribe((updated) => {
                console.log("________________", updated)
              });
            } else {
              this.alertPopup('This User/Manager does not have the required permission to perform this operation!', '');
            }
          }
        });
      }
    }

  }

  processPayment() {
    this.isPaymentLoading = true;
    this.adjustPinUser = localStorage.getItem('pinUser');
    if (!this.adjustPinUser) {
      this.adjustPinUser = ''
    }
    const keyData = {
      "history_id": this.paymentForm.value.history_id,
      "order_id": this.paymentForm.value.order_id,
      "payment_type": this.paymentForm.value.payment_type,
      "amount": this.paymentForm.value.amount,
      "note": this.paymentForm.value.note,
      "user": this.adjustPinUser,
      "split_id": this.paymentForm.value.split_id

    }
    this.orderService.processPostPayment(this.store.id, keyData).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.isPaymentLoading = false;
      this.paymentFailed = false;
      this.paymentSuccess = true;
      this.loadClosedOrders();
      this.orderService.getOrder(this.order.bot_order.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        this.order = Object.assign(new OrdersWithPayment(), data, {
          bot_order: Object.assign(new BotOrder(), data.bot_order, {
            occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
            created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
            updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
            submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
          }),
          store: Object.assign(new Store(), data.store),
          items: data.items.map(o => Object.assign(new Order(), o, {
            modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
          })),
          payment: OrderHelpers.parsePayment(data.payment)
        });
        this.paymentForm.get('order_id').setValue(this.order.bot_order.order_hash);
      });

      this.ngForm.resetForm()
      setTimeout(() => {
        this.paymentSuccess = false;
      }, 5000)
      this.paymentForm.get('order_id').setValue(this.order.bot_order.order_hash);
      this.loadHistory(this.order.bot_order.order_hash);
    }, (error) => {
      this.isPaymentLoading = false;
      this.paymentFailed = true;
    });
  }


  print(botOrder: BotOrder) {
    let terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : "NA";
    this.orderService.printOrder(botOrder.order_hash, terminalName).pipe(takeUntil(this.destroySubject)).subscribe(
      data => {
        this.snackBar.open('Order printed successfully', 'OK', { duration: 2000 });
      },
      error => {
        this.snackBar.open('Unable to print order', 'OK', { duration: 2000 });
      })
  }

  printSplitReceipt(botOrder: BotOrder,row:any) {
    console.log( row);
    var splitId=row.id;
    let terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : "NA";
    this.orderService.printOrderSplit(botOrder.order_hash, terminalName,splitId).subscribe(
      data => {
        this.snackBar.open('Order printed successfully', 'OK', { duration: 2000 });
      },
      error => {
        this.snackBar.open('Unable to print order', 'OK', { duration: 2000 });
      })
  }

  cancel(botOrder: BotOrder, split:string) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(CancelDialogComponent, {
      // width: '500px',
      // height: '650px',
      width: isMobile ? '100vw' : '520px',
      height: isMobile ? '100vh' : '90vh',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        order: this.order,
        botOrder: botOrder,
        store: this.store,
        split_detail: split
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.storeService.isOrderView.next(false);
      botOrder.view_notified = true;
      this.storeService.isOrderTab.next(false);
      this.loadOrders();
      this.orderService.getOrder(this.order.bot_order.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        this.order = Object.assign(new OrdersWithPayment(), data, {
          bot_order: Object.assign(new BotOrder(), data.bot_order, {
            occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
            created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
            updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
            submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
          }),
          store: Object.assign(new Store(), data.store),
          items: data.items.map(o => Object.assign(new Order(), o, {
            modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
          })),
          payment: OrderHelpers.parsePayment(data.payment)
        });
        this.paymentForm.get('order_id').setValue(this.order.bot_order.order_hash);
      });
    });
  }


  checkPOSPermission(botOrder: BotOrder, split:any) {
    if (this.permission == null) {
      if (!this.store.hasModule('POS')) {
        console.log('No Pos');
        this.cancel(botOrder, split);
        this.loadOrders();
      }
    } else if (this.permission.hasPermission('POS_FUNCTIONS', 'CANCEL')) {
      this.cancel(botOrder, split);
      this.loadOrders();
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
        this.pinPermission = resultPer['permission'];
        if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'CANCEL')) {
          this.cancel(botOrder, split);
          this.orderService.updateDescription(botOrder.id, { description: resultPer['description'] }).subscribe((updated) => {
            console.log("________________", updated)
          });
          this.loadOrders();
        } else {
          this.alertPopup('This User/Manager does not have the required permission to perform this operation!', '');
        }
      });
    }
  }

  edit() {
    this.setOccasionDetail = this.occasionDetail;
    localStorage.setItem('setOccasionDetail', JSON.stringify(this.order.getOccasionDetail()));
    if (this.store.hasModule('POS')) {
      const currentPin = localStorage.getItem('currentPIN');
      const posLoggedUser = localStorage.getItem('posLoggedUser');
      if (this.order.bot_order.cashier_id && (posLoggedUser !== this.order.bot_order.cashier_id)){
        this.alertPopup('Would you like to change the Server ID of this order?', 'edit_order');
      } else {
        if (currentPin) {
          localStorage.setItem('order_hash', this.order.bot_order.order_hash);
          this.currentOrderService.changeCurrentOrder(this.order);
          this.orderTypeTabChangeService.changeTab(0);
          this.orderTabChangeService.changeTab(2);
          this.posSummaryToggleService.toggle('OPEN')
          this.orderService.openPosLoginFromOpenOrder.next(false);
        } else {
          this.orderService.openPosLoginFromOpenOrder.next(true);
        }
      }
    } else {
      this.orderTypeTabChangeService.changeTab(0);
      this.alertPopup('Please login and select the station', '');
    }
  }

  checkout() {
    console.log(this.store);

    if (this.store.hasModule("POS")) {
      const currentPin = localStorage.getItem('currentPIN');
      if (currentPin) {
        this.currentOrderService.changeCurrentOrder(this.order);
        this.orderService.openPosLoginFromOpenOrder.next(false);
        if (this.store.enable_consumer_choice) {
          if (this.order.bot_order) {
            this.openConsumerChoiceDialog();
          }
        } else if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
          this.openBBPOSCheckout();
        } else {
          this.openRegularCheckout();
        }
      } else {
        this.orderService.openPosLoginFromOpenOrder.next(true);
      }
    } else {
      this.currentOrderService.changeCurrentOrder(this.order);
      if (this.store.enable_consumer_choice) {
        if (this.order.bot_order) {
          this.openConsumerChoiceDialog();
        }
      } else if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        this.openBBPOSCheckout();
      } else {
        this.openRegularCheckout();
      }
    }
  }


  addNew(bid) {
    console.log("adding new bid", bid);

    this.newBids.push(bid);
  }
  removeBid(bid) {
    this.newBids.splice(this.newBids.indexOf(bid), 1);
  }

  isnew(bid) {
    return this.newBids.includes(bid);
  }


  timeSelect(botOrder: BotOrder) {
    const isMobile = window.innerWidth <= 475;
    const dialogRef = this.dialog.open(OrderAcceptanceDialogComponent, {
      maxWidth: isMobile ? '100vw' : '80vw',
      // disableClose: true,
      data: { botOrder: botOrder, 'store': this.store, 'terminalId': this.terminalId },
      panelClass: 'view-order'
    })
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result > 0) {
        this.orderService.viewOrderConfirm(botOrder.order_hash, result).pipe(takeUntil(this.destroySubject)).subscribe(
          data => {
            this.storeService.isOrderView.next(false);
            botOrder.view_notified = true;
            this.storeService.isOrderTab.next(false);
            localStorage.removeItem('Tab');
            this.snackBar.open('Text notification sent successfully', 'OK', { duration: 2000 })
          },
          error => {
            this.snackBar.open('Unable to send text notification', 'OK', { duration: 2000 })
          })
      } else if (result == 0) {
        this.checkPOSPermission(botOrder, '');
      } else {
      }
    });
  }

  ready(botOrder: BotOrder) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure order is ready?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        setTimeout(() => {
          this.orderService.orderReady(botOrder.order_hash).pipe(takeUntil(this.destroySubject)).subscribe(
            data => {
              botOrder.ready_notified = true;
              botOrder.view_notified = true;
              this.snackBar.open('Text notification sent successfully', 'OK', { duration: 2000 })
              this.loadOrders();
            },
            error => {
              this.snackBar.open('Unable to send text notification', 'OK', { duration: 2000 })
            })
        }, 1000)
      } else {
        botOrder.ready_notified = false;
      }
    });
    // if (confirm('Are you sure order is ready?')) {
    //   setTimeout(() => {
    //     this.orderService.orderReady(botOrder.order_hash).subscribe(
    //       data => {
    //         botOrder.ready_notified = true;
    //         botOrder.view_notified = true;
    //         this.snackBar.open('Text notification sent successfully', 'OK', { duration: 2000 })
    //         this.loadOrders();
    //       },
    //       error => {
    //         this.snackBar.open('Unable to send text notification', 'OK', { duration: 2000 })
    //       })
    //   }, 1000)
    // } else {
    //   botOrder.ready_notified = false;
    // }
  }

  formatDate(date) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('hh:mm A MM/DD/YYYY');
  }

  collapseAll() {
    this.expandedRowId = null;
  }

  ngOnDestroy() {
    this.destroySubject.next();
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  alertPopup(msg, e) {
    if (e) {
      this.dialData = {
        message: msg,
        for_which: e,
      }
    } else {
      this.dialData = {
        message: msg
      }
    }
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: this.dialData
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (e == 'edit_order') {
        const currentPin = localStorage.getItem('currentPIN');
        if (result) {
          const payload = {
            server_id: this.posLoggedUser
          }
          this.orderService.updateServerId(this.order.bot_order.order_hash, payload).subscribe((data:any) => {
          });
        }
        if (currentPin) {
          localStorage.setItem('order_hash', this.order.bot_order.order_hash);
          this.currentOrderService.changeCurrentOrder(this.order);
          this.orderTypeTabChangeService.changeTab(0);
          this.orderTabChangeService.changeTab(2);
          this.posSummaryToggleService.toggle('OPEN')
          this.orderService.openPosLoginFromOpenOrder.next(false);
        } else {
          this.orderService.openPosLoginFromOpenOrder.next(true);
        }
      }
    });
  }

  loadSplitOrderDetails(o_hash) {
    this.orderService.splitOrderDetails(o_hash).subscribe((data:any) => {
      this.splitDataSource = data;
    });
  }

  onSplitRowClicked(order_hash, row) {
    this.selectedSplitRow = row;
    // Update the value of the split_id field in the form
    this.paymentForm.get('split_id').setValue(row.id);
    this.orderService.splitTransactionDetails(order_hash, row.id).subscribe((data:any) => {
      this.splitItems = data;
      this.splitRow = row;
      this.loadSplitHistory(order_hash,row.id);
    });
  }

  loadSplitHistory(order_hash, splitID) {
    this.orderService.getSplitTransactionsHistory(order_hash, splitID).pipe(takeUntil(this.destroySubject)).subscribe(history => {
      this.history = history.filter(order => order.action !== 'CANCELLED');
      if (this.history.length === 1) {
        this.paymentForm.get('history_id').setValue(this.history[0].id);
      }
    });
  }

}

