import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../_services/store.service';
import { Store } from '../../_models/store';
import { AttendantSettings } from '../../_models/attendant_settings';
import { AttendantSettingsService } from '../../_services/attendant-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { EditSettingComponent } from '../edit-setting/edit-setting.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  isLoading: boolean = true;
  store: Store;
  attendantSettings: AttendantSettings
  mp3_link: string = "";
  welcome_audio: string = "";
  closed_hours_audio: string = "";
  mp3Link: any[];
  welcomeAudio: any[];
  closedHoursAudio: any[];
  playerVisible: boolean = false;
  constructor(
    public dialog: MatDialog,
    public storeService: StoreService,
    public IVRSettings: AttendantSettingsService
  ) {
    this.store = Object.assign(new Store(), Store),
      this.attendantSettings = Object.assign(new AttendantSettings(), [])
  }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      console.log(store);
      this.isLoading = true;
      if (store) {

        // Get Store - Data
        this.store = Object.assign(new Store(), store)

        // Get IVR Settings - Data
        this.IVRSettings.getIvrSetting(this.store.id).subscribe(data => {
          this.attendantSettings = Object.assign(new AttendantSettings(), data)
          this.mp3_link = this.attendantSettings.mp3_link;
          this.welcome_audio = this.attendantSettings.welcome_audio;
          this.closed_hours_audio = this.attendantSettings.closed_hours_audio;
          this.mp3Link = [
            {
              url: this.mp3_link,
            },
          ];
          console.log('2222222222',this.mp3Link );
          this.welcomeAudio = [
            {
              url: this.welcome_audio,
              // url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
            },
          ];
          console.log(' this.welcomeAudio3333333', this.welcomeAudio);
          
          this.closedHoursAudio = [
            {
              // url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
              url: this.closed_hours_audio,

            },
          ];
          
        });

      }
    })
  }

  edit(setting_type) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(EditSettingComponent, {
      panelClass: 'edit-setting-dialog',
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        settings: this.attendantSettings,
        type: setting_type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("closed");

      setTimeout(() => {
        // this.ngOnInit();
        this.IVRSettings.getIvrSetting(this.store.id).subscribe(data => {
          this.attendantSettings = Object.assign(new AttendantSettings(), data)
          this.mp3_link = this.attendantSettings.mp3_link;
          this.welcome_audio = this.attendantSettings.welcome_audio;
          this.closed_hours_audio = this.attendantSettings.closed_hours_audio;
          console.log('dddddddddddd==================', this.mp3_link);

          this.mp3Link = [
            {
              url: this.mp3_link,
            },
          ];
          this.welcomeAudio = [
            {
              url: this.welcome_audio,
            },
          ];
          this.closedHoursAudio = [
            {
              url: this.closed_hours_audio,

            },
          ];
          this.playerVisible = false;
          setTimeout(() => { this.playerVisible = true; }, 0);
        })
      }, 2000);
    });
  }

  filterSettingValue(setting_type) {
    let value = null;
    if (setting_type == 'voice') {
      value = this.attendantSettings.voice;
    } else if (setting_type == 'message') {
      value = this.attendantSettings.welcome_message;
    } else if (setting_type == 'close_message') {
      value = this.attendantSettings.closed_hours_message;
    }
    console.log('this.attendantSettings.closed_hours_audio', this.attendantSettings.closed_hours_audio);
    console.log('this.attendantSettings.welcome_audio', this.attendantSettings.welcome_audio);
    console.log('this.attendantSettings.welcome_message', this.attendantSettings.welcome_message);
    console.log('this.attendantSettings.closed_hours_message', this.attendantSettings.closed_hours_message);
    return value;
  }
}
