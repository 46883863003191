<!-- <div *ngIf="storeType == 'Leaflogix'">
  <mat-accordion *ngFor="let first of allCategorys;let i = index">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title class="category-name"> {{first.name}} </mat-panel-title>
        <mat-panel-description class="category-description">
          <div class="w-50"> Master Category </div>
          <div class="category-action w-50">
            <div (click)="editMasterCategory(first)"> Edit </div>
            <div (click)="deleteMasterCategory(first.id)"> Delete </div>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-expansion-panel *ngFor="let second of first.categorys" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="category-name" style="width: 28%;"> {{second.name}} </mat-panel-title>
          <mat-panel-description class="category-description">
            <div class="w-50"> Category </div>
            <div class="category-action w-50">
              <div (click)="editCategoryDialog(second)" class="xs:!-right-8 xs:!mr-7"
                style="position:relative; right: -30px;"> Edit </div>
              <div (click)="deleteCategory(second.id)" class="xs:!left-1" style="position: relative; left: 32px;">
                Delete </div>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngFor="let third of second.subcategorys; let i=index">
          <table mat-table [dataSource]="third" width="100%" class="xs:!table-fixed">
            <ng-container matColumnDef="api_category_name">
              <th mat-header-cell *matHeaderCellDef class=""> Sub Category Name </th>
              <td mat-cell *matCellDef="let element"> {{element.display_name}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Hide Category </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hide_category == true">True</span>
                <span *ngIf="element.hide_category == false">False</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="screen">
              <th mat-header-cell *matHeaderCellDef> New Screen </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.new_screen == true">True</span>
                <span *ngIf="element.new_screen == false">False</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="priority">
              <th mat-header-cell *matHeaderCellDef> Priority </th>
              <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
            </ng-container>

            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef> Color </th>
              <td mat-cell *matCellDef="let element">
                <span class="circle" [style.background-color]="element.font_color"></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen1">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #menuScreen1="matMenu">
                  <button mat-menu-item (click)="editSubCategoryDialog(element)">
                    <span class="material-icons-outlined edit-delete-icon">
                      edit
                    </span>&nbsp;
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>
            <span *ngIf="i==0">
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            </span>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 33.33%"></tr>
          </table>

        </div>
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div> -->

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div *ngIf="storeType == 'Restaurant'" style="clear: both;">
  
  <mat-accordion *ngFor="let first of allCategorys;let i = index">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title class="category-name"> {{first.name}}{{first.subgroup ? ' - ' + first.subgroup : ''}} </mat-panel-title>
        <mat-panel-description class="category-description">
          <div class="w-50"> Master Category </div>
          <div class="category-action w-50">
            <div (click)="allStoreEditMasterCategory(first)"> Edit </div>
            <div (click)="deleteMasterCategory(first.id)"> Delete </div>
            <div (click)="setNewMenuCategory(first.id)"> Set New Menu Category </div>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-expansion-panel *ngFor="let second of first.categorys" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="category-name" style="width: 28%;"> {{second.name}}{{second.subgroup ? ' - ' + second.subgroup : ''}} </mat-panel-title>
          <mat-panel-description class="category-description">
            <div class="w-50"> Sub Category </div>
            <!-- <div class="category-action w-50">
            <div (click)="editmenuCategoryDialog(second)" style="position:relative; right: -33px;"> Edit </div>
            <div (click)="deleteScreens(second.id)" style="position: relative; left: 32px;"> Delete </div>
          </div> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- <div *ngFor="let third of second.subcategorys; let i=index;"> -->
        <table mat-table [dataSource]="second.subcategorys" width="100%">
          <ng-container matColumnDef="api_category_name">
            <th mat-header-cell *matHeaderCellDef> Menu Items Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} {{element.description ? ' - ' + element.description : ''}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Hide Category </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span *ngIf="element.hide_category == true">True</span>
              <span *ngIf="element.hide_category == false">False</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="screen">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> New Screen </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span *ngIf="element.new_screen == true">True</span>
              <span *ngIf="element.new_screen == false">False</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef> Base Price </th>
            <td mat-cell *matCellDef="let element"> {{element.starting_price}} </td>
          </ng-container>

          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> Color </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <span class="circle" [style.background-color]="element.font_color"></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="display: none;"> </th>
            <td mat-cell *matCellDef="let element" style="display: none;">
              <button mat-icon-button aria-label="expand row" [matMenuTriggerFor]="menuScreen1">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menuScreen1="matMenu">
                <button mat-menu-item (click)="editSubCategoryDialog(element)">
                  <span class="material-icons-outlined edit-delete-icon">
                    edit
                  </span>&nbsp;
                  <span>Edit</span>
                </button>
                <!-- <button mat-menu-item (click)="deleteScreens()">
                  <span class="material-icons edit-delete-icon">
                    delete_outline
                  </span>&nbsp;
                  <span>Delete</span>
              </button> -->
              </mat-menu>
            </td>
          </ng-container>
          <span *ngIf="i==0">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          </span>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 33.33%"></tr>
        </table>

        <!-- </div> -->
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div>