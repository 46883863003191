<form [formGroup]="refundForm" (ngSubmit)="onSubmit()">
<h1 mat-dialog-title>Refund Order</h1>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
    <span style="color: #666666;">Complete a partial or full order refund here. Taxes will be adjusted automatically.</span>
    <div style="clear: both; margin-top: 10px;"></div>
    <div *ngIf="!isSplit()">
      <div class="left-sec">
        <app-receipt [orderWithPayment]="orderWithPayment" [onlyItems]="true"></app-receipt>
      </div>
      <div class="right-sec">
        <div class="center-box box-height">
          <p class="primary-color">Submit a refund</p>
          <mat-radio-group formControlName="refundChoice" required="true" style="color:gray">
            <mat-radio-button class="item-pad" value="fullRefund" onchange="">
              Full Refund
              <span class="price">( <span class="sup">$</span> {{canRefund}} )</span>
            </mat-radio-button>

            <mat-radio-button class="item-pad" value="partialRefund" [disabled]="canPartialRefund()">Partial Refund </mat-radio-button>
          </mat-radio-group>
          <div class="form-group item-pad" *ngIf="f.refundChoice == 'partialRefund'">
            <mat-form-field appearance="outline" class="refund-field">
              <span style="color:gray;float: left;" matPrefix>$ &nbsp;</span>
              <mat-label>Refund Amount</mat-label>
              <input type="text" formControlName="refundAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted }" matInput
                    placeholder="Refund Amount" autofocus required="f.refundChoice == 'partialRefund'">
            </mat-form-field>
          </div>
          <p *ngIf="error" class="alert alert-danger item-pad">
            Gateway Response: {{error}}. <span *ngIf="error.includes('not settled')">Please try again in 24hrs.</span>
          </p>
        </div>
      </div>

      </div>
      <div *ngIf="isSplit()">
        <div class="box-height manual-tip-add-table">
          <table class="styled-table" *ngIf="splitTranscations">
            <tr>
              <th>Name</th>
              <th>Last 4</th>
              <th>Type</th>
              <th>Amount</th>
              <th></th>
            </tr>
            <tr *ngFor="let record of splitTranscations">
              <td>{{record.name_on_card}}</td>
              <td>{{record.last_4}}</td>
              <td>{{record.payment_type}}</td>
              <td>{{record.split_amount | currency:'USD'}}</td>
              <td>
                <button mat-flat-button color="warn" [disabled]="loading || record.is_void || record.payment_type === 'CASH'"
                (click)="refundSplit(record)">Refund</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
</div>
<mat-dialog-actions align="end" class="button-box-right">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" type="submit" [disabled]="canRefund <= 0">Submit</button>
</mat-dialog-actions>
</form>
