import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Constants} from 'src/app/constants';
import {OrdersWithPayment} from 'src/app/_models/order';

@Component({
  selector: 'app-order-item-breakup',
  templateUrl: './order-item-breakup.component.html',
  styleUrls: ['./order-item-breakup.component.scss']
})
export class OrderItemBreakupComponent implements OnInit {

  @Input() orderWithPayment: OrdersWithPayment;
  @Input() splitItems: any
  displayedColumns: string[] = ['quantity', 'item'];
  displayedMiscColumns: string[] = ['quantity', 'item'];
  displayedSplitItemColumns: string[] = ['item', 'itemTotal'];
  pizzaOptions:Map<string, string> = Constants.pizzaOptions;
  pizzaOptions$: Map<string, string> = Constants.pizzaOptionsFlag;

  splitData:any

  constructor() { }

  ngOnInit() {
    
  }

  isDelivery(bot_order){
    if(bot_order){
      return bot_order.occasion === 'DELIVERY' &&  bot_order.delivery_address;
    }
  }


  getOccasionDetails(bot_order){
    if(bot_order){
      switch(bot_order.occasion){
        case 'DELVIERY':
          return bot_order.delivery_address || 'Delivery address not available';
        case 'TABLESIDE' || 'DINE_IN':
          return bot_order.dine_in_table_number || 'Table ID not available';
        case 'PICKUP':

      }
    }
  }

}
