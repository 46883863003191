<div class="fixActionRow cancel-dialog-receipt p-5">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="margin-bottom: 5px"></mat-progress-bar>
  <div class="flex flex-row px-6">
    <button fxFlex="49" mat-stroked-button mat-dialog-close class="mr-3">Cancel</button>
    <button fxFlex="49" mat-flat-button color="primary" [ngClass]="{ 'apply-btn-colr': form.valid }" type="button"
      [disabled]="!form.valid || isLoading" (click)="cancelOrder()">Yes</button>
  </div>
  <h1 mat-dialog-title>Cancel Order?</h1>
  <span class="subtitles mb-4 mr-3 ml-3">Are you sure you want to permanently cancel this order? Its transaction and
    payment data
    will be omitted from reporting. This cannot be undone.</span>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="mr-3 ml-3">
    <form [formGroup]="form">
      <mat-form-field class="w-full">
        <mat-label>Cancel Reason</mat-label>
        <input matInput formControlName="cancel_reason" required minlength="1" />
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-content>
    <div class="left-sec">
      <app-receipt [orderWithPayment]="orderWithPayment" [onlyItems]="true"
        [splitOrder]="this.data.split_detail"></app-receipt>
    </div>
  </div>
</div>