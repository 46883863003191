import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {Permission} from '../_models/permission';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PermissionService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  private source = new BehaviorSubject<Permission>(null);
  private sourcePIN = new BehaviorSubject<Permission>(null);
  private sourceOperatorPIN = new BehaviorSubject<Permission>(null);
  current = this.source.asObservable();
  pinCurrent = this.sourcePIN.asObservable();
  pinCurrentOperator = this.sourceOperatorPIN.asObservable();

  constructor(private http: HttpClient) { }

  getUserPermission(storeId){
    return this.http.get<Permission>(`${environment.apiUrl}/backend/v2/permission/${storeId}/current_user/`, this.httpOptions)
    .subscribe(permissions => {
      this.source.next(Object.assign(new Permission(), permissions));
    });
  }

  getUserPermissionById(storeId, userId){
    return this.http.get<Permission>(`${environment.apiUrl}/backend/v2/permission/${storeId}/`, {params: {user_id: userId}})
  }

  getUserPermissionByPIN(storeId, pin){
    return this.http.get<Permission>(`${environment.apiUrl}/backend/v2/permission/${storeId}/pin_current_user/`, {params: {pin}})
    .subscribe(permissions => {
      this.sourcePIN.next(Object.assign(new Permission(), permissions));
    });
  }

  async getUserPermissionByPINSync(storeId, pin): Promise<Permission> {
    return await this.http.get<Permission>(`${environment.apiUrl}/backend/v2/permission/${storeId}/pin_current_user/`, {params: {pin}})
    .pipe(map((r: Permission) => {
      return Object.assign(new Permission(), r);
    }))
    .toPromise().then(per => {
      return per;
    });
  }

  getCurrentUserPermissionByPIN(storeId, pin){
    return this.http.get<Permission>(`${environment.apiUrl}/backend/v2/permission/${storeId}/pin_current_user/`, {params: {pin}})
    .subscribe(permissions => {
      this.sourceOperatorPIN.next(Object.assign(new Permission(), permissions));
    });
  }

  postUserPermission(payload){
    return this.http.post(`${environment.apiUrl}/backend/v2/permission/`, payload, this.httpOptions)
  }
}
