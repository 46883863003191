
<h1 class="mat-dialog-title mx-8 my-4 font-medium text-xl">Business Location</h1>
<div mat-dialog-content>
  <mat-form-field style="width: 70%">
    <mat-label>Search Location</mat-label>
    <input matInput
     matValidateAddress
     matGoogleMapsAutocomplete
     #matGoogleMapsAutocomplete="matGoogleMapsAutocomplete"
     placeholder="Search Location"
     class="form-control"
     country="us"
     [value]="store.address"
     (onAutocompleteSelected)="onAutocompleteSelected($event)"
     (onLocationSelected)="onLocationSelected($event)">
    <mat-error *ngIf="matGoogleMapsAutocomplete.addressSearchControl.hasError('required')">
      Required
    </mat-error>
    <mat-error *ngIf="matGoogleMapsAutocomplete.addressSearchControl.hasError('validateAddress')">
      Invalid input
    </mat-error>
  </mat-form-field>
</div>
<div class="spacer"></div>
<div mat-dialog-actions style="margin-bottom:auto;" align="end" class="lex space-x-4">
  <button mat-button mat-dialog-close class="mb-2">Cancel</button>
  <button mat-flat-button (click)="submit()" color="primary" [disabled]="!payload" class="mb-2 apply-btn-colr">Apply</button>
</div>
